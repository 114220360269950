
<div class="card-body pt-1 pb-1 border-top rounded-0 bg-white">
    <h5 class="row"><i class="material-icons mr-2">card_travel</i> <span>{{data.data.descricao}}</span></h5>
    <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
            <div class="table-responsive-sm">
                <table class="table table-striped table-hover">
                    <thead>
                        <tr>
                            <th class="text-center">Cad. maxdata</th>
                            <th class="text-center">Cód. Usuário</th>
                            <th>E-mail</th>
                            <th>Usa Sistema</th>
                            <th>Remover</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of listaUsuarios.itens" class="cursor">
                            <td class="text-center">{{item.id_maxdata}}</td>
                            <td class="text-center">{{item.id_usuario}}</td>
                            <td>{{item.email}}</td>
                            <td class="text-center" >
                                <ng-container *ngIf="item.chk_usa_sistema === 'SIM'">
                                    <i class="material-icons text-success"  title="SIM USA">check_circle</i>
                                </ng-container>
                                <ng-container *ngIf="item.chk_usa_sistema !== 'SIM'" >
                                    <i class="material-icons text-danger"title="NÃO USA">cancel</i>
                                </ng-container>
                            </td>
                            <td class="text-center">
                                <a (click)="confirmarExclusao(item)">
                                  <i class="material-icons text-maxdata">delete</i>
                                </a>
                              </td>
                              
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>



<!-- Modal de Confirmação -->
<div class="modal" tabindex="-1" role="dialog" [ngClass]="{ 'show': exibirModalConfirmacao }" [style.display]="exibirModalConfirmacao ? 'block' : 'none'">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text-white" >Confirmação de Exclusão</h5>
          <button type="button" class="close" aria-label="Close" (click)="exibirModalConfirmacao = false">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <p>Ao confirmar, o usuário <b>{{ objUsuario.obj?.email }}</b> terá o acesso à empresa
                <b>{{ usuarioEmpresaRemover.descricao }}</b> removido
            </p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" (click)="exibirModalConfirmacao = false">Cancelar</button>
          <button type="button" class="btn btn-danger" (click)="removerUsuario(objUsuarioEmpresa.obj); exibirModalConfirmacao = false;">Excluir</button>
        </div>
      </div>
    </div>
  </div>
  

<app-paginacao 
    [qdtPaginas]="pesquisa.quantidadeRegistrosPagina" 
    [itensgrid]="listaUsuarios.itens.length"              
    [itemsPerPage]="listaUsuarios.paginacao.quantidadeRegistrosPagina"
    [totalItems]="listaUsuarios.paginacao.totalRegistros"
    [pagina]="pesquisa.pagina"  
    (onPaginate)="mudarPagina($event)">
</app-paginacao>