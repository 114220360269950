import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { URLIMPRESSAO } from '@modules/configuration.service';

@Injectable({
  providedIn: 'root'
})
export class ConverteXmlPdfService {

  urlImpressaoRel = `${URLIMPRESSAO}/api`;

  constructor(
    private http$: HttpClient

  ) { }


  imprimir(xml: string, logo: string , rota: string) {
    const payload = { xml: xml, logo: logo };
    return this.http$.post<any>(`${this.urlImpressaoRel}/${rota}/`, payload);
  }
}
