import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CadastroViewModel } from '@modules/admin/view-models/cadastro/CadastroViewModel';
import { PesquisaGeralViewModel } from '@modules/admin/view-models/pesquisa/PesquisaGeralViewModel';
import { AutenticacaoService } from '@modules/autenticacao/services/autenticacao.service';
import { URLBASE } from '@modules/configuration.service';
import { ProdutoGradeTamanhoPesquisaViewModel } from '@modules/manager/view-models/cadastro/estoque/pesquisa/produto-grade-tamanho-pesquisa-view-model';
import { ProdutoGradeTamanhoViewModel } from '@modules/manager/view-models/cadastro/estoque/produto-grade-tamanho-view-model';
import { RetornoApiViewModel } from '@modules/manager/view-models/RetornoApiViewModel';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProdutoGradeTamanhoService {

  idEmpresaMaxdata = this.autenticacao$.acessoUsuarioValue?.idEmpresaMaxdata;
  urlApi =  `${URLBASE}/${this.idEmpresaMaxdata}/produto_grade_tamanho_service`;

  constructor(private http: HttpClient, 
      private autenticacao$: AutenticacaoService){ }

  obterTodos(pesquisa?: ProdutoGradeTamanhoPesquisaViewModel) : Observable<RetornoApiViewModel<ProdutoGradeTamanhoPesquisaViewModel[]>> {

    let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel()

    pesquisaGeral.select = 'produto_grade_tamanho.*';

    pesquisaGeral.calcRowCount = 1;

    pesquisaGeral.limit = pesquisa?.quantidadeRegistrosPagina || 0;

    pesquisaGeral.start = pesquisa?.start || 0;

    pesquisaGeral.order = pesquisa?.order ? pesquisa?.order : 'produto_grade_tamanho.tamanho'

    if (pesquisa?.id) pesquisaGeral.where = ` and produto_grade_tamanho.id in (${pesquisa?.id})`;

    if (pesquisa?.tamanho) pesquisaGeral.where = ` and lower(produto_grade_tamanho.tamanho) like lower('%${pesquisa?.tamanho}%')`;

    return this.http.post<RetornoApiViewModel<ProdutoGradeTamanhoPesquisaViewModel[]>>(`${this.urlApi}/get`,pesquisaGeral)
  }

  obterPorId(id: number): Observable<RetornoApiViewModel<ProdutoGradeTamanhoViewModel[]>>{

    let pesquisaGeralViewModel: PesquisaGeralViewModel = new PesquisaGeralViewModel();

    pesquisaGeralViewModel.select = `produto_grade_tamanho.*`;

                          pesquisaGeralViewModel.where = ` and produto_grade_tamanho.id = ${id} `;

      return this.http.post<RetornoApiViewModel<ProdutoGradeTamanhoViewModel[]>>(`${this.urlApi}/get`,pesquisaGeralViewModel);
  }

  adicionar(produtoGradeViewModel: CadastroViewModel<ProdutoGradeTamanhoViewModel>) : Observable<RetornoApiViewModel<ProdutoGradeTamanhoViewModel>>{

      return this.http.post<RetornoApiViewModel<ProdutoGradeTamanhoViewModel>>(`${this.urlApi}/addupdate`, produtoGradeViewModel);
  }

  atualizar(id: number, produtoGradeViewModel: CadastroViewModel<ProdutoGradeTamanhoViewModel>) : Observable<RetornoApiViewModel<ProdutoGradeTamanhoViewModel>>{

      return this.http.put<RetornoApiViewModel<ProdutoGradeTamanhoViewModel>>(`${this.urlApi}/addupdate/${id}`, produtoGradeViewModel);
  }
}
