import { Injectable, ɵɵresolveBody } from '@angular/core';
import { HttpClient} from '@angular/common/http';

import { ProdutoGrupoPesquisaViewModel } from 'src/modules/manager/view-models/cadastro/estoque/pesquisa/ProdutoGrupoPesquisaViewModel';

import { URLBASE } from 'src/modules/configuration.service';
import { ProdutoGrupoViewModel } from 'src/modules/manager/view-models/cadastro/estoque/ProdutoGrupoViewModel';
import { Observable } from 'rxjs';
import { ListaPaginadaViewModel } from 'src/modules/manager/view-models/ListaPaginadaViewModel';
import { RetornoApiViewModel } from 'src/modules/manager/view-models/RetornoApiViewModel';
import { Uteis } from 'src/modules/utilizarios/uteis';

import { PesquisaGeralViewModel } from '@modules/admin/view-models/pesquisa/PesquisaGeralViewModel';
import { AutenticacaoService } from 'src/modules/autenticacao/services/autenticacao.service';
import { CadastroViewModel } from 'src/modules/admin/view-models/cadastro/CadastroViewModel';

@Injectable({
    providedIn: 'root'
})
export class ProdutoGrupoService {    
    idEmpresaMaxdata = this.autenticacaoService.acessoUsuarioValue?.idEmpresaMaxdata;
    
    urlGet =  `${URLBASE}/${this.idEmpresaMaxdata}/produto_grupo_service/get`;

    urlAddUpdate  = `${URLBASE}/${this.idEmpresaMaxdata}/produto_grupo_service/addupdate`;

    constructor(private http: HttpClient, 
        private autenticacaoService: AutenticacaoService){ }

    obterTodos(pesquisa?: ProdutoGrupoPesquisaViewModel) : Observable<RetornoApiViewModel<ProdutoGrupoPesquisaViewModel[]>> {

        let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel()

        pesquisaGeral.select = `produto_grupo.id, 
                            produto_grupo.aliq_comissao, 
                            produto_grupo.descricao,
                            produto_grupo.dt_cadastro,
                            produto_grupo.ativo`;

        pesquisaGeral.limit = pesquisa?.quantidadeRegistrosPagina || 0;

        pesquisaGeral.where = `and ativo = ${pesquisa?.desativados ? 0 : 1}`;

        pesquisaGeral.start = pesquisa?.start || 0;

        pesquisaGeral.order = pesquisa?.order ? pesquisa?.order : 'produto_grupo.id';
    
        if (pesquisa?.id) pesquisaGeral.where += ` and id = ${pesquisa?.id}`;
    
        if (pesquisa?.descricao) pesquisaGeral.where += ` and lower(descricao) like lower('%${pesquisa?.descricao}%')`;

        return this.http.post<RetornoApiViewModel<ProdutoGrupoPesquisaViewModel[]>>(this.urlGet,pesquisaGeral)
    }

    obterPorId(id: number): Observable<RetornoApiViewModel<ProdutoGrupoViewModel[]>>{

        let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel()

        pesquisaGeral.select = `produto_grupo.id, 
                            produto_grupo.aliq_comissao, 
                            produto_grupo.descricao,
                            produto_grupo.dt_cadastro,
                            produto_grupo.ativo`;

        pesquisaGeral.where = ` and produto_grupo.id = ${id}`;

        return this.http.post<RetornoApiViewModel<ProdutoGrupoViewModel[]>>(`${this.urlGet}`,pesquisaGeral);
    }

    ObterTodosAutoComplete(pesquisaGeralViewModel: PesquisaGeralViewModel = new PesquisaGeralViewModel()) : Observable<RetornoApiViewModel<ProdutoGrupoViewModel[]>> {   

        return this.http.post<RetornoApiViewModel<ProdutoGrupoViewModel[]>>(this.urlGet, pesquisaGeralViewModel);
    }

    adicionar(produtoGrupoViewModel: CadastroViewModel<ProdutoGrupoViewModel>) : Observable<RetornoApiViewModel<ProdutoGrupoViewModel>>{

        return this.http.post<RetornoApiViewModel<ProdutoGrupoViewModel>>(this.urlAddUpdate, produtoGrupoViewModel);
    }

    atualizar(id: number, produtoGrupoViewModel: CadastroViewModel<ProdutoGrupoViewModel>) : Observable<RetornoApiViewModel<ProdutoGrupoViewModel>>{

        return this.http.put<RetornoApiViewModel<ProdutoGrupoViewModel>>(`${this.urlAddUpdate}/${id}`, produtoGrupoViewModel);
    }
}
