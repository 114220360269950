import { Component, OnInit, ElementRef, AfterViewInit, ViewChildren, TemplateRef, Output, EventEmitter, Input } from '@angular/core';
import { CadastroViewModel } from 'src/modules/admin/view-models/cadastro/CadastroViewModel';
import { ProdutoFabricanteViewModel } from 'src/modules/manager/view-models/cadastro/estoque/produto-fabricante-view-model';
import { DisplayMessage, GenericValidator, ValidationMessages } from 'src/modules/validacao/generic-form-validation';
import { PesquisaGeralViewModel } from '@modules/admin/view-models/pesquisa/PesquisaGeralViewModel';
import { FormGroup, Validators, FormBuilder, FormControlName } from '@angular/forms';
import { HistoricoNavegacao } from 'src/modules/utilizarios/tituloPaginas/historicoNavegacao';
import { TituloPagina } from 'src/modules/utilizarios/tituloPaginas/tituloPaginas';
import { Observable, fromEvent, merge } from 'rxjs';
import { MensagensValidacao } from 'src/modules/validacao/mensagensValidacao';
import { Uteis } from 'src/modules/utilizarios/uteis';
import { AlertMessage } from 'src/modules/alert.configuration.service';
import { Router, ActivatedRoute } from '@angular/router';
import { isNumber } from 'util';
import { AlertMensagemPadrao } from 'src/modules/utilizarios/alertMensagemPadrao';
import { NgxSpinnerService } from 'ngx-spinner';

import { ConfigModal } from '@modules/utilizarios/ConfigModal';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ProdutoFabricanteService } from '@modules/manager/cadastro/estoque/produto-fabricante/produto-fabricante.service';

@Component({
  selector: 'app-fabricante-cadastro-modal',
  templateUrl: './fabricante-cadastro-modal.component.html',
  styles: [
  ]
})
export class FabricanteCadastroModalComponent implements OnInit, AfterViewInit {

    @ViewChildren(FormControlName, { read: ElementRef }) formInputElements!: ElementRef[];
    @Output() closeModal = new EventEmitter();
    @Input() abrirFecharCadastroFabricanteModal: boolean = false;
    @Output() idCadastrado: EventEmitter<number> = new EventEmitter<number>();
    tituloPagina: TituloPagina = new TituloPagina('Fabricante de Produto - Cadastro',
      [
        new HistoricoNavegacao('Início', '/'),
        new HistoricoNavegacao('Cadastro'),
        new HistoricoNavegacao('Estoque'),
        new HistoricoNavegacao('Fabricante de Produto', '/cadastro/estoque/fabricante-de-produto'),
        new HistoricoNavegacao('Cadastro'),
      ]);
  
    cadastroForm!: FormGroup;
    objProdutoFabricante: CadastroViewModel<ProdutoFabricanteViewModel> = new CadastroViewModel<ProdutoFabricanteViewModel>();
    alterar: boolean = false;
    formResult: string = '';
    clickAddVariosRegistros: boolean = false;
    validationMessages!: ValidationMessages;
    genericValidator!: GenericValidator;
    displayMessage: DisplayMessage = {};
    carregando: boolean = false;
    modalEmpresaFiltroRef!: BsModalRef;
    tabela: string  = 'produto_fabricante'
  
  
    constructor(private route: ActivatedRoute,
      private router: Router,
      private fb: FormBuilder,
      private produtoFabricante$: ProdutoFabricanteService,
      private alert: AlertMessage,
      private spinner: NgxSpinnerService,
      private modal$: BsModalService) {
      this.messagesValidations();
    }
  
    ngOnInit(): void {
      this.criarFormulario(); 
    }
  
    ngAfterViewInit(): void {
      let controlBlurs: Observable<any>[] = this.formInputElements
        .map((formControl: ElementRef) => fromEvent(formControl.nativeElement, 'blur'));
  
      merge(...controlBlurs).subscribe(() => {
        this.displayMessage = this.genericValidator.processarMensagens(this.cadastroForm);
      });
    }
  
    messagesValidations() {
      this.validationMessages = {
        descricao: {
          required: MensagensValidacao.Obrigatorio('Descrição'),
          minlength: MensagensValidacao.MinimoCaracteres('Descricão', 2),
          maxlength: MensagensValidacao.MaximoCaracteres('Descrição', 50)
        },
      };
      this.genericValidator = new GenericValidator(this.validationMessages);
    }
  
    criarFormulario() {
      this.cadastroForm = this.fb.group({
        id: [{ value: !this.alterar ? 0 : this.objProdutoFabricante.obj.id, disabled: true }],
        descricao: [!this.alterar ? '' : this.objProdutoFabricante.obj.descricao, [Validators.required, Validators.minLength(2), Validators.maxLength(50)]],
        ativo: [!this.alterar ? true : this.objProdutoFabricante.obj.ativo]
      });
    }
  
    limparForm() {
      this.cadastroForm.reset({
        id: 0,
        descricao: '',
        ativo: true
      });
  
      (document.querySelector('[name="descricao"]') as HTMLElement).focus();
    }
  
    onSubmit() {
      this.cadastroForm.markAllAsTouched();
      this.displayMessage = this.genericValidator.processarMensagens(this.cadastroForm);
      if (!this.cadastroForm.valid) {
        this.mensagemCamposInvalidos(this.displayMessage);
        return
      }
      if (!this.cadastroForm.dirty || !this.cadastroForm.valid) {
        this.alert.Info(AlertMensagemPadrao.formularioNaoAlterado.titulo, AlertMensagemPadrao.formularioNaoAlterado.mensagem);
        return
      };
  
      this.carregando = true;
    
       this.adicionar();
    }
  
    atualizarDadosObjeto() {
      this.objProdutoFabricante.obj = Object.assign({}, this.objProdutoFabricante.obj, this.cadastroForm.value);
    }
  

  
    adicionar() {
  
      this.atualizarDadosObjeto();
  
      this.produtoFabricante$.adicionar(this.objProdutoFabricante).subscribe(
        retorno => {
  
          this.mensagensCadastro();
          
          this.limparForm();
          this.carregando = false;
          this.idCadastrado.emit(retorno.result[0].id);
          this.closeModalCadastroFabricante();
  
        },
        error => {
          this.carregando = false;
  
          this.alert.Danger('Erro', Uteis.ObterErroApi(error))
        });
    }
  
    mensagemCamposInvalidos(mensagens: any) {
      let camposInvalidos = Uteis.ConverterObjetoToArray(mensagens);
      this.alert.Warning(AlertMensagemPadrao.formularioInvalido.titulo, AlertMensagemPadrao.formularioInvalido.mensagem + '<br><br>' + camposInvalidos.toString().replace(/,/g, ''));
    }
  
    private mensagensCadastro(): void {
      this.alert.Success('Sucesso', `Fabricante de Produto ${this.alterar ? 'atualizado' : 'cadastrado'} com Sucesso.`);
          }
    
    openModalEmpresaFiltroRef(template: TemplateRef<any>){
      this.modalEmpresaFiltroRef = this.modal$.show(template, ConfigModal.modalPadrao);
      this.modalEmpresaFiltroRef.setClass('modal-large');
      
    }
    closeModalEmpresaFiltroRef(){
      this.modalEmpresaFiltroRef.hide();
    }
  
    closeModalCadastroFabricante() {
      this.closeModal.emit(false);
    }
  
  }