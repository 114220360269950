<form (ngSubmit)="onSubmit()" [formGroup]="cadastroForm">
    <div class="row">
        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <app-titulo-paginas [tituloPagina]="tituloPagina"></app-titulo-paginas>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 text-right btns-group">
            <a class="btn btn-sm btn-outline-secondary" href="javascript:window.history.back();"><i
                    class="material-icons">arrow_back</i>Voltar</a>
            <button class="btn btn-sm btn-outline-success" id="salvar" type="submit" [disabled]="carregando">
                <div [ngSwitch]="carregando">
                    <div *ngSwitchCase="true">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;
                        <span>Carregando ...</span>
                    </div>
                    <div *ngSwitchDefault>
                        <i class="material-icons">done_all</i><span>Salvar</span>
                    </div>
                </div>
            </button>
        </div>
    </div>

    <div class="card shadow-sm">
        <div class="card-header">Dados da Empresa</div>
        <div class="card-body pt-1 pb-1">
            <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <input type="hidden" id="id" name="id" formControlName="id">
                    <div class="row">
                        <div class="col-sm-12 col-md-6 col-lg-3 col-xl-1">
                            <div class="form-group">
                                <label for="id_maxdata" class="col-form-label-sm">Código
                                    Maxdata</label>
                                <input type="number" id="id_maxdata" name="id_maxdata"
                                    class="form-control form-control-sm noscroll" placeholder="Código Maxdata"
                                    formControlName="id_maxdata" [ngClass]="{'is-invalid': displayMessage.id_maxdata}">
                                <span class="invalid-feedback" *ngIf="displayMessage.id_maxdata">
                                    <p [innerHTML]="displayMessage.id_maxdata"></p>
                                </span>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-3 col-xl-1">
                            <div class="form-group">
                                <label for="qtd_max_usuario" class="col-form-label-sm">Máxima de Usuário</label>
                                <input type="number" id="qtd_max_usuario" name="qtd_max_usuario"
                                    class="form-control form-control-sm noscroll" placeholder="Código Maxdata"
                                    formControlName="qtd_max_usuario"
                                    [ngClass]="{'is-invalid': displayMessage.qtd_max_usuario}">
                                <span class="invalid-feedback" *ngIf="displayMessage.qtd_max_usuario">
                                    <p [innerHTML]="displayMessage.qtd_max_usuario"></p>
                                </span>
                            </div>
                        </div>
                        <div
                            class="col-sm-2 col-md-2 col-lg-1 col-xl-10 text-sm-right text-md-right text-lg-right text-xl-right mt-4">
                            <div class="form-group">
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" id="ativo" nae="ativo" class="custom-control-input"
                                        formControlName="ativo">
                                    <label for="ativo" class="custom-control-label">Ativo</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <div class="form-group">
                                <label for="descricao" class="col-form-label-sm">Nome /
                                    Fantasia</label>
                                <input type="text" id="descricao" name="descricao" class="form-control form-control-sm"
                                    placeholder="Nome / Fantasia" formControlName="descricao"
                                    [ngClass]="{'is-invalid': displayMessage.descricao}">
                                <span class="invalid-feedback" *ngIf="displayMessage.descricao">
                                    <p [innerHTML]="displayMessage.descricao"></p>
                                </span>
                            </div>
                        </div>

                        <div class="col-sm-6 col-md-6 col-lg-4 col-xl-2">
                            <div class="form-group">
                                <label for="emp_cnpj" class="col-form-label-sm">CPF / CNPJ<span
                                        class="text-muted"> (Obrigatório)</span></label>
                                <input type="text" id="emp_cnpj" name="emp_cnpj" class="form-control form-control-sm"
                                    placeholder="CPF ou CNPJ" autocomplete="off" [formControlName]="'emp_cnpj'"
                                    (input)="formatarDocumento()"
                                    [ngClass]="{'is-invalid': displayMessage.emp_cnpj}" />
                                    <span class="invalid-feedback" *ngIf="displayMessage.emp_cnpj">
                                        <p [innerHTML]="displayMessage.emp_cnpj" ></p>
                                    </span>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <div class="form-group">
                                <label for="ip_database" class="col-form-label-sm">IP/Link
                                    Servidor</label>
                                <input type="text" id="ip_database" name="ip_database"
                                    class="form-control form-control-sm" placeholder="IP/Link Servidor"
                                    [formControlName]="'ip_database'"
                                    [ngClass]="{'is-invalid': displayMessage.ip_database}">
                                <span class="invalid-feedback" *ngIf="displayMessage.ip_database">
                                    <p [innerHTML]="displayMessage.ip_database"></p>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</form>