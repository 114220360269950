import { Injectable, ɵɵresolveBody } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { URLBASE } from 'src/modules/configuration.service';
import { Observable } from 'rxjs';
import { ListaPaginadaViewModel } from 'src/modules/manager/view-models/ListaPaginadaViewModel';
import { RetornoApiViewModel } from 'src/modules/manager/view-models/RetornoApiViewModel';
import { Uteis } from 'src/modules/utilizarios/uteis';

import { PesquisaGeralViewModel } from '@modules/admin/view-models/pesquisa/PesquisaGeralViewModel';
import { AutenticacaoService } from 'src/modules/autenticacao/services/autenticacao.service';
import { EnderecoMunicipioPesquisaViewModel } from 'src/modules/manager/view-models/cadastro/cliente-fornecedor/pesquisa/endereco-municipio-pesquisa-view-model';
import { EnderecoMunicipioViewModel } from 'src/modules/manager/view-models/cadastro/cliente-fornecedor/endereco-municipio-view-model';
import { CadastroViewModel } from 'src/modules/admin/view-models/cadastro/CadastroViewModel';

@Injectable({
    providedIn: 'root'
})
export class EnderecoMunicipioService {

    idEmpresaMaxdata = this.autenticacaoService.acessoUsuarioValue?.idEmpresaMaxdata;
    urlGet = `${URLBASE}/${this.idEmpresaMaxdata}/endereco_municipio_service/get`;
    urlAddUpdate = `${URLBASE}/${this.idEmpresaMaxdata}/endereco_municipio_service/addupdate`;
    urlImportData = `${URLBASE}/${this.idEmpresaMaxdata}/endereco_municipio_service/importdata`;

    constructor(private http: HttpClient,
        private autenticacaoService: AutenticacaoService) { }

    obterTodos(pesquisa: EnderecoMunicipioPesquisaViewModel): Observable<RetornoApiViewModel<EnderecoMunicipioPesquisaViewModel[]>> {

        let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel()
        
        pesquisaGeral.select = `endereco_municipio.id,
                                endereco_municipio.descricao,
                                endereco_municipio.ativo,
                                endereco_municipio.codigo_ibge,
                                endereco_municipio.codigo_uf,
                                endereco_municipio.codigo_pais,
                                endereco_municipio.descricao_pais,
                                endereco_uf.descricao as endereco_uf_descricao,
                                endereco_uf.sigla as endereco_uf_sigla`;

        pesquisaGeral.calcRowCount = 1;

        pesquisaGeral.start = pesquisa?.start || 0;

        pesquisaGeral.order = pesquisa?.order ? pesquisa?.order : 'endereco_municipio.descricao';

        pesquisaGeral.limit = pesquisa?.quantidadeRegistrosPagina || 0;

        pesquisaGeral.where = ` and endereco_municipio.ativo = ${pesquisa?.desativados ? 0 : 1}`;
    
        if (pesquisa?.id) pesquisaGeral.where += ` and endereco_municipio.id = ${pesquisa?.id}`;
    
        if (pesquisa?.codigo_ibge) pesquisaGeral.where += ` and endereco_municipio.codigo_ibge = ${pesquisa?.codigo_ibge}`;
    
        if (pesquisa?.descricao) pesquisaGeral.where += ` and lower(endereco_municipio.descricao) like lower('%${pesquisa?.descricao}%')`;

        if (pesquisa?.codigo_uf) pesquisaGeral.where += ` and endereco_municipio.codigo_uf = ${pesquisa?.codigo_uf}`;

        return this.http.post<RetornoApiViewModel<EnderecoMunicipioPesquisaViewModel[]>>(this.urlGet, pesquisaGeral)
    }

    obterPorId(id: number): Observable<RetornoApiViewModel<EnderecoMunicipioViewModel[]>> {
        let pesquisa: PesquisaGeralViewModel = new PesquisaGeralViewModel();
        pesquisa.select = `endereco_municipio.id,
                        endereco_municipio.descricao,
                        endereco_municipio.ativo,
                        endereco_municipio.codigo_ibge,
                        endereco_municipio.codigo_uf,
                        endereco_municipio.codigo_pais,
                        endereco_municipio.descricao_pais`;
        pesquisa.where = ` and endereco_municipio.id = ${id}`;
        return this.http.post<RetornoApiViewModel<EnderecoMunicipioViewModel[]>>(`${this.urlGet}`, pesquisa);
    }

    obterPorCodigoIBGE(codigo_ibge: number): Observable<RetornoApiViewModel<EnderecoMunicipioViewModel[]>> {
        let pesquisa: PesquisaGeralViewModel = new PesquisaGeralViewModel();
        pesquisa.select = `endereco_municipio.id,
                        endereco_municipio.descricao,
                        endereco_municipio.ativo,
                        endereco_municipio.codigo_ibge,
                        endereco_municipio.codigo_uf,
                        endereco_municipio.codigo_pais,
                        endereco_municipio.descricao_pais`;
        pesquisa.where = ` and codigo_ibge = ${codigo_ibge}`;
        return this.http.post<RetornoApiViewModel<EnderecoMunicipioViewModel[]>>(`${this.urlGet}`, pesquisa);
    }

    autoComplete(endereco_municipio_descricao: string, endereco_municipio_codigo_uf: number){
        let pesquisa: PesquisaGeralViewModel = new PesquisaGeralViewModel();
        pesquisa.where = ` and lower(endereco_municipio.descricao) like lower('%${endereco_municipio_descricao}%') and endereco_municipio.codigo_uf = ${endereco_municipio_codigo_uf}`;
        return this.http.post<RetornoApiViewModel<EnderecoMunicipioPesquisaViewModel[]>>(this.urlGet, pesquisa)
    }

    adicionar(enderecoMunicipioViewModel: CadastroViewModel<EnderecoMunicipioViewModel>): Observable<RetornoApiViewModel<EnderecoMunicipioViewModel>> {
        return this.http.post<RetornoApiViewModel<EnderecoMunicipioViewModel>>(this.urlAddUpdate, enderecoMunicipioViewModel);
    }

    atualizar(id: number, enderecoMunicipioViewModel: CadastroViewModel<EnderecoMunicipioViewModel>): Observable<RetornoApiViewModel<EnderecoMunicipioViewModel>> {
        return this.http.put<RetornoApiViewModel<EnderecoMunicipioViewModel>>(`${this.urlAddUpdate}/${id}`, enderecoMunicipioViewModel);
    }

    sincronizar() {
        return this.http.get(this.urlImportData);
    }
}
