export class ClienteEnderecoViewModel {
    id?: number;
    cliente_id?: number;
    endereco_id?: number;
    principal?: boolean;
    cobranca?: boolean;
    ie_produtor_rural?: string;

    // Campos utilizados na atualização do endereço
    codigo_ibge?: number;
    descricao_municipio?: string;
    descricao_uf?: string;
}