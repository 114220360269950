export class ConfiguracaoViewModel {    
    id?: number;
    ativo?: boolean;
    emp_cnpj?: string;
    emp_razao?: string;
    emp_fantasia?: string;
    emp_apelido?: string;
    emp_ie?: string;
    emp_fone?: string;
    emp_site?: string;
    emp_email?: string;
    emp_resp_email?: string;
    emp_tipo_pessoa?: number;
    emp_padrao?: boolean;
    emp_endereco?: string;
    emp_endereco_numero?: string;
    emp_endereco_bairro?: string;
    emp_endereco_cep?: string;
    emp_endereco_cidade_codigo_ibge?: number;
    emp_endereco_municipio_descricao?: string;
    emp_endereco_uf_sigla?: string;
    lst_imp_sped_fiscal_perfil?: number;
    lst_imp_tipo_regime?: number;
    lst_imp_incidencia_tributaria?: number;
    lst_imp_sped_tipo_atividade?: number;
    lst_imp_tipo_contrib_apurada?: number;
    lst_imp_metodo_apropriacao_credito?: number;
    lst_imp_sped_pis_cofins_tipo_atividade?: number;
    lst_imp_sped_ind_per_apur_ipi?: number;
    lst_imp_sped_filtro_tipo_data?: number;
    aliq_imp_prev_social?: number;
    chk_imp_sped_gerar_registro_c101?: number;
    chk_imp_sped_gerar_registro_c197?: number;
    chk_imp_sped_gerar_bloco_k?: number;
    lst_imp_sped_class_industrial?: number;
    aliq_imp_irrf?: number;
    versao_db?: number;
    cpf?: number;
    cnpj?: number;
    emp_filtro?: boolean;
    contador_nome?: string;
    contador_cpf?: string;
    contador_crc?: string;
    contabilidade_id?: string;
    contabilidade_nome?: string;
    integra_ecommerce_url?: string;
    integra_ecommerce_key?: string;
    config_seguimento_id?: number;
    emp_utiliza_ecf?: number;
    transf_estoque_cliente_id?: number;
    transf_estoque_cliente_nome?: string;
    transf_estoque_cliente_cpf_cnpj?: string;
    img_logomarca?: string;
}
