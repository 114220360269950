<div class="row">
    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-5 col-xl-4">
        <div class="form-group">
            <label for="produto_embalagem_venda_codigo_ean" class="col-form-label-sm">Código Barras</label>
            <input id="produto_embalagem_venda_codigo_ean" name="produto_embalagem_venda_codigo_ean" class="form-control form-control-sm"
                    [(ngModel)]="pesquisa.codigo_ean"/>
        </div>
    </div>
    <div class="col-sm-12 col-md-8 col-lg-12 col-xl-4">
        <div class="form-group">
            <label for="produto_un_id" class="col-form-label-sm">UN</label>
            <select id="produto_un_id" name="produto_un_id"
                class="form-control form-control-sm" placeholder="produto_un_id"
                autocomplete="off" [(ngModel)]="pesquisa.produto_un_id">
                <option [ngValue]="undefined"></option>
                <option *ngFor="let item of listaProdutoUn" [ngValue]="item.id">
                    {{item.un}} - {{ item.descricao }}</option>
            </select>
        </div>
    </div>
    <div class="col-xs-12 col-sm-2 col-md-3 col-lg-4 col-xl-3 mt-4">
        <div class="text-right btns-group">
            <button type="button" class="btn btn-sm btn-outline-secondary" (click)="fecharModalProdutoEmbalagemVenda()" *ngIf="modalPesquisa">
                Cancelar</button>
            <button type="button" class="btn btn-sm btn-outline-info" (click)="obterProdutoEmbalagemVenda()">
                <i class="material-icons">search</i> Pesquisar
            </button>
            <button type="button" class="btn btn-sm btn-outline-success" (click)="abrirModalProdutoEmbalagemVenda()" *ngIf="!modalPesquisa"><i class="material-icons">add</i>Novo</button>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div class="my-custom-scrollbar table-wrapper-scroll-y">
            <table class="table table-hover table-borderless table-striped table-sm " width="100%" id="table" >
                <thead class="thead">
                    <tr>
                        <th>Código</th>
                        <th>UN</th>
                        <th>Fator Conversão</th>
                        <th>Vlr Venda</th>
                        <th>Código Barras</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="cursor" 
                    *ngFor="let item of produtoEmbalagemVenda; let i = index;" 
                    (click)="selecionarIndex(i);"
                    [ngClass]="{'selected-line': (indexSelecionado || 0) === i }">
                        <td class="text-nowrap pr-5">{{item.id}}</td>
                        <td class="text-nowrap pr-2">{{item.produto_un_un}}</td>
                        <td class="text-nowrap pr-2">{{item.fator_conversao }}</td>
                        <td class="text-nowrap pr-2">{{item.vlr_venda | currency: 'BRL':'R$':'1.2-2'}}</td>
                        <td class="text-nowrap pr-2">{{item.codigo_ean}}</td>
                        <td class="text-nowrap pr-2" (click)="abrirModalProdutoEmbalagemVenda(item)">
                            <i class="material-icons" >{{modalPesquisa ? "done_all" : "edit"}}</i>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<app-produto-embalagem-venda-cadastro-modal *ngIf="abrirFecharProdutoEmbalagemVendaCadastroModal"
    [abrirFecharProdutoEmbalagemVendaCadastroModal]="abrirFecharProdutoEmbalagemVendaCadastroModal"
    [produtoId]="produtoId" [produtoEmbalagemVendaId]="objProdutoEmbalagemVenda?.id || 0"
    (fecharModal)="fecharModalProdutoEmbalagemVendaCadastro()">
</app-produto-embalagem-venda-cadastro-modal>