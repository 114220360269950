import { environment } from '../environments/environment';

let configURL = 'http://homologacao.mx.app.br:8082';
let configURLImpressao = 'http://homologacao.mx.app.br:80';
let hostname = window.location.hostname;
let host = window.location.host;
let producao = environment.production;

if (environment.production) {
    switch (host) {
        case `${hostname}:8083`:
            configURL = `http://${hostname}:8082`;
            configURLImpressao = `http://${hostname}:80`;
            break;

        case 'sistemaweb.local':
            configURL = 'http://sistemaweb.api:8081';
            configURLImpressao = 'http://10.0.4.12:80';
            break;

        case `${hostname}:8081`:
            configURL = 'http://10.0.4.4:8081';
            configURLImpressao = 'http://10.0.4.12:80';
            break;

        case `10.0.4.12`:
            configURL = `http://10.0.4.12:8080`;
            configURLImpressao = `http://10.0.4.12:80`;
            producao = false;
            break;

        case `homologacao.maxdatasistema.com.br`:
            configURL = `http://homologacao.maxdatasistema.com.br:8080`;
            configURLImpressao = `http://homologacao.maxdatasistema.com.br:80`;
            producao = false;
            break;

        case `homologacao.mx.app.br`:
            configURL = `http://homologacao.mx.app.br:8080`;
            configURLImpressao = `http://homologacao.mx.app.br:80`;
            producao = false;
            break;

        case `10.0.2.10`:
            configURL = `http://10.0.2.11:5001`;
            configURLImpressao = `http://10.0.2.10:80`;
            break;

        case `${hostname}:4200`:
            configURL = `http://10.0.2.2:8080`;
            configURLImpressao = `http://10.0.2.2:80`;
            producao = false;
            break;

        default:
            configURL = `http://${hostname}:5001`;
            configURLImpressao = `http://${hostname}:80`;
            break;
    }
} else {
    switch (host) {

        case `homologacao.maxdatasistema.com.br`:
            configURL = `http://homologacao.maxdatasistema.com.br:8080`;
            configURLImpressao = `http://homologacao.maxdatasistema.com.br:80`;
            break;

        default:
            configURL = 'http://homologacao.mx.app.br:8080';
            // configURL = 'http://10.0.2.10:8080';
            configURLImpressao = 'http://homologacao.mx.app.br:80';
            // configURLImpressao = 'http://127.0.0.1:8081';
            break;
    }
    producao = false;
}

export const URLBASE = configURL;
export const URLIMPRESSAO = configURLImpressao;  /** URL de impressão da NF-e */
export const URLINTEGRACAO = 'http://10.0.2.100:8084/api/prestashop';
export const SISTEMAPRODUCAO = producao;


