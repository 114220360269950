import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AlertMessage } from '@modules/alert.configuration.service';
import { OperacaoService } from '@modules/manager/cadastro/contabil/operacao/operacao.service';
import { OperacaoViewModel } from '@modules/manager/view-models/cadastro/contabil/operacao-view-model';
import { OperacaoPesquisaViewModel } from '@modules/manager/view-models/cadastro/contabil/pesquisa/operacao-pesquisa-view-model';
import { ListaPaginadaViewModel } from '@modules/manager/view-models/ListaPaginadaViewModel';
import { Uteis } from '@modules/utilizarios/uteis';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-select-operacao',
  templateUrl: './select-operacao.component.html',
  styles: []
})
export class SelectOperacaoComponent implements OnInit {

  @Output() operacao: EventEmitter<any> = new EventEmitter<any>();

  pesquisaOperacao: OperacaoPesquisaViewModel = new OperacaoPesquisaViewModel();
  listaOperacao: ListaPaginadaViewModel<OperacaoViewModel> = new ListaPaginadaViewModel<OperacaoViewModel>();
  item_da_lista: any = null;

  constructor(
    private alert: AlertMessage,
    private spinner: NgxSpinnerService,
    private operacao$: OperacaoService,

  ) { }

  ngOnInit() {

    this.obterOperacao();

  }

  obterOperacao() {

    this.operacao$.obterTodos(this.pesquisaOperacao).subscribe(
      retorno => {

        this.listaOperacao.itens = retorno.total > 0 ? retorno.result : [];

        this.listaOperacao.paginacao.totalRegistros = retorno.total;

        this.listaOperacao.paginacao.quantidadeRegistrosPagina = 100;

      },
      err => this.alert.Danger("Erro", Uteis.ObterErroApi(err))
    );

  }

  operacaoSelecionada(){

    this.operacao.emit(this.item_da_lista);

  }

}
