import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { AlertMessage } from '@modules/alert.configuration.service';
import { ProdutoLoteService } from '@modules/manager/cadastro/estoque/produto-lote/produto-lote.service';
import { ProdutoLotePesquisaViewModel } from '@modules/manager/view-models/cadastro/estoque/pesquisa/produto-lote-pesquisa-view-model';
import { Uteis } from '@modules/utilizarios/uteis';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-produto-lote-pesquisa-modal',
  templateUrl: './produto-lote-pesquisa-modal.component.html',
  styleUrls: []
})
export class ProdutoLotePesquisaModalComponent implements OnInit {

  @Input() produtoId: number;
  @Input() modalPesquisa: boolean = false;
  @Output() abrirFecharProdutoLoteModal = new EventEmitter();
  @Output() objProdutoLote: EventEmitter<any> = new EventEmitter();
  pesquisa: ProdutoLotePesquisaViewModel = new ProdutoLotePesquisaViewModel();
  produtoLote: ProdutoLotePesquisaViewModel[] = [];
  indexSelecionado: number = -1;
  constructor(
    private produto_lote$: ProdutoLoteService,
    private alert: AlertMessage,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit(): void {

    this.obterProdutoLote();
  }

  obterProdutoLote() {

    this.produtoLote = []

    this.pesquisa.produto_id = this.produtoId;

    if(!this.pesquisa?.produto_id || this.pesquisa?.produto_id <= 0) return; 

    this.indexSelecionado = -1;

    this.produto_lote$.obterTodos(this.pesquisa, this.modalPesquisa).subscribe(
      retorno => {

        this.spinner.hide();

        this.produtoLote = retorno.total > 0 ? retorno.result : [];

        this.indexSelecionado = 0; 

      },
      error => {

        this.indexSelecionado = -1;

        this.alert.Danger('Error', Uteis.ObterErroApi(error))

        this.spinner.hide();
      }
    );
  }

  selecionarIndex(index: number) {
    this.indexSelecionado = index;
  }

  @HostListener('window:keydown', ['$event']) keyEvent(event: KeyboardEvent) {

    if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {

        if (this.indexSelecionado > 0 && event.key === 'ArrowUp') this.indexSelecionado -= 1;

        if (this.indexSelecionado < this.produtoLote.length - 1 && event.key === 'ArrowDown') this.indexSelecionado += 1;
        
        document.getElementById('table').getElementsByTagName('tbody')[0].getElementsByTagName('tr')[this.indexSelecionado].scrollIntoView({block: "center"})
       
    } else if (event.key === 'Enter') {

        if (this.indexSelecionado > -1) this.indexSelecionado = -1;

    } 

  }

  abrirModalProdutoLote(item?: any) {
    
    if (item) {
      this.objProdutoLote.emit(item); 
    }

    else this.abrirFecharProdutoLoteModal.emit();
  }
}
