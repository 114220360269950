import { Component, OnInit } from '@angular/core';
import { UsuarioService } from '../cadastro/usuario/usuario.service';
import { PesquisaGeralViewModel } from '../view-models/pesquisa/PesquisaGeralViewModel';
import { EmpresaService } from '../cadastro/empresa/empresa.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { UsuarioPesquisaViewModel } from '../view-models/pesquisa/UsuarioPesquisaViewModel';
import { EmpresaPesquisaViewModel } from '../view-models/pesquisa/EmpresaPesquisaViewModel';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styles: []
})
export class InicioComponent implements OnInit {
  totalUsuarios: any;
  totalEmpresas: any;
  pesquisaUsuario: UsuarioPesquisaViewModel = new UsuarioPesquisaViewModel();
  pesquisaEmpresa: EmpresaPesquisaViewModel = new EmpresaPesquisaViewModel();

  constructor(private usuario$: UsuarioService,
    private empresa$: EmpresaService,
    private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.pesquisar();
  }

  pesquisar() {

    this.spinner.show();

    this.pesquisaUsuario = Object.assign({ quantidadeRegistrosPagina: 0 })

    this.usuario$.obterTodos(this.pesquisaUsuario).subscribe(
      
      retorno => {

        this.spinner.hide();

        this.totalUsuarios = retorno.total;
        
      },
      error => {

        this.spinner.hide();
      });

    this.pesquisaEmpresa = Object.assign({ quantidadeRegistrosPagina: 0 })

    this.empresa$.obterTodos(this.pesquisaEmpresa).subscribe(
      retorno => {
        
        this.spinner.hide();

        this.totalEmpresas = retorno.total;
        
      },
      error => this.spinner.hide()
    );
  };

}
