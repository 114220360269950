import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProdutoEmpresaService } from '@modules/manager/cadastro/estoque/produto-empresa/produto-empresa.service';
import { ProdutoService } from '@modules/manager/cadastro/estoque/produto/produto.service';
import { RetornoApiViewModel } from '@modules/manager/view-models/RetornoApiViewModel';
import { CadastroProdutoViewModel } from '@modules/manager/view-models/cadastro/estoque/cadastro-produto-view-model';
import { ProdutoEmpresaPesquisaViewModel } from '@modules/manager/view-models/cadastro/estoque/pesquisa/produto-empresa-pesquisa-view-model';
import { ProdutoPesquisaViewModel } from '@modules/manager/view-models/cadastro/estoque/pesquisa/produto-pesquisa-view-model';



export interface produtoEstoque {
  emp_apelido: string;
  emp_razao: string;
  qtd_estoque_atual: number;
  vlr_venda: number;
}

@Component({
  selector: 'app-produto-consulta-estoque-filiais',
  templateUrl: './produto-consulta-estoque-filiais.component.html',
  styles: [
  ]
})

export class ProdutoConsultaEstoqueFiliaisComponent implements OnInit {
  
  total_estoque: number;
  pesquisa: ProdutoEmpresaPesquisaViewModel = new ProdutoEmpresaPesquisaViewModel();
  objProduto:  ProdutoPesquisaViewModel = new  ProdutoPesquisaViewModel();
  aplicacaoProduto: string = "";

  produtoEstoqueEmpresas: produtoEstoque[] = []; 

  

  displayedColumns: string[] = ['Empresa', 'Estoque', 'Valor Un.'];
  dataSource = this.produtoEstoqueEmpresas;
  private base64textString: string = "";
  url: string = "";
  produto_id: number;
  constructor(
    private produtoEmpresaService: ProdutoEmpresaService,
    private produtoService: ProdutoService,

  ) { }

  ngOnInit(): void {
 



  }

  selecionarProduto(produto: any) {
    this.produto_id = produto
    this.estoqueEmpresas();
    this.obterImagem();
    this.obterAplicacaoPoduto();
  }


  estoqueEmpresas() {
    this.pesquisa.produto_id = this.produto_id;
    this.pesquisa.consultarTodasEmpresas =1;

    this.produtoEmpresaService.estoqueEmpresas(this.pesquisa).subscribe(
      retorno => {
        if (retorno.result) {
          this.produtoEstoqueEmpresas = retorno.result.map(item => ({
            emp_apelido: item.emp_apelido,
            emp_razao: item.emp_razao,
            qtd_estoque_atual: item.qtd_estoque_atual,
            vlr_venda: item.vlr_venda,
          }));// Armazene os dados na variável
          this.total_estoque = retorno.result.reduce((total, item) => total + item.qtd_estoque_atual, 0);
     

        } else {
          // Trate o caso de retorno não sucedido, se necessário
        }
      },
      (error: any) => {
        // Trate o erro, se necessário
      }
    );
    
}

  obterImagem() {
    var data = {
      id: this.pesquisa.produto_id = this.produto_id,

      field_name: "img_foto",
    };

    this.produtoService.getImage(this.produto_id, data).subscribe(
      (retorno) => {
        var binaryString = retorno.result[0].img_foto;

        if (retorno.result[0].img_foto == "") {
          this.url = "https://via.placeholder.com/300x150?text=Imagem";
        } else {
          this.base64textString = "data:image/png;base64," + binaryString;

          this.url = this.base64textString;
        }
      },
      (error) => {
        this.url = "https://via.placeholder.com/300x150?text=Imagem";
      }
    );
  }

  obterAplicacaoPoduto() {
    this.objProduto.id = this.produto_id;


    this.produtoService.obterTodos(this.objProduto).subscribe(retorno => {
  

      this.aplicacaoProduto = retorno.result[0].aplicacao

    })


  }

}
