import { Component, OnInit } from '@angular/core';
import { TituloPagina } from 'src/modules/utilizarios/tituloPaginas/tituloPaginas';
import { HistoricoNavegacao } from 'src/modules/utilizarios/tituloPaginas/historicoNavegacao';
import { EmpresaService } from './empresa.service';
import { EmpresaPesquisaViewModel } from '../../view-models/pesquisa/EmpresaPesquisaViewModel';
import { ListaPaginadaViewModel } from 'src/modules/manager/view-models/ListaPaginadaViewModel';
import { EmpresaViewModel } from '../../view-models/cadastro/EmpresaViewModel';
import { QuantidadeItensPaginacao } from 'src/modules/utilizarios/quantidadeItensPaginacao';
import { GrupoMultilojaViewModel } from '../../view-models/cadastro/GrupoMultilojaViewModel';
import { TipoDocumento } from 'src/modules/utilizarios/tipoDocumento';
import { AlertMessage } from 'src/modules/alert.configuration.service';
import { Uteis } from 'src/modules/utilizarios/uteis';
import { PesquisaGeralViewModel } from '../../view-models/pesquisa/PesquisaGeralViewModel';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { UsuarioEmpresaComponent } from '../usuario-empresa/usuario-empresa.component';

@Component({
  selector: 'app-empresa',
  templateUrl: './empresa.component.html',
  styles: []
})
export class EmpresaComponent implements OnInit {

  tituloPagina: TituloPagina = new TituloPagina('Cadastro de Empresas - Pesquisa',
    [
      new HistoricoNavegacao('Início', '/admin'),
      new HistoricoNavegacao('Cadastro'),
      new HistoricoNavegacao('Empresas'),
      new HistoricoNavegacao('Cadastro', 'novo'),
    ]);

  pesquisa!: EmpresaPesquisaViewModel;
  listaPaginada: ListaPaginadaViewModel<EmpresaViewModel> = new ListaPaginadaViewModel<EmpresaViewModel>();
  listaItensPaginacao: QuantidadeItensPaginacao[] = QuantidadeItensPaginacao.listaQuantidades;
  listaGruposMultiloja: GrupoMultilojaViewModel[] = [];
  tipoPesquisaCpfCnpj: string = TipoDocumento.TiposCpfCnpj[0];
  listaTiposDocumentos: string[] = TipoDocumento.TiposCpfCnpj;
  dadosEmpresa: EmpresaViewModel = new EmpresaViewModel();

  constructor(
    private empresa$: EmpresaService,
    private alert: AlertMessage,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog) { }

  ngOnInit() {
    
    this.pesquisa = Uteis.recuperarFiltro('adm_empresa', new EmpresaPesquisaViewModel());

    this.pesquisar();
  }

  pesquisar() {
   
    if (this.pesquisa === undefined) this.pesquisa = new EmpresaPesquisaViewModel();

    this.spinner.show();

    this.empresa$.obterTodos(this.pesquisa).subscribe(
      retorno => {
        
        this.listaPaginada.itens = retorno.total > 0 ? retorno.result : [];

        this.listaPaginada.paginacao.totalRegistros = retorno.total;

        this.listaPaginada.paginacao.quantidadeRegistrosPagina = this.pesquisa.quantidadeRegistrosPagina;

        this.spinner.hide();

        Uteis.atualizarFiltro('adm_empresa', this.pesquisa);

      },
      error => {

        this.spinner.hide();

        this.alert.Danger('Erro', Uteis.ObterErroApi(error))

      }
    )
  }

  mudarPagina(event: any) {

    this.pesquisa.start = (event.page - 1) * this.pesquisa.quantidadeRegistrosPagina;
    this.pesquisa.pagina= event.page;
    this.pesquisar();
  }

  ordenarLista(campo: string) {

    this.pesquisa.order = campo;

    this.pesquisar();
  }

  imprimir() {
    this.spinner.show();

    this.empresa$.imprimirListagemEmpresas(this.pesquisa).subscribe(
      retorno => {

        this.spinner.hide();

        var link = retorno;

        fetch(link).then(res => res.blob()).then(res => window.open(URL.createObjectURL(res), '_blank'));
      },
      err => {
        this.spinner.hide();
        this.alert.Danger('Erro na Impressão', Uteis.ObterErroApi(err))
      }
    )


  }

  modalEmpresaUsuario(item: any) {
    this.dadosEmpresa= item;
    this.dialog.open(UsuarioEmpresaComponent, {

      width: '980px',
      autoFocus: false,
      maxHeight: 'auto',
      data: {
        data: this.dadosEmpresa
      }

    })
  }

}
