<app-header *ngIf="acessoUsuario"></app-header>

<router-outlet *ngIf="!acessoUsuario"></router-outlet>

<div bsModal #childModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-child-name">
    <div class="modal-dialog modal-md">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title pull-left">Finalização da sessão</h4>
            </div>
            <div class="modal-body">
                <p>{{idleState}}</p>
            </div>
            <div class="modal-footer">
                <button class="btn btn-sm btn-outline-secondary" (click)="logout()">logout</button>
                <button class="btn btn-sm btn-outline-success" (click)="stay()">Continuar</button>
            </div>
        </div>
    </div>
</div>


