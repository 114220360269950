import { Injectable } from '@angular/core';
import { URLBASE } from '@modules/configuration.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PesquisaGeralViewModel } from '@modules/admin/view-models/pesquisa/PesquisaGeralViewModel';
import { RetornoApiViewModel } from '@modules/manager/view-models/RetornoApiViewModel';
import { UsuarioManagerPesquisaViewModel } from '@modules/manager/view-models/cadastro/usuario-manager/pesquisa/usuario-manager-pesquisa-view-model';
import { Observable } from 'rxjs';
import { UsuarioManagerViewModel } from '@modules/manager/view-models/cadastro/usuario-manager/usuario-manager-view-model';
import { CadastroViewModel } from '@modules/admin/view-models/cadastro/CadastroViewModel';
import { AutenticacaoService } from '@modules/autenticacao/services/autenticacao.service';
import { UsuarioViewModel } from '@modules/admin/view-models/cadastro/UsuarioViewModel';


@Injectable({
  providedIn: 'root'
})
export class UsuarioManagerService {

  idEmpresaMaxdata = this.autenticacaoService.acessoUsuarioValue?.idEmpresaMaxdata;
  urlAPI = `${URLBASE}/${this.idEmpresaMaxdata}/usuario_service`;

  constructor(private http: HttpClient, private autenticacaoService: AutenticacaoService) { }

  obterTodos(pesquisa?: UsuarioManagerPesquisaViewModel): Observable<RetornoApiViewModel<UsuarioManagerPesquisaViewModel[]>> {

    let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel();

    pesquisaGeral.select = `usuario.id,
                          usuario.id_usuario_adm,
                          usuario.nome,
                          usuario.apelido,
                          usuario.email,
                          usuario.cpf,
                          usuario.rg,
                          usuario.dt_nascimento,
                          usuario.fone,
                          usuario.endereco,
                          usuario.endereco_numero,
                          usuario.endereco_bairro,
                          usuario.endereco_cep,
                          usuario.endereco_cidade_codigo_ibge,
                          usuario.chk_menu_completo,
                          usuario.ativo,
                          usuario.aliq_comissao_servico,
                          usuario.aliq_comissao_produto`;

    pesquisaGeral.calcRowCount = 1;

    pesquisaGeral.limit = pesquisa?.quantidadeRegistrosPagina || 0;

    if(pesquisa?.desativados == null) pesquisaGeral.where += ``;
    else pesquisaGeral.where += ` and usuario.ativo =${pesquisa?.desativados ? 0 : 1}`;

    // pesquisaGeral.where = ` and (usuario.ativo = ${pesquisa?.desativados ? 0 : 1} or usuario.ativo is null)`;

    pesquisaGeral.start = pesquisa?.start || 0;

    pesquisaGeral.order = pesquisa?.order ? pesquisa?.order : 'usuario.nome' 

    if (pesquisa?.id_usuario_adm) pesquisaGeral.where += ' and id_usuario_adm = ' + pesquisa?.id_usuario_adm;

    if (pesquisa?.nome) pesquisaGeral.where += ` and lower(nome) like lower('%` + pesquisa?.nome + `%')`;

    if (pesquisa?.email) pesquisaGeral.where += ` and lower(email) like lower('%` + pesquisa?.email + `%')`;

    return this.http.post<RetornoApiViewModel<UsuarioManagerPesquisaViewModel[]>>(`${this.urlAPI}/get`, pesquisaGeral);
  }

  obterPorId(id: number): Observable<RetornoApiViewModel<UsuarioManagerViewModel[]>> {

    let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel();

    pesquisaGeral.select = `usuario.*`;
    
    pesquisaGeral.where = ` and usuario.id = ${id}`;

    return this.http.post<RetornoApiViewModel<UsuarioManagerViewModel[]>>(`${this.urlAPI}/get`, pesquisaGeral);
  }

  obterPorIdAdm(id: number): Observable<RetornoApiViewModel<UsuarioManagerViewModel[]>> {

    let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel();

    pesquisaGeral.select = `usuario.*`;
    
    pesquisaGeral.where = ` and usuario.id_usuario_adm = ${id}`;

    return this.http.post<RetornoApiViewModel<UsuarioManagerViewModel[]>>(`${this.urlAPI}/get`, pesquisaGeral);
  }

  adicionar(objCadastro: CadastroViewModel<UsuarioManagerViewModel>): Observable<RetornoApiViewModel<UsuarioManagerViewModel>> {
    return this.http.post<RetornoApiViewModel<UsuarioManagerViewModel>>(`${this.urlAPI}/addupdate`, objCadastro);
  }

  atualizar(id: number, objCadastro: CadastroViewModel<UsuarioManagerViewModel>): Observable<RetornoApiViewModel<UsuarioManagerViewModel>> {
    return this.http.put<RetornoApiViewModel<UsuarioManagerViewModel>>(`${this.urlAPI}/addupdate/${id}`, objCadastro);
  }

  enviarEmaildeAtivacao(usuario_id: number) : Observable<RetornoApiViewModel<any>>{

    let obj = Object.assign({ id_usuario_cliente: usuario_id, url_front: `http://${window.location.host}/#/checkin/${this.idEmpresaMaxdata}/` })

    return this.http.post<RetornoApiViewModel<any>>(`${this.urlAPI}/AtivacaoEnviarEmail/${usuario_id}`, obj);
  }

  ativacaoVerificar(emp_id: number, token: string) : Observable<any>{

    return this.http.post<any>(`${URLBASE}/${emp_id}/usuario_service/AtivacaoVerificar`,{ token: token });

  }



}
