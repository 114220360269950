<p-dialog [(visible)]="abrirFecharProdutoEmbalagemVendaCadastroModal" [modal]="true" [closable]="true" [style]="{width: '70vw'}" [closable]="false"
    [maximizable]="false" [baseZIndex]="10000" [draggable]="false" [resizable]="false" (onHide)="closeModalCadastroProdutoEmbalagemVenda()">
    <ngx-spinner name="modalAtualiza" bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-clip-rotate"
        [fullScreen]="false"></ngx-spinner>
    <p-header>Produto Embalagem Venda - {{ alterar ? "Editar" : "Cadastrar"}}</p-header>


    <form (ngSubmit)="onSubmit()">
        <div class="d-flex justify-content-end">
            <div class="btns-group mb-1">
                <button class="btn btn-sm btn-outline-secondary" (click)="closeModalCadastroProdutoEmbalagemVenda()"><i
                    class="material-icons">close</i>Sair</button>
                <button class="btn btn-sm btn-outline-success" id="salvar" type="submit" [disabled]="carregando">
                    <div [ngSwitch]="carregando">
                        <div *ngSwitchCase="true">
                            <span class="spinner-border spinner-border-sm" role="status"
                                aria-hidden="true"></span>&nbsp;
                            <span>Carregando ...</span>
                        </div>
                        <div *ngSwitchDefault>
                            <i class="material-icons">done_all</i><span>Salvar</span>
                        </div>
                    </div>
                </button>
            </div>
        </div>
        <div class="card rounded-0" [formGroup]="cadastroForm">
            <div class="card-body pt-1 pb-1">
                <div class="row">
                    <div class="col-sm-12 col-md-8 col-lg-12 col-xl-4">
                        <div class="form-group">
                            <label for="produto_un_id" class="col-form-label-sm">UN</label>
                            <select id="produto_un_id" name="produto_un_id"
                                class="form-control form-control-sm" placeholder="produto_un_id"
                                autocomplete="off" formControlName="produto_un_id"
                                [ngClass]="{'is-invalid': displayMessage.produto_un_id}">
                                <option *ngFor="let item of listaProdutoUn" [ngValue]="item.id">
                                    {{item.un}} - {{ item.descricao }}</option>
                            </select>
                            <span class="text-danger" *ngIf="displayMessage.produto_un_id">
                                <p [innerHTML]="displayMessage.produto_un_id"></p>
                            </span>

                        </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-2" >
                        <div class="form-group">
                            <label for="fator_conversao" class="col-form-label-sm">Fator Conversão</label>
                            <input type="number" id="fator_conversao" name="fator_conversao" class="form-control form-control-sm noscroll"
                                placeholder="Ex: 1" 
                                [ngClass]="{'is-invalid': displayMessage?.fator_conversao}"
                                formControlName="fator_conversao">
                                <span class="text-danger" *ngIf="displayMessage?.fator_conversao">
                                    <p [innerHTML]="displayMessage?.fator_conversao"></p>
                                </span>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-4 col-xl-2" >
                        <div class="form-group">
                            <label for="vlr_venda" class="col-form-label-sm">Vlr Venda</label>
                            <input type="number" id="vlr_venda" name="vlr_venda" class="form-control form-control-sm noscroll"
                                placeholder="0,00" 
                                [ngClass]="{'is-invalid': displayMessage?.vlr_venda}"
                                formControlName="vlr_venda">
                                <span class="text-danger" *ngIf="displayMessage?.vlr_venda">
                                    <p [innerHTML]="displayMessage?.vlr_venda"></p>
                                </span>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-8 col-lg-4 col-xl-4" >
                        <div class="form-group">
                            <label for="codigo_ean" class="col-form-label-sm">Código Barras</label>
                            <input type="text" id="codigo_ean" name="codigo_ean" class="form-control form-control-sm noscroll"
                                placeholder="Ex: 789" 
                                [ngClass]="{'is-invalid': displayMessage?.codigo_ean}"
                                formControlName="codigo_ean">
                                <span class="text-danger" *ngIf="displayMessage?.codigo_ean">
                                    <p [innerHTML]="displayMessage?.codigo_ean"></p>
                                </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</p-dialog>
