import { Injectable, ɵɵresolveBody } from '@angular/core';
import { HttpClient } from '@angular/common/http';


import { URLBASE } from 'src/modules/configuration.service';
import { Observable } from 'rxjs';
import { ListaPaginadaViewModel } from 'src/modules/manager/view-models/ListaPaginadaViewModel';
import { RetornoApiViewModel } from 'src/modules/manager/view-models/RetornoApiViewModel';
import { Uteis } from 'src/modules/utilizarios/uteis';

import { PesquisaGeralViewModel } from '@modules/admin/view-models/pesquisa/PesquisaGeralViewModel';
import { AutenticacaoService } from 'src/modules/autenticacao/services/autenticacao.service';
import { CadastroViewModel } from 'src/modules/admin/view-models/cadastro/CadastroViewModel';
import { ProdutoCestPesquisaViewModel } from 'src/modules/manager/view-models/cadastro/estoque/pesquisa/produto-cest-pesquisa-view-models';
import { ProdutoCestViewModel } from 'src/modules/manager/view-models/cadastro/estoque/produto-cest-view-models';

@Injectable()
export class ProdutoCestService {
    idEmpresaMaxdata = this.autenticacao$.acessoUsuarioValue?.idEmpresaMaxdata;

    urlGet = `${URLBASE}/${this.idEmpresaMaxdata}/produto_cest_service/get`;
    urlAddUpdate = `${URLBASE}/${this.idEmpresaMaxdata}/produto_cest_service/addupdate`;
    urlImportData = `${URLBASE}/${this.idEmpresaMaxdata}/produto_cest_service/importdata`;

    constructor(private http: HttpClient,
        private autenticacao$: AutenticacaoService) { }

    obterTodos(pesquisa: ProdutoCestPesquisaViewModel): Observable<RetornoApiViewModel<ProdutoCestPesquisaViewModel[]>> {
        
        let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel();

        pesquisaGeral.select = 'produto_cest.id, produto_cest.codigo, produto_cest.descricao, produto_cest.ativo';

        pesquisaGeral.calcRowCount = 1;

        pesquisaGeral.limit = pesquisa?.quantidadeRegistrosPagina;

        pesquisaGeral.start = pesquisa?.start || 0; 

        pesquisaGeral.order = pesquisa?.order ? pesquisa?.order : 'codigo';

        pesquisaGeral.where = `and ativo = ${pesquisa?.desativados ? 0 : 1}`;
    
        if (pesquisa?.id) pesquisaGeral.where += ` and id = ${pesquisa?.id}`;
    
        if (pesquisa?.codigo) pesquisaGeral.where += ` and lower(codigo) like lower('%${pesquisa?.codigo}%')`;
    
        if (pesquisa?.descricao) pesquisaGeral.where += ` and lower(descricao) like lower('%${pesquisa?.descricao}%')`;

        return this.http.post<RetornoApiViewModel<ProdutoCestPesquisaViewModel[]>>(this.urlGet, pesquisaGeral)
    }

    obterPorId(id: number): Observable<RetornoApiViewModel<ProdutoCestViewModel[]>> {

        let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel();

        pesquisaGeral.select = 'produto_cest.id, produto_cest.codigo, produto_cest.descricao, produto_cest.ativo';

        pesquisaGeral.where = ` and produto_cest.id = ${id}`;

        return this.http.post<RetornoApiViewModel<ProdutoCestViewModel[]>>(`${this.urlGet}`, pesquisaGeral);
    }

    adicionar(produtoCestViewModel: CadastroViewModel<ProdutoCestViewModel>): Observable<RetornoApiViewModel<ProdutoCestViewModel>> {
        return this.http.post<RetornoApiViewModel<ProdutoCestViewModel>>(this.urlAddUpdate, produtoCestViewModel);
    }

    atualizar(id: number, produtoCestViewModel: CadastroViewModel<ProdutoCestViewModel>): Observable<RetornoApiViewModel<ProdutoCestViewModel>> {
        return this.http.put<RetornoApiViewModel<ProdutoCestViewModel>>(`${this.urlAddUpdate}/${id}`, produtoCestViewModel);
    }

    sincronizar() {
        return this.http.get(this.urlImportData);
    }
}