export class ProdutoSubGrupoViewModel{
    id?: number;
    produto_grupo_id?: number;
    produto_grupo_descricao?: string;
    descricao?: string;
    aliq_comissao?: number;
    foto?: string;
    foto_data?: string;
    ativo?: boolean;
    dt_cadastro?: Date;
}