import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CadastroViewModel } from '@modules/admin/view-models/cadastro/CadastroViewModel';
import { PesquisaGeralViewModel } from '@modules/admin/view-models/pesquisa/PesquisaGeralViewModel';
import { AutenticacaoService } from '@modules/autenticacao/services/autenticacao.service';
import { URLBASE } from '@modules/configuration.service';
import { ProdutoLotePesquisaViewModel } from '@modules/manager/view-models/cadastro/estoque/pesquisa/produto-lote-pesquisa-view-model';
import { ProdutoLoteViewModel } from '@modules/manager/view-models/cadastro/estoque/produto-lote-view-model';
import { RetornoApiViewModel } from '@modules/manager/view-models/RetornoApiViewModel';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProdutoLoteService {

  idEmpresaMaxdata = this.autenticacao$.acessoUsuarioValue?.idEmpresaMaxdata;
  urlApi =  `${URLBASE}/${this.idEmpresaMaxdata}/produto_lote_service`;

  constructor(private http: HttpClient, 
      private autenticacao$: AutenticacaoService){ }

  obterTodos(pesquisa?: ProdutoLotePesquisaViewModel, modalPesquisa?: boolean) : Observable<RetornoApiViewModel<ProdutoLotePesquisaViewModel[]>> {

    let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel()

    modalPesquisa ? pesquisaGeral.select = `
                                          produto_lote.id,
                                          produto_lote.produto_id,
                                          produto_lote.lote,
                                          produto_lote.estoque, 
                                          produto_lote.dt_fabricacao,
                                          produto_lote.dt_vencimento,
                                          produto_lote.prateleira,
                                          produto_lote.ativo` 
                                          : pesquisaGeral.select = 'produto_lote.*';

    pesquisaGeral.calcRowCount = 1;

    pesquisaGeral.limit = pesquisa?.quantidadeRegistrosPagina || 0;

    pesquisaGeral.start = pesquisa?.start || 0;

    pesquisaGeral.order = pesquisa?.order ? pesquisa?.order : 'produto_lote.dt_vencimento'

    pesquisaGeral.where = ` and produto_lote.ativo = ${pesquisa?.desativados ? 0 : 1}`;

    if (pesquisa?.id) pesquisaGeral.where += ` and produto_lote.id in(${pesquisa?.id})`;

    if (pesquisa?.produto_id) pesquisaGeral.where += ` and produto_lote.produto_id in(${pesquisa?.produto_id})`;

    if (pesquisa?.lote) pesquisaGeral.where += ` and lower(produto_lote.lote) like lower('%${pesquisa?.lote}%')`;

    if (pesquisa?.prateleira) pesquisaGeral.where += ` and lower(produto_lote.prateleira) like lower('%${pesquisa?.prateleira}%')`;

    if (pesquisa?.dt_fabricacao_inicial && pesquisa?.dt_fabricacao_final) {

      pesquisaGeral.where += ` and produto_lote.dt_fabricacao between '${pesquisa?.dt_fabricacao_inicial}' and '${pesquisa?.dt_fabricacao_final}'`;

    }

    if (pesquisa?.dt_vencimento_inicial && pesquisa?.dt_vencimento_final) {

      pesquisaGeral.where += ` and produto_lote.dt_vencimento between '${pesquisa?.dt_vencimento_inicial}' and '${pesquisa?.dt_vencimento_final}'`;

    }

    return this.http.post<RetornoApiViewModel<ProdutoLotePesquisaViewModel[]>>(`${this.urlApi}/get`,pesquisaGeral)
  }

  obterPorId(id: number): Observable<RetornoApiViewModel<ProdutoLotePesquisaViewModel[]>>{

    let pesquisaGeralViewModel: PesquisaGeralViewModel = new PesquisaGeralViewModel();

    pesquisaGeralViewModel.select = `produto_lote.*`;

    pesquisaGeralViewModel.where = ` and produto_lote.id = ${id} `;

    return this.http.post<RetornoApiViewModel<ProdutoLotePesquisaViewModel[]>>(`${this.urlApi}/get`,pesquisaGeralViewModel);
  }

  adicionar(produtoGradeViewModel: CadastroViewModel<ProdutoLoteViewModel>) : Observable<RetornoApiViewModel<ProdutoLoteViewModel>>{

      return this.http.post<RetornoApiViewModel<ProdutoLoteViewModel>>(`${this.urlApi}/addupdate`, produtoGradeViewModel);
  }

  atualizar(id: number, produtoGradeViewModel: CadastroViewModel<ProdutoLoteViewModel>) : Observable<RetornoApiViewModel<ProdutoLoteViewModel>>{

      return this.http.put<RetornoApiViewModel<ProdutoLoteViewModel>>(`${this.urlApi}/addupdate/${id}`, produtoGradeViewModel);
  }
}
