export class HistoricoNavegacao{
    constructor(texto: string, link: string = ''){
        this.texto = texto;
        this.ativo = link === undefined || link === null || link === '';
        this.isLink = link !== undefined && link !== null && link !== '';
        this.link = link;
    }
    
    texto: string;
    ativo: boolean;
    isLink: boolean;
    link: string;
}