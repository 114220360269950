import { Injectable } from '@angular/core';
import {PesquisaGeralViewModel} from "@modules/admin/view-models/pesquisa/PesquisaGeralViewModel";
import {URLBASE} from "@modules/configuration.service";
import {HttpClient} from "@angular/common/http";
import {AutenticacaoService} from "@modules/autenticacao/services/autenticacao.service";
import {ConfiguracaoVendaOrcamentoViewModel} from "@manager/view-models/sistema/configuracao-venda-orcamento-view-model";
import {CadastroViewModel} from "@modules/admin/view-models/cadastro/CadastroViewModel";
import {Observable} from "rxjs";
import {RetornoApiViewModel} from "@manager/view-models/RetornoApiViewModel";
import {ConfiguracaoVendaOrcamentoPesquisaViewModel} from "@manager/view-models/sistema/pesquisa/configuracao-venda-orcamento-pesquisa-view-model";

@Injectable({
  providedIn: 'root'
})
export class ConfiguracaoVendaService {

  pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel();

  idEmpresaMaxdata = this.autenticacaoService$.acessoUsuarioValue?.idEmpresaMaxdata;

  urlAPI = `${URLBASE}/${this.idEmpresaMaxdata}/config_venda_service`;

  constructor(
      private http$: HttpClient,
      private autenticacaoService$: AutenticacaoService,
  ) { }

  obterConfiguracao(idEmpresaMaxdata: number): Observable<RetornoApiViewModel<ConfiguracaoVendaOrcamentoViewModel[]>> {

    this.pesquisaGeral.select = `config_venda.*`;

    this.pesquisaGeral.where = ` and config_venda.emp_id = ${idEmpresaMaxdata}`;

    return this.http$.post<RetornoApiViewModel<ConfiguracaoVendaOrcamentoPesquisaViewModel[]>>(`${this.urlAPI}/get`, this.pesquisaGeral);
  }

  adicionar(ConfiguracaoVendaOrcamentoViewModel: CadastroViewModel<ConfiguracaoVendaOrcamentoViewModel>): Observable<RetornoApiViewModel<ConfiguracaoVendaOrcamentoViewModel[]>> {
    return this.http$.post<RetornoApiViewModel<ConfiguracaoVendaOrcamentoViewModel[]>>(`${this.urlAPI}/addupdate`, ConfiguracaoVendaOrcamentoViewModel);
  }

  atualizar(id: number, ConfiguracaoVendaOrcamentoViewModel: CadastroViewModel<ConfiguracaoVendaOrcamentoViewModel>): Observable<RetornoApiViewModel<ConfiguracaoVendaOrcamentoViewModel[]>> {
    return this.http$.put<RetornoApiViewModel<ConfiguracaoVendaOrcamentoViewModel[]>>(`${this.urlAPI}/addupdate/${id}`, ConfiguracaoVendaOrcamentoViewModel);
  }

}
