import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CadastroViewModel } from '@modules/admin/view-models/cadastro/CadastroViewModel';
import { PesquisaGeralViewModel } from '@modules/admin/view-models/pesquisa/PesquisaGeralViewModel';
import { AutenticacaoService } from '@modules/autenticacao/services/autenticacao.service';
import { URLBASE } from '@modules/configuration.service';
import { VeiculoPesquisaViewModel } from '@modules/manager/view-models/cadastro/servico/pesquisa/veiculo-pesquisa-view-model';
import { VeiculoViewModel } from '@modules/manager/view-models/cadastro/servico/veiculo-view-model';
import { RetornoApiViewModel } from '@modules/manager/view-models/RetornoApiViewModel';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VeiculoService {

  idEmpresaMaxdata = this.autenticacao$.acessoUsuarioValue?.idEmpresaMaxdata;

  urlAPI = `${URLBASE}/${this.idEmpresaMaxdata}/veiculo_service`;

  constructor(private http: HttpClient, private autenticacao$: AutenticacaoService) { }
  
  obterTodos(pesquisa: VeiculoPesquisaViewModel): Observable<RetornoApiViewModel<VeiculoPesquisaViewModel[]>> {

    let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel()
    
    pesquisaGeral.select = `veiculo.id,
                        veiculo.descricao,
                        veiculo.placa,
                        veiculo.marca,
                        veiculo.modelo,
                        veiculo.ano_fab,
                        veiculo.ano_mod,
                        veiculo.cor,
                        veiculo.chassi,
                        veiculo.frota,
                        veiculo.renavam,
                        veiculo.uf,
                        veiculo.rntrc,
                        veiculo.ciot,
                        veiculo.tara,
                        veiculo.capacidade_kg,
                        veiculo.capacidade_m3,
                        veiculo.dt_venda,
                        veiculo.dt_cadastro,
                        veiculo.dt_compra,
                        veiculo.numero_nf,
                        veiculo.dados_compra,
                        veiculo.cliente_id,
                        CONCAT(veiculo.cliente_id, ' - ',cliente.nome) as cliente_nome,
                        veiculo.obs,
                        veiculo.ativo`

    pesquisaGeral.calcRowCount = 1;

    pesquisaGeral.limit = pesquisa?.quantidadeRegistrosPagina || 100;

    pesquisaGeral.where = ` and veiculo.ativo = ${pesquisa?.desativados ? 0 : 1 }`;

    pesquisaGeral.start = pesquisa?.start || 0;

    pesquisaGeral.order = pesquisa?.order ? pesquisa?.order : 'veiculo.id';

    if (pesquisa?.id) pesquisaGeral.where += ' and veiculo.id = ' + pesquisa?.id;
    
    if (pesquisa?.placa) pesquisaGeral.where += ` and lower(veiculo.placa) like lower('%` + pesquisa?.placa + `%')`;

    if (pesquisa?.frota) pesquisaGeral.where += ` and lower(veiculo.frota) like lower('%` + pesquisa?.frota + `%')`;

    if (pesquisa?.cliente_id) pesquisaGeral.where += ' and veiculo.cliente_id = ' + pesquisa?.cliente_id;

    if (pesquisa?.cliente_nome) pesquisaGeral.where += ` and lower(cliente.nome) like lower('%` + pesquisa?.cliente_nome + `%')`;

    return this.http.post<RetornoApiViewModel<VeiculoPesquisaViewModel[]>>(`${this.urlAPI}/get`, pesquisaGeral)
  }

  obterPorId(id: number): Observable<RetornoApiViewModel<VeiculoViewModel[]>> {

    let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel()
    
    pesquisaGeral.select = `veiculo.id,
                          veiculo.descricao,
                          veiculo.placa,
                          veiculo.marca,
                          veiculo.modelo,
                          veiculo.ano_fab,
                          veiculo.ano_mod,
                          veiculo.cor,
                          veiculo.chassi,
                          veiculo.frota,
                          veiculo.renavam,
                          veiculo.uf,
                          veiculo.rntrc,
                          veiculo.ciot,
                          veiculo.tara,
                          veiculo.capacidade_kg,
                          veiculo.capacidade_m3,
                          veiculo.dt_venda,
                          veiculo.dt_cadastro,
                          veiculo.dt_compra,
                          veiculo.numero_nf,
                          veiculo.dados_compra,
                          veiculo.cliente_id,
                          CONCAT(veiculo.cliente_id, ' - ',cliente.nome) as cliente_nome,
                          veiculo.obs,
                          veiculo.ativo`

    pesquisaGeral.where = ` and veiculo.id = ${id}`
    
    return this.http.post<RetornoApiViewModel<VeiculoViewModel[]>>(`${this.urlAPI}/get`, pesquisaGeral);
  }

  adicionar(veiculoViewModel: CadastroViewModel<VeiculoViewModel>): Observable<RetornoApiViewModel<VeiculoViewModel[]>> {
    return this.http.post<RetornoApiViewModel<VeiculoViewModel[]>>(`${this.urlAPI}/addupdate`, veiculoViewModel);
  }

  atualizar(id: number, veiculoViewModel: CadastroViewModel<VeiculoViewModel>): Observable<RetornoApiViewModel<VeiculoViewModel>> {
    return this.http.put<RetornoApiViewModel<VeiculoViewModel>>(`${this.urlAPI}/addupdate/${id}`, veiculoViewModel);
  }

}
