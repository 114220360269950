export class CteViewModel {

    id?: number;
    emp_id?: number;
    atendente_id?: number;
    numero?: number;
    serie?: string;
    dt_emissao?: string;
    operacao_id?: number = 0;
    status?: string = 'A';
    lst_modal?: number;
    lst_forma_pagamento?: number;
    lst_finalidade_emissao?: number;
    lst_forma_emissao?: number;
    lst_tipo_servico?: number;
    codigo_ibge_municipio_inicial?: number;
    codigo_ibge_municipio_final?: number;
    lst_tomador_tipo?: number;
    tomador_id?: number;
    remetente_id?: number;    
    destinatario_id?: number;   
    expedidor_id?: number;
    recebedor_id?: number;
    vlr_total_servico?: number;
    vlr_a_receber?: number;
    cst_icms?: string;
    vlr_bc_icms?: number;
    vlr_icms?: number;
    aliq_icms?: number;
    aliq_icms_red?: number;
    vlr_carga?: number;
    qtd_peso?: number;
    qtd_volume?: number;
    modal_rod_rntrc?: string;
    dt_modal_rod_prev_entrega?: string;
    chave_cte?: string;
    cte_lote_id?: number;
    xml_assinado_base_64?: string;
    xml_destinatario_base_64?: string;
    protocolo?: string;
    xml_cancelamento_base_64?: string;
    cancelamento_motivo?: string;
    cancelamento_obs?: string;
    cancelamento_protocolo?: string;
    dt_cancelamento?: string;
    cancelamento_usuario_id?: number;
    xml_inutilizacao_base_64?: string;
    inutilizacao_motivo?: string;
    inutilizacao_obs?: string;
    inutilizacao_protocolo?: string;
    dt_inutilizacao?: string;
    inutilizacao_usuario_id?: number;
    frete_id?: number;
    chk_enviou_email?: number;
    motorista_id?: number;
    placa?: string;
    obs_lanc_fiscal?: string;
    doc_ant_chave?: string;
    doc_ant_cnpj_cpf?: string;
    doc_ant_ie?: string;
    doc_ant_uf?: string;
    doc_ant_nome?: string;
    lst_responsavel_seguro?: number;
    seguradora_id?: number;
    numero_apolice?: string;
    numero_averbacao?: string;
    modal_multi_cotm?: string;
    lst_modal_multi_ind_negociavel?: number;
    inf_cte_anu_chave?: string;
    dt_inf_cte_anu_emissao?: string;
    inf_cte_sub_chave?: string;
    inf_cte_sub_ref_chave_anu?: string;
    inf_cte_sub_toma_icms_ref_chave?: string;
    cce_protocolo?: string;
    cce_sequencial_evento?: number;
    dt_cce_evento?: string;
    cce_lote_id?: number;
    xml_cce_evento_base_64?: string;
    xml_cce_retorno_base_64?: string;
    cce_usuario_id?: number;
    vlr_pis?: number;
    vlr_cofins?: number;
    vlr_inss?: number;
    vlr_ir?: number;
    vlr_csll?: number;
    lst_modelo_df?: number;
    modal_rod_os_nro_reg_estadual?: string;
    inf_cte_comp_chave?: string;
    lst_peso_c_unid?: number;
    chave_ref?: string;

    dt_emissao_inicial?: string;
    dt_emissao_final?: string;

    remetente_nome?: string;
    destinatario_nome?: string;
    expedidor_nome?: string
    recebedor_nome?: string
    tomador_nome?: string
    motorista_nome?: string
    seguradora_nome?: string

    frete_descricao?: string
    endereco_municipio_inicial_descricao?: string
    endereco_municipio_final_descricao?: string
    endereco_uf_inicial_silga?: string
    endereco_uf_final_silga?: string

}