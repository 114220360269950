export class ProdutoGradeViewModel {
    id?: number;
    produto_id?: number;    
    estoque?: number;
    estoque_minimo?: number;
    produto_grade_descricao_id?: number;    
    produto_grade_tamanho_id?: number;
    codigo_barras?: string;
    vlr_venda?: number;
    ativo?: boolean;
}
