import { PaginacaoViewModel } from '@modules/manager/view-models/PaginacaoViewModel';
import { ProdutoEmbalagemVendaViewModel } from '../produto-embalagem-venda-view-model';
export class ProdutoEmbalagemVendaPesquisaViewModel extends ProdutoEmbalagemVendaViewModel implements PaginacaoViewModel {
    pagina: number = 1;
    quantidadeRegistrosPagina: number = 20;
    totalRegistros: number = 0;
    totalPaginas: number = 0;
    desativados: boolean = false;
    start?: number = 0;
    order?: string;
}