export class EtiquetaModeloViewModel {

    constructor(nomeRelatorio: string, descricao: string) {
        this.modelo = nomeRelatorio;
        this.descricao = descricao;
    }

    modelo: string;
    descricao: string;

    static etiquetaModeloa4 = [
        new EtiquetaModeloViewModel('etiqueta_a4_2colunas_70x97mm', '2 Colunas 70 X 97mm'),
    ]

    static etiquetaModeloBobina = [
        new EtiquetaModeloViewModel('etiqueta_bobina_1coluna_joia_75x13mm_alca_esquerda', '1 Coluna - Jóia BOOP 75 X 13 mm (alça esquerda)'),
        new EtiquetaModeloViewModel('etiqueta_bobina_1coluna_100x100mm', '1 Coluna - 100 X 100 mm'),
        new EtiquetaModeloViewModel('etiqueta_bobina_3colunas_33x60mm', '3 Colunas - 33 X 60 mm'),
        new EtiquetaModeloViewModel('etiqueta_bobina_3colunas_33x21mm', '3 Colunas - 33 X 21 mm'),
        new EtiquetaModeloViewModel('etiqueta_bobina_3colunas_33x21mm_mod2', '3 Colunas - 33 X 21 mm Sem Cód. Barras'),
    ];
}