import { Component, Inject, OnInit, Output } from '@angular/core';
import { UsuarioEmpresaPesquisaViewModel } from '@modules/admin/view-models/pesquisa/UsuarioEmpresaPesquisaViewModel';
import { UsuarioEmpresaService } from './usuario-empresa.service';
import { RetornoApiViewModel } from '@modules/manager/view-models/RetornoApiViewModel';
import { ListaPaginadaViewModel } from '@modules/manager/view-models/ListaPaginadaViewModel';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UsuarioEmpresaViewModel } from '@modules/admin/view-models/cadastro/UsuarioEmpresaViewModel';
import { CadastroViewModel } from '@modules/admin/view-models/cadastro/CadastroViewModel';
import { UsuarioViewModel } from '@modules/admin/view-models/cadastro/UsuarioViewModel';

@Component({
  selector: 'app-usuario-empresa',
  templateUrl: './usuario-empresa.component.html',
  styles: [
  ]
})
export class UsuarioEmpresaComponent implements OnInit {
  usuarioEmpresaRemover: UsuarioEmpresaViewModel = new UsuarioEmpresaViewModel();
  exibirModalConfirmacao: boolean = false;
  objUsuario: CadastroViewModel<UsuarioViewModel> = new CadastroViewModel<UsuarioViewModel>();
  objUsuarioEmpresa: CadastroViewModel<UsuarioEmpresaViewModel> = new CadastroViewModel<UsuarioEmpresaViewModel>();
 pesquisa: UsuarioEmpresaPesquisaViewModel = new UsuarioEmpresaPesquisaViewModel();
  listaUsuarios: ListaPaginadaViewModel<UsuarioEmpresaPesquisaViewModel> = new ListaPaginadaViewModel<UsuarioEmpresaPesquisaViewModel>();
  @Output() id_empresa: number = 7;
  constructor(
    private usuarioEmpresaService: UsuarioEmpresaService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.obterListaUsuarios()
  }


  obterListaUsuarios() {
   
    this.pesquisa.id_empresa = this.data.data.id
    this.pesquisa.quantidadeRegistrosPagina = 10
   

    this.usuarioEmpresaService.obterTodosUsarSistema(this.pesquisa).subscribe(
      (retorno: RetornoApiViewModel<UsuarioEmpresaPesquisaViewModel[]>) => {
        this.listaUsuarios.itens = retorno.total > 0 ? retorno.result : [];
        this.listaUsuarios.paginacao.totalRegistros = retorno.total;

        this.listaUsuarios.paginacao.quantidadeRegistrosPagina = this.pesquisa.quantidadeRegistrosPagina;
  
      },
      (erro) => {
        console.error('Erro ao obter a lista de usuários', erro);
      }
    );
  }


  confirmarExclusao(item: any): void {
   
    this.usuarioEmpresaRemover.descricao = item.empresa_descricao;
    this.objUsuario.obj = item;
    this.exibirModalConfirmacao = true;
    // Armazene o item que será excluído em uma propriedade do componente, se necessário
    this.objUsuarioEmpresa.obj = item;
    
  }
  
  
  

  removerUsuario(item: UsuarioEmpresaViewModel): void {

  this.objUsuarioEmpresa.obj = item;
    

    this.usuarioEmpresaService.removerUsuarioEmpresa(this.objUsuarioEmpresa.obj?.id, this.objUsuarioEmpresa).subscribe(
      (retorno: RetornoApiViewModel<any>) => {
        // Lógica para tratar o retorno da remoção
     
        this.obterListaUsuarios();
        // Realize qualquer outra ação necessária após a remoção
      },
      (error: any) => {
        // Lógica para tratar erros durante a remoção
        console.error('Erro ao remover usuário', error);
        // Realize qualquer outra ação de tratamento de erro necessária
      }
    );
  }
  


  mudarPagina(event: any) {

    this.pesquisa.start = (event.page - 1) * this.pesquisa.quantidadeRegistrosPagina;
    this.pesquisa.pagina= event.page;
    this.obterListaUsuarios();
  }

}
