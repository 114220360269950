import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CadastroViewModel } from '@modules/admin/view-models/cadastro/CadastroViewModel';
import { AlertMessage } from '@modules/alert.configuration.service';
import { ProdutoCestService } from '@modules/manager/cadastro/estoque/produto-cest/produto-cest.service';
import { ProdutoNcmCestService } from '@modules/manager/cadastro/estoque/produto-ncm-cest/produto-ncm-cest.service';
import { ProdutoCestPesquisaViewModel } from '@modules/manager/view-models/cadastro/estoque/pesquisa/produto-cest-pesquisa-view-models';
import { ProdutoCestViewModel } from '@modules/manager/view-models/cadastro/estoque/produto-cest-view-models';
import { ProdutoNcmCestViewModel } from '@modules/manager/view-models/cadastro/estoque/produto-ncm-cest-view-model';
import { ListaPaginadaViewModel } from '@modules/manager/view-models/ListaPaginadaViewModel';
import { AlertMensagemPadrao } from '@modules/utilizarios/alertMensagemPadrao';
import { QuantidadeItensPaginacao } from '@modules/utilizarios/quantidadeItensPaginacao';
import { Uteis } from '@modules/utilizarios/uteis';
import { DisplayMessage, GenericValidator, ValidationMessages } from '@modules/validacao/generic-form-validation';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-produto-ncm-cest-cadastro-modal',
  templateUrl: './produto-ncm-cest-cadastro-modal.component.html',
  styles: [
  ]
})
export class ProdutoNcmCestCadastroModalComponent implements OnInit {
  @Input() produtoNcmCest!: ProdutoNcmCestViewModel;
  @Input() alterar: boolean = false;
  @Input() produtoNcmId: number;
  @Input() abrirFecharProdutoNcmCestCadastroModal: boolean = false;
  @Output() closeModal = new EventEmitter();

  cadastroForm!: FormGroup;
  validationMessages?: ValidationMessages;
  genericValidator?: GenericValidator;
  displayMessage: DisplayMessage = {};
  carregando: boolean = false;
  objProdutoNcmCest: CadastroViewModel<ProdutoNcmCestViewModel> = new CadastroViewModel<ProdutoNcmCestViewModel>();
  dataSourceAutocomplete: Observable<any>
  noResult: boolean = false;
  pesquisaProdutoCest: ProdutoCestPesquisaViewModel = new ProdutoCestPesquisaViewModel();
  pesquisa: ProdutoCestPesquisaViewModel = new ProdutoCestPesquisaViewModel();
  listaPaginada: ListaPaginadaViewModel<ProdutoCestViewModel> = new ListaPaginadaViewModel<ProdutoCestViewModel>();
  listaItensPaginacao: QuantidadeItensPaginacao[] = QuantidadeItensPaginacao.listaQuantidades;
  selectedRowIndex: number = -1 ;
  constructor(
    private alert: AlertMessage,    
    private spinner: NgxSpinnerService,
    private produtoNcmCest$: ProdutoNcmCestService,
    private produtoCest$: ProdutoCestService) {
     }

  ngOnInit(): void {

    this.pesquisar();
    
  }

  adicionar(id: number) {

    this.objProdutoNcmCest.obj = Object.assign({ id: 0, produto_cest_id: id, produto_ncm_id: this.produtoNcmId, padrao: 0 }); 

    if(this.objProdutoNcmCest.obj.produto_cest_id > 0 && this.objProdutoNcmCest.obj.produto_ncm_id > 0) {

      this.produtoNcmCest$.adicionar(this.objProdutoNcmCest).subscribe(
      retorno => {

        this.alert.Success('Sucesso', 'CEST vinculado ao NCM com sucesso!');

        this.fecharModalCadastroProdutoNcmCest();

        this.objProdutoNcmCest = new CadastroViewModel<ProdutoNcmCestViewModel>();

        this.carregando = false;
      },
      error => {

        this.carregando = false;

        this.alert.Danger('Erro', Uteis.ObterErroApi(error))

      }
    )

    }
  }

  pesquisar() {

    if (this.pesquisa === undefined) this.pesquisa = new ProdutoCestPesquisaViewModel();

    this.spinner.show();

    this.produtoCest$.obterTodos(this.pesquisa).subscribe(
      retorno => {
        
        this.listaPaginada.itens = retorno.total > 0 ? retorno.result : [];

        this.listaPaginada.paginacao.totalRegistros = retorno.total;

        this.listaPaginada.paginacao.quantidadeRegistrosPagina = this.pesquisa.quantidadeRegistrosPagina;

        this.selectedRowIndex = 0;

        this.spinner.hide();
      },
        error => { 
          
          this.spinner.hide(); 
          
          this.alert.Danger('Erro', Uteis.ObterErroApi(error)); 

          this.selectedRowIndex = -1;
        });
  }

  ordenarLista(campo: string) {
    this.pesquisa.order = campo;

    this.pesquisar();
  }

  fecharModalCadastroProdutoNcmCest() {
    this.closeModal.emit(false);
  }

  clickRowIndex(index: number){
    this.selectedRowIndex = index;
  }

}