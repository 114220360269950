<div class="row">
    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-5 col-xl-1">
        <div class="form-group">
            <label for="produto_lote_lote" class="col-form-label-sm">Lote</label>
            <input id="produto_lote_lote" name="produto_lote_lote" class="form-control form-control-sm"
                    [(ngModel)]="pesquisa.lote"/>
        </div>
    </div>
    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-5 col-xl-3">
        <div class="form-group">
            <label for="dts" class="col-form-label-sm"><a href="javascript:void(0);"
                                                          (click)="pesquisa.adicionarDataFabricacao()">Fabricação</a></label>
            <div class="input-group">
                <input type="date" id="dt_fabricacao_inicial" name="dt_fabricacao_inicial"
                       class="form-control form-control-sm mr-1"
                       [(ngModel)]="pesquisa.dt_fabricacao_inicial">
                <span class="mt-1">a</span>
                <input type="date" id="dt_fabricacao_final" name="dt_fabricacao_final"
                       class="form-control form-control-sm ml-1"
                       [(ngModel)]="pesquisa.dt_fabricacao_final">
                <div class="input-group-append">
                    <button class="btn btn-sm btn-maxdata" type="button"
                            id="remover_dt_ultima_compra"
                            [disabled]="!pesquisa.dt_fabricacao_inicial && !pesquisa.dt_fabricacao_final"
                            (click)="pesquisa.limparDataFabricacao()">
                        <i class="material-icons mr-0">remove</i>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-5 col-xl-3">
        <div class="form-group">
            <label for="dts" class="col-form-label-sm"><a href="javascript:void(0);"
                                                          (click)="pesquisa.adicionarDataVencimento()">Vencimento</a></label>
            <div class="input-group">
                <input type="date" id="dt_vencimento_inicial" name="dt_vencimento_inicial"
                       class="form-control form-control-sm mr-1"
                       [(ngModel)]="pesquisa.dt_vencimento_inicial">
                <span class="mt-1">a</span>
                <input type="date" id="dt_vencimento_final" name="dt_vencimento_final"
                       class="form-control form-control-sm ml-1"
                       [(ngModel)]="pesquisa.dt_vencimento_final">
                <div class="input-group-append">
                    <button class="btn btn-sm btn-maxdata" type="button"
                            id="remover_dt_ultima_compra"
                            [disabled]="!pesquisa.dt_vencimento_inicial && !pesquisa.dt_vencimento_final"
                            (click)="pesquisa.limparDataVencimento()">
                        <i class="material-icons mr-0">remove</i>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-5 col-xl-1">
        <div class="form-group">
            <label for="produto_lote_prateleira" class="col-form-label-sm">Prateleira</label>
            <input id="produto_lote_prateleira" name="produto_lote_prateleira" class="form-control form-control-sm"
                    [(ngModel)]="pesquisa.prateleira"/>
        </div>
    </div>
    <div class="col-sm-4 col-md-6 col-lg-3 col-xl-1" *ngIf="!modalPesquisa">
        <div class="form-group">
            <div class="custom-control custom-checkbox">
                <input type="checkbox" id="produto_lote_ativo" name="produto_lote_ativo" class="custom-control-input form-control-sm"
                    [(ngModel)]="pesquisa.desativados" />
                <label class="custom-control-label col-form-label-sm status-filtro"
                    for="produto_lote_ativo">Desativado</label>
            </div>
        </div>
    </div>
    <div class="col-xs-12 col-sm-2 col-md-3 col-lg-4 col-xl-3 mt-4">
        <div class="text-right btns-group">
            <button type="button" class="btn btn-sm btn-outline-secondary" (click)="abrirModalProdutoLote()" *ngIf="modalPesquisa">
                Cancelar</button>
            <button type="button" class="btn btn-sm btn-outline-info" (click)="obterProdutoLote()">
                <i class="material-icons">search</i> Pesquisar
            </button>
            <button type="button" class="btn btn-sm btn-outline-success" (click)="abrirModalProdutoLote()" *ngIf="!modalPesquisa"><i class="material-icons">add</i>Novo</button>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div class="my-custom-scrollbar table-wrapper-scroll-y">
            <table class="table table-hover table-borderless table-striped table-sm " width="100%" id="table" >
                <thead class="thead">
                    <tr>
                        <th>Lote</th>
                        <th>Estoque</th>
                        <th>Fabricação</th>
                        <th>Vencimento</th>
                        <th *ngIf="!modalPesquisa">Vlr Custo</th>
                        <th>Prateleira</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="cursor" 
                    *ngFor="let item of produtoLote; let i = index;" 
                    (click)="selecionarIndex(i);"
                    [ngClass]="{'selected-line': (indexSelecionado || 0) === i }">
                        <td class="text-nowrap pr-5">{{item.lote}}</td>
                        <td class="text-nowrap pr-2">{{item.estoque}}</td>
                        <td class="text-nowrap pr-2">{{item.dt_fabricacao |date: 'dd/MM/yyyy'}}</td>
                        <td class="text-nowrap pr-2">{{item.dt_vencimento |date: 'dd/MM/yyyy'}}</td>
                        <td class="text-nowrap pr-2" *ngIf="!modalPesquisa">{{item.vlr_custo | currency: 'BRL':'R$':'1.2-2'}}</td>
                        <td class="text-nowrap pr-2">{{item.prateleira}}</td>
                        <td class="text-nowrap pr-2" (click)="abrirModalProdutoLote(item)">
                            <i class="material-icons" >{{modalPesquisa ? "done_all" : "edit"}}</i>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>