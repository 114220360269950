import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CadastroViewModel } from '@modules/admin/view-models/cadastro/CadastroViewModel';
import { AlertMessage } from '@modules/alert.configuration.service';
import { CategoriaService } from '@modules/manager/cadastro/ecommerce/categoria/categoria.service';
import { SubcategoriaService } from '@modules/manager/cadastro/ecommerce/subcategoria/subcategoria.service';
import { EcommerceCategoriaViewModel } from '@modules/manager/view-models/cadastro/ecommerce/ecommerce-categoria-view-model';
import { EcommerceSubcategoriaViewModel } from '@modules/manager/view-models/cadastro/ecommerce/ecommerce-subcategoria-view-model';
import { EcommerceCategoriaPesquisaViewModel } from '@modules/manager/view-models/cadastro/ecommerce/pesquisa/ecommerce-categoria-pesquisa-view-model';
import { ListaPaginadaViewModel } from '@modules/manager/view-models/ListaPaginadaViewModel';
import { AlertMensagemPadrao } from '@modules/utilizarios/alertMensagemPadrao';
import { Uteis } from '@modules/utilizarios/uteis';
import { DisplayMessage, GenericValidator, ValidationMessages } from '@modules/validacao/generic-form-validation';
import { MensagensValidacao } from '@modules/validacao/mensagensValidacao';

import { NgxSpinnerService } from 'ngx-spinner';


@Component({
    selector: 'app-produto-sub-categoria-modal',
    templateUrl: './produto-sub-categoria-modal.component.html',
    styles: [
    ]
})


export default class ProdutoSubCategoriaModalComponent {
    @Input() alterar: boolean = false;
    @Input() ProdutoSubCategoriaId: number;
    @Input() abrirFecharProdutoSubCategoriaModal: boolean = false;
    @Output() closeModal = new EventEmitter();

    listaCategoria: ListaPaginadaViewModel<EcommerceCategoriaViewModel> =
    new ListaPaginadaViewModel<EcommerceCategoriaViewModel>(); // ListaCategoria
    pesquisaCategoria: EcommerceCategoriaPesquisaViewModel =
    new EcommerceCategoriaPesquisaViewModel(); // PesquisaCategoria
    objProdutoSubCategoria: EcommerceSubcategoriaViewModel = new EcommerceSubcategoriaViewModel()
    cadastroForm!: FormGroup;
    validationMessages?: ValidationMessages;
    genericValidator?: GenericValidator;
    displayMessage: DisplayMessage = {};

    constructor(
        private fb: FormBuilder,
        private alert: AlertMessage,
        private subcategoria: SubcategoriaService,
        private categoria$: CategoriaService,

        private spinner: NgxSpinnerService) {

    };

    ngOnInit(): void {
        this.criarFormulario()
        this.messagesValidations()
        this.obterListaCategoria()
    };

    submit() {
        this.cadastroForm.markAllAsTouched();

        if (!this.cadastroForm.valid) {

            if (typeof this.genericValidator !== 'undefined') {

                this.displayMessage = this.genericValidator.processarMensagens(this.cadastroForm);

            }

            let mensagem = Uteis.ConverterObjetoToArray(this.displayMessage)

            return this.mensagemCamposInvalidos(mensagem)
        }

        if (!this.cadastroForm.dirty) {

            this.alert.Info(AlertMensagemPadrao.formularioNaoAlterado.titulo, AlertMensagemPadrao.formularioNaoAlterado.mensagem);

            return
        }

        this.atualizarObj()

        if (this.alterar) this.atualizar();
        else this.adicionar()
    }


    adicionar() {
        this.spinner.show();

        const cad = new CadastroViewModel<EcommerceCategoriaViewModel>();

        cad.obj = this.objProdutoSubCategoria;

        this.subcategoria.adicionar(cad).subscribe(
            retorno => {
                this.mensagensCadastro()
                this.spinner.hide();
                this.closeModal.emit(true)
            },
            erro => {
                this.spinner.hide()
            }
        );
    }

    atualizarObj() {
        this.objProdutoSubCategoria = Object.assign(this.objProdutoSubCategoria, this.cadastroForm.value)
    }

    atualizar() {
        this.spinner.show();

        const cad = new CadastroViewModel<EcommerceCategoriaViewModel>();

        cad.obj = this.objProdutoSubCategoria;

        this.subcategoria.atualizar(this.objProdutoSubCategoria?.id, cad).subscribe(
            retorno => {
                this.mensagensCadastro()
                this.spinner.hide()
                this.closeModal.emit(true)
            },
            erro => {
                this.spinner.hide()
            }
        );
    }


    obterListaCategoria() {
        this.pesquisaCategoria = Object.assign({ quantidadeRegistrosPagina: 0 });
    
        this.categoria$.obterTodos(this.pesquisaCategoria).subscribe(
          (retorno) => {
            this.listaCategoria.itens = retorno.total > 0 ? retorno.result : [];
    
            this.listaCategoria.paginacao.totalRegistros = retorno.total;
    
            this.listaCategoria.paginacao.quantidadeRegistrosPagina = 999;
          },
          (err) => this.alert.Danger("Categoria", Uteis.ObterErroApi(err))
        );
      }

    mensagensValidator() {
        this.displayMessage = this.genericValidator.processarMensagens(this.cadastroForm);
    };

    private mensagensCadastro(): void {
        this.alert.Success('Sucesso', `Registro ${this.alterar ? 'atualizado' : 'cadastrado'} com Sucesso.`);
    }

    mensagemCamposInvalidos(mensagens: any) {
        this.alert.Warning(AlertMensagemPadrao.formularioInvalido.titulo, 'Todos os campos são obrigatorios!');
    }

    closeModalProdutoSubCategoria() {
        this.closeModal.emit(false);
    };

    messagesValidations() {
        this.validationMessages = {
            descricao: {
                required: MensagensValidacao.Obrigatorio('Descrição'),
                minlength: MensagensValidacao.MinimoCaracteres('Descricão', 5),
            },
            nome: {
                required: MensagensValidacao.Obrigatorio('Nome'),
                minlength: MensagensValidacao.MinimoCaracteres('Nome', 3),
            },
            palavra_chave: {
                required: MensagensValidacao.Obrigatorio('Palavra chave'),
                minlength: MensagensValidacao.MinimoCaracteres('Palavra chave', 2),
            },
            ecommerce_categoria_id: {
                required: MensagensValidacao.Obrigatorio('Categoria'),
            }
        };
        this.genericValidator = new GenericValidator(this.validationMessages);
    };

    criarFormulario() {
        this.cadastroForm = this.fb.group({
            id: [{ value: !this.alterar ? null : this.objProdutoSubCategoria?.id, disabled: true }],
            nome: [!this.alterar ? null : this.objProdutoSubCategoria?.nome, [Validators.required, Validators.minLength(3)]],
            descricao: [!this.alterar ? null : this.objProdutoSubCategoria?.descricao, [Validators.required, Validators.minLength(5)]],
            palavra_chave: [!this.alterar ? null : this.objProdutoSubCategoria?.palavra_chave, [Validators.required, Validators.minLength(3)]],
            chk_visivel: [true],
            ativo: [!this.alterar ? null : this.objProdutoSubCategoria?.ativo],
            ecommerce_categoria_id: [!this.alterar ? null : this.objProdutoSubCategoria?.ecommerce_categoria_id, [Validators.required]]
        });
    };
};