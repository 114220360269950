import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomFormsModule } from 'ng2-validation';
import { AdminRoutingModule } from './admin.routes';

import { NgBrazil } from 'ng-brazil';
import { TextMaskModule } from 'angular2-text-mask';
import { TituloPaginaModule } from '../components/titulo-paginas/titulo-paginas.module';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { ToastrModule } from 'ngx-toastr';

import { InicioComponent } from './inicio/inicio.component';
import { EmpresaComponent } from './cadastro/empresa/empresa.component';
import { EmpresaCadastroComponent } from './cadastro/empresa/empresa-cadastro/empresa-cadastro.component';
import { UsuarioComponent } from './cadastro/usuario/usuario.component';
import { UsuarioCadastroComponent } from './cadastro/usuario/usuario-cadastro/usuario-cadastro.component';

import { EmpresaService } from './cadastro/empresa/empresa.service';
import { UsuarioService } from './cadastro/usuario/usuario.service';
import { UsuarioEmpresaService } from './cadastro/usuario-empresa/usuario-empresa.service';
import { AlertMessage } from '../alert.configuration.service';

import { TabViewModule } from 'primeng/tabview';
import { SistemaMenuComponent } from "./cadastro/sistema-menu/sistema-menu.component";
import { DialogModule } from 'primeng/dialog';
import { Template } from "@angular/compiler/src/render3/r3_ast";
import { TemplatesModule } from "@modules/components/templates/templates.module";
import { UsuarioEmpresaComponent } from './cadastro/usuario-empresa/usuario-empresa.component';
import { MAT_DIALOG_DATA } from "@angular/material/dialog";


@NgModule({
    declarations:[
        EmpresaComponent,
        InicioComponent,
        EmpresaCadastroComponent,
        UsuarioComponent,
        UsuarioCadastroComponent,
        SistemaMenuComponent,
        UsuarioEmpresaComponent,
    ],
    imports:[
        CommonModule,
        AdminRoutingModule,
        TituloPaginaModule,
        FormsModule,
        ReactiveFormsModule,
        CustomFormsModule,
        PaginationModule.forRoot(),
        NgBrazil,
        TextMaskModule,
        TabsModule.forRoot(),
        // BrowserAnimationsModule,
        CollapseModule.forRoot(),
        ModalModule.forRoot(),
        TypeaheadModule.forRoot(),
        ToastrModule.forRoot(), // ToastrModule added
        TabViewModule,
        DialogModule,
        TemplatesModule
    ],
    exports:[],
    providers:[
        EmpresaService,
        UsuarioService,
        SistemaMenuComponent,
        BsModalService,
        UsuarioEmpresaService,
        AlertMessage,
        { provide: MAT_DIALOG_DATA, useValue: {} }
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA // Tells Angular we will have custom tags in our templates
    ]
})
export class AdminModule{}