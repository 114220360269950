import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { URLBASEADMIN } from '@modules/admin/configuration.admin.service';
import { CadastroViewModel } from '@modules/admin/view-models/cadastro/CadastroViewModel';
import { PesquisaGeralViewModel } from '@modules/admin/view-models/pesquisa/PesquisaGeralViewModel';
import { SistemaMenuViewModel } from '@modules/admin/view-models/cadastro/SistemaMenuViewModel';
import { UsuarioManagerPermissaoPesquisaViewModel } from '@modules/manager/view-models/cadastro/usuario-manager/pesquisa/usuario-manager-permissao-pesquisa-view-model';
import { RetornoApiViewModel } from '@modules/manager/view-models/RetornoApiViewModel';
import { Observable } from 'rxjs';

const urlGet = `${URLBASEADMIN}/sistema_menu_adm_service/get`;
const urlAddUpdate  = `${URLBASEADMIN}/sistema_menu_adm_service/addupdate`;
const urlDelete  = `${URLBASEADMIN}/sistema_menu_adm_service/delete`;

@Injectable({
  providedIn: 'root'
})
export class SistemaMenuService {
  constructor(private http: HttpClient){}

  obterMenuCompleto(pesquisa: SistemaMenuViewModel): Observable<RetornoApiViewModel<any[]>> {

    let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel()

    pesquisaGeral.select = `sistema_menu_adm.*`;

    pesquisaGeral.limit = 100;

    pesquisaGeral.start =  0;

    pesquisaGeral.order = 'lst_grupo_menu';

    if(pesquisa?.id) pesquisaGeral.where += ` AND sistema_menu_adm.id = ${pesquisa?.id}`;

    if(pesquisa?.lst_grupo_menu != 0) pesquisaGeral.where += ` AND sistema_menu_adm.lst_grupo_menu = ${pesquisa?.lst_grupo_menu}`;
        
    return this.http.post<RetornoApiViewModel<any[]>>(`${urlGet}`, pesquisaGeral);
  }

  obterTodos(pesquisa: UsuarioManagerPermissaoPesquisaViewModel): Observable<RetornoApiViewModel<UsuarioManagerPermissaoPesquisaViewModel[]>> {

    let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel()

    pesquisaGeral.select = `sistema_menu_adm.id, sistema_menu_adm.id_menu, sistema_menu_adm.descricao, sistema_menu_adm.lst_grupo_menu`;

    pesquisaGeral.limit = pesquisa?.quantidadeRegistrosPagina || 100;

    pesquisaGeral.start = pesquisa?.start || 0;

    pesquisaGeral.order = pesquisa?.order ? pesquisa?.order : 'descricao' 
    
    return this.http.post<RetornoApiViewModel<UsuarioManagerPermissaoPesquisaViewModel[]>>(`${urlGet}`, pesquisaGeral);
  }

  obterPorId(id: number): Observable<RetornoApiViewModel<SistemaMenuViewModel[]>>{

    let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel()

    pesquisaGeral.select = `sistema_menu_adm.id, sistema_menu_adm.id_menu, sistema_menu_adm.descricao, sistema_menu_adm.lst_grupo_menu`;

    pesquisaGeral.where = ` and sistema_menu_adm.id = ${id}`;

    return this.http.post<RetornoApiViewModel<SistemaMenuViewModel[]>>(urlGet,pesquisaGeral);
  }

  adicionar(objCadastro: CadastroViewModel<SistemaMenuViewModel>) : Observable<RetornoApiViewModel<SistemaMenuViewModel>>{
      return this.http.post<RetornoApiViewModel<SistemaMenuViewModel>>(`${urlAddUpdate}`, objCadastro);
  }

  atualizar(id: number, objCadastro: CadastroViewModel<SistemaMenuViewModel>) : Observable<RetornoApiViewModel<SistemaMenuViewModel>>{
      return this.http.put<RetornoApiViewModel<SistemaMenuViewModel>>(`${urlAddUpdate}/${id}`, objCadastro);
  }
  remover(id: number, objCadastro) {
    return this.http.request('delete',`${urlDelete}/${id}`, {body: objCadastro});
  }

}
