<p-dialog [(visible)]="abrirFecharModalMunicipio" [modal]="true" [closable]="false" [style]="{width: '100vw'}"
    [maximizable]="true" [baseZIndex]="10000" [draggable]="false" [resizable]="false">
    <ngx-spinner name="modalAtualiza" bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-clip-rotate"
        [fullScreen]="false"></ngx-spinner>
    <p-header>Pesquisa de Municípios</p-header>
    <div class="row float-right">
        <button class="btn btn-sm btn-outline-info" type="button" (click)="pesquisar()"><i
                class="material-icons">search</i>Pesquisar</button>
    </div>
    <div class="row pl-0">
        <div class="col-xs-12 col-sm-4 col-md-3 col-lg-2 col-xl-1">
            <div class="form-group">
                <label for="id" class="col-form-label-sm">Código</label>
                <input type="number" id="id" name="id" class="form-control form-control-sm noscroll"
                placeholder="Código" [(ngModel)]="pesquisa.id" (keydown.enter)="pesquisar()">
            </div>
        </div>
        <div class="col-xs-12 col-sm-4 col-md-3 col-lg-2 col-xl-1">
            <div class="form-group">
                <label for="ibge" class="col-form-label-sm">Código IBGE</label>
                <input type="number" id="ibge" name="ibge" class="form-control form-control-sm noscroll"
                placeholder="Código" [(ngModel)]="pesquisa.codigo_ibge" (keydown.enter)="pesquisar()">
            </div>
        </div>
        <div class="col-xs-12 col-sm-8 col-md-6 col-lg-5 col-xl-4">
            <div class="form-group">
                <label for="descricao" class="col-form-label-sm">Descrição</label>
                <input type="text" id="descricao" name="descricao" class="form-control form-control-sm"
                    placeholder="Pesquise pela Descricao" [(ngModel)]="pesquisa.descricao" (keydown.enter)="pesquisar()" autofocus >
            </div>
        </div>

    </div>
    <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div class="my-custom-scrollbar table-wrapper-scroll-y">
                <table class="table table-hover table-borderless table-striped table-sm " width="100%">
                    <thead class="thead">
                        <tr>
                            <th>Código</th>
                            <th>Descrição</th>
                            <th>Estado</th>
                            <th>Código IBGE</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="cursor" *ngFor="let item of listaMunicipios" (click)="municipioSlecionado(item)">
                            <td>{{item.id}}</td>
                            <td>{{item.descricao}}</td>
                            <td>{{item.endereco_uf_descricao}}</td>
                            <td>{{item.codigo_ibge}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <button class="btn btn-sm btn-outline-secondary" (click)="closeModalMunicipio()">Cancelar</button>
    </ng-template>
</p-dialog>
