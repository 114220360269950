import { PaginacaoViewModel } from "../../PaginacaoViewModel";
import { AgendamentoViewModel } from "../agendamento-view-model";

export class AgendamentoPesquisaViewModel extends AgendamentoViewModel implements PaginacaoViewModel {
    pagina: number = 1;
    quantidadeRegistrosPagina: number = 20;
    totalRegistros: number = 0;
    totalPaginas: number = 0;
    desativados: boolean = false;
    start?: number = 0;
    order?: string;
    cliente_nome?: string;
    dt_inicial_inicio?: string;
    dt_inicial_final?: string;

}