<p-dialog [(visible)]="abrirFecharAgendamentoModal" [modal]="true" [closable]="true" [style]="{width: '100vw'}"
    [maximizable]="true" [baseZIndex]="10000" [draggable]="false" [resizable]="false" (onHide)="closeModalAgendamento()">
    <ngx-spinner name="modalAtualiza" bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-clip-rotate"
        [fullScreen]="false"></ngx-spinner>
    <p-header>Agendamento</p-header>


    <form (ngSubmit)="onSubmit()"  [formGroup]="cadastroForm" (keydown.enter)="$event.preventDefault()">
        <div class="d-flex justify-content-end">
            <div class="btns-group mb-1">
                <!-- <button class="btn btn-sm btn-outline-secondary" id="processar" type="button"
                    (click)="openModalEmpresaFiltroRef(modalEmpresaFiltroRef)" *ngIf="alterar"><i
                        class="material-icons">toc</i>Empresas</button> -->
                        <button class="btn btn-sm btn-outline-secondary" (click)="closeModalAgendamento()"><i
                            class="material-icons">close</i>Cancelar</button>
                <button class="btn btn-sm btn-outline-success" id="salvar" type="submit" [disabled]="carregando">
                    <div [ngSwitch]="carregando">
                        <div *ngSwitchCase="true">
                            <span class="spinner-border spinner-border-sm" role="status"
                                aria-hidden="true"></span>&nbsp;
                            <span>Carregando ...</span>
                        </div>
                        <div *ngSwitchDefault>
                            <i class="material-icons">done_all</i><span>Salvar</span>
                        </div>
                    </div>
                </button>
            </div>
        </div>
        <!-- <p-tabView>
            <p-tabPanel header="Produto Grade Descrição" class="first"  > -->
                <div class="card rounded-0">
                    <div class="card-body pt-1 pb-1">
                        <div class="row">
                            <div class="col-sm-8 col-md-6 col-lg-4 col-xl-4" formGroupName="agendamento">
                                <div class="form-group">
                                    <label for="cliente_nome" class="col-form-label-sm">Nome do Cliente</label>
                                    <input type="text" id="cliente_nome" name="cliente_nome"
                                        class="form-control form-control-sm" placeholder="Pesquise pelo Nome"
                                        formControlName="cliente_nome"
                                        [ngClass]="{'is-invalid': displayMessage.cliente_id}"
                                        (keydown)="abrirFecharModalCliente = true"
                                        >
                                    <span class="invalid-feedback" *ngIf="displayMessage.cliente_id">
                                        <p [innerHTML]="displayMessage.cliente_id"></p>
                                    </span>
                                </div>
                            </div>
                            <div class="col-sm-4 col-md-3 col-lg-3 col-xl-2" formGroupName="agendamento">
                                <div class="form-group">
                                    <label for="dt_inicial" class="col-form-label-sm">Data Inicial</label>
                                    <input type="datetime-local" id="dt_inicial" name="dt_inicial"
                                        class="form-control form-control-sm" formControlName="dt_inicial" autocomplete="off"
                                        pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}" step="-3" >
                                    <span class="invalid-feedback" *ngIf="displayMessage.dt_inicial">
                                        <p [innerHTML]="displayMessage.dt_inicial"></p>
                                    </span>
                                </div>
                            </div>
                            <div class="col-sm-4 col-md-3 col-lg-3 col-xl-2" formGroupName="agendamento">
                                <div class="form-group">
                                    <label for="dt_final" class="col-form-label-sm">Data Final</label>
                                    <input type="datetime-local" id="dt_final" name="dt_final"
                                        class="form-control form-control-sm" formControlName="dt_final" autocomplete="off"
                                        pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}" step="-3">
                                    <span class="invalid-feedback" *ngIf="displayMessage.dt_final">
                                        <p [innerHTML]="displayMessage.dt_final"></p>
                                    </span>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-6 col-lg-2 col-xl-2" formGroupName="agendamento">
                                <label class="col-form-label-sm custom-select-label">Status</label>
                                <select id="status" name="status" class="form-control form-control-sm" formControlName="status" >
                                    <option [ngValue]="'1'">1 - Confirmado</option>
                                    <option [ngValue]="'2'">2 - Não confirmado</option>
                                    <option [ngValue]="'3'">3 - Cancelado</option>
                                    <option [ngValue]="'4'">4 - Não compareceu</option>
                                </select>
                                <span class="invalid-feedback" *ngIf="displayMessage.status">
                                    <p [innerHTML]="displayMessage.status"></p>
                                </span>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-2" formGroupName="agendamento">
                                <div class="form-group">
                                  <label for="atendente_id" class="col-form-label-sm">Tecnico</label>
                                  <select id="atendente_id" name="atendente_id" class="form-control form-control-sm"formControlName="atendente_id" >
                                    <option [ngValue]="0">-Selecione-</option>
                                    <option *ngFor="let item of listaVendedores" [ngValue]="item.id_usuario_adm">
                                      {{ item.nome }}
                                    </option>
                                  </select>
                                <span class="invalid-feedback" *ngIf="displayMessage.atendente_id">
                                    <p [innerHTML]="displayMessage.atendente_id"></p>
                                </span>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6" formGroupName="agendamento">
                                <div class="form-group">
                                    <label for="obs" class="col-form-label-sm">Observação</label>
                                    <textarea id="obs" name="obs" class="form-control form-control-sm"
                                        placeholder="Observação" formControlName="obs"></textarea>
                                    <span class="invalid-feedback" *ngIf="displayMessage.obs">
                                        <p [innerHTML]="displayMessage.obs"></p>
                                    </span>
                                </div>
                            </div>
                            <div class="col-sm-4 col-md-4 col-lg-4 col-xl-2" formGroupName="agendamento">
                                <div class="form-group">
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" id="enviar_email" name="enviar_email"
                                               class="custom-control-input form-control-sm" formControlName="enviar_email"/>
                                        <label class="custom-control-label col-form-label-sm status-filtro"
                                               for="enviar_email">Enviar e-mail</label>
                                    </div>
                                    <span class="invalid-feedback" *ngIf="displayMessage.enviar_email">
                                        <p [innerHTML]="displayMessage.enviar_email"></p>
                                    </span>
                                </div>
                            </div> 
                                                         
                        </div>
                    </div>

                    <div class="card-body border-top pt-1 pb-1" >
                        <div class="row" formGroupName="agendamento_item" *ngIf="alterar">
                            <div class="col-sm-8 col-md-6 col-lg-4 col-xl-3">
                                <div class="form-group">
                                    <label for="produto_descricao" class="col-form-label-sm">Descrição do Serviço</label>
                                    <input type="text" id="produto_descricao" name="produto_descricao"
                                        class="form-control form-control-sm" placeholder="Pesquise pela descrição"
                                        formControlName="produto_descricao"
                                        [ngClass]="{'is-invalid': displayMessage.produto_descricao}"
                                        (keydown)="abrirFecharPesquisaServicoModal = true">
                                </div>
                            </div>
                            <div class="col-sm-4 col-md-3 col-lg-2 col-xl-1">
                                <div class="form-group">
                                    <label for="qtd" class="col-form-label-sm">Qtde</label>
                                    <input type="number" id="qtd" name="qtd"
                                        class="form-control form-control-sm noscroll" placeholder="Qtde"
                                        formControlName="qtd" (keydown.enter)="adicionarItem()" />
                                </div>
                            </div>
                            <div class="col-sm-4 col-md-4 col-lg-3 col-xl-2 mt-4">
                                <div class="form-group">
                                    <button class="btn btn-sm btn-outline-success btn-block" id="adicionarServico"
                                        (click)="adicionarItem()" type="button" title="Adicionar Serviço"><i
                                            class="material-icons">add</i>Adicionar</button>
                                </div>
                            </div>
                        </div>
                        <div class="card-body pt-1 pb-1 bg-white border-top pt-1 pb-1" style="width: 100%">
                            <div class="row">
                                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                    <div class="my-custom-scrollbar table-wrapper-scroll-y">
                                        <table class="table table-sm table-borderless table-striped table-hover">
                                            <thead>
                                                <tr>
                                                    <th scope="col-sm-auto col-md-auto col-lg-auto col-xl-auto">Código</th>
                                                    <th scope="col-sm-auto col-md-auto col-lg-auto col-xl-auto">Descrição</th>
                                                    <th scope="col-sm-auto col-md-auto col-lg-auto col-xl-auto">Qtde</th>
                                                    <th scope="col-sm-auto col-md-auto col-lg-auto col-xl-auto"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of listaItem" style="cursor: pointer">
                                                    <th scope="row">{{item.produto_id}}</th>
                                                    <td>{{item.produto_descricao}}</td>
                                                    <td>{{item.qtd |currency:'BRL':""}}</td>
                                                    <td class="text-nowrap pr-2">
                                                        <a title="Remover" class="badge badge-maxdata float-left" 
                                                        (click)="removerItem(item)">
                                                            <i class="material-icons" >delete</i>
                                                        </a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            <!-- </p-tabPanel> -->
        <!-- </p-tabView> -->
    </form>
</p-dialog>

<app-cliente-pesquisa-modal *ngIf="abrirFecharModalCliente" [abrirFecharModalCliente]="abrirFecharModalCliente"
    [stringPesquisa]="cadastroForm.get('agendamento')?.get('cliente_nome')?.value" (closeModal)="abrirFecharModalCliente = false"
    [tipoCadastro]="[0]" [tipoPessoa]="[0]" (cliente)="clienteSelecionado($event)">
</app-cliente-pesquisa-modal>

<app-servico-pesquisa-modal *ngIf="abrirFecharPesquisaServicoModal"
    [abrirFecharPesquisaServicoModal]="abrirFecharPesquisaServicoModal"
    [stringPesquisa]="cadastroForm.controls.agendamento_item.get('produto_descricao')?.value"
    (closeModal)="abrirFecharPesquisaServicoModal = false" (servico)="itemSelecionado($event)">
</app-servico-pesquisa-modal>

