import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { ProdutoCadastroModalComponent } from './produto-cadastro-modal/produto-cadastro-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomFormsModule } from 'ng2-validation';
import { DialogModule } from 'primeng/dialog';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgBrazil } from 'ng-brazil';

import { ProdutoNcmService } from '@modules/manager/cadastro/estoque/produto-ncm/produto-ncm.service';
import { ProdutoCestService } from '@modules/manager/cadastro/estoque/produto-cest/produto-cest.service';
import { ProdutoNcmCestService } from '@modules/manager/cadastro/estoque/produto-ncm-cest/produto-ncm-cest.service';
import { TabViewModule } from 'primeng/tabview';
import { FileUploadModule } from 'primeng/fileupload';
import { ButtonModule } from 'primeng/button';
import { ProdutoGradeDescricaoCadastroModalComponent } from './produto-grade-descricao-cadastro-modal/produto-grade-descricao-cadastro-modal.component';
import { ProdutoGradeTamanhoCadastroModalComponent } from './produto-grade-tamanho-cadastro-modal/produto-grade-tamanho-modal.component';
import { ProdutoNcmCestCadastroModalComponent } from './produto-ncm-cest-cadastro-modal/produto-ncm-cest-cadastro-modal.component';
import { AgendamentoModalComponent } from './agendamento-modal/agendamento-modal.component';
import { PesquisaModalModule } from '../pesquisa-modal/pesquisa-modal.module';
import { ProdutoLoteCadastroModalComponent } from './produto-lote-cadastro-modal/produto-lote-cadastro-modal.component';
import { ProdutoGradeCadastroModalComponent } from './produto-grade-cadastro-modal/produto-grade-cadastro-modal.component';
import { EtiquetaComponent } from './etiqueta/etiqueta.component';
import { TituloPaginaModule } from '../titulo-paginas/titulo-paginas.module';
import { TemplatesModule } from '../templates/templates.module';
import { CheckboxModule } from 'primeng/checkbox';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { MedicoCadastroModalComponent } from './medico-clinica-cadastro-modal/medico-clinica-cadastro-modal.component';
import { DioptriaCadastroModalComponent } from './dioptria-cadastro-modal/dioptria-cadastro-modal.component';
import { DioptriaTipoArmacaoModalComponent } from './dioptria-tipo-armacao-modal/dioptria-tipo-armacao-modal.component';
import { UsuarioModalComponent } from './usuario-modal/usuario-modal.component';
import { AdminModule } from '@modules/admin/admin.module';
import { FabricanteCadastroModalComponent } from './fabricante-cadastro-modal/fabricante-cadastro-modal.component';
import { GrupoCadastroModalComponent } from './grupo-cadastro-modal/grupo-cadastro-modal.component';
import { SubGrupoCadastroModalComponent } from './sub-grupo-cadastro-modal/sub-grupo-cadastro-modal.component';
import ProdutoDepartamentoModalComponent from './produto-departamento-modal/produto-departamento-modal.component';
import ProdutoCategoriaModalComponent from './produto-categoria-modal/produto-categoria-modal.component';
import ProdutoSubCategoriaModalComponent from './produto-subcategoria-modal/produto-sub-categoria-modal.component';
import { MultiSelectModule } from 'primeng/multiselect';




@NgModule({
  declarations: [
    MedicoCadastroModalComponent,
    ProdutoCadastroModalComponent,
    ProdutoGradeDescricaoCadastroModalComponent,
    ProdutoGradeTamanhoCadastroModalComponent,
    ProdutoNcmCestCadastroModalComponent,
    AgendamentoModalComponent,
    ProdutoLoteCadastroModalComponent,
    ProdutoGradeCadastroModalComponent,
    EtiquetaComponent,
    DioptriaCadastroModalComponent,
    DioptriaTipoArmacaoModalComponent,
    UsuarioModalComponent,
    FabricanteCadastroModalComponent,
    GrupoCadastroModalComponent,
    SubGrupoCadastroModalComponent,
    ProdutoDepartamentoModalComponent,
    ProdutoCategoriaModalComponent,
    ProdutoSubCategoriaModalComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CustomFormsModule,
    TabViewModule,
    FileUploadModule,
    DialogModule,
    ButtonModule,
    NgxSpinnerModule,
    NgBrazil,
    PaginationModule.forRoot(),
    CollapseModule.forRoot(),
    TypeaheadModule.forRoot(),
    TituloPaginaModule,
    TemplatesModule,
    CheckboxModule,
    AdminModule,
    PesquisaModalModule,
    MultiSelectModule
  ],
  providers: [
    ProdutoNcmService,
    ProdutoCestService,
    ProdutoNcmCestService,
    CurrencyPipe
  ],
  exports: [
    ProdutoCadastroModalComponent, 
    ProdutoGradeDescricaoCadastroModalComponent,
    ProdutoGradeTamanhoCadastroModalComponent,
    ProdutoNcmCestCadastroModalComponent,
    AgendamentoModalComponent,
    ProdutoLoteCadastroModalComponent,
    ProdutoGradeCadastroModalComponent,
    EtiquetaComponent,
    MedicoCadastroModalComponent,
    DioptriaCadastroModalComponent,
    DioptriaTipoArmacaoModalComponent,
    UsuarioModalComponent,
    FabricanteCadastroModalComponent,
    GrupoCadastroModalComponent,
    SubGrupoCadastroModalComponent,
    ProdutoDepartamentoModalComponent,
    ProdutoCategoriaModalComponent,
    ProdutoSubCategoriaModalComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class CadastroModalModule { }
