import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AlertMessage } from '@modules/alert.configuration.service';
import { ProdutoGrupoService } from '@modules/manager/cadastro/estoque/produto-grupo/produto-grupo.service';
import { ProdutoGrupoPesquisaViewModel } from '@modules/manager/view-models/cadastro/estoque/pesquisa/ProdutoGrupoPesquisaViewModel';
import { Uteis } from '@modules/utilizarios/uteis';

@Component({
  selector: 'app-multiselect-grupo',
  templateUrl: './multiselect-grupo.component.html',
  styles: []
})
export class MultiselectGrupoComponent implements OnInit {

  @Output() gruposSelecionados: EventEmitter<any> = new EventEmitter<any>();
  listaGrupos: ProdutoGrupoPesquisaViewModel[] = [];
  grupos: ProdutoGrupoPesquisaViewModel[] = [];

  constructor(
    private alert: AlertMessage, 
    private produtoGrupo$: ProdutoGrupoService,
  ) { }

  ngOnInit() {
    this.produtoGrupo$.obterTodos().subscribe(
      retorno => {
        this.listaGrupos = retorno.total > 0 ? retorno.result : [];        
      },
      error =>
        this.alert.Danger("Grupo de produto", Uteis.ObterErroApi(error))
    );
  }

  enviar() {
    this.gruposSelecionados.emit(this.grupos);
  }

}
