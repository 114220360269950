import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertMessage } from '@modules/alert.configuration.service';
import { NfeService } from '@modules/manager/fiscal/nfe/nfe.service';
import { NfPesquisaViewModel } from '@modules/manager/view-models/fiscal/pesquisa/nf-pesquisa-view-model';
import { Uteis } from '@modules/utilizarios/uteis';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-nf-modal',
  templateUrl: './nf-modal.component.html',
  styles: []
})
export class NfModalComponent implements OnInit {
  @Input() abrirFecharModalNf: boolean = false;
  @Input() stringPesquisa: string = '';
  @Output() closeModal = new EventEmitter();
  @Output() nf: EventEmitter<any> = new EventEmitter();
  pesquisa: NfPesquisaViewModel = new NfPesquisaViewModel();
  listaNf: NfPesquisaViewModel[] = [];
  constructor(
    private alert$: AlertMessage,
    private spinner$: NgxSpinnerService,
    private nfe$: NfeService
  ) { }

  ngOnInit(): void {
  }

  pesquisar(){

    this.spinner$.show();
    
    this.pesquisa = Object.assign({ tipo_nf_sped: '55', tipo: '3', nfe_autorizado: 1 , status: "F" }, this.pesquisa);
    
    this.nfe$.ObterTodosGroup(this.pesquisa).subscribe(
      retorno => {
       
        this.listaNf = retorno.total > 0 ? retorno.result : [];
        this.spinner$.hide();
      },
      error =>{
        this.alert$.Danger('Erro', Uteis.ObterErroApi(error));
        this.spinner$.hide();
      });
  }

  dadosSelecionadosNf : Array<any> = [];
  nfSelecionados(event: any, item ) {

    let data = {
      nf_id: item.id, chave_nfe: item.chave_nf
      , municipio_carregamento_codigo_ibge : item.municipio_carregamento_codigo_ibge
      , municipio_descarregamento_codigo_ibge: item.municipio_descarregamento_codigo_ibge}
    
    let index = this.dadosSelecionadosNf.findIndex(x => x.nf_id === item.nf_id);

    if(event.checked) { if(index === -1) this.dadosSelecionadosNf.push(data) }

    else this.dadosSelecionadosNf.splice(index, 1);
  
  }

  selecionar(){
    this.nf.emit(this.dadosSelecionadosNf);
  }

  closeModalNf() {
    this.closeModal.emit(false);
  }

}
