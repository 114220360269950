import { PaginacaoViewModel } from "../../PaginacaoViewModel";
import { CteViewModel } from "../cte-view-model";

export class CtePesquisaViewModel extends CteViewModel implements PaginacaoViewModel{
    pagina: number = 1;
    quantidadeRegistrosPagina: number = 20;
    totalRegistros: number = 0 ;
    totalPaginas: number ;
    desativados: boolean;

    start: number; 
    order: string;
    
}