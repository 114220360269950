import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CadastroViewModel } from '@modules/admin/view-models/cadastro/CadastroViewModel';
import { PesquisaGeralViewModel } from '@modules/admin/view-models/pesquisa/PesquisaGeralViewModel';
import { AutenticacaoService } from '@modules/autenticacao/services/autenticacao.service';
import { URLBASE, URLIMPRESSAO } from '@modules/configuration.service';
import { ClienteDioptriaArmacaoViewModel } from '@modules/manager/view-models/cadastro/cliente-fornecedor/cliente-dioptria-armacao-view-model';
import { ClienteDioptriaArmacaoPesquisaViewModel } from '@modules/manager/view-models/cadastro/cliente-fornecedor/pesquisa/cliente-dioptria-armacao-pesquisa-view-model ';
import { RetornoApiViewModel } from '@modules/manager/view-models/RetornoApiViewModel';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ClienteDioptriaArmacaoService {

  idEmpresaMaxdata = this.autenticacaoService.acessoUsuarioValue?.idEmpresaMaxdata;

  urlGet = `${URLBASE}/${this.idEmpresaMaxdata}/dioptria_tipo_armacao_service/get`;
  urlAddUpdate = `${URLBASE}/${this.idEmpresaMaxdata}/dioptria_tipo_armacao_service/addupdate`;
  urlImpressaoRel = `${URLIMPRESSAO}/api/relatorio`;
  constructor(private http: HttpClient,
    private autenticacaoService: AutenticacaoService) { }


    obterTodos( pesquisa?: ClienteDioptriaArmacaoPesquisaViewModel): Observable<RetornoApiViewModel<ClienteDioptriaArmacaoPesquisaViewModel[]>> {

      let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel();
  
      pesquisaGeral.select = `dioptria_tipo_armacao.*                              
                    `;       
  
      pesquisaGeral.calcRowCount = 1;
  
      pesquisaGeral.start = pesquisa?.start || 0;
  
      pesquisaGeral.limit = 0;      
         
      return this.http.post<RetornoApiViewModel<ClienteDioptriaArmacaoPesquisaViewModel[]>>(this.urlGet, pesquisaGeral)
    }

    adicionar(armacaoViewModel: CadastroViewModel<ClienteDioptriaArmacaoViewModel>): Observable<RetornoApiViewModel<ClienteDioptriaArmacaoViewModel>> {
      return this.http.post<RetornoApiViewModel<ClienteDioptriaArmacaoViewModel>>(this.urlAddUpdate, armacaoViewModel);
  }


  atualizar(id: number, objArmacao: CadastroViewModel<ClienteDioptriaArmacaoViewModel>) {
    throw new Error('Method not implemented.');
  }

}
