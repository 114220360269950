export class ConfiguracaoVendaOrcamentoViewModel {
    id?: number;
    emp_id?: number;
    chk_venda_converte_orc?: boolean;
    lst_indice_pesquisa_produto?: number;
    lst_indice_pesquisa_produto_pdv?: number;
    centro_custo_id?: number;
    plano_conta_id?: number;
    sub_plano_conta_id?: number;
    os_equipamento?: string;
    os_marca?: string; 
    os_serie?: string; 
    os_defeito?: string; 
    os_obs?: string; 
    os_laudo_tecnico?: string; 
    os_complemento?: string; 
    cp_operacao_entrada?: number;
    cp_operacao_saida?: number;
    chk_mostrar_grafico?: number;
    chk_nao_vender_produto_estoque_negativo?: number;
    chk_enviar_venda_caixa?: number;
    ve_operacao_padrao?: number;
    ve_consumidor_padrao_id?: number;
}
