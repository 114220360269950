import { UsuarioEmpresaViewModel } from './UsuarioEmpresaViewModel';

export class UsuarioViewModel{
    id?: number;
    email?: string;
    senha_hash?: string;
    dt_cadastro?: string;
    ativo?: boolean;
    chk_usu_adm?: boolean;
    refresh_token?: string;
    chk_usa_sistema?: boolean;
    chk_primeiro_acesso?: boolean;


}
