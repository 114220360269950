export class ProdutoLoteViewModel {
    id?: number;
    produto_id?: number;
    lote?: string;
    estoque?: number;
    dt_fabricacao?: string;
    dt_vencimento?: string;
    vlr_custo?: number;
    prateleira?: string;
    ativo?: boolean;

}