import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CadastroViewModel } from '@modules/admin/view-models/cadastro/CadastroViewModel';
import { AlertMessage } from '@modules/alert.configuration.service';
import { EnderecoUfService } from '@modules/manager/cadastro/cliente-fornecedor/endereco-uf/endereco-uf.service';
import { ClinicaConselhoService } from '@modules/manager/cadastro/clinica/clinica-conselho/clinica-conselho.service';
import { ClinicaMedicoService } from '@modules/manager/cadastro/clinica/clinica-medico/clinica-medico.service';
import { ProdutoGradeDescricaoService } from '@modules/manager/cadastro/estoque/produto-grade-descricao/produto-grade-descricao.service';
import { ProdutoGradeTamanhoService } from '@modules/manager/cadastro/estoque/produto-grade-tamanho/produto-grade-tamanho.service';
import { ProdutoGradeService } from '@modules/manager/cadastro/estoque/produto-grade/produto-grade.service';
import { EnderecoUfPesquisaViewModel } from '@modules/manager/view-models/cadastro/cliente-fornecedor/pesquisa/endereco-uf-pesquisa-view-model';
import { MedicoViewModel } from '@modules/manager/view-models/cadastro/clinica/medico-view-model';
import { ConselhoPesquisaViewModel } from '@modules/manager/view-models/cadastro/clinica/pesquisa/conselho-pesquisa-view-model';
import { ProdutoGradeDescricaoPesquisaViewModel } from '@modules/manager/view-models/cadastro/estoque/pesquisa/produto-grade-descricao-pesquisa-view-model';
import { ProdutoGradeTamanhoPesquisaViewModel } from '@modules/manager/view-models/cadastro/estoque/pesquisa/produto-grade-tamanho-pesquisa-view-model';
import { ProdutoGradeViewModel } from '@modules/manager/view-models/cadastro/estoque/produto-grade-view-model';
import { AlertMensagemPadrao } from '@modules/utilizarios/alertMensagemPadrao';
import { Uteis } from '@modules/utilizarios/uteis';
import { DisplayMessage, GenericValidator, ValidationMessages } from '@modules/validacao/generic-form-validation';
import { MensagensValidacao } from '@modules/validacao/mensagensValidacao';
import { BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { isNumber } from 'util';

@Component({
  selector: 'app-medico-clinica-cadastro-modal',
  templateUrl: './medico-clinica-cadastro-modal.component.html',
  styles: [
  ]
})
export class MedicoCadastroModalComponent implements OnInit {

  @Input() medico!: MedicoViewModel;
  @Input() clinica_conselho_id: number;
  @Input() produtoGradeId: number;
  @Input() nome: string;
  @Input() registro: string;
  @Input() uf: string;
  @Input() ativo: boolean;
  @Input() alterar: boolean = false;
  @Input() abrirFecharModalMedico: boolean = false;
  @Output() closeModal = new EventEmitter();
  listaConselho: ConselhoPesquisaViewModel[] = [];
  listaEnderecoUf: EnderecoUfPesquisaViewModel[] = [];
  cadastroForm!: FormGroup;
  validationMessages?: ValidationMessages;
  genericValidator?: GenericValidator;
  displayMessage: DisplayMessage = {};
  carregando: boolean = false;
  objMedico: CadastroViewModel<MedicoViewModel> = new CadastroViewModel<MedicoViewModel>();
  listaProdutoGradeDescricao: ProdutoGradeDescricaoPesquisaViewModel[] = [];
  listaProdutoGradeTamanho: ProdutoGradeTamanhoPesquisaViewModel[] = [];
  constructor(private route: ActivatedRoute
    , private router: Router
    , private fb: FormBuilder
    , private enderecoUf$: EnderecoUfService
    , private conselho$: ClinicaConselhoService
    , private medico$: ClinicaMedicoService
    , private alert: AlertMessage
    , private spinner: NgxSpinnerService
    , private modal$: BsModalService
  ) {
    this.messagesValidations();
  }
  ngOnInit(): void {

    this.objMedico.obj = new MedicoViewModel();

    this.criarFormulario();

    this.obterListaConselho();
    this.obterListaEnderecoUf();



    this.alterar = isNumber(this.produtoGradeId) && this.produtoGradeId > 0;

    if (this.alterar) {

      this.spinner.show();

      this.medico$.obterPorId(this.produtoGradeId || 0).subscribe(
        retorno => {

          this.spinner.hide();

          if (retorno.total <= 0) this.alert.Danger('Erro', 'Registro não encontrado');

          this.objMedico.obj = retorno.total > 0 ? retorno.result[0] : new ProdutoGradeViewModel();

          this.criarFormulario();
        },
        error => {
          this.spinner.hide();

          this.alert.Danger('Erro', Uteis.ObterErroApi(error))
        });
    }
  }

  onSubmit() {

    this.cadastroForm.markAllAsTouched();

    if (!this.cadastroForm.valid) {

      if (typeof this.genericValidator !== 'undefined') {

        this.displayMessage = this.genericValidator.processarMensagens(this.cadastroForm);

      }

      let mensagem = Uteis.ConverterObjetoToArray(this.displayMessage)

      return this.mensagemCamposInvalidos(mensagem);
    }

    if (!this.cadastroForm.dirty) {

      this.alert.Info(AlertMensagemPadrao.formularioNaoAlterado.titulo, AlertMensagemPadrao.formularioNaoAlterado.mensagem);

      return
    }

    this.carregando = true;

   
  this.adicionar();
  }



  criarFormulario() {
    this.cadastroForm = this.fb.group({
      id: [{ value: !this.alterar ? 0 : this.objMedico.obj.id, disabled: true }],
      clinica_conselho_id: [!this.alterar ? 0 : this.objMedico.obj.clinica_conselho_id, []],
      nome: [!this.alterar ? '' : this.objMedico.obj.nome, [Validators.required, Validators.minLength(2), Validators.maxLength(50)]],
      registro: [{ value: !this.alterar ? 0 : this.objMedico.obj.registro, disabled: false }],
      uf: [!this.alterar ? '' : this.objMedico.obj.uf, [Validators.required, Validators.minLength(2)]],
      ativo: [!this.alterar ? true : this.objMedico.obj.ativo]
    });
  }
  atualizarDadosObjeto() {
    this.objMedico.obj = Object.assign({}, this.objMedico.obj, this.cadastroForm.value);
  }

 

  adicionar() {

    this.atualizarDadosObjeto();

    this.medico$.adicionar(this.objMedico).subscribe(
      retorno => {

        this.alert.Success('Sucesso', 'Registro cadastrado com sucesso');

        this.closeModalCadastroMedico();

        this.limparForm();

        this.carregando = false;
      },
      error => {

        this.carregando = false;

        this.alert.Danger('Erro', Uteis.ObterErroApi(error))

      }
    )
  }

  limparForm() {
    this.cadastroForm.reset();
  }

  messagesValidations() {
    this.validationMessages = {
      descricao: {
        required: MensagensValidacao.Obrigatorio('Descrição'),
        minlength: MensagensValidacao.MinimoCaracteres('Descricão', 2),
      },
    }
  }




  closeModalCadastroMedico() {
    this.closeModal.emit(false);
  }
  obterListaConselho() {

    this.conselho$.obterTodos().subscribe(

      retorno => this.listaConselho = retorno.total > 0 ? retorno.result : [],

      error => this.alert.Danger('Erro', Uteis.ObterErroApi(error))
    )
  }

  obterListaEnderecoUf() {

    this.enderecoUf$.obterTodos().subscribe(

      retorno => this.listaEnderecoUf = retorno.total > 0 ? retorno.result : [],

      error => this.alert.Danger('Erro', Uteis.ObterErroApi(error))
    )
  }
  

  mensagemCamposInvalidos(mensagens: any) {
    this.alert.Warning(AlertMensagemPadrao.formularioInvalido.titulo, AlertMensagemPadrao.formularioInvalido.mensagem + '<br><br>' + mensagens.toString().replace(/,/g, ''));
  }

}
