<p-dialog [(visible)]="abrirFecharPesquisaProdutoFornecedorModal" [modal]="true" [closable]="false" [style]="{width: '100vw'}"
    [maximizable]="true" [baseZIndex]="10000" [draggable]="false" [resizable]="false">
    <ngx-spinner name="modalAtualiza" bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-clip-rotate"
        [fullScreen]="false"></ngx-spinner>
    <p-header>Relação de Fornecedores</p-header>
    <div class="row float-right">
        <!-- <button class="btn btn-sm btn-outline-info" (click)="pesquisar()"><i
                class="material-icons">search</i>Pesquisar</button> -->
    </div>
    <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div class="my-custom-scrollbar table-wrapper-scroll-y">
                <table class="table table-hover table-borderless table-striped table-sm " width="100%">
                    <thead class="thead">
                        <tr>
                            <th>Código</th>
                            <th>Dt. Emissão</th>
                            <th>Dt. Contábil</th>
                            <th>Núm. Doc</th>
                            <th>Fornecedor</th>
                            <th>Qtd</th>
                            <th>Vlr Custo</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="cursor" *ngFor="let item of listaProdutoFornecedor">
                            <td class="text-nowrap">{{item.nf_id}}</td>
                            <td class="text-nowrap">{{item.dt_emissao | date : 'dd/MM/yyyy'}}</td>
                            <td class="text-nowrap">{{item.dt_contabil | date : 'dd/MM/yyyy'}}</td>
                            <td class="text-nowrap">{{item.numero}}</td>
                            <td class="text-nowrap ">{{item.id}} - {{item.nome}}</td>
                            <td class="text-nowrap">{{item.qtd}}</td>
                            <td class="text-nowrap">{{item.vlr_un  | currency:'BRL': ''}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <button class="btn btn-sm btn-outline-secondary" (click)="closeModalProdutoFornecedor()">Cancelar</button>
    </ng-template>
</p-dialog>