<p-dialog [(visible)]="abrirFecharVendaPesquisaModal" [modal]="true" [style]="{width: '90vw'}" [closable]="false" [maximizable]="true"
    [baseZIndex]="10000" [draggable]="false" [resizable]="false" >
    <ngx-spinner name="modalAtualiza" bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-clip-rotate"
        [fullScreen]="false"></ngx-spinner>
    <p-header>
        Pesquisar Venda
    </p-header>
    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-right btns-group mb-3">
        <button class="btn btn-sm btn-outline-secondary" type="button" (click)="fecharVendaPesquisaModal()">Cancelar</button>
        <button class="btn btn-sm btn-outline-search" (click)="pesquisarVenda()"><i
                class="material-icons">search</i>Pesquisar</button>
        <button class="btn btn-sm btn-outline-primary" id="salvar" type="button" (click)="vendaSelecionada()">
             <i class="material-icons">done_all</i>Importar</button>
    </div>
    <div class="card rounded-0 mb-1 border-left-0 border-right-0">
        <div class="card-body pt-1 pb-1 bg-white">
            <div class="row">
                <div class="col-12 col-sm-6 col-md-3 col-lg-2 col-xl-1">
                    <div class="form-group">
                        <label for="codigo" class="col-form-label-sm">Num. Venda</label>
                        <input type="number" id="codigo" name="codigo" class="form-control form-control-sm noscroll"
                            placeholder="Venda" [(ngModel)]="pesquisaVenda.id">
                    </div>
                </div>
                <div class="col-4 col-sm-6 col-md-3 col-lg-2 col-xl-1">
                    <div class="form-group">
                        <label for="cliente_id" class="col-form-label-sm">Cód. Cliente</label>
                        <input type="number" id="cliente_id" name="cliente_id"
                            class="form-control form-control-sm noscroll" placeholder="Código Cliente"
                            [(ngModel)]="pesquisaVenda.cliente_id" (keydown.enter)="pesquisarVenda()">
                    </div>
                </div>
                <div class="col-8 col-sm-12 col-md-6 col-lg-5 col-xl-3">
                    <div class="form-group">
                        <label for="cliente_nome" class="col-form-label-sm">Nome Cliente</label>
                        <input id="cliente_nome" name="cliente_nome" class="form-control form-control-sm"
                            placeholder="Pesquise o Cliente pelo nome" autocomplete="off"
                            [(ngModel)]="pesquisaVenda.cliente_nome">
                    </div>
                </div>
                <div class="col-6 col-sm-12 col-md-4 col-lg-3 col-xl-2" >
                    <div class="form-group">
                      <label for="atendente_id" class="col-form-label-sm">Vendedor</label>
                      <select id="atendente_id" name="atendente_id" class="form-control form-control-sm"[(ngModel)]="pesquisaVenda.atendente_id" >
                        <option [ngValue]="0">-Selecione-</option>
                        <option *ngFor="let item of listaVendedores" [ngValue]="item.id_usuario_adm">
                          {{ item.nome }}
                        </option>
                      </select>
                    </div>
                </div>
                <div class="col-6 col-sm-10 col-md-4 col-lg-3 col-xl-2">
                    <div class="form-group">
                        <label for="operacao_id" class="col-form-label-sm">Operação</label>
                        <select id="operacao_id" name="operacao_id" class="form-control form-control-sm"
                            placeholder="Operação" [(ngModel)]="pesquisaVenda.operacao_id">
                            <option>-Selecionar-</option>
                            <option *ngFor="let item of listaOperacao.itens" value="{{ item.id }}">{{ item.descricao }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-sm-8 col-md-4 col-lg-3 col-xl-2">
                    <label class="col-form-label-sm custom-select-label">Filtro Data</label>
                    <select id="filtro_data" name="filtro_data" class="form-control form-control-sm"
                        [(ngModel)]="pesquisaVenda.filtro_data">
                        <option [ngValue]="'A'">Abertura</option>
                        <option [ngValue]="'F'">Fechamento</option>
                    </select>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-6 col-xl-4">
                    <div class="form-group">
                        <label for="data_inicial" class="col-form-label-sm">Data</label>
                        <div class="input-group">
                            <input type="date" id="data_inicial" name="data_inicial"
                                class="form-control form-control-sm" [(ngModel)]="pesquisaVenda.data_inicial">
                            <span class="ml-1 mr-1 mt-1 text">a</span>
                            <input type="date" id="data_final" name="data_final" class="form-control form-control-sm"
                                [(ngModel)]="pesquisaVenda.data_final">
                            <div class="input-group-append">
                                <button class="btn btn-sm btn-maxdata" type="button" id="remover_data_inicial"
                                    [disabled]="!pesquisaVenda.data_inicial && !pesquisaVenda.data_final"
                                    (click)="limparData()"><i class="material-icons mr-0">remove</i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <div class="my-custom-scrollbar table-wrapper-scroll-y">
                        <table class="table table-sm table-borderless table-striped table-hover">
                            <thead class="thead">
                                <th scope="col"><p-checkbox binary="true" [(ngModel)]="selecionarTodasVenda" name="selecionarTodasVenda" (onChange)="selecionarVenda(true)"></p-checkbox></th>
                                <th>Venda</th>
                                <th>Cliente</th>
                                <th>Data Venda</th>
                                <th>Valor R$</th>
                                <th>
                                    Emitiu NFC-e
                                </th>
                                <th>
                                    Emitiu NF-e
                                </th>
                                <th class="text-right">Status</th>
                            </thead>
                            <tbody>
                                <tr class="cursor" *ngFor="let item of listaVenda.itens" >
                                    <td><p-checkbox binary="true" name="item.id" [(ngModel)]="item.checked" (onChange)="selecionarVenda(false)"></p-checkbox></td>
                                    <td>{{item.id }}</td>
                                    <td class="text-left">{{item.cliente_id}} - {{item.cliente_nome}}</td>
                                    <td>{{item.dt_abertura |date: 'dd/MM/yyyy'}}</td>
                                    <td>{{item.total_c_desc | currency: 'BRL' : ''}}</td>
                                    <td>
                                        <span class="customer-badge status-aberto" *ngIf="item.nfce <= 0">Não</span>
                                        <span class="customer-badge status-concluida" *ngIf="item.nfce > 0">Sim</span>
                                    </td>
                                    <td>
                                        <span class="customer-badge status-aberto" *ngIf="item.nfe <= 0">Não</span>
                                        <span class="customer-badge status-concluida" *ngIf="item.nfe > 0">Sim</span>
                                    </td>
                                    <td class="text-right">
                                        <span class="customer-badge status-concluida" *ngIf="item.status === 'F'">Concluída</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <p-footer>
        <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6 text-left">
            <span>Total de Registo(s): </span><strong>{{listaVenda.paginacao.totalRegistros}}</strong>
        </div>
    </p-footer>
</p-dialog>