<p-dialog [(visible)]="abrirFecharProdutoGradeCadastroModal" [modal]="true" [closable]="false" [style]="{width: '100vw'}"
    [maximizable]="true" [baseZIndex]="10000" [draggable]="false" [resizable]="false" (onHide)="closeModalCadastroProdutoGrade()">

    <p-header> {{ alterar ? "Editar" : "Cadastrar"}} Grade de Produto </p-header>

    <div class="d-flex justify-content-end mb-1">
        <div class="btns-group">
            <button class="btn btn-sm btn-outline-secondary" (click)="closeModalCadastroProdutoGrade()"><i class="material-icons">close</i>Cancelar</button>
            <button class="btn btn-sm btn-outline-success" id="salvar" type="button" (click)="onSubmit()" [disabled]="carregando">
                <div [ngSwitch]="carregando">
                    <div *ngSwitchCase="true">
                        <span class="spinner-border spinner-border-sm" role="status"
                            aria-hidden="true"></span>&nbsp;
                        <span>Carregando ...</span>
                    </div>
                    <div *ngSwitchDefault>
                        <i class="material-icons">done_all</i><span>Salvar</span>
                    </div>
                </div>
            </button>
        </div>
    </div>

    <form (ngSubmit)="onSubmit()" [formGroup]="cadastro_form_produto_grade">

        <div class="card rounded-0" >
            <div class="card-body pt-1 pb-1">
                <div class="row">

                    <div class="col-sm-6 col-md-6 col-lg-4 col-xl-2">
                        <div class="form-group">
                            <label for="produto_grade_descricao" class="col-form-label-sm">Descrição</label>
                            <div class="input-group">
                                <select id="produto_grade_descricao" name="produto_grade_descricao" class="form-control form-control-sm" placeholder="Descrição"
                                    formControlName="produto_grade_descricao_id">
                                    <option [ngValue]="null">-Selecione-</option>
                                    <option *ngFor="let item of listaProdutoGradeDescricao" [ngValue]="item.id"> {{item.descricao}}</option>
                                </select>

                                <div class="input-group-append">
                                    <button class="btn btn-sm btn-info" type="button" title="Editar descrição selecionada" id="remover_produto_grade_descricao_id"
                                        [disabled]="!cadastro_form_produto_grade.get('produto_grade_descricao_id')?.value" *ngIf="cadastro_form_produto_grade.get('produto_grade_descricao_id')?.value"
                                        (click)="abrirModalDescricao(true)">
                                        <i class="material-icons mr-0">edit</i>
                                    </button>
                                    <button class="btn btn-sm btn-success" type="button" title="Cadastrar nova descrição" id="adicionar_produto_grade_descricao_id"
                                        [disabled]="cadastro_form_produto_grade.get('produto_grade_descricao_id')?.value" *ngIf="!cadastro_form_produto_grade.get('produto_grade_descricao_id')?.value"
                                        (click)="abrirModalDescricao(false)">
                                        <i class="material-icons mr-0">add</i>
                                    </button>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-sm-6 col-md-6 col-lg-4 col-xl-2">
                        <div class="form-group">
                            <label for="produto_grade_tamanho" class="col-form-label-sm">Tamanho</label>
                            <div class="input-group">
                                <select id="produto_grade_tamanho" name="produto_grade_tamanho"
                                    class="form-control form-control-sm" placeholder="Ex: P, M, G"
                                    formControlName="produto_grade_tamanho_id">
                                    <option [ngValue]="null">-Selecione-</option>
                                    <option *ngFor="let item of listaProdutoGradeTamanho" [ngValue]="item.id"> {{item.tamanho}}</option>
                                </select>
                                <div class="input-group-append">
                                    <button class="btn btn-sm btn-info" type="button" title="Editar tamanho selecionado" id="editar_produto_grade_tamanho_id"
                                        [disabled]="!cadastro_form_produto_grade.get('produto_grade_tamanho_id')?.value" *ngIf="cadastro_form_produto_grade.get('produto_grade_tamanho_id')?.value"
                                        (click)="abrirModalTamanho(true)">
                                        <i class="material-icons mr-0">edit</i>
                                    </button>
                                    <button class="btn btn-sm btn-success" type="button" title="Cadastrar novo tamanho" id="adicionar_produto_grade_tamanho_id"
                                        [disabled]="cadastro_form_produto_grade.get('produto_grade_tamanho_id')?.value" *ngIf="!cadastro_form_produto_grade.get('produto_grade_tamanho_id')?.value"
                                        (click)="abrirModalTamanho(false)">
                                        <i class="material-icons mr-0">add</i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-4 col-md-3 col-lg-2 col-xl-1" >
                        <div class="form-group">
                            <label for="produto_grade_estoque" class="col-form-label-sm" >Estoque</label>
                            <input type="number" id="produto_grade_estoque" name="produto_grade_estoque" 
                                class="form-control form-control-sm noscroll" placeholder="Estoque"
                                formControlName="estoque">
                        </div>
                    </div>

                    <div class="col-sm-4 col-md-3 col-lg-2 col-xl-1" >
                        <div class="form-group">
                            <label for="produto_grade_estoque_minimo" class="col-form-label-sm" >Estoque Minimo</label>
                            <input type="number" id="produto_grade_estoque_minimo" name="produto_grade_estoque_minimo" 
                                class="form-control form-control-sm noscroll" placeholder="Estoque Minimo"
                                formControlName="estoque_minimo">
                        </div>
                    </div>

                    <div class="col-sm-4 col-md-3 col-lg-2 col-xl-1" >
                        <div class="form-group">
                            <label for="produto_grade_vlr_venda" class="col-form-label-sm" >Valor Venda</label>
                            <input type="number" id="produto_grade_vlr_venda" name="produto_grade_vlr_venda" 
                                class="form-control form-control-sm noscroll" placeholder="Valor Venda"
                                formControlName="vlr_venda">
                        </div>
                    </div>

                    <div class="col-sm-4 col-md-3 col-lg-2 col-xl-1" >
                        <div class="form-group">
                            <label for="produto_grade_codigo_barras" class="col-form-label-sm" >Cód. Barras</label>
                            <input type="text" id="produto_grade_codigo_barras" name="produto_grade_codigo_barras" 
                                class="form-control form-control-sm noscroll" placeholder="Cód. Barras"
                                formControlName="codigo_barras"
                                [ngClass]="{'is-invalid': displayMessage.codigo_barras}">
                        </div>
                        <span class="text-danger" *ngIf="displayMessage.codigo_barras">
                            <p [innerHTML]="displayMessage.codigo_barras"></p>
                        </span>
                    </div>

                    <div class="col-sm-4 col-md-4 col-lg-2 col-xl-1" *ngIf="alterar">
                        <div class="form-group">
                            <div class="custom-control custom-checkbox">
                                <input type="checkbox" id="produto_grade_ativo" name="produto_grade_ativo"
                                        class="custom-control-input form-control-sm" formControlName="ativo"/>
                                <label class="custom-control-label col-form-label-sm status-filtro" for="produto_grade_ativo">Ativo</label>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </form>
</p-dialog>


<app-produto-grade-descricao-cadastro-modal *ngIf="modalDescricao"
[abrirFecharProdutoGradeDescricaoCadastroModal]="modalDescricao"
[alterar]="modalTamanhoAlterar"
[produtoGradeDescricaoId]="modalDescricaoProdutoGradeDescricaoId"
(closeModal)="fecharModalDescricao($event)">
</app-produto-grade-descricao-cadastro-modal>


<app-produto-grade-tamanho-cadastro-modal *ngIf="modalTamanho"
[abrirFecharProdutoGradeTamanhoCadastroModal]="modalTamanho"
[alterar]="modalTamanhoAlterar"
[produtoGradeTamanhoId]="modalTamanhoProdutoGradeTamanhoId"
(closeModal)="fecharModalTamanho($event)">
</app-produto-grade-tamanho-cadastro-modal>