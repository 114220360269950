import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AlertMessage } from '@modules/alert.configuration.service';
import { ProdutoFabricanteService } from '@modules/manager/cadastro/estoque/produto-fabricante/produto-fabricante.service';
import { ProdutoFabricanteViewModel } from '@modules/manager/view-models/cadastro/estoque/produto-fabricante-view-model';
import { Uteis } from '@modules/utilizarios/uteis';

@Component({
  selector: 'app-multiselect-fabricante',
  templateUrl: './multiselect-fabricante.component.html',
  styles: []
})
export class MultiselectFabricanteComponent implements OnInit {


  @Output() fabricantesSelecionados: EventEmitter<any> = new EventEmitter<any>();
  listaFabricante: ProdutoFabricanteViewModel[] = [];
  fabricantes: ProdutoFabricanteViewModel[] = [];

  constructor(
    private alert: AlertMessage,
    private produtoFabricante$: ProdutoFabricanteService,
  ) { }

  ngOnInit() {
    this.produtoFabricante$.obterTodos().subscribe(
      retorno => {
        this.listaFabricante = retorno.total > 0 ? retorno.result : [];
      },
      error =>
        this.alert.Danger("Fabricante de produto", Uteis.ObterErroApi(error))
    );
  }

  enviar() {
    this.fabricantesSelecionados.emit(this.fabricantes);
  }


}
