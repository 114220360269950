import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CadastroViewModel } from '@modules/admin/view-models/cadastro/CadastroViewModel';
import { PesquisaGeralViewModel } from '@modules/admin/view-models/pesquisa/PesquisaGeralViewModel';
import { AutenticacaoService } from '@modules/autenticacao/services/autenticacao.service';
import { URLBASE } from '@modules/configuration.service';
import { EquipamentoViewModel } from '@modules/manager/view-models/cadastro/servico/equipamento-view-model';
import { EquipamentoPesquisaViewModel } from '@modules/manager/view-models/cadastro/servico/pesquisa/equipamento-pesquisa-view-model';
import { RetornoApiViewModel } from '@modules/manager/view-models/RetornoApiViewModel';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EquipamentoService {
  idEmpresaMaxdata = this.autenticacao$.acessoUsuarioValue?.idEmpresaMaxdata;
  urlAPI = `${URLBASE}/${this.idEmpresaMaxdata}/equipamento_service`;
  constructor(private http: HttpClient, private autenticacao$: AutenticacaoService) { }

  obterTodos(pesquisa: EquipamentoPesquisaViewModel): Observable<RetornoApiViewModel<EquipamentoPesquisaViewModel[]>> {

    let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel()
    
    pesquisaGeral.select = `equipamento.*`

    pesquisaGeral.calcRowCount = 1;

    pesquisaGeral.limit = pesquisa?.quantidadeRegistrosPagina || 100;

    pesquisaGeral.where = ` and equipamento.ativo = ${pesquisa?.desativados ? 0 : 1 }`;

    pesquisaGeral.start = pesquisa?.start || 0;

    pesquisaGeral.order = pesquisa?.order ? pesquisa?.order : 'equipamento.id';
    
    if (pesquisa?.id) pesquisaGeral.where += ` and equipamento.id = ${pesquisa?.id}`;

    if (pesquisa?.codigo) pesquisaGeral.where += ` and lower(equipamento.codigo) like lower('%` + pesquisa?.codigo + `%')`;

    if (pesquisa?.descricao) pesquisaGeral.where += ` and lower(equipamento.descricao) like lower('%` + pesquisa?.descricao + `%')`;

    return this.http.post<RetornoApiViewModel<EquipamentoPesquisaViewModel[]>>(`${this.urlAPI}/get`, pesquisaGeral)
  }

  obterPorId(id: number): Observable<RetornoApiViewModel<EquipamentoViewModel[]>> {

    let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel()
    
    pesquisaGeral.select = `equipamento.*`

    pesquisaGeral.where = ` and equipamento.id = ${id}`
    
    return this.http.post<RetornoApiViewModel<EquipamentoViewModel[]>>(`${this.urlAPI}/get`, pesquisaGeral);
  }

  adicionar(cadastroViewModel: CadastroViewModel<EquipamentoViewModel>): Observable<RetornoApiViewModel<EquipamentoViewModel[]>> {
    return this.http.post<RetornoApiViewModel<EquipamentoViewModel[]>>(`${this.urlAPI}/addupdate`, cadastroViewModel);
  }

  atualizar(id: number, editarViewModel: CadastroViewModel<EquipamentoViewModel>): Observable<RetornoApiViewModel<EquipamentoViewModel>> {
    return this.http.put<RetornoApiViewModel<EquipamentoViewModel>>(`${this.urlAPI}/addupdate/${id}`, editarViewModel);
  }
}
