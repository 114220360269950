export class FreteViewModel {
    
    id?: number;
    descricao?: string;
    ativo?: number;
    codigo_ibge_municipio_inicial?: number;
    codigo_ibge_municipio_final?: number;
    vlr?: number;
    tributacao_id?: number;
    cod_cst1?: number;

    endereco_municipio_inicial_descricao?: string;
    endereco_municipio_final_descricao?: string;
    endereco_uf_inicial_sigla?: string;
    endereco_uf_final_sigla?: string;
    
}