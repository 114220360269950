import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CadastroViewModel } from '@modules/admin/view-models/cadastro/CadastroViewModel';
import { PesquisaGeralViewModel } from '@modules/admin/view-models/pesquisa/PesquisaGeralViewModel';
import { AutenticacaoService } from '@modules/autenticacao/services/autenticacao.service';
import { URLBASE, URLIMPRESSAO } from '@modules/configuration.service';
import { ArquivoBase64ViewModel } from '@modules/manager/view-models/ArquivoBase64ViewModel';
import { CtePesquisaViewModel } from '@modules/manager/view-models/fiscal/pesquisa/cte-pesquisa-view-model';
import { NfDestinadasPesquisaViewModel } from '@modules/manager/view-models/fiscal/pesquisa/nf-destinadas-pesquisa-view-model';
import { RetornoApiViewModel } from '@modules/manager/view-models/RetornoApiViewModel';
import { Uteis } from '@modules/utilizarios/uteis';
import { Observable, throwError } from 'rxjs';
import { CteDocumentosViewModel } from './../../view-models/fiscal/cte-documentos-view-model';
import { ConfiguracaoService } from '@modules/manager/sistema/configuracao/configuracao.service';
import { concatMap } from 'rxjs/operators';
import { ApiRelatorioViewModel, RelatorioViewModel } from '@modules/manager/view-models/RelatorioViewModel';

@Injectable({
  providedIn: 'root'
})
export class CteService {
  
  pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel();
  idEmpresaMaxdata = this.autenticacaoService$.acessoUsuarioValue?.idEmpresaMaxdata;
  urlGet = `${URLBASE}/${this.idEmpresaMaxdata}/cte_service/get`;
  urlAddUpdate = `${URLBASE}/${this.idEmpresaMaxdata}/cte_nf_service/addupdate`;
  urlGetDocumento = `${URLBASE}/${this.idEmpresaMaxdata}/cte_nf_service/get`;
  urlExportarXML = `${URLBASE}/${this.idEmpresaMaxdata}/cte_service/GerarXmlTmpArray`;
  urlImpressaoRel = `${URLIMPRESSAO}/api/relatorio`;

  constructor(
    private configuracao$: ConfiguracaoService,
    private http$: HttpClient, 
    private autenticacaoService$: AutenticacaoService
  ) { }

  obterTodos(pesquisa: CtePesquisaViewModel): Observable<RetornoApiViewModel<CtePesquisaViewModel[]>>{

    this.pesquisaGeral.select = `cte.*, 
                                remetente.nome as remetente_nome, 
                                destinatario.nome as destinatario_nome, 
                                endereco_municipio_inicial.codigo_ibge as municipio_carregamento_codigo_ibge, 
                                endereco_municipio_final.codigo_ibge as municipio_descarregamento_codigo_ibge`;

    this.pesquisaGeral.calcRowCount = 1;

    this.pesquisaGeral.limit = pesquisa.quantidadeRegistrosPagina || 100;

    this.pesquisaGeral.where = '';

    this.pesquisaGeral.start = pesquisa?.start || 0;

    this.pesquisaGeral.order = pesquisa?.order ? pesquisa?.order : 'cte.dt_emissao desc';
    
    if (pesquisa.numero) this.pesquisaGeral.where += ` and cte.numero = ${pesquisa.numero}`;

    let remetenteId = Uteis.SomenteNumeros((pesquisa?.remetente_nome || ''));

    if (Uteis.validaCpf(remetenteId) || Uteis.validarCNPJ(remetenteId)) 
    {
      this.pesquisaGeral.where += ` and remetente.cpf_cnpj = '${remetenteId}'`;
    } 
    else if (Number(remetenteId) && parseInt(remetenteId) > 0 || pesquisa.remetente_id) 
    {
      this.pesquisaGeral.where += ` and cte.remetente_id = ${parseInt(remetenteId) > 0 ? parseInt(remetenteId) : pesquisa.remetente_id }`;
    }

    if (parseInt(remetenteId) <= 0 && (pesquisa?.remetente_nome || '') !== '') this.pesquisaGeral.where += ` AND LOWER(remetente.nome) LIKE LOWER('%` + (pesquisa?.remetente_nome || '') + `%')`;

    let destinatarioId = Uteis.SomenteNumeros((pesquisa?.destinatario_nome || ''));

    if (Uteis.validaCpf(destinatarioId) || Uteis.validarCNPJ(destinatarioId)) 
    {
      this.pesquisaGeral.where += ` and destinatario.cpf_cnpj = '${destinatarioId}'`;
    }
    else if (Number(destinatarioId) && parseInt(destinatarioId) > 0 || pesquisa.destinatario_id)
    {
      this.pesquisaGeral.where += ` and cte.destinatario_id = ${parseInt(destinatarioId) > 0 ? parseInt(destinatarioId) : pesquisa.destinatario_id}`;
    }

    if (parseInt(destinatarioId) <= 0 && (pesquisa?.destinatario_nome || '') !== '') this.pesquisaGeral.where += ` AND LOWER(destinatario.nome) LIKE LOWER('%` + (pesquisa?.destinatario_nome || '') + `%')`;

    if (pesquisa.status)
    {
      if(pesquisa.status == 'I')
      {
        this.pesquisaGeral.where += ` and LOWER(cte.status) = LOWER('C') AND cte.inutilizacao_protocolo is not null AND cte.inutilizacao_protocolo <> '' `;
      }
      else 
      {
        this.pesquisaGeral.where += ` and LOWER(cte.status) = LOWER('${pesquisa.status}')`;
      }
    }
    else 
    {
      this.pesquisaGeral.where += ` and cte.status in ('A', 'F', 'C', 'D')`;
    }

    if (pesquisa.dt_emissao_inicial && pesquisa.dt_emissao_final) this.pesquisaGeral.where += ` and cte.dt_emissao between '${pesquisa.dt_emissao_inicial}T00:00:00' and '${pesquisa.dt_emissao_final}T23:59:59'`;
  
    if (pesquisa.operacao_id !== 0) this.pesquisaGeral.where += ` and cte.operacao_id = ${pesquisa.operacao_id}`;
     
    return this.http$.post<RetornoApiViewModel<CtePesquisaViewModel[]>>(this.urlGet, this.pesquisaGeral);
  }

  exportarXmlSaida(id_array: string){
    return this.http$.post<RetornoApiViewModel<any>>(`${this.urlExportarXML}`, { id_cte_array: id_array })
  }

  imprimirListagem(pesquisa: CtePesquisaViewModel): Observable<any> {

    let objRelatorio: RelatorioViewModel<any, any> = new RelatorioViewModel<any, any>();

    let logomarca: string = null;

    return this.obterTodos(pesquisa).pipe(
      concatMap(retorno => {

        objRelatorio.obj = retorno.total > 0 ? retorno.result : [];

        if (retorno.total <= 0) return throwError({ error: { errorCode: 404, errorText: 'Sem movimentação' } });

        return this.configuracao$.obterImagem(this.autenticacaoService$.acessoUsuarioValue?.idLoja || 0)
      }),
      concatMap(retorno => {

        logomarca = retorno.result[0].img_logomarca

        return this.configuracao$.obterPorId(this.autenticacaoService$.acessoUsuarioValue?.idLoja || 0)
      }),
      concatMap(retorno => {
        objRelatorio.params = Object.assign({
          emp_razao: retorno.total > 0 ? retorno.result[0].emp_razao : '',
          emp_fantasia: retorno.total > 0 ? retorno.result[0].emp_fantasia : '',
          emp_cpf_cnpj: retorno.total > 0 ? retorno.result[0].emp_cnpj : '',
          emp_fone: retorno.total > 0 ? retorno.result[0].emp_fone : '',
          emp_endereco: retorno.total > 0 ? retorno.result[0].emp_endereco : '',
          emp_endereco_municipio_descricao: retorno.total > 0 ? retorno.result[0].emp_endereco_municipio_descricao : '',
          emp_logo: logomarca,
        });

        const apiRelatorio = new ApiRelatorioViewModel<any, any>();

        apiRelatorio.relatorio = Object.assign(objRelatorio);

        apiRelatorio.subrelatorio = [];

        return this.imprimir(`listagem_cte`, apiRelatorio);        

      })
    )

  }

  imprimir(relatorio, apiRelatorio) {
    return this.http$.post<any>(`${this.urlImpressaoRel}/${relatorio}/`, apiRelatorio);
  }

}
