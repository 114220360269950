import { Component, OnInit } from '@angular/core';
import { TituloPagina } from 'src/modules/utilizarios/tituloPaginas/tituloPaginas';
import { HistoricoNavegacao } from 'src/modules/utilizarios/tituloPaginas/historicoNavegacao';
import { UsuarioPesquisaViewModel } from '../../view-models/pesquisa/UsuarioPesquisaViewModel';
import { ListaPaginadaViewModel } from 'src/modules/manager/view-models/ListaPaginadaViewModel';
import { UsuarioViewModel } from '../../view-models/cadastro/UsuarioViewModel';
import { QuantidadeItensPaginacao } from 'src/modules/utilizarios/quantidadeItensPaginacao';
import { UsuarioService } from './usuario.service'
import { AlertMessage } from 'src/modules/alert.configuration.service';
import { Uteis } from 'src/modules/utilizarios/uteis';

import { PesquisaGeralViewModel } from '@modules/admin/view-models/pesquisa/PesquisaGeralViewModel';

import { from } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-usuario',
  templateUrl: './usuario.component.html',
  styles: []
})
export class UsuarioComponent implements OnInit {

  tituloPagina: TituloPagina = new TituloPagina("Usuários - Pesquisa",
    [
      new HistoricoNavegacao("Início", "/admin"),
      new HistoricoNavegacao("Cadastro"),
      new HistoricoNavegacao("Usuários"),
      new HistoricoNavegacao("Novo", "novo")
    ]);

  pesquisa!: UsuarioPesquisaViewModel;
  listaPaginada: ListaPaginadaViewModel<UsuarioViewModel> = new ListaPaginadaViewModel<UsuarioViewModel>();
  listaItensPaginacao: QuantidadeItensPaginacao[] = QuantidadeItensPaginacao.listaQuantidades;


  constructor(private usuario$: UsuarioService, private alert: AlertMessage, private spinner: NgxSpinnerService) { }

  ngOnInit() {

    this.pesquisa = Uteis.recuperarFiltro('adm_usuario', new UsuarioPesquisaViewModel());

    this.pesquisar()
  }

  pesquisar() {

    if (this.pesquisa === undefined) this.pesquisa = new UsuarioPesquisaViewModel();

    this.spinner.show();

    this.usuario$.obterTodos(this.pesquisa).subscribe(
      retorno => {
        
        this.listaPaginada.itens = retorno.total > 0 ? retorno.result : [];

        this.listaPaginada.paginacao.totalRegistros = retorno.total;

        this.listaPaginada.paginacao.quantidadeRegistrosPagina = this.pesquisa.quantidadeRegistrosPagina;

        this.spinner.hide();

        Uteis.atualizarFiltro('adm_usuario', this.pesquisa);

      },
      error => {

        this.spinner.hide()

        this.alert.Danger('Erro', Uteis.ObterErroApi(error))

      })
  }

  mudarPagina(event: any) {

    this.pesquisa.start = (event.page - 1) * this.pesquisa.quantidadeRegistrosPagina;
    this.pesquisa.pagina= event.page;
    this.pesquisar();
  }
  

  ordenarLista(campo: string) {

    this.pesquisa.order = campo;

    this.pesquisar();
    
  }
}
