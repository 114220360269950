
<div class="row">
  <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
      <app-titulo-paginas [tituloPagina]="tituloPagina"></app-titulo-paginas>
  </div>
  <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
      <div class="text-right btns-group">
          <button class="btn btn-sm btn-outline-info" (click)="pesquisar()"><i
                  class="material-icons">search</i>Pesquisar</button>
          <button class="btn btn-sm btn-outline-success" (click)="modalMenu = true; novoItem();"><i
                  class="material-icons">add</i>Novo</button>
      </div>
  </div>
</div>
<div class="card shadow-sm">
  <div class="card-body pt-1 pb-1">
      <div class="row">

          <div class="col-sm-4 col-md-4 col-lg-2 col-xl-1">
              <div class="form-group">
                  <label for="codigo" class="col-form-label-sm">Id</label>
                  <input type="number" id="codigo" name="codigo" class="form-control form-control-sm noscroll"
                      placeholder="Id" autocomplete="off" [(ngModel)]="pesquisa.id">
              </div>
          </div>

          <div class="col-sm-12 col-md-12 col-lg-4 col-xl-2" >
            <div class="form-group">
                <label for="grupo_pesquisa" class="col-form-label-sm">Grupo</label>
                <select id="grupo_pesquisa" name="grupo_pesquisa" class="form-control form-control-sm" placeholder="grupo_pesquisa" autocomplete="off"
                [(ngModel)]="pesquisa.lst_grupo_menu" (change)="pesquisar()">
                    <option [ngValue]="0">Todos</option>
                    <option [ngValue]="1">Cadastro</option>
                    <option [ngValue]="2">Movimentação</option>
                    <option [ngValue]="3">Fiscal</option>
                    <option [ngValue]="4">Financeiro</option>
                    <option [ngValue]="5">Relatórios</option>
                    <option [ngValue]="6">Sistema</option>
                    <option [ngValue]="7">Administrativo</option>
                </select>
            </div>
        </div>

      </div>
  </div>
  <div class="card-body pt-1 pb-1 border-top rounded-0 bg-white">

      <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div class="table-responsive">
                  <table class="table table-sm table-borderless table-striped table-hover">
                      <thead class="thead">
                          <th >Id</th>
                          <th >Grupo</th>
                          <th >Descrição</th>
                          <th >Id Menu</th>
                      </thead>
                      <tbody>
                          <tr *ngFor="let item of listaPaginada.itens; let i = index;" class="cursor" (click)="editarItem(i); this.modalMenu = true;">
                              <td>{{item.id}}</td>
                              <td>{{
                                item.lst_grupo_menu == 1 ? 'Cadastro' : 
                                item.lst_grupo_menu == 2 ? 'Movimentação' : 
                                item.lst_grupo_menu == 3 ? 'Fiscal' : 
                                item.lst_grupo_menu == 4 ? 'Financeiro' : 
                                item.lst_grupo_menu == 5 ? 'Relatórios' : 
                                item.lst_grupo_menu == 6 ? 'Sistema' : 
                                item.lst_grupo_menu == 7 ? 'Administrativo' : 'SEM GRUPO'}}</td>
                              <td>{{item.descricao}}</td>
                              <td>{{item.id_menu}}</td>
                          </tr>
                      </tbody>
                  </table>
              </div>
          </div>
      </div>
  </div>
</div>


<p-dialog header="Menu" [(visible)]="modalMenu" [modal]="true"
    [style]="{width: '75vw'}" [maximizable]="false" [baseZIndex]="10000" [draggable]="false" [resizable]="false"
    [closeOnEscape]="true" [closable]="false" >
    <form class="mb-2">

        <div class="card mb-1 shadow-sm" id="target"></div>
        <div class="row">

            <div class="col-sm-12 col-md-12 col-lg-4 col-xl-1" >
                <div class="form-group">
                    <label for="id" class="col-form-label-sm">Id</label>
                    <input type="number" id="id" name="id" class="form-control form-control-sm noscroll"
                        placeholder="Id" autocomplete="off" [(ngModel)]="menu.id" disabled="true">
                </div>
            </div>

            <div class="col-sm-12 col-md-12 col-lg-4 col-xl-2" >
              <div class="form-group">
                  <label for="grupo" class="col-form-label-sm">Grupo</label>
                  <select id="grupo" name="grupo" class="form-control form-control-sm" placeholder="grupo" autocomplete="off"
                  [(ngModel)]="menu.lst_grupo_menu">
                      <option [ngValue]="1">Cadastro</option>
                      <option [ngValue]="2">Movimentação</option>
                      <option [ngValue]="3">Fiscal</option>
                      <option [ngValue]="4">Financeiro</option>
                      <option [ngValue]="5">Relatórios</option>
                      <option [ngValue]="6">Sistema</option>
                      <option [ngValue]="7">Administrativo</option>
                  </select>
              </div>
          </div>

            <div class="col-sm-12 col-md-12 col-lg-4 col-xl-3" >
                <div class="form-group">
                    <label for="descricao" class="col-form-label-sm">Descricao</label>
                        <input type="text" id="descricao" name="descricao" class="form-control form-control-sm" 
                        placeholder="Descrição" autocomplete="off" [(ngModel)]="menu.descricao">
                </div>
            </div>

            <div class="col-sm-12 col-md-12 col-lg-4 col-xl-3" >
                <div class="form-group">
                    <label for="id_menu" class="col-form-label-sm">Id Menu</label>
                    <input type="text" id="id_menu" name="id_menu" class="form-control form-control-sm noscroll"
                        placeholder="Id Menu" autocomplete="off" [(ngModel)]="menu.id_menu">
                </div>
            </div>
            
        </div>

    </form>

    <div class="text-right btns-group">
      <button class="btn btn-sm btn-outline-maxdata mr-5" id="remover_item" type="button" (click)="remover()" *ngIf="alterar == true"><i class="material-icons">cancel</i>Remover Item</button>
      <button class="btn btn-sm btn-outline-secondary mr-3" id="cancelar_item" type="button" (click)="modalMenu = false; alterar = false;">Cancelar</button>
      <button class="btn btn-sm btn-outline-success" id="confirmar_item" type="button" (click)="onSubmit()"><i class="material-icons" >done_all</i>Salvar</button>        
    </div>


</p-dialog>
