import { HttpParams, HttpErrorResponse } from '@angular/common/http';
import { AbstractControl } from '@angular/forms';
import { formatDate } from '@angular/common';
import { QuantidadeItensPaginacao } from 'src/modules/utilizarios/quantidadeItensPaginacao';

export class Uteis {
    static NomeDosMesesAno: string[] = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
    static QuantidadeItensPaginacao: string[] = ['10', '20', '40', '60', '100', '200', '500', '1000'];

    static truncar(numero, digitos) { return Math.trunc(numero * Math.pow(10, digitos)) / Math.pow(10, digitos) }
    static DateTimeToString(dt: Date | string): string {
        dt = new Date(dt);
        var dia = dt.getDate() + 1;
        var mes = dt.getMonth() + 1;
        var ano = dt.getFullYear();
        var hora = dt.getHours();
        var minuto = dt.getMinutes();
        var segundo = dt.getSeconds();

        // return `${this.padLeft(dia, 2)}/${this.padLeft(mes, 2)}/${ano} ${this.padLeft(hora, 2)}:${this.padLeft(minuto, 2)}:${this.padLeft(segundo, 2)}`;
        return `${this.padLeft(dia, 2)}/${this.padLeft(mes, 2)}/${ano}`;
    }

    static DateCadastroToString(date: any): string {
        var dia = date.getDate();
        var mes = date.getMonth() + 1;
        var ano = date.getFullYear();

        return `${ano}-${this.padLeft(mes, 2)}-${this.padLeft(dia, 2)}`
    }

    static DateConsultaToString(data: any): string {
        var data = data.split(',');

        var dataInicial: Date = new Date(data[0]);
        var dataFinal: Date = new Date(data[1]);

        var diaInicial = dataInicial.getDate();
        var mesInicial = dataInicial.getMonth() + 1;
        var anoInicial = dataInicial.getFullYear();

        var diaFinal = dataFinal.getDate();
        var mesFinal = dataFinal.getMonth() + 1;
        var anoFinal = dataFinal.getFullYear();

        return `'${anoInicial}/${this.padLeft(mesInicial, 2)}/${this.padLeft(diaInicial, 2)}' and '${anoFinal}/${this.padLeft(mesFinal, 2)}/${this.padLeft(diaFinal, 2)}'`
    }

    static DateConverterRetorno(data: string) {
        if (data.length > 0) {
            return new Date(data.replace(/-/g, '/'))
        }
        return '';
    }

    static DateConverterLista(data: any) {
        return data.map((data: any) => {
            var dtAbertura: Date = data.dtAbertura.split('-');
            dtAbertura = new Date(dtAbertura);
            var dia = dtAbertura.getDate()
            var mes = dtAbertura.getMonth() + 1
            var ano = dtAbertura.getFullYear()
            const dtAberturaFormat = dia + '/' + mes + '/' + ano
            return Object.assign(data, { dtAbertura: dtAberturaFormat })
        })
    }

    static DateTimeZone(value: any) {

        if (typeof value === 'undefined') return "";

        let data = formatDate(value, 'yyyy-MM-dd HH:mm', 'pt', '-0300');
        data = data.replace(' ', 'T')
        return data;
    }

    static DateTimeText(value: any) {
        let data = formatDate(value, 'dd-MM-yyyy_HH:mm:ss', 'pt', '-0300');
        data = data.replace(' ', 'T')
        return data;
    }

    static Date(value: any) {
        let data = formatDate(value, 'dd/MM/yyyy', 'pt', '');
        data = data.replace(' ', 'T')
        return data;
    }

    static DateToBackend(value: any) {
        let data = formatDate(value, 'yyyy-MM-dd', 'pt');
        data = data.replace(' ', 'T')
        return data;
    }

    static DateAtual(value: any) {
        let data = formatDate(value, 'dd/MM/yyyy', 'pt', '+0300');
        data = data.replace(' ', 'T')
        return data;
    }

    static DateTimeZoneEnvio(value: any) {
        let data = formatDate(value, 'yyyy-MM-dd HH:mm:ss', 'pt', '-0300');
        data = data.replace('T', ' ')
        return data;

    }

    static DateTimeZoneRetorno(value: any) {

        if (!value) return null;

        let data = formatDate(value, 'dd/MM/yyyy HH:mm:ss', 'pt', '-0300');

        data = data.replace('T', ' ')

        return data;

    }


    static DiaDaSemana(data: Date | string): string {
        var dt = new Date(data);
        return dt.toLocaleString('pt', { weekday: 'long' })
    }

    static EhDiaUtil(data: Date | string): boolean {
        if (data === undefined || data === null || data === '') return false;
        const diaDaSemana = this.DiaDaSemana(data);
        switch (diaDaSemana.toLowerCase()) {
            default: return true;
            case 'sábado':
            case 'domingo': return false;
        }
    }

    static parseDate(data: string, formatoDataPtBr: boolean = false): Date {
        const dataHora = data.indexOf('T') >= 0 ? data.split('T') : data.indexOf(' ') >= 0 ? data.split(' ') : [data];
        const str = dataHora[0].indexOf('/') >= 0 ? dataHora[0].split('/') : dataHora[0].indexOf('-') >= 0 ? dataHora[0].split('-') : [];
        if (str.length <= 0) return null;
        const year = Number(str[formatoDataPtBr ? 2 : 0]);
        const month = Number(str[1]) - 1;
        const date = Number(str[formatoDataPtBr ? 0 : 2]);
        if (dataHora.length == 1) return new Date(year, month, date);

        const time = dataHora[1].split(':');
        const hour = Number(time[0]);
        const minute = Number(time[1]);
        const seconds = Number(time[2]);
        return new Date(year, month, date, hour, minute, seconds);
    }

    static ProximoDiaUtil(data: Date | string, usarLocalidadePtBrNoRetorno: boolean = false): string {
        if (data === undefined || data === null || data === '') return '';
        data = this.parseDate(typeof data === 'string' ? data : formatDate(data, 'yyyy-MM-dd hh:mm:ss', 'pt'));
        while (true) {
            const diaDaSemana = this.DiaDaSemana(data).toLowerCase();
            switch (diaDaSemana) {
                case 'sábado':
                    data = this.AddDiaEmData(data, 2);
                    return this.ProximoDiaUtil(data);
                case 'domingo':
                    data = this.AddDiaEmData(data, 1);
                    return this.ProximoDiaUtil(data);
            }
            return formatDate(data, usarLocalidadePtBrNoRetorno ? 'dd/MM/yyyy' : 'yyyy-MM-dd', 'pt');
        }
    }

    static AddDiaEmData(data: Date | string, qtdDias: number): Date {
        if (data === undefined || data === null || data === '' || !Number.isInteger(qtdDias)) return null;
        data = this.parseDate(typeof data === 'string' ? data : formatDate(data, 'yyyy-MM-dd hh:mm:ss', 'pt'));
        data.setDate(data.getDate() + qtdDias);
        return data;
    }
    static AddMesEmData(data: Date | string, qtdMeses: number): Date {
        if (data === undefined || data === null || data === '' || !Number.isInteger(qtdMeses)) return null;
        data = this.parseDate(typeof data === 'string' ? data : formatDate(data, 'yyyy-MM-dd hh:mm:ss', 'pt'));
        data.setMonth((data.getMonth() + 1) + qtdMeses);
        return data;
    }
    static AddAnoEmData(data: Date | string, qtdAnos: number): Date {
        if (data === undefined || data === null || data === '' || !Number.isInteger(qtdAnos)) return null;
        data = this.parseDate(typeof data === 'string' ? data : formatDate(data, 'yyyy-MM-dd hh:mm:ss', 'pt'));
        data.setFullYear(data.getFullYear() + qtdAnos);
        return data;
    }
    static ObterUltmimoDiaMes(ano: number, mes: number): number {
        if (!Number.isInteger(ano)) throw 'Uteis.ObterUltmimoDiaMes - Ano deve ser Número Inteiro.';
        if (!Number.isInteger(mes)) throw 'Uteis.ObterUltmimoDiaMes - Mês deve ser Número Inteiro.'
        mes = mes === 0 || mes === 11 ? mes + 1 : mes;
        if (mes < 0 || mes > 12) throw 'Uteis.ObterUltmimoDiaMes - Mês pode ser >= 0 ou <= 12.';

        const data = new Date(ano, mes, 0);
        return data.getDate();
    }
    static DataEhValida(data: Date | string): boolean {
        try {
            data = new Date(data);
            if (Object.prototype.toString.call(data) === "[object Date]") return !isNaN(data.getTime());
            return false;
        } catch {
            return false;
        }
    }

    static AdicionarFocoEmCampoNoModal(nameInput: string) {
        if (nameInput === undefined || nameInput === null || nameInput === '') throw 'Uteis.AdicionarFocoEmCampoNoModal - [name] do input não informado.';
        setTimeout(() => (document.querySelector(`[name="${nameInput}"]`) as HTMLElement)?.focus(), 300);
    }

    static padLeft(num: number, size: number): string {
        let s = num + '';
        while (s.length < size) s = '0' + s;
        return s;
    }

    static ObjetoToQueryString(objeto: object): string {
        return Object.keys(objeto).map(key => objeto[key] === null ? '' : key + '=' + objeto[key]).join('&')
    }

    static ObjetoToQueryStringInHttpParams(objeto: object): HttpParams {
        let paramsStr = this.ObjetoToQueryString(objeto);
        let teste = new HttpParams({ fromString: paramsStr })
        return new HttpParams({ fromString: paramsStr });
    }

    static ObjetoToBodyString(objeto: object): string {
        return JSON.stringify(objeto);
    }

    static ObjetoToBodyJson(objeto: object): string {
        let objetoString = this.ObjetoToBodyString(objeto);
        return JSON.parse(objetoString);
    }

    static SomenteNumeros(texto: string): string {
        if (texto === null) {
            return texto
        }
        return texto.replace(/[^\d]+/g, '');
    }

    static ObterErroApi(error: HttpErrorResponse): string | any {
        if (typeof error.error === 'undefined') return error.toString().split('\n');
        // if ('errors' in error.error)
        //     return this.ConverterErrosEmTextoHtml(error.error.errors);
        if (error.error.error) {
            var messages = '';
            for (let key in error.error.error) {
                if (error.error.error.hasOwnProperty(key)) {
                    let e = error.error.error[key];
                    if (typeof e == 'object') {
                        for (let k in e) {
                            if (e.hasOwnProperty(k)) {
                                let f = e[k];
                                if (typeof f == 'object') {
                                    for (let g in f) {
                                        if (f.hasOwnProperty(g)) {
                                            let h = f[g];
                                            messages = h;
                                        }
                                    }
                                } else {
                                    messages = f;
                                }
                            }
                        }
                    } else {
                        messages = e;
                    }
                } else {
                }
            }
            let msg = messages.split('\n');
            return this.RemoverCamposVazioArray(msg);

        } else if (error.error.errorText) {
            let errorText = error.error.errorText.split('\n')
            // return ('errorText' in error.error ? error.error.errorText : 'Erro desconhecido');
            return this.RemoverCamposVazioArray(errorText)
        } else if (error.message) {
            let errorText = error.message.split('\n');
            return this.RemoverCamposVazioArray(errorText);
        } else {
            let errorText = error.error.split('\n');
            return this.RemoverCamposVazioArray(errorText);
        }
    }


    static ConverterErrosEmTextoHtml(errors: any) {
        if (errors === undefined || errors === null || errors.length <= 0) return '';

        const ultimoErro = errors[errors.length - 1];
        return errors.map(erro => `${erro}${erro === ultimoErro ? '' : '<br/>'}`);
    }

    static ValidacaoCFOP(controle: AbstractControl) {
        if (controle.value === null || controle.value === 0) return null;
        let cfop = controle.value;
        cfop = cfop.toString().substr(0, 1);
        if (cfop >= 1 && cfop <= 3 || cfop >= 5 && cfop <= 7) return null;
        return { cfopInvalido: true }

    }

    static ConverterObjetoToArray(dados: Object) {
        return this.RemoverCamposVazioArray(Object.values(dados));
    }

    static RemoverCamposVazioArray(dados: Array<any>) {
        return dados.filter(dadosArray => dadosArray != "");
    }

    // Para os filtros de pesquisa
    static recuperarFiltro(nome: string, objeto: any) {
        const maxFiltros = JSON.parse(sessionStorage.getItem('max-filtros'));
        if (maxFiltros !== null && maxFiltros.hasOwnProperty(nome)) {
            return maxFiltros[nome];
        }
        else {
            this.adicionarFiltro(nome, objeto);
            return objeto;
        }
    }

    static adicionarFiltro(nome: string, objeto: any) {
        const maxFiltros = JSON.parse(sessionStorage.getItem('max-filtros'));
        if (maxFiltros !== null) {
            maxFiltros[nome] = objeto;
            sessionStorage.setItem('max-filtros', JSON.stringify(maxFiltros))
        }
        else {
            const maxFiltrosAdicionar = new Object();
            maxFiltrosAdicionar[nome] = objeto;
            sessionStorage.setItem('max-filtros', JSON.stringify(maxFiltrosAdicionar))
        }
    }

    static atualizarFiltro(nome: string, objeto: any) {
        const maxFiltros = JSON.parse(sessionStorage.getItem('max-filtros'));
        if (maxFiltros !== null) {
            maxFiltros[nome] = objeto;
            sessionStorage.setItem('max-filtros', JSON.stringify(maxFiltros))
        }
    }

    static excluirFiltro() {
        sessionStorage.removeItem('max-filtros');
    }

    static converterDinheiro(valor: any) {
        valor = valor.toString();
        valor = valor.replace('R$', '')
        valor = valor.replace('.', '');
        valor = valor.replace(',', '.');
        return parseFloat(valor);
    }

    // static converterDinheiroRetorno(valor: any) {
    //     valor = valor.toLocaleString('pt-br', { minimumFractionDigits: 2 })
    //     return valor;
    // }

    static removerCaracteresDinheiro(valor: any) {
        valor = valor.toString();
        valor = valor.replace(/\R$/g, '')
        valor = valor.replace(/\./g, '');
        valor = valor.replace(/\,/g, '');
        valor = valor.replace(/\-/g, '');
        valor = valor.replace(/\*/g, '');
        valor = valor.replace(/^0+/, '')
        return valor;
    }

    static formatarDinheiro(valor: any, decimais: number) {
        let d = decimais || 2;
        let vlr: string = this.removerCaracteresDinheiro(valor);
        if (vlr.length == 1) return '0,0' + vlr;
        if (vlr.length == 2) return vlr.replace(/([0-9]{2}$)/g, "0,$1");
        if (vlr.length >= 3 && vlr.length < 6) return vlr.replace(/([0-9]{2}$)/g, ",$1");
        if (vlr.length >= 6 && vlr.length < 9) return vlr.replace(/([0-9]{3})([0-9]{2}$)/g, ".$1,$2")
        if (vlr.length >= 9 && vlr.length < 12) return vlr.replace(/([0-9]{3})([0-9]{3})([0-9]{2}$)/g, ".$1.$2,$3")
        if (vlr.length >= 12) return vlr.replace(/([0-9]{3})([0-9]{3})([0-9]{3})([0-9]{2}$)/g, ".$1.$2.$3,$4")
        return '0,00'
    }

    static verificarIsNumber(value: any) {
        value = value.replace(/[^\d]+/g, '');
        var er = /^[0-9]+$/;
        return er.test(value);
    }

    static validaCpf(cpf) {
        // const cpf = controle.value;
        cpf = cpf.replace(/[^\d]+/g, '');

        let soma: number = 0;
        let resto: number;
        let valido: boolean;

        const regex = new RegExp('[0-9]{11}');

        if (
            cpf == '00000000000' ||
            cpf == '11111111111' ||
            cpf == '22222222222' ||
            cpf == '33333333333' ||
            cpf == '44444444444' ||
            cpf == '55555555555' ||
            cpf == '66666666666' ||
            cpf == '77777777777' ||
            cpf == '88888888888' ||
            cpf == '99999999999' ||
            !regex.test(cpf)
        )
            valido = false;
        else {
            for (let i = 1; i <= 9; i++)
                soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);
            resto = (soma * 10) % 11;

            if (resto == 10 || resto == 11) resto = 0;
            if (resto != parseInt(cpf.substring(9, 10))) valido = false;

            soma = 0;
            for (let i = 1; i <= 10; i++)
                soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);
            resto = (soma * 10) % 11;

            if (resto == 10 || resto == 11) resto = 0;
            if (resto != parseInt(cpf.substring(10, 11))) valido = false;
            valido = true;
        }

        if (valido) return true;

        return false;
    }

    static validarCNPJ(cnpj) {

        cnpj = cnpj.replace(/[^\d]+/g, '');

        if (cnpj == '') return false;

        if (cnpj.length != 14)
            return false;

        // Elimina CNPJs invalidos conhecidos
        if (cnpj == "00000000000000" ||
            cnpj == "11111111111111" ||
            cnpj == "22222222222222" ||
            cnpj == "33333333333333" ||
            cnpj == "44444444444444" ||
            cnpj == "55555555555555" ||
            cnpj == "66666666666666" ||
            cnpj == "77777777777777" ||
            cnpj == "88888888888888" ||
            cnpj == "99999999999999")
            return false;

        // Valida DVs
        let tamanho = cnpj.length - 2
        let numeros = cnpj.substring(0, tamanho);
        let digitos = cnpj.substring(tamanho);
        let soma = 0;
        let pos = tamanho - 7;
        for (let i = tamanho; i >= 1; i--) {
            soma += numeros.charAt(tamanho - i) * pos--;
            if (pos < 2)
                pos = 9;
        }
        let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado != digitos.charAt(0))
            return false;

        tamanho = tamanho + 1;
        numeros = cnpj.substring(0, tamanho);
        soma = 0;
        pos = tamanho - 7;
        for (let i = tamanho; i >= 1; i--) {
            soma += numeros.charAt(tamanho - i) * pos--;
            if (pos < 2)
                pos = 9;
        }
        resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado != digitos.charAt(1))
            return false;

        return true;

    }

    static b64toBlob(b64Data: string, contentType: string = '', sliceSize: number = 512) {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    static RemoverPropriedadesNulas<T>(objeto: T): T {
        var propNames = Object.getOwnPropertyNames(objeto);
        for (var i = 0; i < propNames.length; i++) {
            var propName = propNames[i];
            if (objeto[propName] === null || objeto[propName] === undefined) delete objeto[propName];
        }
        return objeto;
    }

    static validarKeysPermitidas(key) {
        var keysTecladoPermitido: string[] = ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p', 'a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', 'ç', 'z', 'x', 'c', 'v', 'b', 'n', 'm'];
        if (keysTecladoPermitido.indexOf(key.toLowerCase()) === -1) { return false }
        else { return true; }
    }

    /**  Calcular Desconto */

    static calcularPorcentagemDesconto(valorComDesconto: number, valorSemDesconto: number) {
        let desconto = (((valorComDesconto - valorSemDesconto) / valorSemDesconto) * 100)
        return Math.abs(desconto)
    }

    static removerCasasDecimais(valor: number, casasDecimais: number): number {
        const strNumero = valor.toString();
        const [parteInteira, parteDecimal = ''] = strNumero.split('.');
        const numeroTruncado = parseFloat(`${parteInteira}.${parteDecimal.slice(0, casasDecimais)}`);
        return numeroTruncado;

    }

    static apenasNumeros(e: any, ...allowed: any[]) {
        const allowedKeys = ['Backspace', 'Enter', ...allowed];

        let event = e || window.event
        let key = event.key, regex = /^[0-9.]+$/;

        if (!regex.test(key) && !allowedKeys.some(i => i === key)) {
            event.returnValue = false;
            if (event.preventDefault) event.preventDefault();
        };
    }

    static ValidarEAN13(code: string): boolean{

        if (!/^\d{13}$/.test(code)) {
            // O código deve ter exatamente 13 dígitos numéricos
            return false;
          }
        
          // Obtém os dígitos do código
          const digits = code.split('').map(Number);
        
          // Calcula o dígito verificador
          const checksum = digits.slice(0, 12).reduce((sum, digit, index) => {
            return index % 2 === 0 ? sum + digit : sum + digit * 3;
          }, 0);
        
          const checkDigit = (10 - (checksum % 10)) % 10;
        
          // Verifica se o dígito verificador corresponde ao último dígito do código
          return digits[12] === checkDigit;
    }
}