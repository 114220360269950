import { Injectable } from '@angular/core';
import {URLBASE} from "@modules/configuration.service";
import {HttpClient} from "@angular/common/http";
import {AutenticacaoService} from "@modules/autenticacao/services/autenticacao.service";
import {PesquisaGeralViewModel} from "@modules/admin/view-models/pesquisa/PesquisaGeralViewModel";
import {Observable} from "rxjs";
import {RetornoApiViewModel} from "@manager/view-models/RetornoApiViewModel";
import {EcommerceDepartamentoPesquisaViewModel} from "@manager/view-models/cadastro/ecommerce/pesquisa/ecommerce-departamento-pesquisa-view-model";
import {EcommerceDepartamentoViewModel} from "@manager/view-models/cadastro/ecommerce/ecommerce-departamento-view-model";
import {CadastroViewModel} from "@modules/admin/view-models/cadastro/CadastroViewModel";

@Injectable({
  providedIn: 'root'
})
export class DepartamentoService {

  idEmpresaMaxdata = this.autenticacaoService.acessoUsuarioValue?.idEmpresaMaxdata;

  urlGet = `${URLBASE}/${this.idEmpresaMaxdata}/ecommerce_departamento_service/get`;

  urlAddUpdate = `${URLBASE}/${this.idEmpresaMaxdata}/ecommerce_departamento_service/addupdate`;
  updateBlob = `${URLBASE}/${this.idEmpresaMaxdata}/ecommerce_departamento_service/UpdateBlob`;
  getBlob = `${URLBASE}/${this.idEmpresaMaxdata}/ecommerce_departamento_service/getBlob`;

  constructor(private http: HttpClient, private autenticacaoService: AutenticacaoService) { }

  obterTodos(pesquisa: EcommerceDepartamentoPesquisaViewModel) : Observable<RetornoApiViewModel<EcommerceDepartamentoPesquisaViewModel[]>> {

    let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel();

    pesquisaGeral.select = `ecommerce_departamento.*`;
    pesquisaGeral.calcRowCount = 1;
    pesquisaGeral.limit = pesquisa?.quantidadeRegistrosPagina || 100;
    pesquisaGeral.where = ` and ecommerce_departamento.ativo = ${pesquisa?.desativados ? 0 : 1}`;
    pesquisaGeral.order = ` ecommerce_departamento.id`;
    pesquisaGeral.start = pesquisa?.start || 0;

    if (pesquisa?.id) pesquisaGeral.where += ' and ecommerce_departamento.id = ' + pesquisa?.id;
    if (pesquisa?.descricao) pesquisaGeral.where += ` and lower(ecommerce_departamento.descricao) like lower('%` + pesquisa?.descricao + `%')`;

    return this.http.post<RetornoApiViewModel<EcommerceDepartamentoPesquisaViewModel[]>>(this.urlGet, pesquisaGeral);
  }

  obterPorId(id: number): Observable<RetornoApiViewModel<EcommerceDepartamentoViewModel[]>>{

    let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel();

    pesquisaGeral.select = `ecommerce_departamento.*`;

    pesquisaGeral.where = ` and ecommerce_departamento.id = ${id}`;

    return this.http.post<RetornoApiViewModel<EcommerceDepartamentoViewModel[]>>(`${this.urlGet}`,pesquisaGeral);
  }

  adicionar(ecommerceDepartamentoViewModel: CadastroViewModel<EcommerceDepartamentoViewModel>) : Observable<any>{

    return this.http.post<any[]>(this.urlAddUpdate, ecommerceDepartamentoViewModel);

  }

  atualizar(id: number, ecommerceDepartamentoViewModel: CadastroViewModel<EcommerceDepartamentoViewModel>) : Observable<any>{

    return this.http.put<any[]>(`${this.urlAddUpdate}/${id}`, ecommerceDepartamentoViewModel);

  }

  imprimir() { }


}
