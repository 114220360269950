import { PaginacaoViewModel } from '../../../PaginacaoViewModel';
import { TributacaoViewModel } from '../tributacao-view-model';

export class TributacaoPesquisaViewModel extends TributacaoViewModel implements PaginacaoViewModel {
    pagina: number = 1;
    quantidadeRegistrosPagina: number = 20;
    totalRegistros: number = 0;
    totalPaginas: number = 0;
    desativados: boolean = false;
    start?: number = 0;
    order?: string;
}