<mat-sidenav-container class="sidenav-container" style="height: 100% !important;">

        <p-menubar  [model]="items">
            
            <div class="row zoom mr-0">

                <div class="col-xs-10 mt-2 mr-1">
                    <input placeholder="Pesquise por uma tela" id="menu" name="menu" autocomplete="off" [(ngModel)]="itemSelecionado" [typeahead]="dataSource" [typeaheadAsync]="true" typeaheadOptionField="label" class="form-control form-control-sm " (typeaheadOnSelect)="onSelect($event)"
                        typeaheadGroupField="grupo" [typeaheadScrollable]="true" [typeaheadOptionsInScrollableView]="10" (typeaheadNoResults)="typeaheadNoResults($event)" [ngClass]="{'is-invalid': noResult}" style="min-width: 250px;">
                </div>
                
                <div class="mt-2 mb-2">
                    <p-menu #menuPopUp [popup]="true" [model]="itensMenuPopUp"></p-menu>
                    <button class="btn btn-sm btn-light" (click)="menuPopUp.toggle($event)" style="border-color:#fff !important;"><i class="material-icons m-0">more_vert</i></button>
                </div>
            </div>
        </p-menubar >
        
        <app-menu *ngIf="acessoUsuario"></app-menu>

        <div class="main-container mt-1" *ngIf="acessoUsuario" >
            
            <div class="alert alert-warning text-center" role="alert" *ngIf="sistemaHomologacao">Sistema de homologação</div>

            <router-outlet></router-outlet>

        </div>

        <app-footer ></app-footer>

        
    
</mat-sidenav-container>

<mat-menu #opcoes="matMenu">
    <button mat-menu-item (click)="sair()">Sair</button>
</mat-menu>

<p-dialog [(visible)]="modalAvisoChaveAtivacao" [modal]="true" [closable]="true" [style]="{width: '80vw'}"
    [maximizable]="false" [baseZIndex]="10000" [draggable]="false" [resizable]="false" class="notificacao-chave">
    
    <p-header>Ativação do Sistema</p-header>
    
    <div class="alert text-center" role="alert" [ngClass]="chaveAtivacaoVencida ? 'alert-danger' : 'alert-success'">Chave de ativação válida até <b>{{acessoUsuario.dtValidadeChave | date : 'dd/MM/yyyy'}}</b></div>

    <div class="row text-center" *ngIf="chaveAtivacaoVencida">
        <p>Após {{ posDataString | date : 'dd/MM/yyyy' }}, o Sistema ficará disponível somente para consulta.</p>
    </div>

    <div class="d-flex justify-content-end">
        <div class="btns-group">
            <button class="btn btn-sm btn-outline-secondary" (click)="modalAvisoChaveAtivacao = false"><i
                class="material-icons">close</i>Fechar</button>
        </div>
    </div>
        
</p-dialog>


<p-dialog [(visible)]="modalContato" [modal]="true" [closable]="false" [style]="{'min-width':'50vw', 'max-width':'90vw'}"
    [maximizable]="false" [baseZIndex]="10000" [draggable]="false" [resizable]="false">
    
    <div class="row d-flex justify-content-center">

        <div class="alert text-center m-2" role="alert" [ngClass]="'alert-dark'">
    
            <div>
                <h5 class="mb-3">Suporte</h5>
                <p><b>Segunda-Feira</b> 08:00 às 22:00</p>
                <p><b>Terça-Feira</b> 08:00 às 22:00</p>
                <p><b>Quarta-Feira</b> 08:00 às 22:00</p>
                <p><b>Quinta-Feira</b> 08:00 às 22:00</p>
                <p><b>Sexta-Feira</b> 08:00 às 22:00</p>
                <p><b>Sábado</b> 08:00 às 20:00</p>
                <p><b>Domingo</b> 08:00 às 18:00</p>                
            </div>

        </div>

        <div class="alert text-center m-2" role="alert" [ngClass]="'alert-success'">
    
            <div>
                <h5 class="mb-3">Administrativo</h5>
                <p><b>Segunda-Feira</b> 08:00 às 18:00</p>
                <p><b>Terça-Feira</b> 08:00 às 18:00</p>
                <p><b>Quarta-Feira</b> 08:00 às 18:00</p>
                <p><b>Quinta-Feira</b> 08:00 às 18:00</p>
                <p><b>Sexta-Feira</b> 08:00 às 18:00</p>
                <p><b>Sábado</b> 08:00 às 12:00</p>
            </div>  

        </div>

        <div class="alert text-center m-2" role="alert" [ngClass]="'alert-dark'">
    
            <div>
                <h5 class="mb-3">Contatos</h5>
                <h5>0800-729-6107</h5>
                <h5>(63) 3216 7300</h5>
                <h5>(63) 3219-5800</h5>
                <h5>
                    <a class="d-flex align-items-center justify-content-center text-success" style="text-decoration: none;" href="https://wa.me/5563984566397" target="_blank"> 
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-whatsapp" viewBox="0 0 16 16">
                            <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"/>
                        </svg>
                        &nbsp;(63) 98456-6397
                    </a>
                </h5>

                <p>
                    <a class="d-flex align-items-center justify-content-center text-dark" style="text-decoration: none;" href="mailto:contato@maxdatasistema.com.br" target="_blank"> 
                        <i class="material-icons">mail</i>
                        &nbsp;contato@maxdatasistema.com.br
                    </a>
                </p>

                <a class="text-dark" href="https://maxdatasistemas.com.br" style="text-decoration: none;" target="_blank"><b>www.maxdatasistemas.com.br</b></a>

                <br>
                <a class="btn btn-sm btn-secondary mt-4" href="https://maxdatasistemas.com.br/suporte_maxdata.exe"><i class="material-icons">laptop</i>Suporte Remoto</a>

            </div>  

        </div>

    </div>

    <div class="d-flex justify-content-end">
        <div class="btns-group">
            <button class="btn btn-sm btn-outline-danger" (click)="modalContato = false"><i
                class="material-icons">close</i>Fechar</button>
        </div>
    </div>
        
</p-dialog>
<app-usuario-modal 
*ngIf="modalUsuario"
[abrirFecharModalUsuarioAdmin]="modalUsuario"
[usuario_id_admin]="acessoUsuario.idUsuario"
(closeModal)="closeModalUsuario()"
></app-usuario-modal>