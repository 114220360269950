import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertMessage } from '@modules/alert.configuration.service';
import { ProdutoService } from '@modules/manager/cadastro/estoque/produto/produto.service';
import { ProdutoPesquisaViewModel } from '@modules/manager/view-models/cadastro/estoque/pesquisa/produto-pesquisa-view-model';
import { Uteis } from '@modules/utilizarios/uteis';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-produto-fornecedor-modal',
  templateUrl: './produto-fornecedor-modal.component.html',
  styles: [
  ]
})
export class ProdutoFornecedorModalComponent implements OnInit {
  @Input() abrirFecharPesquisaProdutoFornecedorModal: boolean = false;
  @Input() stringPesquisa: string = '';
  @Input() produtoId: number = 0;
  @Output() closeModal = new EventEmitter();
  pesquisa: ProdutoPesquisaViewModel = new ProdutoPesquisaViewModel();
  listaProdutoFornecedor: ProdutoPesquisaViewModel[] = [];

  constructor(
    private produto$: ProdutoService,
    private alert: AlertMessage,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.pesquisar();
  }

  pesquisar() {

    this.spinner.show();

    this.produto$.obterFornecedorPorProduto(this.produtoId).subscribe(
      retorno => {

        this.listaProdutoFornecedor = retorno.total > 0 ? retorno.result : [];

        this.spinner.hide();
      }, 
      err => {
        this.spinner.hide();

        this.alert.Danger('Erro', Uteis.ObterErroApi(err));
      });
  }

  closeModalProdutoFornecedor() {
    this.closeModal.emit(false);
  }
}
