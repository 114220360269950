export class ClienteViewModel {
    id?: number;
    nome?: string;
    ativo?: boolean;
    fantasia?: string;
    tipo_pessoa?:  any[]  ;
    tipo_cadastro?: number | number[];
    cliente_grupo_id?: number;
    cliente_tipo_id?: number;
    cpf_cnpj?: string;
    rg_ie?: string;
    rg_ie_uf?: string;
    ie_diferido?: string;
    dt_cadastro?: string;
    dt_nascimento?: string;
    vlr_limite_credito?: number;
    obs_venda?: string;
    fone?: string;
    fax?: string;
    celular?: string;
    site?: string;
    email?: string;
    img_foto?: string;
    sexo?: string;
    estado_civil?: string;
    naturalidade_cidade?: string;
    naturalidade_uf?: string;
    nome_pai?: string;
    nome_mae?: string;
    qtd_dependentes?: number;
    dados_prof_cnpj?: string;
    dados_prof_fone?: string;
    dados_prof_data_admissao?: string;
    dados_prof_ocupacao?: string;
    dados_prof_cargo?: string;
    dados_prof_vlr_renda_mensal?: number;
    dados_prof_vlr_outras_rendas?: number;
    dados_prof_endereco?: string;
    dados_prof_endereco_numero?: string;
    dados_prof_endereco_bairro?: string;
    dados_prof_endereco_cep?: string;
    dados_prof_endereco_cidade_codigo_ibge?: number;
    dados_banc_num_banco?: number;
    dados_banc_nome_banco?: string;
    dados_banc_agencia?: string;
    dados_banc_num_conta?: string;
    dados_banc_tipo_conta?: string;
    dados_banc_data_conta?: string;
    dados_banc_fone_ag?: string;
    dados_banc_obs?: string;
    obs_geral?: string;
    tipo_regime_apuracao?: number;
    nome_conjuge?: string;
    inscricao_municipal?: string;
    desconto_auto_aplicar?: boolean;
    desconto_auto_aliq?: number;
    obs_nfe?: string;
    dt_casamento?: string;
    consumidor_final?: boolean;
    dados_prof_nome_empresa?: string;
    
    cpf?: string;
    cnpj?: string;

    rg?: string;
    ie?: string;
    cliente_profissao_id?: number;
    tipo_preco_venda?: number;
    endereco_municipio_descricao?:string;
    chk_alterar_nome?:boolean;
}