import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { PesquisaGeralViewModel } from '@modules/admin/view-models/pesquisa/PesquisaGeralViewModel';
import { AlertMessage } from '@modules/alert.configuration.service';
import { AutenticacaoService } from '@modules/autenticacao/services/autenticacao.service';
import { ProdutoGrupoService } from '@modules/manager/cadastro/estoque/produto-grupo/produto-grupo.service';
import { ProdutoService } from '@modules/manager/cadastro/estoque/produto/produto.service';
import { ConfiguracaoService } from '@modules/manager/sistema/configuracao/configuracao.service';
import { ProdutoPesquisaViewModel } from '@modules/manager/view-models/cadastro/estoque/pesquisa/produto-pesquisa-view-model';
import { ProdutoGrupoPesquisaViewModel } from '@modules/manager/view-models/cadastro/estoque/pesquisa/ProdutoGrupoPesquisaViewModel';
import { ProdutoSubGrupoPesquisaViewModel } from '@modules/manager/view-models/cadastro/estoque/pesquisa/ProdutoSubGrupoPesquisaViewModel';
import { Uteis } from '@modules/utilizarios/uteis';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-servico-pesquisa-modal',
  templateUrl: './servico-pesquisa-modal.component.html',
  styles: [
  ]
})
export class ServicoPesquisaModalComponent implements OnInit {
  @Input() abrirFecharPesquisaServicoModal: boolean = false;
  @Input() stringPesquisa: string = '';
  @Output() closeModal = new EventEmitter();
  @Output() servico: EventEmitter<any> = new EventEmitter();

  pesquisa: ProdutoPesquisaViewModel = new ProdutoPesquisaViewModel();
  pesquisaProdutoGrupo: ProdutoGrupoPesquisaViewModel = new ProdutoGrupoPesquisaViewModel();
  pesquisaProdutoSubGrupo: ProdutoSubGrupoPesquisaViewModel = new ProdutoSubGrupoPesquisaViewModel();
  listaServicos: ProdutoPesquisaViewModel[] = [];
  listaGrupo: ProdutoGrupoPesquisaViewModel[] = [];
  selectedRowIndex: number = -1 ;

  constructor(private produto$: ProdutoService,
    private produtoGrupo$: ProdutoGrupoService,
    private alert: AlertMessage,
    private spinner: NgxSpinnerService,
    private configuracaoService: ConfiguracaoService,
    private autenticacaoService: AutenticacaoService) { }

  ngOnInit() {
    setTimeout(() => {
      (document.querySelector(`[name="descricao_servico"]`) as HTMLElement).focus();
      this.pesquisa.descricao = this.stringPesquisa;
    }, 100);    
    this.obterListaGrupo();
  }
  obterListaGrupo() {    
    this.pesquisaProdutoGrupo = Object.assign({ quantidadeRegistrosPagina: 0 })    
    this.produtoGrupo$.obterTodos(this.pesquisaProdutoGrupo).subscribe(
      retorno => this.listaGrupo = retorno.total > 0 ? retorno.result : [],
      error => this.alert.Danger('Erro', Uteis.ObterErroApi(error))
    )
  }
  @HostListener('window:keydown', ['$event']) keyEvent(event: KeyboardEvent) {
  
    if (event.key === 'ArrowUp') {
      if(this.selectedRowIndex > 0) this.selectedRowIndex -= 1;
      document.getElementById('table')
      .getElementsByTagName('tbody')[0]
      .getElementsByTagName('tr')[this.selectedRowIndex]
      .scrollIntoView({block: "end"})
    }
    else if (event.key === 'ArrowDown') {      
      if(this.selectedRowIndex < this.listaServicos.length - 1) this.selectedRowIndex += 1;
      document.getElementById('table')
        .getElementsByTagName('tbody')[0]
        .getElementsByTagName('tr')[this.selectedRowIndex]
        .scrollIntoView({block: "end"}) 
    }
    else if (event.key === 'Enter'){
      if (this.selectedRowIndex > -1){
        this.servico.emit(this.listaServicos[this.selectedRowIndex]);
        this.selectedRowIndex = -1;
      }
    }
    else if (event.key === 'Escape'){
      this.closeModalProduto();
    }
  }
  clickRowIndex(index: number){
    this.selectedRowIndex = index;
  }
  pesquisar() {
    (document.querySelector('[name="id"]') as HTMLInputElement).blur();
    (document.querySelector('[name="descricao_servico"]') as HTMLInputElement).blur();

    this.selectedRowIndex = -1;

    this.spinner.show();
    this.pesquisa = Object.assign({ tipo: 'S' }, this.pesquisa)    
    this.produto$.obterTodos(this.pesquisa).subscribe(
      retorno => {
        this.listaServicos = retorno.total > 0 ? retorno.result : [];
        this.selectedRowIndex = 0;
        this.spinner.hide();
      },
      err => {
        this.spinner.hide();
        this.alert.Danger('Erro', Uteis.ObterErroApi(err));
        this.selectedRowIndex = -1;
      }
    )
  }
  produtoSlecionado(item) {
    this.servico.emit(item);
    this.selectedRowIndex = -1;
  }
  closeModalProduto() {
    this.closeModal.emit(false);
    this.selectedRowIndex = -1;
  }
}
