import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CadastroViewModel } from '@modules/admin/view-models/cadastro/CadastroViewModel';
import { PesquisaGeralViewModel } from '@modules/admin/view-models/pesquisa/PesquisaGeralViewModel';
import { AutenticacaoService } from '@modules/autenticacao/services/autenticacao.service';
import { URLBASE } from '@modules/configuration.service';
import { ProdutoGradeDescricaoPesquisaViewModel } from '@modules/manager/view-models/cadastro/estoque/pesquisa/produto-grade-descricao-pesquisa-view-model';
import { ProdutoGradeDescricaoViewModel } from '@modules/manager/view-models/cadastro/estoque/produto-grade-descricao-view-model';
import { RetornoApiViewModel } from '@modules/manager/view-models/RetornoApiViewModel';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProdutoGradeDescricaoService {

  idEmpresaMaxdata = this.autenticacao$.acessoUsuarioValue?.idEmpresaMaxdata;
  urlApi =  `${URLBASE}/${this.idEmpresaMaxdata}/produto_grade_descricao_service`;

  constructor(private http: HttpClient, 
      private autenticacao$: AutenticacaoService){ }

  obterTodos(pesquisa?: ProdutoGradeDescricaoPesquisaViewModel) : Observable<RetornoApiViewModel<ProdutoGradeDescricaoPesquisaViewModel[]>> {

    let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel()

    pesquisaGeral.select = 'produto_grade_descricao.*';

    pesquisaGeral.calcRowCount = 1;

    pesquisaGeral.limit = pesquisa?.quantidadeRegistrosPagina || 0;

    pesquisaGeral.start = pesquisa?.start || 0;

    pesquisaGeral.order = pesquisa?.order ? pesquisa?.order : 'produto_grade_descricao.descricao'

    if (pesquisa?.id) pesquisaGeral.where = ` and produto_grade_descricao.id in(${pesquisa?.id})`;

    if (pesquisa?.descricao) pesquisaGeral.where = ` and lower(produto_grade_descricao.descricao) like lower('%${pesquisa?.descricao}%')`;

    return this.http.post<RetornoApiViewModel<ProdutoGradeDescricaoPesquisaViewModel[]>>(`${this.urlApi}/get`,pesquisaGeral)
  }

  obterPorId(id: number): Observable<RetornoApiViewModel<ProdutoGradeDescricaoViewModel[]>>{

    let pesquisaGeralViewModel: PesquisaGeralViewModel = new PesquisaGeralViewModel();

    pesquisaGeralViewModel.select = `produto_grade_descricao.*`;

                          pesquisaGeralViewModel.where = ` and produto_grade_descricao.id = ${id} `;

      return this.http.post<RetornoApiViewModel<ProdutoGradeDescricaoViewModel[]>>(`${this.urlApi}/get`,pesquisaGeralViewModel);
  }

  adicionar(produtoGradeViewModel: CadastroViewModel<ProdutoGradeDescricaoViewModel>) : Observable<RetornoApiViewModel<ProdutoGradeDescricaoViewModel>>{

      return this.http.post<RetornoApiViewModel<ProdutoGradeDescricaoViewModel>>(`${this.urlApi}/addupdate`, produtoGradeViewModel);
  }

  atualizar(id: number, produtoGradeViewModel: CadastroViewModel<ProdutoGradeDescricaoViewModel>) : Observable<RetornoApiViewModel<ProdutoGradeDescricaoViewModel>>{

      return this.http.put<RetornoApiViewModel<ProdutoGradeDescricaoViewModel>>(`${this.urlApi}/addupdate/${id}`, produtoGradeViewModel);
  }
}
