import { ProdutoEquivalenteViewModel } from "@manager/view-models/cadastro/estoque/produto-equivalente-view-model";
import { PaginacaoViewModel } from "@manager/view-models/PaginacaoViewModel";


export class ProdutoEquivalentePesquisaViewModel extends ProdutoEquivalenteViewModel implements PaginacaoViewModel {
    pagina: number = 1;
    quantidadeRegistrosPagina: number = 20;
    totalRegistros: number = 0;
    totalPaginas: number = 0;
    desativados: boolean = false;
    start?: number = 0;
    order?: string;
    produto_id_origem?: number;
    produto_id_destino?: number;
}
