import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CadastroViewModel } from '@modules/admin/view-models/cadastro/CadastroViewModel';
import { PesquisaGeralViewModel } from '@modules/admin/view-models/pesquisa/PesquisaGeralViewModel';
import { AutenticacaoService } from '@modules/autenticacao/services/autenticacao.service';
import { URLBASE } from '@modules/configuration.service';
import { AgendamentoViewModel } from '@modules/manager/view-models/movimentacao/agendamento-view-model';
import { AgendamentoPesquisaViewModel } from '@modules/manager/view-models/movimentacao/pesquisa/agendamento-pesquisa-view-model';
import { RetornoApiViewModel } from '@modules/manager/view-models/RetornoApiViewModel';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AgendamentoService {

  idEmpresaMaxdata = this.autenticacao$.acessoUsuarioValue?.idEmpresaMaxdata;
  urlApi =  `${URLBASE}/${this.idEmpresaMaxdata}/agendamento_service`;

  constructor(private http: HttpClient, 
      private autenticacao$: AutenticacaoService){ }

  obterTodos(pesquisa?: AgendamentoPesquisaViewModel) : Observable<RetornoApiViewModel<AgendamentoPesquisaViewModel[]>> {

    let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel()

    pesquisaGeral.select = `agendamento.*,
                            cliente.nome as cliente_nome,
                            usuario.nome as atendente_nome`;

    pesquisaGeral.calcRowCount = 1;

    pesquisaGeral.limit = pesquisa?.quantidadeRegistrosPagina || 0;

    pesquisaGeral.start = pesquisa?.start || 0;

    pesquisaGeral.order = pesquisa?.order ? pesquisa?.order : 'agendamento.dt_inicial'

    if (pesquisa?.id) pesquisaGeral.where = ` and agendamento.id = ${pesquisa?.id}`;

    if (pesquisa?.dt_inicial_inicio && pesquisa?.dt_inicial_final && !pesquisa?.id ) {

      pesquisaGeral.where += ` and agendamento.dt_inicial between '${pesquisa?.dt_inicial_inicio}T00:00:00' and '${pesquisa?.dt_inicial_final}T23:59:59'`;

    }

    if (pesquisa?.atendente_id) pesquisaGeral.where = ` and agendamento.atendente_id = ${pesquisa?.atendente_id}`;

    if (pesquisa?.cliente_id) pesquisaGeral.where = ` and agendamento.cliente_id = ${pesquisa?.cliente_id}`;

    if (pesquisa?.cliente_nome) pesquisaGeral.where = ` and lower(cliente.nome) like lower('%${pesquisa?.cliente_nome}%')`;

    if (pesquisa?.status) pesquisaGeral.where = ` and lower(agendamento.status) like lower('%${pesquisa?.status}%')`;

    return this.http.post<RetornoApiViewModel<AgendamentoPesquisaViewModel[]>>(`${this.urlApi}/get`,pesquisaGeral)
  }

  obterPorId(id: number): Observable<RetornoApiViewModel<AgendamentoPesquisaViewModel[]>>{

    let pesquisaGeralViewModel: PesquisaGeralViewModel = new PesquisaGeralViewModel();

    pesquisaGeralViewModel.select = `agendamento.*, 
                                    CONCAT(agendamento.cliente_id, ' - ',cliente.nome) as cliente_nome`;

                          pesquisaGeralViewModel.where = ` and agendamento.id = ${id} `;

      return this.http.post<RetornoApiViewModel<AgendamentoPesquisaViewModel[]>>(`${this.urlApi}/get`,pesquisaGeralViewModel);
  }

  adicionar(produtoGradeViewModel: CadastroViewModel<AgendamentoViewModel>) : Observable<RetornoApiViewModel<AgendamentoViewModel>>{

      return this.http.post<RetornoApiViewModel<AgendamentoViewModel>>(`${this.urlApi}/addupdate`, produtoGradeViewModel);
  }

  atualizar(id: number, produtoGradeViewModel: CadastroViewModel<AgendamentoViewModel>) : Observable<RetornoApiViewModel<AgendamentoViewModel>>{

      return this.http.put<RetornoApiViewModel<AgendamentoViewModel>>(`${this.urlApi}/addupdate/${id}`, produtoGradeViewModel);
  }
}
