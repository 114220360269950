export class ProdutoSped{

    constructor(codigo: string, descricao: string){
        this.codigo = codigo;
        this.descricao = descricao;
    }
    codigo: string = '';
    descricao: string = '';
    
    static tipo_sped = [
        new ProdutoSped('00','00 - Merc. para Revenda'),
        new ProdutoSped('01','01 - Matéria-Prima'),
        new ProdutoSped('02','02 - Embalagem'),
        new ProdutoSped('03','03 - Produto em Processo'),
        new ProdutoSped('04','04 - Produto Acabado'),
        new ProdutoSped('05','05 - Subproduto'),
        new ProdutoSped('06','06 - Produto Intermediário'),
        new ProdutoSped('07','07 - Material Uso e Consumo'),
        new ProdutoSped('08','08 - Ativo Imobilizado'),
        new ProdutoSped('09','09 - Serviços'),
        new ProdutoSped('10','10 - Outros Insumos'),
        new ProdutoSped('99','99 - Outras'),
    ]

}