import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CadastroViewModel } from '@modules/admin/view-models/cadastro/CadastroViewModel';
import { AlertMessage } from '@modules/alert.configuration.service';
import { DepartamentoService } from '@modules/manager/cadastro/ecommerce/departamento/departamento.service';
import { EcommerceDepartamentoViewModel } from '@modules/manager/view-models/cadastro/ecommerce/ecommerce-departamento-view-model';
import { AlertMensagemPadrao } from '@modules/utilizarios/alertMensagemPadrao';
import { Uteis } from '@modules/utilizarios/uteis';
import { DisplayMessage, GenericValidator, ValidationMessages } from '@modules/validacao/generic-form-validation';
import { MensagensValidacao } from '@modules/validacao/mensagensValidacao';

import { NgxSpinnerService } from 'ngx-spinner';


@Component({
    selector: 'app-produto-departamento-modal',
    templateUrl: './produto-departamento-modal.component.html',
    styles: [
    ]
})


export default class ProdutoDepartamentoModalComponent {
    @Input() alterar: boolean = false;
    @Input() ProdutoDepartamentoId: number;
    @Input() abrirFecharProdutoDepartamentoModal: boolean = false;
    @Output() closeModal = new EventEmitter();

    objProdutoDepartamento: EcommerceDepartamentoViewModel = new EcommerceDepartamentoViewModel();
    cadastroForm!: FormGroup;
    validationMessages?: ValidationMessages;
    genericValidator?: GenericValidator;
    displayMessage: DisplayMessage = {};

    constructor(
        private fb: FormBuilder,
        private alert: AlertMessage,
        private departamento$: DepartamentoService,
        private spinner: NgxSpinnerService) {

    };

    ngOnInit(): void {
        this.criarFormulario()

        this.messagesValidations()
    };

    submit() {
        this.cadastroForm.markAllAsTouched();

        if (!this.cadastroForm.valid) {
    
          if (typeof this.genericValidator !== 'undefined') {
    
            this.mensagensValidator()
    
          }
    
          let mensagem = Uteis.ConverterObjetoToArray(this.displayMessage)
    
          return this.mensagemCamposInvalidos(mensagem)
        }
    
        if (!this.cadastroForm.dirty) {
    
          this.alert.Info(AlertMensagemPadrao.formularioNaoAlterado.titulo, AlertMensagemPadrao.formularioNaoAlterado.mensagem);
    
          return
        }

        this.atualizarObj()

        if(this.alterar) this.atualizar();
        else this.adicionar()
    }

    adicionar() {
        this.spinner.show();

        const cad = new CadastroViewModel<EcommerceDepartamentoViewModel>();

        cad.obj = this.objProdutoDepartamento;

        this.departamento$.adicionar(cad).subscribe(
            retorno => {
                this.mensagensCadastro()
                this.spinner.hide();
                this.closeModal.emit(true)
            },
            erro => {
                this.spinner.hide()
            }
        );
    }

    atualizarObj(){
        this.objProdutoDepartamento = Object.assign(this.objProdutoDepartamento, this.cadastroForm.value)
    }

    atualizar() {
        this.spinner.show();

        const cad = new CadastroViewModel<EcommerceDepartamentoViewModel>();

        cad.obj = this.objProdutoDepartamento;

        this.departamento$.atualizar(this.objProdutoDepartamento?.id, cad).subscribe(
            retorno => {
                this.mensagensCadastro()
                this.spinner.hide()
                this.closeModal.emit(true)
            },
            erro => {
                this.spinner.hide()
            }
        );
    }

    mensagensValidator() {
        this.displayMessage = this.genericValidator.processarMensagens(this.cadastroForm);
    };

    private mensagensCadastro(): void {
        this.alert.Success('Sucesso', `Registro ${this.alterar ? 'atualizado' : 'cadastrado'} com Sucesso.`);
    }

    mensagemCamposInvalidos(mensagens: any) {
        this.alert.Warning(AlertMensagemPadrao.formularioInvalido.titulo, 'Todos os campos são obrigatorios!');
      }

    closeModalProdutoDepartamento() {
        this.closeModal.emit(false);
    };

    messagesValidations() {
        this.validationMessages = {
            descricao: {
                required: MensagensValidacao.Obrigatorio('Descrição'),
                minlength: MensagensValidacao.MinimoCaracteres('Descricão', 5),
            },
            nome: {
                required: MensagensValidacao.Obrigatorio('Nome'),
                minlength: MensagensValidacao.MinimoCaracteres('Nome', 3),
            },
            palavra_chave: {
                required: MensagensValidacao.Obrigatorio('Palavra chave'),
                minlength: MensagensValidacao.MinimoCaracteres('Palavra chave', 2),
            }
        };
        this.genericValidator = new GenericValidator(this.validationMessages);
    };

    criarFormulario() {
        this.cadastroForm = this.fb.group({
            id: [{ value: !this.alterar ? null : this.objProdutoDepartamento?.id, disabled: true }],
            nome: [!this.alterar ? null : this.objProdutoDepartamento?.nome, [Validators.required, Validators.minLength(3)]],
            descricao: [!this.alterar ? null : this.objProdutoDepartamento?.descricao, [Validators.required, Validators.minLength(5)]],
            palavra_chave: [!this.alterar ? null : this.objProdutoDepartamento.palavra_chave, [Validators.required, Validators.minLength(3)]],
            ativo: [!this.alterar ? null : this.objProdutoDepartamento.ativo],
            chk_visivel: [true]
        });
    };
};