import { PaginacaoViewModel } from 'src/modules/manager/view-models/PaginacaoViewModel';
import { UsuarioViewModel } from '../cadastro/UsuarioViewModel';

export class UsuarioPesquisaViewModel extends UsuarioViewModel implements PaginacaoViewModel {
    pagina: number = 1;
    quantidadeRegistrosPagina: number = 20;
    totalRegistros: number = 0;
    totalPaginas: number = 0;
    desativados: boolean = false;
    start?: number;
    order?: string;

}