<div class="row">
    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <app-titulo-paginas [tituloPagina]="tituloPagina"></app-titulo-paginas>
    </div>
    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <div class="text-right btns-group">
            <button class="btn btn-sm btn-outline-secondary" (click)="imprimir()"><i class="material-icons">print</i> Imprimir</button>
            <button class="btn btn-sm btn-outline-info" (click)="pesquisar()"><i
                    class="material-icons">search</i>Pesquisar</button>
            <a [routerLink]="['novo']" class="btn btn-sm btn-outline-success"><i class="material-icons">add</i>Novo</a>
        </div>
    </div>
</div>

<div class="card shadow-sm">
    <div class="card-body pt-1 pb-1">
        <div class="row">
            <div class="col-sm-6 col-md-4 col-lg-2 col-xl-1">
                <div class="form-group">
                    <label for="id" class="col-form-label-sm">Código</label>
                    <input type="number" id="id" name="id" class="form-control form-control-sm noscroll" placeholder="Código"
                        [(ngModel)]="pesquisa.id">
                </div>
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2">
                <div class="form-group">
                    <label for="id_maxdata" class="col-form-label-sm">Código Maxdata</label>
                    <input type="number" id="id_maxdata" name="id_maxdata" class="form-control form-control-sm noscroll"
                        placeholder="Código Maxdata" [(ngModel)]="pesquisa.id_maxdata">
                </div>
            </div>
            <div class="col-sm-12 col-md-8 col-lg-4 col-xl-4">
                <div class="form-group">
                    <label for="descricao" class="col-form-label-sm">Nome / Fantasia {{pesquisa.descricao}}</label>
                    <input type="text" id="descricao" name="descricao" class="form-control form-control-sm"
                        placeholder="Pesquise pelo Nome / Fantasia" [(ngModel)]="pesquisa.descricao">
                </div>
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2">
                <div class="form-group">
                    <label class="col-form-label-sm">Itens por página</label>
                    <select id="quantidadeRegistrosPagina" name="quantidadeRegistrosPagina"
                        class="form-control form-control-sm" [(ngModel)]="pesquisa.quantidadeRegistrosPagina"
                        (change)="pesquisar()">
                        <option *ngFor="let item of listaItensPaginacao" [ngValue]="item.quantidade" [selected]="10">
                            {{ item.quantidade }}</option>
                    </select>
                </div>
            </div>
            <div class="col-sm-6 col-md-12 col-lg-12 col-xl-3 text-sm-right text-md-right text-lg-right text-xl-right">
                <div class="form-group">
                    <div class="custom-control custom-checkbox">
                        <input type="checkbox" id="desativar" name="desativar" class="custom-control-input"
                            [(ngModel)]="pesquisa.desativados">
                        <label class="custom-control-label status-filtro" for="desativar">Desativado</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card-body pt-1 pb-1 border-top rounded-0 bg-white">
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div class="table-responsive-sm">
                    <table class="table table-sm table-borderless table-striped table-hover">
                        <thead class="thead">
                            <th (click)="ordenarLista('ID')">Código</th>
                            <th (click)="ordenarLista('id_maxdata')">Código Maxdata</th>
                            <th (click)="ordenarLista('descricao')">Nome</th>
                            <th class="text-right">Usuários</th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of listaPaginada.itens" 
                                class="cursor">
                                <td [routerLink]="['editar/', item.id]">{{item.id}}</td>
                                <td [routerLink]="['editar/', item.id]">{{item.id_maxdata}}</td>
                                <td [routerLink]="['editar/', item.id]">{{item.descricao}}</td>
                                <td class="text-right" title=" Lista de Usuários">
                                    <a class="badge badge-info" (click)="modalEmpresaUsuario(item)"><i class="material-icons">visibility</i></a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

<app-paginacao 
    [qdtPaginas]="pesquisa.quantidadeRegistrosPagina" 
    [itensgrid]="listaPaginada.itens.length"              
    [itemsPerPage]="listaPaginada.paginacao.quantidadeRegistrosPagina"
    [totalItems]="listaPaginada.paginacao.totalRegistros"
    [pagina]="pesquisa.pagina"  
    (onPaginate)="mudarPagina($event)">
</app-paginacao>
</div>