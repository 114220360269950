export class ConfigModal {
    
    static modalBloqueado = {
        backdrop: true,
        ignoreBackdropClick: true,
        keyboard: false
      };

    static modalPadrao = {
    backdrop: true,
    ignoreBackdropClick: false,
    keyboard: true
    };
  };