import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CadastroViewModel } from '@modules/admin/view-models/cadastro/CadastroViewModel';
import { AlertMessage } from '@modules/alert.configuration.service';
import { ProdutoGradeTamanhoService } from '@modules/manager/cadastro/estoque/produto-grade-tamanho/produto-grade-tamanho.service';
import { ProdutoGradeTamanhoViewModel } from '@modules/manager/view-models/cadastro/estoque/produto-grade-tamanho-view-model';
import { AlertMensagemPadrao } from '@modules/utilizarios/alertMensagemPadrao';
import { Uteis } from '@modules/utilizarios/uteis';
import { DisplayMessage, GenericValidator, ValidationMessages } from '@modules/validacao/generic-form-validation';
import { MensagensValidacao } from '@modules/validacao/mensagensValidacao';
import { BsModalService } from 'ngx-bootstrap/modal';

import { NgxSpinnerService } from 'ngx-spinner';
import { isNumber } from 'util';

@Component({
  selector: 'app-produto-grade-tamanho-cadastro-modal',
  templateUrl: './produto-grade-tamanho-cadastro-modal.component.html',
  styles: [
  ]
})
export class ProdutoGradeTamanhoCadastroModalComponent implements OnInit {

  @Input() produtoGradeTamanho!: ProdutoGradeTamanhoViewModel;
  @Input() alterar: boolean = false;
  @Input() produtoGradeTamanhoId: number;
  @Input() abrirFecharProdutoGradeTamanhoCadastroModal: boolean = false;
  @Output() closeModal = new EventEmitter();

  cadastroForm!: FormGroup;
  validationMessages?: ValidationMessages;
  genericValidator?: GenericValidator;
  displayMessage: DisplayMessage = {};
  carregando: boolean = false;
  objProdutoGradeTamanho: CadastroViewModel<ProdutoGradeTamanhoViewModel> = new CadastroViewModel<ProdutoGradeTamanhoViewModel>();

  constructor(
    private fb: FormBuilder,
    private modal$: BsModalService,
    private alert: AlertMessage,    
    private spinner: NgxSpinnerService,
    private produtoGradeTamanho$: ProdutoGradeTamanhoService,) {
      this.messagesValidations()
     }

  ngOnInit(): void {

    this.criarFormulario();

    this.alterar = isNumber(this.produtoGradeTamanhoId) && this.produtoGradeTamanhoId > 0;

      if (this.alterar) {

        this.spinner.show();

        this.produtoGradeTamanho$.obterPorId(this.produtoGradeTamanhoId || 0).subscribe(
          retorno => {

            this.spinner.hide();

            if (retorno.total <= 0) this.alert.Danger('Erro', 'Produto Grade Tamanho não encontrado');

            this.objProdutoGradeTamanho.obj = retorno.total > 0 ? retorno.result[0] : new ProdutoGradeTamanhoViewModel();

            this.criarFormulario();
          },
          error => {
            this.spinner.hide();

            this.alert.Danger('Erro', Uteis.ObterErroApi(error))
          });
      }
  }

  onSubmit() {

    this.cadastroForm.markAllAsTouched();

    if (!this.cadastroForm.valid) {

      if (typeof this.genericValidator !== 'undefined') {

        this.displayMessage = this.genericValidator.processarMensagens(this.cadastroForm);

      }

      let mensagem = Uteis.ConverterObjetoToArray(this.displayMessage)

      return this.mensagemCamposInvalidos(mensagem);
    }

    if (!this.cadastroForm.dirty) {

      this.alert.Info(AlertMensagemPadrao.formularioNaoAlterado.titulo, AlertMensagemPadrao.formularioNaoAlterado.mensagem);

      return
    }

    this.carregando = true;

    if (this.alterar) this.atualizar();
    else this.adicionar();
  }

  criarFormulario() {
    this.cadastroForm = this.fb.group({
      id: [{ value: !this.alterar ? null : this.objProdutoGradeTamanho.obj?.id, disabled: true }],
      tamanho: [!this.alterar ? null : this.objProdutoGradeTamanho.obj?.tamanho, [Validators.required, Validators.minLength(1)]],
    })
  }

  atualizarDadosObjeto() {
    this.objProdutoGradeTamanho.obj = Object.assign({}, this.objProdutoGradeTamanho.obj, this.cadastroForm.value);

    this.objProdutoGradeTamanho.obj.tamanho = this.objProdutoGradeTamanho.obj?.tamanho.toUpperCase();
  }

  atualizar() {

    this.atualizarDadosObjeto();
    
    if (this.objProdutoGradeTamanho.obj?.id)
      this.produtoGradeTamanho$.atualizar(this.objProdutoGradeTamanho.obj?.id, this.objProdutoGradeTamanho).subscribe(
          () => {

            this.mensagensCadastro();

            this.closeModalCadastroProdutoGradeTamanho();

            this.carregando = false;

          },
          error => {

            this.carregando = false;

            this.alert.Danger('Erro', Uteis.ObterErroApi(error));

          }
        )
  }

  adicionar() {

    this.atualizarDadosObjeto();

    this.produtoGradeTamanho$.adicionar(this.objProdutoGradeTamanho).subscribe(
      retorno => {

        this.alert.Success('Sucesso','Tamanho cadastrado com sucesso');

        this.closeModalCadastroProdutoGradeTamanho();

        this.limparForm();

        this.carregando = false;
      },
      error => {

        this.carregando = false;

        this.alert.Danger('Erro', Uteis.ObterErroApi(error))

      }
    )
  }

  limparForm() {
    this.cadastroForm.reset();
  }

  messagesValidations() {
    this.validationMessages = {
      tamanho: {
        required: MensagensValidacao.Obrigatorio('Tamanho'),
        minlength: MensagensValidacao.MinimoCaracteres('Tamanho', 1),
      },
    }
  }

  closeModalCadastroProdutoGradeTamanho() {
    this.closeModal.emit(false);
  }

  private mensagensCadastro(): void {
    this.alert.Success('Sucesso', `Registro ${this.alterar ? 'atualizado' : 'cadastrado'} com Sucesso.`);
    if (this.alterar) return;
    this.alert.Info('Informativo', 'Formulário de cadastro foi limpo para realizar um novo cadastro.', { timeOut: 10000 });
  }

  mensagemCamposInvalidos(mensagens: any) {
    this.alert.Warning(AlertMensagemPadrao.formularioInvalido.titulo, AlertMensagemPadrao.formularioInvalido.mensagem + '<br><br>' + mensagens.toString().replace(/,/g, ''));
  }
}
