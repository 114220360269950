import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertMessage } from '@modules/alert.configuration.service';
import { CteService } from '@modules/manager/fiscal/cte/cte.service';
import { CtePesquisaViewModel } from '@modules/manager/view-models/fiscal/pesquisa/cte-pesquisa-view-model';
import { Uteis } from '@modules/utilizarios/uteis';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-cte-pesquisa-modal',
  templateUrl: './cte-pesquisa-modal.component.html',
  styles: [
  ]
})
export class CtePesquisaModalComponent implements OnInit {
  @Input() abrirFecharModalCTe: boolean = false;
  @Input() stringPesquisa: string = '';
  @Output() closeModal = new EventEmitter();
  @Output() cte: EventEmitter<any> = new EventEmitter();
  pesquisa: CtePesquisaViewModel = new CtePesquisaViewModel();
  listaCTe: CtePesquisaViewModel[] = [];
  
  constructor(
    private alert$: AlertMessage,
    private spinner$: NgxSpinnerService,
    private cte$: CteService
  ) { }

  ngOnInit(): void {
  }

  pesquisar(){

    this.spinner$.show();
    
    this.pesquisa.status = 'F';
    this.pesquisa.quantidadeRegistrosPagina=0;

    if (!this.pesquisa.remetente_nome) this.pesquisa.remetente_id = 0;

    if (!this.pesquisa.destinatario_nome) this.pesquisa.destinatario_id = 0;
    this.cte$.obterTodos(this.pesquisa).subscribe(
      retorno => {
       
        this.listaCTe = retorno.total > 0 ? retorno.result : [];
        this.spinner$.hide();
      },
      error =>{
        this.alert$.Danger('Erro', Uteis.ObterErroApi(error));
        this.spinner$.hide();
      });
  }

  dadosSelecionadosCTe : Array<any> = [];
  nfSelecionados(event: any, item ) {

    let data = {
      cte_id: item.id, chave_cte: item.chave_cte
      , municipio_carregamento_codigo_ibge : item.municipio_carregamento_codigo_ibge
      , municipio_descarregamento_codigo_ibge: item.municipio_descarregamento_codigo_ibge}
    
    let index = this.dadosSelecionadosCTe.findIndex(x => x.cte_id === item.cte_id);

    if(event.checked) { if(index === -1) this.dadosSelecionadosCTe.push(data) }

    else this.dadosSelecionadosCTe.splice(index, 1);
  
  }

  selecionar(){
    this.cte.emit(this.dadosSelecionadosCTe);
  }

  closeModalCTe() {
    this.closeModal.emit(false);
  }

}
