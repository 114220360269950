import { UsuarioManagerPermissaoPesquisaViewModel } from '@modules/manager/view-models/cadastro/usuario-manager/pesquisa/usuario-manager-permissao-pesquisa-view-model';
export class Acesso{
    token?: string;
    expires_in: number = 0;
    refresh_token?: string;
    refreshToken?: string; // Utizado para envio do refresehToken 
    idEmpresaMaxdata?: number;
    idLoja?: number;
    nomeEmpresa?: string;
    qtdEmpresa?: number;
    usuAdm?: number;
    idUsuario?: number;
    arrayUsuarioPermissao?: UsuarioManagerPermissaoPesquisaViewModel[];
    usuMenuCompleto?: boolean; 
    dtValidadeChave?: string; 
}