<p-dialog [(visible)]="abrirFecharCadastroFabricanteModal" [modal]="true" [closable]="true" [style]="{width: '100vw'}"
[maximizable]="true" [baseZIndex]="10000" [draggable]="false" [resizable]="false"
(onHide)="closeModalCadastroFabricante()">
<form  [formGroup]="cadastroForm">
    <div class="row">
        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <app-titulo-paginas [tituloPagina]="tituloPagina"></app-titulo-paginas>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 text-right btns-group">
            <button class="btn btn-sm btn-outline-secondary" id="processar" type="button" 
            (click)="openModalEmpresaFiltroRef(modalEmpresaFiltroRef)"
            *ngIf="alterar"><i class="material-icons">toc</i>Empresas</button>
            <a class="btn btn-sm btn-outline-secondary" (click)="closeModalCadastroFabricante()"><i
                    class="material-icons">close</i>Fechar</a>
            <button class="btn btn-sm btn-outline-success" type="button" (click)="onSubmit()" id="salvar" type="submit" [disabled]="carregando">
                <div [ngSwitch]="carregando">
                    <div *ngSwitchCase="true">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;
                        <span>Carregando ...</span>
                    </div>
                    <div *ngSwitchDefault>
                        <i class="material-icons">done_all</i><span>Salvar</span>
                    </div>
                </div>
            </button>
        </div>
    </div>

    <div class="card shadow-sm">
        <div class="card-body pt-1 pb-1">
            <div class="row">
                <div class="col-sm-4 col-md-3 col-lg-2 col-xl-1">
                    <div class="form-group">
                        <label for="id" class="col-form-label-sm">Código</label>
                        <input type="number" id="id" name="id" class="form-control form-control-sm noscroll" placeholder="Código"
                            [formControlName]="'id'">
                    </div>
                </div>
                <div class="col-sm-8 col-md-6 col-lg-6 col-xl-6">
                    <div class="form-group">
                        <label for="descricao" class="col-form-label-sm">Descrição do Fabricante<span
                                class="text-muted"> (Obrigatório)</span></label>
                        <input type="text" id="descricao" name="descricao" class="form-control form-control-sm"
                            placeholder="Descrição do Fabricante" autocomplete="off" formControlName="descricao"
                            [ngClass]="{'is-invalid': displayMessage.descricao}" />
                        <span class="invalid-feedback" *ngIf="displayMessage.descricao">
                            <p [innerHTML]="displayMessage.descricao"></p>
                        </span>
                    </div>
                </div>
                <div class="col-sm-4 col-md-3 col-lg-2 col-xl-1">
                    <div class="form-group">
                        <div class="custom-control custom-checkbox">
                            <input type="checkbox" id="ativo" name="ativo" class="custom-control-input form-control-sm"
                                formControlName="ativo" />
                            <label class="custom-control-label col-form-label-sm status-filtro"
                                for="ativo">Ativo</label>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</form>
</p-dialog>

<ng-template #modalEmpresaFiltroRef>
    <app-empresa-filtro [table_name]="tabela" [id]="objProdutoFabricante.obj.id" (closeModal)="closeModalEmpresaFiltroRef()"></app-empresa-filtro>
</ng-template>
