import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertMessage } from '@modules/alert.configuration.service';
import { ProdutoFabricanteService } from '@modules/manager/cadastro/estoque/produto-fabricante/produto-fabricante.service';
import { ProdutoFabricantePesquisaViewModel } from '@modules/manager/view-models/cadastro/estoque/pesquisa/produto-fabricante-pesquisa-view-model';
import { Uteis } from '@modules/utilizarios/uteis';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-produto-fabricante-pesquisa-modal',
  templateUrl: './produto-fabricante-pesquisa-modal.component.html',
  styles: [
  ]
})
export class ProdutoFabricantePesquisaModalComponent implements OnInit {
  @Input() abrirFecharPesquisaProdutoFabricanteModal: boolean = false;
  @Input() stringPesquisa: string = '';
  @Output() closeModal = new EventEmitter();
  @Output() produtoFabricante: EventEmitter<any> = new EventEmitter();
  pesquisa: ProdutoFabricantePesquisaViewModel = new ProdutoFabricantePesquisaViewModel();
  listaProdutoFabricante: ProdutoFabricantePesquisaViewModel[] = [];

  constructor(private produtoFabricante$: ProdutoFabricanteService,
    private alert: AlertMessage,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    setTimeout(() => {
      (document.querySelector(`[name="descricao"]`) as HTMLElement).focus();
      this.pesquisa.descricao = this.stringPesquisa;
    }, 100);
  }

  pesquisar() {
    this.spinner.show();

    this.produtoFabricante$.obterTodos(this.pesquisa).subscribe(
      retorno => {

        this.listaProdutoFabricante = retorno.total > 0 ? retorno.result : [];

        this.spinner.hide();
      }, 
      err => {
        this.spinner.hide();

        this.alert.Danger('Erro', Uteis.ObterErroApi(err));
      });
  }

  produtoFabricanteSelecionado(item: ProdutoFabricantePesquisaViewModel) {
    this.produtoFabricante.emit(item);
  }

  closeModalProdutoFabricante() {
    this.closeModal.emit(false);
  }

}
