import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertMessage } from '@modules/alert.configuration.service';
import { UsuarioManagerService } from '@modules/manager/cadastro/usuario-manager/usuario-manager.service';
import { UsuarioManagerPesquisaViewModel } from '@modules/manager/view-models/cadastro/usuario-manager/pesquisa/usuario-manager-pesquisa-view-model';
import { Uteis } from '@modules/utilizarios/uteis';
import { allowedNodeEnvironmentFlags } from 'process';

@Component({
  selector: 'app-multiselect-atendente',
  templateUrl: './multiselect-atendente.component.html',
  styles: []
})
export class MultiselectAtendenteComponent implements OnInit {

  @Output() atendentesSelecionados: EventEmitter<any> = new EventEmitter<any>();
  @Input() mostrarDestativados: boolean = false;

  pesquisa: UsuarioManagerPesquisaViewModel = new UsuarioManagerPesquisaViewModel;
  listaAtendentes: UsuarioManagerPesquisaViewModel[] = [];
  atendentes: UsuarioManagerPesquisaViewModel[] = [];
  atendentesDesativados: boolean = false;

  constructor(
    private alert: AlertMessage,
    private usuarioManager$: UsuarioManagerService,
  ) { }

  ngOnInit() {
    
    if(this.atendentesDesativados) this.pesquisa.desativados = null;
    else this.pesquisa.desativados = false;

    this.usuarioManager$.obterTodos(this.pesquisa).subscribe(
        retorno => {  
          this.listaAtendentes = retorno.total > 0 ? retorno.result : [];
          this.listaAtendentes.forEach(item => { if(!item.ativo){ item.nome += " - Desativado" } });
        },
        error => {
          this.alert.Danger('Erro ao obter Atendentes', Uteis.ObterErroApi(error));  
        }
    );

  }

  enviar(){ 
    this.atendentesSelecionados.emit(this.atendentes);
  }

}
