<p-dialog [(visible)]="abrirFecharModalUsuarioAdmin" [modal]="true" [closable]="false" [style]="{width: '90vw'}"
    [maximizable]="false" [baseZIndex]="10000" [draggable]="false" [resizable]="false"
    (onHide)="closeModalUsuarioAdmin()">
    <ngx-spinner name="modalAtualiza" bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-clip-rotate"
        [fullScreen]="false"></ngx-spinner>
    <form [formGroup]="cadastroForm">
       
        <div class="row  d-flex justify-content-between">
            <div class="col-mb">
                <h1 class="h4 border-left pl-2">
                    Alterar Senha 
                </h1>
            </div>
    
            <div class="btns-group" >
                <button class="btn btn-sm btn-outline-secondary" (click)="closeModalUsuarioAdmin()" *ngIf="acesso==false"><i
                        class="material-icons">close</i>Cancelar</button>
                <button class="btn btn-sm btn-outline-success" id="salvar_senha" type="button" (click)="atualizar()"
                    [disabled]="carregando">
                    <div [ngSwitch]="carregando">
                        <div *ngSwitchCase="true">
                            <span class="spinner-border spinner-border-sm" role="status"
                                aria-hidden="true"></span>&nbsp;
                            <span>Carregando ...</span>
                        </div>
                        <div *ngSwitchDefault>
                            <i class="material-icons">done_all</i><span>Salvar</span>
                        </div>
                    </div>
                </button>
            </div>
    
        </div>
        
            <div class="card rounded-0 border border-top-0" >
                <div class="card-body pt-1 pb-1">
                    <div class="row">
                        
                        <div class="col-sm-12 col-md-8 col-lg-6 col-xl-4">
                            <div class="form-group">
                                <label for="email" class="col-form-label-sm">E-mail</label>
                                <input *ngIf="alterar" type="text" id="email_senha" name="email"
                                    class="form-control form-control-sm" placeholder="E-mail do Usuário"
                                    autocomplete="off" formControlName="email" readonly
                                    [ngClass]="{'is-invalid': displayMessage.email}" />
                                
                            </div>
                        </div>
                        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2" *ngIf="alterar">
                            <div class="form-group">
                                <label for="senha" class="col-form-label-sm">Senha</label>
                                <input type="password" id="senha" name="senha" class="form-control form-control-sm"
                                    placeholder="Senha" autocomplete="off" formControlName="senha_hash"
                                    [ngClass]="{'is-invalid': displayMessage.senha_hash}" />
                                <span class="invalid-feedback" *ngIf="displayMessage.senha_hash">
                                    <p [innerHTML]="displayMessage.senha_hash"></p>
                                </span>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
     
    </form>
</p-dialog>