import { Injectable } from '@angular/core';
import { URLBASE, URLIMPRESSAO } from 'src/modules/configuration.service';
import { HttpClient } from '@angular/common/http';
import { AutenticacaoService } from 'src/modules/autenticacao/services/autenticacao.service';
import { PesquisaGeralViewModel } from '@modules/admin/view-models/pesquisa/PesquisaGeralViewModel';
import { Observable, of } from 'rxjs';
import { RetornoApiViewModel } from '../../view-models/RetornoApiViewModel';
import { NfPesquisaViewModel } from '../../view-models/fiscal/pesquisa/nf-pesquisa-view-model';
import { NfViewModel } from '../../view-models/fiscal/nf-view-model';
import { CadastroViewModel } from 'src/modules/admin/view-models/cadastro/CadastroViewModel';
import { NfConcluirCancelarViewModel, NfProcessarViewModel } from '../../view-models/fiscal/NfProcessarViewModal';
import { ArquivoBase64ViewModel } from '../../view-models/ArquivoBase64ViewModel';
import { NfItemService } from '../nf-item/nf-item.service';
import { catchError, concatMap, isEmpty } from 'rxjs/operators';
import { NfItemPesquisaViewModel } from '@modules/manager/view-models/fiscal/pesquisa/nf-item-pesquisa-view-model';
import { ApiRelatorioViewModel, RelatorioViewModel } from '@modules/manager/view-models/RelatorioViewModel';
import { ConfiguracaoService } from '@modules/manager/sistema/configuracao/configuracao.service';
import { DatePipe } from '@angular/common';
import { ConverteXmlPdfService } from '../converte-xml-pdf.service';
import { ConfiguracaoNfeService } from '@modules/manager/sistema/configuracao-nfe/configuracao-nfe.service';
@Injectable({
  providedIn: 'root'
})
export class NfeService {

  idEmpresaMaxdata = this.autenticacao$.acessoUsuarioValue?.idEmpresaMaxdata;
  urlAPI = `${URLBASE}/${this.idEmpresaMaxdata}/nf_service`;
  urlGet = `${URLBASE}/${this.idEmpresaMaxdata}/nf_service/get`;

  urlGetBlob = `${URLBASE}/${this.idEmpresaMaxdata}/nf_service/getBlob`;
  urlGetGroup = `${URLBASE}/${this.idEmpresaMaxdata}/nf_service/getGroup`;
  urlAddUpdate = `${URLBASE}/${this.idEmpresaMaxdata}/nf_service/addupdate`;
  urlProcessar = `${URLBASE}/${this.idEmpresaMaxdata}/nf_service/processar`;
  urlCalcularImposto = `${URLBASE}/${this.idEmpresaMaxdata}/nf_service/calcularImposto`;
  urlGerarXmlTmp = `${URLBASE}/${this.idEmpresaMaxdata}/nf_service/gerarxmltmp`;
  urlAddUpdateBlob = `${URLBASE}/${this.idEmpresaMaxdata}/nf_service/UpdateBlob`;
  urlConcluir = `${URLBASE}/${this.idEmpresaMaxdata}/nf_service/concluir`;
  urlCancelar = `${URLBASE}/${this.idEmpresaMaxdata}/nf_service/cancelar`;
  urlCartaCorrecao = `${URLBASE}/${this.idEmpresaMaxdata}/nf_service/GerarCartaCorrecao`;
  urlAddUpdateConcluida = `${URLBASE}/${this.idEmpresaMaxdata}/nf_service/UpdateNfConcluida`;
  urlPreverDANFe = `${URLBASE}/${this.idEmpresaMaxdata}/nf_service/gerarpdftmp`;
  urlImpressaoDANFe = `${URLBASE}/${this.idEmpresaMaxdata}/nf_service/GerarDANFe`;
  urlImpressaoRel = `${URLIMPRESSAO}/api/relatorio`;
  urlExportarXML = `${URLBASE}/${this.idEmpresaMaxdata}/nf_service/GerarXmlTmpArray`;
  urlConsultarProtocolo = `${URLBASE}/${this.idEmpresaMaxdata}/nf_service/ConsultarProtocolo`;


  constructor(
    private http: HttpClient,
    private autenticacao$: AutenticacaoService,
    private nfItem$: NfItemService,
    private configuracao$: ConfiguracaoService,
    private datePipe: DatePipe,
    private converteXmlPdfService$: ConverteXmlPdfService,
    private configuracaoNfe$: ConfiguracaoNfeService) { }

  obterTodos(pesquisa: NfPesquisaViewModel): Observable<RetornoApiViewModel<NfPesquisaViewModel[]>> {

    let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel()

    pesquisaGeral.limit = pesquisa?.quantidadeRegistrosPagina || 0;

    return this.http.post<RetornoApiViewModel<NfPesquisaViewModel[]>>(this.urlGet, pesquisaGeral)
  }

  ObterTodosGroup(pesquisa: NfPesquisaViewModel, relatorio: boolean = false): Observable<RetornoApiViewModel<NfPesquisaViewModel[]>> {

    let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel()

    pesquisaGeral.select = `nf.id
                          , nf.numero
                          , nf.tipo
                          , nf.cliente_id                          
                          , cliente.nome as cliente_nome  
                          , CASE WHEN nf.cliente_cpf_cnpj ISNULL THEN cliente.cpf_cnpj ELSE nf.cliente_cpf_cnpj END AS cliente_cpf_cnpj
                          , cliente.fantasia as cliente_fantasia                          
                          , nf.dt_emissao 
                          , nf.tipo_nf
                          , nf.status
                          , nf.nf_tipo_ajuste
                          , nf.nf_tipo_complementar
                          , nf.chave_nf
                          , nf.nfe_autorizado
                          , nf.operacao_id
                          , operacao.descricao as operacao_descricao
                          , nf.dt_lancamento
                          , nf.dt_contabil
                          , nf_item.nf_id
                          ,(select endereco_cidade_codigo_ibge from cliente_endereco inner join endereco on cliente_endereco.endereco_id = endereco.id where cliente_endereco.id= nf.cliente_endereco_id ) as municipio_descarregamento_codigo_ibge
                          ,(select emp_endereco_cidade_codigo_ibge from config where config.id= nf.emp_id ) as municipio_carregamento_codigo_ibge
                          ,
                          CASE 
                            WHEN 
                              nf.vlr_xml_entrada_bc is not null and nf.vlr_xml_entrada_bc > 0 
                            THEN
                              nf.vlr_xml_entrada_bc
                            ELSE
                              sum(COALESCE(nf_item.vlr_bc_icms,0))
                              
                            end
                          as vlr_bc_icms,
                          CASE 
                            WHEN 
                              nf.vlr_xml_entrada_icms is not null and nf.vlr_xml_entrada_icms > 0
                            THEN
                              nf.vlr_xml_entrada_icms
                            ELSE
                              sum(COALESCE(nf_item.vlr_icms,0))
                              
                            end
                          as vlr_icms,
                          CASE 
                            WHEN 
                              nf.vlr_xml_entrada_bcst is not null and nf.vlr_xml_entrada_bcst > 0
                            THEN
                              nf.vlr_xml_entrada_bcst
                            ELSE
                              sum(COALESCE(nf_item.vlr_bc_icms_subst,0)) 
                            end
                          as vlr_bc_icms_subst,
                          CASE 
                            WHEN 
                              nf.vlr_xml_entrada_st is not null and nf.vlr_xml_entrada_st > 0
                            THEN
                              nf.vlr_xml_entrada_st
                            ELSE
                              sum(COALESCE(nf_item.vlr_icms_subst,0)) 
                            end
                            as vlr_icms_subst,
                          sum(COALESCE(nf_item.vlr_bc_issqn,0)) as vlr_bc_issqn,
                          sum(COALESCE(nf_item.vlr_issqn,0)) as vlr_issqn,
                          sum(COALESCE(nf_item.vlr_bc_ii,0)) as vlr_bc_ii,
                          sum(COALESCE(nf_item.vlr_ii,0)) as vlr_ii,
                          sum(COALESCE(nf_item.vlr_bc_fcp_st,0)) as vlr_bc_fcp_st,
                          CASE 
                            WHEN 
                              nf.vlr_xml_entrada_fcpst is not null and nf.vlr_xml_entrada_fcpst > 0
                            THEN
                              nf.vlr_xml_entrada_fcpst
                            ELSE
                              sum(COALESCE(nf_item.vlr_fcp_st,0)) 
                            end
                            as vlr_fcp_st,
                          sum(round(CAST(COALESCE(nf_item.qtd_com,0) *  COALESCE(nf_item.vlr_un_com,0) as numeric),2)) as total_s_desc, 
                          sum(round(CAST(COALESCE(nf_item.qtd_com,0) *  COALESCE(nf_item.vlr_un_com,0) as numeric),2)) - sum(COALESCE(nf_item.qtd_com,0) * COALESCE(nf_item.vlr_un_com,0) * (COALESCE(nf_item.aliq_desconto,0) / 100)) as vlr_total_prod,
                          CASE 
                            WHEN 
                              nf.vlr_xml_entrada_frete is not null and nf.vlr_xml_entrada_frete > 0
                            THEN
                              nf.vlr_xml_entrada_frete
                            ELSE
                              sum(COALESCE(nf_item.vlr_frete,0)) 
                            end
                          as vlr_frete,
                          CASE 
                            WHEN 
                              nf.vlr_xml_entrada_seg is not null and nf.vlr_xml_entrada_seg > 0
                            THEN
                              nf.vlr_xml_entrada_seg
                            ELSE
                              sum(COALESCE(nf_item.vlr_seguro,0))
                            end
                          as vlr_seguro,
                          CASE 
                            WHEN 
                              nf.vlr_xml_entrada_outro is not null and nf.vlr_xml_entrada_outro > 0
                            THEN
                              nf.vlr_xml_entrada_outro
                            ELSE
                              sum(COALESCE(nf_item.vlr_outras_desp,0))
                            end
                          as vlr_outras_desp,
                          CASE 
                            WHEN 
                              nf.vlr_xml_entrada_ipi is not null and nf.vlr_xml_entrada_ipi > 0
                            THEN
                              nf.vlr_xml_entrada_ipi
                            ELSE
                              sum(COALESCE(nf_item.vlr_ipi,0)) 
                            end
                          as vlr_ipi,
                          sum(COALESCE(nf_item.qtd_com,0) * COALESCE(nf_item.vlr_un_com,0) * (COALESCE(nf_item.aliq_desconto,0) / 100)) as vlr_desc,
                          CASE 
                            WHEN 
                              nf.vlr_xml_entrada_icms_deson is not null and nf.vlr_xml_entrada_icms_deson > 0
                            THEN
                              nf.vlr_xml_entrada_icms_deson
                            ELSE
                              sum(COALESCE(nf_item.vlr_icms_deson,0)) 
                            end
                          as vlr_icms_deson,
                          CASE 
                            WHEN 
                              nf.vlr_xml_entrada_nf is not null and nf.vlr_xml_entrada_nf > 0
                            THEN
                              nf.vlr_xml_entrada_nf
                            ELSE
                              sum(COALESCE(nf_item.vlr_icms_subst,0)
                                  + COALESCE(nf_item.vlr_fcp_st,0)
                                  + COALESCE(nf_item.vlr_frete,0)
                                  + COALESCE(nf_item.vlr_seguro,0)
                                  + COALESCE(nf_item.vlr_outras_desp,0)
                                  + COALESCE(nf_item.vlr_ipi,0)
                                  + COALESCE(nf_item.vlr_ii,0)
                                  + COALESCE(nf_item.qtd_com,0) * COALESCE(nf_item.vlr_un_com,0)
                                  - COALESCE(nf_item.qtd_com,0) * COALESCE(nf_item.vlr_un_com,0) * (COALESCE(nf_item.aliq_desconto,0) / 100)
                                  - COALESCE(nf_item.vlr_icms_deson,0))
                            end
                          as total_c_desc`;

    pesquisaGeral.group = `nf.id,
                      		nf.numero,
                      		nf.tipo,
                      		nf.cliente_id,
                      		cliente.nome,
                          cliente.fantasia,
                          cliente.cpf_cnpj,
                      		nf.dt_emissao, 
                      		nf.tipo_nf,
                      		nf.status,
                          nf.nf_tipo_ajuste,
                          nf.nf_tipo_complementar,
                          nf.nfe_autorizado,
                      		nf.operacao_id,
                          operacao.descricao,
                      		nf.dt_lancamento,
                      		nf.dt_contabil,
                      		nf_item.nf_id,
                          nf.chave_nf`;

    pesquisaGeral.order = pesquisa?.order ? pesquisa?.order : 'nf.dt_emissao desc'

    pesquisaGeral.limit = relatorio ? 0 : pesquisa?.quantidadeRegistrosPagina || 100;

    pesquisaGeral.start = pesquisa?.start || 0;

    pesquisaGeral.where = ` and nf.tipo in (${pesquisa.tipo})`

    if (pesquisa?.status == 'I') pesquisaGeral.where += ` and nf.inutilizacao_protocolo <> '' `;
    else if (pesquisa?.status == 'C') pesquisaGeral.where += ` and nf.status = '${pesquisa?.status}' and nf.inutilizacao_protocolo = '' `;
    else if (pesquisa?.status) pesquisaGeral.where += ` and nf.status = '${pesquisa?.status}'`;

    if (pesquisa?.status == 'F' && pesquisa?.nfe_autorizado) pesquisaGeral.where += ` and nf.nfe_autorizado = ${pesquisa?.nfe_autorizado ? 1 : 0}`

    if (pesquisa?.dt_emissao_inicial && pesquisa?.dt_emissao_final && !pesquisa?.numero) {

      pesquisaGeral.where += ` and nf.dt_emissao between '${pesquisa?.dt_emissao_inicial}T00:00:00' and '${pesquisa?.dt_emissao_final}T23:59:59'`;

    }

    if (pesquisa?.dt_lancamento_inicial && pesquisa?.dt_lancamento_final && !pesquisa?.numero) {

      pesquisaGeral.where += ` and nf.dt_lancamento between '${pesquisa?.dt_lancamento_inicial}T00:00:00' and '${pesquisa?.dt_lancamento_final}T23:59:59'`;

    }

    if (pesquisa?.dt_contabil_inicial && pesquisa?.dt_contabil_final && !pesquisa?.numero) {

      pesquisaGeral.where += ` and nf.dt_contabil between '${pesquisa?.dt_contabil_inicial}T00:00:00' and '${pesquisa?.dt_contabil_final}T23:59:59'`;

    }

    if (pesquisa?.cliente_id) {

      pesquisaGeral.where += ` and nf.cliente_id = ${pesquisa?.cliente_id}`;

    } else {

      pesquisa = Object.assign({ cliente_nome: null, cliente_fantasia: null }, pesquisa)

    }

    if (pesquisa?.numero) pesquisaGeral.where += " and nf.numero = " + pesquisa?.numero;

    if (pesquisa?.id) pesquisaGeral.where += " and nf.id = " + pesquisa?.id;

    if (pesquisa?.operacao_id && pesquisa?.operacao_id > 0) pesquisaGeral.where += " and nf.operacao_id = " + pesquisa?.operacao_id;

    if (pesquisa?.tipo_nf_sped) pesquisaGeral.where += ` and nf.tipo_nf_sped = '${pesquisa?.tipo_nf_sped}'`;

    return this.http.post<RetornoApiViewModel<NfPesquisaViewModel[]>>(this.urlGetGroup, pesquisaGeral)
  }

  obterPorId(id: number, tipo?: number | string): Observable<RetornoApiViewModel<NfViewModel[]>> {

    let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel();

    pesquisaGeral.select = `nf.id
                              , CASE WHEN nf.cliente_nome= '' THEN cliente.nome ELSE nf.cliente_nome END AS cliente_nome  
                              , CASE WHEN nf.cliente_cpf_cnpj ISNULL THEN cliente.cpf_cnpj ELSE nf.cliente_cpf_cnpj END AS cliente_cpf_cnpj
                              , nf.*
                              , TO_CHAR(nf.dt_emissao, 'YYYY-MM-DD HH24:MI') as dt_emissao
                              , TO_CHAR(nf.dt_contabil, 'YYYY-MM-DD') as dt_contabil`;

    pesquisaGeral.where = ` and nf.id = ${id} and nf.tipo in (${tipo})`;

    return this.http.post<RetornoApiViewModel<NfViewModel[]>>(`${this.urlGet}`, pesquisaGeral);
  }
  obterPorNum(numero: number, tipo?: number | string): Observable<RetornoApiViewModel<NfViewModel[]>> {

    let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel();

    pesquisaGeral.select = `nf.id
                            , CASE WHEN nf.cliente_nome ISNULL THEN cliente.nome ELSE nf.cliente_nome END AS cliente_nome  
                            , CASE WHEN nf.cliente_cpf_cnpj ISNULL THEN cliente.cpf_cnpj ELSE nf.cliente_cpf_cnpj END AS cliente_cpf_cnpj
                            , nf.*
                            , TO_CHAR(nf.dt_emissao, 'YYYY-MM-DD HH24:MI') as dt_emissao
                            , TO_CHAR(nf.dt_contabil, 'YYYY-MM-DD') as dt_contabil`;

    pesquisaGeral.where = ` and nf.id = ${numero} and nf.tipo in (${tipo})`;

    return this.http.post<RetornoApiViewModel<NfViewModel[]>>(`${this.urlGet}`, pesquisaGeral);
  }

  ObterTodosId(pesquisa: NfPesquisaViewModel): Observable<RetornoApiViewModel<NfPesquisaViewModel[]>> {

    let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel()

    pesquisaGeral.select = `nf.id`;

    pesquisaGeral.limit = 0;

    pesquisaGeral.group = `nf.id`;

    pesquisaGeral.start = pesquisa?.start || 0;

    pesquisaGeral.where = ` and nf.tipo in (${pesquisa.tipo})`

    pesquisaGeral.where += ` and nf.status = 'F'`;

    if (pesquisa?.status == 'F' && pesquisa?.nfe_autorizado) pesquisaGeral.where += ` and nf.nfe_autorizado = 1`

    if (pesquisa?.dt_emissao_inicial && pesquisa?.dt_emissao_final && !pesquisa?.numero) {
      pesquisaGeral.where += ` and nf.dt_emissao between '${pesquisa?.dt_emissao_inicial}T00:00:00' and '${pesquisa?.dt_emissao_final}T23:59:59'`;
    }

    if (pesquisa?.cliente_id) {
      pesquisaGeral.where += ` and nf.cliente_id = ${pesquisa?.cliente_id}`;
    } else {
      pesquisa = Object.assign({ cliente_nome: null, cliente_fantasia: null }, pesquisa)
    }

    if (pesquisa?.numero) pesquisaGeral.where += " and nf.numero = " + pesquisa?.numero;

    if (pesquisa?.id) pesquisaGeral.where += " and nf.id = " + pesquisa?.id;

    if (pesquisa?.operacao_id && pesquisa?.operacao_id > 0) pesquisaGeral.where += " and nf.operacao_id = " + pesquisa?.operacao_id;

    if (pesquisa?.tipo_nf_sped) pesquisaGeral.where += ` and nf.tipo_nf_sped = '${pesquisa?.tipo_nf_sped}'`;

    return this.http.post<RetornoApiViewModel<NfPesquisaViewModel[]>>(this.urlGetGroup, pesquisaGeral)
  }

  ObterArquivoBase64(nfViewModel: ArquivoBase64ViewModel = new ArquivoBase64ViewModel()): Observable<RetornoApiViewModel<ArquivoBase64ViewModel>> {
    return this.http.post<RetornoApiViewModel<ArquivoBase64ViewModel>>(this.urlGetBlob, nfViewModel)
  }

  adicionar(nfViewModel: CadastroViewModel<NfViewModel>): Observable<RetornoApiViewModel<NfViewModel[]>> {
    return this.http.post<RetornoApiViewModel<NfViewModel[]>>(this.urlAddUpdate, nfViewModel);
  }

  atualizar(id: number, nfViewModel: CadastroViewModel<NfViewModel>): Observable<RetornoApiViewModel<NfViewModel>> {
    return this.http.put<RetornoApiViewModel<NfViewModel>>(`${this.urlAddUpdate}/${id}`, nfViewModel);
  }

  processar(id: number, nfViewModel: NfProcessarViewModel): Observable<RetornoApiViewModel<NfProcessarViewModel>> {
    return this.http.post<RetornoApiViewModel<NfProcessarViewModel>>(`${this.urlProcessar}/${id}`, nfViewModel);
  }

  calcularImposto(id: number, nfViewModel: any): Observable<RetornoApiViewModel<any>> {
    return this.http.post<RetornoApiViewModel<any>>(`${this.urlCalcularImposto}/${id}`, nfViewModel);
  }

  GerarXmlTemp(id: number, nfViewModel: any): Observable<RetornoApiViewModel<any>> {
    return this.http.post<RetornoApiViewModel<any>>(`${this.urlGerarXmlTmp}/${id}`, nfViewModel);
  }

  AtualizarArquivoBase64(id: number, nfViewModel: ArquivoBase64ViewModel): Observable<ArquivoBase64ViewModel> {
    return this.http.post<ArquivoBase64ViewModel>(`${this.urlAddUpdateBlob}/${id}`, nfViewModel)
  }

  concluir(id: number, nfViewModel: NfConcluirCancelarViewModel): Observable<RetornoApiViewModel<NfConcluirCancelarViewModel>> {
    return this.http.post<RetornoApiViewModel<NfConcluirCancelarViewModel>>(`${this.urlConcluir}/${id}`, nfViewModel);
  }

  cancelar(id: number, nfCancelar: any) {
    return this.http.post<RetornoApiViewModel<any>>(`${this.urlCancelar}/${id}`, nfCancelar);
  }

  cartaCorrecao(id: number, nfCartaCorrecao: any) {
    return this.http.post<RetornoApiViewModel<any>>(`${this.urlAPI}/GerarCartaCorrecao/${id}`, nfCartaCorrecao);
  }

  gerarNfComplementar(id: number, nfComplementar: any) {
    return this.http.post<RetornoApiViewModel<any>>(`${this.urlAPI}/GerarNfComplementar/${id}`, nfComplementar);
  }


  gerarNfAjuste(id: number): Observable<RetornoApiViewModel<any>> {
    return this.http.post<RetornoApiViewModel<any>>(`${this.urlAPI}/GerarNfAjuste/${id}`, { id_nf: id });
  }

  atualizarConcluida(id: number, nfViewModel: CadastroViewModel<NfViewModel>): Observable<RetornoApiViewModel<NfViewModel>> {
    return this.http.put<RetornoApiViewModel<NfViewModel>>(`${this.urlAPI}/UpdateNfConcluida/${id}`, nfViewModel);
  }

  imprimir(rel: any, dados: any): Observable<any> {
    return this.http.post<any>(`${this.urlImpressaoRel}/${rel}/`, dados);
  }

  preverDanfe(id_nf: number) {
    return this.http.post<RetornoApiViewModel<any>>(`${this.urlPreverDANFe}/${id_nf}`, { id_nf })
  }

  imprimirDanfe(id_nf: number) {
    return this.http.post<RetornoApiViewModel<any>>(`${this.urlImpressaoDANFe}/${id_nf}`, { id_nf })
  }

  imprimirNfEntrada(pesquisa: NfPesquisaViewModel) {

    let objRelatorio: RelatorioViewModel<any, NfPesquisaViewModel[]> = new RelatorioViewModel<any, NfPesquisaViewModel[]>();

    let pesquisaNfGroup: NfPesquisaViewModel = new NfPesquisaViewModel();

    pesquisaNfGroup = Object.assign(pesquisa, { tipo: `1,4,5,6,7,8` });

    let logomarca: string = null;

    return this.ObterTodosGroup(pesquisaNfGroup, true)
      .pipe(
        concatMap(
          retorno => {

            objRelatorio.obj = Object.assign(retorno.total > 0 ? retorno.result : [], objRelatorio.obj);

            return this.configuracao$.obterImagem(this.autenticacao$.acessoUsuarioValue?.idLoja || 0)
          }),
        concatMap(retorno => {

          logomarca = retorno.result[0].img_logomarca

          return this.configuracao$.obterPorId(this.autenticacao$.acessoUsuarioValue?.idLoja || 0)
        }),
        concatMap(retorno => {

          objRelatorio.params = Object.assign({
            emp_cpf_cnpj: retorno.total > 0 ? retorno.result[0].emp_cnpj : '',
            emp_razao: retorno.total > 0 ? retorno.result[0].emp_razao : '',
            emp_fantasia: retorno.total > 0 ? retorno.result[0].emp_fantasia : '',
            emp_fone: retorno.total > 0 ? retorno.result[0].emp_fone : '',
            emp_endereco: retorno.total > 0 ? retorno.result[0].emp_endereco : '',
            emp_endereco_municipio_descricao: retorno.total > 0 ? retorno.result[0].emp_endereco_municipio_descricao : '',
            emp_logo: logomarca
          });

          const apiRelatorio = new ApiRelatorioViewModel<any, any>();

          apiRelatorio.relatorio = objRelatorio;

          apiRelatorio.subrelatorio = [];

          return this.imprimir('nf_entrada', apiRelatorio);
        }
        )
      )

  }

  imprimirNfEntradaDetalhada(pesquisa: NfItemPesquisaViewModel) {

    let objRelatorio: RelatorioViewModel<any, NfPesquisaViewModel[]> = new RelatorioViewModel<any, NfPesquisaViewModel[]>();

    let pesquisaNfGroup: NfPesquisaViewModel = new NfPesquisaViewModel();

    pesquisaNfGroup = Object.assign({ id: pesquisa?.nf_id || 0, tipo: `1,4,5,6,7,8` });

    let logomarca: string = null;

    return this.ObterTodosGroup(pesquisaNfGroup)
      .pipe(
        concatMap(
          retorno => {

            const nf = retorno.total > 0 ? retorno.result[0] : null;
        
            if (nf) {
              nf.dt_contabil = this.datePipe.transform(nf.dt_contabil, 'yyyy-MM-dd');

              nf.dt_emissao = this.datePipe.transform(nf.dt_emissao, 'yyyy-MM-dd');
            }
            
            objRelatorio.obj = Object.assign(nf || {}, objRelatorio.obj);
            return this.nfItem$.obterTodos(pesquisa)
          }
        ),
        concatMap(
          retorno => {

            objRelatorio.obj['itens'] = retorno.total > 0 ? retorno.result : [];

            return this.configuracao$.obterImagem(this.autenticacao$.acessoUsuarioValue?.idLoja || 0)
          }),
        concatMap(retorno => {

          logomarca = retorno.result[0].img_logomarca

          return this.configuracao$.obterPorId(this.autenticacao$.acessoUsuarioValue?.idLoja || 0)
        }),
        concatMap(retorno => {

          objRelatorio.params = Object.assign({
            emp_cpf_cnpj: retorno.total > 0 ? retorno.result[0].emp_cnpj : '',
            emp_razao: retorno.total > 0 ? retorno.result[0].emp_razao : '',
            emp_fantasia: retorno.total > 0 ? retorno.result[0].emp_fantasia : '',
            emp_fone: retorno.total > 0 ? retorno.result[0].emp_fone : '',
            emp_endereco: retorno.total > 0 ? retorno.result[0].emp_endereco : '',
            emp_endereco_municipio_descricao: retorno.total > 0 ? retorno.result[0].emp_endereco_municipio_descricao : '',
            emp_logo: logomarca
          });

          const apiRelatorio = new ApiRelatorioViewModel<any, any>();
        
          apiRelatorio.relatorio = objRelatorio;
          apiRelatorio.subrelatorio = [{ nome: "sub_nf_entrada_detalhada_produtos" }];

          return this.imprimir('nf_entrada_detalhada', apiRelatorio);
        }
        )
      )

  }

  imprimirDanfeCartaCorrecao(id_nf: number) {
    return this.http.post<RetornoApiViewModel<any>>(`${this.urlAPI}/GerarPdfCartaCorrecao/${id_nf}`, { id_nf: id_nf })
  }

  enviarPorEmail(obj: any) {
    return this.http.post<RetornoApiViewModel<any>>(`${this.urlAPI}/EnviarEmail/${obj?.id_nf || 0}`, obj)
  }

  exportarXmlSaida(id_nf_array: string) {
    return this.http.post<RetornoApiViewModel<any>>(`${this.urlExportarXML}`, { id_nf_array: id_nf_array })
  }

  consultarProtocolo(id: any) {
    return this.http.post<RetornoApiViewModel<any>>(`${this.urlConsultarProtocolo}/${id || 0}`, { id_nf: id })
  }

  GerarXmlInutilizada(id: number): Observable<RetornoApiViewModel<any>> {
    return this.http.post<RetornoApiViewModel<any>>(`${this.urlAPI}/GerarXmlInutilizada/${id}`, { id_nf: id });
  }

  GerarPdfInutilizada(id: number): Observable<RetornoApiViewModel<any>> {
    return this.http.post<RetornoApiViewModel<any>>(`${this.urlAPI}/GerarPdfInutilizada/${id}`, { id_nf: id });
  }
  gerarDanfe(id: number, nfViewModel: any): Observable<any> {
    const rota ='danfe'
    return this.GerarXmlTemp(id, nfViewModel).pipe(
      concatMap(({ result: [{ XML_Base64 }] }) =>
        this.configuracaoNfe$.obterPorEmpresaLogada().pipe(
          catchError(() => of(null)),
          concatMap(({ result: [{ id }] }) =>
            this.configuracaoNfe$.obterImagem(id ?? 0).pipe(
              catchError(() => of(null)),
              concatMap(({ result: [{ img_logo_nfe }] }) =>

              this.converteXmlPdfService$.imprimir(XML_Base64, img_logo_nfe, rota)
              )
            )
          )
        )
      )
    );
  }
}
