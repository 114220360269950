export class ContasReceberPagarViewModel{
    id?:number;
    emp_id?: number;
    cliente_id?: number;
    cliente_nome?: string;
    atendente_id?: number;
    usuario_lancou_id?: number;
    usuario_lancou_nome?: string;
    venda_id?: number;
    nf_id?: number;
    conta_id?: number;
    pgto_tipo_id?: number | number[];
    cartao_id?: number;
    caixa_id?: number;
    vlr?: number;
    vlr_original?: number;
    vlr_juros?: number;
    vlr_multa?: number;
    vlr_desconto_fin?: number;
    codigo_banco?: string;
    codigo_agencia?: string;
    codigo_conta_corrente?: string;
    numero_doc?: string;
    nosso_numero?: string;
    codigo_portador?: string;
    banco_numero_arquivo?: number;
    msg_portador?: string;
    dt_emissao?: string;
    dt_vencimento?: string;
    dt_lancamento?: string;
    obs?: string;
    historico?: string;
    acompanhamento?: string;
    numero_parcela?: number = 1;
    tipo_conta?: 'R'|'P' |'T'|undefined;
    dt_cancel?: string;
    usuario_cancel_id?: number;
    usuario_cancel_nome?: string;
    motivo_cancel?: string;
    usuario_cancelou_id?: number;
    status?: 'N'|'S'|'F'|'C'|'G'|'P'|'D' | string | string[];
    dt_quitou?: string;
    usuario_quitou_id?: number;
    usuario_quitou_nome?: string;
    tipo_operacao?: 'CO'|'VE'|'FA'|'BP'|'FI'|'CR'|'SU'|'SA'|'PG'|'RE'|'DV'|'OS'|'FR'|'FP'| undefined;
    dt_custodia?: string;
    vlr_comissao?: number;
    aliq_comissao?: number;
    dt_boleto_remessa?: string;
    dt_boleto_retorno?: string;
    codigo_aliena_cheque_dev?: number;
    chk_recebido_no_caixa?: number;
    usuario_faturou_id?: number;
    dt_faturou?: string;
    vlr_dinheiro_troco?: number;
    chk_estorno?: boolean;
    dt_estorno?: string;
    usuario_estorno_id?: number;
    dt_descontado?: string;
    conta_descontado_id?: number;
    pgto_descontado_id?: number;
    avalista_id?: number;
    avalista_nome?: string;
    portador_id?: number;
    portador_acbr_codigo_mora?: string;
    portador_acbr_data_baixa?: string;
    portador_acbr_data_desconto?: string;
    portador_acbr_data_mora_juros?: string;
    portador_acbr_data_protesto?: string;
    portador_acbr_multa_valor_fixo?: number;
    portador_acbr_parcela?: number;
    portador_acbr_percentual_multa?: number;
    portador_acbr_tipo_desconto?: number;
    portador_acbr_tipo_juros?: number;
    portador_acbr_total_parcelas?: number;
    portador_acbr_valor_abatimento?: number;
    portador_acbr_valor_desconto?: number;
    portador_acbr_valor_mora_juros?: number;
    dt_boleto_grafico?: string;
    centro_custo_id?: number;
    plano_conta_id?: number;
    sub_plano_conta_id?: number;
    pgto_ref_pgto_id?: number;
    cte_id?: number;
    codigo_autorizacao?: string;
    nsu?: string;
}