<p-dialog [(visible)]="abrirFecharDioptriaModal" [modal]="true" [closable]="true" [style]="{width: '100vw'}"
    [maximizable]="true" [baseZIndex]="10000" [draggable]="false" [resizable]="false" (onHide)="closeModalDioptria()">
    <ngx-spinner name="modalAtualiza" bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-clip-rotate"
        [fullScreen]="false"></ngx-spinner>

    <div class="row  d-flex justify-content-between">
        <div class="col-mb">
            <h1 class="h4 border-left pl-2">
                Formulário de Receita
            </h1>
        </div>

        <div class="btns-group">
            <a class="btn btn-sm btn-outline-secondary" target="_blank" (click)="imprimir()" ><i class="material-icons">print</i> Imprimir</a>
            <button class="btn btn-sm btn-outline-secondary" (click)="closeModalDioptria()"><i
                    class="material-icons">close</i>Cancelar</button>
            <button class="btn btn-sm btn-outline-success" id="salvar" type="button" (click)="onSubmit()"
                [disabled]="carregando">
                <div [ngSwitch]="carregando">
                    <div *ngSwitchCase="true">
                        <span class="spinner-border spinner-border-sm" role="status"
                            aria-hidden="true"></span>&nbsp;
                        <span>Carregando ...</span>
                    </div>
                    <div *ngSwitchDefault>
                        <i class="material-icons">done_all</i><span>Salvar</span>
                    </div>
                </div>
            </button>
        </div>

    </div>
    <form [formGroup]="cadastroForm">

        <div class="card">

            <div class="card-header" >
                Cadastro de Receita
            </div>
            <div class="card-body">
                <div class="form-row">
                    <div class="form-group col-md-1">
                        <label for="id" class="col-form-label-sm">Cod. Receita</label>
                        <div class="input-group mb-3 row">
                        <input type="text"  id="id" name="id"  class="form-control form-control-sm" 
                        formControlName="id" readonly>     
                        </div>
                    </div>
                    <div class="form-group col-md-1">
                        <label for="cliente_id" class="col-form-label-sm">Cod. Cliente</label>
                        <div class="input-group mb-3 row">
                        <input type="text"  id="cliente_id" name="cliente_id"  class="form-control form-control-sm" 
                        formControlName="cliente_id" readonly>     
                        </div>
                    </div>
                    <div class="form-group col-md-3">
                        <label for="medico_nome" class="col-form-label-sm">Médico Responsavél</label>
                        <div class="input-group mb-3 row">
                            <select id="medico_nome" name="medico_nome" class="form-control form-control-sm"
                                formControlName="clinica_medico_id">
                                <option *ngFor="let item of listaMedicos" [ngValue]="item.id">
                                    {{item.nome}}</option>
                            </select>
                            <div class="input-group-append">
                                <button class="btn btn-sm btn-outline-success float-right" id="adicionarMedico"
                                    data-toggle="tooltip" data-placement="top" title="Novo Médico" type="button"
                                    (click)="showModalCadastroMedico()">
                                    <i class="material-icons">add</i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-md-3">
                        <label for="tipo_armacao" class="col-form-label-sm">Tipo Armação</label>
                        <div class="input-group mb-3 row">
                            <select id="tipo_armacao" name="tipo_armacao" class="form-control form-control-sm"
                                formControlName="dioptria_tipo_armacao_id">
                                <option *ngFor="let item of listaArmacao" [ngValue]="item.id">
                                    {{item.descricao}}</option>
                            </select>
                            <div class="input-group-append">
                                <button class="btn btn-sm btn-outline-success float-right" id="adicionarArmacao"
                                    data-toggle="tooltip" data-placement="top" title="Nova Armação" type="button"
                                    (click)="showModalCadastroArmacao()">
                                    <i class="material-icons">add</i>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2">
                        <div class="form-group">
                            <label for="nome" class="col-form-label-sm">Data Vencimento</label>
                            <input type="date" id="dt_venc_receita" name="dt_venc_receita"
                                class="form-control form-control-sm" autocomplete="off"
                                formControlName="dt_venc_receita" />
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-center">


                    <div class="">

                        <div class="d-flex justify-content-center mt-1">
                            <div class="border mt-1 mb-1">
                                <h5 class="card-title  mt-5" style="transform: rotate(270deg)">LONGE</h5>

                            </div>
                            <div class="container-fluid border mt-1 mb-1">
                                <div class="d-flex justify-content-center">

                                    <div class="mr-4">

                                        <h6 class="card-title">OLHO DIREITO</h6>
                                        <div class="form-row border">
                                            <div class="form-group col-sm">
                                                <label for="longe_olho_dir_esferico"
                                                    class="col-form-label-sm">Esferico</label>
                                                <input type="text" class="form-control" id="longe_olho_dir_esferico"
                                                    placeholder="Esferico" formControlName="longe_olho_dir_esferico" oninput="this.value = this.value.replace(/[^0-9, + -]/g, '').replace(/(\..*?)\..*/g, '$1');" >

                                            </div>
                                            <div class="form-group col-sm">
                                                <label for="longe_olho_dir_cilindrico"
                                                    class="col-form-label-sm">Cilíndrico</label>
                                                <input type="text" class="form-control" id="longe_olho_dir_cilindrico"
                                                    placeholder="Cilíndrico"
                                                    formControlName="longe_olho_dir_cilindrico"   oninput="this.value = this.value.replace(/[^0-9, + -]/g, '').replace(/(\..*?)\..*/g, '$1');">

                                            </div>
                                            <div class="form-group col-sm">
                                                <label for="longe_olho_dir_eixo" class="col-form-label-sm">Eixo</label>
                                                <input type="text" class="form-control" id="longe_olho_dir_eixo"
                                                    placeholder="Eixo" formControlName="longe_olho_dir_eixo"  oninput="this.value = this.value.replace(/[^0-9, + -]/g, '').replace(/(\..*?)\..*/g, '$1');">

                                            </div>
                                            <div class="form-group col-sm">
                                                <label for="longe_olho_dir_dnp" class="col-form-label-sm">DNP</label>
                                                <input type="text" class="form-control" id="longe_olho_dir_dnp"
                                                    placeholder="DNP" formControlName="longe_olho_dir_dnp" (change)="somaDP()"  oninput="this.value = this.value.replace(/[^0-9, + -]/g, '').replace(/(\..*?)\..*/g, '$1');">

                                            </div>
                                            <div class="form-group col-sm">
                                                <label for="longe_olho_dir_altura"
                                                    class="col-form-label-sm">Altura</label>
                                                <input type="text" class="form-control" id="longe_olho_dir_altura"
                                                    placeholder="Altura" formControlName="longe_olho_dir_altura"  oninput="this.value = this.value.replace(/[^0-9, + -]/g, '').replace(/(\..*?)\..*/g, '$1');">

                                            </div>
                                            <div class="form-group col-sm">
                                                <label for="longe_olho_dir_centro_optico" class="col-form-label-sm">Centro
                                                    Optico</label>
                                                <input type="text" class="form-control" id="longe_olho_dir_centro_optico"
                                                    placeholder="Esferico" formControlName="longe_olho_dir_centro_optico"  oninput="this.value = this.value.replace(/[^0-9, + -]/g, '').replace(/(\..*?)\..*/g, '$1');">

                                            </div>
                                            

                                        </div>
                                    </div>
                                    <div class="ml-4">

                                        <h6 class="card-title">OLHO ESQUERDO</h6>
                                        <div class="form-row border">
                                            <div class="form-group col-sm">
                                                <label for="longe_olho_esq_esferico"
                                                    class="col-form-label-sm">Esferico</label>
                                                <input type="text" class="form-control" id="longe_olho_esq_esferico"
                                                    placeholder="Esferico" formControlName="longe_olho_esq_esferico"  oninput="this.value = this.value.replace(/[^0-9, + -]/g, '').replace(/(\..*?)\..*/g, '$1');">

                                            </div>
                                            <div class="form-group col-sm">
                                                <label for="longe_olho_esq_cilindrico"
                                                    class="col-form-label-sm">Cilíndrico</label>
                                                <input type="text" class="form-control" id="longe_olho_esq_cilindrico"
                                                    placeholder="Cilíndrico"
                                                    formControlName="longe_olho_esq_cilindrico"   oninput="this.value = this.value.replace(/[^0-9, + -]/g, '').replace(/(\..*?)\..*/g, '$1');">

                                            </div>
                                            <div class="form-group col-sm">
                                                <label for="longe_olho_esq_eixo" class="col-form-label-sm">Eixo</label>
                                                <input type="text" class="form-control" id="longe_olho_esq_eixo"
                                                    placeholder="Eixo" formControlName="longe_olho_esq_eixo"  oninput="this.value = this.value.replace(/[^0-9, + -]/g, '').replace(/(\..*?)\..*/g, '$1');">

                                            </div>
                                            <div class="form-group col-sm">
                                                <label for="longe_olho_esq_dnp" class="col-form-label-sm">DNP</label>
                                                <input type="text" class="form-control" id="longe_olho_esq_dnp"
                                                    placeholder="DNP" formControlName="longe_olho_esq_dnp" (change)="somaDP()" oninput="this.value = this.value.replace(/[^0-9, + -]/g, '').replace(/(\..*?)\..*/g, '$1');">

                                            </div>
                                            <div class="form-group col-sm">
                                                <label for="longe_olho_esq_altura"
                                                    class="col-form-label-sm">Altura</label>
                                                <input type="text" class="form-control" id="longe_olho_esq_altura"
                                                    placeholder="Altura" formControlName="longe_olho_esq_altura"  oninput="this.value = this.value.replace(/[^0-9, + -]/g, '').replace(/(\..*?)\..*/g, '$1');">

                                            </div>
                                            <div class="form-group col-sm">
                                                <label for="longe_olho_esq_centro_optico" class="col-form-label-sm">Centro
                                                    Optico</label>
                                                <input type="text" class="form-control" id="longe_olho_esq_centro_optico"
                                                    placeholder="Esferico" formControlName="longe_olho_esq_centro_optico"  oninput="this.value = this.value.replace(/[^0-9, + -]/g, '').replace(/(\..*?)\..*/g, '$1');">

                                            </div>
                                            <div class="form-group col-sm">
                                                <label for="dp_longe" class="col-form-label-sm">DP</label>
                                                <input type="text" class="form-control" id="dp_longo" placeholder="DP" formControlName="dp_longe"readonly>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="d-flex justify-content-center mt-1">
                            <div class="border mt-1 mb-1">
                                <h5 class="card-title  mt-5" style="transform: rotate(270deg)">PERTO</h5>

                            </div>
                            <div class="container-fluid border mt-1 mb-1">
                                <div class="d-flex justify-content-center">

                                    <div class="mr-4">

                                        <h6 class="card-title">OLHO DIREITO</h6>
                                        <div class="form-row border">


                                            <div class="form-group col-sm">
                                                <label for="perto_olho_dir_adicao" class="col-form-label-sm">Adição</label>
                                                <input type="text" class="form-control" id="perto_olho_dir_adicao" 
                                                placeholder="Adição" formControlName="perto_olho_dir_adicao" 
                                                 oninput="this.value = this.value.replace(/[^0-9, + -]/g, '').replace(/(\..*?)\..*/g, '$1');" (change)="adicaoOD()">

                                            </div>
                                            <div class="form-group col-sm">
                                                <label for="perto_olho_dir_esferico"
                                                    class="col-form-label-sm">Esferico</label>
                                                <input type="text" class="form-control" id="perto_olho_dir_esferico"
                                                    placeholder="Esferico" formControlName="perto_olho_dir_esferico"  oninput="this.value = this.value.replace(/[^0-9, + -]/g, '').replace(/(\..*?)\..*/g, '$1');">

                                            </div>
                                            <div class="form-group col-sm">
                                                <label for="perto_olho_dir_cilindrico"
                                                    class="col-form-label-sm">Cilíndrico</label>
                                                <input type="text" class="form-control" id="perto_olho_dir_cilindrico"
                                                    placeholder="Cilíndrico"
                                                    formControlName="perto_olho_dir_cilindrico"   oninput="this.value = this.value.replace(/[^0-9, + -]/g, '').replace(/(\..*?)\..*/g, '$1');">

                                            </div>
                                            <div class="form-group col-sm">
                                                <label for="perto_olho_dir_eixo" class="col-form-label-sm">Eixo</label>
                                                <input type="text" class="form-control" id="perto_olho_dir_eixo"
                                                    placeholder="Eixo" formControlName="perto_olho_dir_eixo"  oninput="this.value = this.value.replace(/[^0-9, + -]/g, '').replace(/(\..*?)\..*/g, '$1');">

                                            </div>
                                            <div class="form-group col-sm">
                                                <label for="perto_olho_dir_dnp" class="col-form-label-sm">DNP</label>
                                                <input type="text" class="form-control" id="perto_olho_dir_dnp"
                                                    placeholder="DNP" formControlName="perto_olho_dir_dnp" (change)="somaDP()"  oninput="this.value = this.value.replace(/[^0-9, + -]/g, '').replace(/(\..*?)\..*/g, '$1');">

                                            </div>
                                            <div class="form-group col-sm">
                                                <label for="perto_olho_dir_altura"
                                                    class="col-form-label-sm">Altura</label>
                                                <input type="text" class="form-control" id="perto_olho_dir_altura"
                                                    placeholder="Altura" formControlName="perto_olho_dir_altura"  oninput="this.value = this.value.replace(/[^0-9, + -]/g, '').replace(/(\..*?)\..*/g, '$1');">

                                            </div>
                                            <div class="form-group col-sm">
                                                <label for="perto_olho_dir_centro_optico" class="col-form-label-sm">Centro
                                                    Optico</label>
                                                <input type="text" class="form-control" id="perto_olho_dir_centro_optico"
                                                    placeholder="Esferico" formControlName="perto_olho_dir_centro_optico"  oninput="this.value = this.value.replace(/[^0-9, + -]/g, '').replace(/(\..*?)\..*/g, '$1');">

                                            </div>
                                            
                                            

                                        </div>
                                    </div>
                                    <div class="ml-4">

                                        <h6 class="card-title">OLHO ESQUERDO</h6>
                                        <div class="form-row border">
                                            <div class="form-group col-sm">
                                                <label for="perto_olho_esq_adicao" class="col-form-label-sm">Adição</label>
                                                <input type="text" class="form-control" id="perto_olho_esq_adicao"
                                                 placeholder="Adição" formControlName="perto_olho_esq_adicao"  
                                                 oninput="this.value = this.value.replace(/[^0-9, + -]/g, '').replace(/(\..*?)\..*/g, '$1');" (change)="adicaoOE()">
                                                
                                            </div>
                                            
                                            <div class="form-group col-sm">
                                                <label for="perto_olho_esq_esferico"
                                                    class="col-form-label-sm">Esferico</label>
                                                <input type="text" class="form-control" id="perto_olho_esq_esferico"
                                                    placeholder="Esferico" formControlName="perto_olho_esq_esferico"  oninput="this.value = this.value.replace(/[^0-9, + -]/g, '').replace(/(\..*?)\..*/g, '$1');">

                                            </div>
                                            <div class="form-group col-sm">
                                                <label for="perto_olho_esq_cilindrico"
                                                    class="col-form-label-sm">Cilíndrico</label>
                                                <input type="text" class="form-control" id="perto_olho_esq_cilindrico"
                                                    placeholder="Cilíndrico"
                                                    formControlName="perto_olho_esq_cilindrico"  oninput="this.value = this.value.replace(/[^0-9, + -]/g, '').replace(/(\..*?)\..*/g, '$1');">

                                            </div>
                                            <div class="form-group col-sm">
                                                <label for="perto_olho_esq_eixo" class="col-form-label-sm">Eixo</label>
                                                <input type="text" class="form-control" id="perto_olho_esq_eixo"
                                                    placeholder="Eixo" formControlName="perto_olho_esq_eixo"  oninput="this.value = this.value.replace(/[^0-9, + -]/g, '').replace(/(\..*?)\..*/g, '$1');">

                                            </div>
                                            <div class="form-group col-sm">
                                                <label for="perto_olho_esq_dnp" class="col-form-label-sm">DNP</label>
                                                <input type="text" class="form-control" id="perto_olho_esq_dnp"
                                                    placeholder="DNP" formControlName="perto_olho_esq_dnp" (change)="somaDP()"  oninput="this.value = this.value.replace(/[^0-9, + -]/g, '').replace(/(\..*?)\..*/g, '$1');">

                                            </div>
                                            <div class="form-group col-sm">
                                                <label for="perto_olho_esq_altura"
                                                    class="col-form-label-sm">Altura</label>
                                                <input type="text" class="form-control" id="perto_olho_esq_altura"
                                                    placeholder="Altura" formControlName="perto_olho_esq_altura"  oninput="this.value = this.value.replace(/[^0-9, + -]/g, '').replace(/(\..*?)\..*/g, '$1');">

                                            </div>
                                            <div class="form-group col-sm">
                                                <label for="perto_olho_esq_centro_optico" class="col-form-label-sm">Centro
                                                    Optico</label>
                                                <input type="text" class="form-control" id="perto_olho_esq_centro_optico"
                                                    placeholder="Esferico" formControlName="perto_olho_esq_centro_optico"  oninput="this.value = this.value.replace(/[^0-9, + -]/g, '').replace(/(\..*?)\..*/g, '$1');">

                                            </div>
                                            
                                            <div class="form-group col-sm">
                                                <label for="dp" class="col-form-label-sm">DP</label>
                                                <input type="text" class="form-control" id="dp" placeholder="DP" formControlName="dp_perto" readonly >

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>


                </div>
                <div class="mt-2">
                    <div class="mr-4">
        
                        <h6 class="card-title"> <strong>DADOS ARMAÇÃO</strong> </h6>
                        <div class="form-row border">
                            <div class="form-group col-sm">
                                <label for="armacao_ponte" class="col-form-label-sm">Ponte</label>
                                <input type="text" class="form-control" id="armacao_ponte"
                                    placeholder="Ponte" formControlName="armacao_ponte"  oninput="this.value = this.value.replace(/[^0-9, + -]/g, '').replace(/(\..*?)\..*/g, '$1');">

                            </div>
                            <div class="form-group col-sm">
                                <label for="armacao_aro"
                                    class="col-form-label-sm">Aro</label>
                                <input type="text" class="form-control" id="armacao_aro"
                                    placeholder="Aro" formControlName="armacao_aro"  oninput="this.value = this.value.replace(/[^0-9, + -]/g, '').replace(/(\..*?)\..*/g, '$1');">

                            </div>
                            <div class="form-group col-sm">
                                <label for="armacao_diag_maior" class="col-form-label-sm">Diag.Maior</label>
                                <input type="text" class="form-control" id="armacao_diag_maior"
                                    placeholder="Diag.Maior" formControlName="armacao_diag_maior"  oninput="this.value = this.value.replace(/[^0-9, + -]/g, '').replace(/(\..*?)\..*/g, '$1');">

                            </div>
                            <div class="form-group col-sm">
                                <label for="armacao_altura" class="col-form-label-sm">Altura</label>
                                <input type="text" class="form-control" id="armacao_altura"
                                    placeholder="Altura" formControlName="armacao_altura"  oninput="this.value = this.value.replace(/[^0-9, + -]/g, '').replace(/(\..*?)\..*/g, '$1');">

                            </div>
                            
                            
                           

                        </div>
                    </div>
                </div>

            </div>

            <div class="form-group">
                <label for="obs">Observação</label>
                <textarea class="form-control" id="obs" rows="3" formControlName="obs"></textarea>
              </div>
        </div>

       
    </form>




    <app-medico-clinica-cadastro-modal [abrirFecharModalMedico]="modalCadastroMedico"
        (closeModal)="closeModalCadastroMedico()">
    </app-medico-clinica-cadastro-modal>

    <app-dioptria-tipo-armacao-modal [abrirFecharDioptriaArmacaoModal]="modalCadastroArmacao"
        (closeModal)="closeModalCadastArmacao()">
    </app-dioptria-tipo-armacao-modal>
</p-dialog>