import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AlertMessage } from '@modules/alert.configuration.service';
import { AutenticacaoService } from '@modules/autenticacao/services/autenticacao.service';
import { ConfiguracaoService } from '@modules/manager/sistema/configuracao/configuracao.service';
import { ConfiguracaoPesquisaViewModel } from '@modules/manager/view-models/sistema/pesquisa/configuracao-pesquisa-view-model';
import { Uteis } from '@modules/utilizarios/uteis';
import { id } from '@swimlane/ngx-charts';

@Component({
  selector: 'app-multiselect-empresa',
  templateUrl: './multiselect-empresa.component.html',
  styles: []
})
export class MultiselectEmpresaComponent implements OnInit {
  @Output() empresasSelecionadas: EventEmitter<any> = new EventEmitter<any>();  
  listaEmpresas: ConfiguracaoPesquisaViewModel[] = [];
  empresas: ConfiguracaoPesquisaViewModel[] = [];
  
  constructor(
    private autenticacao$: AutenticacaoService,
    private alert: AlertMessage,
    private configuracao$: ConfiguracaoService,
  ) { }

  ngOnInit() {

    this.configuracao$.obterTodos().subscribe(
      retorno => {
        
        this.listaEmpresas = retorno.total > 0 ? retorno.result : [];

        this.empresas = this.listaEmpresas.filter(item => item.id == this.autenticacao$.acessoUsuarioValue?.idLoja)

        this.empresasSelecionadas.emit(
          this.listaEmpresas.filter(item => item.id == this.autenticacao$.acessoUsuarioValue?.idLoja)
        );
        
      },
        error => { 
          this.alert.Danger('Empresas', Uteis.ObterErroApi(error))
        }
      )

  }

  enviar(){ 
    this.empresasSelecionadas.emit(this.empresas);
  }

}
