import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CadastroViewModel } from '@modules/admin/view-models/cadastro/CadastroViewModel';
import { AlertMessage } from '@modules/alert.configuration.service';
import { ClienteDioptriaArmacaoService } from '@modules/manager/cadastro/cliente-fornecedor/cliente-dioptria-armacao/cliente-dioptria-armacao.service';
import { ClienteDioptriaService } from '@modules/manager/cadastro/cliente-fornecedor/cliente-dioptria/cliente-dioptria.service';
import { ClinicaMedicoService } from '@modules/manager/cadastro/clinica/clinica-medico/clinica-medico.service';
import { ClienteDioptriaViewModel } from '@modules/manager/view-models/cadastro/cliente-fornecedor/cliente-dioptria-view-model ';
import { ClienteDioptriaArmacaoPesquisaViewModel } from '@modules/manager/view-models/cadastro/cliente-fornecedor/pesquisa/cliente-dioptria-armacao-pesquisa-view-model ';
import { MedicoPesquisaViewModel } from '@modules/manager/view-models/cadastro/clinica/pesquisa/medico-pesquisa-view-model';
import { AlertMensagemPadrao } from '@modules/utilizarios/alertMensagemPadrao';
import { Uteis } from '@modules/utilizarios/uteis';
import { DisplayMessage, GenericValidator } from '@modules/validacao/generic-form-validation';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-dioptria-cadastro-modal',
  templateUrl: './dioptria-cadastro-modal.component.html',
  styles: [
  ]
})
export class DioptriaCadastroModalComponent implements OnInit {

  @Input() abrirFecharDioptriaModal: boolean = false;
  @Input() idCliente: number;
  @Input() id_receita: number;
  @Input() cliente_nome: string;

  @Output() closeModal = new EventEmitter();
  dialog: boolean = false;
  objReceita: CadastroViewModel<ClienteDioptriaViewModel> = new CadastroViewModel<ClienteDioptriaViewModel>();
  modalCadastroMedico: boolean = false;
  carregando: boolean = false;
  displayMessage: DisplayMessage = {};
  dp_perto:number = 0;
  dp_longo:number = 0;
  modalCadastroArmacao: boolean = false;
  alterar: boolean = false;
  listaMedicos: MedicoPesquisaViewModel[] = [];
  listaArmacao: ClienteDioptriaArmacaoPesquisaViewModel[] = [];
  cadastroForm: FormGroup;
  genericValidator!: GenericValidator;
  constructor(
    private fb: FormBuilder,
    private dioptria$: ClienteDioptriaService,
    private medico$: ClinicaMedicoService,
    private armacao$: ClienteDioptriaArmacaoService,
    private alert: AlertMessage,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.verificarId();
    this.criarFormulario();
    this.obterListaMedicos();
    this.obterListaArmacao();
    this.obterPorId();


  }

  onSubmit() {

    this.cadastroForm.markAllAsTouched();

    // this.displayMessage = this.genericValidator.processarMensagens(this.cadastroForm);

    if (!this.cadastroForm.valid) {

      this.mensagemCamposInvalidos(this.displayMessage);

      return
    }
    if (!this.cadastroForm.dirty || !this.cadastroForm.valid) {

      this.alert.Info(AlertMensagemPadrao.formularioNaoAlterado.titulo, AlertMensagemPadrao.formularioNaoAlterado.mensagem);

      return
    };

    this.carregando = true;

    if (this.alterar) this.atualizar();
    else this.adicionar();
  }

  private mensagensCadastro(): void {
    this.alert.Success('Sucesso', `Receita ${this.alterar ? 'atualizado' : 'cadastrado'} com Sucesso.`);
  }

  adicionar() {
    this.atualizarDadosObjeto();

    this.dioptria$.adicionar(this.objReceita).subscribe(
      retorno => {

        this.mensagensCadastro();

        this.closeModalDioptria();

        this.carregando = false;
      },
      error => { this.carregando = false; this.alert.Danger('Erro', Uteis.ObterErroApi(error)); }
    )
  }
  atualizar() {

    this.atualizarDadosObjeto();

    if (this.objReceita.obj.id && this.objReceita.obj.id > 0)

      this.dioptria$.atualizar(this.objReceita.obj.id, this.objReceita).subscribe(
        retorno => {

          this.mensagensCadastro();


          this.carregando = false;
          this.closeModalDioptria();
        }, error => {
          this.carregando = false;

          this.alert.Danger('Erro', Uteis.ObterErroApi(error))
        });
  }
  verificarId() {
    this.id_receita ? this.alterar = true : this.alterar = false;
  }
  atualizarDadosObjeto() {
    this.objReceita.obj = Object.assign({}, this.objReceita.obj, this.cadastroForm.value);

  }
  mensagemCamposInvalidos(mensagens: any) {
    let camposInvalidos = Uteis.ConverterObjetoToArray(mensagens);
    this.alert.Warning(AlertMensagemPadrao.formularioInvalido.titulo, AlertMensagemPadrao.formularioInvalido.mensagem + '<br><br>' + camposInvalidos.toString().replace(/,/g, ''));
  }


  criarFormulario() {
    this.cadastroForm = this.fb.group({
      id: [0],
      cliente_id: [this.idCliente, []],
      clinica_medico_id: [0, []],
      dioptria_tipo_armacao_id: [0, []],
      dt_ocorrencia: [Uteis.DateCadastroToString(new Date()), []],
      dt_venc_receita: [''],
      longe_olho_dir_esferico: [''],
      longe_olho_dir_cilindrico: [''],
      longe_olho_dir_eixo: [''],
      longe_olho_dir_dnp: [''],
      longe_olho_dir_altura: [''],
      longe_olho_esq_esferico: [''],
      longe_olho_esq_cilindrico: [''],
      longe_olho_esq_eixo: [''],
      longe_olho_esq_dnp: [''],
      longe_olho_esq_altura: [''],
      longe_olho_dir_centro_optico: [''],
      longe_olho_esq_centro_optico: [''],
      perto_olho_dir_esferico: [''],
      perto_olho_dir_cilindrico: [''],
      perto_olho_dir_eixo: [''],
      perto_olho_dir_dnp: [''],
      perto_olho_dir_altura: [''],
      perto_olho_dir_adicao: [''],
      perto_olho_esq_esferico: [''],
      perto_olho_esq_cilindrico: [''],
      perto_olho_esq_eixo: [''],
      perto_olho_esq_dnp: [''],
      perto_olho_esq_altura: [''],
      perto_olho_esq_adicao: [''],
      perto_olho_dir_centro_optico: [''],
      perto_olho_esq_centro_optico: [''],
      armacao_ponte: [''],
      armacao_aro: [''],
      armacao_diag_maior: [''],
      armacao_altura: [''],
      dp_longe: [this.dp_longo,[]],
      dp_perto: [ this.dp_perto, []],
      obs: ['']
    });

  }

  closeModalDioptria() {

    this.closeModal.emit(false);
  }

  showModalCadastroMedico() {
    this.modalCadastroMedico = true;
  }
  closeModalCadastroMedico() {
    this.modalCadastroMedico = false;
  }
  showModalCadastroArmacao() {
    this.modalCadastroArmacao = true;
  }
  closeModalCadastArmacao() {
    this.modalCadastroArmacao = false;
    this.obterListaArmacao();


  }

  obterListaMedicos() {

    this.medico$.obterTodos().subscribe(

      retorno => this.listaMedicos = retorno.total > 0 ? retorno.result : [],

      error => this.alert.Danger('Erro', Uteis.ObterErroApi(error))
    )
  }
  obterListaArmacao() {

    this.armacao$.obterTodos().subscribe(

      retorno => {
        this.listaArmacao = retorno.total > 0 ? retorno.result : []
      }),
      error => { this.alert.Danger('Erro', Uteis.ObterErroApi(error)) }

  }
  obterPorId() {
    if (this.alterar) {

      this.spinner.show();

      this.dioptria$.obterPorId(this.id_receita).subscribe(
        retorno => {

          this.objReceita.obj = retorno.total > 0 ? retorno.result[0] : new ClienteDioptriaViewModel();
          this.dp_longo = parseInt( this.objReceita.obj.longe_olho_dir_dnp) + parseInt( this.objReceita.obj.longe_olho_esq_dnp)

          this.cadastroForm.patchValue(this.objReceita.obj)

        this.somaDP()
        this.cilindroNegativo()
        this.adicaoOD()
        this.adicaoOE()
          this.spinner.hide();
        },
        error => {
          this.spinner.hide();

          this.alert.Danger('Erro', Uteis.ObterErroApi(error))
        });
    }
  }

  imprimir() {

   
 

    this.spinner.show();



    this.dioptria$.imprimirReceita(this.objReceita.obj).subscribe(
      
      retorno => {
        this.spinner.hide();
        var link = retorno;
        fetch(link).then(res => res.blob()).then(res => window.open(URL.createObjectURL(res), '_blank'));
        this.dialog = false;
      },
      err => {
        this.spinner.hide();
        this.alert.Danger('Erro na Impressão', Uteis.ObterErroApi(err));
      }
    )

  }
somaDP() {
this.dp_longo = parseInt(this.dioptria_longe_olho_dir_dnp.value) + parseInt(this.dioptria_longe_olho_esq_dnp.value)
this.dp_perto = parseInt(this.dioptria_perto_olho_dir_dnp.value) + parseInt(this.dioptria_perto_olho_esq_dnp.value)
this.dioptria_dp_longe.patchValue(this.dp_longo)
this.dioptria_dp_perto.patchValue(this.dp_perto)

}
cilindroNegativo() {
var dioptria_longe_olho_dir_cilindrico = parseInt(this.dioptria_longe_olho_dir_cilindrico.value) *-1;
var dioptria_longe_olho_esq_cilindrico = parseInt(this.dioptria_longe_olho_esq_cilindrico.value) *-1;
var dioptria_perto_olho_dir_cilindrico = parseInt(this.dioptria_perto_olho_dir_cilindrico.value) *-1;
var dioptria_perto_olho_esq_cilindrico = parseInt(this.dioptria_perto_olho_esq_cilindrico.value) *-1;

 this.dioptria_longe_olho_dir_cilindrico.patchValue(dioptria_longe_olho_dir_cilindrico.toString())
 this.dioptria_longe_olho_esq_cilindrico.patchValue(dioptria_longe_olho_esq_cilindrico.toString())
 this.dioptria_perto_olho_dir_cilindrico.patchValue(dioptria_perto_olho_dir_cilindrico.toString())
 this.dioptria_perto_olho_esq_cilindrico.patchValue(dioptria_perto_olho_esq_cilindrico.toString())


}
adicaoOD() {
var somaOD = parseInt(this.dioptria_perto_olho_dir_adicao.value)+ parseInt(this.dioptria_longe_olho_dir_esferico.value);


 this.dioptria_perto_olho_dir_esferico.patchValue(somaOD.toString())


}
adicaoOE() {
var somaOE = parseInt(this.dioptria_perto_olho_esq_adicao.value)+ parseInt(this.dioptria_longe_olho_esq_esferico.value);


 this.dioptria_perto_olho_esq_esferico.patchValue(somaOE.toString())


}
  get dioptria_longe_olho_dir_cilindrico() { return this.cadastroForm.get('longe_olho_dir_cilindrico') };
  get dioptria_longe_olho_esq_cilindrico() { return this.cadastroForm.get('longe_olho_esq_cilindrico') };
  get dioptria_perto_olho_dir_cilindrico() { return this.cadastroForm.get('perto_olho_dir_cilindrico') };
  get dioptria_perto_olho_esq_cilindrico() { return this.cadastroForm.get('perto_olho_esq_cilindrico') };
  get dioptria_longe_olho_dir_esferico() { return this.cadastroForm.get('longe_olho_dir_esferico') };
  get dioptria_longe_olho_esq_esferico() { return this.cadastroForm.get('longe_olho_esq_esferico') };
  get dioptria_longe_olho_dir_dnp() { return this.cadastroForm.get('longe_olho_dir_dnp') };
  get dioptria_longe_olho_esq_dnp() { return this.cadastroForm.get('longe_olho_esq_dnp') };
  get dioptria_perto_olho_dir_dnp() { return this.cadastroForm.get('perto_olho_dir_dnp') };
  get dioptria_perto_olho_esq_dnp() { return this.cadastroForm.get('perto_olho_esq_dnp') };
  get dioptria_dp_longe() { return this.cadastroForm.get('dp_longe') };
  get dioptria_dp_perto() { return this.cadastroForm.get('dp_perto') };
  get dioptria_perto_olho_dir_esferico() { return this.cadastroForm.get('perto_olho_dir_esferico') };
  get dioptria_perto_olho_esq_esferico() { return this.cadastroForm.get('perto_olho_esq_esferico') };
  get dioptria_perto_olho_dir_adicao() { return this.cadastroForm.get('perto_olho_dir_adicao') };
  get dioptria_perto_olho_esq_adicao() { return this.cadastroForm.get('perto_olho_esq_adicao') };

}
