import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { DatePipe, DecimalPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AlertMessage } from '@modules/alert.configuration.service';
import { CondicaoPagamentoViewModel } from '@modules/manager/view-models/dashboard/vendas/condicao-pagamento-view-model';
import { DesempenhoCaixaViewModel } from '@modules/manager/view-models/dashboard/vendas/desempenho-caixa-view-model';
import { IndicadoresRentabilidadeVendaViewModel } from '@modules/manager/view-models/dashboard/vendas/indicadores-rentabilidade-venda-view-model';
import { TiposVendaViewModel } from '@modules/manager/view-models/dashboard/vendas/tipos-venda-view-model';
import { TopFabricanteViewModel } from '@modules/manager/view-models/dashboard/vendas/top-fabricante-view-model';
import { TopGrupoViewModel } from '@modules/manager/view-models/dashboard/vendas/top-grupo-view-model';
import { TopVendedorViewModel } from '@modules/manager/view-models/dashboard/vendas/top-vendedor-view-model';
import { VendasUltimos12MesesViewModel } from '@modules/manager/view-models/dashboard/vendas/vendas-ultimos12-meses-view-model';
import { Uteis } from '@modules/utilizarios/uteis';
import { DashboardVendaService } from '../services/dashboard-venda.service';
import { UsuarioDepartamentoService } from '@modules/manager/cadastro/usuario-manager/usuario-departamento/usuario-departamento.service';
import { ConfiguracaoVendaService } from '@modules/manager/sistema/configuracao-venda/configuracao-venda-service';
import { UsuarioDepartamentoPesquisaViewModel } from '@modules/manager/view-models/cadastro/usuario-manager/pesquisa/usuario-departamento-pesquisa-view-model';
import { UsuarioManagerService } from '@modules/manager/cadastro/usuario-manager/usuario-manager.service';
import { ConfiguracaoVendaOrcamentoViewModel } from '@modules/manager/view-models/sistema/configuracao-venda-orcamento-view-model';

@Component({
	selector: 'app-dashboard-vendas',
	templateUrl: './dashboard-vendas.component.html',
	styleUrls: ['./dashboard-vendas.component.css']
})
export class DashboardVendasComponent implements OnInit {
	isXLarge!: boolean;
	isLarge!: boolean;
	isMedium!: boolean;
	isSmall!: boolean;
	isXSmall!: boolean;

	matgroup = false;
	dataInicial: string = '';
	dataFinal: string = '';
	dataInicialFormatada: string = '';
	dataFinalFormatada: string = '';
	lst_departamento!: number;
	configuracaoVenda: ConfiguracaoVendaOrcamentoViewModel = new ConfiguracaoVendaOrcamentoViewModel();



	//INDICADORES DE RENTABILIDADE - INICIO
	carregandoIndicadoresRentabilidade: boolean = false;
	indicadoresRentabilidade: IndicadoresRentabilidadeVendaViewModel = new IndicadoresRentabilidadeVendaViewModel();
	viewGraficoIndicadoresRentabilidade!: number[];
	graficoIndicadoresRentabilidade: any[] = [];
	colorSchemeIndicadoresRentabilidade = { domain: ['#4869a5', '#f39e39'] };
	//INDICADORES DE RENTABILIDADE - FIM

	//TIPO DE VENDA - INICIO
	carregandoTiposVenda: boolean = false;
	tiposVenda: TiposVendaViewModel = new TiposVendaViewModel();
	graficoTiposVenda: any[] = [];
	//TIPO DE VENDA - FIM

	//TOP GRUPO - INICIO
	carregandoTopGrupos: boolean = false;
	topGrupos: TopGrupoViewModel[] = [];
	viewGraficoTopGrupos!: number[];
	graficoTopGrupos: any[] = [];
	colorSchemeTopGrupos = {
		domain: ['#e57373', '#f06292', '#ba68c8', '#9575cd', '#7986cb', '#64b5f6', '#4fc3f7', '#4dd0e1']
	};
	//TOP GRUPO - FIM

	//TOP FABRICANTE - INICIO
	carregandoTopFabricantes: boolean = false;
	topFabricantes: TopFabricanteViewModel[] = [];
	viewGraficoTopFabricantes!: number[];
	graficoTopFabricantes: any[] = [];
	colorSchemeTopFabricantes = {
		domain: ['#e57373', '#f06292', '#ba68c8', '#9575cd', '#7986cb', '#64b5f6', '#4fc3f7', '#4dd0e1']
	};
	//TOP FABRICANTE - FIM

	//DESEMPENHO DOS CAIXAS - INICIO
	carregandoDesempenhoCaixas: boolean = false;
	desempenhoCaixas: DesempenhoCaixaViewModel[] = [];
	viewGraficoDesempenhoCaixas!: number[];
	graficoDesempenhoCaixas: any[] = [];
	colorSchemeDesempenhoCaixas = {
		domain: ['#f44336', '#e91e63', '#9c27b0', '#673ab7', '#3f51b5', '#2196f3', '#03a9f4', '#00bcd4']
	};
	//DESEMPENHO DOS CAIXAS - FIM

	//CONDIÇÕES DE PAGAMENTO - INICIO
	carregandoCondicaoPagamento: boolean = false;
	condicaoPagamento: CondicaoPagamentoViewModel[] = [];
	viewGraficoCondicaoPagamento!: number[];
	graficoCondicaoPagamento: any[] = [];
	colorSchemeCondicaoPagamento = {
		domain: ['#d32f2f', '#c2185b', '#7b1fa2', '#512da8', '#303f9f', '#1976d2', '#0288d1', '#0097a7']
	};
	//CONDIÇÕES DE PAGAMENTO - FIM

	//TOP VENDEDORES - INICIO
	carregandoTopVendedores: boolean = false;
	topVendedores: TopVendedorViewModel[] = [];
	viewGraficoTopVendedores!: number[];
	graficoTopVendedores: any[] = [];
	colorSchemeTopVendedores = {
		domain: ['#b71c1c', '#880e4f', '#4a148c', '#311b92', '#1a237e', '#0d47a1', '#01579b', '#006064']
	};
	//TOP VENDEDORES - FIM

	//VENDAS DOS ÚLTIMOS 12 MESES - INICIO
	carregandoVendasUltimos12Meses: boolean = false;
	vendasUltimos12Meses: VendasUltimos12MesesViewModel[] = [];
	viewGraficoVendasUltimos12Meses!: number[];
	graficoVendasUltimos12Meses: any[] = [];
	colorSchemeVendasUltimos12Meses = {
		domain: ['#ff5252', '#ff4081', '#e040fb', '#7c4dff', '#536dfe', '#448aff', '#40c4ff', '#18ffff']
	};
	//VENDAS DOS ÚLTIMOS 12 MESES - FIM

	constructor(private dashboardVendasService: DashboardVendaService
		, private datePipe: DatePipe
		, private decimalPipe: DecimalPipe
		, private breakpointObserver: BreakpointObserver
		, private usuarioDepartamentoService: UsuarioDepartamentoService
		, private configuracaoVendaService: ConfiguracaoVendaService
		, private usuarioManagerService: UsuarioManagerService
		, private alert: AlertMessage) { }

	ngOnInit() {
		this.verificaUsuario().then(resultado => {
			if (resultado) {
				this.matgroup = true;
				this.calcularVendas('dia');
				this.breakpointObserver.observe(Breakpoints.XLarge).subscribe(retorno => this.isXLarge = retorno.matches);
				this.breakpointObserver.observe(Breakpoints.Large).subscribe(retorno => this.isLarge = retorno.matches);
				this.breakpointObserver.observe(Breakpoints.Medium).subscribe(retorno => this.isMedium = retorno.matches);
				this.breakpointObserver.observe(Breakpoints.Small).subscribe(retorno => this.isSmall = retorno.matches);
				this.breakpointObserver.observe(Breakpoints.XSmall).subscribe(retorno => this.isXSmall = retorno.matches);
				this.pesquisar();
			} else {
			
			}
		  });
		  
		
			

		
	}

	pesquisar() {

		this.pesquisaInidicadoresResponsabilidade();
		this.pesquisaTiposVenda();
		this.pesquisaTopGrupos();
		this.pesquisaTopFabricantes();
		this.pesquisaDesempenhoCaixas();
		this.pesquisaCondicaoPagamento();
		this.pesquisaTopVendedores();
		this.pesquisaVendasUltimos12Meses();
	}

	// Método para calcular as vendas com base no período desejado
	calcularVendas(periodo: string) {
		// Variáveis para armazenar as datas inicial e final
		let dataInicial;
		let dataFinal;

		// Determinar as datas inicial e final com base no período selecionado
		switch (periodo) {
			case 'dia':
				dataInicial = new Date(); // Data atual
				dataFinal = new Date();
				break;
			case 'semana':
				const today = new Date();
				dataInicial = getStartOfWeek(today); // Função para obter o início da semana
				dataFinal = getEndOfWeek(today);     // Função para obter o fim da semana
				break;
			case 'mes':
				const currentDate = new Date();
				dataInicial = getStartOfMonth(currentDate); // Função para obter o início do mês
				dataFinal = getEndOfMonth(currentDate);     // Função para obter o fim do mês
				break;
			case 'ano':
				const currentYear = new Date();
				dataInicial = getStartOfYear(currentYear); // Função para obter o início do ano
				dataFinal = getEndOfYear(currentYear);     // Função para obter o fim do ano
				break;
			default:
				// Período inválido
			
				return;
		}
		function getStartOfWeek(date: Date): Date {
			const startOfWeek = new Date(date);
			startOfWeek.setDate(date.getDate() - date.getDay()); // Define o início da semana como o domingo

			return startOfWeek;
		}

		// Função para obter o fim da semana
		function getEndOfWeek(date: Date): Date {
			const endOfWeek = new Date(date);
			endOfWeek.setDate(date.getDate() + (6 - date.getDay())); // Define o fim da semana como o sábado

			return endOfWeek;
		}

		// Função para obter o início do mês
		function getStartOfMonth(date: Date): Date {
			return new Date(date.getFullYear(), date.getMonth(), 1);
		}

		// Função para obter o fim do mês
		function getEndOfMonth(date: Date): Date {
			return new Date(date.getFullYear(), date.getMonth() + 1, 0);
		}

		// Função para obter o início do ano
		function getStartOfYear(date: Date): Date {
			return new Date(date.getFullYear(), 0, 1);
		}

		// Função para obter o fim do ano
		function getEndOfYear(date: Date): Date {
			return new Date(date.getFullYear(), 11, 31);
		}

		this.dataInicialFormatada = this.datePipe.transform(dataInicial, 'yyyy-MM-dd');
		this.dataFinalFormatada = this.datePipe.transform(dataFinal, 'yyyy-MM-dd');
		this.pesquisar();
	}



	verificaUsuario() {
		const usuarioString = localStorage.getItem('acessoUsuario');
		const usuarioObjeto = JSON.parse(usuarioString);	

		let pesquisa = new UsuarioDepartamentoPesquisaViewModel();

		return new Promise<boolean>((resolve, reject) => {
			this.usuarioManagerService.obterPorIdAdm(usuarioObjeto.idUsuario).subscribe(retorno => {
				pesquisa.usuario_id = retorno.result[0].id;

				this.usuarioDepartamentoService.obterTodos(pesquisa).subscribe(retorno => {
					this.lst_departamento = Number(retorno.result.filter(item => item.lst_departamento ===3).map(item => item.lst_departamento).toString());
					if (this.lst_departamento === 3) {
						
						this.configuracaoVendaService.obterConfiguracao(usuarioObjeto.idLoja).subscribe(retorno => {
							this.configuracaoVenda.chk_mostrar_grafico = retorno.result[0].chk_mostrar_grafico;				
							if (this.configuracaoVenda.chk_mostrar_grafico === 1) {
								resolve(true);
							} else {
								resolve(false);
							}
						});
					} else {
						resolve(false);
					}
				});
			});
		});
	}


	pesquisaInidicadoresResponsabilidade() {
		this.carregandoIndicadoresRentabilidade = true;
		this.dashboardVendasService
			.indicadoresRentabilidade(this.dataInicialFormatada, this.dataFinalFormatada)
			.subscribe(retorno => {
				this.graficoIndicadoresRentabilidade = [];
				this.indicadoresRentabilidade = new IndicadoresRentabilidadeVendaViewModel();
				this.carregandoIndicadoresRentabilidade = false;
				if (retorno.total <= 0 || (retorno.result[0]?.quantidade_vendas || 0) <= 0) return;

				this.indicadoresRentabilidade = retorno.result[0];				
				this.graficoIndicadoresRentabilidade.push({ name: 'Lucro Bruto', value: this.indicadoresRentabilidade.valor_total_liquido - this.indicadoresRentabilidade.custo_medio });
				this.graficoIndicadoresRentabilidade.push({ name: 'CMV', value: this.indicadoresRentabilidade.custo_medio });
			}, error => {
				this.alert.Danger('Erro', Uteis.ObterErroApi(error));
				this.carregandoIndicadoresRentabilidade = false;
			});
	}

	pesquisaTiposVenda() {
		this.carregandoTiposVenda = true;
		this.dashboardVendasService
			.tiposVenda(this.dataInicialFormatada, this.dataFinalFormatada)
			.subscribe(retorno => {
				this.graficoTiposVenda = [];
				this.tiposVenda = new TiposVendaViewModel();
				this.carregandoTiposVenda = false;
				if (retorno.total <= 0 || ((retorno.result[0]?.valor_total_vendas_a_vista || 0) <= 0 && (retorno.result[0]?.valor_total_vendas_a_vista || 0) <= 0)) return;

				this.tiposVenda = retorno.result[0];
				this.graficoTiposVenda.push({ name: 'À vista', value: this.tiposVenda.valor_total_vendas_a_vista });
				this.graficoTiposVenda.push({ name: 'À prazo', value: this.tiposVenda.valor_total_vendas_a_prazo });
			}, error => {
				this.alert.Danger('Erro', Uteis.ObterErroApi(error));
				this.carregandoTiposVenda = false;
			});
	}

	resizeGraficoTopGrupos() {
		const divisor = (this.isXLarge ? 4 : this.isLarge && !this.isXLarge ? 3 : this.isMedium ? 2 : this.isSmall ? 0.72 : 0.75) + 0.4;
		const altura = this.isXLarge ? 232 : this.isLarge && !this.isXLarge ? 255 : this.isMedium ? 232 : this.isSmall ? 250 : 232;
		this.viewGraficoTopGrupos = [innerWidth / divisor, altura];
	}
	pesquisaTopGrupos() {
		this.carregandoTopGrupos = true;
		this.dashboardVendasService
			.topGrupos(this.dataInicialFormatada, this.dataFinalFormatada)
			.subscribe(retorno => {
				this.topGrupos = retorno.total > 0 ? retorno.result : [];
				this.carregandoTopGrupos = false;
				this.graficoTopGrupos = this.topGrupos.map((grupo) => { return { name: grupo.grupo_nome, value: grupo.valor_total_liquido_mais_impostos || 0 }; });
				this.resizeGraficoTopGrupos();
			}, error => {
				this.alert.Danger('Erro', Uteis.ObterErroApi(error));
				this.carregandoTopGrupos = false;
			});
	}

	resizeGraficoTopFabricantes() {
		const divisor = (this.isXLarge ? 4 : this.isLarge && !this.isXLarge ? 3 : this.isMedium ? 2 : this.isSmall ? 0.72 : 0.82) + 0.4;
		const altura = this.isXLarge ? 232 : this.isLarge && !this.isXLarge ? 255 : this.isMedium ? 232 : this.isSmall ? 250 : 232;
		this.viewGraficoTopFabricantes = [innerWidth / divisor, altura];
	}
	pesquisaTopFabricantes() {
		this.carregandoTopFabricantes = true;
		this.dashboardVendasService
			.topFabricantes(this.dataInicialFormatada, this.dataFinalFormatada)
			.subscribe(retorno => {
				this.topFabricantes = retorno.total > 0 ? retorno.result : [];
				this.carregandoTopFabricantes = false;
				this.graficoTopFabricantes = this.topFabricantes.map((fabricante) => { return { name: fabricante.fabricante_nome, value: fabricante.valor_total_liquido_mais_impostos || 0 }; });
				this.resizeGraficoTopFabricantes();
			}, error => {
				this.alert.Danger('Erro', Uteis.ObterErroApi(error));
				this.carregandoTopFabricantes = false;
			});
	}

	resizeGraficoDesempenhoCaixas() {
		const divisor = (this.isXLarge ? 4 : this.isLarge && !this.isXLarge ? 3 : this.isMedium ? 2 : this.isSmall ? 0.72 : 0.82) + 0.4;
		const altura = 232;
		this.viewGraficoDesempenhoCaixas = [innerWidth / divisor, altura];
	}
	pesquisaDesempenhoCaixas() {
		this.carregandoDesempenhoCaixas = true;
		this.dashboardVendasService
			.desempenhoCaixa(this.dataInicialFormatada, this.dataFinalFormatada)
			.subscribe(retorno => {
				this.desempenhoCaixas = retorno.total > 0 ? retorno.result : [];
				this.desempenhoCaixas = this.desempenhoCaixas.filter(retorno => (typeof (retorno.caixa_nome) !== 'undefined' && retorno.caixa_nome !== null && retorno.caixa_nome !== ''))
				this.carregandoDesempenhoCaixas = false;
				this.graficoDesempenhoCaixas = this.desempenhoCaixas.map((caixa) => { return { name: caixa.caixa_nome, value: caixa.valor_total_liquido_mais_impostos || 0 }; });
				this.resizeGraficoDesempenhoCaixas();
			}, error => {
				this.alert.Danger('Erro', Uteis.ObterErroApi(error));
				this.carregandoDesempenhoCaixas = false;
			});
	}

	resizeGraficoCondicaoPagamento() {
		const divisor = (this.isXLarge ? 4 : this.isLarge && !this.isXLarge ? 3 : this.isMedium ? 2 : this.isSmall ? 0.72 : 0.82) + 0.4;
		const altura = this.isXLarge ? 232 : this.isLarge && !this.isXLarge ? 300 : this.isMedium ? 232 : this.isSmall ? 250 : 232;
		this.viewGraficoCondicaoPagamento = [innerWidth / divisor, altura];
	}
	pesquisaCondicaoPagamento() {
		this.carregandoCondicaoPagamento = true;
		this.dashboardVendasService
			.condicaoPagamento(this.dataInicialFormatada, this.dataFinalFormatada)
			.subscribe(retorno => {
				this.condicaoPagamento = retorno.total > 0 ? retorno.result : [];
				this.carregandoCondicaoPagamento = false;
				this.resizeGraficoCondicaoPagamento();
				this.graficoCondicaoPagamento = this.condicaoPagamento.map((condicao) => { return { name: condicao.condicao_pagamento_nome, value: condicao.valor_total_liquido_mais_impostos || 0 }; });
			}, error => {
				this.alert.Danger('Erro', Uteis.ObterErroApi(error));
				this.carregandoCondicaoPagamento = false;
			});
	}

	resizeGraficoTopVendedores() {
		const divisor = (this.isXLarge ? 4 : this.isLarge && !this.isXLarge ? 3 : this.isMedium ? 2 : this.isSmall ? 0.72 : 0.82) + 0.4;
		const altura = this.isXLarge ? 236.5 : this.isLarge && !this.isXLarge ? 232 : this.isMedium ? 400 : this.isSmall ? 250 : 232;
		this.viewGraficoTopVendedores = [innerWidth / divisor, altura];
	}
	pesquisaTopVendedores() {
		this.carregandoTopVendedores = true;
		this.dashboardVendasService
			.topVendedores(this.dataInicialFormatada, this.dataFinalFormatada)
			.subscribe(retorno => {
				this.topVendedores = retorno.total > 0 ? retorno.result : [];
				this.carregandoTopVendedores = false;
				this.graficoTopVendedores = this.topVendedores.map((vendedor) => { return { name: vendedor.vendedor_nome, value: vendedor.valor_total_liquido_mais_impostos || 0 }; });
				this.resizeGraficoTopVendedores();
			}, error => {
				this.alert.Danger('Erro', Uteis.ObterErroApi(error));
				this.carregandoTopVendedores = false;
			});
	}

	resizeGraficoVendasUltimos12Meses() {
		const divisor = (this.isXLarge ? 1.8 : this.isLarge && !this.isXLarge ? 1.31 : this.isMedium ? 2.05 : this.isSmall ? 0.77 : 0.87) + 0.4;
		const altura = this.isXLarge ? 400 : this.isLarge && !this.isXLarge ? 300 : this.isMedium ? 400 : this.isSmall ? 400 : 400;
		this.viewGraficoVendasUltimos12Meses = [innerWidth / divisor, altura];
	}
	pesquisaVendasUltimos12Meses() {
		this.carregandoVendasUltimos12Meses = true;
		this.dashboardVendasService
			.vendasUltimos12Meses()
			.subscribe(retorno => {
				this.vendasUltimos12Meses = [];
				this.graficoVendasUltimos12Meses = [];
				this.carregandoVendasUltimos12Meses = false;
				if (retorno.total <= 0 || (retorno.result.length === 1 && (retorno.result[0].valor_total_liquido_mais_impostos || 0) === 0)) return;

				this.vendasUltimos12Meses = retorno.result;
				this.graficoVendasUltimos12Meses.push({
					name: 'Total',
					series: this.vendasUltimos12Meses.map((vendas) => {
						return {
							name: `${Uteis.NomeDosMesesAno[vendas.mes - 1]}/${vendas.ano}`,
							value: vendas.valor_total_liquido_mais_impostos || 0,
							extra: { code: 'pt' }
						};
					})
				});
				this.resizeGraficoVendasUltimos12Meses();
			}, error => {
				this.alert.Danger('Erro', Uteis.ObterErroApi(error));
				this.carregandoVendasUltimos12Meses = false;
			});
	}

	formatacaoValor(valor: number) {
		return ((valor || 0) === null ? 0 : (valor || 0)).toLocaleString('pt');
	}
}
