import { ContaPesquisaViewModel } from '@modules/manager/view-models/cadastro/financeiro/pesquisa/conta-pesquisa-view-model';
import { TipoPagamentoPesquisaViewModel } from '@modules/manager/view-models/cadastro/financeiro/pesquisa/tipo-pagamento-pesquisa-view-model';
import { PaginacaoViewModel } from '@modules/manager/view-models/PaginacaoViewModel';
import { ConfiguracaoPesquisaViewModel } from '@modules/manager/view-models/sistema/pesquisa/configuracao-pesquisa-view-model';
import { ContasReceberPagarViewModel } from '../contas-receber-pagar-view-model';

export class ContasReceberPagarPesquisaViewModel extends ContasReceberPagarViewModel implements PaginacaoViewModel {
    pagina: number = 1;
    quantidadeRegistrosPagina: number = 20;
    totalRegistros: number = 0;
    totalPaginas: number = 0;
    desativados: boolean = false;
    start?: number;
    order?: string;
    cliente_nome?: string;
    cliente_cpf_cnpj?: string;
    usuario_lancou_nome?: string;
    conta_descricao?: string;
    origem_registros?: string;
    boleto_status?: number = 0;
    usuario_lancou?: string;
    usuario_cancelou?: string;
    usuario_quitou?: string;
    pgto_tipo_descricao?: string;
    atendente_nome?: string;
    produto_tabela_preco_descricao?: string;
    nf_numero?: number;
    cartao_bandeira?: string;
    numero_operacao?: string;
    pgto_obs?: string;
    cartao_taxa?: number;
    vlr_liquido?: number;
    tipo_vencimento: 'T' | 'A' | 'V' | 'H' = 'T';
    vlr_str: string = '0,00';
    grupo_cliente_id: number = 0;
    dt_emissao_inicio?: string;
    dt_emissao_fim?: string;
    dt_vencimento_inicio?: string;
    dt_vencimento_fim?: string;
    dt_pagamento_inicio = '';
    dt_pagamento_fim?: string;
    dt_cancelamento_inicio?: string;
    dt_cancelamento_fim?: string;
    dt_lancamento_inicio?: string;
    dt_lancamento_fim?: string;
    remessa_numero?: number;
    avalista_nome?: string;
    vendedor_nome?: string;
    contas_mov_selecionada: ContaPesquisaViewModel[] = [];
    sinal_vlr = '=';
    cliente_grupo_id?: number;
    cliente_uf?: number;
    cliente_uf_codigo?: number;
    cliente_cidade?: string;
    cliente_cidade_id?: number;
    produto_tabela_preco_id?: number;
    empresas_selecionadas: ConfiguracaoPesquisaViewModel[] = [];
    tipo_pagamentos_selecionados: TipoPagamentoPesquisaViewModel[] = [];
    cliente_vlr_limite_credito?: number;
    media_atraso_periodo?: number;
    percentual?: number;
    percentualAcumulado?: number;
    cartao_tipo_descricao?: string;
    empresa_nome?: string;
    cliente_fone?: string;
    cliente_celular?: string;
    dias_atrazo?: number;
    centro_custo_descricao?: string;
    ordenar_por?: string;
    data_calcular_juros?: string;

    vlr_total?: string;
    total_s_desc?: number;
    vlr_taxa?: number;
    total_c_desc?: number;
    checked?: boolean;
    total_c_juros_multa?: number;

    
}