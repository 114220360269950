import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {AutenticacaoService} from "@modules/autenticacao/services/autenticacao.service";
import {PesquisaGeralViewModel} from "@modules/admin/view-models/pesquisa/PesquisaGeralViewModel";
import {Observable} from "rxjs";
import {RetornoApiViewModel} from "@manager/view-models/RetornoApiViewModel";
import {EcommerceCategoriaPesquisaViewModel} from "@manager/view-models/cadastro/ecommerce/pesquisa/ecommerce-categoria-pesquisa-view-model";
import {URLBASE, URLIMPRESSAO} from "@modules/configuration.service";
import {EcommerceCategoriaViewModel} from "@manager/view-models/cadastro/ecommerce/ecommerce-categoria-view-model";
import {CadastroViewModel} from "@modules/admin/view-models/cadastro/CadastroViewModel";
import {EcommerceDepartamentoPesquisaViewModel} from "@manager/view-models/cadastro/ecommerce/pesquisa/ecommerce-departamento-pesquisa-view-model";
import {EcommerceDepartamentoViewModel} from "@manager/view-models/cadastro/ecommerce/ecommerce-departamento-view-model";
import {CadastroProdutoViewModel} from "@manager/view-models/cadastro/estoque/cadastro-produto-view-model";
import {ProdutoViewModel} from "@manager/view-models/cadastro/estoque/produto-view-model";
import {ProdutoEmpresaViewModel} from "@manager/view-models/cadastro/estoque/produto-empresa-view-model";
import {CadastroCategoriaViewModel} from "@manager/view-models/cadastro/ecommerce/cadastro-categoria-view-model";
import {id} from "@swimlane/ngx-charts";

@Injectable({
    providedIn: 'root'
})
export class CategoriaService {

    idEmpresaMaxdata = this.autenticacaoService.acessoUsuarioValue?.idEmpresaMaxdata;

    urlGet = `${URLBASE}/${this.idEmpresaMaxdata}/ecommerce_categoria_service/get`;

    urlAddUpdate = `${URLBASE}/${this.idEmpresaMaxdata}/ecommerce_categoria_service/addupdate`;
    updateBlob = `${URLBASE}/${this.idEmpresaMaxdata}/ecommerce_categoria_service/UpdateBlob`;
    getBlob = `${URLBASE}/${this.idEmpresaMaxdata}/ecommerce_categoria_service/getBlob`;

    constructor(
        private http: HttpClient,
        private autenticacaoService: AutenticacaoService
    ) {
    }

    obterTodos(pesquisa: EcommerceCategoriaPesquisaViewModel) : Observable<RetornoApiViewModel<EcommerceCategoriaPesquisaViewModel[]>> {
        let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel();

        pesquisaGeral.select = ` ecommerce_categoria.*`;
        pesquisaGeral.calcRowCount = 1;
        pesquisaGeral.limit = pesquisa?.quantidadeRegistrosPagina || 100;
        pesquisaGeral.where = ` and ecommerce_categoria.ativo = ${pesquisa?.desativados ? 0 : 1}`;
        pesquisaGeral.order = ` ecommerce_categoria.id`;
        pesquisaGeral.start = pesquisa?.start || 0;

        if (pesquisa?.id) pesquisaGeral.where += ' and ecommerce_categoria.id = ' + pesquisa?.id;
        if (pesquisa?.descricao) pesquisaGeral.where += ` and lower(ecommerce_categoria.descricao) like lower('%` + pesquisa?.descricao + `%')`;

        return this.http.post<RetornoApiViewModel<EcommerceCategoriaPesquisaViewModel[]>>(this.urlGet, pesquisaGeral);
    }

    obterPorDepartamento(idDep: number, pesquisa: EcommerceCategoriaPesquisaViewModel) : Observable<RetornoApiViewModel<EcommerceCategoriaPesquisaViewModel[]>> {
        let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel();

        pesquisaGeral.select = ` ecommerce_categoria.*`;
        pesquisaGeral.calcRowCount = 1;
        pesquisaGeral.limit = pesquisa?.quantidadeRegistrosPagina || 100;
        pesquisaGeral.where = ` and ecommerce_categoria.ativo = ${pesquisa?.desativados ? 0 : 1} and ecommerce_categoria.ecommerce_departamento_id = ${idDep}`;
        pesquisaGeral.order = ` ecommerce_categoria.id`;
        pesquisaGeral.start = pesquisa?.start || 0;

        if (pesquisa?.id) pesquisaGeral.where += ' and ecommerce_categoria.id = ' + pesquisa?.id;
        if (pesquisa?.descricao) pesquisaGeral.where += ` and lower(ecommerce_categoria.descricao) like lower('%` + pesquisa?.descricao + `%')`;

        return this.http.post<RetornoApiViewModel<EcommerceCategoriaPesquisaViewModel[]>>(this.urlGet, pesquisaGeral);
    }

    obterPorId(id: number): Observable<RetornoApiViewModel<EcommerceCategoriaViewModel[]>>{

        let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel();

        pesquisaGeral.select = ` ecommerce_categoria.*`;

        pesquisaGeral.where = ` and ecommerce_categoria.id = ${id}`;

        return this.http.post<RetornoApiViewModel<EcommerceCategoriaViewModel[]>>(`${this.urlGet}`,pesquisaGeral);
    }

    adicionar(ecommerceCategoriaViewModel: CadastroViewModel<EcommerceCategoriaViewModel>) : Observable<any>{

        return this.http.post<any[]>(this.urlAddUpdate, ecommerceCategoriaViewModel);

    }

    atualizar(id: number, ecommerceCategoriaViewModel: CadastroViewModel<EcommerceCategoriaViewModel>) : Observable<any>{

        return this.http.put<any[]>(`${this.urlAddUpdate}/${id}`, ecommerceCategoriaViewModel);

    }

    imprimir() { }

}
