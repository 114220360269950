<p-dialog [(visible)]="abrirFecharPesquisaServicoModal" [modal]="true" [closable]="false" [style]="{width: '100vw'}"
    [maximizable]="true" [baseZIndex]="10000" [draggable]="false" [resizable]="false">
    <ngx-spinner name="modalAtualiza" bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-clip-rotate"
        [fullScreen]="false"></ngx-spinner>
    <p-header>Pesquisa de Serviço</p-header>
    <div class="row float-right">
        <button class="btn btn-sm btn-outline-info" (click)="pesquisar()" type="button"><i
                class="material-icons">search</i>Pesquisar</button>
    </div>
    <div class="row pl-0">
        <div class="col-xs-12 col-sm-4 col-md-3 col-lg-2 col-xl-1">
            <div class="form-group">
                <label for="id" class="col-form-label-sm">Código</label>
                <input type="number" id="id" name="id" class="form-control form-control-sm noscroll"
                    placeholder="Código" [(ngModel)]="pesquisa.id" (keydown.enter)="pesquisar()" autocomplete="off">
            </div>
        </div>
        <div class="col-xs-12 col-sm-8 col-md-6 col-lg-5 col-xl-4">
            <div class="form-group">
                <label for="vlr_venda" class="col-form-label-sm">Descrição</label>
                <input type="text" id="descricao_servico" name="descricao_servico" class="form-control form-control-sm"
                    placeholder="Pesquise pela Descricao" [(ngModel)]="pesquisa.descricao"
                    (keydown.enter)="pesquisar()" autocomplete="off">
            </div>
        </div>
        <div class="col-xs-12 col-sm-8 col-md-6 col-lg-3 col-xl-2">
            <label class="col-form-label-sm custom-select-label">Grupo</label>
            <select id="produto_grupo_id" name="produto_grupo_id" class="form-control form-control-sm"
                [(ngModel)]="pesquisa.produto_grupo_id">
                <option [ngValue]="0">Todos</option>
                <option *ngFor="let item of listaGrupo" [ngValue]="item.id">{{ item.descricao }}</option>
            </select>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div class="my-custom-scrollbar table-wrapper-scroll-y">
                <table class="table table-hover table-borderless table-striped table-sm " width="100%" id="table">
                    <thead class="thead">
                        <tr>
                            <th>Código</th>
                            <th>Descrição</th>
                            <th>Grupo</th>
                            <th class="text-right">Vlr Venda</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="cursor" *ngFor="let item of listaServicos; let i = index;" 
                        (dblclick)="produtoSlecionado(item)"
                        (click)="clickRowIndex(i)"
                        [ngClass]="{'selected-line': (selectedRowIndex || 0) === i }">
                            <td class="text-nowrap pr-5">{{item.id}}</td>
                            <td class="text-nowrap w-descricao pr-2 w-descricao text-truncate">
                                {{item.descricao}}</td>
                            <td class="text-nowrap w-descricao pr-2">{{item.grupo_descricao}}</td>
                            <td class="text-nowrap text-right pr-2">{{item.vlr_venda | currency: 'BRL':'R$':'1.2-2'}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <button class="btn btn-sm btn-outline-secondary" (click)="closeModalProduto()">Cancelar</button>
    </ng-template>
</p-dialog>