<div class="card-footer">
  <div class="row d-footer">
    <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6">
      <span>Mostrando de </span>
      <strong>{{paginaParcial}}</strong>
      a
      <strong>{{qtdPorPagina}}</strong>
      <span> do Total de Registo(s) : </span>
      <strong>{{totalItems}}</strong>
    </div>
    <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6 text-footer">
      <div class="btn-group btn-group-sm">
        <pagination boundaryLinks="true" [maxSize]="5" [rotate]="false" previousText="&lsaquo;" nextText="&rsaquo;"
          firstText="&laquo;" lastText="&raquo;" [itemsPerPage]="itemsPerPage" [totalItems]="totalItems"
          (pageChanged)="mudarPagina($event); somaPaginacao()" (numPages)=" somaPaginacao()" [(ngModel)]="currentPage">
        </pagination>
      </div>
    </div>
  </div>
</div>