import { PaginacaoViewModel } from "../../PaginacaoViewModel";
import { AgendamentoItemViewModel } from "../agendamento-item-view-model";

export class AgendamentoItemPesquisaViewModel extends AgendamentoItemViewModel implements PaginacaoViewModel {
    pagina: number = 1;
    quantidadeRegistrosPagina: number = 20;
    totalRegistros: number = 0;
    totalPaginas: number = 0;
    desativados: boolean = false;
    order?: string;
    start?: number;

}