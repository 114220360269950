export class ProdutoNcmViewModel {
    id?: number;
    codigo?: string;
    descricao?: string;
    ativo?: boolean;
    pis_retido?: number;
    cofins_retido?: number;
    cst_ipi?: string;
    cst_pis?: string;
    cst_cofins?: string;
    cst_ipi_entrada?: string;
    cst_pis_entrada?: string;
    cst_cofins_entrada?: string;
    cst_ipi_saida_pf?: string;
    cst_pis_saida_pf?: string;
    cst_cofins_saida_pf?: string;
    cst_ipi_entrada_pf?: string;
    cst_pis_entrada_pf?: string;
    cst_cofins_entrada_pf?: string;
    aliq_imposto_pago?: number;
    aliq_imposto_pago_importacao?: number;
    aliq_imposto_pago_estadual?: number;
    msg_nf_pf?: string;
    msg_nf_pj?: string;
    msg_nf_capa_pf?: string;
    msg_nf_capa_pj?: string;
    cod_natureza_receita?: string;
}