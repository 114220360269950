import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { URLBASEADMIN } from '../../configuration.admin.service';
import { Observable } from 'rxjs';
import { ListaPaginadaViewModel } from 'src/modules/manager/view-models/ListaPaginadaViewModel';
import { RetornoApiViewModel } from 'src/modules/manager/view-models/RetornoApiViewModel';
import { Uteis } from 'src/modules/utilizarios/uteis';
import { UsuarioEmpresaViewModel } from '../../view-models/cadastro/UsuarioEmpresaViewModel';
import { UsuarioEmpresaPesquisaViewModel } from '../../view-models/pesquisa/UsuarioEmpresaPesquisaViewModel';
import { PesquisaGeralViewModel } from '../../view-models/pesquisa/PesquisaGeralViewModel';
import { CadastroViewModel } from '../../view-models/cadastro/CadastroViewModel';

const urlGet = `${URLBASEADMIN}/usuario_empresa_adm_service/get`;
const urlDelete = `${URLBASEADMIN}/usuario_empresa_adm_service/delete`;
const urlAddUpdate = `${URLBASEADMIN}/usuario_empresa_adm_service/addupdate`;

@Injectable()
export class UsuarioEmpresaService {
    constructor(private http: HttpClient) { }

    obterTodos(pesquisa: UsuarioEmpresaPesquisaViewModel): Observable<RetornoApiViewModel<UsuarioEmpresaPesquisaViewModel[]>> {

        let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel()

        pesquisaGeral.select = ``;

        pesquisaGeral.calcRowCount = 1;

        pesquisaGeral.order;

        pesquisaGeral.where = ` and id_usuario = ${pesquisa?.id_usuario}`;

        return this.http.post<RetornoApiViewModel<UsuarioEmpresaPesquisaViewModel[]>>(urlGet, pesquisaGeral);
    }
    obterTodosUsarSistema(pesquisa: UsuarioEmpresaPesquisaViewModel): Observable<RetornoApiViewModel<UsuarioEmpresaPesquisaViewModel[]>> {

        let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel()

        pesquisaGeral.select = `empresa_adm.descricao as empresa_descricao
                                , empresa_adm.id_maxdata
                                , usuario_adm.id as usuario_id
                                , usuario_adm.email
                                , CASE WHEN usuario_adm.chk_usa_sistema==1 THEN 'SIM'
                                ELSE 'NÃO' END AS chk_usa_sistema
                                , usuario_empresa_adm.id as id
                                , usuario_empresa_adm.id_empresa 
                                , usuario_empresa_adm.id_usuario
                                `;

        pesquisaGeral.calcRowCount = 1;

        pesquisaGeral.order;

        pesquisaGeral.where = ` AND id_empresa = ${pesquisa?.id_empresa} AND usuario_adm.ativo = 1`;

        pesquisaGeral.limit = pesquisa?.quantidadeRegistrosPagina || 20;


        pesquisaGeral.start = pesquisa?.start || 0;

        return this.http.post<RetornoApiViewModel<UsuarioEmpresaPesquisaViewModel[]>>(urlGet, pesquisaGeral);
    }

    adicionar(objUsuarioEmpresa: CadastroViewModel<UsuarioEmpresaViewModel>): Observable<RetornoApiViewModel<UsuarioEmpresaViewModel>> {
        return this.http.post<RetornoApiViewModel<UsuarioEmpresaViewModel>>(urlAddUpdate, objUsuarioEmpresa);
    }

    removerUsuarioEmpresa(id: number, objDelete: CadastroViewModel<UsuarioEmpresaViewModel>): Observable<RetornoApiViewModel<any>> {
        return this.http.request<RetornoApiViewModel<any>>('delete', `${urlDelete}/${id}`, { body: objDelete })
    }
}