export class IndicadoresRentabilidadeVendaViewModel{
    valor_total_liquido : number = 0;
    valor_total_liquido_mais_impostos : number = 0;
    total_desconto : number = 0;
    quantidade_vendas : number = 0;
    valor_medio_venda : number = 0;
    custo_medio : number = 0;
    
    get totalDescontoPercentual(): number{
     return this.total_desconto === 0 || this.valor_total_liquido_mais_impostos === 0 ? 0 
            : (this.total_desconto / this.valor_total_liquido_mais_impostos) * 100
    }
}