import { Injectable } from '@angular/core';
import { URLBASE } from 'src/modules/configuration.service';
import { HttpClient } from '@angular/common/http';
import { AutenticacaoService } from 'src/modules/autenticacao/services/autenticacao.service';
import { PesquisaGeralViewModel } from '@modules/admin/view-models/pesquisa/PesquisaGeralViewModel';
import { Observable } from 'rxjs';
import { CadastroViewModel } from 'src/modules/admin/view-models/cadastro/CadastroViewModel';
import { OperacaoViewModel } from 'src/modules/manager/view-models/cadastro/contabil/operacao-view-model';
import { RetornoApiViewModel } from 'src/modules/manager/view-models/RetornoApiViewModel';
import { OperacaoPesquisaViewModel } from 'src/modules/manager/view-models/cadastro/contabil/pesquisa/operacao-pesquisa-view-model';

@Injectable({
  providedIn: 'root'
})
export class OperacaoService {
  
  idEmpresaMaxdata = this.autenticacaoService.acessoUsuarioValue?.idEmpresaMaxdata;
  urlGet =  `${URLBASE}/${this.idEmpresaMaxdata}/operacao_service/get`;
  urlAddUpdate  = `${URLBASE}/${this.idEmpresaMaxdata}/operacao_service/addupdate`;

  constructor(private http: HttpClient, private autenticacaoService: AutenticacaoService) { }

  obterTodos(pesquisa?: OperacaoPesquisaViewModel) : Observable<RetornoApiViewModel<OperacaoPesquisaViewModel[]>> {

    let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel();
    pesquisaGeral.select = `operacao.id,
                          operacao.descricao,
                          operacao.ativo,
                          operacao.natureza_operacao,
                          operacao.gera_finan,
                          operacao.gera_icms,
                          operacao.gera_credito,
                          operacao.gera_ipi,
                          operacao.tipo_estoque,
                          operacao.tipo_finan,
                          operacao.tipo_opr,
                          operacao.usa_ref,
                          operacao.usa_preco_custo,
                          operacao.usa_vlr_neg,
                          operacao.destaca_msf,
                          operacao.altera_vlr,
                          operacao.destaca_no_caixa,
                          operacao.nao_enviar_sped,
                          operacao.nao_permite_venda_forn,
                          operacao.altera_vlr_tt_nf,
                          operacao.nao_enviar_inventario,
                          operacao.pedir_senha_gerente,
                          operacao.ciap,
                          operacao.gera_fcp_st`;

    pesquisaGeral.calcRowCount = 1;

    pesquisaGeral.start = pesquisa?.start || 0;  

    pesquisaGeral.order = pesquisa?.order ? pesquisa?.order : 'id'; 

    pesquisaGeral.limit = pesquisa?.quantidadeRegistrosPagina || 0;

    pesquisaGeral.where = ` and  operacao.ativo = ${ pesquisa?.desativados ? 0 : 1}`;

    if (pesquisa?.id) pesquisaGeral.where += ` and  operacao.id = ${pesquisa?.id}`;

    if (pesquisa?.descricao) pesquisaGeral.where += ` and lower(operacao.descricao) like lower('%${pesquisa?.descricao}%')`;

    if (pesquisa?.natureza_operacao) pesquisaGeral.where += ` and lower(operacao.natureza_operacao) like lower('%${pesquisa?.natureza_operacao}%')`;
    
    if (pesquisa?.tipo_opr) pesquisaGeral.where += ` and  operacao.tipo_opr IN (${pesquisa?.tipo_opr})`;

    return this.http.post<RetornoApiViewModel<OperacaoPesquisaViewModel[]>>(this.urlGet,pesquisaGeral)
  }
  
  obterPorId(id: number): Observable<RetornoApiViewModel<OperacaoViewModel[]>>{
    
    let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel();

    pesquisaGeral.select = `operacao.id,
                          operacao.descricao,
                          operacao.ativo,
                          operacao.natureza_operacao,
                          operacao.gera_finan,
                          operacao.gera_icms,
                          operacao.gera_credito,
                          operacao.gera_ipi,
                          operacao.tipo_estoque,
                          operacao.tipo_finan,
                          operacao.tipo_opr,
                          operacao.usa_ref,
                          operacao.usa_preco_custo,
                          operacao.usa_vlr_neg,
                          operacao.destaca_msf,
                          operacao.altera_vlr,
                          operacao.destaca_no_caixa,
                          operacao.nao_enviar_sped,
                          operacao.nao_permite_venda_forn,
                          operacao.altera_vlr_tt_nf,
                          operacao.nao_enviar_inventario,
                          operacao.pedir_senha_gerente,
                          operacao.ciap,
                          operacao.gera_fcp_st`;

      pesquisaGeral.where = `and operacao.id = ${id}`;

      return this.http.post<RetornoApiViewModel<OperacaoViewModel[]>>(`${this.urlGet}`,pesquisaGeral);
  }
  
  adicionar(operacaoViewModel: CadastroViewModel<OperacaoViewModel>) : Observable<RetornoApiViewModel<OperacaoViewModel[]>>{
      return this.http.post<RetornoApiViewModel<OperacaoViewModel[]>>(this.urlAddUpdate, operacaoViewModel);
  }
  
  atualizar(id: number, operacaoViewModel: CadastroViewModel<OperacaoViewModel>) : Observable<RetornoApiViewModel<OperacaoViewModel>>{
      return this.http.put<RetornoApiViewModel<OperacaoViewModel>>(`${this.urlAddUpdate}/${id}`, operacaoViewModel);
  }

}
