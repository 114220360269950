import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UsuarioService } from '@modules/admin/cadastro/usuario/usuario.service';
import { CadastroViewModel } from '@modules/admin/view-models/cadastro/CadastroViewModel';
import { UsuarioViewModel } from '@modules/admin/view-models/cadastro/UsuarioViewModel';
import { AlertMessage } from '@modules/alert.configuration.service';
import { Uteis } from '@modules/utilizarios/uteis';
import { DisplayMessage } from '@modules/validacao/generic-form-validation';
import { CustomValidators } from 'ng2-validation';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-usuario-modal',
  templateUrl: './usuario-modal.component.html',
  styles: [
  ]
})
export class UsuarioModalComponent implements OnInit {
  objUsuario: CadastroViewModel<UsuarioViewModel> = new CadastroViewModel<UsuarioViewModel>();
  acesso: boolean;
  alterar: boolean = true;
  carregando: boolean = false;
  @Output() closeModal = new EventEmitter();
  @Input() usuario_id_admin: number;
  cadastroForm!: FormGroup;
  displayMessage: DisplayMessage = {};
  isCollapsed: boolean = false;
  @Input() abrirFecharModalUsuarioAdmin: boolean = false;
  constructor(
    private usuario$: UsuarioService
    , private router: Router
    , private alert: AlertMessage
    , private formBuilder: FormBuilder
    , private spinner: NgxSpinnerService) {
  }

  ngOnInit(): void {

    this.criarFormulario();
    this.usuarioPorId(this.usuario_id_admin); 


  }



  usuarioPorId(id: number): void {


    this.spinner.show();

    this.usuario$.obterPorId(id).subscribe(
      retorno => {
        if (retorno.total <= 0) this.alert.Danger('Erro', 'Usuário não encontrado');

        this.objUsuario.obj = retorno.total > 0 ? retorno.result[0] : new UsuarioViewModel();
        this.objUsuario.obj.senha_hash = "";
        this.acesso = this.objUsuario.obj.chk_primeiro_acesso
        this.objUsuario.obj.chk_primeiro_acesso = false

        this.cadastroForm.setValue(this.objUsuario.obj);

        this.spinner.hide();
      },
      error => {

        this.spinner.hide();

        this.alert.Danger('Erro', Uteis.ObterErroApi(error));

      }
    )


  }
   verificaUsuario(): boolean {
    const usuarioString = localStorage.getItem('acessoUsuario');
    const usuarioObjeto = JSON.parse(usuarioString);
    return usuarioObjeto.idUsuario === this.usuario_id_admin;
  }


  atualizar() {
    const senhaHash = this.cadastroForm.get('senha_hash').value;
    if (senhaHash && senhaHash.trim() !== '') {
        
        if (this.verificaUsuario()) {
          this.objUsuario.obj = this.cadastroForm.value
          if (this.usuario_id_admin && this.usuario_id_admin > 0) {
            this.usuario$.atualizar(this.usuario_id_admin, this.objUsuario).subscribe(
              retorno => {
                this.mensagensCadastro();
                this.closeModalUsuarioAdmin();
                this.carregando = false;
              },
              error => {
                this.carregando = false;
                this.alert.Danger('Erro', Uteis.ObterErroApi(error));
              }
            )
          }
        } else {
          // Exibe uma mensagem de erro ou faz algo caso o id seja diferente
          this.alert.Info('Falha ao alterar senha', 'Não foi possível alterar a senha. O usuário selecionado é diferente do usuário logado. Por favor, faça login novamente com o usuário correto.');
        }
    } else {
        // Exibe uma mensagem de erro ou faz algo caso o campo esteja vazio
        this.alert.Info('Falha ao alterar senha', 'A senha não pode estar vazia.');
    }
}


  mensagensCadastro(): void {

    this.alert.Success('Sucesso', `Usuário ${this.alterar ? 'atualizado' : 'cadastrado'} com Sucesso.`);

    if (this.alterar) return;

    this.alert.Info('Informativo', 'Formulário de cadastro foi limpo para realizar um novo cadastro.', { timeOut: 10000 });

  }

  criarFormulario() {

    let senha_hash = new FormControl(!this.alterar ? '' : this.objUsuario.obj?.senha_hash, [Validators.required, CustomValidators.rangeLength([1, 50])]);

    this.cadastroForm = this.formBuilder.group({
      id: [!this.alterar ? 0 : this.objUsuario.obj?.id],
      email: [!this.alterar ? '' : this.objUsuario.obj?.email, [Validators.required, Validators.email],],
      senha_hash: senha_hash,
      dt_cadastro: [!this.alterar ? Uteis.DateCadastroToString(new Date()) : this.objUsuario.obj?.dt_cadastro],
      ativo: [!this.alterar ? true : this.objUsuario.obj?.ativo],
      chk_usu_adm: [!this.alterar ? false : this.objUsuario.obj?.chk_usu_adm],
      refresh_token: [!this.alterar ? '' : this.objUsuario.obj?.refresh_token],
      chk_usa_sistema: [!this.alterar ? '' : this.objUsuario.obj?.chk_usa_sistema],
      chk_primeiro_acesso: [!this.alterar ? '' : this.objUsuario.obj?.chk_primeiro_acesso]
    });
  }

  closeModalUsuarioAdmin() {
    this.closeModal.emit(false);

  }
}
