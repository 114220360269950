import { Component, OnInit, AfterViewInit, ViewChildren, ElementRef, TemplateRef, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControlName } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, fromEvent, merge } from 'rxjs';
import { isNumber } from 'util';


import { ProdutoGrupoViewModel } from 'src/modules/manager/view-models/cadastro/estoque/ProdutoGrupoViewModel';
import { MensagensValidacao } from 'src/modules/validacao/mensagensValidacao';
import { TituloPagina } from 'src/modules/utilizarios/tituloPaginas/tituloPaginas';
import { HistoricoNavegacao } from 'src/modules/utilizarios/tituloPaginas/historicoNavegacao';
import { AlertMessage } from 'src/modules/alert.configuration.service';
import { Uteis } from 'src/modules/utilizarios/uteis';
import { PesquisaGeralViewModel } from '@modules/admin/view-models/pesquisa/PesquisaGeralViewModel';
import { TypeaheadOptions } from 'ngx-bootstrap/typeahead/public_api';
import { CadastroViewModel } from 'src/modules/admin/view-models/cadastro/CadastroViewModel';
import { AlertMensagemPadrao } from 'src/modules/utilizarios/alertMensagemPadrao';
import { NgxSpinnerService } from 'ngx-spinner';

import { ConfigModal } from '@modules/utilizarios/ConfigModal';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DisplayMessage, GenericValidator, ValidationMessages } from '@modules/validacao/generic-form-validation';
import { ProdutoGrupoService } from '@modules/manager/cadastro/estoque/produto-grupo/produto-grupo.service';

@Component({
  selector: 'app-grupo-cadastro-modal',
  templateUrl: './grupo-cadastro-modal.component.html',
  styles: [
  ]
})
export class GrupoCadastroModalComponent implements OnInit {

  @ViewChildren(FormControlName, { read: ElementRef }) formInputElements!: ElementRef[];
  @Output() closeModal = new EventEmitter();
  @Output() idCadastrado: EventEmitter<number> = new EventEmitter<number>();
  @Input() abrirFecharCadastroGrupoModal: boolean = false;
  tituloPagina: TituloPagina = new TituloPagina('Grupo de Produto - Cadastro',
    [
      new HistoricoNavegacao('Início', '/'),
      new HistoricoNavegacao('Cadastro'),
      new HistoricoNavegacao('Estoque'),
      new HistoricoNavegacao('Grupos de Produto', '/cadastro/estoque/grupo-de-produto'),
      new HistoricoNavegacao('Cadastro'),
    ]);

  cadastroForm!: FormGroup;
  objProdutoGrupo: CadastroViewModel<ProdutoGrupoViewModel> = new CadastroViewModel<ProdutoGrupoViewModel>();
  alterar: boolean = false;
  formResult: string = '';
  clickAddVariosRegistros: boolean = false;
  validationMessages!: ValidationMessages;
  genericValidator!: GenericValidator;
  displayMessage: DisplayMessage = {};
  carregando: boolean = false;
  abrirFecharCadastroGrupo: boolean = false;
  tabela: string = 'produto_grupo'

  constructor(private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private produtoGrupo$: ProdutoGrupoService,
    private alert: AlertMessage,
    private spinner: NgxSpinnerService,
    private modal$: BsModalService) {

    this.messagesValidations();
  }

  ngOnInit(): void {
    this.criarFormulario();

  }

  ngAfterViewInit(): void {
    let controlBlurs: Observable<any>[] = this.formInputElements
      .map((formControl: ElementRef) => fromEvent(formControl.nativeElement, 'blur'));

    merge(...controlBlurs).subscribe(() => {
      this.displayMessage = this.genericValidator.processarMensagens(this.cadastroForm);
    });
  }

  messagesValidations() {
    this.validationMessages = {
      descricao: {
        required: MensagensValidacao.Obrigatorio('Descrição'),
        minlength: MensagensValidacao.MinimoCaracteres('Descrição', 2),
        maxlength: MensagensValidacao.MaximoCaracteres('Descrição', 50)
      },
      aliq_comissao: {
        min: MensagensValidacao.ValorMinimo('Comissão', 0)
      },
      data_cadastro: {
        required: MensagensValidacao.Obrigatorio('Data de Cadastro')
      }
    };
    this.genericValidator = new GenericValidator(this.validationMessages);
  }

  criarFormulario() {
    this.cadastroForm = this.fb.group({
      id: [{ value: !this.alterar ? 0 : this.objProdutoGrupo.obj?.id, disabled: true }],
      descricao: [!this.alterar ? '' : this.objProdutoGrupo.obj?.descricao, [Validators.required, Validators.minLength(2), Validators.maxLength(50)]],
      aliq_comissao: [!this.alterar ? 0 : this.objProdutoGrupo.obj?.aliq_comissao, [Validators.min(0)]],
      dt_cadastro: [!this.alterar ? Uteis.DateCadastroToString(new Date()) : this.objProdutoGrupo.obj?.dt_cadastro],
      ativo: [!this.alterar ? true : this.objProdutoGrupo.obj?.ativo]
    });
  }

  limparForm() {
    this.cadastroForm.reset({
      id: 0,
      descricao: '',
      aliq_comissao: 0,
      ativo: true
    });

    (document.querySelector('[name="descricao"]') as HTMLElement).focus();
  }

  onSubmit() {

    this.cadastroForm.markAllAsTouched();

    this.displayMessage = this.genericValidator.processarMensagens(this.cadastroForm);

    if (!this.cadastroForm.valid) {

      this.mensagemCamposInvalidos(this.displayMessage);

      return
    }
    if (!this.cadastroForm.dirty || !this.cadastroForm.valid) {

      this.alert.Info(AlertMensagemPadrao.formularioNaoAlterado.titulo, AlertMensagemPadrao.formularioNaoAlterado.mensagem);

      return
    };

    this.carregando = true;


    this.adicionar();
  }

  atualizarDadosObjeto() {
    this.objProdutoGrupo.obj = Object.assign({}, this.objProdutoGrupo.obj, this.cadastroForm.value);
  }

  atualizar() {

    this.atualizarDadosObjeto();

    if (this.objProdutoGrupo.obj?.id && this.objProdutoGrupo.obj?.id > 0)

      this.produtoGrupo$.atualizar(this.objProdutoGrupo.obj?.id, this.objProdutoGrupo).subscribe(
        retorno => {

          this.mensagensCadastro();

          this.router.navigate(['/cadastro/estoque/grupo-de-produto']);

          this.carregando = false;

        }, error => {
          this.carregando = false;

          this.alert.Danger('Erro', Uteis.ObterErroApi(error))
        });
  }

  adicionar() {

    this.atualizarDadosObjeto();

    this.produtoGrupo$.adicionar(this.objProdutoGrupo).subscribe(
      retorno => {
        

        this.mensagensCadastro();
        
        this.limparForm();
        this.carregando = false;
        this.idCadastrado.emit(retorno.result[0].id);
        this.closeModal.emit(false);
      },
      error => {
        this.carregando = false;

        this.alert.Danger('Erro', Uteis.ObterErroApi(error))
      });
  }

  mensagemCamposInvalidos(mensagens: any) {
    let camposInvalidos = Uteis.ConverterObjetoToArray(mensagens);
    this.alert.Warning(AlertMensagemPadrao.formularioInvalido.titulo, AlertMensagemPadrao.formularioInvalido.mensagem + '<br><br>' + camposInvalidos.toString().replace(/,/g, ''));
  }

  private mensagensCadastro(): void {
    this.alert.Success('Sucesso', `Grupo de Produto ${this.alterar ? 'atualizado' : 'cadastrado'} com Sucesso.`);

  }

  openModalCadastroGrupo() {
    this.abrirFecharCadastroGrupoModal = true;

  }
  closeModalCadastroGrupo() {
    this.closeModal.emit(false);
  }
}

