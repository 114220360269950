import { Injectable, ɵɵresolveBody } from '@angular/core';
import { HttpClient } from '@angular/common/http';


import { URLBASE } from 'src/modules/configuration.service';
import { Observable } from 'rxjs';
import { ListaPaginadaViewModel } from 'src/modules/manager/view-models/ListaPaginadaViewModel';
import { RetornoApiViewModel } from 'src/modules/manager/view-models/RetornoApiViewModel';
import { Uteis } from 'src/modules/utilizarios/uteis';

import { PesquisaGeralViewModel } from '@modules/admin/view-models/pesquisa/PesquisaGeralViewModel';
import { AutenticacaoService } from 'src/modules/autenticacao/services/autenticacao.service';
import { CadastroViewModel } from 'src/modules/admin/view-models/cadastro/CadastroViewModel';
import { ProdutoNcmPesquisaViewModel } from 'src/modules/manager/view-models/cadastro/estoque/pesquisa/produto-ncm-pesquisa-view-model';
import { ProdutoNcmViewModel } from 'src/modules/manager/view-models/cadastro/estoque/produto-ncm-view-model';

@Injectable()
export class ProdutoNcmService {
    idEmpresaMaxdata = this.autenticacaoService.acessoUsuarioValue?.idEmpresaMaxdata;

    urlGet = `${URLBASE}/${this.idEmpresaMaxdata}/produto_ncm_service/get`;
    urlAddUpdate = `${URLBASE}/${this.idEmpresaMaxdata}/produto_ncm_service/addupdate`;
    urlImportData = `${URLBASE}/${this.idEmpresaMaxdata}/produto_ncm_service/importdata`;

    constructor(private http: HttpClient,
        private autenticacaoService: AutenticacaoService) { }

    obterTodos(pesquisa: ProdutoNcmPesquisaViewModel): Observable<RetornoApiViewModel<ProdutoNcmPesquisaViewModel[]>> {

        let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel()
        
        pesquisaGeral.select = `produto_ncm.id,
                            produto_ncm.codigo,
                            produto_ncm.descricao,
                            produto_ncm.ativo,
                            produto_ncm.pis_retido,
                            produto_ncm.cofins_retido,
                            produto_ncm.cst_ipi,
                            produto_ncm.cst_pis,
                            produto_ncm.cst_cofins,
                            produto_ncm.cst_ipi_entrada,
                            produto_ncm.cst_pis_entrada,
                            produto_ncm.cst_cofins_entrada,
                            produto_ncm.cst_ipi_saida_pf,
                            produto_ncm.cst_pis_saida_pf,
                            produto_ncm.cst_cofins_saida_pf,
                            produto_ncm.cst_ipi_entrada_pf,
                            produto_ncm.cst_pis_entrada_pf,
                            produto_ncm.cst_cofins_entrada_pf,
                            produto_ncm.aliq_imposto_pago,
                            produto_ncm.aliq_imposto_pago_importacao,
                            produto_ncm.aliq_imposto_pago_estadual,
                            produto_ncm.msg_nf_pf,
                            produto_ncm.msg_nf_pj,
                            produto_ncm.msg_nf_capa_pf,
                            produto_ncm.msg_nf_capa_pj,
                            produto_ncm.cod_natureza_receita`;

        pesquisaGeral.calcRowCount = 1;

        pesquisaGeral.limit = pesquisa?.quantidadeRegistrosPagina;

        pesquisaGeral.where = `and ativo = ${pesquisa?.desativados ? 0 : 1}`;

        pesquisaGeral.start = pesquisa.start || 0;

        pesquisaGeral.order = pesquisa?.order ? pesquisa?.order : 'codigo';
    
        if (pesquisa?.id) pesquisaGeral.where += ` and id = ${pesquisa?.id}`;
    
        if (pesquisa?.codigo) pesquisaGeral.where += ` and codigo like '%${pesquisa?.codigo}%'`;
    
        if (pesquisa?.descricao) pesquisaGeral.where += ` and descricao like '%${pesquisa?.descricao}%'`;
    
        if (pesquisa?.cod_natureza_receita) pesquisaGeral.where += ` and cod_natureza_receita like '%${pesquisa?.cod_natureza_receita}%'`;

        return this.http.post<RetornoApiViewModel<ProdutoNcmPesquisaViewModel[]>>(this.urlGet, pesquisaGeral)
    }

    obterPorId(id: number): Observable<RetornoApiViewModel<ProdutoNcmViewModel[]>> {

        let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel()
        
        pesquisaGeral.select = `produto_ncm.id,
                            produto_ncm.codigo,
                            produto_ncm.descricao,
                            produto_ncm.ativo,
                            produto_ncm.pis_retido,
                            produto_ncm.cofins_retido,
                            produto_ncm.cst_ipi,
                            produto_ncm.cst_pis,
                            produto_ncm.cst_cofins,
                            produto_ncm.cst_ipi_entrada,
                            produto_ncm.cst_pis_entrada,
                            produto_ncm.cst_cofins_entrada,
                            produto_ncm.cst_ipi_saida_pf,
                            produto_ncm.cst_pis_saida_pf,
                            produto_ncm.cst_cofins_saida_pf,
                            produto_ncm.cst_ipi_entrada_pf,
                            produto_ncm.cst_pis_entrada_pf,
                            produto_ncm.cst_cofins_entrada_pf,
                            produto_ncm.aliq_imposto_pago,
                            produto_ncm.aliq_imposto_pago_importacao,
                            produto_ncm.aliq_imposto_pago_estadual,
                            produto_ncm.msg_nf_pf,
                            produto_ncm.msg_nf_pj,
                            produto_ncm.msg_nf_capa_pf,
                            produto_ncm.msg_nf_capa_pj,
                            produto_ncm.cod_natureza_receita`;

        pesquisaGeral.where = ` and produto_ncm.id = ${id}`;

        return this.http.post<RetornoApiViewModel<ProdutoNcmViewModel[]>>(`${this.urlGet}`, pesquisaGeral);
    }

    adicionar(produtoNcmViewModel: CadastroViewModel<ProdutoNcmViewModel>): Observable<RetornoApiViewModel<ProdutoNcmViewModel>> {
        return this.http.post<RetornoApiViewModel<ProdutoNcmViewModel>>(this.urlAddUpdate, produtoNcmViewModel);
    }

    atualizar(id: number, produtoNcmViewModel: CadastroViewModel<ProdutoNcmViewModel>): Observable<RetornoApiViewModel<ProdutoNcmViewModel>> {
        return this.http.put<RetornoApiViewModel<ProdutoNcmViewModel>>(`${this.urlAddUpdate}/${id}`, produtoNcmViewModel);
    }

    sincronizar() {
        return this.http.get(this.urlImportData);
    }
}