import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule }   from '@angular/forms';

import { HeaderComponent } from './header/header.component';
import { MenuComponent } from './menu/menu.component';
import { FooterComponent } from './footer/footer.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';

import { NgMaterialMultilevelMenuModule } from 'ng-material-multilevel-menu';
import { ConfiguracaoService } from '../manager/sistema/configuracao/configuracao.service';

import { TypeaheadModule } from 'ngx-bootstrap/typeahead';

import { MegaMenuModule } from 'primeng/megamenu';
import {MenubarModule} from 'primeng/menubar';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { SharedModule } from 'primeng/api';
import {MenuModule} from 'primeng/menu';
import { DialogModule } from "primeng/dialog";
import { AdminModule } from "@modules/admin/admin.module";
import { CadastroModalModule } from "@modules/components/cadastro-modal/cadastro-modal.module";
@NgModule({
    declarations: [
        HeaderComponent,
        MenuComponent,
        FooterComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        MenubarModule,
        BsDropdownModule.forRoot(),
        FormsModule, 
        LayoutModule,
        MatToolbarModule,
        MatButtonModule,
        MatSidenavModule,
        MatIconModule,
        MatListModule,
        MatMenuModule,
        MegaMenuModule,
        NgMaterialMultilevelMenuModule,
        InputTextModule,
        ButtonModule,
        SharedModule,
        TypeaheadModule.forRoot(),
        MenuModule,
        DialogModule,
        AdminModule,
        CadastroModalModule
    ],
    exports: [
        HeaderComponent,
        MenuComponent,
        FooterComponent,
    ],
    providers: [
        ConfiguracaoService
    ]
})

export class NavegacaoModule { }