import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CadastroViewModel } from '@modules/admin/view-models/cadastro/CadastroViewModel';
import { PesquisaGeralViewModel } from '@modules/admin/view-models/pesquisa/PesquisaGeralViewModel';
import { AutenticacaoService } from '@modules/autenticacao/services/autenticacao.service';
import { URLBASE } from '@modules/configuration.service';
import { ProdutoGradePesquisaViewModel } from '@modules/manager/view-models/cadastro/estoque/pesquisa/produto-grade-pesquisa-view-model';
import { ProdutoGradeViewModel } from '@modules/manager/view-models/cadastro/estoque/produto-grade-view-model';
import { RetornoApiViewModel } from '@modules/manager/view-models/RetornoApiViewModel';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProdutoGradeService {
  idEmpresaMaxdata = this.autenticacao$.acessoUsuarioValue?.idEmpresaMaxdata;
  urlApi =  `${URLBASE}/${this.idEmpresaMaxdata}/produto_grade_service`;

  constructor(private http: HttpClient, 
      private autenticacao$: AutenticacaoService){ }

  obterTodos(pesquisa?: ProdutoGradePesquisaViewModel) : Observable<RetornoApiViewModel<ProdutoGradePesquisaViewModel[]>> {


    let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel()

    pesquisaGeral.select = `produto_grade.*, 
                            produto_grade_descricao.descricao as produto_grade_descricao, 
                            produto_grade_tamanho.tamanho as produto_grade_tamanho `;

    pesquisaGeral.calcRowCount = 1;

    pesquisaGeral.limit = pesquisa?.quantidadeRegistrosPagina || 0;
 
    pesquisaGeral.where = `and produto_grade.produto_id = ${pesquisa?.produto_id || 0} and produto_grade.ativo = ${pesquisa?.desativados ? 0 : 1}`;

    pesquisaGeral.start = pesquisa?.start || 0;

    pesquisaGeral.order = pesquisa?.order ? pesquisa?.order : 'produto_grade_descricao.descricao';

    if (pesquisa?.id) pesquisaGeral.where += ` and produto_grade.id = ${pesquisa?.id}`;

    if (pesquisa?.produto_grade_tamanho_id) pesquisaGeral.where += ` and produto_grade.produto_grade_tamanho_id = ${pesquisa?.produto_grade_tamanho_id}`;

    if (pesquisa?.produto_grade_tamanho) pesquisaGeral.where += ` and lower(produto_grade_tamanho.tamanho) like lower('%${pesquisa?.produto_grade_tamanho}%')`;

    if (pesquisa?.produto_grade_descricao_id) pesquisaGeral.where += ` and produto_grade.produto_grade_descricao_id = ${pesquisa?.produto_grade_descricao_id}`;
    
    //if (pesquisa?.produto_id) pesquisaGeral.where += ` and produto_grade.produto_id = ${pesquisa?.produto_id}`;

    if (pesquisa?.produto_grade_descricao) pesquisaGeral.where += ` and lower(produto_grade_descricao.descricao) like lower('%${pesquisa?.produto_grade_descricao}%')`;

    if (pesquisa?.codigo_barras) pesquisaGeral.where += ` and lower(produto_grade.codigo_barras) = lower('${pesquisa?.codigo_barras}')`;

    return this.http.post<RetornoApiViewModel<ProdutoGradePesquisaViewModel[]>>(`${this.urlApi}/get`,pesquisaGeral)
  }

  obterTodosGroup(pesquisa?: ProdutoGradePesquisaViewModel) : Observable<RetornoApiViewModel<ProdutoGradePesquisaViewModel[]>> {

    let pesquisaGeral: {}

    pesquisaGeral = Object.assign({

      produto_id_array: pesquisa?.produto_id.toString(),

      start: pesquisa?.start || 0,

      limit: pesquisa?.quantidadeRegistrosPagina || 100,
      
    })

    return this.http.post<RetornoApiViewModel<ProdutoGradePesquisaViewModel[]>>(`${this.urlApi}/Get_Grade`,pesquisaGeral)
  }

  obterPorId(id: number): Observable<RetornoApiViewModel<ProdutoGradeViewModel[]>>{

    let pesquisaGeralViewModel : PesquisaGeralViewModel = new PesquisaGeralViewModel();

    pesquisaGeralViewModel.where = ` and produto_grade.id = ${id}`; 

    return this.http.post<RetornoApiViewModel<ProdutoGradeViewModel[]>>(`${this.urlApi}/get`,pesquisaGeralViewModel);
  }

  adicionar(produtoGradeViewModel: CadastroViewModel<ProdutoGradeViewModel>) : Observable<RetornoApiViewModel<ProdutoGradeViewModel>>{

      return this.http.post<RetornoApiViewModel<ProdutoGradeViewModel>>(`${this.urlApi}/addupdate`, produtoGradeViewModel);
  }

  atualizar(id: number, produtoGradeViewModel: CadastroViewModel<ProdutoGradeViewModel>) : Observable<RetornoApiViewModel<ProdutoGradeViewModel>>{

      return this.http.put<RetornoApiViewModel<ProdutoGradeViewModel>>(`${this.urlApi}/addupdate/${id}`, produtoGradeViewModel);
  }

  obterPorCodigoBarras(codigo_barras: string) : Observable<RetornoApiViewModel<ProdutoGradePesquisaViewModel[]>> {

    let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel()

    pesquisaGeral.select = `produto_grade.*, 
                            produto_grade_descricao.descricao as produto_grade_descricao, 
                            produto_grade_tamanho.tamanho as produto_grade_tamanho `;

    pesquisaGeral.calcRowCount = 1;

    pesquisaGeral.where = ` and produto_grade.ativo = 1 and lower(produto_grade.codigo_barras) = lower('${codigo_barras}')`

    pesquisaGeral.limit = 1;

    return this.http.post<RetornoApiViewModel<ProdutoGradePesquisaViewModel[]>>(`${this.urlApi}/get`,pesquisaGeral)
  }
}
