import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertMessage } from '@modules/alert.configuration.service';
import { ProdutoGrupoPesquisaViewModel } from '@modules/manager/view-models/cadastro/estoque/pesquisa/ProdutoGrupoPesquisaViewModel';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProdutoGrupoService } from '@modules/manager/cadastro/estoque/produto-grupo/produto-grupo.service';
import { Uteis } from '@modules/utilizarios/uteis';

@Component({
  selector: 'app-produto-grupo-pesquisa-modal',
  templateUrl: './produto-grupo-pesquisa-modal.component.html',
  styles: [
  ]
})
export class ProdutoGrupoPesquisaModalComponent implements OnInit {
  @Input() abrirFecharPesquisaProdutoGrupoModal: boolean = false;
  @Input() stringPesquisa: string = '';
  @Output() closeModal = new EventEmitter();
  @Output() produtoGrupo: EventEmitter<any> = new EventEmitter();
  pesquisa: ProdutoGrupoPesquisaViewModel = new ProdutoGrupoPesquisaViewModel();
  listaProdutoGrupo: ProdutoGrupoPesquisaViewModel[] = [];

  constructor(private produtoGrupo$: ProdutoGrupoService,
    private alert: AlertMessage,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    setTimeout(() => {
      (document.querySelector(`[name="descricao"]`) as HTMLElement).focus();
      this.pesquisa.descricao = this.stringPesquisa;
    }, 100);
  }

  pesquisar() {
    this.spinner.show();

    this.produtoGrupo$.obterTodos(this.pesquisa).subscribe(
      retorno => {

        this.listaProdutoGrupo = retorno.total > 0 ? retorno.result : [];

        this.spinner.hide();
      }, 
      err => {
        this.spinner.hide();

        this.alert.Danger('Erro', Uteis.ObterErroApi(err));
      });
  }

  produtoGrupoSelecionado(item: ProdutoGrupoPesquisaViewModel) {
    this.produtoGrupo.emit(item);
  }

  closeModalProdutoGrupo() {
    this.closeModal.emit(false);
  }

}
