<div class="row">
    <div class="row col-10">
        <div class="col-3">
            <div class="card mb-4">
                <div class="card-body">
                    <h5 class="card-title">Empresas cadastradas - {{ totalEmpresas?.totalEmpresas }}</h5>
                    <p class="card-text"></p>
                </div>
                <div class="text-right mr-3">
                    <p><a class="btn btn-outline-secondary btn-sm" [routerLink]="['cadastro/empresa']" role="button">Cadastrar Nova Empresa</a></p>
                </div>
            </div>
        </div>
        <!-- <div class="col-3">
            <div class="card text-white bg-secondary mb-4">
                <div class="card-body">
                    <h5 class="card-title">Grupos Multiloja cadastradas</h5>
                    <p class="card-text"></p>
                </div>
                <div class="text-right mr-3">
                    <p><a class="btn btn-outline-light btn-sm" [routerLink]="['cadastro/grupo-multiloja']" role="button">Cadastro Grupo Multiloja</a></p>
                </div>
            </div>
        </div> -->
        <div class="col-3">
            <div class="card mb-4">
                <div class="card-body">
                    <h5 class="card-title">Usuários cadastrados - {{ totalUsuarios?.totalUsuarios }}</h5>
                    <p class="card-text"></p>
                </div>
                <div class="text-right mr-3">
                    <p><a class="btn btn-outline-secondary btn-sm" [routerLink]="['cadastro/usuario']" role="button">Cadastro Usuario</a></p>
                </div>
            </div>
        </div>
    </div>
</div>