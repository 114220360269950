<footer class="footer">

    <div class="row zoom mr-0 ml-2 justify-content-between">
        <div class="col-xs-12 mt-3 " id="nome_usuario_menu">
            <span class="text-usuario-logado">{{ this.usuarioLogado == undefined ? '' : this.usuarioLogado }} </span>
        </div>

        <div class="col-xs-12 mt-2 mb-2">
            <select id="listaLoja" class="form-control form-control-sm" placeholder="lista de loja" autocomplete="off"
                (change)="alterarLoja()" [(ngModel)]="pesquisa.id" style="min-width: 250px;">
                <option *ngFor="let item of listaLoja.itens" [ngValue]="item.id">{{ item.emp_apelido }}</option>
            </select>
        </div>

    </div>

</footer>