import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';
import { AlertMensagemPadrao } from './utilizarios/alertMensagemPadrao';

@Injectable({ providedIn: 'root' })
export class AlertMessage{
    private configuracaoPadrao:any = {
        closeButton: true,
        timeOut:5000,
        extendedTimeOut:5000,
        enableHtml: true,
        progressBar: true,
        progressAnimation: 'decreasing',
        preventDuplicates: true,
        countDuplicates: true,
        resetTimeoutOnDuplicate: true,
        positionClass: 'toast-bottom-right'
    }

    constructor(private toastr: ToastrService){

    }

    Success(titulo: string, texto: string, configuracao: any = {}){
        this.toastr.success(texto, titulo, { ...this.configuracaoPadrao, ...configuracao});
    }
    Info(titulo: string, texto: string, configuracao: any = {}){
        this.toastr.info(texto, titulo, { ...this.configuracaoPadrao, ...configuracao});
    }
    Warning(titulo: string, texto: string, configuracao: any = {}){
        this.toastr.warning(texto, titulo, { ...this.configuracaoPadrao, ...configuracao});
    }
    Danger(titulo: string, texto: string, configuracao: any = {}){
        this.toastr.error(texto, titulo, { ...this.configuracaoPadrao, ...configuracao});
    }

    SuccessPadrao(titulo: AlertMensagemPadrao, configuracao: any = {}){
        this.toastr.success(titulo.mensagem, titulo.titulo, { ...this.configuracaoPadrao, ...configuracao});
    }
}