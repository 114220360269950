<div class="form-group">
    <label for="grupo_produto" class="col-form-label-sm">Grupo</label>
    <p-multiSelect inputId="grupo_produto"
                  defaultLabel="-Selecione-" 
                  optionLabel="descricao" 
                  [showHeader]="true" 
                  [options]="listaGrupos" 
                  [emptyFilterMessage]="'Não encontrado'"
                  [selectedItemsLabel]="'Grupos Selecionados {0}'" 
                  [(ngModel)]="grupos" 
                  (onPanelHide)="enviar()">
    </p-multiSelect>
</div>