<p-dialog [(visible)]="abrirFecharProdutoLoteCadastroModal" [modal]="true" [closable]="true" [style]="{width: '70vw'}" [closable]="false"
    [maximizable]="false" [baseZIndex]="10000" [draggable]="false" [resizable]="false" (onHide)="closeModalCadastroProdutoLote()">
    <ngx-spinner name="modalAtualiza" bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-clip-rotate"
        [fullScreen]="false"></ngx-spinner>
    <p-header>Produto Lote - {{ alterar ? "Editar" : "Cadastrar"}}</p-header>


    <form (ngSubmit)="onSubmit()">
        <div class="d-flex justify-content-end">
            <div class="btns-group mb-1">
                <button class="btn btn-sm btn-outline-secondary" (click)="closeModalCadastroProdutoLote()"><i
                    class="material-icons">close</i>Cancelar</button>
                <button class="btn btn-sm btn-outline-success" id="salvar" type="submit" [disabled]="carregando">
                    <div [ngSwitch]="carregando">
                        <div *ngSwitchCase="true">
                            <span class="spinner-border spinner-border-sm" role="status"
                                aria-hidden="true"></span>&nbsp;
                            <span>Carregando ...</span>
                        </div>
                        <div *ngSwitchDefault>
                            <i class="material-icons">done_all</i><span>Salvar</span>
                        </div>
                    </div>
                </button>
            </div>
        </div>
        <div class="card rounded-0" [formGroup]="cadastroForm">
            <div class="card-body pt-1 pb-1">
                <div class="row">
                    <div class="col-sm-4 col-md-4 col-lg-2 col-xl-2" >
                        <div class="form-group">
                            <label for="lote" class="col-form-label-sm">Lote</label>
                            <input type="text" id="lote" name="lote" class="form-control form-control-sm noscroll"
                                placeholder="Lote" 
                                [ngClass]="{'is-invalid': displayMessage?.lote}"
                                formControlName="lote">
                                <span class="text-danger" *ngIf="displayMessage?.lote">
                                    <p [innerHTML]="displayMessage?.lote"></p>
                                </span>
                        </div>
                    </div>
                    <div class="col-sm-4 col-md-4 col-lg-2 col-xl-1" >
                        <div class="form-group">
                            <label for="estoque" class="col-form-label-sm">Estoque</label>
                            <input type="number" id="lote" name="estoque" class="form-control form-control-sm noscroll"
                                placeholder="0" 
                                [ngClass]="{'is-invalid': displayMessage?.estoque}"
                                formControlName="estoque">
                                <span class="text-danger" *ngIf="displayMessage?.estoque">
                                    <p [innerHTML]="displayMessage?.estoque"></p>
                                </span>
                        </div>
                    </div>
                    <div class="col-sm-4 col-md-3 col-lg-3 col-xl-2">
                        <div class="form-group">
                            <label for="dt_fabricacao" class="col-form-label-sm">Data Fabricação</label>
                            <input type="date" id="dt_fabricacao" name="dt_fabricacao"
                                class="form-control form-control-sm" formControlName="dt_fabricacao" autocomplete="off"
                                pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}" step="-3" >
                            <span class="invalid-feedback" *ngIf="displayMessage.dt_fabricacao">
                                <p [innerHTML]="displayMessage.dt_fabricacao"></p>
                            </span>
                        </div>
                    </div>
                    <div class="col-sm-4 col-md-3 col-lg-3 col-xl-2" >
                        <div class="form-group">
                            <label for="dt_vencimento" class="col-form-label-sm">Data Vencimento</label>
                            <input type="date" id="dt_vencimento" name="dt_vencimento"
                                class="form-control form-control-sm" formControlName="dt_vencimento" autocomplete="off"
                                pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}" step="-3">
                            <span class="invalid-feedback" *ngIf="displayMessage.dt_vencimento">
                                <p [innerHTML]="displayMessage.dt_vencimento"></p>
                            </span>
                        </div>
                    </div>
                    <div class="col-sm-4 col-md-4 col-lg-2 col-xl-1" >
                        <div class="form-group">
                            <label for="vlr_custo" class="col-form-label-sm">Vlr Custo</label>
                            <input type="number" id="vlr_custo" name="vlr_custo" class="form-control form-control-sm noscroll"
                                placeholder="0,00" 
                                [ngClass]="{'is-invalid': displayMessage?.vlr_custo}"
                                formControlName="vlr_custo">
                                <span class="text-danger" *ngIf="displayMessage?.vlr_custo">
                                    <p [innerHTML]="displayMessage?.vlr_custo"></p>
                                </span>
                        </div>
                    </div><div class="col-sm-4 col-md-4 col-lg-2 col-xl-1" >
                        <div class="form-group">
                            <label for="prateleira" class="col-form-label-sm">Prateleira</label>
                            <input type="text" id="prateleira" name="prateleira" class="form-control form-control-sm noscroll"
                                placeholder="Prateleira" 
                                [ngClass]="{'is-invalid': displayMessage?.prateleira}"
                                formControlName="prateleira">
                                <span class="text-danger" *ngIf="displayMessage?.prateleira">
                                    <p [innerHTML]="displayMessage?.prateleira"></p>
                                </span>
                        </div>
                    </div>
                    <div class="col-sm-4 col-md-4 col-lg-2 col-xl-1" *ngIf="alterar">
                        <div class="form-group">
                            <div class="custom-control custom-checkbox">
                                <input type="checkbox" id="produto_lote_modal_ativo" name="produto_lote_modal_ativo"
                                       class="custom-control-input form-control-sm" formControlName="ativo"/>
                                <label class="custom-control-label col-form-label-sm status-filtro"
                                       for="produto_lote_modal_ativo">Ativo</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</p-dialog>
