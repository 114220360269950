export class VendaViewModel {
    id?: number;
    emp_id?: number;
    cliente_id?: number;
    cliente_nome?: string;
    cliente_alterar_nome?: boolean;
    cliente_obs_venda?: string;
    cliente_cpf_cnpj?: string;
    tipo_preco_venda?: number;
    tipo?: 'VE' | 'OR' | 'OS' | 'DV' | 'CO' | 'CP' | 'TE';
    atendente_id?: number;
    atendente_nome?: string;
    status?: 'A' | 'B' | 'C' | 'D' | 'E' | 'F' | 'G' | 'H' | 'I' | 'J' | 'K' | 'L' | 'M' | 'N' | 'O' | 'P' | 'R' | 'Q' | 'S' | 'T' | 'U' | 'V' | 'W' | 'X' | 'Y' | 'Z' ;
    obs?: string;
    obs_cancel?: string;
    dt_abertura?: Date;
    dt_fechamento?: Date;
    dt_previsao_chegada?: string;
    usu_cancel_id?: number;
    dt_cancel?: Date;
    msg?: string;
    obs_entrega?: string;
    acompanhamento?: string;
    operacao_id?: number;
    operacao_descricao?: string;
    operacao_altera_vlr?: boolean
    operacao_usa_preco_custo?: boolean
    consumidor_final?: number;
    produto_tabela_preco_id?: number;
    os_equipamento?: string;
    os_marca?: string;
    os_serie?: string;
    os_tecnico_id?: number;
    os_complemento?: string;
    os_defeito?: string;
    os_obs?: string;
    os_laudo_tecnico?: string;
    devolucao_origem_venda_id?: number;
    devolucao_origem_nf_id?: number;
    devolucao_troca_venda_id?: number;
    veiculo_id?: number;
    veiculo_placa?: string;
    veiculo_chassi?: string;
    veiculo_marca?: string;
    veiculo_modelo?: string;
    veiculo_ano_fab?: number;
    veiculo_ano_mod?: number;
    veiculo_cor?: string;
    equipamento_id?: number;
    equipamento_descricao?: string;
    equipamento_codigo?: string;
    numero_pedido?: number;
    total_c_desc?: number;
    total_s_desc?: number;
    dt_producao?: Date;
    venda_ref_venda_id?: number;
    peso?: number;
    retirada_entrega_tipo?: 'E' | 'R' | undefined;
    retirada_entrega_cliente_id?: number;
    retirada_entrega_cliente_endereco_id?: number;
    retirada_entrega_cpf_cnpj?: string;
    retirada_entrega_ie?: string;
    retirada_entrega_nome?: string;
    retirada_entrega_endereco?: string;
    retirada_entrega_numero?: string;
    retirada_entrega_bairro?: string;
    retirada_entrega_complemento?: string;
    retirada_entrega_municipio_descricao?: string;
    retirada_entrega_municipio_codigo_ibge?: number;
    retirada_entrega_uf_sigla?: string;
    retirada_entrega_cep?: number;
    retirada_entrega_codigo_pais?: number;
    retirada_entrega_fone?: string;
    retirada_entrega_email?: string;
    nf_dados_adicionais?: string;
    vendaTipo?: string;
    cliente_tipo?: 0 | 1;
    emp_destino_id?: number;
    vlr_frete?:number ;
    vlr_seguro?:number ;
    vlr_outras_desp?: number;
    usa_atacado?: boolean;
}
// Tipo 
// VE = Venda 
// OS = Ordem de serviço 
// OR = Orçamento 
// CO = Pedido de compra
// CP = Controle de Produção

// Status
// A = Em aberto
// B = Pendente de Conferência
// C = Cancelada
// D = Farmacia Popular
// E = Exportada para Max_PDV_PAF
// F = Concluida
// G = Comanda Agrupada
// H = Controle de Produção Entrada (Tela 141)
// I = Comanda em andamento
// J = Controle de Produção Saida (Tela 141)
// K = Controle de Produção Entrada Cancelada (Tela 141)
// L = Venda concluída (recebida) e Pendente de Conferência
// M = Reservado (OS Fernando)
// N = Controle de Produção Entrada Em aberto (Tela 141)
// O = Pedido de compra em aberto
// P = Pendente na Entrada
// Q = Pedido de compra concluido
// S = Vai para Supervisor de Vendas
// X = Aparece no Caixa
// Y = Exportada para Tabela de NF (entrada)
// Z = Pedido de compra cancelado
function RetiraMascara(ObjCPF) {

    return ObjCPF.value.replace(/\D/g, '');
}