import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URLBASE, URLIMPRESSAO } from 'src/modules/configuration.service';
import { AutenticacaoService } from 'src/modules/autenticacao/services/autenticacao.service';
import { PesquisaGeralViewModel } from '@modules/admin/view-models/pesquisa/PesquisaGeralViewModel';
import { Observable } from 'rxjs';
import { CadastroViewModel } from 'src/modules/admin/view-models/cadastro/CadastroViewModel';
import { VendaViewModel } from '../../view-models/movimentacao/venda-view-model';
import { RetornoApiViewModel } from '../../view-models/RetornoApiViewModel';
import { VendaPesquisaViewModel } from '../../view-models/movimentacao/pesquisa/venda-pesquisa-view-model';
import { GerarDevolucaoViewModel } from '@modules/manager/view-models/movimentacao/gerar-devolucao-view-model';
import { RetornoSucesso } from '@modules/manager/view-models/RetornoSucesso';
import { concatMap } from 'rxjs/operators';
import { ClienteService } from '@modules/manager/cadastro/cliente-fornecedor/cliente/cliente.service';
import { ClienteEnderecoService } from '@modules/manager/cadastro/cliente-fornecedor/cliente-endereco/cliente-endereco.service';
import { ConfiguracaoService } from '@modules/manager/sistema/configuracao/configuracao.service';
import { ApiRelatorioViewModel, RelatorioViewModel } from '@modules/manager/view-models/RelatorioViewModel';
import {
  RelatorioDavOsObjViewModel,
  RelatorioDavOsParamsViewModel
} from './../../view-models/movimentacao/pesquisa/relatorio-dav-os-view-model';
import { ClienteEnderecoPesquisaViewModel } from '@modules/manager/view-models/cadastro/cliente-fornecedor/pesquisa/cliente-endereco-pesquisa-view-model';
import { CadastroArrayViewModel } from "@modules/admin/view-models/cadastro/CadastroArrayViewModel";
import { DatePipe } from "@angular/common";
import { Uteis } from '@modules/utilizarios/uteis';
import { ContasReceberPagarService } from '@modules/manager/financeiro/contas-receber-pagar/contas-receber-pagar.service';
import { ContasReceberPagarPesquisaViewModel } from '@modules/manager/view-models/financeiro/contas-receber-pagar/pesquisa/contas-receber-pagar-pesquisa-view-model';

@Injectable({
  providedIn: 'root'
})
export class VendaService {
  idEmpresaMaxdata = this.autenticacao$.acessoUsuarioValue?.idEmpresaMaxdata;
  urlVenda = `${URLBASE}/${this.idEmpresaMaxdata}/venda_service`;
  urlImpressaoRel = `${URLIMPRESSAO}/api/relatorio`;
  pesquisaClienteEndereco: ClienteEnderecoPesquisaViewModel = new ClienteEnderecoPesquisaViewModel();
  urlConverVendaOrcamento = `${this.urlVenda}/GerarOrcamento`;
  urlAddUpdateArray = `${this.urlVenda}/AddUpdateArray`;

  constructor(private http: HttpClient,
    private autenticacao$: AutenticacaoService,
    private cliente$: ClienteService,
    private contasReceberPagarService$: ContasReceberPagarService,
    private clienteEndereco$: ClienteEnderecoService,
    private configuracao$: ConfiguracaoService,
    public datepipe: DatePipe) {
  }

  obterTodos(pesquisa: VendaPesquisaViewModel): Observable<RetornoApiViewModel<VendaPesquisaViewModel[]>> {

    let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel();

    if (pesquisa?.tipo) pesquisaGeral.where += ` and venda.tipo = '${pesquisa?.tipo}'`;
    if (pesquisa?.status) pesquisaGeral.where += ` and venda.status = '${pesquisa?.status}'`;
    if (pesquisa?.id) pesquisaGeral.where += ` and venda.id = '${pesquisa?.id}'`;

    return this.http.post<RetornoApiViewModel<VendaPesquisaViewModel[]>>(`${this.urlVenda}/get`, pesquisaGeral)
  }

  obterTodosGroup(pesquisa: VendaPesquisaViewModel): Observable<RetornoApiViewModel<VendaPesquisaViewModel[]>> {

    let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel()

    pesquisaGeral.select = ` venda.Id
                            , venda.tipo
                            , venda.cliente_id
                            ,  COALESCE(NULLIF(venda.cliente_nome, ''), cliente.nome) AS cliente_nome
                            , cliente.fantasia AS cliente_fantasia
                            , cliente.fone AS cliente_fone
                            , venda.dt_abertura
                            , venda.dt_fechamento
                            , venda.dt_producao
                            , venda.numero_pedido 
                            , TO_CHAR(venda.dt_abertura, 'DD/MM/YYYY') as dt_abertura_formatado
                            , TO_CHAR(venda.dt_fechamento, 'DD/MM/YYYY') as dt_fechamento_formatado
                            , TO_CHAR(venda.dt_previsao_chegada, 'DD/MM/YYYY') as dt_previsao_chegada
                            , venda.dt_cancel
                            , venda.status
                            , (
                                SELECT 
                                  COUNT(nf_venda_importada.id) 
                                FROM nf_venda_importada 
                                  INNER JOIN nf ON nf.id = nf_venda_importada.nf_id
                                WHERE nf_venda_importada.venda_id = venda.id
                                  AND nf.tipo = 10
                               ) as nfce
                            , (
                                SELECT 
                                  COUNT(nf_venda_importada.id) 
                                FROM nf_venda_importada 
                                  INNER JOIN nf ON nf.id = nf_venda_importada.nf_id
                                WHERE nf_venda_importada.venda_id = venda.id
                                  AND nf.tipo = 3
                              ) as nfe    
                            , SUM(
                                CASE WHEN 
                                  venda_item.status = 'C' or produto.tipo <> 'P'
                                THEN 
                                  0 
                                ELSE 
                                 venda_item.vlr_bc_icms 
                                END
                              ) as vlr_bc_icms
                            , SUM(
                                CASE WHEN 
                                  venda_item.status = 'C' or produto.tipo <> 'P'
                                THEN 
                                  0 
                                ELSE 
                                  venda_item.vlr_icms 
                                END
                              )as vlr_icms
                            , SUM(
                                CASE WHEN 
                                  venda_item.status = 'C' or produto.tipo <> 'P'
                                THEN 
                                  0 
                                ELSE 
                                  venda_item.vlr_bc_subst 
                                END
                              ) as vlr_bc_icms_subst
                            , SUM(
                                CASE WHEN 
                                  venda_item.status = 'C' or produto.tipo <> 'P'
                                THEN 
                                  0 
                                ELSE 
                                  venda_item.vlr_subst 
                                END
                              ) as vlr_icms_subst
                            , SUM(
                                CASE WHEN 
                                  venda_item.status = 'C' or produto.tipo <> 'P'
                                THEN 
                                  0 
                                ELSE 
                                  venda_item.vlr_ipi 
                                END
                              ) as vlr_ipi
                            , SUM(
                                CASE WHEN 
                                  venda_item.status = 'C' 
                                THEN 
                                  0 
                                ELSE 
                                  venda_item.vlr_frete 
                                END
                              ) as vlr_frete
                            , SUM(
                                CASE WHEN 
                                  venda_item.status = 'C'
                                THEN 
                                  0 
                                ELSE 
                                  venda_item.vlr_seguro 
                                END
                              ) as vlr_seguro
                            , SUM(
                                CASE WHEN 
                                  venda_item.status = 'C'
                                THEN 
                                  0 
                                ELSE 
                                  venda_item.vlr_outras_desp 
                                END
                              ) as vlr_outras_desp
                            , SUM(
                                CASE WHEN 
                                  venda_item.status = 'C'
                                THEN 
                                  0 
                                ELSE 
                                  COALESCE(venda_item.vlr_un_com,0) * COALESCE(venda_item.qtd_com, 0)
                                END
                              ) as total_s_desc
                            , SUM(
                                CASE WHEN 
                                  venda_item.status = 'C' or produto.tipo <> 'P'
                                THEN 
                                  0 
                                ELSE 
                                  COALESCE(venda_item.vlr_un_com,0) * COALESCE(venda_item.qtd_com, 0)
                                END
                              ) as total_produto_s_desc
                            , SUM(
                                CASE WHEN 
                                  venda_item.status = 'C' or produto.tipo <> 'S'
                                THEN 
                                  0 
                                ELSE 
                                  COALESCE(venda_item.vlr_un_com,0) * COALESCE(venda_item.qtd_com, 0)
                                END
                              ) as total_servico_s_desc 
                            , SUM(
                                CASE WHEN 
                                  venda_item.status = 'C' 
                                THEN 
                                  0 
                                ELSE 
                                  COALESCE(venda_item.qtd_com,0) 
                                  * COALESCE(venda_item.vlr_un_com,0) 
                                  * (COALESCE(venda_item.aliq_desconto,0) / 100)
                                END
                              ) as vlr_desc
                            , CASE WHEN (
                                SUM(
                                    venda_item.qtd_com * venda_item.vlr_un_com)) <> 0 
                                  THEN
                                    (
                                      (
                                        (
                                          (SUM(venda_item.qtd_com * venda_item.vlr_un_com)) 
                                          - (SUM((venda_item.vlr_un_com - venda_item.vlr_un_com  * venda_item.aliq_desconto ) * venda_item.qtd_com))
                                        )  / 100)  / (SUM(venda_item.qtd_com * venda_item.vlr_un_com))
                                      ) * 100
                                  ELSE
                                      0 
                                  end
                                as aliq_desc
                                ,SUM(round(CAST((CASE WHEN venda_item.status = 'C' or produto.tipo <> 'P' THEN 0 ELSE COALESCE(venda_item.vlr_icms_subst,0)END)
                                  + (CASE WHEN venda_item.status = 'C' or produto.tipo <> 'P' THEN 0 ELSE COALESCE(venda_item.vlr_frete,0)END)
                                  + (CASE WHEN venda_item.status = 'C' or produto.tipo <> 'P' THEN 0 ELSE COALESCE(venda_item.vlr_seguro,0)END)
                                  + (CASE WHEN venda_item.status = 'C' or produto.tipo <> 'P' THEN 0 ELSE COALESCE(venda_item.vlr_outras_desp,0)END)
                                  + (CASE WHEN venda_item.status = 'C' or produto.tipo <> 'P' THEN 0 ELSE COALESCE(venda_item.vlr_ipi,0)END)
                                  + (CASE WHEN venda_item.status = 'C' or produto.tipo <> 'P' THEN 0 ELSE COALESCE(venda_item.qtd_com,0) * COALESCE(venda_item.vlr_un_com,0)END)
                                  - (CASE WHEN venda_item.status = 'C' or produto.tipo <> 'P' THEN 0 ELSE COALESCE(venda_item.vlr_un_com,0) * COALESCE(venda_item.qtd_com, 0) * (COALESCE(venda_item.aliq_desconto,0) / 100)END) as numeric), 2)) as total_produto_c_desc
                               , SUM(round(CAST((CASE WHEN venda_item.status = 'C' or produto.tipo <> 'P' THEN 0 ELSE COALESCE(venda_item.vlr_icms_subst,0)END)
                                  + (CASE WHEN venda_item.status = 'C' THEN 0 ELSE COALESCE(venda_item.vlr_frete,0)END)
                                  + (CASE WHEN venda_item.status = 'C' THEN 0 ELSE COALESCE(venda_item.vlr_seguro,0)END)
                                  + (CASE WHEN venda_item.status = 'C' THEN 0 ELSE COALESCE(venda_item.vlr_outras_desp,0)END)
                                  + (CASE WHEN venda_item.status = 'C' or produto.tipo <> 'P' THEN 0 ELSE COALESCE(venda_item.vlr_ipi,0)END)
                                  + (CASE WHEN venda_item.status = 'C' THEN 0 ELSE COALESCE(venda_item.qtd_com,0) * COALESCE(venda_item.vlr_un_com,0)END)
                                  - (CASE WHEN venda_item.status = 'C' THEN 0 ELSE (COALESCE(venda_item.qtd_com, 0) * COALESCE(venda_item.vlr_un_com,0)) * (COALESCE(venda_item.aliq_desconto,0) / 100)END) as numeric), 4)) as total_c_desc
                            , usuario.nome AS vendedor_nome
                            ,operacao.tipo_opr 
                            , operacao.descricao AS operacao_descricao
                            , COALESCE(produto_tabela_preco.id, 0) AS produto_tabela_preco_id
                            , COALESCE(produto_tabela_preco.descricao, '') AS produto_tabela_preco_descricao`;

    pesquisaGeral.group = ` venda.Id
                            , venda.tipo
                            , venda.cliente_id
                            , venda.status
                            , cliente.nome
                            , cliente.fantasia
                            , cliente.fone                            
                            , venda.dt_abertura
                            , venda.dt_fechamento
                            , venda.dt_cancel
                            , venda.dt_producao
                            , usuario.nome
                            , operacao.descricao
                            , operacao.tipo_opr
                            , COALESCE(produto_tabela_preco.id, 0)
                            , COALESCE(produto_tabela_preco.descricao, '')`;

    pesquisaGeral.order = pesquisa?.status == 'A' ? 'venda.dt_abertura desc' : pesquisa?.status == 'F' ? 'venda.dt_fechamento desc' : 'venda.dt_cancel desc'

    pesquisaGeral.start = pesquisa?.start || 0;

    pesquisaGeral.limit = pesquisa.quantidadeRegistrosPagina;

    pesquisaGeral.where = ` AND venda.status = '${pesquisa?.status || 'A'}' ${pesquisa?.tipo ? `and venda.tipo = '${pesquisa?.tipo}'` : ''}`;

    if ((pesquisa?.id || 0) > 0) pesquisaGeral.where += ' AND venda.id = ' + pesquisa?.id;

    if ((pesquisa?.numero_pedido || 0) > 0) pesquisaGeral.where += ' AND venda.numero_pedido = ' + pesquisa?.numero_pedido;

    if ((pesquisa?.cliente_id || 0) > 0) pesquisaGeral.where += ' AND venda.cliente_id = ' + pesquisa?.cliente_id;

    if ((pesquisa?.cliente_nome || '') !== '') pesquisaGeral.where += `AND (LOWER(cliente.nome) LIKE LOWER('%` + pesquisa?.cliente_nome + `%') OR LOWER(cliente.fantasia) LIKE LOWER('%` + pesquisa?.cliente_nome + `%'))`;

    if ((pesquisa?.atendente_id || 0) > 0) pesquisaGeral.where += ' AND venda.atendente_id = ' + pesquisa?.atendente_id;

    if (pesquisa?.operacao_id) pesquisaGeral.where += ` and venda.operacao_id = ` + pesquisa?.operacao_id;

    if (pesquisa?.produto_tabela_preco_id && pesquisa?.produto_tabela_preco_id > 0) pesquisaGeral.where += ` and venda.produto_tabela_preco_id = ` + pesquisa?.produto_tabela_preco_id;

    if (pesquisa?.data_inicial && pesquisa?.data_final) pesquisaGeral.where += `${pesquisa?.filtro_data === 'A' ? ' and venda.dt_abertura' : ' and venda.dt_fechamento'} between '${pesquisa?.data_inicial}T00:00:00' and '${pesquisa?.data_final}T23:59:59'`;

    if (pesquisa?.dt_abertura_inicial && pesquisa?.dt_abertura_final) pesquisaGeral.where += ` and venda.dt_abertura between '${pesquisa?.dt_abertura_inicial}T00:00:00' and '${pesquisa?.dt_abertura_final}T23:59:59'`;

    if (pesquisa?.placa) pesquisaGeral.where += ` and veiculo.placa like UPPER('${pesquisa?.placa}%')`;

    if (pesquisa?.status == "X") pesquisaGeral.order = pesquisa?.order ? pesquisa?.order : 'venda.dt_abertura desc';

    if (pesquisa?.tipo_opr == 0) pesquisaGeral.where += ` and operacao.tipo_opr = 0`
    else if (pesquisa?.tipo_opr == null) { }
    else { pesquisaGeral.where += ` and operacao.tipo_opr = ${pesquisa?.tipo_opr}` }

    return this.http.post<RetornoApiViewModel<VendaPesquisaViewModel[]>>(`${this.urlVenda}/getGroup`, pesquisaGeral)

  }

  obterListaItem(venda_id: number, produto_tipo?: string): Observable<RetornoApiViewModel<VendaPesquisaViewModel[]>> {

    let pesquisa: PesquisaGeralViewModel = new PesquisaGeralViewModel();

    pesquisa.select = `venda_item.id,
                          venda_item.venda_id,
                          venda_item.produto_id,                          
                          COALESCE(NULLIF(venda_item.produto_descricao, ''), produto.descricao) AS produto_descricao,
                          venda_item.qtd,                          
                          venda_item.un,
                          venda_item.vlr_un,
                          venda_item.qtd_com,                         
                          venda_item.un_com,                          
                          venda_item.vlr_un_cadastro,
                          venda_item.vlr_un_cadastro_atacado,
                          venda_item.vlr_custo_final,
                          venda_item.aliq_desconto,
                          venda_item.cst,
                          venda_item.csosn,
                          venda_item.status,
                          venda_item.dt_lancamento,
                          venda_item.aliq_comissao,
                          venda_item.lote,
                          venda_item.inf_ad_prod,
                          venda_item.cfop,
                          venda_item.vlr_bc_icms,
                          venda_item.vlr_icms,
                          venda_item.aliq_icms,
                          venda_item.vlr_bc_ipi,
                          venda_item.vlr_ipi,
                          venda_item.aliq_ipi,
                          venda_item.vlr_frete,
                          venda_item.vlr_seguro,
                          venda_item.vlr_outras_desp,
                          venda_item.tributacao_id,
                          venda_item.aliq_reducao,
                          venda_item.aliq_reducao_st,
                          venda_item.aliq_diferimento,
                          venda_item.aliq_iva,
                          venda_item.aliq_icms_st,
                          venda_item.vlr_bc_icms_subst,
                          venda_item.vlr_icms_subst,
                          venda_item.aliq_partilha_icms,
                          venda_item.aliq_icms_inter,
                          venda_item.aliq_icms_inter_part,
                          venda_item.aliq_icms_uf_dest,
                          venda_item.vlr_bc_icms_uf_dest,
                          venda_item.vlr_icms_uf_dest,
                          venda_item.vlr_icms_uf_remet,
                          venda_item.aliq_fcp_uf_dest,
                          venda_item.vlr_fcp_uf_dest,
                          venda_item.aliq_fcp_st,
                          venda_item.vlr_bc_fcp_st,
                          venda_item.vlr_fcp_st,
                          venda_item.aliq_sn,
                          venda_item.vlr_bc_icms_sn,
                          venda_item.vlr_icms_sn,
                          venda_item.cst_ipi,
                          venda_item.aliq_carga_media_icms_st,
                          venda_item.vlr_icms_op,
                          venda_item.vlr_icms_dif,
                          venda_item.cst_pis,
                          venda_item.aliq_pis,
                          venda_item.vlr_bc_pis,
                          venda_item.vlr_pis,
                          venda_item.cst_cofins,
                          venda_item.aliq_cofins,
                          venda_item.vlr_bc_cofins,
                          venda_item.vlr_cofins,
                          venda_item.vlr_bc_subst,
                          venda_item.vlr_subst,
                          venda_item.aliq_st,
                          venda_item.aliq_issqn,
                          venda_item.vlr_bc_issqn,
                          venda_item.vlr_issqn,
                          venda_item.aliq_ii,
                          venda_item.vlr_bc_ii,
                          venda_item.vlr_ii,
                          venda_item.codigo_ncm,
                          venda_item.vlr_pmc,
                          venda_item.aliq_ret_pis,
                          venda_item.vlr_ret_pis,
                          venda_item.aliq_ret_cofins,
                          venda_item.vlr_ret_cofins,
                          venda_item.aliq_ret_csll,
                          venda_item.vlr_ret_csll,
                          venda_item.aliq_bc_irrf,
                          venda_item.vlr_bc_irrf,
                          venda_item.aliq_irrf,
                          venda_item.vlr_irrf,
                          venda_item.aliq_bc_ret_prev,
                          venda_item.vlr_bc_ret_prev,
                          venda_item.aliq_ret_prev,
                          venda_item.vlr_ret_prev,
                          venda_item.vlr_bc_ret_csll,
                          venda_item.aliq_desconto_red_icms,
                          venda_item.vlr_icms_deson,
                          venda_item.tipo_icms_deson_motivo,
                          venda_item.qtd_origem,
                          venda_item.chk_atualizou_estoque_movimento,                    
                    produto_fabricante.descricao as produto_fabricante_descricao,
                    produto_empresa.qtd_estoque_atual, 
                    produto_empresa.aliq_desconto_max,
                    produto_empresa.usa_desconto,
                    produto.peso,                    
                    usuario.nome as usuario_nome,
                    venda_item.os_tecnico_id,
                    (select usuario.nome from usuario where usuario.id = venda_item.os_tecnico_id) as os_tecnico_nome,
                    round(COALESCE(venda_item.vlr_un_com,0),4) as vlr_un_com,
                    round(COALESCE(venda_item.qtd_com, 0) * COALESCE(venda_item.vlr_un_com,0),4) as total_s_desc,
                    round(COALESCE(venda_item.qtd_com, 0) * COALESCE(venda_item.vlr_un_com,0) - (COALESCE(venda_item.qtd_com, 0) * COALESCE(venda_item.vlr_un_com,0)) * (CAST(COALESCE(venda_item.aliq_desconto,0) AS numeric) / 100),4) as total_c_desc,
                    round((COALESCE(venda_item.qtd_com, 0) * COALESCE(venda_item.vlr_un_com,0)) * (CAST(COALESCE(venda_item.aliq_desconto,0) AS numeric) / 100),4) as vlr_desc,
                    venda_item.produto_grade_id,
                    (
                      select produto_grade_descricao.descricao 
                      from produto_grade, produto_grade_descricao
                      where produto_grade.id = venda_item.produto_grade_id and produto_grade_descricao.id = produto_grade.produto_grade_descricao_id
                    ) as produto_grade_descricao,
                    (
                      select produto_grade_tamanho.tamanho
                      from produto_grade, produto_grade_tamanho
                      where produto_grade.id = venda_item.produto_grade_id and produto_grade_tamanho.id = produto_grade.produto_grade_tamanho_id
                    ) as produto_grade_tamanho,
                    venda_item.produto_embalagem_venda_id
                    `
    pesquisa.where = ` and venda_item.venda_id = ${venda_id} and venda_item.status = '' ${produto_tipo ? `and produto.tipo = '${produto_tipo}'` : ''}`
    pesquisa.order = ' venda_item.id asc'

    pesquisa.limit = 0;

    pesquisa.group = `venda_item.id,
                    venda_item.venda_id,
                    venda_item.produto_id,
                    venda_item.qtd,
                    venda_item.un,
                    venda_item.vlr_un,
                    venda_item.qtd_com,
                    venda_item.un_com,
                    venda_item.vlr_un_com,
                    venda_item.vlr_un_cadastro,
                    venda_item.vlr_un_cadastro_atacado,
                    venda_item.vlr_custo_final,
                    venda_item.aliq_desconto,
                    venda_item.cst,
                    venda_item.csosn,
                    venda_item.status,
                    venda_item.dt_lancamento,
                    venda_item.aliq_comissao,
                    venda_item.lote,
                    venda_item.inf_ad_prod,
                    venda_item.cfop,
                    venda_item.vlr_bc_icms,
                    venda_item.vlr_icms,
                    venda_item.aliq_icms,
                    venda_item.vlr_bc_ipi,
                    venda_item.vlr_ipi,
                    venda_item.aliq_ipi,
                    venda_item.vlr_frete,
                    venda_item.vlr_seguro,
                    venda_item.vlr_outras_desp,
                    venda_item.tributacao_id,
                    venda_item.aliq_reducao,
                    venda_item.aliq_reducao_st,
                    venda_item.aliq_diferimento,
                    venda_item.aliq_iva,
                    venda_item.aliq_icms_st,
                    venda_item.vlr_bc_icms_subst,
                    venda_item.vlr_icms_subst,
                    venda_item.aliq_partilha_icms,
                    venda_item.aliq_icms_inter,
                    venda_item.aliq_icms_inter_part,
                    venda_item.aliq_icms_uf_dest,
                    venda_item.vlr_bc_icms_uf_dest,
                    venda_item.vlr_icms_uf_dest,
                    venda_item.vlr_icms_uf_remet,
                    venda_item.aliq_fcp_uf_dest,
                    venda_item.vlr_fcp_uf_dest,
                    venda_item.aliq_fcp_st,
                    venda_item.vlr_bc_fcp_st,
                    venda_item.vlr_fcp_st,
                    venda_item.aliq_sn,
                    venda_item.vlr_bc_icms_sn,
                    venda_item.vlr_icms_sn,
                    venda_item.cst_ipi,
                    venda_item.aliq_carga_media_icms_st,
                    venda_item.vlr_icms_op,
                    venda_item.vlr_icms_dif,
                    venda_item.cst_pis,
                    venda_item.aliq_pis,
                    venda_item.vlr_bc_pis,
                    venda_item.vlr_pis,
                    venda_item.cst_cofins,
                    venda_item.aliq_cofins,
                    venda_item.vlr_bc_cofins,
                    venda_item.vlr_cofins,
                    venda_item.vlr_bc_subst,
                    venda_item.vlr_subst,
                    venda_item.aliq_st,
                    venda_item.aliq_issqn,
                    venda_item.vlr_bc_issqn,
                    venda_item.vlr_issqn,
                    venda_item.aliq_ii,
                    venda_item.vlr_bc_ii,
                    venda_item.vlr_ii,
                    venda_item.codigo_ncm,
                    venda_item.vlr_pmc,
                    venda_item.aliq_ret_pis,
                    venda_item.vlr_ret_pis,
                    venda_item.aliq_ret_cofins,
                    venda_item.vlr_ret_cofins,
                    venda_item.aliq_ret_csll,
                    venda_item.vlr_ret_csll,
                    venda_item.aliq_bc_irrf,
                    venda_item.vlr_bc_irrf,
                    venda_item.aliq_irrf,
                    venda_item.vlr_irrf,
                    venda_item.aliq_bc_ret_prev,
                    venda_item.vlr_bc_ret_prev,
                    venda_item.aliq_ret_prev,
                    venda_item.vlr_ret_prev,
                    venda_item.vlr_bc_ret_csll,
                    venda_item.aliq_desconto_red_icms,
                    venda_item.vlr_icms_deson,
                    venda_item.tipo_icms_deson_motivo,
                    venda_item.qtd_origem,
                    venda_item.chk_atualizou_estoque_movimento,
                    venda_item.aliq_desconto,
                    produto_fabricante.descricao,
                    produto.descricao,
                    produto.peso, 
                    produto_empresa.qtd_estoque_atual,
                    produto_empresa.usa_desconto,
                    produto_empresa.aliq_desconto_max,                  
                    usuario.nome,
                    venda_item.produto_grade_id,
                    produto_grade_descricao,
                    produto_grade_tamanho,
                    venda_item.produto_embalagem_venda_id`;

    return this.http.post<RetornoApiViewModel<VendaPesquisaViewModel[]>>(`${this.urlVenda}/getGroup`, pesquisa)
  }

  obterPorId(id: number, tipo: string): Observable<RetornoApiViewModel<VendaPesquisaViewModel[]>> {

    let pesquisa: PesquisaGeralViewModel = new PesquisaGeralViewModel();

    pesquisa.select = `venda.id
                  ,  venda.emp_id
                  ,  venda.emp_destino_id
                  ,  venda.cliente_id
                  ,  COALESCE(NULLIF(venda.cliente_nome, ''), cliente.nome) AS cliente_nome
                  ,  COALESCE(NULLIF(venda.cliente_cpf_cnpj, ''), cliente.cpf_cnpj) AS cliente_cpf_cnpj
                  ,  cliente.chk_alterar_nome  as cliente_alterar_nome                                       
                  ,  venda.tipo
                  ,  venda.dt_abertura
                  ,  venda.dt_fechamento
                  ,  venda.atendente_id
                  ,  venda.status
                  ,  venda.obs
                  ,  venda.obs_cancel                    
                  ,  venda.usu_cancel_id
                  ,  venda.dt_cancel
                  ,  venda.msg
                  ,  venda.obs_entrega
                  ,  venda.acompanhamento
                  ,  venda.operacao_id
                  ,  venda.consumidor_final
                  ,  venda.produto_tabela_preco_id
                  ,  venda.os_equipamento
                  ,  venda.os_marca
                  ,  venda.os_serie
                  ,  venda.os_tecnico_id
                  ,  venda.os_complemento
                  ,  venda.os_defeito
                  ,  venda.vlr_frete
                  ,  venda.vlr_seguro
                  ,  venda.vlr_outras_desp
                  ,  venda.os_obs
                  ,  venda.os_laudo_tecnico
                  ,  venda.devolucao_origem_venda_id
                  ,  venda.devolucao_origem_nf_id
                  ,  venda.devolucao_troca_venda_id
                  ,  venda.veiculo_id
                  ,  venda.equipamento_id
                  ,  venda.numero_pedido
                  ,  venda.dt_previsao_chegada
                  ,  venda.dt_producao
                  ,  venda.venda_ref_venda_id
                  ,  COALESCE(NULLIF(venda.retirada_entrega_tipo, ''),'E') AS retirada_entrega_tipo                  
                  ,  venda.retirada_entrega_cliente_id
                  ,  venda.retirada_entrega_cliente_endereco_id
                  ,  venda.retirada_entrega_cpf_cnpj
                  ,  venda.retirada_entrega_ie
                  ,  venda.retirada_entrega_nome
                  ,  venda.retirada_entrega_endereco
                  ,  venda.retirada_entrega_numero
                  ,  venda.retirada_entrega_complemento
                  ,  venda.retirada_entrega_bairro
                  ,  venda.retirada_entrega_municipio_codigo_ibge
                  ,  venda.retirada_entrega_uf_sigla
                  ,  venda.retirada_entrega_cep
                  ,  venda.retirada_entrega_codigo_pais
                  ,  venda.retirada_entrega_fone
                  ,  venda.retirada_entrega_email
                  , (select descricao from endereco_municipio where codigo_ibge = venda.retirada_entrega_municipio_codigo_ibge limit 1) as retirada_endereco_municipio_descricao
                  ,  venda.nf_dados_adicionais
                  ,  venda.orcamento_importado_id
                  ,  (select usuario.apelido from usuario where usuario.id = venda.os_tecnico_id) as os_tecnico_nome    
                  ,  cliente.fantasia as cliente_fantasia 
                  ,  cliente.email as cliente_email       
                  ,  cliente.fone as cliente_fone       
                  ,  cliente.celular as cliente_celular       
                  ,  cliente.tipo_preco_venda as tipo_preco_venda             
                  ,  usuario.nome as atendente_nome       
                  ,  TO_CHAR(venda.dt_abertura, 'DD/MM/YYYY HH24:MI:SS') as dt_abertura
                  ,  TO_CHAR(venda.dt_fechamento, 'DD/MM/YYYY HH24:MI:SS') as dt_fechamento
                  ,  TO_CHAR(venda.dt_abertura, 'DD/MM/YYYY') as dt_abertura_formatada
                  ,  TO_CHAR(venda.dt_fechamento, 'DD/MM/YYYY') as dt_fechamento_formatada
                  ,  veiculo.placa as veiculo_placa
                  ,  veiculo.chassi as veiculo_chassi
                  ,  veiculo.marca as veiculo_marca
                  ,  veiculo.modelo as veiculo_modelo
                  ,  veiculo.ano_fab as veiculo_ano_fab
                  ,  veiculo.ano_mod as veiculo_ano_mod
                  ,  veiculo.cor as veiculo_cor
                  ,  equipamento.descricao as equipamento_descricao
                  ,  equipamento.codigo as equipamento_codigo
                  , usa_atacado
                  , operacao.natureza_operacao
                 `

    pesquisa.where = ` and venda.id = ${id} and venda.tipo = '${tipo ? tipo : 'VE'}'`;

    return this.http.post<RetornoApiViewModel<VendaPesquisaViewModel[]>>(`${this.urlVenda}/get`, pesquisa);
  }

  obterPorReferenciaVenda(referancia_id: number): Observable<RetornoApiViewModel<VendaPesquisaViewModel[]>> {

    let pesquisa: PesquisaGeralViewModel = new PesquisaGeralViewModel();

    pesquisa.select = `venda.*                            
                    ,  cliente.nome as cliente_nome         
                    ,  cliente.fantasia as cliente_fantasia 
                    ,  cliente.cpf_cnpj as cliente_cpf_cnpj 
                    ,  cliente.email as cliente_email       
                    ,  usuario.nome as atendente_nome       
                    ,  TO_CHAR(venda.dt_abertura, 'DD/MM/YYYY HH24:MI') as dt_abertura
                    ,  TO_CHAR(venda.dt_fechamento, 'DD/MM/YYYY HH24:MI') as dt_fechamento
                    ,  veiculo.placa as veiculo_placa
                    ,  veiculo.chassi as veiculo_chassi
                    ,  veiculo.marca as veiculo_marca
                    ,  veiculo.modelo as veiculo_modelo
                    ,  veiculo.ano_fab as veiculo_ano_fab
                    ,  veiculo.ano_mod as veiculo_ano_mod
                    ,  veiculo.cor as veiculo_cor
                    ,  equipamento.descricao as equipamento_descricao
                    ,  equipamento.codigo as equipamento_codigo`

    pesquisa.where = ` and venda.venda_ref_venda_id = ${referancia_id}`;

    return this.http.post<RetornoApiViewModel<VendaPesquisaViewModel[]>>(`${this.urlVenda}/get`, pesquisa);
  }

  adicionar(adicionarViewModel: CadastroViewModel<VendaViewModel>): Observable<RetornoApiViewModel<VendaViewModel>> {
    return this.http.post<RetornoApiViewModel<VendaViewModel>>(`${this.urlVenda}/addupdate`, adicionarViewModel);
  }

  atualizar(id: number, atualizarViewModel: CadastroViewModel<VendaViewModel>): Observable<RetornoApiViewModel<VendaViewModel>> {
    return this.http.put<RetornoApiViewModel<VendaViewModel>>(`${this.urlVenda}/addupdate/${id}`, atualizarViewModel);
  }

  gerarDevolucao(gerarDevolucaoViewModel: GerarDevolucaoViewModel) {

    return this.http.post<RetornoApiViewModel<RetornoSucesso[]>>(`${this.urlVenda}/GerarDevolucao`, gerarDevolucaoViewModel);
  }

  calcularImposto(id_venda: number): Observable<RetornoApiViewModel<any>> {
    return this.http.post<RetornoApiViewModel<any>>(`${this.urlVenda}/calcularImposto/${id_venda}`, { id_venda: id_venda });
  }

  calcularDesconto(id_venda: number): Observable<RetornoApiViewModel<any>> {
    return this.http.post<RetornoApiViewModel<any>>(`${this.urlVenda}/calcularDesconto/${id_venda}`, { id_venda: id_venda });
  }

  concluir(id: number, calcularViewModel: {}): Observable<RetornoApiViewModel<any>> {

    return this.http.post<RetornoApiViewModel<any>>(`${this.urlVenda}/concluir/${id}`, calcularViewModel);
  }

  cancelar(id_venda: number, obs_cancel: string): Observable<RetornoApiViewModel<any>> {
    return this.http.post<RetornoApiViewModel<any>>(`${this.urlVenda}/cancelar/${id_venda}`, {
      id_venda,
      obs_cancel
    });
  }

  cancelarArray(venda_id_array: any, obs_cancel: string) {
    const body = {
      venda_id_array: venda_id_array.join(', '),
      obs_cancel: obs_cancel
    };
    return this.http.post<RetornoApiViewModel<any>>(`${this.urlVenda}/CancelarArray`, body);
  }

  alterarAtendente(id: number, atendente: Object): Observable<RetornoApiViewModel<any>> {
    return this.http.post<RetornoApiViewModel<any>>(`${this.urlVenda}/AlterarAtendente/${id}`, atendente);
  }

  imprimirDav(venda_id: number, venda_tipo: string, relatorio: string): Observable<any> {

    let objRelatorio: RelatorioViewModel<RelatorioDavOsParamsViewModel, RelatorioDavOsObjViewModel> = new RelatorioViewModel<RelatorioDavOsParamsViewModel, RelatorioDavOsObjViewModel>();

    let logomarca: string = null;

    return this.obterPorId(venda_id || 0, venda_tipo || 'VE').pipe(
      concatMap(retorno => {

        objRelatorio.obj = Object.assign(retorno.result[0]);

        objRelatorio.obj.dt_abertura = Uteis.DateTimeZoneRetorno(objRelatorio.obj?.dt_abertura || '');

        objRelatorio.obj.dt_fechamento = Uteis.DateTimeZoneRetorno(objRelatorio.obj?.dt_fechamento || '');

        this.pesquisaClienteEndereco = Object.assign({ cliente_id: retorno.result[0].cliente_id || 0, principal: 1 })

        let pesquisa: ContasReceberPagarPesquisaViewModel = new ContasReceberPagarPesquisaViewModel();

        pesquisa.venda_id = venda_id;

        return this.contasReceberPagarService$.obterPgtoPorVendaId(pesquisa)
      }),
      concatMap(retorno => {

        objRelatorio.obj['pagamentos'] = retorno.result;

        return this.clienteEndereco$.obterTodos(this.pesquisaClienteEndereco)
      }),
      concatMap(retorno => {
        objRelatorio.obj = Object.assign(retorno.total > 0 ? retorno.result[0] : [], objRelatorio.obj)

        return this.obterListaItem(venda_id || 0, 'P')
      }),
      concatMap(retorno => {

        objRelatorio.obj = Object.assign({ produtos: retorno.total > 0 ? retorno.result : [] }, objRelatorio.obj);

        return this.obterListaItem(venda_id || 0, 'S')
      }),
      concatMap(retorno => {

        objRelatorio.obj = Object.assign({ servicos: retorno.total > 0 ? retorno.result : [] }, objRelatorio.obj);

        return this.configuracao$.obterImagem(this.autenticacao$.acessoUsuarioValue?.idLoja || 0)
      }),
      concatMap(retorno => {

        logomarca = retorno.result[0].img_logomarca;

        return this.configuracao$.obterPorId(this.autenticacao$.acessoUsuarioValue?.idLoja || 0)
      }),
      concatMap(retorno => {

        let titulo = objRelatorio?.obj?.tipo == 'OS' ? `DAV-OS (ORDEM DE SERVIÇO) ` : objRelatorio?.obj?.tipo == 'OR' ? 'DAV ORÇAMENTO' : 'DAV';

        objRelatorio.params = Object.assign({
          emp_cpf_cnpj: retorno.total > 0 ? retorno.result[0].emp_cnpj : '',
          emp_razao: retorno.total > 0 ? retorno.result[0].emp_razao : '',
          emp_fantasia: retorno.total > 0 ? retorno.result[0].emp_fantasia : '',
          emp_fone: retorno.total > 0 ? retorno.result[0].emp_fone : '',
          emp_endereco: retorno.total > 0 ? retorno.result[0].emp_endereco : '',
          emp_endereco_municipio_descricao: retorno.total > 0 ? retorno.result[0].emp_endereco_municipio_descricao : '',
          titulo: titulo + ` Nº ${objRelatorio.obj.id}`,
          atendente_nome: objRelatorio?.obj?.atendente_nome || '',
          os_tecnico_nome: objRelatorio?.obj?.os_tecnico_nome || '',
          status: objRelatorio?.obj?.status || '',
          //url_qrcode:                      `${URLBASE}/#/checkin/venda-qrcode/${this.idEmpresaMaxdata}/${objRelatorio.obj?.id || 0}`
          emp_logo: logomarca
        });

        const apiRelatorio = new ApiRelatorioViewModel<any, any>();
        apiRelatorio.relatorio = objRelatorio;
console.log(apiRelatorio);

        switch (relatorio) {

          case 'dav_os':
            apiRelatorio.subrelatorio = [
              { nome: "sub_dav_os_defeito" },
              { nome: "sub_dav_os_laudo_tecnico" },
              { nome: "sub_dav_os_produtos" },
              { nome: "sub_dav_os_servicos" },
              { nome: "sub_dav_os_pagamentos" }
            ]
            break;

          case 'dav_venda_os_bobina_termica':
            apiRelatorio.subrelatorio = [
              { nome: "dav_venda_os_bobina_termica_sub_produtos" },
              { nome: "dav_venda_os_bobina_termica_sub_servicos" },
              { nome: "dav_venda_os_bobina_termica_sub_pagamentos" }
            ]
            break;

        }

        return this.http.post<any>(`${this.urlImpressaoRel}/${relatorio}/`, apiRelatorio);

      })
    )
  }

  imprimirVendaOrcamento(pesquisa: VendaPesquisaViewModel): Observable<any> {

    let objRelatorio: RelatorioViewModel<any, any> = new RelatorioViewModel<any, any>();

    let logomarca: string = null;

    pesquisa.start = 0;

    pesquisa.quantidadeRegistrosPagina = 0;

    return this.obterTodosGroup(pesquisa).pipe(
      concatMap(retorno => {

        objRelatorio.obj = Object.assign(retorno.result);

        return this.configuracao$.obterImagem(this.autenticacao$.acessoUsuarioValue?.idLoja || 0)
      }),
      concatMap(retorno => {

        logomarca = retorno.result[0].img_logomarca;

        return this.configuracao$.obterPorId(this.autenticacao$.acessoUsuarioValue?.idLoja || 0)
      }),
      concatMap(retorno => {

        objRelatorio.params = Object.assign({
          emp_cpf_cnpj: retorno.total > 0 ? retorno.result[0].emp_cnpj : '',
          emp_razao: retorno.total > 0 ? retorno.result[0].emp_razao : '',
          emp_fantasia: retorno.total > 0 ? retorno.result[0].emp_fantasia : '',
          emp_fone: retorno.total > 0 ? retorno.result[0].emp_fone : '',
          emp_endereco: retorno.total > 0 ? retorno.result[0].emp_endereco : '',
          emp_endereco_municipio_descricao: retorno.total > 0 ? retorno.result[0].emp_endereco_municipio_descricao : '',
          emp_logo: logomarca
        });

        const apiRelatorio = new ApiRelatorioViewModel<any, any>();

        apiRelatorio.relatorio = objRelatorio;

        apiRelatorio.subrelatorio = [];

        apiRelatorio.subrelatorio = []

        return this.http.post<any>(`${this.urlImpressaoRel}/venda_orcamento/`, apiRelatorio);
      })
    )
  }

  aplicarDesconto(id_venda: number, dados: any) {
    return this.http.post<any>(`${this.urlVenda}/AplicarDesconto/${id_venda}`, dados);
  }

  converteVendaOrcamento(obj: any): Observable<any> {
    return this.http.post<any>(this.urlConverVendaOrcamento, obj);
  }

  obterVendasConverteOrcamento(pesquisa: VendaPesquisaViewModel): Observable<RetornoApiViewModel<VendaPesquisaViewModel[]>> {

    let dataFinal = new Date();
    dataFinal.setDate(dataFinal.getDate() - 1);
    let data_final = this.datepipe.transform(dataFinal, 'yyyy-MM-dd');

    let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel();

    pesquisaGeral.select = ` venda.*`;

    pesquisaGeral.order = pesquisa?.order ? pesquisa?.order : 'venda.dt_abertura';

    pesquisaGeral.start = pesquisa?.start || 0;

    pesquisaGeral.where = ` AND venda.status = 'A' and venda.tipo = 'VE'`;

    pesquisaGeral.where += ` AND venda.dt_abertura between '1899-12-30T00:00:00' and '${data_final}T23:59:59'`;

    if (pesquisa?.dt_abertura_inicial && pesquisa?.dt_abertura_final) pesquisaGeral.where += ` and venda.dt_abertura between '${pesquisa?.dt_abertura_inicial}T00:00:00' and '${pesquisa?.dt_abertura_final}T23:59:59'`;

    return this.http.post<RetornoApiViewModel<VendaPesquisaViewModel[]>>(`${this.urlVenda}/get`, pesquisaGeral)
  }

  updateVendaArray(VendaArray: CadastroArrayViewModel<VendaViewModel>): Observable<any> {
    return this.http.put<any>(this.urlAddUpdateArray, VendaArray);
  }

  gerarNf(obj: any): Observable<RetornoApiViewModel<any>> {
    return this.http.post<RetornoApiViewModel<any>>(`${this.urlVenda}/GerarNf`, obj);
  }

}
