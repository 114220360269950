<p-dialog [(visible)]="abrirFecharPesquisaProdutoModal" [modal]="true" [closable]="false" [style]="{width: '100vw'}"
    [maximizable]="true" [baseZIndex]="10000" [draggable]="false" [resizable]="false">
    <ngx-spinner name="modalAtualiza" bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-clip-rotate"
        [fullScreen]="false"></ngx-spinner>
    <p-header>Pesquisa de Produto</p-header>
    <div class="row float-right">
        <button type="button" class="btn btn-sm btn-outline-info" (click)="controlePesquisa()"><i
                class="material-icons">search</i>Pesquisar</button>
    </div>
    <div class="row pl-0">

        <div class="form-group">
            <label for="modal_codigo" class="col-form-label-sm font-weight-bold cursor" (click)="onKeydown($event)">Cód.
                {{pesquisaPorCodigo === 0 ? 'Interno' : pesquisaPorCodigo === 1 ? 'Fab.' : 'Barras'}} [+]</label>
            <input type="{{pesquisaPorCodigo === 1 ? 'text' : 'number'}}" id="modal_codigo" name="modal_codigo"
                class="form-control form-control-sm noscroll" autocomplete="off"
                placeholder="Cód. {{pesquisaPorCodigo === 0 ? 'Interno' : pesquisaPorCodigo === 1 ? 'Fab.' : 'Barras'}}"
                [(ngModel)]="pesquisaPorCodigoInput" (keydown)="onKeydown($event)" />
        </div>

        <div class="col-xs-12 col-sm-8 col-md-6 col-lg-5 col-xl-3">
            <div class="form-group">
                <label for="modal_descricao" class="col-form-label-sm">Descrição</label>
                <input type="text" id="modal_descricao" name="modal_descricao" class="form-control form-control-sm"
                    placeholder="Pesquise pela Descricao" [(ngModel)]="pesquisa.descricao" (keydown.enter)="pesquisar()"
                    autocomplete="off">
            </div>
        </div>
        <div class="col-xs-12 col-sm-8 col-md-6 col-lg-3 col-xl-2">
            <div class="form-group">
                <label for="produto_fabricante_descricao" class="col-form-label-sm">Fabricante</label>
                <input type="text" id="produto_fabricante_descricao" name="produto_fabricante_descricao"
                    class="form-control form-control-sm" placeholder="Pesquise pelo Fabricante"
                    [(ngModel)]="pesquisa.produto_fabricante_descricao" autocomplete="off" />
            </div>
        </div>
        <div class="col-xs-12 col-sm-8 col-md-6 col-lg-3 col-xl-2">
            <label class="col-form-label-sm custom-select-label">Grupo</label>
            <select id="produto_grupo_id" name="produto_grupo_id" class="form-control form-control-sm"
                [(ngModel)]="pesquisa.produto_grupo_id" (change)="obterListaSubGrupo()">
                <option [ngValue]="0">Todos</option>
                <option *ngFor="let item of listaGrupo" [ngValue]="item.id">{{ item.descricao }}</option>
            </select>
        </div>
        <div class="col-xs-12 col-sm-8 col-md-6 col-lg-3 col-xl-2">
            <label class="col-form-label-sm custom-select-label">Sub-Grupo</label>
            <select id="produto_sub_grupo_id" name="produto_sub_grupo_id" class="form-control form-control-sm"
                [(ngModel)]="pesquisa.produto_sub_grupo_id">
                <option [ngValue]="0">Todos</option>
                <option *ngFor="let item of listaSubGrupo" [ngValue]="item.id">{{ item.descricao }}</option>
            </select>
        </div>

    </div>
    <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">

            <div class="my-custom-scrollbar table-wrapper-scroll-y">
                <table class="table table-hover table-borderless table-striped table-sm " width="100%" id="table">
                    <thead class="thead">
                        <tr>
                            <th>Código</th>
                            <th>Descrição</th>
                            <th>Fabricante</th>
                            <th>UN</th>
                            <th class="text-right">Estoque</th>
                            <th class="text-right">Vlr Venda</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="cursor" *ngFor="let item of listaProdutos; let i = index; " (click)="selecionarProduto(item.id)"
                            (dblclick)="produtoSlecionado(item)"
                            (click)="clickRowIndex(i); produtoEquivalente(item.id);"
                            [ngClass]="{'selected-line': (selectedRowIndex || 0) === i }">
                            <td class="text-nowrap pr-5">{{item.id}}</td>
                            <td class="text-nowrap w-descricao pr-2 w-descricao text-truncate">{{item.descricao}}</td>
                            <td class="text-nowrap w-descricao pr-2">{{item.produto_fabricante_descricao}}</td>
                            <td class="text-nowrap pr-2">{{item.un}}</td>
                            <td class="text-nowrap text-right pr-2">{{item.qtd_estoque_atual}}</td>
                            <td class="text-nowrap text-right pr-2">{{item.vlr_venda | currency: 'BRL':'R$':'1.2-2'}}
                            </td>

                        </tr>
                    </tbody>
                </table>
            </div>
           
            
        </div>
    </div>
    <div class="col-auto pl-4">
        <label class="col-form-label-sm p-0 text-insible" for="consulta_produto_equivalente">Consultar produto
            equivalente</label>
        <div class="custom-control custom-checkbox p-1">
            <input type="checkbox" id="consulta_produto_equivalente" class="custom-control-input form-control-sm"
                autocomplete="off" (change)="onCheckBoxChangeProdutoEquivalente($event)" />
            <label class="custom-control-label col-form-label-sm" for="consulta_produto_equivalente">Consultar produto
                equivalente</label>
        </div>
    </div>

    <div class="row" *ngIf="chkProdutoEquivalente">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">

            <div class="my-custom-scrollbar table-wrapper-scroll-y">
                <table class="table table-hover table-borderless table-striped table-sm " width="100%" id="table">
                    <thead class="thead">
                        <tr>
                            <th>Código</th>
                            <th>Descrição</th>
                            <th>Fabricante</th>
                            <th>UN</th>
                            <th class="text-right">Estoque</th>
                            <th class="text-right">Vlr Venda</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="cursor" *ngFor="let item of listaProdutoEquivalente.itens; let i = index;"
                            (dblclick)="produtoEquivalenteSelecionado(item)" (click)="clickRowIndexProdEqui(i)"
                            [ngClass]="{'selected-line': (selectedRowIndexProdEqui || 0) === i }">
                            <td class="text-nowrap pr-5">{{item.id}}</td>
                            <td class="text-nowrap w-descricao pr-2 w-descricao text-truncate">{{item.descricao}}</td>
                            <td class="text-nowrap w-descricao pr-2">{{item.produto_fabricante}}</td>
                            <td class="text-nowrap pr-2">{{item.un}}</td>
                            <td class="text-nowrap text-right pr-2">{{item.qtd_estoque_atual}}</td>
                            <td class="text-nowrap text-right pr-2">{{item.vlr_venda | currency: 'BRL':'R$':'1.2-2'}}
                            </td>

                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <app-produto-consulta-estoque-filiais
   
  ></app-produto-consulta-estoque-filiais>
    
    <ng-template pTemplate="footer">
        <button type="button" class="btn btn-sm btn-outline-secondary" (click)="closeModalProduto()">Cancelar</button>
    </ng-template>
</p-dialog>

