import { Injectable, ɵɵresolveBody } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { URLBASE, URLIMPRESSAO } from 'src/modules/configuration.service';
import { Observable } from 'rxjs';
import { ListaPaginadaViewModel } from 'src/modules/manager/view-models/ListaPaginadaViewModel';
import { RetornoApiViewModel } from 'src/modules/manager/view-models/RetornoApiViewModel';
import { Uteis } from 'src/modules/utilizarios/uteis';

import { PesquisaGeralViewModel } from '@modules/admin/view-models/pesquisa/PesquisaGeralViewModel';
import { AutenticacaoService } from 'src/modules/autenticacao/services/autenticacao.service';
import { ClientePesquisaViewModel } from 'src/modules/manager/view-models/cadastro/cliente-fornecedor/pesquisa/cliente-pesquisa-view-model';
import { ClienteViewModel } from 'src/modules/manager/view-models/cadastro/cliente-fornecedor/cliente-view-model';
import { CadastroViewModel } from 'src/modules/admin/view-models/cadastro/CadastroViewModel';
import { concatMap, isEmpty } from 'rxjs/operators';
import { ApiRelatorioViewModel, RelatorioViewModel } from '@modules/manager/view-models/RelatorioViewModel';
import { ConfiguracaoService } from '@modules/manager/sistema/configuracao/configuracao.service';

@Injectable({
  providedIn: 'root'
})
export class ClienteService {

  idEmpresaMaxdata = this.autenticacao$.acessoUsuarioValue?.idEmpresaMaxdata;

  urlGet = `${URLBASE}/${this.idEmpresaMaxdata}/cliente_service/get`;
  urlAddUpdate = `${URLBASE}/${this.idEmpresaMaxdata}/cliente_service/addupdate`;
  urlImpressaoRel = `${URLIMPRESSAO}/api/relatorio`;

  constructor(private http: HttpClient,
    private autenticacao$: AutenticacaoService,
    private configuracao$: ConfiguracaoService) { }

  obterTodos(pesquisa: ClientePesquisaViewModel, imprimir?: boolean): Observable<RetornoApiViewModel<ClientePesquisaViewModel[]>> {

    let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel();

    pesquisaGeral.select = `cliente.id, 
                                cliente.nome, 
                                cliente.ativo, 
                                cliente.fantasia, 
                                cliente.tipo_pessoa, 
                                cliente.tipo_cadastro, 
                                cliente.cliente_grupo_id, 
                                cliente.cpf_cnpj,
                                cliente.tipo_preco_venda,
                                cliente.rg_ie,
                                cliente.fone, 
                                cliente.chk_alterar_nome, 
                                cliente.celular,
                                cliente.email, 
                                cliente.dt_cadastro, 
                                cliente.obs_venda, 
                                endereco_municipio.descricao as endereco_municipio_descricao,
                                endereco as endereco,
                                endereco_cep as endereco_cep,
                                endereco_bairro as endereco_bairro,
                                endereco_numero as endereco_numero,
                                endereco_uf.sigla as uf_sigla

                                `

    pesquisaGeral.calcRowCount = 1;

    pesquisaGeral.where = `and cliente.ativo = ${pesquisa?.desativados ? 0 : 1}`;

    pesquisaGeral.start = imprimir ? 0 : pesquisa?.start || 0;

    pesquisaGeral.limit = pesquisa?.quantidadeRegistrosPagina >= 0 ? pesquisa?.quantidadeRegistrosPagina : 100;

    pesquisaGeral.order = `${pesquisa?.order ? pesquisa?.order : 'cliente.id'}`;

    if (pesquisa?.id) pesquisaGeral.where += ` and cliente.id = ${pesquisa?.id}`;
    
    if (pesquisa?.fone) pesquisaGeral.where += ` and cliente.fone like  '%${pesquisa?.fone}%'`;

    if (pesquisa?.nome) pesquisaGeral.where += ` and lower(cliente.nome) like lower('%${pesquisa?.nome}%')`;

    if (pesquisa?.cpf_cnpj) pesquisaGeral.where += ` and lower(cliente.cpf_cnpj) like lower('%${Uteis.SomenteNumeros(pesquisa?.cpf_cnpj)}%')`;

    if (typeof pesquisa?.tipo_cadastro != 'undefined') pesquisaGeral.where += ` and cliente.tipo_cadastro in (${pesquisa?.tipo_cadastro})`;



    if (pesquisa?.tipo_pessoa?.length > 0 || Number(pesquisa?.tipo_pessoa) === 0 || Number(pesquisa?.tipo_pessoa) === 1) pesquisaGeral.where += ` and cliente.tipo_pessoa in (${pesquisa?.tipo_pessoa})`;

    if (pesquisa?.cliente_grupo_id) pesquisaGeral.where += ` and cliente.cliente_grupo_id = ${pesquisa?.cliente_grupo_id}`;

    return this.http.post<RetornoApiViewModel<ClientePesquisaViewModel[]>>(this.urlGet, pesquisaGeral)
  }

  obterPorId(id: number): Observable<RetornoApiViewModel<ClienteViewModel[]>> {
    let pesquisa: PesquisaGeralViewModel = new PesquisaGeralViewModel();
    pesquisa.select = `cliente.id                                       ,
                            cliente.nome                                     ,
                            cliente.ativo                                    ,
                            cliente.fantasia                                 ,
                            cliente.tipo_pessoa                              ,
                            cliente.tipo_cadastro                            ,
                            cliente.cliente_grupo_id                         ,
                            cliente.cliente_tipo_id                          ,
                            cliente.cpf_cnpj                                 ,
                            cliente.rg_ie                                    ,
                            cliente.rg_ie_uf                                 ,
                            cliente.ie_diferido                              ,
                            cliente.dt_cadastro                              ,
                            cliente.dt_nascimento                            ,
                            cliente.vlr_limite_credito                       ,
                            cliente.obs_venda                                ,
                            cliente.fone                                     ,
                            cliente.fax                                      ,
                            cliente.celular                                  ,
                            cliente.site                                     ,
                            cliente.email                                    ,
                            cliente.img_foto                                 ,
                            cliente.sexo                                     ,
                            cliente.estado_civil                             ,
                            cliente.naturalidade_cidade                      ,
                            cliente.naturalidade_uf                          ,
                            cliente.nome_pai                                 ,
                            cliente.nome_mae                                 ,
                            cliente.qtd_dependentes                          ,
                            cliente.dados_prof_cnpj                          ,
                            cliente.dados_prof_fone                          ,
                            cliente.dados_prof_data_admissao                 ,
                            cliente.dados_prof_ocupacao                      ,
                            cliente.dados_prof_cargo                         ,
                            cliente.dados_prof_vlr_renda_mensal              ,
                            cliente.dados_prof_vlr_outras_rendas             ,
                            cliente.dados_prof_endereco                      ,
                            cliente.dados_prof_endereco_numero               ,
                            cliente.dados_prof_endereco_bairro               ,
                            cliente.dados_prof_endereco_cep                  ,
                            cliente.dados_prof_endereco_cidade_codigo_ibge   ,
                            cliente.dados_banc_num_banco                     ,
                            cliente.dados_banc_nome_banco                    ,
                            cliente.dados_banc_agencia                       ,
                            cliente.dados_banc_num_conta                     ,
                            cliente.dados_banc_tipo_conta                    ,
                            cliente.dados_banc_data_conta                    ,
                            cliente.dados_banc_fone_ag                       ,
                            cliente.dados_banc_obs                           ,
                            cliente.obs_geral                                ,
                            cliente.tipo_regime_apuracao                     ,
                            cliente.nome_conjuge                             ,
                            cliente.inscricao_municipal                      ,
                            cliente.desconto_auto_aplicar                    ,
                            cliente.desconto_auto_aliq                       ,
                            cliente.obs_nfe                                  ,
                            cliente.dt_casamento                             ,
                            cliente.consumidor_final                         ,
                            cliente.dados_prof_nome_empresa                  ,
                            cliente.cliente_profissao_id                     ,
                            cliente.chk_alterar_nome                         ,
                            cliente.tipo_preco_venda                         `

    pesquisa.where += ` and cliente.id = ${id}`

    return this.http.post<RetornoApiViewModel<ClienteViewModel[]>>(`${this.urlGet}`, pesquisa);
  }

  adicionar(clienteViewModel: CadastroViewModel<ClienteViewModel>): Observable<RetornoApiViewModel<ClienteViewModel[]>> {
    return this.http.post<RetornoApiViewModel<ClienteViewModel[]>>(this.urlAddUpdate, clienteViewModel);
  }

  atualizar(id: number, clienteViewModel: CadastroViewModel<ClienteViewModel>): Observable<RetornoApiViewModel<ClienteViewModel>> {
    return this.http.put<RetornoApiViewModel<ClienteViewModel>>(`${this.urlAddUpdate}/${id}`, clienteViewModel);
  }

  imprimirListagemCliente(pesquisa: ClientePesquisaViewModel): Observable<any> {
    let objRelatorio: RelatorioViewModel<any, ClientePesquisaViewModel[]> = new RelatorioViewModel<any, ClientePesquisaViewModel[]>();

    let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel();

    pesquisaGeral.select = `cliente.id, 
                                cliente.nome, 
                                cliente.fantasia, 
                                cliente.cpf_cnpj, 
                                cliente.fone, 
                                cliente.celular, 
                                cliente.dt_cadastro, 
                                endereco_municipio.descricao AS cidade`

    pesquisaGeral.limit = 0;

    pesquisaGeral.order = `${pesquisa.agrupar_cidade ? 'endereco_municipio.descricao, cliente.id' : 'cliente.id'}`;

    pesquisaGeral.where = ``;

    if (pesquisa.id) pesquisaGeral.where += ' and cliente.id = ' + pesquisa.id;

    if (pesquisa.nome) pesquisaGeral.where += ` and lower(cliente.nome) like lower('%${pesquisa.nome}%')`;

    if (!(pesquisa.tipo_pessoa?.includes(9))) { pesquisaGeral.where += ` AND cliente.tipo_pessoa = ${pesquisa.tipo_pessoa}`; }

    if ((pesquisa.tipo_cadastro)) { pesquisaGeral.where += ` AND cliente.tipo_cadastro = ${pesquisa.tipo_cadastro}`; }

    if ((pesquisa.tipo_regime_apuracao !== 9 || '')) { pesquisaGeral.where += `cliente.tipo_regime_apuracao = ${pesquisa.tipo_regime_apuracao}`; }

    if ((pesquisa.cliente_grupo_id !== 0 || '')) { pesquisaGeral.where += ` AND cliente.cliente_grupo_id = ${pesquisa.cliente_grupo_id}`; }

    if ((pesquisa.dt_cadastro_inicio || '') !== '' && (pesquisa.dt_cadastro_fim || '') === '') pesquisaGeral.where += ` AND (cliente.dt_cadastro > ('${pesquisa.dt_cadastro_inicio}T00:00:00'))`;

    if ((pesquisa.dt_cadastro_fim || '') !== '' && (pesquisa.dt_cadastro_inicio || '') === '') pesquisaGeral.where += ` AND (cliente.dt_cadastro < ('${pesquisa.dt_cadastro_fim}T23:59:59'))`;


    if ((pesquisa.dt_cadastro_inicio || '') !== '' && (pesquisa.dt_cadastro_fim || '') !== '') pesquisaGeral.where += ` AND (cliente.dt_cadastro BETWEEN ('${pesquisa.dt_cadastro_inicio}T00:00:00') AND ('${pesquisa.dt_cadastro_fim}T23:59:59'))`;

    pesquisa.quantidadeRegistrosPagina = 0;

    return this.obterTodos(pesquisa).pipe(

      concatMap(
        retorno => {

          objRelatorio.obj = retorno.total > 0 ? retorno.result : [];

          return this.configuracao$.obterPorId(this.autenticacao$.acessoUsuarioValue?.idLoja || 0)
        }
      ),
      concatMap(
        retorno => {

          objRelatorio.params = Object.assign({
            emp_razao: retorno.total > 0 ? retorno.result[0].emp_razao : '',
            emp_fantasia: retorno.total > 0 ? retorno.result[0].emp_fantasia : '',
            emp_cpf_cnpj: retorno.total > 0 ? retorno.result[0].emp_cnpj : '',
            emp_fone: retorno.total > 0 ? retorno.result[0].emp_fone : '',
            emp_endereco: retorno.total > 0 ? retorno.result[0].emp_endereco : '',
            emp_endereco_municipio_descricao: retorno.total > 0 ? retorno.result[0].emp_endereco_municipio_descricao : ''
          });

          return this.configuracao$.obterImagem(this.autenticacao$.acessoUsuarioValue?.idLoja || 0)
        }
      ),
      concatMap(
        retorno => {

          objRelatorio.params['emp_logo'] = retorno.total > 0 ? retorno.result[0] : null;

          const apiRelatorio = new ApiRelatorioViewModel<any, ClientePesquisaViewModel[]>();

          apiRelatorio.relatorio = objRelatorio;

          apiRelatorio.subrelatorio = [];

          return this.imprimir(`${pesquisa?.agrupar_cidade ? 'listagem_clientes_agrupamento_cidades' : 'listagem_clientes'}`, apiRelatorio);
        }
      )
    )
  }

  imprimirCadastroClienteFornecedores(pesquisa: ClientePesquisaViewModel): Observable<any> {

    let objRelatorio: RelatorioViewModel<any, any> = new RelatorioViewModel<any, any>();

    let logomarca: string = null;

    return this.obterTodos(pesquisa, true).pipe(
      concatMap(retorno => {

        objRelatorio.obj = retorno.total > 0 ? retorno.result : [];

        return this.configuracao$.obterImagem(this.autenticacao$.acessoUsuarioValue?.idLoja || 0)
      }),
      concatMap(retorno => {

        logomarca = retorno.result[0].img_logomarca

        return this.configuracao$.obterPorId(this.autenticacao$.acessoUsuarioValue?.idLoja || 0)
      }),
      concatMap(retorno => {
        objRelatorio.params = Object.assign({
          emp_razao: retorno.total > 0 ? retorno.result[0].emp_razao : '',
          emp_fantasia: retorno.total > 0 ? retorno.result[0].emp_fantasia : '',
          emp_cpf_cnpj: retorno.total > 0 ? retorno.result[0].emp_cnpj : '',
          emp_fone: retorno.total > 0 ? retorno.result[0].emp_fone : '',
          emp_endereco: retorno.total > 0 ? retorno.result[0].emp_endereco : '',
          emp_endereco_municipio_descricao: retorno.total > 0 ? retorno.result[0].emp_endereco_municipio_descricao : '',
          emp_logo: logomarca
        });

        const apiRelatorio = new ApiRelatorioViewModel<any, any>();

        apiRelatorio.relatorio = Object.assign(objRelatorio);

        apiRelatorio.subrelatorio = [];

        return this.http.post<any>(`${this.urlImpressaoRel}/cadastro_cliente_fornecedores/`, apiRelatorio);
      })
    )


  }

  imprimir(relatorio, apiRelatorio) {
    return this.http.post<any>(`${this.urlImpressaoRel}/${relatorio}/`, apiRelatorio);
  }

}
