export class RelatorioViewModel<T, U>{
   params?:T;
   obj?:U;
}

export class ApiRelatorioViewModel<T, U>{
   relatorio?:RelatorioViewModel<T, U>;
   subrelatorio:SubRelatorio[] = [];
}

export class SubRelatorio{
   nome?:string;
}