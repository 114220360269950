<p-dialog [(visible)]="modalProdutoGrade" [modal]="true" [closable]="false" [style]="{width: '90vw'}"
    [maximizable]="false" [baseZIndex]="10000" [draggable]="false" [resizable]="false">

    <ng-template pTemplate="header">
        <div class="row">
            <h5>Grade do Produto</h5>
        </div>
    </ng-template>

    <h6>{{produto_id}} - {{descricao}}</h6>
    <div class="card rounded-0">
        <div class="card-body pt-1 pb-1">
            <div class="row">
                <div class="col-sm-4 col-md-3 col-lg-2 col-xl-1">
                    <div class="form-group">
                        <label for="produto_grade_codigo_barras" class="col-form-label-sm">Cód. Barras</label>
                        <input type="text" id="produto_grade_codigo_barras" name="modal_produto_grade_codigo_barras"
                            class="form-control form-control-sm noscroll" placeholder="Cód. Barras"
                            [(ngModel)]="pesquisaProdutoGrade.codigo_barras" (keydown.enter)="obterListaProdutoGrade()">
                        <!-- [ngClass]="{'is-invalid': displayMessageProdutoGrade.estoque}"> -->
                        <!-- <span class="invalid-feedback" *ngIf="displayMessageProdutoGrade.estoque">
                            <p [innerHTML]="displayMessageProdutoGrade.estoque"></p>
                        </span> -->
                    </div>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-4 col-xl-2">
                    <div class="form-group">
                        <label for="produto_grade_descricao" class="col-form-label-sm">Descrição</label>
                        <div class="input-group">
                            <select id="produto_grade_descricao" name="produto_grade_descricao"
                                class="form-control form-control-sm" placeholder="Descrição"
                                [(ngModel)]="pesquisaProdutoGrade.produto_grade_descricao_id">
                                <option></option>
                                <option *ngFor="let item of listaProdutoGradeDescricao.itens" [ngValue]="item.id">
                                    {{item.descricao}}</option>
                            </select>

                        </div>
                    </div>
                </div>

                <div class="col-sm-6 col-md-6 col-lg-4 col-xl-2">
                    <div class="form-group">
                        <label for="produto_grade_tamanho" class="col-form-label-sm">Tamanho</label>
                        <div class="input-group">
                            <select id="produto_grade_tamanho" name="produto_grade_tamanho"
                                class="form-control form-control-sm" placeholder="Ex: P, M, G"
                                [(ngModel)]="pesquisaProdutoGrade.produto_grade_tamanho_id">
                                <option></option>
                                <option *ngFor="let item of listaProdutoGradeTamanho.itens" [ngValue]="item.id">
                                    {{item.tamanho}}</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3  btns-group mt-4">
                    <button class="btn btn-sm btn-outline-info" type="button" (click)="obterListaProdutoGrade()"><i
                            class="material-icons">search</i>Pesquisar</button>
                    <button class="btn btn-sm btn-outline-secondary" (click)="modalClose(false)">Cancelar</button>
                </div>

                <div *ngIf="exibir_input_qtd" (click)="multiplosSelecionados()" class="col-sm-6 col-md-4 col-lg-3 col-xl-3 text-right btns-group mt-4">
                    <button class="btn btn-sm btn-outline-success">
                        <i class="material-icons text-success">done_all</i>
                        Confirmar
                    </button>
                </div>
            </div>
            <div class="card-body bg-white border-top pt-1 pb-1">
                <div class="my-custom-scrollbar table-wrapper-scroll-y ">
                    <table class="table table-hover table-borderless table-striped table-sm " width="100%">
                        <thead class="thead">
                            <tr>
                                <th scope="text-nowrap">Código</th>
                                <th scope="text-nowrap">Descrição</th>
                                <th scope="text-nowrap">Tamanho</th>
                                <th scope="text-nowrap">Estoque</th>
                                <th scope="text-nowrap">Vlr Venda</th>
                                <th scope="text-nowrap">Cód. Barras</th>
                                <th scope="text-nowrap">Selecionar</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="cursor" *ngFor="let item of listaProdutoGrade.itens">
                                <td (dblclick)="produtoGradeSelecionada(item)">{{item.id}}</td>
                                <td (dblclick)="produtoGradeSelecionada(item)">{{item.produto_grade_descricao}}</td>
                                <td (dblclick)="produtoGradeSelecionada(item)">{{item.produto_grade_tamanho}}</td>
                                <td (dblclick)="produtoGradeSelecionada(item)">{{item.estoque}}</td>
                                <td (dblclick)="produtoGradeSelecionada(item)">{{item.vlr_venda | currency:
                                    'BRL':'':'1.2-2'}}</td>
                                <td (dblclick)="produtoGradeSelecionada(item)">{{item.codigo_barras}}</td>

                                <td *ngIf="!exibir_input_qtd">
                                    <i class="material-icons text-success" (click)="produtoGradeSelecionada(item)">done_all</i>
                                    <i *ngIf="multiSelect" class="material-icons text-success ml-3" (click)="adicionarQtdItem(item)">add</i>
                                </td>

                                <td *ngIf="exibir_input_qtd">
                                    <div class="input-group input-group-sm m-0 text-left" style="transform: scale(0.8);">
                                        <div class="input-group-prepend">
                                            <button class="btn btn-sm btn-outline-secondary" type="button"
                                                (click)="subtrairQtdItem(item)">
                                                <i class="material-icons mr-0">remove</i>
                                            </button>
                                        </div>

                                        <input style="width: 115px; font-size: 14px;" [value]="getOneMultGrade(item.id)?.qtd_com || 0"
                                            class="form-control form-control-sm font-weight-bold text-center" (keydown)="apenasNumeros($event)"
                                            (change)="adicionarQtdItem(item, $event.target.value)">

                                        <div class="input-group-append">
                                            <button class="btn btn-sm btn-outline-secondary" type="button"
                                                (click)="adicionarQtdItem(item)">
                                                <i class="material-icons mr-0">add</i>
                                            </button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</p-dialog>