import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AlertMessage } from '@modules/alert.configuration.service';
import { PlanoContaService } from '@modules/manager/cadastro/financeiro/plano-conta/plano-conta.service';
import { SubPlanoContaService } from '@modules/manager/cadastro/financeiro/sub-plano-conta/sub-plano-conta.service';
import { PlanoContaPesquisaViewModel } from '@modules/manager/view-models/cadastro/financeiro/pesquisa/plano-conta-pesquisa-view-model';
import { Uteis } from '@modules/utilizarios/uteis';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-multiselect-plano-conta',
  templateUrl: './multiselect-plano-conta.component.html',
  styles: []
})
export class MultiselectPlanoContaComponent implements OnInit {

  @Output() planoContasSelecionados: EventEmitter<any> = new EventEmitter<any>();  
  listaPlanoContas: PlanoContaPesquisaViewModel[] = [];
  plano_contas: PlanoContaPesquisaViewModel[] = [];
  
  constructor(
    private planoConta$: PlanoContaService,
    private subPlanoConta$: SubPlanoContaService,
    private alert: AlertMessage,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit() {
    this.planoConta$.obterTodos().subscribe(

      retorno => this.listaPlanoContas = retorno.total > 0 ? retorno.result : [],

      error => { this.alert.Danger('Erro', Uteis.ObterErroApi(error)) }
    )
  }

  enviar(){ 
    this.planoContasSelecionados.emit(this.plano_contas);
  }

}
