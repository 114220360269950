<div class="row">
    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <app-titulo-paginas [tituloPagina]="tituloPagina"></app-titulo-paginas>
    </div>
    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <div class="text-right btns-group">
            <button class="btn btn-sm btn-outline-info" (click)="pesquisar()"><i
                    class="material-icons">search</i>Pesquisar</button>
            <a [routerLink]="['novo']" class="btn btn-sm btn-outline-success"><i class="material-icons">add</i>Novo</a>
        </div>
    </div>
</div>
<div class="card shadow-sm">
    <div class="card-body pt-1 pb-1">
        <div class="row">
            <div class="col-sm-4 col-md-4 col-lg-2 col-xl-1">
                <div class="form-group">
                    <label for="codigo" class="col-form-label-sm">Código</label>
                    <input type="number" id="codigo" name="codigo" class="form-control form-control-sm noscroll"
                        placeholder="Código" [(ngModel)]="pesquisa.id">
                </div>
            </div>
            <div class="col-sm-8 col-md-8 col-lg-4 col-xl-4">
                <div class="form-group">
                    <label for="email" class="col-form-label-sm">E-mail</label>
                    <input type="text" id="email" name="email" class="form-control form-control-sm"
                        placeholder="Pesquise pelo E-mail" [(ngModel)]="pesquisa.email">
                </div>
            </div>
            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2">
                <div class="form-group">
                    <label class="col-form-label-sm">Itens por página</label>
                    <select id="quantidadeRegistrosPagina" name="quantidadeRegistrosPagina"
                        class="form-control form-control-sm" [(ngModel)]="pesquisa.quantidadeRegistrosPagina"
                        (change)="pesquisar()">
                        <option *ngFor="let item of listaItensPaginacao" [ngValue]="item.quantidade" [selected]="10">
                            {{ item.quantidade }}</option>
                    </select>
                </div>
            </div>
            <div class="col-sm-6 col-md-8 col-lg-3 col-xl-5 text-right">
                <div class="form-group">
                    <div class="custom-control custom-checkbox">
                        <input type="checkbox" id="desativar" name="desativar" class="custom-control-input"
                            [(ngModel)]="pesquisa.desativados">
                        <label class="custom-control-label status-filtro" for="desativar">Desativado</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card-body pt-1 pb-1 border-top rounded-0 bg-white">

        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div class="table-responsive">
                    <table class="table table-sm table-borderless table-striped table-hover">
                        <thead class="thead">
                            <th (click)="ordenarLista('ID')">Código</th>
                            <th (click)="ordenarLista('email')">E-mail</th>
                            <th class="w-20"></th>
                            <th></th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of listaPaginada.itens"  [routerLink]="['editar/', item.id]" class="cursor">
                                <td>{{item.id}}</td>
                                <td>{{item.email}}</td>
                                <td class="w-20">
                                    <span class="badge"
                                        [ngClass]="!item.chk_usu_adm ? '' : 'badge-primary'">{{item.chk_usu_adm ? 'Admin': ''}}</span>
                                </td>
                                <td class="text-right">
                                    <!-- <a class="badge badge-info"><i
                                            class="material-icons">edit</i></a> -->
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
<app-paginacao 
    [qdtPaginas]="pesquisa.quantidadeRegistrosPagina" 
    [itensgrid]="listaPaginada.itens.length"              
    [itemsPerPage]="listaPaginada.paginacao.quantidadeRegistrosPagina"
    [totalItems]="listaPaginada.paginacao.totalRegistros"
    [pagina]="pesquisa.pagina"  
    (onPaginate)="mudarPagina($event)">
</app-paginacao>
</div>