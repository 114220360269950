import { Injectable, ɵɵresolveBody } from '@angular/core';
import { HttpClient} from '@angular/common/http';


import { URLBASE } from 'src/modules/configuration.service';
import { Observable } from 'rxjs';
import { ListaPaginadaViewModel } from 'src/modules/manager/view-models/ListaPaginadaViewModel';
import { RetornoApiViewModel } from 'src/modules/manager/view-models/RetornoApiViewModel';
import { Uteis } from 'src/modules/utilizarios/uteis';

import { PesquisaGeralViewModel } from '@modules/admin/view-models/pesquisa/PesquisaGeralViewModel';
import { AutenticacaoService } from 'src/modules/autenticacao/services/autenticacao.service';
import { CadastroViewModel } from 'src/modules/admin/view-models/cadastro/CadastroViewModel';
import { ProdutoNcmCestViewModel } from 'src/modules/manager/view-models/cadastro/estoque/produto-ncm-cest-view-model';
import { ProdutoNcmCestPesquisaViewModel } from '@modules/manager/view-models/cadastro/estoque/pesquisa/produto-ncm-cest-pesquisa-view-model';

@Injectable()
export class ProdutoNcmCestService {    
    idEmpresaMaxdata = this.autenticacaoService.acessoUsuarioValue?.idEmpresaMaxdata;
    
    urlGet =  `${URLBASE}/${this.idEmpresaMaxdata}/produto_ncm_cest_service/get`;
    urlAddUpdate  = `${URLBASE}/${this.idEmpresaMaxdata}/produto_ncm_cest_service/addupdate`;
    urlDelete = `${URLBASE}/${this.idEmpresaMaxdata}/produto_ncm_cest_service/delete`;

    constructor(private http: HttpClient, 
        private autenticacaoService: AutenticacaoService){ }

    obterTodos(pesquisa: ProdutoNcmCestPesquisaViewModel) : Observable<RetornoApiViewModel<ProdutoNcmCestPesquisaViewModel[]>> {

        let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel()

        pesquisaGeral.select = `produto_ncm_cest.id, 
                            produto_ncm_cest.produto_ncm_id,
                            produto_ncm.codigo as produto_ncm_codigo,
                            produto_ncm_cest.produto_cest_id,
                            produto_cest.codigo as produto_cest_codigo,
                            produto_ncm_cest.padrao`;

        pesquisaGeral.where = ` and produto_ncm_cest.produto_ncm_id = ${pesquisa?.produto_ncm_id || 0}`;

        pesquisaGeral.start = pesquisa?.start || 0;

        pesquisaGeral.order = pesquisa?.order ? pesquisa?.order : '';

        return this.http.post<RetornoApiViewModel<ProdutoNcmCestPesquisaViewModel[]>>(this.urlGet, pesquisaGeral)
    }

    adicionar(produtoNcmCestViewModel: CadastroViewModel<ProdutoNcmCestViewModel>) : Observable<RetornoApiViewModel<ProdutoNcmCestViewModel>>{
        return this.http.post<RetornoApiViewModel<ProdutoNcmCestViewModel>>(this.urlAddUpdate, produtoNcmCestViewModel);
    }

    atualizar(id: number, produtoNcmCestViewModel: CadastroViewModel<ProdutoNcmCestViewModel>) : Observable<any>{
          return this.http.put<RetornoApiViewModel<ProdutoNcmCestViewModel>>(`${this.urlAddUpdate}/${id}`, produtoNcmCestViewModel);
      }

    RemoverProdutoCest(id: number, objDelete: CadastroViewModel<ProdutoNcmCestViewModel>) : Observable<RetornoApiViewModel<any>>{
        return this.http.request<RetornoApiViewModel<any>>('delete',`${this.urlDelete}/${id}`, {body: objDelete})
    }
}