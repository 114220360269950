import { Injectable, ɵɵresolveBody } from '@angular/core';
import { HttpClient } from '@angular/common/http';


import { URLBASE } from 'src/modules/configuration.service';
import { Observable } from 'rxjs';
import { ListaPaginadaViewModel } from 'src/modules/manager/view-models/ListaPaginadaViewModel';
import { RetornoApiViewModel } from 'src/modules/manager/view-models/RetornoApiViewModel';
import { Uteis } from 'src/modules/utilizarios/uteis';

import { PesquisaGeralViewModel } from '@modules/admin/view-models/pesquisa/PesquisaGeralViewModel';
import { AutenticacaoService } from 'src/modules/autenticacao/services/autenticacao.service';
import { CadastroViewModel } from 'src/modules/admin/view-models/cadastro/CadastroViewModel';
import { ProdutoUnidadePesquisaViewModel } from 'src/modules/manager/view-models/cadastro/estoque/pesquisa/produto-unidade-pesquisa-view-model';
import { ProdutoUnidadeViewModel } from 'src/modules/manager/view-models/cadastro/estoque/produto-unidade-view-model';
@Injectable({
    providedIn: 'root'
})
export class ProdutoUnidadeService {
    idEmpresaMaxdata = this.autenticacao$.acessoUsuarioValue?.idEmpresaMaxdata;

    urlGet = `${URLBASE}/${this.idEmpresaMaxdata}/produto_un_service/get`;
    urlAddUpdate = `${URLBASE}/${this.idEmpresaMaxdata}/produto_un_service/addupdate`;

    constructor(private http: HttpClient,
        private autenticacao$: AutenticacaoService) { }

    obterTodos(pesquisa?: ProdutoUnidadePesquisaViewModel): Observable<RetornoApiViewModel<ProdutoUnidadePesquisaViewModel[]>> {

        let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel()

        pesquisaGeral.select = 'produto_un.id, produto_un.descricao, produto_un.un, produto_un.ativo';

        pesquisaGeral.calcRowCount = 1;

        pesquisaGeral.limit = pesquisa?.quantidadeRegistrosPagina || 0;

        pesquisaGeral.where = `and produto_un.ativo = ${pesquisa?.desativados ? 0 : 1}`;

        pesquisaGeral.start = pesquisa?.start || 0;

        pesquisaGeral.order = pesquisa?.order ? pesquisa?.order : 'produto_un.un'
    
        if (pesquisa?.id) pesquisaGeral.where += ` and produto_un.id = ${pesquisa?.id}`;
    
        if (pesquisa?.un) pesquisaGeral.where += ` and lower(produto_un.un) like lower('%${pesquisa?.un}%')`;
    
        if (pesquisa?.descricao) pesquisaGeral.where += ` and lower(produto_un.descricao) like lower('%${pesquisa?.descricao}%')`;

        return this.http.post<RetornoApiViewModel<ProdutoUnidadePesquisaViewModel[]>>(this.urlGet, pesquisaGeral)
    }
    obterTodosProd(pesquisa?: ProdutoUnidadePesquisaViewModel): Observable<RetornoApiViewModel<ProdutoUnidadePesquisaViewModel[]>> {

        let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel()

        pesquisaGeral.select = 'produto_un.id, produto_un.descricao, produto_un.un, produto_un.ativo';

        pesquisaGeral.calcRowCount = 1;

        pesquisaGeral.limit = 0;

        pesquisaGeral.where = `and produto_un.ativo = ${pesquisa?.desativados ? 0 : 1}`;

        pesquisaGeral.start = pesquisa?.start || 0;

        pesquisaGeral.order = pesquisa?.order ? pesquisa?.order : 'produto_un.un'
    
        if (pesquisa?.id) pesquisaGeral.where += ` and produto_un.id = ${pesquisa?.id}`;
    
        if (pesquisa?.un) pesquisaGeral.where += ` and lower(produto_un.un) like lower('%${pesquisa?.un}%')`;
    
        if (pesquisa?.descricao) pesquisaGeral.where += ` and lower(produto_un.descricao) like lower('%${pesquisa?.descricao}%')`;

        return this.http.post<RetornoApiViewModel<ProdutoUnidadePesquisaViewModel[]>>(this.urlGet, pesquisaGeral)
    }

    obterPorId(id: number): Observable<RetornoApiViewModel<ProdutoUnidadeViewModel[]>> {

        let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel()

        pesquisaGeral.select = 'produto_un.id, produto_un.descricao, produto_un.un, produto_un.ativo';

        pesquisaGeral.where = `and produto_un.id = ${id}`;

        return this.http.post<RetornoApiViewModel<ProdutoUnidadeViewModel[]>>(`${this.urlGet}`, pesquisaGeral);
    }

    adicionar(produtoUnidadeViewModel: CadastroViewModel<ProdutoUnidadeViewModel>): Observable<RetornoApiViewModel<ProdutoUnidadeViewModel>> {
        return this.http.post<RetornoApiViewModel<ProdutoUnidadeViewModel>>(this.urlAddUpdate, produtoUnidadeViewModel);
    }

    atualizar(id: number, produtoUnidadeViewModel: CadastroViewModel<ProdutoUnidadeViewModel>): Observable<RetornoApiViewModel<ProdutoUnidadeViewModel>> {
        return this.http.put<RetornoApiViewModel<ProdutoUnidadeViewModel>>(`${this.urlAddUpdate}/${id}`, produtoUnidadeViewModel);
    }
}