export class VeiculoViewModel {
    id?: number;
    descricao?: string;
    placa?: string;
    marca?: string;
    modelo?: string;
    ano_fab?: number;
    ano_mod?: number;
    cor?: string;
    chassi?: string;
    frota?: string;
    renavam?: string;
    uf?: string;
    tipo_veiculo?: number;
    rntrc?: string;
    ciot?: string;
    tara?: number;
    capacidade_kg?: number;
    capacidade_m3?: number;
    dt_venda?: string;
    dt_cadastro?: string;
    dt_compra?: string;
    numero_nf?: string;
    dados_compra?: string;
    cliente_id?: number;
    cliente_nome?: string;
    obs?: string;
    ativo?: boolean; 
}
