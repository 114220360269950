import { ProdutoEmpresaViewModel } from "../produto-empresa-view-model";
import { PaginacaoViewModel } from './../../../PaginacaoViewModel';

export class ProdutoEmpresaPesquisaViewModel extends ProdutoEmpresaViewModel implements PaginacaoViewModel {
    pagina: number = 1;
    quantidadeRegistrosPagina: number = 20;
    totalRegistros: number = 0;
    totalPaginas: number = 0;
    desativados: boolean = false;
    start?: number = 0;
    order?: string;
    consultarTodasEmpresas?: number = 0;
}
