import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertMessage } from '@modules/alert.configuration.service';
import { AutenticacaoService } from '@modules/autenticacao/services/autenticacao.service';
import { VeiculoService } from '@modules/manager/cadastro/servico/veiculo/veiculo.service';
import { ConfiguracaoService } from '@modules/manager/sistema/configuracao/configuracao.service';
import { ProdutoPesquisaViewModel } from '@modules/manager/view-models/cadastro/estoque/pesquisa/produto-pesquisa-view-model';
import { VeiculoPesquisaViewModel } from '@modules/manager/view-models/cadastro/servico/pesquisa/veiculo-pesquisa-view-model';
import { ListaPaginadaViewModel } from '@modules/manager/view-models/ListaPaginadaViewModel';
import { Uteis } from '@modules/utilizarios/uteis';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-veiculo-pesquisa-modal',
  templateUrl: './veiculo-pesquisa-modal.component.html',
  styles: [
  ]
})
export class VeiculoPesquisaModalComponent implements OnInit {

  @Input() abrirFecharPesquisaVeiculoModal: boolean = false;
  @Input() stringPesquisa: string = '';
  @Output() closeModal = new EventEmitter();
  @Output() veiculo: EventEmitter<any> = new EventEmitter();

  pesquisa: VeiculoPesquisaViewModel = new VeiculoPesquisaViewModel();
  listaPaginada: ListaPaginadaViewModel<ProdutoPesquisaViewModel> = new ListaPaginadaViewModel<ProdutoPesquisaViewModel>();
  listaVeiculos: VeiculoPesquisaViewModel[] = [];

  constructor(
    private alert$: AlertMessage,
    private spinner$: NgxSpinnerService,
    private veiculoService$: VeiculoService
  ) { }

  ngOnInit(): void {
    
    this.pesquisar();

  }
  
  pesquisar(){
    this.spinner$.show();
    this.veiculoService$.obterTodos(this.pesquisa).subscribe(
      retorno => {
        this.listaPaginada.itens = retorno.total > 0 ? retorno.result : [];

        this.listaPaginada.paginacao.totalRegistros = retorno.total;

        this.listaPaginada.paginacao.quantidadeRegistrosPagina = this.pesquisa.quantidadeRegistrosPagina;
        
        this.spinner$.hide();
    },
    error => {
      this.alert$.Danger('Erro', Uteis.ObterErroApi(error));
  
      this.spinner$.hide();
    });
    
  }

  veiculoSlecionado(item) {
    this.veiculo.emit(item);
  }

  mudarPagina(event: any) {

    this.pesquisa.start = (event.page - 1) * this.pesquisa.quantidadeRegistrosPagina;
    this.pesquisa.pagina= event.page;
    this.pesquisar();
  }

  closeModalVeiculo() {
    this.closeModal.emit(false);
  }
}
