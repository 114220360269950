import { PaginacaoViewModel } from "@manager/view-models/PaginacaoViewModel";
import { UsuarioDepartamentoViewModel } from "@manager/view-models/cadastro/usuario-manager/usuario-departamento-view-model";

export class UsuarioDepartamentoPesquisaViewModel extends UsuarioDepartamentoViewModel implements PaginacaoViewModel {
    pagina: number = 1;
    quantidadeRegistrosPagina: number = 20;
    totalRegistros: number = 0;
    totalPaginas: number = 0;
    desativados: boolean = false;
    start?: number;
    order?: string;
    lst_departamento?: number;
}
