<div class="form-group">
  <label for="uf_codigo" class="col-form-label-sm">Estado / Cidade</label>
  <div class="input-group">
      <select id="uf_codigo" name="uf_codigo" class="form-control form-control-sm mr-2" style="max-width:70px;" 
      [(ngModel)]="index_uf" (change)="pesquisarCidadesPorCodigoEstado()">
          <option [ngValue]="-1">-UF-</option>
          <option *ngFor="let item of listaEstados; let index;" [ngValue]="index">{{item.sigla}}</option>
      </select>
      <select id="codigo_ibge" name="codigo_ibge" class="form-control form-control-sm" [(ngModel)]="index_municipio"
              (change)="municipioSelecionado()">
          <option [ngValue]="-1">-Selecione-</option>
          <option *ngFor="let item of listaCidades; let index;" [ngValue]="index">{{item.descricao}}</option>
      </select>
  </div>
</div>