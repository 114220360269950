import { Injectable, ɵɵresolveBody } from '@angular/core';
import { HttpClient} from '@angular/common/http';


import { URLBASE } from 'src/modules/configuration.service';
import { Observable } from 'rxjs';
import { ListaPaginadaViewModel } from 'src/modules/manager/view-models/ListaPaginadaViewModel';
import { RetornoApiViewModel } from 'src/modules/manager/view-models/RetornoApiViewModel';
import { Uteis } from 'src/modules/utilizarios/uteis';

import { PesquisaGeralViewModel } from '@modules/admin/view-models/pesquisa/PesquisaGeralViewModel';
import { AutenticacaoService } from 'src/modules/autenticacao/services/autenticacao.service';
import { CadastroViewModel } from 'src/modules/admin/view-models/cadastro/CadastroViewModel';
import { ConfiguracaoPesquisaViewModel } from '../../view-models/sistema/pesquisa/configuracao-pesquisa-view-model';
import { ConfiguracaoViewModel } from '../../view-models/sistema/configuracao-view-model';
import { concatMap } from 'rxjs/operators';
import { NfLogViewModel } from '@modules/manager/view-models/sistema/nf-log-view-model';

@Injectable()
export class ConfiguracaoService {    
    idEmpresaMaxdata = this.autenticacao$.acessoUsuarioValue?.idEmpresaMaxdata;
  
    urlAPI =  `${URLBASE}/${this.idEmpresaMaxdata}/config_service`;
    //urlGet =  `${URLBASE}/${this.idEmpresaMaxdata}/config_service/get`;
    urlAddUpdate  = `${URLBASE}/${this.idEmpresaMaxdata}/config_service/addupdate`;
    
    nfLogUrlAddUpdate  = `${URLBASE}/${this.idEmpresaMaxdata}/nf_log_service/addupdate`;

    pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel()
    constructor(private http: HttpClient, 
        private autenticacao$: AutenticacaoService){ }

    obterTodos(pesquisa?: ConfiguracaoPesquisaViewModel) : Observable<RetornoApiViewModel<ConfiguracaoPesquisaViewModel[]>> {
        
        this.pesquisaGeral.select = `config.*,
                            config.emp_endereco_cidade_codigo_ibge as endereco_municipio_codigo_ibge,
                            endereco_municipio.descricao as endereco_municipio_descricao,
                            endereco_uf.sigla as endereco_uf_sigla,
                            endereco_uf.descricao as endereco_uf_descricao,
                            CONCAT(config.contabilidade_id, '' , contabilidade.nome) as contabilidade_nome,
                            transf_estoque_cliente.nome as transf_estoque_cliente_nome
                            `

        this.pesquisaGeral.where = ` and config.ativo = ${pesquisa?.desativados ? 0 : 1}`;

        this.pesquisaGeral.order = pesquisa?.order ? pesquisa?.order : 'config.id';

        if(pesquisa?.id) this.pesquisaGeral.where += ` and config.id = ${pesquisa?.id}`

        return this.http.post<RetornoApiViewModel<ConfiguracaoPesquisaViewModel[]>>(`${this.urlAPI}/get`, this.pesquisaGeral)
    }

    obterPorId(id: number, ): Observable<RetornoApiViewModel<ConfiguracaoViewModel[]>>{

        let pesquisa: PesquisaGeralViewModel = new PesquisaGeralViewModel();

        pesquisa.select = `config.*,
                        config.emp_endereco_cidade_codigo_ibge as emp_endereco_municipio_codigo_ibge,
                        endereco_municipio.descricao as emp_endereco_municipio_descricao,
                        endereco_uf.sigla as emp_endereco_uf_sigla,
                        endereco_uf.descricao as emp_endereco_uf_descricao,
                        CONCAT(config.contabilidade_id, ' - ' , contabilidade.nome) as contabilidade_nome,
                        transf_estoque_cliente.nome as transf_estoque_cliente_nome,
                        transf_estoque_cliente.cpf_cnpj as transf_estoque_cliente_cpf_cnpj
                        `

        pesquisa.where = ` and config.id = ${id}`;

        return this.http.post<RetornoApiViewModel<ConfiguracaoViewModel[]>>(`${this.urlAPI}/get`,pesquisa);
    }

    adicionar(configuracaoViewModel: CadastroViewModel<ConfiguracaoViewModel>) : Observable<RetornoApiViewModel<ConfiguracaoViewModel>>{
        return this.http.post<RetornoApiViewModel<ConfiguracaoViewModel>>(this.urlAddUpdate, configuracaoViewModel);
    }

    atualizar(id: number, configuracaoViewModel: CadastroViewModel<ConfiguracaoViewModel>) : Observable<RetornoApiViewModel<ConfiguracaoViewModel>>{
        return this.http.put<RetornoApiViewModel<ConfiguracaoViewModel>>(`${this.urlAddUpdate}/${id}`, configuracaoViewModel);
    }

    atualizarImagem(id, dados): Observable<any>{
        return this.http.put<any>(`${this.urlAPI}/UpdateBlob/${id}`,dados);
    }

    obterImagem(id): Observable<any>{

        let data = Object.assign({ id: id || 0, field_name: "img_logomarca" })
        
        return this.http.post<any>(`${this.urlAPI}/getBlob/${id}`,data);
    }

    adicionarNfLog(nfLogViewModel: CadastroViewModel<NfLogViewModel>) : Observable<RetornoApiViewModel<ConfiguracaoViewModel>>{
        return this.http.post<RetornoApiViewModel<ConfiguracaoViewModel>>(this.nfLogUrlAddUpdate, nfLogViewModel);
    }


    obterPorIdEmpresa(emp_id: number): Observable<RetornoApiViewModel<ConfiguracaoViewModel[]>>{

        let pesquisa: PesquisaGeralViewModel = new PesquisaGeralViewModel();
    
        pesquisa.select = `config.*`;
    
        pesquisa.where = ` and config.id = ${emp_id}`;
    
        return this.http.post<RetornoApiViewModel<ConfiguracaoViewModel[]>>(`${this.urlAPI}/get`,pesquisa);
      }
}