import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID,   } from '@angular/core';

import { ToastrModule } from 'ngx-toastr';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { MomentModule } from 'angular2-moment';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';

import { AppComponent } from './app.component';
import { DashboardComponent } from '../modules/manager/dashboard/dashboard.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app.routes';
import { NavegacaoModule } from '../modules/navegacao/navegacao.module';
import { TituloPaginaModule } from '../modules/components/titulo-paginas/titulo-paginas.module';
import { AutenticacaoService } from '../modules/autenticacao/services/autenticacao.service';
import { AuthGuard } from '../modules/autenticacao/services/guards/auth.guard';
import { JwtInterceptor } from '../modules/utilizarios/jwt.interceptor';
import { ErrorInterceptor } from '../modules/utilizarios/error.interceptor';
import { AdminAuthGuard } from '../modules/admin/admin.auth.guard';
import localePt from '@angular/common/locales/pt';
import { DatePipe, DecimalPipe, registerLocaleData } from '@angular/common';
import { DashboardVendasComponent } from '@modules/manager/dashboard/dashboard-vendas/dashboard-vendas.component';
import { DashboardVendaService } from '@modules/manager/dashboard/services/dashboard-venda.service';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { MaterialModule } from '@modules/utilizarios/material.module';

registerLocaleData(localePt, 'pt');


@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    DashboardVendasComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    NavegacaoModule,
    TituloPaginaModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(), // ToastrModule added
    NgIdleKeepaliveModule.forRoot(), // Idle Timeout
    MomentModule, // Idle Timeout
    ModalModule.forRoot(),
    TabsModule.forRoot(),
    NgxChartsModule,
    MaterialModule


  ],
  providers: [
    AuthGuard,
    AdminAuthGuard,
    AutenticacaoService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: LOCALE_ID,
      useValue: 'pt-BR',
    },
    DashboardVendaService,
    DatePipe,
    DecimalPipe,
  ],
  bootstrap: [
    AppComponent
  ],
  exports:[
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA // Tells Angular we will have custom tags in our templates
  ]
})
export class AppModule { }
