import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertMessage } from '@modules/alert.configuration.service';
import { Uteis } from '@modules/utilizarios/uteis';
import { NgxSpinnerService } from 'ngx-spinner';
import { EnderecoMunicipioService } from './../../../manager/cadastro/cliente-fornecedor/endereco-municipio/endereco-municipio.service';
import { EnderecoMunicipioPesquisaViewModel } from './../../../manager/view-models/cadastro/cliente-fornecedor/pesquisa/endereco-municipio-pesquisa-view-model';

@Component({
  selector: 'app-municipio-pesquisa-modal',
  templateUrl: './municipio-pesquisa-modal.component.html',
  styles: [
  ]
})
export class MunicipioPesquisaModalComponent implements OnInit {
  @Input() abrirFecharModalMunicipio: boolean = false;
  @Input() stringPesquisa: string = '';
  @Output() closeModal = new EventEmitter();
  @Output() municipio: EventEmitter<any> = new EventEmitter();

  pesquisa: EnderecoMunicipioPesquisaViewModel = new EnderecoMunicipioPesquisaViewModel();

  listaMunicipios: EnderecoMunicipioPesquisaViewModel[] = [];

  constructor(
    private alert$: AlertMessage,
    private spinner$: NgxSpinnerService,
    private enderecoMunicipioService$: EnderecoMunicipioService
  ) { }

  ngOnInit(): void {

    setTimeout(() => {

      this.pesquisa.descricao = this.stringPesquisa;

    }, 100);

  }

  pesquisar(){
    this.spinner$.show();

    this.enderecoMunicipioService$.obterTodos(this.pesquisa).subscribe(
      retorno => {
        this.listaMunicipios = retorno.total > 0 ? retorno.result: [];
        this.spinner$.hide();
    },
    error => {
      this.alert$.Danger('Erro', Uteis.ObterErroApi(error));
  
      this.spinner$.hide();
    });
    
  }

  municipioSlecionado(item) {
    this.municipio.emit(item);
  }

  closeModalMunicipio() {
    this.closeModal.emit(false);
  }


}
