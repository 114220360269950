import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

import { RetornoApiViewModel } from 'src/modules/manager/view-models/RetornoApiViewModel'
import { Acesso } from '../dados/acesso';

import { URLBASE } from 'src/modules/configuration.service';
import { Router } from '@angular/router';
import { Uteis } from 'src/modules/utilizarios/uteis';

const urlFinal: string = `${URLBASE}`;

@Injectable({ providedIn: 'root' })
export class AutenticacaoService {
    private acessoUsuarioSubject?: BehaviorSubject<Acesso>;
    private chaveLocalStorage: string = "acessoUsuario";
    public acessoUsuario: Observable<Acesso> = new Observable;

    constructor(
        private http: HttpClient,
        private router: Router,
    ) {
        this.verificar();
    }

    verificar() {
        this.acessoUsuarioSubject = new BehaviorSubject<Acesso>(JSON.parse(localStorage.getItem(this.chaveLocalStorage)!));
        this.acessoUsuario = this.acessoUsuarioSubject.asObservable();
    }

    public get acessoUsuarioValue(): Acesso | undefined {
        if (localStorage.getItem(this.chaveLocalStorage)) { return this.acessoUsuarioSubject?.value; }
    }

    login(dadosUser: any): Observable<RetornoApiViewModel<Acesso[]>> {
        return this.http.post<RetornoApiViewModel<Acesso[]>>(`${urlFinal}/maxdataAdm/usuario_adm_service/token`, dadosUser);
    }

    refreshToken(dadosUser: any): Observable<RetornoApiViewModel<Acesso[]>> {
        return this.http.post<RetornoApiViewModel<Acesso[]>>(`${urlFinal}/maxdataAdm/usuario_adm_service/tokenrefresh`, dadosUser);
    }

    salvarAcesso(acesso: Acesso) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem(this.chaveLocalStorage, JSON.stringify(acesso));
        this.acessoUsuarioSubject?.next(acesso);
    }

    getAcesso() {
        return JSON.parse(localStorage.getItem(this.chaveLocalStorage) || '{}');
    }

    logout() {
        // remove user from local storage and set current user to null
        localStorage.removeItem(this.chaveLocalStorage);
        Uteis.excluirFiltro();
        this.acessoUsuarioSubject?.next(null!);
        location.reload(true);
    }
}