import { Injectable } from '@angular/core';
import {URLBASE} from "@modules/configuration.service";
import {HttpClient} from "@angular/common/http";
import {AutenticacaoService} from "@modules/autenticacao/services/autenticacao.service";
import {Observable} from "rxjs";
import {RetornoApiViewModel} from "@manager/view-models/RetornoApiViewModel";
import {PesquisaGeralViewModel} from "@modules/admin/view-models/pesquisa/PesquisaGeralViewModel";
import {ProdutoEquivalentePesquisaViewModel} from "@manager/view-models/cadastro/estoque/pesquisa/produto-equivalente-pesquisa-view-model";
import {CadastroViewModel} from "@modules/admin/view-models/cadastro/CadastroViewModel";
import {ProdutoEquivalenteViewModel} from "@manager/view-models/cadastro/estoque/produto-equivalente-view-model";

@Injectable({
  providedIn: 'root'
})
export class ProdutoEquivalenteService {

  idEmpresaMaxdata = this.autenticacao$.acessoUsuarioValue?.idEmpresaMaxdata;

  private urlMaxApi = `${URLBASE}/${this.idEmpresaMaxdata}/produto_equivalente_service`;
  private urlGet =  `${this.urlMaxApi}/get`;
  private urlAddUpdate  = `${this.urlMaxApi}/addupdate`;
  private urlDelete = `${this.urlMaxApi}/delete`;

  constructor(private http: HttpClient, private autenticacao$: AutenticacaoService){ }

  obterTodos(pesquisa: ProdutoEquivalentePesquisaViewModel) : Observable<RetornoApiViewModel<ProdutoEquivalentePesquisaViewModel[]>> {

    let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel();

    pesquisaGeral.select = `produto_equivalente.produto_id_origem, 
                            produto_equivalente.produto_id_destino as id,
                            produto_origem.id as produto_origem_id,                            
                            produto_destino.id as produto_destino_id, 
                            produto_destino.descricao,
                            produto_empresa_origem.produto_id as produto_empresa_origem_produto_id,
                            produto_empresa_destino.produto_id as produto_empresa_destino_produto_id,
                            produto_empresa_destino.qtd_estoque_atual,
                            produto_empresa_destino.vlr_custo,
                            produto_empresa_destino.vlr_venda`;

    if(pesquisa?.produto_id_origem) pesquisaGeral.where = `AND produto_equivalente.produto_id_origem = '${pesquisa?.produto_id_origem}'`;

    return this.http.post<RetornoApiViewModel<ProdutoEquivalentePesquisaViewModel[]>>(this.urlGet, pesquisaGeral)
  }

  adicionar(produtoEquivalenteViewModel: CadastroViewModel<ProdutoEquivalenteViewModel>) : Observable<RetornoApiViewModel<ProdutoEquivalenteViewModel>>{
    return this.http.post<RetornoApiViewModel<ProdutoEquivalenteViewModel>>(this.urlAddUpdate, produtoEquivalenteViewModel);
  }

  remover(id: number, produtoEquivalenteViewModel: CadastroViewModel<ProdutoEquivalenteViewModel>) : Observable<RetornoApiViewModel<any>>{
    return this.http.request<RetornoApiViewModel<any>>('delete',`${this.urlDelete}/${id}`, {body: produtoEquivalenteViewModel})
  }

}
