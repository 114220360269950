export class TipoVencimentoPgto{
    private constructor(id: 'T'|'A'|'V'|'H', descricao:string) {
        this._id = id;
        this._descricao = descricao;
    }
    private _id:'T'|'A'|'V'|'H';
    private _descricao:string;

    get id(){return this._id};
    get descricao(){return this._descricao};

    static Todos: TipoVencimentoPgto = new TipoVencimentoPgto('T', 'Todos');
    static AVencer: TipoVencimentoPgto = new TipoVencimentoPgto('A', 'A Vencer')
    static Vencidas: TipoVencimentoPgto = new TipoVencimentoPgto('V', 'Vencidas');
    static VenceHoje: TipoVencimentoPgto = new TipoVencimentoPgto('H', 'Hoje');
    static listaTiposVencimento():TipoVencimentoPgto[]{return [this.Todos, this.AVencer, this.Vencidas, this.VenceHoje];}
}