import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CadastroViewModel } from '@modules/admin/view-models/cadastro/CadastroViewModel';
import { AlertMessage } from '@modules/alert.configuration.service';
import { ProdutoGradeDescricaoService } from '@modules/manager/cadastro/estoque/produto-grade-descricao/produto-grade-descricao.service';
import { ProdutoGradeTamanhoService } from '@modules/manager/cadastro/estoque/produto-grade-tamanho/produto-grade-tamanho.service';
import { ProdutoGradeService } from '@modules/manager/cadastro/estoque/produto-grade/produto-grade.service';
import { ProdutoGradeDescricaoPesquisaViewModel } from '@modules/manager/view-models/cadastro/estoque/pesquisa/produto-grade-descricao-pesquisa-view-model';
import { ProdutoGradeTamanhoPesquisaViewModel } from '@modules/manager/view-models/cadastro/estoque/pesquisa/produto-grade-tamanho-pesquisa-view-model';
import { ProdutoGradeViewModel } from '@modules/manager/view-models/cadastro/estoque/produto-grade-view-model';
import { AlertMensagemPadrao } from '@modules/utilizarios/alertMensagemPadrao';
import { Uteis } from '@modules/utilizarios/uteis';
import { DisplayMessage, GenericValidator, ValidationMessages } from '@modules/validacao/generic-form-validation';
import { MensagensValidacao } from '@modules/validacao/mensagensValidacao';
import { NgxSpinnerService } from 'ngx-spinner';
import { isNumber } from 'util';

@Component({
  selector: 'app-produto-grade-cadastro-modal',
  templateUrl: './produto-grade-cadastro-modal.component.html',
  styles: [
  ]
})
export class ProdutoGradeCadastroModalComponent implements OnInit {

  @Input() produtoGrade!: ProdutoGradeViewModel;
  @Input() produtoId: number;
  @Input() produtoGradeId: number;
  @Input() alterar: boolean = false;
  @Input() abrirFecharProdutoGradeCadastroModal: boolean = false;
  @Output() closeModal = new EventEmitter();

  cadastro_form_produto_grade!: FormGroup;
  validationMessages?: ValidationMessages;
  genericValidator?: GenericValidator;
  displayMessage: DisplayMessage = {};
  carregando: boolean = false;
  objProdutoGrade: CadastroViewModel<ProdutoGradeViewModel> = new CadastroViewModel<ProdutoGradeViewModel>();
  listaProdutoGradeDescricao: ProdutoGradeDescricaoPesquisaViewModel[] = [];
  listaProdutoGradeTamanho: ProdutoGradeTamanhoPesquisaViewModel[] = [];

  modalDescricao: boolean = false;
  modalDescricaoAlterar: boolean = false;
  modalDescricaoProdutoGradeDescricaoId: number = 0;

  modalTamanho: boolean = false;
  modalTamanhoAlterar: boolean = false;
  modalTamanhoProdutoGradeTamanhoId: number = 0;

  constructor(private fb: FormBuilder,
    private alert: AlertMessage,    
    private spinner: NgxSpinnerService,
    private produtoGrade$: ProdutoGradeService,
    private produtoGradeTamanho$: ProdutoGradeTamanhoService,
    private produtoGradeDescricao$: ProdutoGradeDescricaoService,) {
      this.messagesValidations()
     }

  ngOnInit(): void {

    this.objProdutoGrade.obj = new ProdutoGradeViewModel();

    this.criarFormulario();

    this.obterListaProdutoGradeDescricao();

    this.obterListaProdutoGradeTamanho();

      this.alterar = isNumber(this.produtoGradeId) && this.produtoGradeId > 0;

      if (this.alterar) {

        this.spinner.show();

        this.produtoGrade$.obterPorId(this.produtoGradeId || 0).subscribe(
          retorno => {

            this.spinner.hide();

            if (retorno.total <= 0) this.alert.Danger('Erro', 'Registro não encontrado');

            this.objProdutoGrade.obj = retorno.total > 0 ? retorno.result[0] : new ProdutoGradeViewModel();

            this.criarFormulario();
          },
          error => {
            this.spinner.hide();

            this.alert.Danger('Erro', Uteis.ObterErroApi(error))
          });
      } 
  }

  onSubmit() {

    this.cadastro_form_produto_grade.markAllAsTouched();

    if (!this.cadastro_form_produto_grade.valid) {

      if (typeof this.genericValidator !== 'undefined') {

        this.displayMessage = this.genericValidator.processarMensagens(this.cadastro_form_produto_grade);

      }

      let mensagem = Uteis.ConverterObjetoToArray(this.displayMessage)

      return this.mensagemCamposInvalidos(mensagem);
    }

    this.carregando = true;

    if (this.alterar) this.atualizar();
    else this.adicionar();
  }

  criarFormulario() {
    this.cadastro_form_produto_grade = this.fb.group({
      id:                         [{ value: !this.alterar ? 0 : this.objProdutoGrade.obj?.id, disabled: true }],
      produto_id:                 [!this.alterar ? this.produtoId : this.objProdutoGrade.obj?.produto_id, [Validators.required, Validators.min(1)]],
      produto_grade_descricao_id: [!this.alterar ? null : this.objProdutoGrade.obj?.produto_grade_descricao_id,[Validators.required, Validators.min(1)]],
      produto_grade_tamanho_id:   [!this.alterar ? null : this.objProdutoGrade.obj?.produto_grade_tamanho_id, [Validators.required, Validators.min(1)]],
      estoque:                    [!this.alterar ? 0 : this.objProdutoGrade.obj?.estoque],
      estoque_minimo:             [!this.alterar ? 0 : this.objProdutoGrade.obj?.estoque_minimo, [Validators.min(0)]],
      ativo:                      [!this.alterar ? true : this.objProdutoGrade.obj?.ativo],
      codigo_barras:              [!this.alterar ? null : this.objProdutoGrade.obj?.codigo_barras, [Validators.minLength(12), Validators.maxLength(14)]],
      vlr_venda:                  [!this.alterar ? 0 : this.objProdutoGrade.obj?.vlr_venda, [Validators.min(0)]],
    })
  }

  atualizarDadosObjeto() {
    this.objProdutoGrade.obj = Object.assign({}, this.objProdutoGrade.obj, this.cadastro_form_produto_grade.value);
  }

  atualizar() {

    this.atualizarDadosObjeto();
    
    if (this.objProdutoGrade.obj?.id)
      this.produtoGrade$.atualizar(this.objProdutoGrade.obj?.id, this.objProdutoGrade).subscribe(
          () => {

            this.mensagensCadastro();

            this.closeModalCadastroProdutoGrade();

            this.carregando = false;

          },
          error => {

            this.carregando = false;

            this.alert.Danger('Erro', Uteis.ObterErroApi(error));

          }
        )
  }

  adicionar() {

    this.atualizarDadosObjeto();

    this.produtoGrade$.adicionar(this.objProdutoGrade).subscribe(
      retorno => {

        this.alert.Success('Sucesso','Registro cadastrado com sucesso');

        this.closeModalCadastroProdutoGrade();

        this.limparForm();

        this.carregando = false;
      },
      error => {

        this.carregando = false;

        this.alert.Danger('Erro', Uteis.ObterErroApi(error))

      }
    )
  }

  limparForm() {
    this.cadastro_form_produto_grade.reset();
  }

  messagesValidations() {
    this.validationMessages = {
      descricao: {
        required: MensagensValidacao.Obrigatorio('Descrição'),
        minlength: MensagensValidacao.MinimoCaracteres('Descricão', 2),
      },
      codigo_barras: {
        minlength: MensagensValidacao.MinimoCaracteres('Codigo de barras', 12),
        maxlength: MensagensValidacao.MaximoCaracteres('Codigo de barras', 14),
      }
    }
    this.genericValidator = new GenericValidator(this.validationMessages);
  }

  obterListaProdutoGradeDescricao() {
    this.produtoGradeDescricao$.obterTodos().subscribe(
      
      (retorno) => this.listaProdutoGradeDescricao = retorno.total > 0 ? retorno.result : [],

      (error) => this.alert.Danger("Erro", Uteis.ObterErroApi(error))
    );
  }

  obterListaProdutoGradeTamanho() {
    this.produtoGradeTamanho$.obterTodos().subscribe(

      (retorno) => this.listaProdutoGradeTamanho = retorno.total > 0 ? retorno.result : [],

      (error) => this.alert.Danger("Erro", Uteis.ObterErroApi(error))
    );
  }

  closeModalCadastroProdutoGrade() {
    this.closeModal.emit(false);
  }

  private mensagensCadastro(): void {
    this.alert.Success('Sucesso', `Registro ${this.alterar ? 'atualizado' : 'cadastrado'} com Sucesso.`);
    if (this.alterar) return;
    this.alert.Info('Informativo', 'Formulário de cadastro foi limpo para realizar um novo cadastro.', { timeOut: 10000 });
  }

  mensagemCamposInvalidos(mensagens: any) {
    this.alert.Warning(AlertMensagemPadrao.formularioInvalido.titulo, AlertMensagemPadrao.formularioInvalido.mensagem + '<br><br>' + mensagens.toString().replace(/,/g, ''));
  }

  abrirModalDescricao(alterar: boolean) {
    this.modalDescricao = true;  
    this.modalDescricaoAlterar = alterar;
    if(alterar){
      this.modalDescricaoProdutoGradeDescricaoId = this.produto_grade_descricao_id.value;
    }
    else{
      this.modalDescricaoProdutoGradeDescricaoId = null;
    }
  }

  abrirModalTamanho(alterar: boolean) {
    this.modalTamanho = true;  
    this.modalTamanhoAlterar = alterar;
    if(alterar){
      this.modalTamanhoProdutoGradeTamanhoId = this.produto_grade_tamanho_id.value;
    }
    else{
      this.modalTamanhoProdutoGradeTamanhoId = null;
    }
  }

  fecharModalDescricao(event){
    this.modalDescricao = event;
    this.obterListaProdutoGradeDescricao();
    this.cadastro_form_produto_grade.markAllAsTouched();
    this.modalDescricaoProdutoGradeDescricaoId = null;
  }

  fecharModalTamanho(event){
    this.modalTamanho = event;
    this.obterListaProdutoGradeTamanho();
    this.cadastro_form_produto_grade.markAllAsTouched();
    this.modalTamanhoProdutoGradeTamanhoId = null;
  }

  get produto_grade_descricao_id() { return this.cadastro_form_produto_grade.get("produto_grade_descricao_id"); }
  get produto_grade_tamanho_id() { return this.cadastro_form_produto_grade.get("produto_grade_tamanho_id"); }

}
