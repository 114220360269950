import { Injectable } from '@angular/core';
import {URLBASE} from "@modules/configuration.service";
import {HttpClient} from "@angular/common/http";
import {AutenticacaoService} from "@modules/autenticacao/services/autenticacao.service";
import {UsuarioDepartamentoPesquisaViewModel} from "@manager/view-models/cadastro/usuario-manager/pesquisa/usuario-departamento-pesquisa-view-model";
import {Observable} from "rxjs";
import {RetornoApiViewModel} from "@manager/view-models/RetornoApiViewModel";
import {PesquisaGeralViewModel} from "@modules/admin/view-models/pesquisa/PesquisaGeralViewModel";
import {CadastroViewModel} from "@modules/admin/view-models/cadastro/CadastroViewModel";
import {UsuarioDepartamentoViewModel} from "@manager/view-models/cadastro/usuario-manager/usuario-departamento-view-model";

@Injectable({
  providedIn: 'root'
})
export class UsuarioDepartamentoService {

  idEmpresaMaxdata = this.autenticacaoService.acessoUsuarioValue?.idEmpresaMaxdata;
  private urlMaxApi = `${URLBASE}/${this.idEmpresaMaxdata}/usuario_departamento_service`;
  private urlGet =  `${this.urlMaxApi}/get`;
  private urlAddUpdate  = `${this.urlMaxApi}/addupdate`;
  private urlDelete = `${this.urlMaxApi}/delete`;

  constructor(
      private http: HttpClient,
      private autenticacaoService: AutenticacaoService,
  ) { }

  obterTodos(pesquisa?: UsuarioDepartamentoPesquisaViewModel): Observable<RetornoApiViewModel<UsuarioDepartamentoPesquisaViewModel[]>> {

    let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel();

    pesquisaGeral.select = `usuario_departamento.*,
                            departamento.descricao AS departamento_descricao,
                            departamento.lst_departamento AS lst_departamento,
                            usuario.nome AS usuario_nome,
                            usuario.apelido AS usuario_apelido,
                            usuario.email AS usuario_email, 
                            usuario.id_usuario_adm AS usuario_id_usuario_adm`;

    if (pesquisa?.usuario_id) pesquisaGeral.where += ` and usuario_departamento.usuario_id = ${pesquisa?.usuario_id}`;

    if (pesquisa?.lst_departamento) pesquisaGeral.where += ` and departamento.lst_departamento IN (${pesquisa?.lst_departamento})`;

    if (pesquisa?.emp_id) pesquisaGeral.where += ' and usuario_departamento.emp_id = ' + pesquisa.emp_id;

    return this.http.post<RetornoApiViewModel<UsuarioDepartamentoPesquisaViewModel[]>>(this.urlGet, pesquisaGeral);
  }

  adicionar(usuarioDepartamentoViewModel: CadastroViewModel<UsuarioDepartamentoViewModel>): Observable<RetornoApiViewModel<UsuarioDepartamentoViewModel>> {
    return this.http.post<RetornoApiViewModel<UsuarioDepartamentoViewModel>>(this.urlAddUpdate, usuarioDepartamentoViewModel);
  }

  remover(id: number, usuarioDepartamentoViewModel: CadastroViewModel<UsuarioDepartamentoViewModel>) : Observable<RetornoApiViewModel<any>> {
    return this.http.request<RetornoApiViewModel<any>>('delete', `${this.urlDelete}/${id}`, {body: usuarioDepartamentoViewModel})
  }

}
