<p-dialog [(visible)]="abrirFecharModal" [modal]="true" [closable]="false" [draggable]="false" [maximizable]="false"
    [baseZIndex]="10000" [draggable]="false" [resizable]="false" [responsive]="true">

    <p-header>
        <div class="modal-header text-white">
            <h5 class="modal-title">
                Imprimir Pré-Venda / DAV Orçamento
            </h5>
        </div>
    </p-header>
    <div class="btn-group-vertical d-flex">
        <button type="button" class="btn btn-light d-flex justify-content-center flex-wrap" (click)="imprimirVenda()">
            <i class="material-icons mr-2">print</i>Imprimir Padrão
        </button>
        <button type="button" class="btn btn-light d-flex justify-content-center flex-wrap"
            (click)="imprimirVendaBobinaTermica()"><small> <i class="material-icons mr-2">print</i></small>Bobina
            Térmica</button>
        <button type="button" class="btn btn-light" 
        (click)="closeModalImpressao()">Fechar</button>

    </div>
    <app-loanding *ngIf="loanding"
    [textoP]="'A venda está sendo impressa.'"
    
    ></app-loanding>
    
</p-dialog>


