<p-dialog [(visible)]="abrirFecharCadastroGrupoModal" [modal]="true" [closable]="true" [style]="{width: '100vw'}"
[maximizable]="true" [baseZIndex]="10000" [draggable]="false" [resizable]="false"
(onHide)="closeCadastroSubGrupo()">

<form (ngSubmit)="onSubmit()" [formGroup]="cadastroForm">
    <div class="row">
        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <app-titulo-paginas [tituloPagina]="tituloPagina"></app-titulo-paginas>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6 text-right btns-group">
             <a class="btn btn-sm btn-outline-secondary" (click)="closeCadastroSubGrupo()"><i
                    class="material-icons">close</i>Fechar</a>
            <button class="btn btn-sm btn-outline-success" id="salvar" type="submit" [disabled]="carregando">
                <div [ngSwitch]="carregando">
                    <div *ngSwitchCase="true">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;
                        <span>Carregando ...</span>
                    </div>
                    <div *ngSwitchDefault>
                        <i class="material-icons">done_all</i><span>Salvar</span>
                    </div>
                </div>
            </button>
        </div>
    </div>
    <div class="card shadow-sm">
        <div class="card-body pt-1 pb-1">
            <div class="row">
                <div class="col-sm-4 col-md-3 col-lg-2 col-xl-1">
                    <div class="form-group">
                        <label for="ID" class="col-form-label-sm">Código</label>
                        <input type="number" id="ID" name="ID" class="form-control form-control-sm noscroll" placeholder="Código"
                            formControlName="id">
                    </div>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-4 col-xl-4">
                    <div class="form-group">
                        <label for="descricao" class="col-form-label-sm">Descrição do Sub-Grupo<span class="text-muted">
                                (Obrigatório)</span></label>
                        <input type="text" id="descricao" name="descricao" class="form-control form-control-sm"
                            placeholder="Descrição do Sub-Grupo" formControlName="descricao"
                            [ngClass]="{'is-invalid': displayMessage.descricao}" />
                        <span class="invalid-feedback" *ngIf="displayMessage.descricao">
                            <p [innerHTML]="displayMessage.descricao"></p>
                        </span>
                    </div>
                </div>
                <div class="col-sm-8 col-md-6 col-lg-4 col-xl-2">
                    <div class="form-group">
                        <label for="grupo" class="col-form-label-sm">Grupo<span class="text-muted">
                                (Obrigatório)</span></label>
                        <input id="grupo" name="grupo" class="form-control form-control-sm" type="hidden"
                            formControlName="produto_grupo_id" autocomplete="off" [(ngModel)]="produto_grupo.id">

                        <input id="grupoAutocomplete" name="grupo" class="form-control form-control-sm"
                            placeholder="Digite para encontrar um Grupo" formControlName="produto_grupo_descricao"
                            autocomplete="off" [(ngModel)]="produto_grupo.descricao"
                            [typeahead]="dataSourceAutocomplete" typeaheadOptionField="descricao"
                            (typeaheadOnSelect)="onSelect($event)" [typeaheadAsync]="true" [isAnimated]="false"
                            [typeaheadMinLength]="1" [typeaheadScrollable]="true" [typeaheadOptionsInScrollableView]="5"
                            [adaptivePosition]="true" (typeaheadNoResults)="typeaheadNoResults($event)"
                            [ngClass]="{'is-invalid': noResult}">
                        <span class="invalid-feedback" *ngIf="displayMessage.produto_grupo_nome">
                            <p [innerHTML]="displayMessage.produto_grupo_nome"></p>
                        </span>
                    </div>
                </div>
                <div class="col-sm-4 col-md-3 col-lg-2 col-xl-1">
                    <div class="form-group">
                        <label class="col-form-label-sm" for="aliq_comissao">Comissão %</label>
                        <input type="number" id="aliq_comissao" name="aliq_comissao"
                            class="form-control form-control-sm" placeholder="Comissão %" step="0.1"
                            formControlName="aliq_comissao" [ngClass]="{'is-invalid': displayMessage.aliq_comissao}" />
                        <span class="invalid-feedback" *ngIf="displayMessage.aliq_comissao">
                            <p [innerHTML]="displayMessage.aliq_comissao"></p>
                        </span>
                    </div>
                </div>

                <div class="col-sm-4 col-md-3 col-lg-2 col-xl-1">
                    <div class="form-group">
                        <div class="custom-control custom-checkbox">
                            <input type="checkbox" id="ativo" name="ativo" class="custom-control-input form-control-sm"
                                formControlName="ativo" />
                            <label class="custom-control-label col-form-label-sm status-filtro"
                                for="ativo">Ativo</label>
                        </div>
                    </div>
                </div>               
            </div>
        </div>
    </div>
</form>

</p-dialog>