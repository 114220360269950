import { Component, OnInit } from '@angular/core';
import { Acesso } from 'src/modules/autenticacao/dados/acesso';
import { AutenticacaoService } from 'src/modules/autenticacao/services/autenticacao.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  acessoUsuario: Acesso | undefined;

  constructor(private autenticacaoService: AutenticacaoService) { 
    this.autenticacaoService.acessoUsuario.subscribe(acesso => this.acessoUsuario = acesso);
  }

  ngOnInit() {
  }

}
