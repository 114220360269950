import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CadastroViewModel } from '@modules/admin/view-models/cadastro/CadastroViewModel';
import { AlertMessage } from '@modules/alert.configuration.service';
import { UsuarioManagerService } from '@modules/manager/cadastro/usuario-manager/usuario-manager.service';
import { AgendamentoItemService } from '@modules/manager/movimentacao/agendamento-item/agendamento-item.service';
import { AgendamentoService } from '@modules/manager/movimentacao/agendamento/agendamento.service';
import { ClientePesquisaViewModel } from '@modules/manager/view-models/cadastro/cliente-fornecedor/pesquisa/cliente-pesquisa-view-model';
import { UsuarioManagerPesquisaViewModel } from '@modules/manager/view-models/cadastro/usuario-manager/pesquisa/usuario-manager-pesquisa-view-model';
import { ListaPaginadaViewModel } from '@modules/manager/view-models/ListaPaginadaViewModel';
import { AgendamentoItemViewModel } from '@modules/manager/view-models/movimentacao/agendamento-item-view-model';
import { AgendamentoViewModel } from '@modules/manager/view-models/movimentacao/agendamento-view-model';
import { AgendamentoItemPesquisaViewModel } from '@modules/manager/view-models/movimentacao/pesquisa/agendamento-item-pesquisa-view-model';
import { AgendamentoPesquisaViewModel } from '@modules/manager/view-models/movimentacao/pesquisa/agendamento-pesquisa-view-model';
import { AlertMensagemPadrao } from '@modules/utilizarios/alertMensagemPadrao';
import { Uteis } from '@modules/utilizarios/uteis';
import { DisplayMessage, GenericValidator, ValidationMessages } from '@modules/validacao/generic-form-validation';
import { MensagensValidacao } from '@modules/validacao/mensagensValidacao';
import { BsModalService } from 'ngx-bootstrap/modal';

import { NgxSpinnerService } from 'ngx-spinner';
import { isNumber } from 'util';

@Component({
  selector: 'app-agendamento-modal',
  templateUrl: './agendamento-modal.component.html',
  styles: [
  ]
})
export class AgendamentoModalComponent implements OnInit {
  @Input() agendamento!: AgendamentoPesquisaViewModel;
  @Input() agendamentoId: number;
  @Input() alterar: boolean = false;
  @Input() abrirFecharAgendamentoModal: boolean = false;
  @Output() closeModal = new EventEmitter();

  cadastroForm!: FormGroup;
  validationMessages?: ValidationMessages;
  genericValidator?: GenericValidator;
  displayMessage: DisplayMessage = {};
  carregando: boolean = false;

  objAgendamento: CadastroViewModel<AgendamentoPesquisaViewModel> = new CadastroViewModel<AgendamentoPesquisaViewModel>();
  objAgendamentoItem: CadastroViewModel<AgendamentoItemPesquisaViewModel> = new CadastroViewModel<AgendamentoItemPesquisaViewModel>();

  listaVendedores: UsuarioManagerPesquisaViewModel[] = [];
  listaItem: AgendamentoItemPesquisaViewModel[] = [];

  abrirFecharModalCliente: boolean = false;
  abrirFecharPesquisaServicoModal: boolean = false;
  objAgendamentoReset: AgendamentoPesquisaViewModel = new AgendamentoPesquisaViewModel();
  objAgendamentoItemReset: AgendamentoItemPesquisaViewModel = new AgendamentoItemPesquisaViewModel();

  constructor(private fb: FormBuilder,
    private modal$: BsModalService,
    private alert: AlertMessage,    
    private spinner: NgxSpinnerService,
    private agendamento$: AgendamentoService,
    private agendamentoItem$: AgendamentoItemService,
    private usuarioManager$: UsuarioManagerService) {
      this.messagesValidations()
     }

  ngOnInit(): void {

    this.criarFormulario();

    this.objAgendamentoReset = this.cadastroForm.get('agendamento')?.value;

    this.objAgendamentoItemReset = this.cadastroForm.get('agendamento_item')?.value;

    this.obterListaVendedor();

    this.alterar = isNumber(this.agendamentoId) && this.agendamentoId > 0;

    if (this.alterar) {

      this.spinner.show();

      this.agendamento$.obterPorId(this.agendamentoId|| 0).subscribe(
        retorno => {

          this.spinner.hide();

          if (retorno.total <= 0) this.alert.Danger('Erro', 'Agendamento não encontrado');

          this.objAgendamento.obj = retorno.total > 0 ? retorno.result[0] : new AgendamentoPesquisaViewModel();

          this.criarFormulario();

          this.obterListaItem();
        },
        error => {
          this.spinner.hide();

          this.alert.Danger('Erro', Uteis.ObterErroApi(error))
        });
    }
  }

  onSubmit() {

    this.cadastroForm.get('agendamento')?.markAllAsTouched();

    if (!this.cadastroForm.get('agendamento')?.valid) {

      if (typeof this.genericValidator !== 'undefined') {

        this.displayMessage = this.genericValidator.processarMensagens(this.cadastroForm.get('agendamento'));

      }

      let mensagem = Uteis.ConverterObjetoToArray(this.displayMessage)

      return this.mensagemCamposInvalidos(mensagem);
    }

    if (!this.cadastroForm.get('agendamento')?.dirty) {

      this.alert.Info(AlertMensagemPadrao.formularioNaoAlterado.titulo, AlertMensagemPadrao.formularioNaoAlterado.mensagem);

      return
    }

    this.carregando = true;

    if (this.alterar) this.atualizar();
    else this.adicionar();
  }

  criarFormulario() {
    this.cadastroForm = this.fb.group({
      agendamento: this.fb.group({
          id:             [!this.alterar ? 0 : this.objAgendamento.obj?.id || 0 ],
          dt_inicial:     [!this.alterar ? null : Uteis.DateTimeZone(this.objAgendamento.obj?.dt_inicial || new Date()),   [Validators.required]],
          dt_final:       [!this.alterar ? null : Uteis.DateTimeZone(this.objAgendamento.obj?.dt_final || null), ],
          atendente_id:   [!this.alterar ? 0    : this.objAgendamento.obj?.atendente_id, [Validators.required]],
          cliente_id:     [!this.alterar ? 0    : this.objAgendamento.obj?.cliente_id,   [Validators.required]],
          cliente_nome:   [!this.alterar ? null : this.objAgendamento.obj?.cliente_nome, ],
          status:         [!this.alterar ? '2' : this.objAgendamento.obj?.status,        ],
          obs:            [!this.alterar ? null : this.objAgendamento.obj?.obs,          ],
          enviar_email:   [!this.alterar ? false: this.objAgendamento.obj?.enviar_email  ],
          dt_email:       [!this.alterar ? null : this.objAgendamento.obj?.dt_email,     ],
          emp_id:         [!this.alterar ? 0    : this.objAgendamento.obj?.emp_id,       ],
      }),
      agendamento_item: this.fb.group({
        id:                   [0],
        agendamento_id:       [this.objAgendamento.obj?.id || 0, [Validators.required]],
        produto_id:           [0, [Validators.required, Validators.min(1)]],
        produto_descricao:    [null],        
        qtd:                  [1 , [Validators.min(0)]],
      })
  
    })
  }

  atualizarDadosObjeto() {
    this.objAgendamento.obj = Object.assign({}, this.objAgendamento.obj, this.cadastroForm.get('agendamento').value);

    if (this.objAgendamento.obj?.dt_inicial) { this.objAgendamento.obj.dt_inicial = this.objAgendamento.obj?.dt_inicial + ":00"; }

    if (this.objAgendamento.obj?.dt_final) { this.objAgendamento.obj.dt_final = this.objAgendamento.obj?.dt_final + ":00"; }
  }

  atualizar() {

    this.atualizarDadosObjeto();
    
    if (this.objAgendamento.obj?.id)
      this.agendamento$.atualizar(this.objAgendamento.obj?.id, this.objAgendamento).subscribe(
          () => {

            this.mensagensCadastro();

            this.carregando = false;

          },
          error => {

            this.carregando = false;

            this.alert.Danger('Erro', Uteis.ObterErroApi(error));

          }
        )
  }

  adicionar() {

    this.atualizarDadosObjeto();

    this.agendamento$.adicionar(this.objAgendamento).subscribe(
      retorno => {

        this.alert.Success('Sucesso','Registro cadastrado com sucesso');

        this.closeModalAgendamento();

        this.limparForm();

        this.carregando = false;
      },
      error => {

        this.carregando = false;

        this.alert.Danger('Erro', Uteis.ObterErroApi(error))

      }
    )
  }

  limparForm() {
    this.cadastroForm.get('agendamento')?.reset();
  }

  obterListaVendedor() {

    this.usuarioManager$.obterTodos().subscribe(

      retorno => this.listaVendedores = retorno.total > 0 ? retorno.result : [],

      err => this.alert.Danger('Erro', Uteis.ObterErroApi(err))

    );
  }

  clienteSelecionado(cliente: ClientePesquisaViewModel) {

    this.agendamento_cliente_id.setValue(cliente?.id || 0)

    this.agendamento_cliente_nome.setValue(cliente.id + ' - ' + cliente.nome)

    this.abrirFecharModalCliente = false;
  }

  atualizarDadosObjetoItem() {
      this.objAgendamentoItem.obj = Object.assign({}, this.objAgendamentoItem.obj, this.cadastroForm.get('agendamento_item')?.value)  
  }

  limparFormItem() { 
    this.cadastroForm.get('agendamento_item')?.reset(this.objAgendamentoItemReset);

    this.agendamento_item_agendamento_id.setValue(this.objAgendamento.obj?.id || 0);
  }
 
  adicionarItem() {

    this.cadastroForm.get('agendamento_item')?.markAllAsTouched();

    if (!this.cadastroForm.get('agendamento_item')?.valid) {

      if (typeof this.genericValidator !== 'undefined') {

        this.displayMessage = this.genericValidator.processarMensagens(this.cadastroForm.get('agendamento_item'));

      }

      let mensagem = Uteis.ConverterObjetoToArray(this.displayMessage)

      return this.mensagemCamposInvalidos(mensagem);
    }

    this.spinner.show();

    this.atualizarDadosObjetoItem();

    this.agendamentoItem$.adicionar(this.objAgendamentoItem).subscribe(
        retorno => {

          this.obterListaItem();

          this.spinner.hide();

          this.limparFormItem();

          this.alert.Success('Success', 'Item adicionado com sucesso!');

          (document.getElementById('produto_descricao') as HTMLElement).focus();

        },
        err => {

          this.spinner.hide();

          this.alert.Danger('Erro', Uteis.ObterErroApi(err));

        }
      );
  }

  removerItem(item: AgendamentoItemViewModel) {

    this.objAgendamentoItem.obj = Object.assign(item); 

    if(this.objAgendamentoItem.obj?.id && this.objAgendamentoItem.obj?.id > 0) {

      this.agendamentoItem$.remover(this.objAgendamentoItem.obj?.id, this.objAgendamentoItem).subscribe(
        retorno => {
  
          this.spinner.hide();

          this.alert.Success('Sucesso', 'Registro removido com sucesso!')
  
          this.obterListaItem();
        },
        error => {
          
          this.spinner.hide();
  
          this.alert.Danger('Erro', Uteis.ObterErroApi(error));
        }
      )
    }
  }

  obterListaItem() {

    let pesquisaAgendamentoItem: AgendamentoItemPesquisaViewModel = new AgendamentoItemPesquisaViewModel();

    pesquisaAgendamentoItem.agendamento_id = this.objAgendamento.obj.id;

    this.agendamentoItem$.obterTodos(pesquisaAgendamentoItem).subscribe(

      retorno => this.listaItem = retorno.total > 0 ? retorno.result : [],

      err => this.alert.Danger('Erro', Uteis.ObterErroApi(err))

    );
  }

  itemSelecionado(item) {

    this.agendamento_item_produto_id.setValue(item.id || 0)

    this.agendamento_item_produto_descricao.setValue(item.id + ' - ' + item.descricao); 

    this.abrirFecharPesquisaServicoModal = false;

    (document.getElementById('qtd') as HTMLElement).focus();

  }

  messagesValidations() {
    this.validationMessages = {
      dt_inicial: {
        required: MensagensValidacao.Obrigatorio('Data Inicial'),
        pattern: MensagensValidacao.FormatoInvalido('Data Inicial'),
      },
      dt_final: {
        pattern: MensagensValidacao.FormatoInvalido('Data Final'),
      },
      atendente_id: {
        required: MensagensValidacao.Obrigatorio('Tecnico'),
      },
      cliente_id: {
        required: MensagensValidacao.Obrigatorio('Cliente'),
      },
    }

    this.genericValidator = new GenericValidator(this.validationMessages);
  }

  closeModalAgendamento() {
    this.closeModal.emit(false);
  }

  private mensagensCadastro(): void {
    this.alert.Success('Sucesso', `Registro ${this.alterar ? 'atualizado' : 'cadastrado'} com Sucesso.`);
    if (this.alterar) return;
    this.alert.Info('Informativo', 'Formulário de cadastro foi limpo para realizar um novo cadastro.', { timeOut: 10000 });
  }

  mensagemCamposInvalidos(mensagens: any) {
    this.alert.Warning(AlertMensagemPadrao.formularioInvalido.titulo, AlertMensagemPadrao.formularioInvalido.mensagem + '<br><br>' + mensagens.toString().replace(/,/g, ''));
  }

  get agendamento_cliente_id() { return this.cadastroForm.get('agendamento')?.get('cliente_id') }
  get agendamento_cliente_nome() { return this.cadastroForm.get('agendamento')?.get('cliente_nome') }

  get agendamento_item_agendamento_id() { return this.cadastroForm.get('agendamento_item')?.get('agendamento_id') }
  get agendamento_item_produto_id() { return this.cadastroForm.get('agendamento_item')?.get('produto_id') }
  get agendamento_item_produto_descricao() { return this.cadastroForm.get('agendamento_item')?.get('produto_descricao') }



}
