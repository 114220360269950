export class AlertMensagemPadrao {

    constructor(titulo: string, mensagem: string) {
        this.titulo = titulo;
        this.mensagem = mensagem;
    }

    titulo: string;
    mensagem: string;

    static formularioInvalido = new AlertMensagemPadrao('Formulário inválido!', 'Por favor corrija as mensagens de erro informada nos campos para salvar o registro.');
    static formularioNaoAlterado = new AlertMensagemPadrao('Informativo', 'Nenhuma informação do formulário foi alterada.');
    static registroAtualizado = new AlertMensagemPadrao('Sucesso', 'Registro atualizado com sucesso.');

    static Cadastro(nomeCampo: string) {
        if(nomeCampo === null || nomeCampo.length == 0)
            throw 'Nome do Campo não informado';
                    
        return new AlertMensagemPadrao('Sucesso', `${nomeCampo} cadastrado com sucesso.`)
    }

    static Atualizar(nomeCampo: string) {
        if(nomeCampo === null || nomeCampo.length == 0)
            throw 'Nome do Campo não informado';

        return new AlertMensagemPadrao('Sucesso', `${nomeCampo} atualizado com sucesso.`)
    }

    


}