<div class="form-group" >
  <label for="empresa_id" class="col-form-label-sm">Empresas</label>
  <p-multiSelect name="empresa_id"
                inputId="empresa_id" 
                defaultLabel="-Selecione-" 
                optionLabel="emp_razao" 
                [showHeader]="true"
                [selectedItemsLabel]="'Empresas Selecionados {0}'"
                [options]="listaEmpresas"
                [(ngModel)]="empresas"
                (onPanelHide)="enviar()" 
                >
  </p-multiSelect>
</div>