import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanLoad, CanActivateChild, UrlTree } from '@angular/router';
import { PesquisaGeralViewModel } from '@modules/admin/view-models/pesquisa/PesquisaGeralViewModel';
import { UsuarioManagerPermissaoService } from '@modules/manager/cadastro/usuario-manager/usuario-manager-permissao/usuario-manager-permissao.service';
import { VerificarPermissoes } from '@modules/manager/cadastro/usuario-manager/usuario-manager-permissao/verificar-permissoes';
import { UsuarioManagerPermissaoPesquisaViewModel } from '@modules/manager/view-models/cadastro/usuario-manager/pesquisa/usuario-manager-permissao-pesquisa-view-model';
import { RetornoApiViewModel } from '@modules/manager/view-models/RetornoApiViewModel';
import { Observable } from 'rxjs';
import { toArray } from 'rxjs/operators';
import { AutenticacaoService } from '../autenticacao.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate, CanLoad, CanActivateChild  {
    
    constructor(private router: Router, private autenticacaoService: AutenticacaoService, private usuarioManagerPermissao$: UsuarioManagerPermissaoService){ 

    }
    
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean{
        
        const acessoUsuario = this.autenticacaoService.acessoUsuarioValue;

        if(acessoUsuario) return true;

        this.router.navigate(['/login'], { queryParams: { returnUrl:state.url }});

        return false;
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot) : Observable<boolean> {
        return this.checarRota(childRoute);
    }

    canLoad(): boolean{

        const acessoUsuario = this.autenticacaoService.acessoUsuarioValue;

        if(acessoUsuario) return true;

        this.router.navigate(['/login']);

        return false;
    }
    protected checarRota(activated: ActivatedRouteSnapshot) : Observable<boolean> {

        const acessoUsuario = this.autenticacaoService.acessoUsuarioValue;

        if(typeof activated.data['roles'] !== 'undefined' && activated.data['roles'].length){

            const rolesRota = Object.assign(activated.data['roles']);

            const roles = Object.assign(acessoUsuario?.arrayUsuarioPermissao || []);

            return new Observable<boolean>(subscriver => {

                if(acessoUsuario?.usuMenuCompleto || acessoUsuario?.usuAdm == 1) subscriver.next(true);

                if (!VerificarPermissoes.temPermissao(rolesRota, roles)) {

                    subscriver.next(false);

                    this.router.navigate['/login'];

                } else {

                    subscriver.next(true);
                }
            });

            
        }
       return new Observable<boolean>(subscriber => subscriber.next(true));
    }

}