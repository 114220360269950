<p-dialog [(visible)]="abrirFecharProdutoCadastroModal" [modal]="true" [closable]="true" [style]="{width: '100vw'}"
    [maximizable]="true" [baseZIndex]="10000" [draggable]="false" [resizable]="false"
    (onHide)="closeModalCadastroProduto()">
    <ngx-spinner name="modalAtualiza" bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-clip-rotate"
        [fullScreen]="false"></ngx-spinner>
    <p-header>Cadastrar Produto</p-header>


    <form  [formGroup]="cadastroForm">
        <div class="d-flex justify-content-end">
            <div class="btns-group">
                <!-- <button class="btn btn-sm btn-outline-secondary" id="processar" type="button"
                    (click)="openModalEmpresaFiltroRef(modalEmpresaFiltroRef)" *ngIf="alterar"><i
                        class="material-icons">toc</i>Empresas</button> -->
                <button class="btn btn-sm btn-outline-secondary" (click)="closeModalCadastroProduto()"><i
                        class="material-icons">close</i>Cancelar</button>
                <button class="btn btn-sm btn-outline-success" id="salvar" type="button" (click)="onSubmit()" [disabled]="carregando">
                    <div [ngSwitch]="carregando">
                        <div *ngSwitchCase="true">
                            <span class="spinner-border spinner-border-sm" role="status"
                                aria-hidden="true"></span>&nbsp;
                            <span>Carregando ...</span>
                        </div>
                        <div *ngSwitchDefault>
                            <i class="material-icons">done_all</i><span>Salvar</span>
                        </div>
                    </div>
                </button>
            </div>
        </div>
        <p-tabView>
            <p-tabPanel header="Dados do Produto" class="first">
                <div class="card rounded-0 border border-top-0">
                    <div class="card-body pt-1 pb-1">
                        <div class="row">
                            <div class="col-sm-4 col-md-4 col-lg-2 col-xl-1" formGroupName="produto">
                                <div class="form-group">
                                    <label for="id" class="col-form-label-sm">Código</label>
                                    <input type="number" id="id" name="id" class="form-control form-control-sm noscroll"
                                        placeholder="Código" formControlName="id">
                                </div>
                            </div>
                            <div class="col-sm-8 col-md-8 col-lg-6 col-xl-2" formGroupName="produto_empresa">
                                <div class="form-group">
                                    <label for="codigo" class="col-form-label-sm">Codigo Fabricante</label>
                                    <input type="string" id="codigo" name="codigo" class="form-control form-control-sm"
                                        placeholder="Codigo Fabricante" formControlName="codigo">
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-8 col-lg-8 col-xl-6" formGroupName="produto">
                                <div class="form-group">
                                    <label for="descricao" class="col-form-label-sm">Nome do Produto</label>
                                    <input type="text" id="descricao" name="descricao"
                                        class="form-control form-control-sm" placeholder="Nome do Produto"
                                        autocomplete="off" formControlName="descricao"
                                        [ngClass]="{'is-invalid': displayMessageProduto.descricao}" />
                                    <span class="text-danger" *ngIf="displayMessageProduto.descricao">
                                        <p [innerHTML]="displayMessageProduto.descricao"></p>
                                    </span>

                                </div>
                            </div>

                            <div class="col-sm-4 col-md-4 col-lg-4 col-xl-2" formGroupName="produto_empresa">
                                <div class="form-group">
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" id="ativo" name="ativo"
                                            class="custom-control-input form-control-sm" formControlName="ativo" />
                                        <label class="custom-control-label col-form-label-sm status-filtro"
                                            for="ativo">Produto
                                            Ativo</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h6 class="line"><span>Formação de Preço</span></h6>
                        <div class="row">
                            <div class="col-sm-6 col-md-4 col-lg-4 col-xl-2" formGroupName="produto_empresa">
                                <div class="form-group">
                                    <label class="col-form-label-sm" for="vlr_custo">Valor de Custo</label>
                                    <div class="input-group input-group-sm mb-2">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">R$</div>
                                        </div>
                                        <input type="number" id="vlr_custo" name="vlr_custo"
                                            class="form-control form-control-sm" placeholder="vlr_custo"
                                            formControlName="vlr_custo" (blur)="formacaoPreco()">
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-4 col-xl-2" formGroupName="produto_empresa">
                                <div class="form-group">
                                    <label class="col-form-label-sm" for="vlr_venda">Valor de Venda</label>
                                    <div class="input-group input-group-sm mb-2">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">R$</div>
                                        </div>
                                        <input type="number" id="vlr_venda" name="vlr_venda"
                                            class="form-control form-control-sm" placeholder="vlr_venda"
                                            formControlName="vlr_venda"
                                            [ngClass]="{'is-invalid': displayMessageProdEmpresa.vlr_venda}">
                                        <span class="text-danger" *ngIf="displayMessageProdEmpresa.vlr_venda">
                                            <p [innerHTML]="displayMessageProdEmpresa.vlr_venda"></p>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3" formGroupName="produto_empresa">
                                <div class="form-group">
                                    <label class="col-form-label-sm" for="vlr_preco_sugerido">Valor Sugerido de
                                        Venda</label>
                                    <div class="input-group input-group-sm mb-2">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">R$</div>
                                        </div>
                                        <input type="text" id="vlr_preco_sugerido" name="vlr_preco_sugerido"
                                            class="form-control form-control-sm" placeholder="vlr_preco_sugerido"
                                            formControlName="vlr_preco_sugerido">
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-4 col-xl-2" formGroupName="produto_empresa">
                                <div class="form-group">
                                    <label class="col-form-label-sm" for="vlr_atacado">Valor de Atacado</label>
                                    <div class="input-group input-group-sm mb-2">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">R$</div>
                                        </div>
                                        <input type="number" id="vlr_atacado" name="vlr_atacado"
                                            class="form-control form-control-sm" placeholder="vlr_atacado"
                                            formControlName="vlr_atacado">
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-4 col-xl-2">
                                <div class="form-group">
                                    <label class="col-form-label-sm"><br /></label>
                                    <div class="input-group input-group-sm mb-2">
                                        <button type="button" class="btn btn-sm card-header" style="border-radius: 3px;"
                                            (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed"
                                            aria-controls="collapseFormacaoDePreco">Formação
                                            de Preço <i class="material-icons">arrow_drop_down</i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card border-0 mb-4">
                            <div id="collapseFormacaoDePreco" [collapse]="isCollapsed" [isAnimated]="true">
                                <div class="card-body pt-1 pb-1">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-6">
                                            <h6 class="line"><span>Valor de Custo</span></h6>
                                            <div class="row">
                                                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                                    <div class="form-group">
                                                        <label class="col-form-label-sm" for="aliq_fp_cred_icms">Créd.
                                                            ICMS
                                                            [%]</label>
                                                        <div class="row">
                                                            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-12"
                                                                formGroupName="produto_empresa">
                                                                <div class="input-group input-group-sm mb-2">
                                                                    <div class="input-group-prepend">
                                                                        <div class="input-group-text">%</div>
                                                                    </div>
                                                                    <input type="number" id="aliq_fp_cred_icms"
                                                                        name="aliq_fp_cred_icms"
                                                                        class="form-control form-control-sm"
                                                                        placeholder="aliq_fp_cred_icms"
                                                                        formControlName="aliq_fp_cred_icms"
                                                                        (blur)="formacaoPreco()">
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-12"
                                                                formGroupName="formacaoPreco">
                                                                <div class="input-group input-group-sm mb-2">
                                                                    <div class="input-group-prepend">
                                                                        <div class="input-group-text">R$</div>
                                                                    </div>
                                                                    <input type="text" id="aliqFpCredIcmsExibir"
                                                                        name="aliqFpCredIcmsExibir"
                                                                        class="form-control form-control-sm"
                                                                        formControlName="aliq_fp_cred_icms">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                                    <div class="form-group">
                                                        <label class="col-form-label-sm" for="aliq_fp_cred_pis">Créd.
                                                            PIS
                                                            [%]</label>
                                                        <div class="row">
                                                            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-12"
                                                                formGroupName="produto_empresa">
                                                                <div class="input-group input-group-sm mb-2">
                                                                    <div class="input-group-prepend">
                                                                        <div class="input-group-text">%</div>
                                                                    </div>
                                                                    <input type="number" id="aliq_fp_cred_pis"
                                                                        name="aliq_fp_cred_pis"
                                                                        class="form-control form-control-sm"
                                                                        placeholder="aliq_fp_cred_pis"
                                                                        formControlName="aliq_fp_cred_pis"
                                                                        (blur)="formacaoPreco()">


                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-12"
                                                                formGroupName="formacaoPreco">
                                                                <div class="input-group input-group-sm mb-2">
                                                                    <div class="input-group-prepend">
                                                                        <div class="input-group-text">R$</div>
                                                                    </div>
                                                                    <input type="text" id="aliqFpCredPisExibir"
                                                                        name="aliqFpCredPisExibir"
                                                                        class="form-control form-control-sm"
                                                                        formControlName="aliq_fp_cred_pis">
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                                    <div class="form-group">
                                                        <label class="col-form-label-sm" for="aliq_fp_cred_cofins">Créd.
                                                            COFINS
                                                            [%]</label>
                                                        <div class="row">
                                                            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-12"
                                                                formGroupName="produto_empresa">
                                                                <div class="input-group input-group-sm mb-2">
                                                                    <div class="input-group-prepend">
                                                                        <div class="input-group-text">%</div>
                                                                    </div>
                                                                    <input type="number" id="aliq_fp_cred_cofins"
                                                                        name="aliq_fp_cred_cofins"
                                                                        class="form-control form-control-sm"
                                                                        placeholder="aliq_fp_cred_cofins"
                                                                        formControlName="aliq_fp_cred_cofins"
                                                                        (blur)="formacaoPreco()">

                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-12"
                                                                formGroupName="formacaoPreco">
                                                                <div class="input-group input-group-sm mb-2">
                                                                    <div class="input-group-prepend">
                                                                        <div class="input-group-text">R$</div>
                                                                    </div>
                                                                    <input type="text" id="aliqFpCredCofinsExibir"
                                                                        name="aliqFpCredCofinsExibir"
                                                                        class="form-control form-control-sm"
                                                                        formControlName="aliq_fp_cred_cofins">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                                    <div class="form-group">
                                                        <label class="col-form-label-sm" for="aliq_fp_cred_ipi">Créd.
                                                            IPI
                                                            [%]</label>
                                                        <div class="row">
                                                            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-12"
                                                                formGroupName="produto_empresa">
                                                                <div class="input-group input-group-sm mb-2">
                                                                    <div class="input-group-prepend">
                                                                        <div class="input-group-text">%</div>
                                                                    </div>
                                                                    <input type="number" id="aliq_fp_cred_ipi"
                                                                        name="aliq_fp_cred_ipi"
                                                                        class="form-control form-control-sm"
                                                                        placeholder="aliq_fp_cred_ipi"
                                                                        formControlName="aliq_fp_cred_ipi"
                                                                        (blur)="formacaoPreco()">

                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-12"
                                                                formGroupName="formacaoPreco">
                                                                <div class="input-group input-group-sm mb-2">
                                                                    <div class="input-group-prepend">
                                                                        <div class="input-group-text">R$</div>
                                                                    </div>
                                                                    <input type="text" id="aliqFpCredIpiExibir"
                                                                        name="aliqFpCredIpiExibir"
                                                                        class="form-control form-control-sm"
                                                                        formControlName="aliq_fp_cred_ipi">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                                    <div class="form-group">
                                                        <label class="col-form-label-sm" for="aliq_fp_icms_deson">ICMS
                                                            Deson.
                                                            [%]</label>
                                                        <div class="row">
                                                            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-12"
                                                                formGroupName="produto_empresa">
                                                                <div class="input-group input-group-sm mb-2">
                                                                    <div class="input-group-prepend">
                                                                        <div class="input-group-text">%</div>
                                                                    </div>
                                                                    <input type="number" id="aliq_fp_icms_deson"
                                                                        name="aliq_fp_icms_deson"
                                                                        class="form-control form-control-sm"
                                                                        placeholder="aliq_fp_icms_deson"
                                                                        formControlName="aliq_fp_icms_deson"
                                                                        (blur)="formacaoPreco()">

                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-12"
                                                                formGroupName="formacaoPreco">
                                                                <div class="input-group input-group-sm mb-2">
                                                                    <div class="input-group-prepend">
                                                                        <div class="input-group-text">R$</div>
                                                                    </div>
                                                                    <input type="text" id="aliqFpIcmsDesonExibir"
                                                                        name="aliqFpIcmsDesonExibir"
                                                                        class="form-control form-control-sm"
                                                                        formControlName="aliq_fp_icms_deson">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                                    <div class="form-group">
                                                        <label class="col-form-label-sm" for="aliq_fp_ipi">IPI.
                                                            [%]</label>
                                                        <div class="row">
                                                            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-12"
                                                                formGroupName="produto_empresa">
                                                                <div class="input-group input-group-sm mb-2">
                                                                    <div class="input-group-prepend">
                                                                        <div class="input-group-text">%</div>
                                                                    </div>
                                                                    <input type="number" id="aliq_fp_ipi"
                                                                        name="aliq_fp_ipi"
                                                                        class="form-control form-control-sm"
                                                                        placeholder="aliq_fp_ipi"
                                                                        formControlName="aliq_fp_ipi"
                                                                        (blur)="formacaoPreco()">

                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-12"
                                                                formGroupName="formacaoPreco">
                                                                <div class="input-group input-group-sm mb-2">
                                                                    <div class="input-group-prepend">
                                                                        <div class="input-group-text">R$</div>
                                                                    </div>
                                                                    <input type="text" id="aliqFpIpiExibir"
                                                                        name="aliqFpIpiExibir"
                                                                        class="form-control form-control-sm"
                                                                        formControlName="aliq_fp_ipi">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                                    <div class="form-group">
                                                        <label class="col-form-label-sm" for="aliq_fp_icms_sub">Sub.
                                                            Trib.
                                                            [%]</label>
                                                        <div class="row">
                                                            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-12"
                                                                formGroupName="produto_empresa">
                                                                <div class="input-group input-group-sm mb-2">
                                                                    <div class="input-group-prepend">
                                                                        <div class="input-group-text">%</div>
                                                                    </div>
                                                                    <input type="number" id="aliq_fp_icms_sub"
                                                                        name="aliq_fp_icms_sub"
                                                                        class="form-control form-control-sm"
                                                                        placeholder="aliq_fp_icms_sub"
                                                                        formControlName="aliq_fp_icms_sub"
                                                                        (blur)="formacaoPreco()">

                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-12"
                                                                formGroupName="formacaoPreco">
                                                                <div class="input-group input-group-sm mb-2">
                                                                    <div class="input-group-prepend">
                                                                        <div class="input-group-text">R$</div>
                                                                    </div>
                                                                    <input type="text" id="aliqFpIcmsSubExibir"
                                                                        name="aliqFpIcmsSubExibir"
                                                                        class="form-control form-control-sm"
                                                                        formControlName="aliq_fp_icms_sub">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                                    <div class="form-group">
                                                        <label class="col-form-label-sm" for="aliq_fp_frete">Frete
                                                            [%]</label>
                                                        <div class="row">
                                                            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-12"
                                                                formGroupName="produto_empresa">
                                                                <div class="input-group input-group-sm mb-2">
                                                                    <div class="input-group-prepend">
                                                                        <div class="input-group-text">%</div>
                                                                    </div>
                                                                    <input type="number" id="aliq_fp_frete"
                                                                        name="aliq_fp_frete"
                                                                        class="form-control form-control-sm"
                                                                        placeholder="aliq_fp_frete"
                                                                        formControlName="aliq_fp_frete"
                                                                        (blur)="formacaoPreco()">

                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-12"
                                                                formGroupName="formacaoPreco">
                                                                <div class="input-group input-group-sm mb-2">
                                                                    <div class="input-group-prepend">
                                                                        <div class="input-group-text">R$</div>
                                                                    </div>
                                                                    <input type="text" id="aliqFpFreteExibir"
                                                                        name="aliqFpFreteExibir"
                                                                        class="form-control form-control-sm "
                                                                        formControlName="aliq_fp_frete">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3">
                                                    <div class="form-group">
                                                        <label class="col-form-label-sm"
                                                            for="aliq_fp_outros_custo">Outros
                                                            Custos
                                                            [%]</label>
                                                        <div class="row">
                                                            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-12"
                                                                formGroupName="produto_empresa">
                                                                <div class="input-group input-group-sm mb-2">
                                                                    <div class="input-group-prepend">
                                                                        <div class="input-group-text">%</div>
                                                                    </div>
                                                                    <input type="number" id="aliq_fp_outros_custo"
                                                                        name="aliq_fp_outros_custo"
                                                                        class="form-control form-control-sm"
                                                                        placeholder="aliq_fp_outros_custo"
                                                                        formControlName="aliq_fp_outros_custo"
                                                                        (blur)="formacaoPreco()">

                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-12"
                                                                formGroupName="formacaoPreco">
                                                                <div class="input-group input-group-sm mb-2">
                                                                    <div class="input-group-prepend">
                                                                        <div class="input-group-text">R$</div>
                                                                    </div>
                                                                    <input type="text" id="aliqFpOutrosCustoExibir"
                                                                        name="aliqFpOutrosCustoExibir"
                                                                        class="form-control form-control-sm"
                                                                        (blur)="formacaoPreco()"
                                                                        formControlName="aliq_fp_outros_custo">
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3"
                                                    formGroupName="formacaoPreco">
                                                    <div class="form-group">
                                                        <label class="col-form-label-sm" for="custoFinal">Custo
                                                            Final</label>
                                                        <div class="input-group input-group-sm mb-2">
                                                            <div class="input-group-prepend">
                                                                <div class="input-group-text">R$</div>
                                                            </div>
                                                            <input type="text" id="custoFinal" name="custoFinal"
                                                                class="form-control form-control-sm"
                                                                placeholder="Custo Final " formControlName="custoFinal">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-6">
                                            <h6 class="line"><span>Preço de Venda</span></h6>
                                            <div class="row">
                                                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                                                    <div class="form-group">
                                                        <label class="col-form-label-sm" for="aliq_fp_custo_op">Custo
                                                            Oper.
                                                            [%]</label>
                                                        <div class="row">
                                                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-12"
                                                                formGroupName="produto_empresa">
                                                                <div class="input-group input-group-sm mb-2">
                                                                    <div class="input-group-prepend">
                                                                        <div class="input-group-text">%</div>
                                                                    </div>
                                                                    <input type="number" id="aliq_fp_custo_op"
                                                                        name="aliq_fp_custo_op"
                                                                        class="form-control form-control-sm"
                                                                        placeholder="aliq_fp_custo_op"
                                                                        formControlName="aliq_fp_custo_op"
                                                                        (blur)="formacaoPreco()">

                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-12"
                                                                formGroupName="formacaoPreco">
                                                                <div class="input-group input-group-sm mb-2">
                                                                    <div class="input-group-prepend">
                                                                        <div class="input-group-text">R$</div>
                                                                    </div>
                                                                    <input type="text" id="aliqFpCustoOpExibir"
                                                                        name="aliqFpCustoOpExibir"
                                                                        class="form-control form-control-sm"
                                                                        formControlName="aliq_fp_custo_op">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                                                    <div class="form-group">
                                                        <label class="col-form-label-sm" for="aliq_fp_trib_fed">Trib.
                                                            Fed.
                                                            [%]</label>
                                                        <div class="row">
                                                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-12"
                                                                formGroupName="produto_empresa">
                                                                <div class="input-group input-group-sm mb-2">
                                                                    <div class="input-group-prepend">
                                                                        <div class="input-group-text">%</div>
                                                                    </div>
                                                                    <input type="number" id="aliq_fp_trib_fed"
                                                                        name="aliq_fp_trib_fed"
                                                                        class="form-control form-control-sm"
                                                                        placeholder="aliq_fp_trib_fed"
                                                                        formControlName="aliq_fp_trib_fed"
                                                                        (blur)="formacaoPreco()">

                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-12"
                                                                formGroupName="formacaoPreco">
                                                                <div class="input-group input-group-sm mb-2">
                                                                    <div class="input-group-prepend">
                                                                        <div class="input-group-text">R$</div>
                                                                    </div>
                                                                    <input type="text" id="aliqFpTribFedExibir"
                                                                        name="aliqFpTribFedExibir"
                                                                        class="form-control form-control-sm "
                                                                        formControlName="aliq_fp_trib_fed">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                                                    <div class="form-group">
                                                        <label class="col-form-label-sm" for="aliq_pis">PIS [%]</label>
                                                        <div class="row">
                                                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-12"
                                                                formGroupName="produto_empresa">
                                                                <div class="input-group input-group-sm mb-2">
                                                                    <div class="input-group-prepend">
                                                                        <div class="input-group-text">%</div>
                                                                    </div>
                                                                    <input type="number" id="aliq_pis" name="aliq_pis"
                                                                        class="form-control form-control-sm"
                                                                        placeholder="aliq_pis"
                                                                        formControlName="aliq_pis"
                                                                        (blur)="formacaoPreco()">

                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-12"
                                                                formGroupName="formacaoPreco">
                                                                <div class="input-group input-group-sm mb-2">
                                                                    <div class="input-group-prepend">
                                                                        <div class="input-group-text">R$</div>
                                                                    </div>
                                                                    <input type="text" id="aliqPisExibir"
                                                                        name="aliqPisExibir"
                                                                        class="form-control form-control-sm"
                                                                        formControlName="aliq_pis">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                                                    <div class="form-group">
                                                        <label class="col-form-label-sm" for="aliq_cofins">COFINS
                                                            [%]</label>
                                                        <div class="row">
                                                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-12"
                                                                formGroupName="produto_empresa">
                                                                <div class="input-group input-group-sm mb-2">
                                                                    <div class="input-group-prepend">
                                                                        <div class="input-group-text">%</div>
                                                                    </div>
                                                                    <input type="number" id="aliq_cofins"
                                                                        name="aliq_cofins"
                                                                        class="form-control form-control-sm"
                                                                        placeholder="aliq_cofins"
                                                                        formControlName="aliq_cofins"
                                                                        (blur)="formacaoPreco()">

                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-12"
                                                                formGroupName="formacaoPreco">
                                                                <div class="input-group input-group-sm mb-2">
                                                                    <div class="input-group-prepend">
                                                                        <div class="input-group-text">R$</div>
                                                                    </div>
                                                                    <input type="text" id="aliqCofinsExibir"
                                                                        name="aliqCofinsExibir"
                                                                        class="form-control form-control-sm"
                                                                        formControlName="aliq_cofins">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                                                    <div class="form-group">
                                                        <label class="col-form-label-sm" for="aliq_imposto_renda">I.R.
                                                            [%]</label>
                                                        <div class="row">
                                                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-12"
                                                                formGroupName="produto_empresa">
                                                                <div class="input-group input-group-sm mb-2">
                                                                    <div class="input-group-prepend">
                                                                        <div class="input-group-text">%</div>
                                                                    </div>
                                                                    <input type="number" id="aliq_imposto_renda"
                                                                        name="aliq_imposto_renda"
                                                                        class="form-control form-control-sm"
                                                                        placeholder="aliq_imposto_renda"
                                                                        formControlName="aliq_imposto_renda"
                                                                        (blur)="formacaoPreco()">

                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-12"
                                                                formGroupName="formacaoPreco">
                                                                <div class="input-group input-group-sm mb-2">
                                                                    <div class="input-group-prepend">
                                                                        <div class="input-group-text">R$</div>
                                                                    </div>
                                                                    <input type="text" id="aliqImpostoRendaExibir"
                                                                        name="aliqImpostoRendaExibir"
                                                                        class="form-control form-control-sm "
                                                                        formControlName="aliq_imposto_renda">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                                                    <div class="form-group">
                                                        <label class="col-form-label-sm" for="aliq_fp_outros">Outros
                                                            Vlr.
                                                            [%]</label>
                                                        <div class="row">
                                                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-12"
                                                                formGroupName="produto_empresa">
                                                                <div class="input-group input-group-sm mb-2">
                                                                    <div class="input-group-prepend">
                                                                        <div class="input-group-text">%</div>
                                                                    </div>
                                                                    <input type="number" id="aliq_fp_outros"
                                                                        name="aliq_fp_outros"
                                                                        class="form-control form-control-sm"
                                                                        placeholder="aliq_fp_outros"
                                                                        formControlName="aliq_fp_outros"
                                                                        (blur)="formacaoPreco()">

                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-12"
                                                                formGroupName="formacaoPreco">
                                                                <div class="input-group input-group-sm mb-2">
                                                                    <div class="input-group-prepend">
                                                                        <div class="input-group-text">R$</div>
                                                                    </div>
                                                                    <input type="text" id="aliqFpOutrosExibir"
                                                                        name="aliqFpOutrosExibir"
                                                                        class="form-control form-control-sm"
                                                                        formControlName="aliq_fp_outros">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                                                    <div class="form-group">
                                                        <label class="col-form-label-sm" for="aliq_fp_encarg_finan">Enc.
                                                            Finan.
                                                            [%]</label>
                                                        <div class="row">
                                                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-12"
                                                                formGroupName="produto_empresa">
                                                                <div class="input-group input-group-sm mb-2">
                                                                    <div class="input-group-prepend">
                                                                        <div class="input-group-text">%</div>
                                                                    </div>
                                                                    <input type="number" id="aliq_fp_encarg_finan"
                                                                        name="aliq_fp_encarg_finan"
                                                                        class="form-control form-control-sm"
                                                                        placeholder="aliq_fp_encarg_finan"
                                                                        formControlName="aliq_fp_encarg_finan"
                                                                        (blur)="formacaoPreco()">

                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-12"
                                                                formGroupName="formacaoPreco">
                                                                <div class="input-group input-group-sm mb-2">
                                                                    <div class="input-group-prepend">
                                                                        <div class="input-group-text">R$</div>
                                                                    </div>
                                                                    <input type="text" id="aliqFpEncargFinanExibir"
                                                                        name="aliqFpEncargFinanExibir"
                                                                        class="form-control form-control-sm"
                                                                        formControlName="aliq_fp_encarg_finan">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                                                    <div class="form-group">
                                                        <label class="col-form-label-sm" for="aliq_fp_icms">ICMS
                                                            [%]</label>
                                                        <div class="row">
                                                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-12"
                                                                formGroupName="produto_empresa">
                                                                <div class="input-group input-group-sm mb-2">
                                                                    <div class="input-group-prepend">
                                                                        <div class="input-group-text">%</div>
                                                                    </div>
                                                                    <input type="number" id="aliq_fp_icms"
                                                                        name="aliq_fp_icms"
                                                                        class="form-control form-control-sm"
                                                                        placeholder="aliq_fp_icms"
                                                                        formControlName="aliq_fp_icms"
                                                                        (blur)="formacaoPreco()">

                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-12"
                                                                formGroupName="formacaoPreco">
                                                                <div class="input-group input-group-sm mb-2">
                                                                    <div class="input-group-prepend">
                                                                        <div class="input-group-text">R$</div>
                                                                    </div>
                                                                    <input type="text" id="aliqFpIcmsExibir"
                                                                        name="aliqFpIcmsExibir"
                                                                        class="form-control form-control-sm"
                                                                        formControlName="aliq_fp_icms">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                                                    <div class="form-group">
                                                        <label class="col-form-label-sm"
                                                            for="aliq_fp_contrib_social">CSLL
                                                            [%]</label>
                                                        <div class="row">
                                                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-12"
                                                                formGroupName="produto_empresa">
                                                                <div class="input-group input-group-sm mb-2">
                                                                    <div class="input-group-prepend">
                                                                        <div class="input-group-text">%</div>
                                                                    </div>
                                                                    <input type="number" id="aliq_fp_contrib_social"
                                                                        name="aliq_fp_contrib_social"
                                                                        class="form-control form-control-sm"
                                                                        placeholder="aliq_fp_contrib_social"
                                                                        formControlName="aliq_fp_contrib_social"
                                                                        (blur)="formacaoPreco()">

                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-12"
                                                                formGroupName="formacaoPreco">
                                                                <div class="input-group input-group-sm mb-2">
                                                                    <div class="input-group-prepend">
                                                                        <div class="input-group-text">R$</div>
                                                                    </div>
                                                                    <input type="text" id="aliqFpContribSocialExibir"
                                                                        name="aliqFpContribSocialExibir"
                                                                        class="form-control form-control-sm"
                                                                        formControlName="aliq_fp_contrib_social">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                                                    <div class="form-group">
                                                        <label class="col-form-label-sm" for="aliq_fp_comissao">Comissão
                                                            [%]</label>
                                                        <div class="row">
                                                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-12"
                                                                formGroupName="produto_empresa">
                                                                <div class="input-group input-group-sm mb-2">
                                                                    <div class="input-group-prepend">
                                                                        <div class="input-group-text">%</div>
                                                                    </div>
                                                                    <input type="number" id="aliq_fp_comissao"
                                                                        name="aliq_fp_comissao"
                                                                        class="form-control form-control-sm"
                                                                        placeholder="aliq_fp_comissao"
                                                                        formControlName="aliq_fp_comissao"
                                                                        (blur)="formacaoPreco()">

                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-12"
                                                                formGroupName="formacaoPreco">
                                                                <div class="input-group input-group-sm mb-2">
                                                                    <div class="input-group-prepend">
                                                                        <div class="input-group-text">R$</div>
                                                                    </div>
                                                                    <input type="text" id="aliqFpComissaoExibir"
                                                                        name="aliqFpComissaoExibir"
                                                                        class="form-control form-control-sm"
                                                                        formControlName="aliq_fp_comissao">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                                                    <div class="form-group">
                                                        <label class="col-form-label-sm" for="aliq_fp_venda_m_lucro">M.
                                                            Lucro
                                                            [%]</label>
                                                        <div class="row">
                                                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-12"
                                                                formGroupName="produto_empresa">
                                                                <div class="input-group input-group-sm mb-2">
                                                                    <div class="input-group-prepend">
                                                                        <div class="input-group-text">%</div>
                                                                    </div>
                                                                    <input type="number" id="aliq_fp_venda_m_lucro"
                                                                        name="aliq_fp_venda_m_lucro"
                                                                        class="form-control form-control-sm"
                                                                        placeholder="aliq_fp_venda_m_lucro"
                                                                        formControlName="aliq_fp_venda_m_lucro"
                                                                        (blur)="formacaoPreco()">

                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-12"
                                                                formGroupName="formacaoPreco">
                                                                <div class="input-group input-group-sm mb-2">
                                                                    <div class="input-group-prepend">
                                                                        <div class="input-group-text">R$</div>
                                                                    </div>
                                                                    <input type="text" id="aliqFpVendaMlucroExibir"
                                                                        name="aliqFpVendaMlucroExibir"
                                                                        class="form-control form-control-sm"
                                                                        formControlName="aliq_fp_venda_m_lucro">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                                                    <div class="form-group">
                                                        <label class="col-form-label-sm"
                                                            for="aliq_fp_atacado_m_lucro">M.
                                                            Lucro
                                                            Atacado [%]</label>
                                                        <div class="row">
                                                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-12"
                                                                formGroupName="produto_empresa">
                                                                <div class="input-group input-group-sm mb-2">
                                                                    <div class="input-group-prepend">
                                                                        <div class="input-group-text">%</div>
                                                                    </div>
                                                                    <input type="number" id="aliq_fp_atacado_m_lucro"
                                                                        name="aliq_fp_atacado_m_lucro"
                                                                        class="form-control form-control-sm"
                                                                        placeholder="aliq_fp_atacado_m_lucro"
                                                                        formControlName="aliq_fp_atacado_m_lucro"
                                                                        (blur)="formacaoPreco()">

                                                                </div>
                                                            </div>
                                                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-12"
                                                                formGroupName="formacaoPreco">
                                                                <div class="input-group input-group-sm mb-2">
                                                                    <div class="input-group-prepend">
                                                                        <div class="input-group-text">R$</div>
                                                                    </div>
                                                                    <input type="text" id="aliqFpAtacadoMlucroExibir"
                                                                        name="aliqFpAtacadoMlucroExibir"
                                                                        class="form-control form-control-sm"
                                                                        formControlName="aliq_fp_atacado_m_lucro">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <h6 class="line"><span>Impostos</span></h6>
                        <div class="row">
                            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4" formGroupName="produto_empresa">
                                <div class="form-group">
                                    <label for="tributacao_id" class="col-form-label-sm">Tributação</label>
                                    <select id="tributacao_id" class="form-control form-control-sm"
                                        placeholder="Tributação " autocomplete="off" formControlName="tributacao_id"
                                        [ngClass]="{'is-invalid': displayMessageProdEmpresa.tributacao_id}">
                                        <option *ngFor="let item of listaTributacao.itens" [ngValue]="item.id">
                                            {{ item.descricao }}</option>
                                    </select>
                                    <span class="invalid-feedback" *ngIf="displayMessageProdEmpresa.tributacao_id">
                                        <p [innerHTML]="displayMessageProdEmpresa.tributacao_id"></p>
                                    </span>

                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-6 col-xl-4" formGroupName="produto_empresa">
                                <div class="form-group">
                                    <label for="cod_cst1" class="col-form-label-sm">CST 1</label>
                                    <select id="cod_cst1" class="form-control form-control-sm" placeholder="cod_cst1"
                                        autocomplete="off" formControlName="cod_cst1">
                                        <option *ngFor="let item of produtoCst1" value="{{ item.codigo }}">
                                            {{ item.descricao }}
                                        </option>
                                    </select>
                                    <span class="text-danger" *ngIf="displayMessageProdEmpresa.cod_cst1">
                                        <p [innerHTML]="displayMessageProdEmpresa.cod_cst1"></p>
                                    </span>

                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-2" formGroupName="produto_empresa">
                                <div class="form-group">
                                    <label class="col-form-label-sm " for="aliq_ii">Imp. de Importação [%]</label>
                                    <div class="input-group input-group-sm mb-2">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">%</div>
                                        </div>
                                        <input type="number" id="aliq_ii" name="aliq_ii"
                                            class="form-control form-control-sm" placeholder="aliq_ii"
                                            autocomplete="off" formControlName="aliq_ii" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-2" formGroupName="produto_empresa">
                                <div class="form-group">
                                    <label class="col-form-label-sm " for="aliq_fcp">FCP [%] </label>
                                    <div class="input-group input-group-sm">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">%</div>
                                        </div>
                                        <input type="number" id="aliq_fcp" name="aliq_fcp"
                                            class="form-control form-control-sm" placeholder="aliq_fcp"
                                            autocomplete="off" formControlName="aliq_fcp" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                <h6 class="line"><span>Unidade</span></h6>
                                <div class="row">
                                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-4" formGroupName="produto">
                                        <div class="form-group">
                                            <label for="produto_un_comercial_id" class="col-form-label-sm">UN
                                                Comercial</label>
                                            <select id="produto_un_comercial_id" name="produto_un_comercial_id"
                                                class="form-control form-control-sm"
                                                placeholder="produto_un_comercial_id" autocomplete="off"
                                                formControlName="produto_un_comercial_id"
                                                [ngClass]="{'is-invalid': displayMessageProduto.produto_un_comercial_id}">
                                                <option *ngFor="let item of listaProdutoUn" [ngValue]="item.id">
                                                    {{item.un}} - {{ item.descricao }}</option>
                                            </select>
                                            <span class="text-danger"
                                                *ngIf="displayMessageProduto.produto_un_comercial_id">
                                                <p [innerHTML]="displayMessageProduto.produto_un_comercial_id"></p>
                                            </span>

                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-4" formGroupName="produto">
                                        <div class="form-group">
                                            <label for="produto_un_trib_id" class="col-form-label-sm">UN
                                                Tributária</label>
                                            <select id="produto_un_trib_id" name="produto_un_trib_id"
                                                class="form-control form-control-sm" placeholder="produto_un_trib_id"
                                                autocomplete="off" formControlName="produto_un_trib_id"
                                                [ngClass]="{'is-invalid': displayMessageProduto.produto_un_trib_id}">
                                                <option *ngFor="let item of listaProdutoUn" [ngValue]="item.id">
                                                    {{item.un}} - {{ item.descricao }}</option>
                                            </select>
                                            <span class="text-danger" *ngIf="displayMessageProduto.produto_un_trib_id">
                                                <p [innerHTML]="displayMessageProduto.produto_un_trib_id"></p>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-4" formGroupName="produto">
                                        <div class="form-group">
                                            <label for="produto_un_multiplo" class="col-form-label-sm">UN
                                                Múltiplo</label>
                                            <select id="produto_un_multiplo" class="form-control form-control-sm"
                                                placeholder="Produto Un Multiplo" autocomplete="off"
                                                formControlName="produto_un_multiplo_id">
                                                <option [ngValue]="0"></option>
                                                <option *ngFor="let item of listaProdutoUn" [ngValue]="item.id">
                                                    {{item.un}} - {{ item.descricao }}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                                <h6 class="line"><span>NCM / CEST</span></h6>
                                <div class="row">
                                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-6" formGroupName="produto_ncm">
                                        <div class="form-group">
                                            <label for="produtoNcmCodigo" class="col-form-label-sm">NCM &nbsp;
                                                <span *ngIf="carregandoAutocomplete"
                                                    class="spinner-border spinner-border-sm text-primary spinner-autocomplete"
                                                    role="status" aria-hidden="true"></span>
                                            </label>
                                            <div class="input-group">
                                                <input id="produtoNcmCodigo" name="produtoNcmCodigo"
                                                    class="form-control form-control-sm"
                                                    placeholder="Pesquise um NCM pelo codigo" autocomplete="off"
                                                    formControlName="codigo" [typeahead]="produtoNcmAutoComplete"
                                                    typeaheadOptionField="codigo"
                                                    (typeaheadOnSelect)="onSelect($event,'produto_ncm')"
                                                    [typeaheadAsync]="true" [isAnimated]="false"
                                                    [typeaheadMinLength]="1" [typeaheadScrollable]="true"
                                                    [typeaheadOptionsInScrollableView]="5" [adaptivePosition]="true"
                                                    (typeaheadNoResults)="noResultProdutoNcm = $event"
                                                    [ngClass]="{'is-invalid': noResultProdutoNcm || displayMessageProduto.produto_ncm_id}">
                                                <div class="input-group-append">
                                                    <button class="btn btn-sm btn-maxdata" type="button"
                                                        id="remover_produto_ncm_id"
                                                        *ngIf="cadastroForm.controls.produto.get('produto_ncm_id')?.value"
                                                        (click)="removerItemSelecioando('produto_ncm_id')"><i
                                                            class="material-icons mr-0">clear</i></button>
                                                </div>
                                            </div>
                                            <span class="text-danger" *ngIf="displayMessageProduto.produto_ncm_id">
                                                <p [innerHTML]="displayMessageProduto.produto_ncm_id"></p>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-6" formGroupName="produto">
                                        <div class="form-group">
                                            <label for="produto_cest_id" class="col-form-label-sm">CEST</label>
                                            <div class="input-group-append">
                                                <select id="produto_cest_id" name="produto_cest_id"
                                                    class="form-control form-control-sm" placeholder="CEST"
                                                    autocomplete="off" formControlName="produto_cest_id"
                                                    [ngClass]="{'is-invalid': displayMessageProduto.produto_cest_id}">
                                                    <option></option>
                                                    <option *ngFor="let item of listaProdutoCest" [ngValue]="item.id">
                                                        {{item.produto_cest_codigo}}</option>
                                                </select>
                                                <button class="btn btn-sm btn-success" type="button"
                                                    title="Cadastrar novo tamanho" id="adicionar_produto_cest_id"
                                                    [disabled]="!cadastroForm.controls.produto.get('produto_ncm_id')?.value"
                                                    (click)="abrirFecharProdutoNcmCestCadastroModal = true"
                                                    *ngIf="cadastroForm.controls.produto.get('produto_ncm_id')?.value"><i
                                                        class="material-icons mr-0">add</i></button>
                                            </div>
                                            <span class="text-danger" *ngIf="displayMessageProduto.produto_cest_id">
                                                <p [innerHTML]="displayMessageProduto.produto_cest_id"></p>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-4 col-xl-3" formGroupName="produto">
                                <div class="form-group">
                                    <label for="produto_fabricante" class="col-form-label-sm">Fabricante</label>
                                    <input type="hidden" id="produto_fabricante" name="produto_fabricante"
                                        class="form-control form-control-sm" placeholder="Fabricante"
                                        formControlName="produto_fabricante_id">
                                    <div class="input-group">
                                        <input type="text" id="produto_fabricante_descricao"
                                            name="produto_fabricante_descricao" class="form-control form-control-sm"
                                            placeholder="Fabricante" formControlName="produto_fabricante_descricao"
                                            (click)="openModalPesquisarFabricante()">
                                        <div class="input-group-append">
                                            <button class="btn btn-sm btn-maxdata" type="button"
                                                id="remover_produto_fabricante_id"
                                                *ngIf="cadastroForm.controls.produto.get('produto_fabricante_id')?.value"
                                                (click)="removerItemSelecioando('produto_fabricante_id')"><i
                                                    class="material-icons mr-0">clear</i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3" formGroupName="produto">
                                <div class="form-group">
                                    <label for="produto_grupo" class="col-form-label-sm">Grupo</label>
                                    <input type="hidden" id="produto_grupo" name="produto_grupo"
                                        class="form-control form-control-sm" placeholder="Produto Grupo"
                                        formControlName="produto_grupo_id">
                                    <div class="input-group">
                                        <input type="text" id="produto_grupo_descricao" name="produto_grupo_descricao"
                                            class="form-control form-control-sm" placeholder="Produto Grupo"
                                            formControlName="produto_grupo_descricao"
                                            (click)="openModalPesquisarGrupo()">
                                        <div class="input-group-append">
                                            <button class="btn btn-sm btn-maxdata" type="button"
                                                id="remover_produto_grupo_id"
                                                *ngIf="cadastroForm.controls.produto.get('produto_grupo_id')?.value"
                                                (click)="removerItemSelecioando('produto_grupo_id')"><i
                                                    class="material-icons mr-0">clear</i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3" formGroupName="produto">
                                <div class="form-group">
                                    <label for="produto_sub_grupo" class="col-form-label-sm">Sub-Grupo</label>
                                    <input type="hidden" id="produto_sub_grupo" name="produto_sub_grupo"
                                        class="form-control form-control-sm" placeholder="produto Sub-Grupo"
                                        formControlName="produto_sub_grupo_id">
                                    <div class="input-group">
                                        <input type="text" id="produto_sub_grupo_descricao"
                                            name="produto_sub_grupo_descricao" class="form-control form-control-sm"
                                            placeholder="produto Sub-Grupo"
                                            formControlName="produto_sub_grupo_descricao"
                                            (click)="openModalPesquisarSubGrupo()">
                                        <div class="input-group-append">
                                            <button class="btn btn-sm btn-maxdata" type="button"
                                                id="remover_produto_sub_grupo_id"
                                                *ngIf="cadastroForm.controls.produto.get('produto_sub_grupo_id')?.value"
                                                (click)="removerItemSelecioando('produto_sub_grupo_id')"><i
                                                    class="material-icons mr-0">clear</i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </p-tabPanel>
            <p-tabPanel header="Configuração">
                <div class="card rounded-0 border border-top-0">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3" formGroupName="produto">
                                <div class="form-group">
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" id="lista_inventario" name="lista_inventario"
                                            class="custom-control-input form-control-sm"
                                            formControlName="lista_inventario" />
                                        <label class="custom-control-label col-form-label-sm status-filtro"
                                            for="lista_inventario">Lista no Inventário</label>
                                    </div>
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" id="usa_fracionado" name="usa_fracionado"
                                            class="custom-control-input form-control-sm"
                                            formControlName="usa_fracionado" />
                                        <label class="custom-control-label col-form-label-sm status-filtro"
                                            for="usa_fracionado">Fracionar Produto</label>
                                    </div>
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" id="usa_balanca" name="usa_balanca"
                                            class="custom-control-input form-control-sm"
                                            formControlName="usa_balanca" />
                                        <label class="custom-control-label col-form-label-sm status-filtro"
                                            for="usa_balanca">Usa Balança</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3" formGroupName="produto">
                                <div class="form-group">
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" id="usa_m2" name="usa_m2"
                                            class="custom-control-input form-control-sm" formControlName="usa_m2" />
                                        <label class="custom-control-label col-form-label-sm status-filtro"
                                            for="usa_m2">Unidade Produto M2</label>
                                    </div>
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" id="usa_m3" name="usa_m3"
                                            class="custom-control-input form-control-sm" formControlName="usa_m3" />
                                        <label class="custom-control-label col-form-label-sm status-filtro"
                                            for="usa_m3">Unidade Produto M3</label>
                                    </div>
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" id="usa_grade" name="usa_grade"
                                            class="custom-control-input form-control-sm" formControlName="usa_grade" />
                                        <label class="custom-control-label col-form-label-sm status-filtro"
                                            for="usa_grade">Usa Grade</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                                <div class="form-group">
                                    <div class="custom-control custom-checkbox" formGroupName="produto_empresa">
                                        <input type="checkbox" id="altera_valor" name="altera_valor"
                                            class="custom-control-input form-control-sm" placeholder="altera_valor"
                                            autocomplete="off" formControlName="altera_valor" />
                                        <label for="altera_valor"
                                            class="custom-control-label col-form-label-sm col-form-label-sm status-filtro">Alterar
                                            Valor</label>
                                    </div>
                                    <div class="custom-control custom-checkbox" formGroupName="produto">
                                        <input type="checkbox" id="usa_conferidor" name="usa_conferidor"
                                            class="custom-control-input form-control-sm"
                                            formControlName="usa_conferidor" />
                                        <label class="custom-control-label col-form-label-sm status-filtro"
                                            for="usa_conferidor">Usa Conferidor</label>
                                    </div>
                                    <div class="custom-control custom-checkbox" formGroupName="produto">
                                        <input type="checkbox" id="veiculo_novo" name="veiculo_novo"
                                            class="custom-control-input form-control-sm"
                                            formControlName="veiculo_novo" />
                                        <label class="custom-control-label col-form-label-sm status-filtro"
                                            for="veiculo_novo">Veículo Novo</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                                <div class="form-group">
                                    <div class="custom-control custom-checkbox" formGroupName="produto_empresa">
                                        <input type="checkbox" id="nao_cont_estoque" name="nao_cont_estoque"
                                            class="custom-control-input form-control-sm" placeholder="nao_cont_estoque"
                                            autocomplete="off" formControlName="nao_cont_estoque" />
                                        <label for="nao_cont_estoque"
                                            class="custom-control-label col-form-label-sm status-filtro">Não Controlar
                                            Estoque</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <h6 style="padding-left: 3px;">Tipo item (SPED)</h6>
                        <div class="row">
                            <div class="col-xs-12 col-sm-12 col-md-10 col-lg-8 col-xl-6" formGroupName="produto">
                                <div class="form-group">
                                    <!-- <label for="tipo_sped" class="col-form-label-sm">Tipo item (SPED)</label> -->
                                    <select id="tipo_sped" class="form-control form-control-sm"
                                        formControlName="tipo_sped"
                                        [ngClass]="{'is-invalid': displayMessageProdEmpresa.tipo_sped}">
                                        <option *ngFor="let item of listaProdutoSped" [ngValue]="item.codigo">
                                            {{ item.descricao }}</option>
                                    </select>
                                    <span class="invalid-feedback" *ngIf="displayMessageProdEmpresa.tipo_sped">
                                        <p [innerHTML]="displayMessageProdEmpresa.tipo_sped"></p>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </p-tabPanel>
            <p-tabPanel header="Informações Adicionais">
                <div class="card rounded-0 border border-top-0">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6" formGroupName="produto">
                                <div class="form-group">
                                    <label for="aplicacao" class="col-form-label-sm">Aplicação</label>
                                    <textarea id="aplicacao" name="aplicacao" class="form-control form-control-sm"
                                        placeholder="aplicacao" autocomplete="off" formControlName="aplicacao">

                                        </textarea>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6" formGroupName="produto">
                                <div class="form-group">
                                    <label for="inf_ad_prod" class="col-form-label-sm">Informação Adicional do
                                        Produto</label>
                                    <textarea id="inf_ad_prod" name="inf_ad_prod" class="form-control form-control-sm"
                                        placeholder="Informação Adicional do Produto" autocomplete="off"
                                        formControlName="inf_ad_prod">
                                        </textarea>

                                </div>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6" formGroupName="produto">
                                <div class="form-group">
                                    <label for="apresentacao" class="col-form-label-sm">Apresentação</label>
                                    <textarea id="apresentacao" name="apresentacao" class="form-control form-control-sm"
                                        placeholder="Apresentação" autocomplete="off" formControlName="apresentacao">
                                        </textarea>

                                </div>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6" formGroupName="produto">
                                <div class="form-group">
                                    <label for="msg" class="col-form-label-sm">Mensagem do Produto</label>
                                    <textarea type="text" id="msg" name="msg" class="form-control form-control-sm"
                                        placeholder="Mensagem do Produto" autocomplete="off" formControlName="msg">
                                        </textarea>

                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <h6 class="line"><span>Desconto</span></h6>
                                <div class="row">
                                    <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3" formGroupName="produto_empresa">
                                        <div class="form-group">
                                            <div class="custom-control custom-checkbox">
                                                <input type="checkbox" id="oferta" name="oferta"
                                                    class="custom-control-input form-control-sm"
                                                    formControlName="oferta" />
                                                <label class="custom-control-label col-form-label-sm "
                                                    for="oferta">oferta</label>
                                            </div>
                                            <div class="custom-control custom-checkbox"
                                                *ngIf="cadastroForm.value.produto_empresa.oferta">
                                                <input type="checkbox" id="promocao_tem_vencimento"
                                                    name="promocao_tem_vencimento"
                                                    class="custom-control-input form-control-sm"
                                                    formControlName="promocao_tem_vencimento" />
                                                <label class="custom-control-label col-form-label-sm "
                                                    for="promocao_tem_vencimento">Tem Vencicmento</label>
                                            </div>
                                            <div class="input-group input-group-sm mb-2"
                                                *ngIf="cadastroForm.value.produto_empresa.oferta">
                                                <div class="input-group-prepend">
                                                    <div class="input-group-text">R$</div>
                                                </div>
                                                <input type="number" id="vlr_promocao" name="vlr_promocao"
                                                    class="form-control form-control-sm" placeholder="vlr_promocao"
                                                    autocomplete="off" formControlName="vlr_promocao" />
                                            </div>
                                            <div class="input-group input-group-sm mb-2"
                                                *ngIf="cadastroForm.value.produto_empresa.promocao_tem_vencimento && cadastroForm.value.produto_empresa.oferta">
                                                <div class="input-group-prepend ">
                                                    <div class="input-group-text">Venc.</div>
                                                </div>
                                                <input type="date" id="promocao_data_vencimento"
                                                    name="promocao_data_vencimento" class="form-control form-control-sm"
                                                    placeholder="promocao_data_vencimento" autocomplete="off"
                                                    formControlName="promocao_data_vencimento" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3" formGroupName="produto_empresa">
                                        <div class="form-group">
                                            <div class="custom-control custom-checkbox">
                                                <input type="checkbox" id="usa_desconto" name="usa_desconto"
                                                    class="custom-control-input form-control-sm"
                                                    formControlName="usa_desconto" />
                                                <label class="custom-control-label col-form-label-sm "
                                                    for="usa_desconto">Desconto Max</label>
                                            </div>
                                            <div class="custom-control custom-checkbox"
                                                *ngIf="cadastroForm.value.produto_empresa.usa_desconto">
                                                <input type="checkbox" id="usa_desconto_max_lanc_auto"
                                                    name="usa_desconto_max_lanc_auto"
                                                    class="custom-control-input form-control-sm"
                                                    placeholder="usa_desconto_max_lanc_auto" autocomplete="off"
                                                    formControlName="usa_desconto_max_lanc_auto" />
                                                <label for="usa_desconto_max_lanc_auto"
                                                    class="custom-control-label col-form-label-sm">Lançar
                                                    Automaticamente</label>
                                            </div>
                                            <div class="input-group input-group-sm mb-2"
                                                *ngIf="cadastroForm.value.produto_empresa.usa_desconto">
                                                <div class="input-group-prepend">
                                                    <div class="input-group-text">%</div>
                                                </div>
                                                <input type="number" id="aliq_desconto_max" name="aliq_desconto_max"
                                                    class="form-control form-control-sm" placeholder="aliq_desconto_max"
                                                    autocomplete="off" formControlName="aliq_desconto_max" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3" formGroupName="produto_empresa">
                                        <div class="form-group">
                                            <div class="custom-control custom-checkbox">
                                                <input type="checkbox" id="usa_desconto_atacado"
                                                    name="usa_desconto_atacado"
                                                    class="custom-control-input form-control-sm"
                                                    formControlName="usa_desconto_atacado" />
                                                <label class="custom-control-label col-form-label-sm "
                                                    for="usa_desconto_atacado">Desconto Max Atacado</label>
                                            </div>
                                            <div class="custom-control custom-checkbox"
                                                *ngIf="cadastroForm.value.produto_empresa.usa_desconto_atacado">
                                                <input type="checkbox" id="usaDescontoMaxAtaLancAuto"
                                                    name="usaDescontoMaxAtaLancAuto"
                                                    class="custom-control-input form-control-sm"
                                                    placeholder="usaDescontoMaxAtaLancAuto" autocomplete="off" />
                                                <label for="usaDescontoMaxAtaLancAuto"
                                                    class="custom-control-label col-form-label-sm">Lançar
                                                    Automaticamente</label>
                                            </div>
                                            <div class="input-group input-group-sm mb-2"
                                                *ngIf="cadastroForm.value.produto_empresa.usa_desconto_atacado">
                                                <div class="input-group-prepend">
                                                    <div class="input-group-text">%</div>
                                                </div>
                                                <input type="number" id="aliq_desconto_max_atacado"
                                                    name="aliq_desconto_max_atacado"
                                                    class="form-control form-control-sm"
                                                    placeholder="aliq_desconto_max_atacado" autocomplete="off"
                                                    formControlName="aliq_desconto_max_atacado" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3" formGroupName="produto_empresa">
                                        <div class="form-group">
                                            <label for="qtd_atacarejo" class="col-form-label-sm">Qtde
                                                Atacarejo</label>
                                            <input type="number" id="qtd_atacarejo" name="qtd_atacarejo"
                                                class="form-control form-control-sm noscroll"
                                                placeholder="qtd_atacarejo" autocomplete="off"
                                                formControlName="qtd_atacarejo" />

                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3" formGroupName="produto_empresa">
                                        <div class="form-group">
                                            <label class="col-form-label-sm " for="usa_desconto_atacado">Desc.
                                                Atacarejo</label>
                                            <div class="input-group input-group-sm mb-2">
                                                <div class="input-group-prepend">
                                                    <div class="input-group-text">R$</div>
                                                </div>
                                                <input type="number" id="vlr_desconto_atacarejo"
                                                    name="vlr_desconto_atacarejo" class="form-control form-control-sm"
                                                    placeholder="vlr_desconto_atacarejo" autocomplete="off"
                                                    formControlName="vlr_desconto_atacarejo" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <h6 class="line"><span>Desconto</span></h6>
                                <div class="row">
                                    <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3" formGroupName="produto_empresa">
                                        <div class="form-group">
                                            <div class="custom-control custom-checkbox">
                                                <input type="checkbox" id="usa_comissao" name="usa_comissao"
                                                    class="custom-control-input form-control-sm"
                                                    formControlName="usa_comissao" />
                                                <label class="custom-control-label col-form-label-sm "
                                                    for="usa_comissao">Aplicar Comissão</label>
                                            </div>
                                            <div class="input-group input-group-sm mb-2"
                                                *ngIf="cadastroForm.value.produto_empresa.usa_comissao">
                                                <div class="input-group-prepend">
                                                    <div class="input-group-text">%</div>
                                                </div>
                                                <input type="number" id="aliq_comissao" name="aliq_comissao"
                                                    class="form-control form-control-sm" placeholder="aliq_comissao"
                                                    autocomplete="off" formControlName="aliq_comissao" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3" formGroupName="produto_empresa">
                                        <div class="form-group">
                                            <div class="custom-control custom-checkbox">
                                                <input type="checkbox" id="usa_comissao_atacado"
                                                    name="usa_comissao_atacado"
                                                    class="custom-control-input form-control-sm"
                                                    formControlName="usa_comissao_atacado" />
                                                <label class="custom-control-label col-form-label-sm "
                                                    for="usa_comissao_atacado">Aplicar Comissão Atacado</label>
                                            </div>
                                            <div class="input-group input-group-sm mb-2"
                                                *ngIf="cadastroForm.value.produto_empresa.usa_comissao_atacado">
                                                <div class="input-group-prepend">
                                                    <div class="input-group-text">%</div>
                                                </div>
                                                <input type="number" id="aliq_comissao_atacado"
                                                    name="aliq_comissao_atacado" class="form-control form-control-sm"
                                                    placeholder="aliq_comissao_atacado" autocomplete="off"
                                                    formControlName="aliq_comissao_atacado" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3" formGroupName="produto_empresa">
                                        <div class="form-group">
                                            <label class="col-form-label-sm " for="vlr_comissao_entrega">Valor
                                                Comissão Entrega</label>
                                            <div class="input-group input-group-sm mb-2">
                                                <div class="input-group-prepend">
                                                    <div class="input-group-text">R$</div>
                                                </div>
                                                <input type="number" id="vlr_comissao_entrega"
                                                    name="vlr_comissao_entrega" class="form-control form-control-sm"
                                                    placeholder="vlr_comissao_entrega" autocomplete="off"
                                                    formControlName="vlr_comissao_entrega" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <hr />

                        <!--
                            foto
                        -->
                        <div class="row">

                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3" formGroupName="produto">

                                <img [src]="url? url: 'https://via.placeholder.com/300x150?text=Imagem'"
                                    class="img-thumbnail"> <br />
                                <div class="form-group file">
                                    <label for="img_foto" class="btn btn-sm btn-secondary mr-2 mt-1">Escolher
                                        Foto</label>
                                    <button class="btn btn-sm btn-success mr-2" type="button"
                                        (click)="salvarImg()">Salvar</button>
                                    <button class="btn btn-sm btn-maxdata" type="button"
                                        (click)="limparImg()">Limpar</button>
                                    <input type="file" id="img_foto" (change)="handleFileSelect($event)">
                                </div>

                            </div>

                            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-3 mt-2">

                                <div class="card">
                                    <div class="card-body pt-1 pb-1 border-bottom">
                                        <h6><span>Código de Barras</span></h6>
                                    </div>
                                    <div class="card-body pt-1 pb-1 border-bottom">
                                        <div class="row" formGroupName="produto_ean" *ngIf="alterar">
                                            <div class="col-sm-4 col-md-3 col-lg-8 col-xl-8">
                                                <div class="form-group">
                                                    <label for="codigo_ean" class="col-form-label-sm">Código
                                                        Barras</label>
                                                    <input type="text" id="codigo_ean" name="codigo_ean"
                                                        class="form-control form-control-sm noscroll"
                                                        placeholder="Código Barras" formControlName="codigo_ean">
                                                </div>
                                            </div>
                                            <div class="col-sm-6 col-md-4 col-lg-4 col-xl-4 mt-4">
                                                <div class="form-group">
                                                    <button class="btn btn-sm btn-outline-success"
                                                        id="adicionarCodigoBarra" (click)="adicionarCodigoBarra()"
                                                        type="button" title="adicionar"><i
                                                            class="material-icons">add</i></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="table-wrapper-scroll-y" style="min-height: 4rem">
                                        <table class="table table-hover table-borderless table-striped table-sm"
                                            width="100%">
                                            <thead class="thead">
                                                <tr>
                                                    <th scope="col">Código Barras</th>
                                                    <th scope="col"></th>
                                                    <th scope="col"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr class="cursor" *ngFor="let item of listaCodigoBarra.itens">
                                                    <td class="text-nowrap pr-2"
                                                        (dblclick)="atualizarCodigoBarra(item)">{{ item.codigo_ean }}
                                                    </td>
                                                    <td class="text-nowrap pr-2"></td>
                                                    <td class="text-end"><a title="Remover"
                                                            class="badge badge-maxdata float-left"
                                                            (click)="removerCodigoBarra(item)"><i
                                                                class="material-icons">delete</i></a></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3" formGroupName="produto_empresa">
                                <div class="form-group">
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" id="usa_preco_pauta" name="usa_preco_pauta"
                                            class="custom-control-input form-control-sm"
                                            formControlName="usa_preco_pauta" />
                                        <label class="custom-control-label col-form-label-sm "
                                            for="usa_preco_pauta">Usar
                                            Preço Pauta</label>
                                    </div>
                                    <div class="input-group input-group-sm mb-3"
                                        *ngIf="cadastroForm.value.produto_empresa.usa_preco_pauta">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">R$</div>
                                        </div>
                                        <input type="number" id="vlr_preco_pauta" name="vlr_preco_pauta"
                                            class="form-control form-control-sm" placeholder="vlr_preco_pauta"
                                            autocomplete="off" formControlName="vlr_preco_pauta" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-4 col-lg-3 col-xl-3" formGroupName="produto">
                                <div class="form-group">
                                    <label for="peso" class="col-form-label-sm">Peso</label>
                                    <input type="number" id="peso" name="peso"
                                        class="form-control form-control-sm noscroll" placeholder="peso"
                                        autocomplete="off" formControlName="peso" />
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3" formGroupName="produto">
                                <div class="form-group">
                                    <label for="volume" class="col-form-label-sm">Volume</label>
                                    <input type="text" id="volume" name="volume" class="form-control form-control-sm"
                                        placeholder="volume" autocomplete="off" formControlName="volume" />

                                </div>
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3" formGroupName="produto">
                                <div class="form-group">
                                    <label for="garantia" class="col-form-label-sm">Garantia</label>
                                    <input type="text" id="garantia" name="garantia"
                                        class="form-control form-control-sm" placeholder="Garantia" autocomplete="off"
                                        formControlName="garantia" />

                                </div>
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3" formGroupName="produto">
                                <div class="form-group">
                                    <label for="registro_ms" class="col-form-label-sm">Registro MS</label>
                                    <input type="text" id="registro_ms" name="registro_ms"
                                        class="form-control form-control-sm" placeholder="Registro MS"
                                        autocomplete="off" formControlName="registro_ms" />

                                </div>
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3" formGroupName="produto">
                                <div class="form-group">
                                    <label for="cubagem" class="col-form-label-sm">Cubagem</label>
                                    <input type="number" id="cubagem" name="cubagem"
                                        class="form-control form-control-sm noscroll" placeholder="cubagem"
                                        autocomplete="off" formControlName="cubagem" />

                                </div>
                            </div>

                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3" formGroupName="produto_empresa">
                                <div class="form-group">
                                    <label for="qtd_estoque_min" class="col-form-label-sm">Estoque Mínimo</label>
                                    <input type="number" id="qtd_estoque_min" name="qtd_estoque_min"
                                        class="form-control form-control-sm noscroll" placeholder="Estoque Mínimo"
                                        autocomplete="off" formControlName="qtd_estoque_min" />

                                </div>
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3" formGroupName="produto_empresa">
                                <div class="form-group">
                                    <label for="vlr_custo_dolar" class="col-form-label-sm">Valor Custo Dolar</label>
                                    <input type="number" id="vlr_custo_dolar" name="vlr_custo_dolar"
                                        class="form-control form-control-sm" placeholder="vlr_custo_dolar"
                                        autocomplete="off" formControlName="vlr_custo_dolar" />

                                </div>
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3" formGroupName="produto_empresa">
                                <div class="form-group">
                                    <label for="localizador" class="col-form-label-sm">localizador</label>
                                    <input type="text" id="localizador" name="localizador"
                                        class="form-control form-control-sm" placeholder="Localizador"
                                        autocomplete="off" formControlName="localizador" />

                                </div>
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3" formGroupName="produto_empresa">
                                <div class="form-group">
                                    <label for="prateleira" class="col-form-label-sm">Prateleira</label>
                                    <input type="text" id="prateleira" name="prateleira"
                                        class="form-control form-control-sm" placeholder="Prateleira" autocomplete="off"
                                        formControlName="prateleira" />

                                </div>
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3" formGroupName="produto_empresa">
                                <div class="form-group">
                                    <label for="aliq_issqn" class="col-form-label-sm">Alíquota ISSQN</label>
                                    <input type="number" id="aliq_issqn" name="aliq_issqn"
                                        class="form-control form-control-sm" placeholder="Alíquota ISSQN"
                                        autocomplete="off" formControlName="aliq_issqn" />

                                </div>
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3" formGroupName="produto_empresa">
                                <div class="form-group">
                                    <label for="pontuacao" class="col-form-label-sm">Pontução</label>
                                    <input type="number" id="pontuacao" name="pontuacao"
                                        class="form-control form-control-sm noscroll" placeholder="Pontuacão"
                                        autocomplete="off" formControlName="pontuacao"
                                        [ngClass]="{'is-invalid': displayMessageProdEmpresa.pontuacao}" />
                                    <span class="text-danger" *ngIf="displayMessageProdEmpresa.pontuacao">
                                        <p [innerHTML]="displayMessageProdEmpresa.pontuacao"></p>
                                    </span>

                                </div>
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3" formGroupName="produto_empresa">
                                <div class="form-group">
                                    <label for="qtd_multiplo" class="col-form-label-sm">Qtd. Múltiplo</label>
                                    <input type="number" id="qtd_multiplo" name="qtd_multiplo"
                                        class="form-control form-control-sm noscroll" placeholder="Qtd. Múltiplo"
                                        autocomplete="off" formControlName="qtd_multiplo" />

                                </div>
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3" formGroupName="produto_empresa">
                                <div class="form-group">
                                    <label for="vlr_preco_max_cons" class="col-form-label-sm">Valor Preço Máximo
                                        Consumo</label>
                                    <input type="number" id="vlr_preco_max_cons" name="vlr_preco_max_cons"
                                        class="form-control form-control-sm" placeholder="Valor Preço Máximo Consumo"
                                        autocomplete="off" formControlName="vlr_preco_max_cons" />

                                </div>
                            </div>

                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3" formGroupName="produto">
                                <div class="form-group">
                                    <label for="qtd_com_entrada" class="col-form-label-sm">Qtd. Comercial
                                        Entrada</label>
                                    <input type="number" id="qtd_com_entrada" name="qtd_com_entrada"
                                        class="form-control form-control-sm" placeholder="Qtd Comercial Entrada"
                                        autocomplete="off" formControlName="qtd_com_entrada" />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </p-tabPanel>
        </p-tabView>
    </form>

    <p-dialog header="Pesquisar Fabricante" [(visible)]="modalPesquisarFabricante" [modal]="true"
        [style]="{width: '90vw'}" [maximizable]="true" [baseZIndex]="10000" [draggable]="false" [resizable]="false"
        *ngIf="modalPesquisarFabricante">


        <div class="row float-right">
            <button class="btn btn-sm btn-outline-primary" (click)="pesquisarFabricante()"><i
                    class="material-icons">search</i>Pesquisar
            </button>

            <button class="btn btn-sm btn-outline-success" id="pesquisar" (click)="abrirModalCadastroFabricante()">
                <i class="material-icons">add</i><span>Novo</span>
            </button>
        </div>

        <div class="card d-flex flex-row   mt-5">
            <div class="col-sm-6 col-md-6 col-lg-6 col-xl-4">
                <div class="form-group">
                    <label for="codigoFab" class="col-form-label-sm">Código</label>
                    <input type="text" id="codigoFab" name="codigoFab" class="form-control form-control-sm"
                        placeholder="CódigoFab" [(ngModel)]="pesquisa.id">
                </div>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-6 col-xl-8">
                <div class="form-group">
                    <label for="nome" class="col-form-label-sm">Nome</label>
                    <input type="text" id="nome" name="nome" class="form-control form-control-sm"
                        placeholder="Pesquise pelo Nome" [(ngModel)]="pesquisa.descricao">
                </div>
            </div>
            <input type="hidden" id="quantidadeRegistrosPagina" name="quantidadeRegistrosPagina"
                class="form-control form-control-sm" [(ngModel)]="pesquisa.quantidadeRegistrosPagina" [value]="2">
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div class="table-responsive-sm">
                    <table class="table table-sm table-borderless table-striped table-hover">
                        <thead class="thead">
                            <th>Código</th>
                            <th>Nome</th>
                        </thead>
                        <tbody>
                            <tr class="cursor" *ngFor="let item of listaFabricante.itens"
                                (click)="selecionar('produto_fabricante',item.id, item.descricao)">
                                <td>{{item.id }}</td>
                                <td>{{item.descricao}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <p-footer>
            <app-paginacao [qdtPaginas]="pesquisa.quantidadeRegistrosPagina" [itensgrid]="listaFabricante.itens.length"
                [itemsPerPage]="listaFabricante.paginacao.quantidadeRegistrosPagina"   [pagina]="pesquisa.pagina" 
                [totalItems]="listaFabricante.paginacao.totalRegistros" (onPaginate)="mudarPaginaFabricante($event)">
            </app-paginacao>


        </p-footer>
    </p-dialog>
    <p-dialog header="Cadastrar Fabricante" [(visible)]="modalCadastrarFabricante" [modal]="true"
        [style]="{width: '90vw'}" [maximizable]="true" [baseZIndex]="10000" [draggable]="false" [resizable]="false"
        *ngIf="modalCadastrarFabricante">

        <div class="btns-group float-right">
            <button class="btn btn-sm btn-outline-secondary" (click)="fecharModalCadastroFabricante()"><i
                    class="material-icons">close</i>Cancelar</button>
            <button (click)="adicionarFabricante()" id="Salvar" class="btn btn-sm btn-outline-success">
                <i class="material-icons">done_all</i><span>Salvar</span>
            </button>
        </div>

        <form [formGroup]="cadastroForm">

            <div class="card d-flex flex-row  mt-5">
                  <div class="col-sm-6 col-md-6 col-lg-6 col-xl-8">
                    <div class="form-group" formGroupName="produto_fabricante">
                        <label for="descricao" class="col-form-label-sm">Descrição do Fabricante<span
                                class="text-muted"> (Obrigatório)</span></label>
                        <input type="text"  name="descricao" class="form-control form-control-sm"
                            placeholder="Descrição do Fabricante" autocomplete="off" formControlName="descricao"
                            [ngClass]="{'is-invalid': displayMessage.descricao}" />
                        <span class="invalid-feedback" *ngIf="displayMessage.descricao">
                            <p [innerHTML]="displayMessage.descricao"></p>
                        </span>
                    </div>
                </div>
                
            </div>
        </form>
        <p-footer>
        </p-footer>
    </p-dialog>

    <p-dialog header="Pesquisar Grupo" [(visible)]="modalPesquisarGrupo" [modal]="true" [style]="{width: '90vw'}"
        [maximizable]="true" [baseZIndex]="10000" [draggable]="false" [resizable]="false" *ngIf="modalPesquisarGrupo">
        <div class="row float-right">
            <button class="btn btn-sm btn-outline-primary" (click)="pesquisarGrupo()"><i
                    class="material-icons">search</i>Pesquisar
            </button>

            <button class="btn btn-sm btn-outline-success" id="pesquisar" (click)="abrirModalCadastroGrupo()">
                <i class="material-icons">add</i><span>Novo</span>
            </button>
        </div>



        <div class="row">
            <div class="col-sm-6 col-md-6 col-lg-6 col-xl-4">
                <div class="form-group">
                    <label for="grupoId" class="col-form-label-sm">Código</label>
                    <input type="text" id="grupoId" name="grupoId" class="form-control form-control-sm"
                        placeholder="Código" [(ngModel)]="pesquisa.id">
                </div>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-6 col-xl-8">
                <div class="form-group">
                    <label for="nome" class="col-form-label-sm">Nome</label>
                    <input type="text" id="nome" name="nome" class="form-control form-control-sm"
                        placeholder="Pesquise pelo Nome" [(ngModel)]="pesquisa.descricao">
                </div>
            </div>
            <input type="hidden" id="quantidadeRegistrosPagina" name="quantidadeRegistrosPagina"
                class="form-control form-control-sm" [(ngModel)]="pesquisa.quantidadeRegistrosPagina" [value]="2">
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div class="table-responsive-sm">
                    <table class="table table-sm table-borderless table-striped table-hover">
                        <thead class="thead">
                            <th>Código</th>
                            <th>Nome</th>
                        </thead>
                        <tbody>
                            <tr class="cursor" *ngFor="let item of listaGrupo.itens"
                                (click)="selecionar('produto_grupo',item.id, item.descricao)">
                                <td>{{item.id }}</td>
                                <td>{{item.descricao}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <p-footer>
            <div class="btn-group btn-group-sm justify-content-end">
                <pagination boundaryLinks="true" [maxSize]="5" [rotate]="false" previousText="&lsaquo;"
                    nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;"
                    [itemsPerPage]="listaGrupo.paginacao.quantidadeRegistrosPagina"
                    [totalItems]="listaGrupo.paginacao.totalRegistros" (pageChanged)="mudarPaginaGrupo($event)">
                </pagination>
            </div>
        </p-footer>
    </p-dialog>
    <p-dialog header="Cadastrar Grupo" [(visible)]="modalCadastrarGrupo" [modal]="true" [style]="{width: '90vw'}"
        [maximizable]="true" [baseZIndex]="10000" [draggable]="false" [resizable]="false" *ngIf="modalCadastrarGrupo">
        <div class="btns-group float-right">
            <button class="btn btn-sm btn-outline-secondary" (click)="fecharModalCadastroGrupo()"><i
                    class="material-icons">close</i>Cancelar</button>
            <button (click)="AdicionarGrupo()" id="Salvar" class="btn btn-sm btn-outline-success">
                <i class="material-icons">done_all</i><span>Salvar</span>
            </button>
        </div>


        <form [formGroup]="cadastroForm">

            <div class="card d-flex flex-row  mt-5">
                                <div class="col-sm-6 col-md-6 col-lg-6 col-xl-8">
                    <div class="form-group" formGroupName="produto_grupo">
                        <label for="descricao" class="col-form-label-sm">Descrição do Grupo<span class="text-muted">
                                (Obrigatório)</span></label>
                        <input type="text" id="descricao" name="descricao" class="form-control form-control-sm"
                            placeholder="Descrição do Grupo" autocomplete="off" formControlName="descricao"
                            [ngClass]="{'is-invalid': displayMessage.descricao}" />
                        <span class="invalid-feedback" *ngIf="displayMessage.descricao">
                            <p [innerHTML]="displayMessage.descricao"></p>
                        </span>
                    </div>
                </div>

                <div class="col-sm-4 col-md-4 col-lg-3 col-xl-2">
                    <div class="form-group" formGroupName="produto_grupo">
                        <label for="comissao" class="col-form-label-sm">Comissão</label>
                        <input type="number" id="aliq_comissao" name="aliq_comissao"
                            class="form-control form-control-sm noscroll" placeholder="0" autocomplete="off" step="0.1"
                            formControlName="aliq_comissao" [ngClass]="{'is-invalid': displayMessage.aliq_comissao}" />
                        <span class="invalid-feedback" *ngIf="displayMessage.aliq_comissao">
                            <p [innerHTML]="displayMessage.aliq_comissao"></p>
                        </span>
                    </div>
                </div>
                
            </div>
        </form>
    </p-dialog>

    <p-dialog header="Pesquisar Sub-Grupo" [(visible)]="modalPesquisarSubGrupo" [modal]="true" [style]="{width: '90vw'}"
        [maximizable]="true" [baseZIndex]="10000" [draggable]="false" [resizable]="false"
        *ngIf="modalPesquisarSubGrupo">
        <div class="d-flex justify-content-between">
            <label for="codigo" class="col-form-label-sm">
                <h6>Grupo: {{produto_grupo_descricao.value}}</h6>
            </label>
            <div class="button">

                <button class="btn btn-sm btn-outline-success" id="pesquisar" (click)="abrirModalCadastroSubGrupo()">
                    <i class="material-icons">add</i><span>Novo</span>
                </button>

                <button class="btn btn-sm btn-outline-info form-inline" (click)="pesquisarSubGrupo()"><i
                        class="material-icons">search</i>Pesquisar</button>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6 col-md-6 col-lg-6 col-xl-4">
                <div class="form-group">
                    <label for="codigo" class="col-form-label-sm">Código</label>
                    <input type="text" id="codigo" name="codigo" class="form-control form-control-sm"
                        placeholder="Código" [(ngModel)]="pesquisa.id">
                </div>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-6 col-xl-8">
                <div class="form-group">
                    <label for="nome" class="col-form-label-sm">Nome</label>
                    <input type="text" id="nome" name="nome" class="form-control form-control-sm"
                        placeholder="Pesquise pelo Nome" [(ngModel)]="pesquisa.descricao">
                </div>
            </div>
            <input type="hidden" id="quantidadeRegistrosPagina" name="quantidadeRegistrosPagina"
                class="form-control form-control-sm" [(ngModel)]="pesquisa.quantidadeRegistrosPagina" [value]="2">
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div class="table-responsive-sm">
                    <table class="table table-sm table-borderless table-striped table-hover">
                        <thead class="thead">
                            <th>Código</th>
                            <th>Nome</th>
                        </thead>
                        <tbody>
                            <tr class="cursor" *ngFor="let item of listaSubGrupo.itens"
                                (click)="selecionar('produto_sub_grupo',item.id, item.descricao)">
                                <td>{{item.id }}</td>
                                <td>{{item.descricao}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <p-footer>
            <div class="btn-group btn-group-sm justify-content-end">
                <pagination boundaryLinks="true" [maxSize]="5" [rotate]="false" previousText="&lsaquo;"
                    nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;"
                    [itemsPerPage]="listaSubGrupo.paginacao.quantidadeRegistrosPagina"
                    [totalItems]="listaSubGrupo.paginacao.totalRegistros" (pageChanged)="mudarPaginaSubGrupo($event)">
                </pagination>
            </div>
        </p-footer>
    </p-dialog>
    <p-dialog header="Cadastrar Sub-Grupo" [(visible)]="modalCadastrarSubGrupo" [modal]="true" [style]="{width: '90vw'}"
        [maximizable]="true" [baseZIndex]="10000" [draggable]="false" [resizable]="false"
        *ngIf="modalCadastrarSubGrupo">
        <div class="btns-group float-right">
            <button class="btn btn-sm btn-outline-secondary" (click)="fecharModalCadastroSubGrupo()"><i
                    class="material-icons">close</i>Cancelar</button>
            <button (click)="AdicionarSubGrupo()" class="btn btn-sm btn-outline-success" type="button">
                <i class="material-icons">done_all</i><span>Salvar</span>
            </button>
        </div>


        <form [formGroup]="cadastroForm">
            <div class="form-row">              
                    <div class="col-sm-6 col-md-6 col-lg-6 col-xl-8">
                        <div class="form-group" formGroupName="produto_sub_grupo">
                            <label for="descricao" class="col-form-label-sm">Descrição do Sub-Grupo<span class="text-muted">
                                    (Obrigatório)</span></label>
                            <input type="text" id="descricao" name="descricao" class="form-control form-control-sm"
                                placeholder="Descrição do Sub-Grupo" autocomplete="off" formControlName="descricao"
                                [ngClass]="{'is-invalid': displayMessage.descricao}" />
                            <span class="invalid-feedback" *ngIf="displayMessage.descricao">
                                <p [innerHTML]="displayMessage.descricao"></p>
                            </span>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-4 col-xl-3" formGroupName="produto_sub_grupo">
                        <div class="form-group">
                            <label for="produto_grupo_id" class="col-form-label-sm">Grupo</label>
                            <select id="produto_grupo_id" name="produto_grupo_id" class="form-control form-control-sm"
                                placeholder="produto_grupo_id" autocomplete="off" formControlName="produto_grupo_id"
                                [ngClass]="{'is-invalid': displayMessageProduto.produto_grupo_id}">
                                <option [ngValue]="0"></option>
                                <option *ngFor="let item of listaGrupo.itens" [ngValue]="item.id">
                                    {{item.id}} - {{ item.descricao }}</option>
                            </select>
                            <span class="text-danger" *ngIf="displayMessageProduto.produto_grupo_id">
                                <p [innerHTML]="displayMessageProduto.produto_grupo_id"></p>
                            </span>
                        </div>
                    </div>
    
                    <div class="col-sm-4 col-md-4 col-lg-3 col-xl-2">
                        <div class="form-group" formGroupName="produto_sub_grupo">
                            <label for="comissao" class="col-form-label-sm">Comissão</label>
                            <input type="number" id="aliq_comissao" name="aliq_comissao"
                                class="form-control form-control-sm noscroll" placeholder="0" autocomplete="off" step="0.1"
                                formControlName="aliq_comissao" [ngClass]="{'is-invalid': displayMessage.aliq_comissao}" />
                            <span class="invalid-feedback" *ngIf="displayMessage.aliq_comissao">
                                <p [innerHTML]="displayMessage.aliq_comissao"></p>
                            </span>
                        </div>
                    </div>
                       
               
            </div>
        </form>
    </p-dialog>

    <ng-template #modalEmpresaFiltroRef>
        <app-empresa-filtro [table_name]="tabela" [id]="objProduto.produto?.id"
            (closeModal)="closeModalEmpresaFiltroRef()">
        </app-empresa-filtro>
    </ng-template>
</p-dialog>

<app-produto-ncm-cest-cadastro-modal *ngIf="abrirFecharProdutoNcmCestCadastroModal"
    [abrirFecharProdutoNcmCestCadastroModal]="abrirFecharProdutoNcmCestCadastroModal"
    [produtoNcmId]="produto_ncm_id?.value" (closeModal)="fecharModalCadastroProdutoNcmCest()">
</app-produto-ncm-cest-cadastro-modal>