import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertMessage } from '@modules/alert.configuration.service';
import { ProdutoGrupoService } from '@modules/manager/cadastro/estoque/produto-grupo/produto-grupo.service';
import { ProdutoSubGrupoService } from '@modules/manager/cadastro/estoque/produto-sub-grupo/produto-sub-grupo.service';
import { ProdutoSubGrupoPesquisaViewModel } from '@modules/manager/view-models/cadastro/estoque/pesquisa/ProdutoSubGrupoPesquisaViewModel';
import { Uteis } from '@modules/utilizarios/uteis';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-produto-sub-grupo-pesquisa-modal',
  templateUrl: './produto-sub-grupo-pesquisa-modal.component.html',
  styles: [
  ]
})
export class ProdutoSubGrupoPesquisaModalComponent implements OnInit {
  @Input() abrirFecharPesquisaProdutoSubGrupoModal: boolean = false;
  @Input() stringPesquisa: string = '';
  @Input() produtoGrupoId: number = 0;
  @Output() closeModal = new EventEmitter();
  @Output() produtoSubGrupo: EventEmitter<any> = new EventEmitter();
  pesquisa: ProdutoSubGrupoPesquisaViewModel = new ProdutoSubGrupoPesquisaViewModel();
  listaProdutoSubGrupo: ProdutoSubGrupoPesquisaViewModel[] = [];

  constructor(
    private produtoSubGrupo$: ProdutoSubGrupoService,
    private alert: AlertMessage,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    setTimeout(() => {
      (document.querySelector(`[name="descricao"]`) as HTMLElement).focus();
      this.pesquisa.descricao = this.stringPesquisa;
    }, 100);
  }

  pesquisar() {
    this.spinner.show();

    this.pesquisa.produto_grupo_id = this.produtoGrupoId;

    this.produtoSubGrupo$.obterTodos(this.pesquisa).subscribe(
      retorno => {

        this.listaProdutoSubGrupo = retorno.total > 0 ? retorno.result : [];

        this.spinner.hide();
      }, 
      err => {
        this.spinner.hide();

        this.alert.Danger('Erro', Uteis.ObterErroApi(err));
      });
  }

  produtoSubGrupoSelecionado(item: ProdutoSubGrupoPesquisaViewModel) {
    this.produtoSubGrupo.emit(item);
  }

  closeModalProdutoSubGrupo() {
    this.closeModal.emit(false);
  }
}
