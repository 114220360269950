import { PaginacaoViewModel } from '../../PaginacaoViewModel';
import { ConfiguracaoViewModel } from '../configuracao-view-model';

export class ConfiguracaoPesquisaViewModel extends ConfiguracaoViewModel implements PaginacaoViewModel {
    pagina: number = 1;
    quantidadeRegistrosPagina: number = 20;
    totalRegistros: number = 0;
    totalPaginas: number = 0;
    desativados: boolean = false;
    start?: number = 0;
    order?: string;
    emp_endereco_municipio?: string;
    emp_endereco_uf?: string;
    emp_logo?: string;
    emp_filtro?: boolean;

    // Inventário 
    numero_livro?: number;
    dt_inventario?: string;
    endereco_municipio_codigo_ibge?: number;
    endereco_municipio_descricao?: string;
    endereco_municipio_ativo?: boolean;
    endereco_municipio_codigo_pais?: number;
    endereco_municipio_descricao_pais?: string;
    endereco_uf_codigo?: number;
    endereco_uf_sigla?: string;
    endereco_uf_descricao?: string;
    endereco_uf_ativo?: boolean;



}
