import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {AutenticacaoService} from "@modules/autenticacao/services/autenticacao.service";
import {PesquisaGeralViewModel} from "@modules/admin/view-models/pesquisa/PesquisaGeralViewModel";
import {Observable} from "rxjs";
import {RetornoApiViewModel} from "@manager/view-models/RetornoApiViewModel";
import {URLBASE} from "@modules/configuration.service";
import {EcommerceSubcategoriaPesquisaViewModel} from "@manager/view-models/cadastro/ecommerce/pesquisa/ecommerce-subcategoria-pesquisa-view-model";
import {EcommerceSubcategoriaViewModel} from "@manager/view-models/cadastro/ecommerce/ecommerce-subcategoria-view-model";
import {CadastroSubcategoriaViewModel} from "@manager/view-models/cadastro/ecommerce/cadastro-subcategoria-view-model";
import {CadastroViewModel} from "@modules/admin/view-models/cadastro/CadastroViewModel";

@Injectable({
    providedIn: 'root'
})
export class SubcategoriaService {

    idEmpresaMaxdata = this.autenticacaoService.acessoUsuarioValue?.idEmpresaMaxdata;

    urlGet = `${URLBASE}/${this.idEmpresaMaxdata}/ecommerce_sub_categoria_service/get`;
    urlAddUpdate = `${URLBASE}/${this.idEmpresaMaxdata}/ecommerce_sub_categoria_service/addupdate`;

    constructor(private http: HttpClient, private autenticacaoService: AutenticacaoService) {
    }

    obterTodos(pesquisa: EcommerceSubcategoriaPesquisaViewModel) : Observable<RetornoApiViewModel<EcommerceSubcategoriaPesquisaViewModel[]>> {

        let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel();

        pesquisaGeral.select = ` ecommerce_sub_categoria.*`;
        pesquisaGeral.calcRowCount = 1;
        pesquisaGeral.limit = pesquisa?.quantidadeRegistrosPagina || 100;
        pesquisaGeral.where = ` and ecommerce_sub_categoria.ativo = ${pesquisa?.desativados ? 0 : 1}`;
        pesquisaGeral.order = ` ecommerce_sub_categoria.id`;
        pesquisaGeral.start = pesquisa?.start || 0;

        if (pesquisa?.id) pesquisaGeral.where += ' and ecommerce_sub_categoria.id = ' + pesquisa?.id;
        if (pesquisa?.descricao) pesquisaGeral.where += ` and lower(ecommerce_sub_categoria.descricao) like lower('%` + pesquisa?.descricao + `%')`;

        return this.http.post<RetornoApiViewModel<EcommerceSubcategoriaPesquisaViewModel[]>>(this.urlGet, pesquisaGeral);
    }

    obterPorCategoria(idCat: number, pesquisa: EcommerceSubcategoriaPesquisaViewModel) : Observable<RetornoApiViewModel<EcommerceSubcategoriaPesquisaViewModel[]>> {
        let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel();

        pesquisaGeral.select = ` ecommerce_sub_categoria.*`;
        pesquisaGeral.calcRowCount = 1;
        pesquisaGeral.limit = pesquisa?.quantidadeRegistrosPagina || 100;
        pesquisaGeral.where = ` and ecommerce_sub_categoria.ativo = ${pesquisa?.desativados ? 0 : 1} and ecommerce_sub_categoria.ecommerce_categoria_id = ${idCat}`;
        pesquisaGeral.order = ` ecommerce_sub_categoria.id`;
        pesquisaGeral.start = pesquisa?.start || 0;

        if (pesquisa?.id) pesquisaGeral.where += ' and ecommerce_sub_categoria.id = ' + pesquisa?.id;
        if (pesquisa?.descricao) pesquisaGeral.where += ` and lower(ecommerce_sub_categoria.descricao) like lower('%` + pesquisa?.descricao + `%')`;

        return this.http.post<RetornoApiViewModel<EcommerceSubcategoriaPesquisaViewModel[]>>(this.urlGet, pesquisaGeral);
    }

    obterPorId(id: number): Observable<RetornoApiViewModel<EcommerceSubcategoriaViewModel[]>>{

        let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel();

        pesquisaGeral.select = ` ecommerce_sub_categoria.*`;

        pesquisaGeral.where = ` and ecommerce_sub_categoria.id = ${id}`;

        return this.http.post<RetornoApiViewModel<EcommerceSubcategoriaViewModel[]>>(`${this.urlGet}`,pesquisaGeral);
    }

    adicionar(ecommerceSubcategoriaViewModel: CadastroViewModel<EcommerceSubcategoriaViewModel>) : Observable<any>{

        return this.http.post<any[]>(this.urlAddUpdate, ecommerceSubcategoriaViewModel);

    }

    atualizar(id: number, ecommerceSubcategoriaViewModel: CadastroViewModel<EcommerceSubcategoriaViewModel>) : Observable<any>{

        return this.http.put<any[]>(`${this.urlAddUpdate}/${id}`, ecommerceSubcategoriaViewModel);

    }

    imprimir() { }

}
