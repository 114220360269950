import { Component, OnInit, ViewChildren, ElementRef, AfterViewInit } from '@angular/core';
import { EmpresaViewModel } from 'src/modules/admin/view-models/cadastro/EmpresaViewModel';
import { FormControlName, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ValidationMessages, GenericValidator, DisplayMessage } from 'src/modules/validacao/generic-form-validation';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, fromEvent, merge } from 'rxjs';
import { MensagensValidacao } from 'src/modules/validacao/mensagensValidacao';
import { EmpresaService } from '../empresa.service';
import { TituloPagina } from 'src/modules/utilizarios/tituloPaginas/tituloPaginas';
import { HistoricoNavegacao } from 'src/modules/utilizarios/tituloPaginas/historicoNavegacao';
import { TipoDocumento } from 'src/modules/utilizarios/tipoDocumento';

import { utilsBr } from 'js-brasil';
import Stepper from 'bs-stepper';
import { Uteis } from 'src/modules/utilizarios/uteis';
import { isNumber } from 'util';
import { AlertMessage } from 'src/modules/alert.configuration.service';
import { GrupoMultilojaViewModel } from 'src/modules/admin/view-models/cadastro/GrupoMultilojaViewModel';
import { CadastroViewModel } from 'src/modules/admin/view-models/cadastro/CadastroViewModel';
import { NgxSpinnerService } from 'ngx-spinner';
import { PesquisaGeralViewModel } from '@modules/admin/view-models/pesquisa/PesquisaGeralViewModel';
import { AlertMensagemPadrao } from 'src/modules/utilizarios/alertMensagemPadrao';
import { NgBrazilValidators } from 'ng-brazil';

@Component({
  selector: 'app-empresa-cadastro',
  templateUrl: './empresa-cadastro.component.html',
  styles: []
})
export class EmpresaCadastroComponent implements OnInit, AfterViewInit {

  tituloPagina: TituloPagina = new TituloPagina('Cadastro de Empresas - Cadastro',
    [
      new HistoricoNavegacao('Início', '/admin'),
      new HistoricoNavegacao('Cadastro'),
      new HistoricoNavegacao('Empresas', '/admin/cadastro/empresa'),
      new HistoricoNavegacao('Cadastro'),
    ]);

  @ViewChildren(FormControlName, { read: ElementRef }) formInputElements!: ElementRef[];
  documento: string;

  cadastroForm!: FormGroup;
  objCadastro: CadastroViewModel<EmpresaViewModel> = new CadastroViewModel<EmpresaViewModel>();
  alterar: boolean = false;
  formResult: string = '';
  MASKS = utilsBr.MASKS;
  carregando: boolean = false;
  tipoDocumentoCpfCnpj: string = TipoDocumento.TiposCpfCnpj[1];
  listaTiposDocumentos: string[] = TipoDocumento.TiposCpfCnpj;
  listaGrupoMultiloja: GrupoMultilojaViewModel[] = [];

  validationMessages!: ValidationMessages;
  genericValidator!: GenericValidator;
  displayMessage: DisplayMessage = {};

  constructor(private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private empresa$: EmpresaService,
    private alert: AlertMessage,
    private spinner: NgxSpinnerService) {
    this.messagesValidations();
  }

  ngOnInit() {

    this.criarFormulario();

    this.route.params.subscribe(params => {

      let id = parseInt(params['id']);

      this.alterar = isNumber(id) && id > 0;

      if (this.alterar) {

        this.spinner.show();

        this.empresa$.obterPorId(id).subscribe(
          retorno => {

            if (retorno.total <= 0) this.alert.Danger('Erro', 'Empresa não encontrada!');

            this.objCadastro.obj = retorno.total > 0 ? retorno.result[0] : new EmpresaViewModel();
            if (this.objCadastro.obj?.emp_cnpj?.length === 11) {
              this.objCadastro.obj.emp_cnpj = this.objCadastro.obj?.emp_cnpj.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
            } else if (this.objCadastro.obj?.emp_cnpj?.length === 14) {
              this.objCadastro.obj.emp_cnpj =
              this.objCadastro.obj?.emp_cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');

            }


            this.tituloPagina.historicoNavegacao.push(new HistoricoNavegacao(retorno.result[0].descricao || ''));

            this.criarFormulario();

            this.spinner.hide();

          },
          error => {

            this.spinner.hide();

            this.alert.Danger('Erro', Uteis.ObterErroApi(error))
          });
      }
    });
  }

  ngAfterViewInit(): void {
    let controlBlurs: Observable<any>[] = this.formInputElements
      .map((formControl: ElementRef) => fromEvent(formControl.nativeElement, 'blur'));

    merge(...controlBlurs).subscribe(() => {
      this.displayMessage = this.genericValidator.processarMensagens(this.cadastroForm);
    });
  }

  messagesValidations() {
    this.validationMessages = {
      id_maxdata: {
        required: MensagensValidacao.Obrigatorio('Código Maxdata'),
        min: MensagensValidacao.ValorMinimo('Código', 1)
      },
      descricao: {
        required: MensagensValidacao.Obrigatorio('Nome / Fantasia'),
        minlength: MensagensValidacao.MinimoCaracteres('Nome / Fantasia', 2),
        maxlength: MensagensValidacao.MaximoCaracteres('Nome / Fantasia', 50)
      },
      emp_cnpj: {
        required: MensagensValidacao.Obrigatorio('CPF / CNPJ'),
        minlength: MensagensValidacao.MinimoCaracteres('CPF / CNPJ', 14),
        maxlength: MensagensValidacao.MaximoCaracteres('CPF / CNPJ', 18),
        cnpj: MensagensValidacao.FormatoInvalido('CNPJ'),
        cpf: MensagensValidacao.FormatoInvalido('CPF')
      },
      ip_database: {
        required: MensagensValidacao.Obrigatorio('IP / Link do Servidor'),
        maxlength: MensagensValidacao.MaximoCaracteres('IP / Link do Servidor', 50)
      }
    };
    this.genericValidator = new GenericValidator(this.validationMessages);
  }

  criarFormulario() {
    this.cadastroForm = this.formBuilder.group({
      id: [!this.alterar ? 0 : this.objCadastro.obj?.id],
      id_maxdata: [{ value: !this.alterar ? '' : this.objCadastro.obj?.id_maxdata, disabled: this.alterar }, [Validators.required, Validators.min(1)]],
      descricao: [!this.alterar ? '' : this.objCadastro.obj?.descricao, [Validators.required, Validators.minLength(2), Validators.maxLength(50)]],
      emp_cnpj: [!this.alterar ? '' : this.objCadastro.obj?.emp_cnpj, [Validators.required, Validators.minLength(14), Validators.maxLength(18)]],
      ip_database: [{ value: !this.alterar ? '' : this.objCadastro.obj?.ip_database, disabled: this.alterar }, [Validators.required, Validators.maxLength(50)]],
      ativo: [!this.alterar ? true : this.objCadastro.obj?.ativo],
      qtd_max_usuario: [!this.alterar ? 0 : this.objCadastro.obj?.qtd_max_usuario || 0],
    });
  }
  formatarDocumento() {
    const documento = this.cadastroForm.get('emp_cnpj').value.replace(/\D/g, '');

    if (documento.length === 11) {
      this.cadastroForm.get('emp_cnpj').setValue(documento.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4'));
      this.cadastroForm.get('emp_cnpj')?.setValidators([Validators.required, NgBrazilValidators.cpf]);

      this.cadastroForm.get('emp_cnpj')?.updateValueAndValidity();


    } else if (documento.length === 14) {
      this.cadastroForm.get('emp_cnpj').setValue(documento.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5'));

      this.cadastroForm.get('emp_cnpj')?.setValidators([Validators.required, NgBrazilValidators.cnpj]);

      this.cadastroForm.get('emp_cnpj')?.updateValueAndValidity();
    }
  }


  limparForm() {
    this.cadastroForm.reset({
      id: 0,
      id_maxdata: '',
      descricao: '',
      ip_database: '',
      emp_cnpj: '',
      ativo: true,
    });

    (document.querySelector('[name="id_maxdata"]') as HTMLElement).focus();
  }

  onSubmit() {

    this.cadastroForm.markAllAsTouched();

    this.displayMessage = this.genericValidator.processarMensagens(this.cadastroForm);

    if (!this.cadastroForm.valid) {

      this.mensagemCamposInvalidos(this.displayMessage);

      return
    }

    if (!this.cadastroForm.dirty || !this.cadastroForm.valid) {

      this.alert.Info(AlertMensagemPadrao.formularioNaoAlterado.titulo, AlertMensagemPadrao.formularioNaoAlterado.mensagem);

      return
    };

    this.atualizarDadosObjeto();

    this.carregando = true;

    if (this.alterar) this.atualizar();
    else this.adicionar();
  }

  atualizarDadosObjeto() {
    this.objCadastro.obj = Object.assign({}, this.objCadastro.obj, this.cadastroForm.value);

    if (typeof this.objCadastro.obj !== 'undefined') {
      this.objCadastro.obj.emp_cnpj = Uteis.SomenteNumeros(this.cadastroForm.get('emp_cnpj')?.value)
    }

  }

  atualizar() {


    if (this.objCadastro.obj?.id && this.objCadastro.obj?.id > 0)
      this.empresa$.atualizar(this.objCadastro.obj.id, this.objCadastro).subscribe(
        retorno => {

          this.mensagensCadastro();

          this.router.navigate(['/admin/cadastro/empresa']);

          this.carregando = false;

        },
        error => {

          this.carregando = false;

          this.alert.Danger('Erro', Uteis.ObterErroApi(error));
        }
      );
  }

  adicionar() {


    this.empresa$.adicionar(this.objCadastro).subscribe(
      retorno => {

        this.mensagensCadastro()

        this.limparForm();

        this.carregando = false;

      },
      error => {

        this.carregando = false;

        this.alert.Danger('Erro', Uteis.ObterErroApi(error))

      }
    )
  }

  mensagemCamposInvalidos(mensagens: any) {

    let camposInvalidos = Uteis.ConverterObjetoToArray(mensagens);

    this.alert.Warning(AlertMensagemPadrao.formularioInvalido.titulo, AlertMensagemPadrao.formularioInvalido.mensagem + '<br><br>' + camposInvalidos.toString().replace(/,/g, ''));
  }

  private mensagensCadastro(): void {

    this.alert.Success('Sucesso', `Empresa ${this.alterar ? 'atualizada' : 'cadastrada'} com Sucesso.`);

    if (this.alterar) return;

    this.alert.Info('Informativo', 'Formulário de cadastro foi limpo para realizar um novo cadastro.', { timeOut: 10000 });

  }
}
