import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpRequest } from '@angular/common/http';

import { ProdutoSubGrupoPesquisaViewModel } from 'src/modules/manager/view-models/cadastro/estoque/pesquisa/ProdutoSubGrupoPesquisaViewModel';

import { URLBASE } from 'src/modules/configuration.service';
import { ProdutoSubGrupoViewModel } from 'src/modules/manager/view-models/cadastro/estoque/ProdutoSubGrupoViewModel';
import { Observable } from 'rxjs';
import { ListaPaginadaViewModel } from 'src/modules/manager/view-models/ListaPaginadaViewModel';
import { RetornoApiViewModel } from 'src/modules/manager/view-models/RetornoApiViewModel';
import { Uteis } from 'src/modules/utilizarios/uteis';
import { AutenticacaoService } from 'src/modules/autenticacao/services/autenticacao.service';
import { PesquisaGeralViewModel } from '@modules/admin/view-models/pesquisa/PesquisaGeralViewModel';
import { CadastroViewModel } from 'src/modules/admin/view-models/cadastro/CadastroViewModel';

//const urlFinal = `${URLBASE}/sub-grupo-de-produtos`;
@Injectable({
    providedIn: 'root'
})
export class ProdutoSubGrupoService{
    idEmpresaMaxdata = this.autenticacaoService.acessoUsuarioValue?.idEmpresaMaxdata;

    urlGet =  `${URLBASE}/${this.idEmpresaMaxdata}/produto_sub_grupo_service/get`;

    urlAddUpdate  = `${URLBASE}/${this.idEmpresaMaxdata}/produto_sub_grupo_service/addupdate`;

    constructor(private http: HttpClient, private autenticacaoService: AutenticacaoService,){}

    obterTodos(pesquisa?: ProdutoSubGrupoPesquisaViewModel) : Observable<RetornoApiViewModel<ProdutoSubGrupoPesquisaViewModel[]>> {

        let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel()

        pesquisaGeral.select = `produto_sub_grupo.id,
                            produto_sub_grupo.descricao,
                            produto_sub_grupo.ativo,
                            produto_sub_grupo.dt_cadastro,
                            produto_sub_grupo.produto_grupo_id,
                            produto_sub_grupo.aliq_comissao,
                            produto_grupo.descricao as produto_grupo_descricao`;

		pesquisaGeral.calcRowCount = 1;

		pesquisaGeral.where = ` and produto_sub_grupo.ativo = ${pesquisa?.desativados ? 0 : 1}`;

        pesquisaGeral.start = pesquisa?.start || 0;

		pesquisaGeral.limit = pesquisa?.quantidadeRegistrosPagina || 100;

        pesquisaGeral.order = pesquisa?.order ? pesquisa?.order : 'produto_sub_grupo.id'

		if (pesquisa?.id) pesquisaGeral.where += ` and produto_sub_grupo.id = ${pesquisa?.id}`;

		if (pesquisa?.produto_grupo_id) { 
            
            Array.isArray(pesquisa?.produto_grupo_id) ? 

            pesquisaGeral.where += ` and produto_sub_grupo.produto_grupo_id in (${pesquisa?.produto_grupo_id})` :

            pesquisaGeral.where += ` and produto_sub_grupo.produto_grupo_id = ${pesquisa?.produto_grupo_id}`;
        
        }

		if (pesquisa?.descricao) pesquisaGeral.where += ` and lower(produto_sub_grupo.descricao) like lower('%${pesquisa?.descricao}%')`;

		if (pesquisa?.produto_grupo_descricao) pesquisaGeral.where += ` and lower(produto_grupo.descricao) like lower('%${pesquisa.produto_grupo_descricao}%')`;

        return this.http.post<RetornoApiViewModel<ProdutoSubGrupoPesquisaViewModel[]>>(this.urlGet, pesquisaGeral)
    }

    obterPorId(id: number): Observable<RetornoApiViewModel<ProdutoSubGrupoViewModel[]>>{

        let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel()

        pesquisaGeral.select = `produto_sub_grupo.id,
                            produto_sub_grupo.descricao,
                            produto_sub_grupo.ativo,
                            produto_sub_grupo.dt_cadastro,
                            produto_sub_grupo.produto_grupo_id,
                            produto_sub_grupo.aliq_comissao,
                            produto_grupo.descricao as produto_grupo_descricao`;

        pesquisaGeral.where = ` and produto_sub_grupo.id = ${id}`;

        return this.http.post<RetornoApiViewModel<ProdutoSubGrupoViewModel[]>>(this.urlGet, pesquisaGeral);
    }

    adicionar(produtoSubGrupoViewModel:  CadastroViewModel<ProdutoSubGrupoViewModel>) : Observable<RetornoApiViewModel<ProdutoSubGrupoViewModel>>{

        return this.http.post<RetornoApiViewModel<ProdutoSubGrupoViewModel>>(this.urlAddUpdate, produtoSubGrupoViewModel);
    }

    atualizar(id: number, produtoSubGrupoViewModel:  CadastroViewModel<ProdutoSubGrupoViewModel>) : Observable<RetornoApiViewModel<ProdutoSubGrupoViewModel>>{
        
        return this.http.put<RetornoApiViewModel<ProdutoSubGrupoViewModel>>(`${this.urlAddUpdate}/${id}`, produtoSubGrupoViewModel);
    }
}
