import { PaginacaoViewModel } from 'src/modules/manager/view-models/PaginacaoViewModel';
import { EmpresaViewModel } from '../cadastro/EmpresaViewModel';

export class EmpresaPesquisaViewModel extends EmpresaViewModel implements PaginacaoViewModel {
    pagina: number = 1;
    quantidadeRegistrosPagina: number = 20;
    totalRegistros: number = 0;
    totalPaginas: number = 0;
    desativados: boolean = false;
    order?: string;
    start?: number;

}