import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CadastroViewModel } from '@modules/admin/view-models/cadastro/CadastroViewModel';
import { AlertMessage } from '@modules/alert.configuration.service';
import { ProdutoLoteService } from '@modules/manager/cadastro/estoque/produto-lote/produto-lote.service';
import { ProdutoLoteViewModel } from '@modules/manager/view-models/cadastro/estoque/produto-lote-view-model';
import { AlertMensagemPadrao } from '@modules/utilizarios/alertMensagemPadrao';
import { Uteis } from '@modules/utilizarios/uteis';
import { DisplayMessage, GenericValidator, ValidationMessages } from '@modules/validacao/generic-form-validation';
import { MensagensValidacao } from '@modules/validacao/mensagensValidacao';
import { BsModalService } from 'ngx-bootstrap/modal';

import { NgxSpinnerService } from 'ngx-spinner';
import { isNumber } from 'util';

@Component({
  selector: 'app-produto-lote-cadastro-modal',
  templateUrl: './produto-lote-cadastro-modal.component.html',
  styles: [
  ]
})
export class ProdutoLoteCadastroModalComponent implements OnInit {
  @Input() produtoId: number;
  @Input() produtoLote!: ProdutoLoteViewModel;
  @Input() produtoLoteId: number;
  @Input() alterar: boolean = false;
  @Input() abrirFecharProdutoLoteCadastroModal: boolean = false;
  @Output() closeModal = new EventEmitter();

  cadastroForm!: FormGroup;
  validationMessages?: ValidationMessages;
  genericValidator?: GenericValidator;
  displayMessage: DisplayMessage = {};
  carregando: boolean = false;
  objProdutoLote: CadastroViewModel<ProdutoLoteViewModel> = new CadastroViewModel<ProdutoLoteViewModel>();

  constructor(private fb: FormBuilder,
    private modal$: BsModalService,
    private alert: AlertMessage,    
    private spinner: NgxSpinnerService,
    private produtoLote$: ProdutoLoteService,) {
      this.messagesValidations()
     }

  ngOnInit(): void {

    this.criarFormulario();

      this.alterar = isNumber(this.produtoLoteId) && this.produtoLoteId > 0;

      if (this.alterar) {

        this.spinner.show();

        this.produtoLote$.obterPorId(this.produtoLoteId|| 0).subscribe(
          retorno => {

            this.spinner.hide();

            if (retorno.total <= 0) this.alert.Danger('Erro', 'Produto Grade Descrição não encontrado');

            this.objProdutoLote.obj = retorno.total > 0 ? retorno.result[0] : new ProdutoLoteViewModel();

            this.criarFormulario();
          },
          error => {
            this.spinner.hide();

            this.alert.Danger('Erro', Uteis.ObterErroApi(error))
          });
      }
  }

  onSubmit() {

    this.cadastroForm.markAllAsTouched();

    if (!this.cadastroForm.valid) {

      if (typeof this.genericValidator !== 'undefined') {

        this.displayMessage = this.genericValidator.processarMensagens(this.cadastroForm);

      }

      let mensagem = Uteis.ConverterObjetoToArray(this.displayMessage)

      return this.mensagemCamposInvalidos(mensagem);
    }

    if (!this.cadastroForm.dirty) {

      this.alert.Info(AlertMensagemPadrao.formularioNaoAlterado.titulo, AlertMensagemPadrao.formularioNaoAlterado.mensagem);

      return
    }

    this.carregando = true;

    if (this.alterar) this.atualizar();
    else this.adicionar();
  }

  criarFormulario() {
    this.cadastroForm = this.fb.group({
      id:            [{ value: !this.alterar ? 0 : this.objProdutoLote.obj?.id, disabled: true }],
      produto_id:    [!this.alterar ? this.produtoId : this.objProdutoLote.obj?.produto_id, [Validators.required, Validators.min(1)]],
      lote:          [!this.alterar ? null : this.objProdutoLote.obj?.lote, [Validators.required, Validators.minLength(2)]],
      estoque:       [!this.alterar ? null : this.objProdutoLote.obj?.estoque],
      dt_fabricacao: [!this.alterar ? null : this.objProdutoLote.obj?.dt_fabricacao, [Validators.required]],
      dt_vencimento: [!this.alterar ? null : this.objProdutoLote.obj?.dt_vencimento, [Validators.required]],
      vlr_custo:     [!this.alterar ? null : this.objProdutoLote.obj?.vlr_custo],
      prateleira:    [!this.alterar ? null : this.objProdutoLote.obj?.prateleira],
      ativo:         [!this.alterar ? true : this.objProdutoLote.obj?.ativo],
    })
  }

  atualizarDadosObjeto() {

    this.objProdutoLote.obj = Object.assign({}, this.objProdutoLote.obj, this.cadastroForm.value);
    
    this.objProdutoLote.obj.lote = this.objProdutoLote.obj?.lote.toLowerCase();
  }

  atualizar() {

    this.atualizarDadosObjeto();
    
    if (this.objProdutoLote.obj?.id)
      this.produtoLote$.atualizar(this.objProdutoLote.obj?.id, this.objProdutoLote).subscribe(
          () => {

            this.mensagensCadastro();

            this.closeModalCadastroProdutoLote();

            this.carregando = false;

          },
          error => {

            this.carregando = false;

            this.alert.Danger('Erro', Uteis.ObterErroApi(error));

          }
        )
  }

  adicionar() {

    this.atualizarDadosObjeto();

    this.produtoLote$.adicionar(this.objProdutoLote).subscribe(
      retorno => {

        this.alert.Success('Sucesso','Descrição cadastrada com sucesso');

        this.closeModalCadastroProdutoLote();

        this.limparForm();

        this.carregando = false;
      },
      error => {

        this.carregando = false;

        this.alert.Danger('Erro', Uteis.ObterErroApi(error))

      }
    )
  }

  limparForm() {
    this.cadastroForm.reset();
  }

  messagesValidations() {
    this.validationMessages = {
      descricao: {
        required: MensagensValidacao.Obrigatorio('Lote'),
        minlength: MensagensValidacao.MinimoCaracteres('Lote', 2),
      },
    }
  }

  closeModalCadastroProdutoLote() {
    this.closeModal.emit(false);
  }

  private mensagensCadastro(): void {
    this.alert.Success('Sucesso', `Registro ${this.alterar ? 'atualizado' : 'cadastrado'} com Sucesso.`);
    if (this.alterar) return;
    this.alert.Info('Informativo', 'Formulário de cadastro foi limpo para realizar um novo cadastro.', { timeOut: 10000 });
  }

  mensagemCamposInvalidos(mensagens: any) {
    this.alert.Warning(AlertMensagemPadrao.formularioInvalido.titulo, AlertMensagemPadrao.formularioInvalido.mensagem + '<br><br>' + mensagens.toString().replace(/,/g, ''));
  }

}
