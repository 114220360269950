<div class="form-group">
  <label for="cliente_id" class="col-form-label-sm">Código</label>
  <input
    type="number"
    id="cliente_id"
    name="cliente_id"
    class="form-control form-control-sm noscroll"
    placeholder="Código"
    autocomplete="off"
    [(ngModel)]="cliente_id"
    (keydown.enter)="obterCliente()"
  />
</div>
