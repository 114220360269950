import { Acesso } from "@modules/autenticacao/dados/acesso";
import { AutenticacaoService } from "@modules/autenticacao/services/autenticacao.service";
import { UsuarioManagerPermissaoPesquisaViewModel } from "@modules/manager/view-models/cadastro/usuario-manager/pesquisa/usuario-manager-permissao-pesquisa-view-model";

export class VerificarPermissoes { 
    public static temPermissao(roles: UsuarioManagerPermissaoPesquisaViewModel[], permissoesDoUsuario: UsuarioManagerPermissaoPesquisaViewModel[]) : boolean {
        for(let role of roles) {
            let permissao = permissoesDoUsuario.filter(retorno => retorno.id_menu == role.id_menu && retorno[role.chk || ''] == true)  
            if(permissao.length > 0){
                return true;
            }
        }
        return false;
    }
}