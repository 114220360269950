<p-dialog [(visible)]="abrirFecharProdutoCategoriaModal" [modal]="true" [closable]="true" [style]="{width: '100vw'}"
    [maximizable]="true" [baseZIndex]="10000" [draggable]="false" [resizable]="false"
    (onHide)="closeModalProdutoCategoria()">
    <ngx-spinner name="modalAtualiza" bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-clip-rotate"
        [fullScreen]="false"></ngx-spinner>
    <p-header>Categoria de produto</p-header>

    <form>

        <div class="d-flex justify-content-end">
            <div class="btns-group mb-1">
                <button class="btn btn-sm btn-outline-secondary" (click)="closeModalProdutoCategoria()"><i
                        class="material-icons">close</i>Cancelar</button>
                <button class="btn btn-sm btn-outline-success" id="salvar_categoria" type="button"
                    (click)="submit()">
                    <div>
                        <i class="material-icons">done_all</i><span>Salvar</span>
                    </div>
                </button>
            </div>
        </div>

        <div class="card rounded-0" [formGroup]="cadastroForm">
            <div class="card-body pt-1 pb-1">
                <div class="row">
                    <div class="col-sm-4 col-md-4 col-lg-2 col-xl-1">
                        <div class="form-group">
                            <label for="categoria_id" class="col-form-label-sm">Código</label>
                            <input type="number" id="categoria_id" name="categoria_id" class="form-control form-control-sm noscroll"
                                placeholder="Código" formControlName="id">
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-8 col-lg-8 col-xl-3">
                        <div class="form-group">
                            <label for="categoria_nome" class="col-form-label-sm">Nome</label>
                            <input type="text" id="categoria_nome" name="categoria_nome" class="form-control form-control-sm"
                                placeholder="nome" autocomplete="off" formControlName="nome"
                                [ngClass]="{'is-invalid': displayMessage?.nome}" />
                            <span class="text-danger" *ngIf="displayMessage?.nome">
                                <p [innerHTML]="displayMessage?.nome"></p>
                            </span>

                        </div>
                    </div>

                    <div class="col-sm-12 col-md-8 col-lg-8 col-xl-3">
                        <div class="form-group">
                            <label for="categoria_descricao" class="col-form-label-sm">Descrição</label>
                            <input type="text" id="categoria_descricao" name="categoria_descricao" class="form-control form-control-sm"
                                placeholder="Descrição" autocomplete="off" formControlName="descricao"
                                [ngClass]="{'is-invalid': displayMessage?.descricao}" />
                            <span class="text-danger" *ngIf="displayMessage?.descricao">
                                <p [innerHTML]="displayMessage?.descricao"></p>
                            </span>

                        </div>
                    </div>
                    <div class="col-sm-12 col-md-8 col-lg-8 col-xl-3">
                        <div class="form-group">
                            <label for="categoria_palavra_chave" class="col-form-label-sm">Palavra Chave</label>
                            <input type="text" id="categoria_palavra_chave" name="categoria_palavra_chave"
                                class="form-control form-control-sm" placeholder="Palavra Chave" autocomplete="off"
                                formControlName="palavra_chave"
                                [ngClass]="{'is-invalid': displayMessage?.palavra_chave}" />
                            <span class="text-danger" *ngIf="displayMessage?.palavra_chave">
                                <p [innerHTML]="displayMessage?.palavra_chave"></p>
                            </span>

                        </div>
                    </div>

                    <div class="col-sm-4 col-md-4 col-lg-2 col-xl-1" *nfIf="alterar">
                        <div class="form-group">
                            <div class="custom-control custom-checkbox">
                                <input type="checkbox" id="produto_categoria_visivel" name="produto_categoria_visivel"
                                    class="custom-control-input form-control-sm" formControlName="chk_visivel" />
                                <label class="custom-control-label col-form-label-sm status-filtro"
                                    for="produto_categoria_visivel">Visivel</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> 

    </form>
</p-dialog>