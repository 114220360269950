import { PaginacaoViewModel } from 'src/modules/manager/view-models/PaginacaoViewModel';
import { UsuarioEmpresaViewModel } from '../cadastro/UsuarioEmpresaViewModel';

export class UsuarioEmpresaPesquisaViewModel extends UsuarioEmpresaViewModel implements PaginacaoViewModel {
    pagina: number = 1;
    quantidadeRegistrosPagina: number = 20;
    totalRegistros: number = 0;
    totalPaginas: number = 0;
    desativados: boolean = false;
    start?: number;
    order?: string;

}