<p-dialog [(visible)]="abrirFecharModalNf" [modal]="true" [style]="{width: '90vw'}" [maximizable]="true"
    [baseZIndex]="10000" [draggable]="false" [resizable]="false" [closable]="false">
    <p-header>
        Pesquisar Nota Fiscal
    </p-header>
    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-right btns-group mb-3">
        <button class="btn btn-sm btn-outline-search" type="button" (click)="pesquisar()"><i
                class="material-icons">search</i>Pesquisar</button>
    </div>
    <div class="card rounded-0 mb-1 border-left-0 border-right-0">
        <div class="card-body pt-1 pb-1 bg-white">
            <div class="row">
                <div class="col-sm-6 col-md-3 col-lg-2 col-xl-1">
                    <div class="form-group">
                        <label for="codigo_nf" class="col-form-label-sm">Num. NF</label>
                        <input type="number" id="codigo_nf" name="codigo_nf"
                            class="form-control form-control-sm noscroll" placeholder="NF" [(ngModel)]="pesquisa.id">
                    </div>
                </div>
                <div class="col-sm-6 col-md-3 col-lg-2 col-xl-1">
                    <div class="form-group">
                        <label for="fornecedor_id" class="col-form-label-sm">Código</label>
                        <input type="number" id="fornecedor_id" name="fornecedor_id"
                            class="form-control form-control-sm noscroll" placeholder="Código"
                            [(ngModel)]="pesquisa.cliente_id">
                    </div>
                </div>
                <div class="col-sm-12 col-md-9 col-lg-6 col-xl-3">
                    <div class="form-group">
                        <label for="cliente_fornecedor" class="col-form-label-sm">Nome Cliente</label>
                        <input id="cliente_fornecedor" name="cliente_fornecedor" class="form-control form-control-sm"
                            placeholder="Pesquise o Cliente pelo Nome" autocomplete="off"
                            [(ngModel)]="pesquisa.cliente_nome">
                    </div>
                </div>
                <div class="col-sm-12 col-md-8 col-lg-6 col-xl-3">
                    <div class="form-group">
                        <label for="dt_contabil" class="col-form-label-sm">Período</label>
                        <div class="input-group">
                            <input type="date" id="dt_contabil" name="dt_contabil" class="form-control form-control-sm"
                                [(ngModel)]="pesquisa.dt_contabil_inicial">
                            <span class="ml-1 mr-1 mt-1 text">a</span>
                            <input type="date" id="data_final" name="data_final" class="form-control form-control-sm"
                                [(ngModel)]="pesquisa.dt_contabil_final">
                            <div class="input-group-append">
                                <button class="btn btn-sm btn-maxdata" type="button" id="remover_data_inicial"
                                    [disabled]="!pesquisa.dt_contabil_inicial && !pesquisa.dt_contabil_final"
                                    ><i class="material-icons mr-0">remove</i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <div class="my-custom-scrollbar table-wrapper-scroll-y">
                        <table class="table table-sm table-borderless table-striped table-hover">
                            <thead class="thead">
                                <th>Nº NF-e</th>
                                <th class="text-nowrap">N. Interno</th>
                                <th>Cliente</th>
                                <th class="text-nowrap">Chave</th>
                                <th class="text-nowrap">Período</th>
                                <th class="text-nowrap">Valor NF</th>
                                <th class="w-20 text-center"></th>
                            </thead>
                            <tbody>
                                <tr class="cursor" *ngFor="let item of listaNf" >
                                    <td class="text-nowrap pr-5">{{item.numero }}</td>
                                    <td class="text-nowrap pr-5">{{item.id}}</td>
                                    <td class="text-nowrap pr-2 w-descricao text-truncate">{{item.cliente_id }} - {{item.cliente_nome }}</td>
                                    <td class="text-nowrap pr-5">{{item.chave_nf}}</td>
                                    <td class="text-nowrap pr-5">{{item.dt_emissao |date: 'dd/MM/yyyy'}}</td>
                                    <td class="text-nowrap pr-5">{{item.total_c_desc | currency: 'BRL' : ''}}</td>
                                    <td class="text-center" >                                      
                                        <p-checkbox binary="true" (onChange)="nfSelecionados($event, item)"></p-checkbox>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <button class="btn btn-sm btn-outline-danger"  type="button" (click)="closeModalNf()"
            >Cancelar</button>
        <button class="btn btn-sm btn-outline-primary" type="button" (click)="selecionar()">
            <span>Selecionar</span>
        </button>
    </ng-template>
</p-dialog>