import { Injectable, ɵɵresolveBody } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { URLBASE, URLIMPRESSAO } from 'src/modules/configuration.service';
import { Observable } from 'rxjs';
import { ListaPaginadaViewModel } from 'src/modules/manager/view-models/ListaPaginadaViewModel';
import { RetornoApiViewModel } from 'src/modules/manager/view-models/RetornoApiViewModel';
import { Uteis } from 'src/modules/utilizarios/uteis';

import { PesquisaGeralViewModel } from '@modules/admin/view-models/pesquisa/PesquisaGeralViewModel';
import { AutenticacaoService } from 'src/modules/autenticacao/services/autenticacao.service';
import { ClienteEnderecoPesquisaViewModel } from 'src/modules/manager/view-models/cadastro/cliente-fornecedor/pesquisa/cliente-endereco-pesquisa-view-model';
import { ClienteEnderecoViewModel } from 'src/modules/manager/view-models/cadastro/cliente-fornecedor/cliente-endereco-view-model';
import { CadastroViewModel } from 'src/modules/admin/view-models/cadastro/CadastroViewModel';
import { EnderecoPesquisaViewModel } from 'src/modules/manager/view-models/cadastro/cliente-fornecedor/pesquisa/endereco-pesquisa-view-model';
import { ClientePesquisaViewModel } from '@modules/manager/view-models/cadastro/cliente-fornecedor/pesquisa/cliente-pesquisa-view-model';

@Injectable({
    providedIn: 'root'
})
export class ClienteEnderecoService {

    idEmpresaMaxdata = this.autenticacaoService.acessoUsuarioValue?.idEmpresaMaxdata;

    urlGet = `${URLBASE}/${this.idEmpresaMaxdata}/cliente_endereco_service/get`;
    urlAddUpdate = `${URLBASE}/${this.idEmpresaMaxdata}/cliente_endereco_service/addupdate`;
    urlImpressaoRel = `${URLIMPRESSAO}/api/relatorio`;
    
    constructor(private http: HttpClient,
        private autenticacaoService: AutenticacaoService) { }

    obterTodos(pesquisa?: ClienteEnderecoPesquisaViewModel): Observable<RetornoApiViewModel<ClienteEnderecoPesquisaViewModel[]>> {
        let pesquisaGeral:  PesquisaGeralViewModel = new PesquisaGeralViewModel(); 

        pesquisaGeral.select = `cliente_endereco.*,
                        endereco.descricao                      as endereco_descricao,
                        endereco.ativo                          as endereco_ativo,
                        endereco.endereco                       as endereco_endereco,
                        endereco.endereco_numero, 
                        endereco.endereco_bairro,
                        endereco.endereco_cep,
                        endereco_municipio.codigo_ibge          as endereco_municipio_codigo_ibge,
                        endereco_municipio.descricao            as endereco_municipio_descricao,
                        endereco_municipio.ativo                as endereco_municipio_ativo,
                        endereco_municipio.codigo_pais          as endereco_municipio_codigo_pais,
                        endereco_municipio.descricao_pais       as endereco_municipio_descricao_pais,
                        endereco_uf.codigo                      as endereco_uf_codigo,
                        endereco_uf.sigla                       as endereco_uf_sigla,
                        endereco_uf.descricao                   as endereco_uf_descricao,
                        endereco_uf.ativo                       as endereco_uf_ativo
                        `;

        pesquisaGeral.where = ` and cliente_endereco.cliente_id = ${pesquisa?.cliente_id || 0} and endereco.ativo = ${pesquisa?.desativados ? 0 : 1 }`;
      

        pesquisaGeral.calcRowCount = 1;

        pesquisaGeral.start = pesquisa?.start || 0;

        pesquisaGeral.limit = pesquisa?.quantidadeRegistrosPagina || 0;

        pesquisaGeral.order = `${pesquisa?.order ? pesquisa?.order : 'cliente.id'}`;
        
        if(pesquisa.id) pesquisaGeral.where += ` and cliente_endereco.id = ${pesquisa?.id || 0}`;
        if(pesquisa.principal) pesquisaGeral.where += ` and cliente_endereco.principal = ${pesquisa?.principal || 0}`;

        return this.http.post<RetornoApiViewModel<ClienteEnderecoPesquisaViewModel[]>>(this.urlGet, pesquisaGeral)
    }

    obterPorId(id: number, cliente_id: number): Observable<RetornoApiViewModel<ClienteEnderecoViewModel[]>> {

        let pesquisaGeral:  PesquisaGeralViewModel = new PesquisaGeralViewModel(); 

        pesquisaGeral.select = `cliente_endereco.*,
                            endereco.descricao                      as endereco_descricao,
                            endereco.ativo                          as endereco_ativo,
                            endereco.endereco                       as endereco_endereco,
                            endereco.endereco_numero, 
                            endereco.endereco_bairro,
                            endereco.endereco_cep,
                            endereco_municipio.codigo_ibge          as endereco_municipio_codigo_ibge,
                            endereco_municipio.descricao            as endereco_municipio_descricao,
                            endereco_municipio.ativo                as endereco_municipio_ativo,
                            endereco_municipio.codigo_pais          as endereco_municipio_codigo_pais,
                            endereco_municipio.descricao_pais       as endereco_municipio_descricao_pais,
                            endereco_uf.codigo                      as endereco_uf_codigo,
                            endereco_uf.sigla                       as endereco_uf_sigla,
                            endereco_uf.descricao                   as endereco_uf_descricao,
                            endereco_uf.ativo                       as endereco_uf_ativo
                            `;

        pesquisaGeral.where = ` and cliente_endereco.id = ${id}
                            and cliente_endereco.cliente_id = ${cliente_id || 0}`;

        return this.http.post<RetornoApiViewModel<ClienteEnderecoViewModel[]>>(`${this.urlGet}`, pesquisaGeral);
    }

    adicionar(clienteEnderecoViewModel: CadastroViewModel<ClienteEnderecoViewModel>): Observable<RetornoApiViewModel<ClienteEnderecoViewModel>> {
        return this.http.post<RetornoApiViewModel<ClienteEnderecoViewModel>>(this.urlAddUpdate, clienteEnderecoViewModel);
    }

    atualizar(id: number, clienteEnderecoViewModel: CadastroViewModel<ClienteEnderecoViewModel>): Observable<RetornoApiViewModel<ClienteEnderecoViewModel>> {
        return this.http.put<RetornoApiViewModel<ClienteEnderecoViewModel>>(`${this.urlAddUpdate}/${id}`, clienteEnderecoViewModel);
    }
}
