import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CadastroViewModel } from '@modules/admin/view-models/cadastro/CadastroViewModel';
import { PesquisaGeralViewModel } from '@modules/admin/view-models/pesquisa/PesquisaGeralViewModel';
import { AutenticacaoService } from '@modules/autenticacao/services/autenticacao.service';
import { URLBASE, URLIMPRESSAO } from '@modules/configuration.service';
import { ConfiguracaoService } from '@modules/manager/sistema/configuracao/configuracao.service';
import { MedicoViewModel } from '@modules/manager/view-models/cadastro/clinica/medico-view-model';
import { MedicoPesquisaViewModel } from '@modules/manager/view-models/cadastro/clinica/pesquisa/medico-pesquisa-view-model';
import { RetornoApiViewModel } from '@modules/manager/view-models/RetornoApiViewModel';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class ClinicaMedicoService {

  idEmpresaMaxdata = this.autenticacao$.acessoUsuarioValue?.idEmpresaMaxdata;

  urlGet = `${URLBASE}/${this.idEmpresaMaxdata}/clinica_medico_service/get`;
  urlAddUpdate = `${URLBASE}/${this.idEmpresaMaxdata}/clinica_medico_service/addupdate`;
  urlImpressaoRel = `${URLIMPRESSAO}/api/relatorio`;
 
  constructor(private http: HttpClient,
      private autenticacao$: AutenticacaoService,
      private configuracao$: ConfiguracaoService) { }
      obterTodos(pesquisa?: MedicoPesquisaViewModel, imprimir?: boolean): Observable<RetornoApiViewModel<MedicoPesquisaViewModel[]>> {
      
        let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel();

        pesquisaGeral.select = `clinica_medico.id, 
                                clinica_medico.clinica_conselho_id, 
                                clinica_medico.nome, 
                                clinica_medico.registro, 
                                clinica_medico.ativo, 
                                clinica_medico.uf,
                                (select descricao from clinica_conselho where clinica_conselho.id= clinica_medico.clinica_conselho_id) as descricao
                                `
                                
        pesquisaGeral.calcRowCount = 1;    

        pesquisaGeral.where = `and clinica_medico.ativo = ${pesquisa?.desativados ? 0 : 1}`;

        pesquisaGeral.start = imprimir ? 0 : pesquisa?.start || 0;

        pesquisaGeral.limit = imprimir ? 0 : pesquisa?.quantidadeRegistrosPagina || 100;

        pesquisaGeral.order = `${pesquisa?.order ? pesquisa?.order : 'clinica_medico.id'}`;
    
        if (pesquisa?.id) pesquisaGeral.where += ` and clinica_medico.id = ${pesquisa?.id}`;

        if (pesquisa?.clinica_conselho_id) pesquisaGeral.where += ` and clinica_medico.clinica_conselho_id = ${pesquisa?.clinica_conselho_id}`;

        if (pesquisa?.registro) pesquisaGeral.where += ` and clinica_medico.registro = '${pesquisa?.registro}'`;
    
        if (pesquisa?.nome) pesquisaGeral.where += ` and lower(clinica_medico.nome) like lower('%${pesquisa?.nome}%')`; 
        
        if (pesquisa?.uf) pesquisaGeral.where += ` and clinica_medico.uf = '{pesquisa?.uf}'`;  
      
        return this.http.post<RetornoApiViewModel<MedicoPesquisaViewModel[]>>(this.urlGet, pesquisaGeral)
    }

    adicionar(medicoViewModel: CadastroViewModel<MedicoViewModel>): Observable<RetornoApiViewModel<MedicoViewModel>> {
      return this.http.post<RetornoApiViewModel<MedicoViewModel>>(this.urlAddUpdate, medicoViewModel);
  }

  atualizar(id: number, medicoViewModel: CadastroViewModel<MedicoViewModel>): Observable<RetornoApiViewModel<MedicoViewModel>> {
      return this.http.put<RetornoApiViewModel<MedicoViewModel>>(`${this.urlAddUpdate}/${id}`, medicoViewModel);
  }

  obterPorId(id: number): Observable<RetornoApiViewModel<MedicoViewModel[]>> {

    let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel()

    pesquisaGeral.select = 'clinica_medico.id, clinica_medico.clinica_conselho_id, clinica_medico.nome,  clinica_medico.registro,  clinica_medico.ativo,  clinica_medico.uf';

    pesquisaGeral.where = `and clinica_medico.id = ${id}`;

    return this.http.post<RetornoApiViewModel<MedicoViewModel[]>>(`${this.urlGet}`, pesquisaGeral);
}

}
