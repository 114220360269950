import { Component, ViewChild } from '@angular/core';
import { Acesso } from '../modules/autenticacao/dados/acesso';
import { AutenticacaoService } from '../modules/autenticacao/services/autenticacao.service';

// Idle Timeout
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';

import { BsModalService, BsModalRef, ModalDirective } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { AppService } from './app.service';

import { environment} from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {

  title = 'Max-Web';

  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date;
  acessoUsuario?: Acesso;

  public modalRef: BsModalRef = new BsModalRef;

  @ViewChild('childModal') childModal?: ModalDirective;

  constructor(private autenticacaoService: AutenticacaoService,
              private idle: Idle,
              private keepalive: Keepalive,
              private router: Router, 
              private modalService: BsModalService,
              private appService: AppService,
              ){
  
    // sets an idle timeout of 5 seconds, for testing purposes.
    idle.setIdle(900);
    // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    idle.setTimeout(30);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    // É chamado quando a movimento no teclado ou no mouse na página.
    idle.onIdleEnd.subscribe(() => { 
      this.idleState = 'Não está mais inativo.'
      this.reset();
    });
    
    // Quando o tempo restante de inativiadade acabar ele chama a função logout().
    idle.onTimeout.subscribe(() => {
      this.idleState = 'Você foi desconectadx por tempo de inatividade';
      this.timedOut = true;      
      this.logout();
    });
    
    // Chama o modal quando o tempo regular de inatinvidade acabar.
    idle.onIdleStart.subscribe(() => {
        this.idleState = 'Você ficou muito tempo inativo!'
        this.childModal?.show();
    });
    
    // Contagem regressiva para o logout do usuá
    idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'Você será desconectadx em ' + countdown + ' segundos!'
    });

    // sets the ping interval to 15 seconds
    keepalive.interval(15);

    keepalive.onPing.subscribe(() => this.lastPing = new Date());

    this.autenticacaoService.acessoUsuario.subscribe(acesso => {
      if(acesso){
        this.acessoUsuario = acesso
        this.timedOut = false;      
        idle.watch(); 
      } else {
        idle.stop();
      }
    });
    
  }

  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }

  hideChildModal(): void {
    this.childModal?.hide();
  }

  stay() {
    this.childModal?.hide();
    this.reset();
  }

  logout() {
    this.childModal?.hide();
    this.appService.setUserLoggedIn(false);
    this.autenticacaoService.logout();
    
  }
}