<p-dialog [(visible)]="abrirFecharPesquisaVeiculoModal" [modal]="true" [closable]="false" [style]="{width: '100vw'}"
    [maximizable]="true" [baseZIndex]="10000" [draggable]="false" [resizable]="false">
    <ngx-spinner name="modalAtualiza" bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-clip-rotate"
        [fullScreen]="false"></ngx-spinner>
    <p-header>Pesquisa de Veículos</p-header>
    <div class="row float-right">
        <button class="btn btn-sm btn-outline-info" type="button" (click)="pesquisar()"><i
                class="material-icons">search</i>Pesquisar</button>
    </div>
    <div class="row pl-0">
        <div class="col-xs-12 col-sm-4 col-md-3 col-lg-2 col-xl-1">
            <div class="form-group">
                <label for="id" class="col-form-label-sm">Código</label>
                <input type="number" id="id" name="id" class="form-control form-control-sm noscroll"
                placeholder="Código" [(ngModel)]="pesquisa.id" (keydown.enter)="pesquisar()">
            </div>
        </div>
        
        <div class="col-xs-12 col-sm-4 col-md-3 col-lg-2 col-xl-1">
            <div class="form-group">
                <label for="placaModal" class="col-form-label-sm">Placa</label>
                <input type="text" id="placaModal" name="placaModal" class="form-control form-control-sm noscroll"
                placeholder="Placa" [(ngModel)]="pesquisa.placa" (keydown.enter)="pesquisar()">
            </div>
        </div>

        <div class="col-xs-12 col-sm-8 col-md-6 col-lg-5 col-xl-4">
            <div class="form-group">
                <label for="cliente_nome" class="col-form-label-sm">Nome Cliente</label>
                <input type="text" id="cliente_nome" name="cliente_nome" class="form-control form-control-sm"
                    placeholder="Pesquise pelo Cliente" [(ngModel)]="pesquisa.cliente_nome" (keydown.enter)="pesquisar()" autofocus >
            </div>
        </div>

    </div>
    <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div class="my-custom-scrollbar table-wrapper-scroll-y" >
                <table class="table table-hover table-borderless table-striped table-sm " width="100%" >
                    <thead class="thead">
                        <tr>
                            <th>Código</th>
                            <th>Placa</th>
                            <th>RNTRC</th>
                            <th>Marca</th>
                            <th>Modelo</th>
                            <th>Motorista</th>                            
                            <th>Cor</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="cursor" *ngFor="let item of listaPaginada.itens" (click)="veiculoSlecionado(item)">
                            <td>{{item.id}}</td>
                            <td>{{item.placa}}</td>
                            <td>{{item.rntrc}}</td>
                            <td>{{item.marca}}</td>
                            <td>{{item.modelo}}</td>
                            <td>{{item.cliente_nome}}</td>
                            <td>{{item.cor}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <app-paginacao 
    [qdtPaginas]="pesquisa.quantidadeRegistrosPagina" 
    [itensgrid]="listaPaginada.itens.length"  
    [pagina]="pesquisa.pagina"             
    [itemsPerPage]="listaPaginada.paginacao.quantidadeRegistrosPagina"
    [totalItems]="listaPaginada.paginacao.totalRegistros" 
    (onPaginate)="mudarPagina($event)">
  </app-paginacao>

    <ng-template pTemplate="footer">
        <button class="btn btn-sm btn-outline-secondary" type="button" (click)="closeModalVeiculo()">Cancelar</button>
    </ng-template>
</p-dialog>
