import { PaginacaoViewModel } from '../../../PaginacaoViewModel';
import { ClienteViewModel } from './../cliente-view-model';

export class ClientePesquisaViewModel extends ClienteViewModel implements PaginacaoViewModel {
    pagina: number = 1;
    quantidadeRegistrosPagina: number = 20;
    totalRegistros: number = 0;
    totalPaginas: number = 0;
    desativados: boolean = false;
    start?: number = 0;
    order?: string;
    tipo_regime_apuracao?: number;
    cliente_grupo_id?: number;
    cpf_cnpj?: string;
    rg_ie?: string;
    fone?: string;
    dt_cadastro?: string;
    dt_cadastro_inicio?: string;
    dt_cadastro_fim?: string;
    emp_logo?: string;
    endereco_bairro?: string;
    endereco_numero?: string;
    endereco?: string;
    cidade_descricao?: string;
    uf_sigla?: string;

    agrupar_cidade: boolean = false;
    cliente_id_diferente?: number;

    
   
}