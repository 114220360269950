import { PaginacaoViewModel } from '../../../PaginacaoViewModel';
import { ProdutoFabricanteViewModel } from '../produto-fabricante-view-model';

export class ProdutoFabricantePesquisaViewModel extends ProdutoFabricanteViewModel implements PaginacaoViewModel {
    pagina: number = 1;
    quantidadeRegistrosPagina: number = 20;
    totalRegistros: number = 0;
    totalPaginas: number = 0;
    desativados: boolean = false;
    start?: number = 0;
    order?: string;
}
