import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { URLBASEADMIN } from '@modules/admin/configuration.admin.service';
import { CadastroViewModel } from '@modules/admin/view-models/cadastro/CadastroViewModel';
import { PesquisaGeralViewModel } from '@modules/admin/view-models/pesquisa/PesquisaGeralViewModel';
import { AutenticacaoService } from '@modules/autenticacao/services/autenticacao.service';
import { URLBASE } from '@modules/configuration.service';
import { UsuarioManagerPermissaoPesquisaViewModel } from '@modules/manager/view-models/cadastro/usuario-manager/pesquisa/usuario-manager-permissao-pesquisa-view-model';
import { UsuarioManagerPermissaoViewModel } from '@modules/manager/view-models/cadastro/usuario-manager/usuario-manager-permissao-view-model';
import { RetornoApiViewModel } from '@modules/manager/view-models/RetornoApiViewModel';
import { Observable } from 'rxjs';
import { CadastroArrayViewModel } from '@modules/admin/view-models/cadastro/CadastroArrayViewModel';

@Injectable({
  providedIn: 'root'
})
export class UsuarioManagerPermissaoService {
  idEmpresaMaxdata = this.autenticacao$.acessoUsuarioValue?.idEmpresaMaxdata;
  urlGet = `${URLBASE}/${this.idEmpresaMaxdata}/usuario_permissao_service/get`;
  urlAddUpdate = `${URLBASE}/${this.idEmpresaMaxdata}/usuario_permissao_service/addupdate`;
  urlAddUpdateArray = `${URLBASE}/${this.idEmpresaMaxdata}/usuario_permissao_service/AddUpdateArray`;

  constructor(private http: HttpClient, private autenticacao$: AutenticacaoService){}

  obterTodos(pesquisa: UsuarioManagerPermissaoPesquisaViewModel): Observable<RetornoApiViewModel<UsuarioManagerPermissaoPesquisaViewModel[]>> {

    let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel()
    
    pesquisaGeral.select = `usuario_permissao.id,
                        usuario_permissao.id_usuario_adm,
                        usuario_permissao.chk_select,
                        usuario_permissao.chk_insert,
                        usuario_permissao.chk_update,
                        usuario_permissao.chk_delete,
                        usuario_permissao.chk_run,
                        usuario_permissao.id_menu,
                        usuario_permissao.emp_id`;

    pesquisaGeral.where = ` and usuario_permissao.id_usuario_adm = ${pesquisa?.id_usuario_adm}`;

    pesquisaGeral.start = pesquisa?.start || 0;

    pesquisaGeral.limit = pesquisa?.quantidadeRegistrosPagina || 100;

    pesquisaGeral.order = pesquisa?.order ? pesquisa?.order : 'usuario_permissao.id'
                        
    return this.http.post<RetornoApiViewModel<UsuarioManagerPermissaoPesquisaViewModel[]>>(`${this.urlGet}`, pesquisaGeral);
  }

  obterPorId(id: number): Observable<RetornoApiViewModel<UsuarioManagerPermissaoViewModel[]>>{

    let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel()
    
    pesquisaGeral.select = `usuario_permissao.id,
                        usuario_permissao.id_usuario_adm,
                        usuario_permissao.chk_select,
                        usuario_permissao.chk_insert,
                        usuario_permissao.chk_update,
                        usuario_permissao.chk_delete,
                        usuario_permissao.chk_run,
                        usuario_permissao.id_menu,
                        usuario_permissao.emp_id`

    pesquisaGeral.where = ` and usuario_permissao.id = ${id}`;

    return this.http.post<RetornoApiViewModel<UsuarioManagerPermissaoViewModel[]>>(this.urlGet,pesquisaGeral);
  }

  adicionar(objCadastro: CadastroViewModel<UsuarioManagerPermissaoViewModel>) : Observable<RetornoApiViewModel<UsuarioManagerPermissaoViewModel>>{
      return this.http.post<RetornoApiViewModel<UsuarioManagerPermissaoViewModel>>(`${this.urlAddUpdate}`, objCadastro);
  }

  atualizar(id: number, objCadastro: CadastroViewModel<UsuarioManagerPermissaoViewModel>) : Observable<RetornoApiViewModel<UsuarioManagerPermissaoViewModel>>{
      return this.http.put<RetornoApiViewModel<UsuarioManagerPermissaoViewModel>>(`${this.urlAddUpdate}/${id}`, objCadastro);
  }

  adicionarArray(objCadastro: CadastroArrayViewModel<UsuarioManagerPermissaoViewModel>) : Observable<RetornoApiViewModel<UsuarioManagerPermissaoViewModel>>{
    return this.http.post<RetornoApiViewModel<UsuarioManagerPermissaoViewModel>>(`${this.urlAddUpdateArray}`, objCadastro);
}
}
