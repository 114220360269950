import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { AlertMessage } from '@modules/alert.configuration.service';
import { AutenticacaoService } from '@modules/autenticacao/services/autenticacao.service';
import { EnderecoUfService } from '@modules/manager/cadastro/cliente-fornecedor/endereco-uf/endereco-uf.service';
import { ClinicaConselhoService } from '@modules/manager/cadastro/clinica/clinica-conselho/clinica-conselho.service';
import { ClinicaMedicoService } from '@modules/manager/cadastro/clinica/clinica-medico/clinica-medico.service';
import { ConfiguracaoService } from '@modules/manager/sistema/configuracao/configuracao.service';
import { EnderecoUfPesquisaViewModel } from '@modules/manager/view-models/cadastro/cliente-fornecedor/pesquisa/endereco-uf-pesquisa-view-model';
import { ConselhoPesquisaViewModel } from '@modules/manager/view-models/cadastro/clinica/pesquisa/conselho-pesquisa-view-model';
import { MedicoPesquisaViewModel } from '@modules/manager/view-models/cadastro/clinica/pesquisa/medico-pesquisa-view-model';
import { ListaPaginadaViewModel } from '@modules/manager/view-models/ListaPaginadaViewModel';
import { Uteis } from '@modules/utilizarios/uteis';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-medico-pesquisa-modal',
  templateUrl: './medico-pesquisa-modal.component.html',
  styles: []
})
export class MedicoPesquisaModalComponent implements OnInit {
  /**
     * @description
     * Modal de pesquisa de cliente
     * @param abrirFecharModalMedico Parâmetro que abre o modal. Se for igual a true o modal é inciado.
     * @param stringPesquisa String que é utilizada na pesquisa por nome.
     * @param tipoCadastro Tipo de cadastro para a pesquisa de cliente, deve ser passado em forma de lista.
     *                      0 = Somente Cliente;
                            1 = Somente Fornecedor;
                            2 = Cliente / Fornecedor;
                            3 = Transportadora;
                            4 = Funcionário;
                            5 = Usuário do Sistema;
                            6 = Grupo de Usuário do Sistema.
     * @param closeModal Emite um evento para que o modal seja fechado.
     * @param medico Emite um evento e retorno o cliente selecioando para o componente pai.
     */

  @Input() abrirFecharModalMedico: boolean = false;
  @Input() stringPesquisa: string = '';
  @Input() clinica_conselho_id: [] = [];
  @Input() registro: [] = [];
  @Output() closeModal = new EventEmitter();
  @Output() cliente: EventEmitter<any> = new EventEmitter();
  pesquisa: MedicoPesquisaViewModel = new MedicoPesquisaViewModel();
  listaMedicos: MedicoPesquisaViewModel[] = [];
  listaConselho: ConselhoPesquisaViewModel[] = [];
  selectedRowIndex: number = -1;
  listaEnderecoUf: EnderecoUfPesquisaViewModel[] = [];
  listaPaginada: ListaPaginadaViewModel<MedicoPesquisaViewModel> = new ListaPaginadaViewModel<MedicoPesquisaViewModel>();
  constructor(private medico$: ClinicaMedicoService,
    private alert: AlertMessage,
    private spinner: NgxSpinnerService,
    private configuracao$: ConfiguracaoService,
    private conselho$: ClinicaConselhoService,
    private autenticacao$: AutenticacaoService,
    private enderecoUf$: EnderecoUfService) { }

  ngOnInit() {

    setTimeout(() => {
      (document.querySelector(`[name="nome"]`) as HTMLElement).focus();
      this.pesquisa.nome = this.stringPesquisa;
      this.pesquisa.clinica_conselho_id = this.clinica_conselho_id.length > 0 ? this.clinica_conselho_id : undefined;
      this.pesquisa.registro = this.registro.length > 0 ? this.registro : undefined;
    }, 100);
  }

  @HostListener('window:keydown', ['$event']) keyEvent(event: KeyboardEvent) {

    if (event.key === 'ArrowUp') {
      if (this.selectedRowIndex > 0) this.selectedRowIndex -= 1;
      document.getElementById('table')
        .getElementsByTagName('tbody')[0]
        .getElementsByTagName('tr')[this.selectedRowIndex]
        .scrollIntoView({ block: "center" })
    }
    if (event.key === 'ArrowDown') {
      if (this.selectedRowIndex < this.listaMedicos.length - 1) this.selectedRowIndex += 1;
      document.getElementById('table')
        .getElementsByTagName('tbody')[0]
        .getElementsByTagName('tr')[this.selectedRowIndex]
        .scrollIntoView({ block: "center" })
    }
    if (event.key === 'Enter') {
      if (this.selectedRowIndex > -1) {
        this.cliente.emit(this.listaMedicos[this.selectedRowIndex]);
        this.selectedRowIndex = -1;
      }
    }
    if (event.key === 'Escape') {
      this.closeModalCliente();
    }

  }//
  clickRowIndex(index: number) {
    this.selectedRowIndex = index;
  }//

  pesquisar() {

    (document.querySelector('[name="id"]') as HTMLInputElement).blur();
    (document.querySelector('[name="clinica_conselho_id"]') as HTMLInputElement).blur();
    (document.querySelector('[name="nome"]') as HTMLInputElement).blur();
    (document.querySelector('[name="registro"]') as HTMLInputElement).blur();
    (document.querySelector('[name="uf"]') as HTMLInputElement).blur();

    this.spinner.show();
    this.medico$.obterTodos(this.pesquisa).subscribe(
      retorno => {
        this.listaMedicos = retorno.total > 0 ? retorno.result : [];
        this.selectedRowIndex = 0;
        this.spinner.hide();
      },
      error => {
        this.alert.Danger('Erro', Uteis.ObterErroApi(error));
        this.spinner.hide();
        this.selectedRowIndex = -1;
      }
    );
  }

  clienteSlecionado(item) {
    this.cliente.emit(item);
    this.selectedRowIndex = -1;
  }

  closeModalCliente() {
    this.closeModal.emit(false);
    this.selectedRowIndex = -1;
  }


  obterListaConselho() {

    this.conselho$.obterTodos().subscribe(

      retorno => this.listaConselho = retorno.total > 0 ? retorno.result : [],

      error => this.alert.Danger('Erro', Uteis.ObterErroApi(error))
    )
  }

  obterListaEnderecoUf() {

    this.enderecoUf$.obterTodos().subscribe(

      retorno => this.listaEnderecoUf = retorno.total > 0 ? retorno.result : [], 

      error => this.alert.Danger('Erro', Uteis.ObterErroApi(error))
    )
  }
  mudarPagina(event: any) {
    this.pesquisa.start = (event.page - 1) * this.pesquisa.quantidadeRegistrosPagina;
    this.pesquisar();
  }

  ordenarLista(campo: string) {
    this.pesquisa.order = campo;
    this.pesquisar();
  }
}

