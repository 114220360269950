import { Component, OnInit } from '@angular/core';
import { AlertMessage } from '@modules/alert.configuration.service';
import { Acesso } from '@modules/autenticacao/dados/acesso';
import { AutenticacaoService } from '@modules/autenticacao/services/autenticacao.service';
import { UsuarioManagerService } from '@modules/manager/cadastro/usuario-manager/usuario-manager.service';
import { ConfiguracaoService } from '@modules/manager/sistema/configuracao/configuracao.service';
import { UsuarioManagerPesquisaViewModel } from '@modules/manager/view-models/cadastro/usuario-manager/pesquisa/usuario-manager-pesquisa-view-model';
import { ListaPaginadaViewModel } from '@modules/manager/view-models/ListaPaginadaViewModel';
import { ConfiguracaoPesquisaViewModel } from '@modules/manager/view-models/sistema/pesquisa/configuracao-pesquisa-view-model';
import { Uteis } from '@modules/utilizarios/uteis';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  refreshToken: Acesso = new Acesso();
  usuarioLogado: string;
  acessoUsuario: Acesso = new Acesso();
  listaLoja: ListaPaginadaViewModel<ConfiguracaoPesquisaViewModel> =
    new ListaPaginadaViewModel<ConfiguracaoPesquisaViewModel>();

  pesquisa: ConfiguracaoPesquisaViewModel = new ConfiguracaoPesquisaViewModel();

  constructor(
    private usuarioManager$: UsuarioManagerService,
    private autenticacao$: AutenticacaoService,
    private configuracao$: ConfiguracaoService,
    private alert: AlertMessage,
    private app$: AppService,

  ) {
    this.autenticacao$.acessoUsuario.subscribe((acesso) => {
      this.acessoUsuario = acesso;
      this.pesquisarLoja();
    });
  };

  ngOnInit() {

    this.obterUsuarioLogado();
  };

  alterarLoja() {
    this.refreshToken.refreshToken = this.acessoUsuario.refresh_token;
    this.refreshToken.idEmpresaMaxdata = this.acessoUsuario.idEmpresaMaxdata;
    this.refreshToken.idLoja = this.pesquisa.id;
    delete this.refreshToken.expires_in;
    this.autenticacao$.refreshToken(this.refreshToken).subscribe(
      (retorno) => {
        if (typeof retorno.result[0].token !== "undefined") {
          this.autenticacao$.salvarAcesso(retorno.result[0]);
          this.app$.setUserLoggedIn(true);
          this.alert.Success("Loja", "Loja alterada com sucesso!");
          location.reload();
          //this.router.navigate[this.router.url];
        }
      },
      (err) => this.alert.Danger("Error", Uteis.ObterErroApi(err))
    );
  }

  pesquisarLoja() {
    this.configuracao$.obterTodos().subscribe(
      (retorno) => {
        this.listaLoja.itens = retorno.total > 0 ? retorno.result : [];
        this.pesquisa.id = this.acessoUsuario.idLoja;
      },
      (err) => (err) => this.alert.Danger("Error", Uteis.ObterErroApi(err))
    );
  }

  obterUsuarioLogado() {
    let usuario_manager: UsuarioManagerPesquisaViewModel = new UsuarioManagerPesquisaViewModel();
    usuario_manager.id_usuario_adm = this.acessoUsuario.idUsuario;
    this.usuarioManager$.obterTodos(usuario_manager).subscribe((retorno) => {

      this.usuarioLogado = retorno.total > 0 ? retorno.result[0].nome : "";
    });
  };
};
