import { Component, EventEmitter, HostListener, Input, OnInit, Output, ElementRef, ViewChild } from '@angular/core';
import { AlertMessage } from '@modules/alert.configuration.service';
import { AutenticacaoService } from '@modules/autenticacao/services/autenticacao.service';
import { ProdutoEanService } from '@modules/manager/cadastro/estoque/produto-ean/produto-ean.service';
import { ProdutoGrupoService } from '@modules/manager/cadastro/estoque/produto-grupo/produto-grupo.service';
import { ProdutoSubGrupoService } from '@modules/manager/cadastro/estoque/produto-sub-grupo/produto-sub-grupo.service';
import { ProdutoService } from '@modules/manager/cadastro/estoque/produto/produto.service';
import { ConfiguracaoService } from '@modules/manager/sistema/configuracao/configuracao.service';
import { ProdutoEanPesquisaViewModel } from '@modules/manager/view-models/cadastro/estoque/pesquisa/produto-ean-pesquisa-view-model';
import { ProdutoPesquisaViewModel } from '@modules/manager/view-models/cadastro/estoque/pesquisa/produto-pesquisa-view-model';
import { ProdutoGrupoPesquisaViewModel } from '@modules/manager/view-models/cadastro/estoque/pesquisa/ProdutoGrupoPesquisaViewModel';
import { ProdutoSubGrupoPesquisaViewModel } from '@modules/manager/view-models/cadastro/estoque/pesquisa/ProdutoSubGrupoPesquisaViewModel';
import { Uteis } from '@modules/utilizarios/uteis';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProdutoEquivalentePesquisaViewModel } from "@manager/view-models/cadastro/estoque/pesquisa/produto-equivalente-pesquisa-view-model";
import { ProdutoEquivalenteService } from "@manager/cadastro/estoque/produto-equivalente/produto-equivalente.service";
import { ListaPaginadaViewModel } from "@manager/view-models/ListaPaginadaViewModel";
import { ProdutoEquivalenteViewModel } from "@manager/view-models/cadastro/estoque/produto-equivalente-view-model";
import { ProdutoConsultaEstoqueFiliaisComponent } from '../produto-consulta-estoque-filiais/produto-consulta-estoque-filiais.component';


@Component({
    selector: 'app-produto-pesquisa-modal',
    templateUrl: './produto-pesquisa-modal.component.html',
    styles: []
})

export class ProdutoPesquisaModalComponent implements OnInit {

    @Input() abrirFecharPesquisaProdutoModal: boolean = false;
    @Input() pesquisa_global_com_like: boolean = false;
    @Input() stringPesquisa: string = '';
    @Input() tipo_sped: string | Array<string>;
    @Output() closeModal = new EventEmitter();
    @Output() produto: EventEmitter<any> = new EventEmitter();
    @Output() codigoProduto: EventEmitter<number> = new EventEmitter();

    pesquisa: ProdutoPesquisaViewModel = new ProdutoPesquisaViewModel();
    pesquisaProdutoGrupo: ProdutoGrupoPesquisaViewModel = new ProdutoGrupoPesquisaViewModel();
    pesquisaProdutoSubGrupo: ProdutoSubGrupoPesquisaViewModel = new ProdutoSubGrupoPesquisaViewModel();
    pesquisaProdutoEan: ProdutoEanPesquisaViewModel = new ProdutoEanPesquisaViewModel();
    listaProdutos: ProdutoPesquisaViewModel[] = [];
    listaGrupo: ProdutoGrupoPesquisaViewModel[] = [];
    listaSubGrupo: ProdutoSubGrupoPesquisaViewModel[] = [];
    pesquisaPorCodigo: 0 | 1 | 2 = 0;
    // 0 Código Interno
    // 1 Código Fabricante
    // 2 Código Barras
    pesquisaPorCodigoInput: string = "";
    @ViewChild(ProdutoConsultaEstoqueFiliaisComponent) consultaEstoqueFiliais!: ProdutoConsultaEstoqueFiliaisComponent;
    selectedRowIndex: number = -1;
    selectedRowIndexProdEqui: number = -1;

    chkProdutoEquivalente: boolean = false;
    pesquisaProdEquivalente: ProdutoEquivalentePesquisaViewModel = new ProdutoEquivalentePesquisaViewModel();
    listaProdutoEquivalente: ListaPaginadaViewModel<ProdutoEquivalenteViewModel> = new ListaPaginadaViewModel<ProdutoEquivalenteViewModel>();
    paginaAtual: number = 1;
    produto_estoque_empresas: number = 0;
    constructor(private produto$: ProdutoService,
        private produtoGrupo$: ProdutoGrupoService,
        private produtoSubGrupo$: ProdutoSubGrupoService,
        private alert: AlertMessage,
        private spinner: NgxSpinnerService,
        private configuracao$: ConfiguracaoService,
        private autenticacao$: AutenticacaoService,
        private produtoEan$: ProdutoEanService,
        private produtoEquivalente$: ProdutoEquivalenteService,) {
    }

    ngOnInit() {
        setTimeout(() => {
            (document.querySelector(`[name="modal_descricao"]`) as HTMLElement).focus();
            this.pesquisa.descricao = this.stringPesquisa;
        }, 100);
        this.obterListaGrupo();
     
    }


    selecionarProduto(produtoId: any) {    
        if (this.consultaEstoqueFiliais) {
            this.consultaEstoqueFiliais.selecionarProduto(produtoId);
        }
        }

      
      
    obterListaGrupo() {
        this.produtoGrupo$.obterTodos(this.pesquisaProdutoGrupo).subscribe(
            retorno => this.listaGrupo = retorno.total > 0 ? retorno.result : [],
            error => this.alert.Danger('Erro', Uteis.ObterErroApi(error))
        )
    }

    obterListaSubGrupo() {
        this.pesquisaProdutoSubGrupo = Object.assign({ produto_grupo_id: this.pesquisa?.produto_grupo_id || 0 })
        this.produtoSubGrupo$.obterTodos(this.pesquisaProdutoSubGrupo).subscribe(
            retorno => this.listaSubGrupo = retorno.total > 0 ? retorno.result : [],
            error => this.alert.Danger('Erro', Uteis.ObterErroApi(error))
        )
    }

    controlePesquisa() {
        if (this.pesquisaPorCodigo == 0) {
            this.pesquisa.id = parseInt(this.pesquisaPorCodigoInput);
            this.pesquisa.produto_empresa_codigo = undefined;
            this.pesquisa.produto_ean_codigo_ean = undefined;
            this.pesquisar();
        }
        if (this.pesquisaPorCodigo == 1) {
            this.pesquisa.produto_empresa_codigo = this.pesquisaPorCodigoInput;
            this.pesquisa.id = undefined;
            this.pesquisa.produto_ean_codigo_ean = undefined;
            this.pesquisar();
        }
        if (this.pesquisaPorCodigo == 2) {
            this.pesquisa.produto_ean_codigo_ean = this.pesquisaPorCodigoInput;
            this.pesquisa.id = undefined;
            this.pesquisa.produto_empresa_codigo = undefined;
            this.obterCodigoBarras();
        }
    }

    onKeydown(event: KeyboardEvent) {

        if (event.key === 'Enter') {
            this.controlePesquisa();
        }

        if (event.shiftKey && event.key === '+' || event.key === '+' || event.type === 'click') {
            event.preventDefault();
            this.pesquisaPorCodigo++;
            if (this.pesquisaPorCodigo == 3) this.pesquisaPorCodigo = 0;
        }

    }

    @HostListener('window:keydown', ['$event'])  handleKeyboardEvent(event: KeyboardEvent) {

    
     
        if (event.key === 'ArrowUp') {

            if (this.selectedRowIndex > 0) this.selectedRowIndex -= 1;
            document.getElementById('table').getElementsByTagName('tbody')[0].getElementsByTagName('tr')[this.selectedRowIndex].scrollIntoView({ block: "center" })

            if (this.selectedRowIndexProdEqui > 0) this.selectedRowIndexProdEqui -= 1;
            document.getElementById('table').getElementsByTagName('tbody')[0].getElementsByTagName('tr')[this.selectedRowIndexProdEqui].scrollIntoView({ block: "center" })
            this.selecionarProduto(this.listaProdutos[this.selectedRowIndex].id);
        } else if (event.key === 'ArrowDown') {

            if (this.selectedRowIndex < this.listaProdutos.length - 1) this.selectedRowIndex += 1;
            document.getElementById('table').getElementsByTagName('tbody')[0].getElementsByTagName('tr')[this.selectedRowIndex].scrollIntoView({ block: "center" })

            if (this.selectedRowIndexProdEqui < this.listaProdutos.length - 1) this.selectedRowIndexProdEqui += 1;
            document.getElementById('table').getElementsByTagName('tbody')[0].getElementsByTagName('tr')[this.selectedRowIndexProdEqui].scrollIntoView({ block: "center" })
            this.selecionarProduto(this.listaProdutos[this.selectedRowIndex].id);

        } else if (event.key === 'Enter') {

            if (this.selectedRowIndex > -1) {
                this.codigoProduto.emit(this.listaProdutos[this.selectedRowIndex].id)
                this.produto.emit( this.listaProdutos[this.selectedRowIndex])
                this.selectedRowIndex = -1;
            }

            if (this.selectedRowIndexProdEqui > -1) {
                this.selectedRowIndexProdEqui = -1;
            }

        } else if (event.key === 'Escape') {

            this.closeModalProduto();

        }

    }

    clickRowIndex(index: number) {
        this.selectedRowIndex = index;
    }

    clickRowIndexProdEqui(index: number) {
        this.selectedRowIndexProdEqui = index;
    }

    pesquisar() {

        this.pesquisa.quantidadeRegistrosPagina = 200;
         (document.querySelector('[name="modal_codigo"]') as HTMLInputElement).blur();
         (document.querySelector('[name="modal_descricao"]') as HTMLInputElement).blur();

        this.selectedRowIndex = -1;

        this.spinner.show();

        this.tipo_sped !== '' || Array.isArray(this.tipo_sped) && this.tipo_sped.length > 0 ? this.pesquisa.tipo_sped = this.tipo_sped : null;
        this.pesquisa.pesquisa_global_com_like = this.pesquisa_global_com_like;

        this.produto$.obterTodos(this.pesquisa).subscribe(
            retorno => {

                this.listaProdutos = retorno.total > 0 ? retorno.result : [];
                this.selectedRowIndex = 0;
                this.spinner.hide();
            },
            err => {
                this.selectedRowIndex = -1;
                this.spinner.hide();
                this.alert.Danger('Erro', Uteis.ObterErroApi(err));
            });
    }

    obterCodigoBarras() {
        this.pesquisaProdutoEan = Object.assign({ codigo_ean: this.pesquisa?.produto_ean_codigo_ean })

        this.produtoEan$.obterTodos(this.pesquisaProdutoEan).subscribe(
            retorno => {
                this.pesquisa = Object.assign({ id: retorno.total > 0 ? retorno.result[0].produto_id : 0 });
                this.pesquisar();
            }
        )
    }

    produtoSlecionado(item: ProdutoPesquisaViewModel) {
        this.produto.emit(item);
        this.codigoProduto.emit(item.id)
        
        this.selectedRowIndex = -1;
    }

    closeModalProduto() {
        this.closeModal.emit(false);
        this.selectedRowIndex = -1;
        if(!this.pesquisa_global_com_like){

            (document.querySelector(`[name="produto_descricao"]`) as HTMLElement).focus();
        }
    }

    get codigo() {
        return this.pesquisa.id
    }

    onCheckBoxChangeProdutoEquivalente(e) {
        e.target.checked ? this.chkProdutoEquivalente = true : this.chkProdutoEquivalente = false;
    }

    produtoEquivalenteSelecionado(item: ProdutoPesquisaViewModel) {
        this.produto.emit(item);
        this.codigoProduto.emit(item.id)
        this.selectedRowIndexProdEqui = -1;
    }



    produtoEquivalente(e) {

        this.produto_estoque_empresas = e;
       
        this.pesquisaProdEquivalente = Object.assign({ produto_id_origem: e || 0, quantidadeRegistrosPagina: 0 })

        return this.produtoEquivalente$.obterTodos(this.pesquisaProdEquivalente).subscribe(retorno => {

            this.listaProdutoEquivalente.itens = retorno.total > 0 ? retorno.result : [];

            this.listaProdutoEquivalente.paginacao.totalRegistros = retorno.total;

            this.listaProdutoEquivalente.paginacao.quantidadeRegistrosPagina = 999;
        },
            err => this.alert.Danger('Consulta produto equivalente', Uteis.ObterErroApi(err)));
    }


}
