import { Component, OnInit } from '@angular/core';
import { CadastroViewModel } from '@modules/admin/view-models/cadastro/CadastroViewModel';
import { SistemaMenuViewModel } from '@modules/admin/view-models/cadastro/SistemaMenuViewModel';
import { AlertMessage } from '@modules/alert.configuration.service';
import { ListaPaginadaViewModel } from '@modules/manager/view-models/ListaPaginadaViewModel';
import { QuantidadeItensPaginacao } from '@modules/utilizarios/quantidadeItensPaginacao';
import { TituloPagina } from '@modules/utilizarios/tituloPaginas/tituloPaginas';
import { Uteis } from '@modules/utilizarios/uteis';
import { NgxSpinnerService } from 'ngx-spinner';
import { SistemaMenuService } from './sistema-menu.service';

@Component({
  selector: 'app-sistema-menu',
  templateUrl: './sistema-menu.component.html',
  styles: []
})
export class SistemaMenuComponent implements OnInit {

  tituloPagina: TituloPagina = new TituloPagina("Menu do Sistema - Pesquisa", []);

  listaPaginada: ListaPaginadaViewModel<any> = new ListaPaginadaViewModel<any>();
  listaItensPaginacao: QuantidadeItensPaginacao[] = QuantidadeItensPaginacao.listaQuantidades;

  pesquisa: SistemaMenuViewModel = new SistemaMenuViewModel;
  menu: SistemaMenuViewModel = new SistemaMenuViewModel;

  objMenuItem: CadastroViewModel<SistemaMenuViewModel> = new CadastroViewModel<SistemaMenuViewModel>();

  modalMenu: boolean = false;

  alterar: boolean = false;

  constructor(
    private sistemaMenuService$: SistemaMenuService, 
    private alert: AlertMessage, 
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.pesquisa.lst_grupo_menu = 0;
    this.pesquisar();
  }

  pesquisar(){
    this.spinner.show();
    this.sistemaMenuService$.obterMenuCompleto(this.pesquisa).subscribe(
      retorno => {       
        this.listaPaginada.itens = retorno.total > 0 ? retorno.result : [];
        this.listaPaginada.paginacao.totalRegistros = retorno.total;
        this.spinner.hide();
      },
      error => {
        this.spinner.hide();
        if(error.status == 200) return this.alert.Info('Não encontrado', '');
        this.alert.Danger('Erro', Uteis.ObterErroApi(error))
      }
    );
  }  

  onSubmit(){
    if(this.alterar) this.atualizar();
    else this.inserir();
  }

  editarItem(item) {
    this.alterar = true;
    this.menu = this.listaPaginada.itens[item];
  }
  novoItem(){
    this.menu = new SistemaMenuViewModel;
    this.menu.id = 0;
    this.menu.lst_grupo_menu = 1;
  }

  inserir(){

    if(this.menu.descricao == undefined || this.menu.id_menu == undefined) return this.alert.Warning('Menu', 'Informar todos os campos');
    if(this.menu.descricao == '' || this.menu.id_menu == '') return this.alert.Warning('Menu', 'Informar todos os campos');

    this.spinner.show();
    this.objMenuItem.obj = Object.assign(this.menu);

    this.sistemaMenuService$.adicionar(this.objMenuItem).subscribe(
      retorno => {
        this.spinner.hide();
        this.alert.Success('Adicionado com sucesso', '');
        this.modalMenu = false;
        this.pesquisar();
      },
      error => {
        this.spinner.hide();
        this.alert.Danger('Erro', Uteis.ObterErroApi(error))
      }
    );  
    
  }

  atualizar(){

    if(this.menu.descricao == undefined || this.menu.id_menu == undefined) return this.alert.Warning('Menu', 'Informar todos os campos');
    if(this.menu.descricao == '' || this.menu.id_menu == '') return this.alert.Warning('Menu', 'Informar todos os campos');

    this.spinner.show();
    this.objMenuItem.obj = Object.assign(this.menu)

    this.sistemaMenuService$.atualizar(this.menu.id, this.objMenuItem).subscribe(
      retorno => {
        this.spinner.hide();
        this.alert.Success('Alterado com sucesso', '');
        this.modalMenu = false;
        this.pesquisar();
      },
      error => {
        this.spinner.hide();
        this.alert.Danger('Erro', Uteis.ObterErroApi(error));
      }
    );

  }

  remover(){

    this.objMenuItem.obj = Object.assign({}, this.menu);

    this.sistemaMenuService$.remover(this.menu.id, this.objMenuItem).subscribe(
      retorno => {
        this.alert.Success('Excluído com sucesso', '');
        this.modalMenu = false;
        this.pesquisar();
      },
      error => {
        this.alert.Danger('Erro', Uteis.ObterErroApi(error))
      }
    );

  }

}
