import { HistoricoNavegacao } from './historicoNavegacao';

export class TituloPagina{
    constructor(tituloPagina: string, historicoNavegacao: HistoricoNavegacao[]){
        this.tituloPagina = tituloPagina;
        this.historicoNavegacao = historicoNavegacao;
    }
    
    tituloPagina: string;
    historicoNavegacao: HistoricoNavegacao[] = [];
}