<!-- VENDA LÍQUIDA E TOTAL CRÉDITO E DESCONTO VENDA E QUANTIDADE VENDIDA E TICKET MÉDIO - XLARGE - INICIO -->
<div *ngIf="matgroup"  class="mat-group">
    <div class="center-content">
        <mat-button-toggle-group #group="matButtonToggleGroup">
            <mat-button-toggle value="dia" aria-label="dia" (click)="calcularVendas(group.value)" checked>
                DIA
            </mat-button-toggle>
            <mat-button-toggle value="semana" aria-label="semana" (click)="calcularVendas(group.value)">
                SEMANA
            </mat-button-toggle>
            <mat-button-toggle value="mes" aria-label="mes" (click)="calcularVendas(group.value)">
                MÊS
            </mat-button-toggle>
            <mat-button-toggle value="ano"  aria-label="ano" (click)="calcularVendas(group.value)">
                ANO
            </mat-button-toggle>
        </mat-button-toggle-group>

    </div>
</div>


<div class="row row-cols-12" *ngIf="(isXLarge)">
    <!-- VENDA LÍQUIDA - INICIO -->
    <div class="col">
        <div class="card inside-card rounded-0">
            <div class="card-body p-2 bg-white">
                <div class="d-inline-flex">
                    <i class="material-icons" style="font-size:27px;color:#2196f3;">local_atm</i>
                    <h5>&nbsp;{{ indicadoresRentabilidade.valor_total_liquido | currency:'BRL':'symbol':'1.2-2':'pt' }}
                    </h5>
                </div>
                <div class="card-title mb-0">Venda Líquida</div>
            </div>
        </div>
    </div>
    <!-- VENDA LÍQUIDA - FIM -->
    <!-- TOTAL CRÉDITO - INICIO -->
    <div class="col">
        <div class="card inside-card rounded-0">
            <div class="card-body p-2 bg-white">
                <div class="d-inline-flex">
                    <i class="material-icons" style="color:#4caf50">fact_check</i>
                    <h5>&nbsp;R$ 0,00</h5>
                </div>
                <div class="card-title mb-0">Total Crédito</div>
            </div>
        </div>
    </div>
    <!-- TOTAL CRÉDITO - FIM -->
    <!-- DESCONTO VENDA - INICIO -->
    <div class="col">
        <div class="card inside-card rounded-0">
            <div class="card-body p-2 bg-white">
                <div class="row">
                    <div class="col-12">
                        <div class="d-inline-flex">
                            <i class="material-icons text-maxdata">loyalty</i>
                            <h5>&nbsp;{{ indicadoresRentabilidade.total_desconto | currency:'BRL':'symbol':'1.2-2':'pt'
                                }}</h5>
                        </div>
                    </div>
                </div>
                <div class="card-title mb-0">
                    <div class="d-flex justify-content-between">
                        Desconto Venda
                        <h6 class="text-maxdata mb-0">
                            {{ (indicadoresRentabilidade.total_desconto === 0 ||
                            indicadoresRentabilidade.valor_total_liquido_mais_impostos === 0 ? 0
                            : indicadoresRentabilidade.total_desconto /
                            indicadoresRentabilidade.valor_total_liquido_mais_impostos) | percent:'1.2-2':'pt' }}
                        </h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- DESCONTO VENDA - FIM -->
    <!-- QUANTIDADE VENDIDA - INICIO -->
    <div class="col">
        <div class="card inside-card rounded-0">
            <div class="card-body p-2 bg-white">
                <div class="d-inline-flex">
                    <i class="material-icons" style="color:#ff9800;">shopping_basket</i>
                    <h5>&nbsp;{{ indicadoresRentabilidade.quantidade_vendas | number:'1.0-0':'pt' }}</h5>
                </div>
                <div class="card-title mb-0">Quantidade Venda</div>
            </div>
        </div>
    </div>
    <!-- QUANTIDADE VENDIDA - FIM -->
    <!-- TICKET MÉDIO - INICIO -->
    <div class="col">
        <div class="card inside-card rounded-0">
            <div class="card-body p-2 bg-white">
                <div class="d-inline-flex">
                    <i class="material-icons" style="color:#673ab7;">confirmation_number</i>
                    <h5>&nbsp;{{ indicadoresRentabilidade.valor_medio_venda | currency:'BRL':'symbol':'1.2-2':'pt' }}
                    </h5>
                </div>
                <div class="card-title mb-0">Ticket Médio</div>
            </div>
        </div>
    </div>
    <!-- TICKET MÉDIO - FIM -->
</div>
<!-- VENDA LÍQUIDA E TOTAL CRÉDITO E DESCONTO VENDA E QUANTIDADE VENDIDA E TICKET MÉDIO - XLARGE - FIM -->
<!-- VENDA LÍQUIDA E TOTAL CRÉDITO E DESCONTO VENDA E QUANTIDADE VENDIDA E TICKET MÉDIO - LARGE - INICIO -->
<div class="row row-cols-12" *ngIf="!(isXLarge) && (isLarge)">
    <!-- VENDA LÍQUIDA - INICIO -->
    <div class="col">
        <div class="card inside-card rounded-0">
            <div class="card-body p-2 bg-white">
                <div class="d-inline-flex">
                    <i class="material-icons" style="font-size:27px;color:#2196f3;">local_atm</i>
                    <h5>&nbsp;{{ indicadoresRentabilidade.valor_total_liquido | currency:'BRL':'symbol':'1.2-2':'pt' }}
                    </h5>
                </div>
                <div class="card-title mb-0">Venda Líquida</div>
            </div>
        </div>
    </div>
    <!-- VENDA LÍQUIDA - FIM -->
    <!-- TOTAL CRÉDITO - INICIO -->
    <div class="col">
        <div class="card inside-card rounded-0">
            <div class="card-body p-2 bg-white">
                <div class="d-inline-flex">
                    <i class="material-icons" style="color:#4caf50">fact_check</i>
                    <h5>&nbsp;R$ 0,00</h5>
                </div>
                <div class="card-title mb-0">Total Crédito</div>
            </div>
        </div>
    </div>
    <!-- TOTAL CRÉDITO - FIM -->
    <!-- DESCONTO VENDA - INICIO -->
    <div class="col">
        <div class="card inside-card rounded-0">
            <div class="card-body p-2 bg-white">
                <div class="row">
                    <div class="col-12">
                        <div class="d-inline-flex">
                            <i class="material-icons text-maxdata">loyalty</i>
                            <h5>&nbsp;{{ indicadoresRentabilidade.total_desconto | currency:'BRL':'symbol':'1.2-2':'pt'
                                }}</h5>
                        </div>
                    </div>
                </div>
                <div class="card-title mb-0">
                    <div class="d-flex justify-content-between">
                        Desconto Venda
                        <h6 class="text-maxdata mb-0">
                            {{ (indicadoresRentabilidade.total_desconto === 0 ||
                            indicadoresRentabilidade.valor_total_liquido_mais_impostos === 0 ? 0
                            : indicadoresRentabilidade.total_desconto /
                            indicadoresRentabilidade.valor_total_liquido_mais_impostos) | percent:'1.2-2':'pt' }}
                        </h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- DESCONTO VENDA - FIM -->
    <!-- QUANTIDADE VENDIDA - INICIO -->
    <div class="col">
        <div class="card inside-card rounded-0">
            <div class="card-body p-2 bg-white">
                <div class="d-inline-flex">
                    <i class="material-icons" style="color:#ff9800;">shopping_basket</i>
                    <h5>&nbsp;{{ indicadoresRentabilidade.quantidade_vendas | number:'1.0-0':'pt' }}</h5>
                </div>
                <div class="card-title mb-0">Quantidade Venda</div>
            </div>
        </div>
    </div>
    <!-- QUANTIDADE VENDIDA - FIM -->
    <!-- TICKET MÉDIO - INICIO -->
    <div class="col">
        <div class="card inside-card rounded-0">
            <div class="card-body p-2 bg-white">
                <div class="d-inline-flex">
                    <i class="material-icons" style="color:#673ab7;">confirmation_number</i>
                    <h5>&nbsp;{{ indicadoresRentabilidade.valor_medio_venda | currency:'BRL':'symbol':'1.2-2':'pt' }}
                    </h5>
                </div>
                <div class="card-title mb-0">Ticket Médio</div>
            </div>
        </div>
    </div>
    <!-- TICKET MÉDIO - FIM -->
</div>
<!-- VENDA LÍQUIDA E TOTAL CRÉDITO E DESCONTO VENDA E QUANTIDADE VENDIDA E TICKET MÉDIO - LARGE - FIM -->
<!-- VENDA LÍQUIDA E TOTAL CRÉDITO E DESCONTO VENDA E QUANTIDADE VENDIDA E TICKET MÉDIO - MEDIUM - INICIO -->
<div class="row row-cols-12" *ngIf="(isMedium)">
    <!-- VENDA LÍQUIDA - INICIO -->
    <div class="col">
        <div class="card inside-card rounded-0">
            <div class="card-body p-2 bg-white">
                <div class="d-inline-flex">
                    <i class="material-icons" style="color:#2196f3;">local_atm</i>
                    <h6 style="margin-top:1px;">&nbsp;{{ indicadoresRentabilidade.valor_total_liquido |
                        currency:'BRL':'symbol':'1.2-2':'pt' }}</h6>
                </div>
                <div class="card-title mb-0">Venda Líquida</div>
            </div>
        </div>
    </div>
    <!-- VENDA LÍQUIDA - FIM -->
    <!-- TOTAL CRÉDITO - INICIO -->
    <div class="col">
        <div class="card inside-card rounded-0">
            <div class="card-body p-2 bg-white">
                <div class="d-inline-flex">
                    <i class="material-icons" style="color:#4caf50">fact_check</i>
                    <h6 style="margin-top:1px;">&nbsp;R$ 0,00</h6>
                </div>
                <div class="card-title mb-0">Total Crédito</div>
            </div>
        </div>
    </div>
    <!-- TOTAL CRÉDITO - FIM -->
    <!-- DESCONTO VENDA - INICIO -->
    <div class="col">
        <div class="card inside-card rounded-0">
            <div class="card-body p-2 bg-white">
                <div class="row">
                    <div class="col-12">
                        <div class="d-inline-flex">
                            <i class="material-icons text-maxdata">loyalty</i>
                            <h6 style="margin-top:1px;">&nbsp;{{ indicadoresRentabilidade.total_desconto |
                                currency:'BRL':'symbol':'1.2-2':'pt' }}</h6>
                        </div>
                    </div>
                </div>
                <div class="card-title mb-0">
                    <div class="d-flex justify-content-between">
                        Desconto Venda
                        <h6 class="text-maxdata mb-0">
                            {{ (indicadoresRentabilidade.total_desconto === 0 ||
                            indicadoresRentabilidade.valor_total_liquido_mais_impostos === 0 ? 0
                            : indicadoresRentabilidade.total_desconto /
                            indicadoresRentabilidade.valor_total_liquido_mais_impostos) | percent:'1.2-2':'pt' }}
                        </h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- DESCONTO VENDA - FIM -->
    <!-- QUANTIDADE VENDIDA - INICIO -->
    <div class="col">
        <div class="card inside-card rounded-0">
            <div class="card-body p-2 bg-white">
                <div class="d-inline-flex">
                    <i class="material-icons" style="color:#ff9800;">shopping_basket</i>
                    <h6 style="margin-top:1px;">&nbsp;{{ indicadoresRentabilidade.quantidade_vendas |
                        number:'1.0-0':'pt' }}</h6>
                </div>
                <div class="card-title mb-0">Quantidade Venda</div>
            </div>
        </div>
    </div>
    <!-- QUANTIDADE VENDIDA - FIM -->
    <!-- TICKET MÉDIO - INICIO -->
    <div class="col">
        <div class="card inside-card rounded-0">
            <div class="card-body p-2 bg-white">
                <div class="d-inline-flex">
                    <i class="material-icons" style="color:#673ab7;">confirmation_number</i>
                    <h6 style="margin-top:1px;">&nbsp;{{ indicadoresRentabilidade.valor_medio_venda |
                        currency:'BRL':'symbol':'1.2-2':'pt' }}</h6>
                </div>
                <div class="card-title mb-0">Ticket Médio</div>
            </div>
        </div>
    </div>
    <!-- TICKET MÉDIO - FIM -->
</div>
<!-- VENDA LÍQUIDA E TOTAL CRÉDITO E DESCONTO VENDA E QUANTIDADE VENDIDA E TICKET MÉDIO - MEDIUM - FIM -->
<!-- VENDA LÍQUIDA E TOTAL CRÉDITO E DESCONTO VENDA E QUANTIDADE VENDIDA E TICKET MÉDIO - SMALL - INICIO -->
<div class="row" *ngIf="(isSmall)">
    <!-- VENDA LÍQUIDA - INICIO -->
    <div class="col-sm-4">
        <div class="card inside-card rounded-0">
            <div class="card-body p-2 bg-white">
                <div class="d-inline-flex">
                    <i class="material-icons font30" style="margin-top:1px;color:#2196f3;">local_atm</i>
                    <h5 class="mt-1">&nbsp;{{ indicadoresRentabilidade.valor_total_liquido |
                        currency:'BRL':'symbol':'1.2-2':'pt' }}</h5>
                </div>
                <div class="card-title mb-0">Venda Líquida</div>
            </div>
        </div>
    </div>
    <!-- VENDA LÍQUIDA - FIM -->
    <!-- TOTAL CRÉDITO - INICIO -->
    <div class="col-sm-4">
        <div class="card inside-card rounded-0">
            <div class="card-body p-2 bg-white">
                <div class="d-inline-flex">
                    <i class="material-icons font30" style="color:#4caf50">fact_check</i>
                    <h5 class="mt-1">&nbsp;R$ 0,00</h5>
                </div>
                <div class="card-title mb-0">Total Crédito</div>
            </div>
        </div>
    </div>
    <!-- TOTAL CRÉDITO - FIM -->
    <!-- DESCONTO VENDA - INICIO -->
    <div class="col-sm-4">
        <div class="card inside-card rounded-0">
            <div class="card-body p-2 bg-white">
                <div class="d-inline-flex">
                    <i class="material-icons font30 text-maxdata">loyalty</i>
                    <h5 class="mt-1">&nbsp;{{ indicadoresRentabilidade.total_desconto |
                        currency:'BRL':'symbol':'1.2-2':'pt' }}</h5>
                </div>
                <div class="card-title mb-0">
                    <div class="d-flex justify-content-between">
                        Desconto Venda
                        <h6 class="text-maxdata mb-0">
                            {{ (indicadoresRentabilidade.total_desconto === 0 ||
                            indicadoresRentabilidade.valor_total_liquido_mais_impostos === 0 ? 0
                            : indicadoresRentabilidade.total_desconto /
                            indicadoresRentabilidade.valor_total_liquido_mais_impostos) | percent:'1.2-2':'pt' }}
                        </h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- DESCONTO VENDA - FIM -->
    <!-- QUANTIDADE VENDIDA - INICIO -->
    <div class="col-sm-4">
        <div class="card inside-card rounded-0 mt-2">
            <div class="card-body p-2 bg-white">
                <div class="d-inline-flex">
                    <i class="material-icons font30" style="color:#ff9800;">shopping_basket</i>
                    <h5 class="mt-1">&nbsp;{{indicadoresRentabilidade.quantidade_vendas | number:'1.0-0':'pt' }}</h5>
                </div>
                <div class="card-title mb-0">Quantidade Venda</div>
            </div>
        </div>
    </div>
    <!-- QUANTIDADE VENDIDA - FIM -->
    <!-- TICKET MÉDIO - INICIO -->
    <div class="col-sm-8">
        <div class="card inside-card rounded-0 mt-2">
            <div class="card-body p-2 bg-white">
                <div class="d-inline-flex">
                    <i class="material-icons font30" style="color:#673ab7;">confirmation_number</i>
                    <h5 class="mt-1">&nbsp;{{ indicadoresRentabilidade.valor_medio_venda |
                        currency:'BRL':'symbol':'1.2-2':'pt' }}</h5>
                </div>
                <div class="card-title mb-0">Ticket Médio</div>
            </div>
        </div>
    </div>
    <!-- TICKET MÉDIO - FIM -->
</div>
<!-- VENDA LÍQUIDA E TOTAL CRÉDITO E DESCONTO VENDA E QUANTIDADE VENDIDA E TICKET MÉDIO - XMALL - FIM -->
<!-- VENDA LÍQUIDA E TOTAL CRÉDITO E DESCONTO VENDA E QUANTIDADE VENDIDA E TICKET MÉDIO - XSMALL - INICIO -->
<div class="row" *ngIf="(isXSmall)">
    <!-- VENDA LÍQUIDA - INICIO -->
    <div class="col-xs-6">
        <div class="card inside-card rounded-0">
            <div class="card-body p-2 bg-white">
                <div class="d-inline-flex">
                    <i class="material-icons font20" style="margin-top:1px;color:#2196f3;">local_atm</i>
                    <h6>&nbsp;{{ indicadoresRentabilidade.valor_total_liquido | currency:'BRL':'symbol':'1.2-2':'pt' }}
                    </h6>
                </div>
                <div class="card-title mb-0">Venda Líquida</div>
            </div>
        </div>
    </div>
    <!-- VENDA LÍQUIDA - FIM -->
    <!-- TOTAL CRÉDITO - INICIO -->
    <div class="col-xs-6">
        <div class="card inside-card rounded-0">
            <div class="card-body p-2 bg-white">
                <div class="d-inline-flex">
                    <i class="material-icons font20" style="color:#4caf50">fact_check</i>
                    <h6>&nbsp;R$ 00,00</h6>
                </div>
                <div class="card-title mb-0">Total Crédito</div>
            </div>
        </div>
    </div>
    <!-- TOTAL CRÉDITO - FIM -->
    <!-- DESCONTO VENDA - INICIO -->
    <div class="col-xs-6">
        <div class="card inside-card rounded-0 mt-2">
            <div class="card-body p-2 bg-white">
                <div class="d-inline-flex">
                    <i class="material-icons font20 text-maxdata">loyalty</i>
                    <h6>&nbsp;{{ indicadoresRentabilidade.total_desconto | currency:'BRL':'symbol':'1.2-2':'pt' }}</h6>
                </div>
                <div class="card-title mb-0 d-flex justify-content-between">
                    <label class="mb-0">Desconto<br />Venda</label>&nbsp;&nbsp;&nbsp;&nbsp;
                    <h6 class="text-maxdata text-right mb-0">
                        {{ (indicadoresRentabilidade.total_desconto === 0 ||
                        indicadoresRentabilidade.valor_total_liquido_mais_impostos === 0 ? 0
                        : indicadoresRentabilidade.total_desconto /
                        indicadoresRentabilidade.valor_total_liquido_mais_impostos) | percent:'1.2-2':'pt' }}
                    </h6>
                </div>
            </div>
        </div>
    </div>
    <!-- DESCONTO VENDA - FIM -->
    <!-- QUANTIDADE VENDIDA - INICIO -->
    <div class="col-xs-6">
        <div class="card inside-card rounded-0 mt-2">
            <div class="card-body p-2 bg-white">
                <div class="d-inline-flex">
                    <i class="material-icons font20" style="color:#ff9800;">shopping_basket</i>
                    <h6>&nbsp;{{indicadoresRentabilidade.quantidade_vendas | number:'1.0-0':'pt' }}</h6>
                </div>
                <div class="card-title mb-0">Quantidade<br />Venda</div>
            </div>
        </div>
    </div>
    <!-- QUANTIDADE VENDIDA - FIM -->
    <!-- TICKET MÉDIO - INICIO -->
    <div class="col-xs-12">
        <div class="card inside-card rounded-0 mt-2">
            <div class="card-body p-2 bg-white">
                <div class="d-inline-flex">
                    <i class="material-icons font20" style="color:#673ab7;">confirmation_number</i>
                    <h6>&nbsp;{{ indicadoresRentabilidade.valor_medio_venda | currency:'BRL':'symbol':'1.2-2':'pt' }}
                    </h6>
                </div>
                <div class="card-title mb-0">Ticket Médio</div>
            </div>
        </div>
    </div>
    <!-- TICKET MÉDIO - FIM -->
</div>
<!-- VENDA LÍQUIDA E TOTAL CRÉDITO E DESCONTO VENDA E QUANTIDADE VENDIDA E TICKET MÉDIO - XSMALL - FIM -->

<br />

<!-- INDICADORES DE RENTABILIDADE E TIPOS DE VENDA E TOP GRUPOS E TOP FABRICANTES E DESEMPENHO DOS CAIXAS E CONDIÇÕES DE PAGAMENTO E TOP VENDEDORES E VENDA DOS ULTÍMOS 12 MESES - XLARGE - INICIO -->
<div *ngIf="(isXLarge)">
    <!-- INDICADORES DE RENTABILIDADE E TIPOS DE VENDA E TOP GRUPOS E TOP FABRICANTES E DESEMPENHO DOS CAIXAS E CONDIÇÕES DE PAGAMENTO E TOP VENDEDORES - INICIO -->
    <div class="row">
        <!-- INDICADORES DE RENTABILIDADE E TIPOS DE VENDA E TOP GRUPOS E TOP FABRICANTES - INICIO -->
        <div class="col">
            <!-- INDICADORES DE RENTABILIDADE E TIPOS DE VENDA - INICIO -->
            <div class="row">
                <!-- INDICADORES DE RENTABILIDADE - INICIO -->
                <div class="col">
                    <div class="card inside-card rounded-0"
                        [ngStyle]="{'min-height': graficoIndicadoresRentabilidade.length > 0 ? '287px': 'inherit'}">
                        <div class="card-body p-2 bg-white">
                            <div class="card-title">Indicadores de Rentabilidade</div>
                            <div class="ph-item p-2 m-0" *ngIf="carregandoIndicadoresRentabilidade">
                                <div class="ph-col-6">
                                    <div class="ph-avatar ml-2" style="max-width: 130px;"></div>
                                </div>
                                <div class="ph-col-6 p-0 m-0">
                                    <div class="ph-row m-0">
                                        <div class="ph-col-12 big"></div>
                                        <div class="ph-col-12 big"></div>
                                    </div>
                                </div>
                                <div class="ph-col-12 p-0 m-0">
                                    <div class="ph-row m-0">
                                        <div class="ph-col-12"></div>
                                        <div class="ph-col-12"></div>
                                        <div class="ph-col-12"></div>
                                        <div class="ph-col-12"></div>
                                        <div class="ph-col-12"></div>
                                        <div class="ph-col-12 p-0 m-0"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="row"
                                *ngIf="graficoIndicadoresRentabilidade.length > 0 && !carregandoIndicadoresRentabilidade">
                                <div class="col-4">
                                    <ngx-charts-pie-chart [view]="[150,150]" [results]="graficoIndicadoresRentabilidade"
                                        [scheme]="colorSchemeIndicadoresRentabilidade" [animations]="true"
                                        [doughnut]="true" [arcWidth]="0.25" [legend]="false" [labels]="false">
                                    </ngx-charts-pie-chart>
                                </div>
                                <div class="col-8" *ngIf="graficoIndicadoresRentabilidade.length > 0">
                                    <h4 style="color:#4869a5;">Lucro Bruto {{
                                        (indicadoresRentabilidade.valor_total_liquido -
                                        indicadoresRentabilidade.custo_medio) /
                                        indicadoresRentabilidade.valor_total_liquido | percent:'1.2-2':'pt' }}</h4>
                                    <h4 style="color:#f39e39;">
                                        CMV&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {{
                                        indicadoresRentabilidade.custo_medio /
                                        indicadoresRentabilidade.valor_total_liquido | percent:'1.2-2':'pt' }}</h4>
                                </div>
                            </div>
                            <div class="row mt-3"
                                *ngIf="graficoIndicadoresRentabilidade.length > 0 && !carregandoIndicadoresRentabilidade">
                                <div class="col flex flex-wrap">
                                    <div class="item-meio flex-item-1">
                                        <div class="card inside-card">
                                            <div class="card-body p-1 bg-white">
                                                <h6 class="text-nowrap mb-0">{{
                                                    indicadoresRentabilidade.valor_total_liquido |
                                                    currency:'BRL':'symbol':'1.2-2':'pt' }}</h6>
                                                <div class="card-title mb-0 text-nowrap">Venda Líquida</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="item-meio text-center">
                                        <h3 class="material-icons text-maxdata mt-3 font20">remove</h3>
                                    </div>
                                    <div class="item-meio flex-item-1">
                                        <div class="card inside-card">
                                            <div class="card-body p-1 bg-white">
                                                <h6 class="text-nowrap mb-0">{{ indicadoresRentabilidade.custo_medio |
                                                    currency:'BRL':'symbol':'1.2-2':'pt' }}</h6>
                                                <div class="card-title mb-0 text-nowrap">CMV</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="item-meio text-center">
                                        <h3 class="material-icons mt-3 font20">drag_handle</h3>
                                    </div>
                                    <div class="item-meio flex-item-1">
                                        <div class="card inside-card">
                                            <div class="card-body p-1 bg-white">
                                                <h6 class="text-nowrap mb-0">{{
                                                    (indicadoresRentabilidade.valor_total_liquido -
                                                    indicadoresRentabilidade.custo_medio) |
                                                    currency:'BRL':'symbol':'1.2-2':'pt' }}</h6>
                                                <div class="card-title mb-0 text-nowrap">Lucro Bruto</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- INDICADORES DE RENTABILIDADE - FIM -->
                <!-- TIPOS DE VENDA - INICIO -->
                <div class="col">
                    <div class="card inside-card rounded-0"
                        [ngStyle]="{'min-height': graficoTiposVenda.length > 0 ? '287px': 'inherit'}">
                        <div class="card-body p-2 bg-white">
                            <div class="card-title">Tipo de Venda</div>
                            <div class="ph-item p-2 m-0" *ngIf="carregandoTiposVenda">
                                <div class="ph-col-6">
                                    <div class="ph-avatar mt-3 ml-2" style="max-width: 130px;"></div>
                                </div>
                                <div class="ph-col-6 p-0 m-0"></div>
                                <div class="ph-col-12 p-0 m-0">
                                    <div class="ph-row m-0">
                                        <div class="ph-col-12 big empty"></div>
                                        <div class="ph-col-12 big"></div>
                                        <div class="ph-col-12 big p-0 m-0"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="row" *ngIf="graficoTiposVenda.length > 0 && !carregandoTiposVenda">
                                <div class="col-4">
                                    <ngx-charts-pie-chart [view]="[150,165]" [results]="graficoTiposVenda"
                                        [scheme]="colorSchemeIndicadoresRentabilidade" [animations]="true"
                                        [legend]="false" [labels]="false">
                                    </ngx-charts-pie-chart>
                                </div>
                                <div class="col-8">
                                    <h4 style="color:#4869a5;" class="text-nowrap">À Vista&nbsp; {{
                                        tiposVenda.valor_total_vendas_a_vista / (tiposVenda.valor_total_vendas_a_vista +
                                        tiposVenda.valor_total_vendas_a_prazo) | percent:'1.2-2':'pt' }}</h4>
                                    <h4 style="color:#f39e39;" class="text-nowrap">À Prazo {{
                                        tiposVenda.valor_total_vendas_a_prazo / (tiposVenda.valor_total_vendas_a_vista +
                                        tiposVenda.valor_total_vendas_a_prazo) | percent:'1.2-2':'pt' }}</h4>
                                </div>
                            </div>
                            <div class="row" *ngIf="graficoTiposVenda.length > 0 && !carregandoTiposVenda">
                                <div class="col">
                                    <div class="row">
                                        <div class="col">
                                            <div class="card inside-card">
                                                <div class="card-body p-1 bg-white">
                                                    <h6 class="text-nowrap mb-0">{{
                                                        tiposVenda.valor_total_vendas_a_vista |
                                                        currency:'BRL':'symbol':'1.2-2':'pt' }}</h6>
                                                    <div class="card-title mb-0 text-nowrap" style="color:#4869a5;">À
                                                        Vista</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col">
                                            <div class="card inside-card">
                                                <div class="card-body p-1 bg-white">
                                                    <h6 class="text-nowrap mb-0">{{
                                                        tiposVenda.valor_total_vendas_a_prazo |
                                                        currency:'BRL':'symbol':'1.2-2':'pt' }}</h6>
                                                    <div class="card-title mb-0 text-nowrap" style="color:#f39e39;">À
                                                        Prazo</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- TIPOS DE VENDA - FIM -->
            </div>
            <!-- INDICADORES DE RENTABILIDADE E TIPOS DE VENDA - FIM -->
            <br />
            <!-- TOP GRUPOS E TOP FABRICANTES - INICIO -->
            <div class="row">
                <!-- TOP GRUPOS - INICIO -->
                <div class="col">
                    <div class="card inside-card rounded-0">
                        <div class="card-body p-2 bg-white">
                            <div class="card-title">Top Grupos</div>
                            <div class="ph-item p-2 m-0" *ngIf="carregandoTopGrupos">
                                <div class="ph-col-6 pl-2 p-0 m-0">
                                    <div class="ph-avatar ml-2" style="max-width: 200px;"></div>
                                </div>
                                <div class="ph-col-6 pl-4 p-0 m-0">
                                    <div class="ph-row m-0">
                                        <div class="ph-col-12"></div>
                                        <div class="ph-col-12"></div>
                                        <div class="ph-col-12"></div>
                                        <div class="ph-col-12"></div>
                                        <div class="ph-col-12"></div>
                                        <div class="ph-col-12"></div>
                                        <div class="ph-col-12"></div>
                                        <div class="ph-col-12"></div>
                                        <div class="ph-col-12"></div>
                                        <div class="ph-col-12"></div>
                                        <div class="ph-col-12"></div>
                                        <div class="ph-col-12"></div>
                                        <div class="ph-col-12 p-0 m-0"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="row" *ngIf="graficoTopGrupos.length > 0 && !carregandoTopGrupos">
                                <div class="col recuar-chart">
                                    <ngx-charts-pie-chart (window:resize)="resizeGraficoTopGrupos()"
                                        [view]="viewGraficoTopGrupos" [results]="graficoTopGrupos"
                                        [scheme]="colorSchemeTopGrupos" [legendTitle]="'Legenda'" [animations]="true"
                                        [legend]="true" [labels]="false">
                                    </ngx-charts-pie-chart>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- TOP GRUPOS - FIM -->
                <!-- TOP FABRICANTES - INICIO -->
                <div class="col">
                    <div class="card inside-card rounded-0">
                        <div class="card-body p-2 bg-white">
                            <div class="card-title">Top Fabricantes</div>
                            <div class="ph-item p-2 m-0" *ngIf="carregandoTopFabricantes">
                                <div class="ph-col-6 pl-2 p-0 m-0">
                                    <div class="ph-avatar ml-2" style="max-width: 200px;"></div>
                                </div>
                                <div class="ph-col-6 pl-4 p-0 m-0">
                                    <div class="ph-row m-0">
                                        <div class="ph-col-12"></div>
                                        <div class="ph-col-12"></div>
                                        <div class="ph-col-12"></div>
                                        <div class="ph-col-12"></div>
                                        <div class="ph-col-12"></div>
                                        <div class="ph-col-12"></div>
                                        <div class="ph-col-12"></div>
                                        <div class="ph-col-12"></div>
                                        <div class="ph-col-12"></div>
                                        <div class="ph-col-12"></div>
                                        <div class="ph-col-12"></div>
                                        <div class="ph-col-12"></div>
                                        <div class="ph-col-12 p-0 m-0"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="row" *ngIf="graficoTopFabricantes.length > 0 && !carregandoTopFabricantes">
                                <div class="col recuar-chart">
                                    <ngx-charts-pie-chart (window:resize)="resizeGraficoTopFabricantes()"
                                        [view]="viewGraficoTopFabricantes" [results]="graficoTopFabricantes"
                                        [scheme]="colorSchemeTopFabricantes" [legendTitle]="'Legenda'"
                                        [animations]="true" [legend]="true" [labels]="false">
                                    </ngx-charts-pie-chart>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- TOP FABRICANTES - FIM -->
            </div>
            <!-- TOP GRUPOS E TOP FABRICANTES - FIM -->
        </div>
        <!-- INDICADORES DE RENTABILIDADE E TIPOS DE VENDA E TOP GRUPOS E TOP FABRICANTES - FIM -->
        <!-- DESEMPENHO DOS CAIXAS E CONDIÇÕES DE PAGAMENTO E TOP VENDEDORES - INICIO -->
        <div class="col">
            <!-- DESEMPENHO DOS CAIXAS E CONDIÇÕES DE PAGAMENTO - INICIO -->
            <div class="row">
                <!-- DESEMPENHO DOS CAIXAS - INICIO -->
                <div class="col">
                    <div class="card inside-card rounded-0">
                        <div class="card-body p-2 bg-white">
                            <div class="card-title">Desempenho dos Caixas</div>
                            <div class="ph-item p-2 m-0" *ngIf="carregandoDesempenhoCaixas">
                                <div class="ph-col-12 p-0 m-0">
                                    <div class="ph-row m-0">
                                        <div class="ph-col-12 big"></div>
                                        <div class="ph-col-10 big"></div>
                                        <div class="ph-col-2 big empty"></div>
                                        <div class="ph-col-8 big"></div>
                                        <div class="ph-col-4 big empty"></div>
                                        <div class="ph-col-6 big"></div>
                                        <div class="ph-col-6 big empty"></div>
                                        <div class="ph-col-4 big"></div>
                                        <div class="ph-col-8 big empty"></div>
                                        <div class="ph-col-4 big"></div>
                                        <div class="ph-col-8 big empty"></div>
                                        <div class="ph-col-2 big p-0 m-0"></div>
                                        <div class="ph-col-10 big empty p-0 m-0"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="row" *ngIf="graficoDesempenhoCaixas.length > 0 && !carregandoDesempenhoCaixas">
                                <div class="col">
                                    <ngx-charts-bar-horizontal (window:resize)="resizeGraficoDesempenhoCaixas()"
                                        [view]="viewGraficoDesempenhoCaixas" [results]="graficoDesempenhoCaixas"
                                        [scheme]="colorSchemeDesempenhoCaixas" [showDataLabel]="true" [gradient]="false"
                                        [xAxis]="true" [yAxis]="true" [legend]="false" [showXAxisLabel]="false"
                                        [showYAxisLabel]="false" [xAxisTickFormatting]="formatacaoValor">
                                    </ngx-charts-bar-horizontal>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- DESEMPENHO DOS CAIXAS - FIM -->
                <!-- CONDIÇÕES DE PAGAMENTO - INICIO -->
                <div class="col">
                    <div class="card inside-card rounded-0">
                        <div class="card-body p-2 bg-white">
                            <div class="card-title">Tabela de Preço</div>
                            <div class="ph-item p-2 m-0" *ngIf="carregandoCondicaoPagamento">
                                <div class="ph-col-12 p-0 m-0">
                                    <div class="ph-row m-0">
                                        <div class="ph-col-12 big"></div>
                                        <div class="ph-col-10 big"></div>
                                        <div class="ph-col-2 big empty"></div>
                                        <div class="ph-col-8 big"></div>
                                        <div class="ph-col-4 big empty"></div>
                                        <div class="ph-col-6 big"></div>
                                        <div class="ph-col-6 big empty"></div>
                                        <div class="ph-col-4 big"></div>
                                        <div class="ph-col-8 big empty"></div>
                                        <div class="ph-col-4 big"></div>
                                        <div class="ph-col-8 big empty"></div>
                                        <div class="ph-col-2 big p-0 m-0"></div>
                                        <div class="ph-col-10 big empty p-0 m-0"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="row"
                                *ngIf="graficoCondicaoPagamento.length > 0 && !carregandoCondicaoPagamento">
                                <div class="col">
                                    <ngx-charts-bar-horizontal (window:resize)="resizeGraficoCondicaoPagamento()"
                                        [view]="viewGraficoCondicaoPagamento" [results]="graficoCondicaoPagamento"
                                        [scheme]="colorSchemeCondicaoPagamento" [showDataLabel]="true"
                                        [gradient]="false" [xAxis]="true" [yAxis]="true" [legend]="false"
                                        [showXAxisLabel]="false" [showYAxisLabel]="false"
                                        [xAxisTickFormatting]="formatacaoValor">
                                    </ngx-charts-bar-horizontal>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- CONDIÇÕES DE PAGAMENTO - FIM -->
            </div>
            <!-- DESEMPENHO DOS CAIXAS E CONDIÇÕES DE PAGAMENTO - FIM -->
            <br />
            <!-- TOP VENDEDORES - INICIO -->
            <div class="row">
                <div class="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                    <div class="card inside-card rounded-0">
                        <div class="card-body p-2 bg-white">
                            <div class="card-title">Top Vendedores</div>
                            <div class="ph-item p-2 m-0" *ngIf="carregandoTopVendedores">
                                <div class="ph-col-12 p-0 m-0">
                                    <div class="ph-row m-0">
                                        <div class="ph-col-12 big"></div>
                                        <div class="ph-col-10 big"></div>
                                        <div class="ph-col-2 big empty"></div>
                                        <div class="ph-col-8 big"></div>
                                        <div class="ph-col-4 big empty"></div>
                                        <div class="ph-col-6 big"></div>
                                        <div class="ph-col-6 big empty"></div>
                                        <div class="ph-col-4 big"></div>
                                        <div class="ph-col-8 big empty"></div>
                                        <div class="ph-col-4 big"></div>
                                        <div class="ph-col-8 big empty"></div>
                                        <div class="ph-col-2 big p-0 m-0"></div>
                                        <div class="ph-col-10 big empty p-0 m-0"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="row" *ngIf="graficoTopVendedores.length > 0 && !carregandoTopVendedores">
                                <div class="col">
                                    <ngx-charts-bar-horizontal (window:resize)="resizeGraficoTopVendedores()"
                                        [view]="viewGraficoTopVendedores" [results]="graficoTopVendedores"
                                        [scheme]="colorSchemeTopVendedores" [showDataLabel]="true" [gradient]="false"
                                        [xAxis]="true" [yAxis]="true" [legend]="false" [showXAxisLabel]="false"
                                        [showYAxisLabel]="false" [xAxisTickFormatting]="formatacaoValor">
                                    </ngx-charts-bar-horizontal>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- TOP VENDEDORES - FIM -->
        </div>
        <!-- DESEMPENHO DOS CAIXAS E CONDIÇÕES DE PAGAMENTO E TOP VENDEDORES - FIM -->
    </div>
    <!-- INDICADORES DE RENTABILIDADE E TIPOS DE VENDA E TOP GRUPOS E TOP FABRICANTES E DESEMPENHO DOS CAIXAS E CONDIÇÕES DE PAGAMENTO E TOP VENDEDORES - FIM -->
    <br />
    <!-- VENDA DOS ULTÍMOS 12 MESES - INICIO -->
    <div class="row">
        <div class="offset-3 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <div class="card inside-card rounded-0">
                <div class="card-body p-2 bg-white">
                    <div class="card-title">Vendas dos Últimos 12 Meses</div>
                    <div class="ph-item p-2 m-0" *ngIf="carregandoVendasUltimos12Meses">
                        <div class="ph-col-12 p-0 m-0">
                            <div class="ph-row m-0">
                                <div class="ph-col-12 big"></div>
                                <div class="ph-col-12 big"></div>
                                <div class="ph-col-12 big"></div>
                                <div class="ph-col-12 big"></div>
                                <div class="ph-col-12 big"></div>
                                <div class="ph-col-12 big"></div>
                                <div class="ph-col-12 big"></div>
                                <div class="ph-col-12 big"></div>
                                <div class="ph-col-12 big"></div>
                                <div class="ph-col-12 big p-0 m-0"></div>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="graficoVendasUltimos12Meses.length > 0 && !carregandoVendasUltimos12Meses">
                        <div class="col">
                            <ngx-charts-line-chart (window:resize)="resizeGraficoVendasUltimos12Meses()"
                                [view]="viewGraficoVendasUltimos12Meses" [results]="graficoVendasUltimos12Meses"
                                [scheme]="colorSchemeVendasUltimos12Meses" [showDataLabel]="true" [legend]="false"
                                [showXAxisLabel]="true" [showYAxisLabel]="true" [xAxis]="true" [yAxis]="true"
                                [xAxisLabel]="'Período'" [yAxisLabel]="'Valor'" [timeline]="false"
                                [yAxisTickFormatting]="formatacaoValor">
                            </ngx-charts-line-chart>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- VENDA DOS ULTÍMOS 12 MESES - INICIO -->
</div>
<!-- INDICADORES DE RENTABILIDADE E TIPOS DE VENDA E TOP GRUPOS E TOP FABRICANTES E DESEMPENHO DOS CAIXAS E CONDIÇÕES DE PAGAMENTO E TOP VENDEDORES E VENDA DOS ULTÍMOS 12 MESES - XLARGE - FIM -->

<!-- INDICADORES DE RENTABILIDADE E TIPOS DE VENDA E TOP GRUPOS E TOP FABRICANTES E DESEMPENHO DOS CAIXAS E CONDIÇÕES DE PAGAMENTO E TOP VENDEDORES E VENDA DOS ULTÍMOS 12 MESES - LARGE - INICIO -->
<div class="row" *ngIf="(isLarge) && !(isXLarge)">
    <div class="col">
        <!-- INDICADORES DE RENTABILIDADE E TIPOS DE VENDA E TOP GRUPOS - LARGE - INICIO -->
        <div class="row">
            <!-- INDICADORES DE RENTABILIDADE - INICIO -->
            <div class="col">
                <div class="card inside-card rounded-0"
                    [ngStyle]="{'min-height': graficoIndicadoresRentabilidade.length > 0 ? '313px': 'inherit'}">
                    <div class="card-body p-2 bg-white">
                        <div class="card-title">Indicadores de Rentabilidade</div>
                        <div class="ph-item p-2 m-0" *ngIf="carregandoIndicadoresRentabilidade">
                            <div class="ph-col-6">
                                <div class="ph-avatar ml-2" style="max-width: 130px;"></div>
                            </div>
                            <div class="ph-col-6 p-0 m-0">
                                <div class="ph-row m-0">
                                    <div class="ph-col-12 big"></div>
                                    <div class="ph-col-12 big"></div>
                                </div>
                            </div>
                            <div class="ph-col-12 p-0 m-0">
                                <div class="ph-row m-0">
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12 p-0 m-0"></div>
                                </div>
                            </div>
                        </div>
                        <div class="row"
                            *ngIf="graficoIndicadoresRentabilidade.length > 0 && !carregandoIndicadoresRentabilidade">
                            <div class="col-4">
                                <ngx-charts-pie-chart [view]="[175,175]" [results]="graficoIndicadoresRentabilidade"
                                    [scheme]="colorSchemeIndicadoresRentabilidade" [animations]="true" [doughnut]="true"
                                    [arcWidth]="0.25" [legend]="false" [labels]="false">
                                </ngx-charts-pie-chart>
                            </div>
                            <div class="col-8" *ngIf="graficoIndicadoresRentabilidade.length > 0">
                                <div class="ml-3">
                                    <h4 style="color:#4869a5;">Lucro Bruto {{
                                        (indicadoresRentabilidade.valor_total_liquido -
                                        indicadoresRentabilidade.custo_medio) /
                                        indicadoresRentabilidade.valor_total_liquido | percent:'1.2-2':'pt' }}</h4>
                                    <h4 style="color:#f39e39;">
                                        CMV&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {{
                                        indicadoresRentabilidade.custo_medio /
                                        indicadoresRentabilidade.valor_total_liquido | percent:'1.2-2':'pt' }}</h4>
                                </div>
                            </div>
                        </div>
                        <div class="row"
                            *ngIf="graficoIndicadoresRentabilidade.length > 0 && !carregandoIndicadoresRentabilidade"
                            style="margin-top:.66rem!important;">
                            <div class="col flex flex-wrap">
                                <div class="item-meio flex-item-1">
                                    <div class="card inside-card mt-1">
                                        <div class="card-body p-2 bg-white">
                                            <h6 class="text-nowrap mb-0">{{ indicadoresRentabilidade.valor_total_liquido
                                                | currency:'BRL':'symbol':'1.2-2':'pt' }}</h6>
                                            <div class="card-title mb-0 text-nowrap">Venda Líquida</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="item-meio text-center">
                                    <h2 class="material-icons text-maxdata mt-4 font20">remove</h2>
                                </div>
                                <div class="item-meio flex-item-1">
                                    <div class="card inside-card mt-1">
                                        <div class="card-body p-2 bg-white">
                                            <h6 class="text-nowrap mb-0">{{ indicadoresRentabilidade.custo_medio |
                                                currency:'BRL':'symbol':'1.2-2':'pt' }}</h6>
                                            <div class="card-title mb-0 text-nowrap">CMV</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="item-meio text-center">
                                    <h2 class="material-icons mt-4 font20">drag_handle</h2>
                                </div>
                                <div class="item-meio flex-item-1">
                                    <div class="card inside-card mt-1">
                                        <div class="card-body p-2 bg-white">
                                            <h6 class="text-nowrap mb-0">{{
                                                (indicadoresRentabilidade.valor_total_liquido -
                                                indicadoresRentabilidade.custo_medio) |
                                                currency:'BRL':'symbol':'1.2-2':'pt' }}</h6>
                                            <div class="card-title mb-0 text-nowrap">Lucro Bruto</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- INDICADORES DE RENTABILIDADE - FIM -->
            <!-- TIPOS DE VENDA - INICIO -->
            <div class="col">
                <div class="card inside-card rounded-0"
                    [ngStyle]="{'min-height': graficoTiposVenda.length > 0 ? '313px': 'inherit'}">
                    <div class="card-body p-2 bg-white">
                        <div class="card-title">Tipo de Venda</div>
                        <div class="ph-item p-2 m-0" *ngIf="carregandoTiposVenda">
                            <div class="ph-col-6">
                                <div class="ph-avatar mt-3 ml-2" style="max-width: 130px;"></div>
                            </div>
                            <div class="ph-col-6 p-0 m-0"></div>
                            <div class="ph-col-12 p-0 m-0">
                                <div class="ph-row m-0">
                                    <div class="ph-col-12 big empty"></div>
                                    <div class="ph-col-12 big"></div>
                                    <div class="ph-col-12 big p-0 m-0"></div>
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="graficoTiposVenda.length > 0 && !carregandoTiposVenda">
                            <div class="col">
                                <ngx-charts-pie-chart [view]="[250,175]" [results]="graficoTiposVenda"
                                    [scheme]="colorSchemeIndicadoresRentabilidade" [animations]="true" [legend]="false"
                                    [labels]="false">
                                </ngx-charts-pie-chart>
                            </div>
                            <div class="col">
                                <h4 style="color:#4869a5;" class="text-nowrap">À Vista&nbsp; {{
                                    tiposVenda.valor_total_vendas_a_vista / (tiposVenda.valor_total_vendas_a_vista +
                                    tiposVenda.valor_total_vendas_a_prazo) | percent:'1.2-2':'pt' }}</h4>
                                <h4 style="color:#f39e39;" class="text-nowrap">À Prazo {{
                                    tiposVenda.valor_total_vendas_a_prazo / (tiposVenda.valor_total_vendas_a_vista +
                                    tiposVenda.valor_total_vendas_a_prazo) | percent:'1.2-2':'pt' }}</h4>
                            </div>
                        </div>
                        <div class="row mt-3" *ngIf="graficoTiposVenda.length > 0 && !carregandoTiposVenda">
                            <div class="col">
                                <div class="row">
                                    <div class="col">
                                        <div class="card inside-card rounded-0">
                                            <div class="card-body p-2 bg-white">
                                                <h6 class="text-nowrap mb-0">{{ tiposVenda.valor_total_vendas_a_vista |
                                                    currency:'BRL':'symbol':'1.2-2':'pt' }}</h6>
                                                <div class="card-title mb-0 text-nowrap" style="color:#4869a5;">À Vista
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="card inside-card rounded-0">
                                            <div class="card-body p-2 bg-white">
                                                <h6 class="text-nowrap mb-0">{{ tiposVenda.valor_total_vendas_a_prazo |
                                                    currency:'BRL':'symbol':'1.2-2':'pt' }}</h6>
                                                <div class="card-title mb-0 text-nowrap" style="color:#f39e39;">À Prazo
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- TIPOS DE VENDA - FIM -->
            <!-- TOP GRUPOS - INICIO -->
            <div class="col">
                <div class="card inside-card rounded-0"
                    [ngStyle]="{'min-height': graficoTopGrupos.length > 0 ? '312px': 'inherit'}">
                    <div class="card-body p-2 bg-white">
                        <div class="card-title">Top Grupos</div>
                        <div class="ph-item p-2 m-0" *ngIf="carregandoTopGrupos">
                            <div class="ph-col-6 pl-2 p-0 m-0">
                                <div class="ph-avatar mt-3 ml-1" style="max-width: 220px;"></div>
                            </div>
                            <div class="ph-col-6 pl-4 p-0 m-0">
                                <div class="ph-row m-0">
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12 p-0 m-0"></div>
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="graficoTopGrupos.length > 0 && !carregandoTopGrupos">
                            <div class="col recuar-chart">
                                <ngx-charts-pie-chart (window:resize)="resizeGraficoTopGrupos()"
                                    [view]="viewGraficoTopGrupos" [results]="graficoTopGrupos"
                                    [scheme]="colorSchemeTopGrupos" [legendTitle]="'Legenda'" [showDataLabel]="true"
                                    [animations]="true" [legend]="true" [labels]="false">
                                </ngx-charts-pie-chart>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- TOP GRUPOS - FIM -->
        </div>
        <!-- INDICADORES DE RENTABILIDADE E TIPOS DE VENDA E TOP GRUPOS - LARGE - FIM -->
        <br />
        <!-- TOP FABRICANTES E TOP VENDEDORES E DESEMPENHO DOS CAIXAS - LARGE - INICIO -->
        <div class="row">
            <!-- TOP FABRICANTES - INICIO -->
            <div class="col">
                <div class="card inside-card rounded-0">
                    <div class="card-body p-2 bg-white mb-1">
                        <div class="card-title">Top Fabricantes</div>
                        <div class="ph-item p-2 m-0" *ngIf="carregandoTopFabricantes">
                            <div class="ph-col-6 pl-2 p-0 m-0">
                                <div class="ph-avatar mt-3 ml-1" style="max-width: 220px;"></div>
                            </div>
                            <div class="ph-col-6 pl-4 p-0 m-0">
                                <div class="ph-row m-0">
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12 p-0 m-0"></div>
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="graficoTopFabricantes.length > 0 && !carregandoTopFabricantes">
                            <div class="col recuar-chart">
                                <ngx-charts-pie-chart (window:resize)="resizeGraficoTopFabricantes()"
                                    [view]="viewGraficoTopFabricantes" [results]="graficoTopFabricantes"
                                    [scheme]="colorSchemeTopFabricantes" [legendTitle]="'Legenda'"
                                    [showDataLabel]="true" [animations]="true" [legend]="true" [labels]="false">
                                </ngx-charts-pie-chart>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- TOP FABRICANTES - FIM -->
            <!-- TOP VENDEDORES - INICIO -->
            <div class="col">
                <div class="card inside-card rounded-0"
                    [ngStyle]="{'min-height': graficoTopVendedores.length > 0 ? '309px': 'inherit'}">
                    <div class="card-body p-2 bg-white">
                        <div class="card-title">Top Vendedores</div>
                        <div class="ph-item p-2 m-0" *ngIf="carregandoTopVendedores">
                            <div class="ph-col-12 p-0 m-0">
                                <div class="ph-row m-0">
                                    <div class="ph-col-12 big"></div>
                                    <div class="ph-col-10 big"></div>
                                    <div class="ph-col-2 big empty"></div>
                                    <div class="ph-col-8 big"></div>
                                    <div class="ph-col-4 big empty"></div>
                                    <div class="ph-col-6 big"></div>
                                    <div class="ph-col-6 big empty"></div>
                                    <div class="ph-col-4 big"></div>
                                    <div class="ph-col-8 big empty"></div>
                                    <div class="ph-col-4 big"></div>
                                    <div class="ph-col-8 big empty"></div>
                                    <div class="ph-col-2 big p-0 m-0"></div>
                                    <div class="ph-col-10 big empty p-0 m-0"></div>
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="graficoTopVendedores.length > 0 && !carregandoTopVendedores">
                            <div class="col">
                                <ngx-charts-bar-horizontal (window:resize)="resizeGraficoTopVendedores()"
                                    [view]="viewGraficoTopVendedores" [results]="graficoTopVendedores"
                                    [scheme]="colorSchemeTopVendedores" [showDataLabel]="true" [gradient]="false"
                                    [xAxis]="true" [yAxis]="true" [legend]="false" [showXAxisLabel]="false"
                                    [showYAxisLabel]="false" [xAxisTickFormatting]="formatacaoValor">
                                </ngx-charts-bar-horizontal>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- TOP VENDEDORES - FIM -->
            <!-- DESEMPENHO DOS CAIXAS - INICIO -->
            <div class="col">
                <div class="card inside-card rounded-0"
                    [ngStyle]="{'min-height': graficoDesempenhoCaixas.length > 0 ? '309px': 'inherit'}">
                    <div class="card-body p-2 bg-white">
                        <div class="card-title">Desempenho dos Caixas</div>
                        <div class="ph-item p-2 m-0" *ngIf="carregandoDesempenhoCaixas">
                            <div class="ph-col-12 p-0 m-0">
                                <div class="ph-row m-0">
                                    <div class="ph-col-12 big"></div>
                                    <div class="ph-col-10 big"></div>
                                    <div class="ph-col-2 big empty"></div>
                                    <div class="ph-col-8 big"></div>
                                    <div class="ph-col-4 big empty"></div>
                                    <div class="ph-col-6 big"></div>
                                    <div class="ph-col-6 big empty"></div>
                                    <div class="ph-col-4 big"></div>
                                    <div class="ph-col-8 big empty"></div>
                                    <div class="ph-col-4 big"></div>
                                    <div class="ph-col-8 big empty"></div>
                                    <div class="ph-col-2 big p-0 m-0"></div>
                                    <div class="ph-col-10 big empty p-0 m-0"></div>
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="graficoDesempenhoCaixas.length > 0 && !carregandoDesempenhoCaixas">
                            <div class="col">
                                <ngx-charts-bar-horizontal (window:resize)="resizeGraficoDesempenhoCaixas()"
                                    [view]="viewGraficoDesempenhoCaixas" [results]="graficoDesempenhoCaixas"
                                    [scheme]="colorSchemeDesempenhoCaixas" [showDataLabel]="true" [gradient]="false"
                                    [xAxis]="true" [yAxis]="true" [legend]="false" [showXAxisLabel]="false"
                                    [showYAxisLabel]="false" [xAxisTickFormatting]="formatacaoValor">
                                </ngx-charts-bar-horizontal>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- DESEMPENHO DOS CAIXAS - FIM -->
        </div>
        <!-- TOP FABRICANTES E TOP VENDEDORES E DESEMPENHO DOS CAIXAS - LARGE - FIM -->
        <br />
        <!-- CONDIÇÕES DE PAGAMENTO E VENDA DOS ULTÍMOS 12 MESES - LARGE - INICIO -->
        <div class="row">
            <!-- CONDIÇÕES DE PAGAMENTO - INICIO -->
            <div class="col-4">
                <div class="card inside-card rounded-0">
                    <div class="card-body p-2 bg-white">
                        <div class="card-title">Tabela de Preço</div>
                        <div class="ph-item p-2 m-0" *ngIf="carregandoCondicaoPagamento">
                            <div class="ph-col-12 p-0 m-0">
                                <div class="ph-row m-0">
                                    <div class="ph-col-12 big"></div>
                                    <div class="ph-col-10 big"></div>
                                    <div class="ph-col-2 big empty"></div>
                                    <div class="ph-col-8 big"></div>
                                    <div class="ph-col-4 big empty"></div>
                                    <div class="ph-col-8 big"></div>
                                    <div class="ph-col-4 big empty"></div>
                                    <div class="ph-col-6 big"></div>
                                    <div class="ph-col-6 big empty"></div>
                                    <div class="ph-col-4 big"></div>
                                    <div class="ph-col-8 big empty"></div>
                                    <div class="ph-col-4 big"></div>
                                    <div class="ph-col-8 big empty"></div>
                                    <div class="ph-col-2 big p-0 m-0"></div>
                                    <div class="ph-col-10 big empty p-0 m-0"></div>
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="graficoCondicaoPagamento.length > 0 && !carregandoCondicaoPagamento">
                            <div class="col">
                                <ngx-charts-bar-horizontal (window:resize)="resizeGraficoCondicaoPagamento()"
                                    [view]="viewGraficoCondicaoPagamento" [results]="graficoCondicaoPagamento"
                                    [scheme]="colorSchemeCondicaoPagamento" [showDataLabel]="true" [gradient]="false"
                                    [xAxis]="true" [yAxis]="true" [legend]="false" [showXAxisLabel]="false"
                                    [showYAxisLabel]="false" [xAxisTickFormatting]="formatacaoValor">
                                </ngx-charts-bar-horizontal>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- CONDIÇÕES DE PAGAMENTO - FIM -->
            <!-- VENDA DOS ULTÍMOS 12 MESES - INICIO -->
            <div class="col-8">
                <div class="card inside-card rounded-0">
                    <div class="card-body p-2 bg-white">
                        <div class="card-title">Vendas dos Últimos 12 Meses</div>
                        <div class="ph-item p-2 m-0" *ngIf="carregandoVendasUltimos12Meses">
                            <div class="ph-col-12 p-0 m-0">
                                <div class="ph-row m-0">
                                    <div class="ph-col-12 big"></div>
                                    <div class="ph-col-12 big"></div>
                                    <div class="ph-col-12 big"></div>
                                    <div class="ph-col-12 big"></div>
                                    <div class="ph-col-12 big"></div>
                                    <div class="ph-col-12 big"></div>
                                    <div class="ph-col-12 big"></div>
                                    <div class="ph-col-12 big p-0 m-0"></div>
                                </div>
                            </div>
                        </div>
                        <div class="row"
                            *ngIf="graficoVendasUltimos12Meses.length > 0 && !carregandoVendasUltimos12Meses">
                            <div class="col">
                                <ngx-charts-line-chart (window:resize)="resizeGraficoVendasUltimos12Meses()"
                                    [view]="viewGraficoVendasUltimos12Meses" [results]="graficoVendasUltimos12Meses"
                                    [scheme]="colorSchemeVendasUltimos12Meses" [showDataLabel]="true" [legend]="false"
                                    [showXAxisLabel]="true" [showYAxisLabel]="true" [xAxis]="true" [yAxis]="true"
                                    [xAxisLabel]="'Período'" [yAxisLabel]="'Valor'" [timeline]="false"
                                    [yAxisTickFormatting]="formatacaoValor">
                                </ngx-charts-line-chart>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- VENDA DOS ULTÍMOS 12 MESES - FIM -->
        </div>
        <!-- CONDIÇÕES DE PAGAMENTO E VENDA DOS ULTÍMOS 12 MESES - LARGE - FIM -->
    </div>
</div>
<!-- INDICADORES DE RENTABILIDADE E TIPOS DE VENDA E TOP GRUPOS E TOP FABRICANTES E DESEMPENHO DOS CAIXAS E CONDIÇÕES DE PAGAMENTO E TOP VENDEDORES E VENDA DOS ULTÍMOS 12 MESES - LARGE - FIM -->

<!-- INDICADORES DE RENTABILIDADE E TIPOS DE VENDA E TOP GRUPOS E TOP FABRICANTES E DESEMPENHO DOS CAIXAS E CONDIÇÕES DE PAGAMENTO E TOP VENDEDORES E VENDA DOS ULTÍMOS 12 MESES - MEDIUM - INICIO -->
<div class="row" *ngIf="(isMedium)">
    <div class="col">
        <!-- INDICADORES DE RENTABILIDADE E TIPOS DE VENDA - INICIO -->
        <div class="row">
            <!-- INDICADORES DE RENTABILIDADE - INICIO -->
            <div class="col">
                <div class="card inside-card rounded-0">
                    <div class="card-body p-2 bg-white">
                        <div class="card-title">Indicadores de Rentabilidade</div>
                        <div class="ph-item p-2 m-0" *ngIf="carregandoIndicadoresRentabilidade">
                            <div class="ph-col-6">
                                <div class="ph-avatar ml-2" style="max-width: 130px;"></div>
                            </div>
                            <div class="ph-col-6 p-0 m-0">
                                <div class="ph-row m-0">
                                    <div class="ph-col-12 big"></div>
                                    <div class="ph-col-12 big"></div>
                                </div>
                            </div>
                            <div class="ph-col-12 p-0 m-0">
                                <div class="ph-row m-0">
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12 p-0 m-0"></div>
                                </div>
                            </div>
                        </div>
                        <div class="row"
                            *ngIf="graficoIndicadoresRentabilidade.length > 0 && !carregandoIndicadoresRentabilidade">
                            <div class="col-4">
                                <ngx-charts-pie-chart [view]="[150,150]" [results]="graficoIndicadoresRentabilidade"
                                    [scheme]="colorSchemeIndicadoresRentabilidade" [animations]="true" [doughnut]="true"
                                    [arcWidth]="0.25" [legend]="false" [labels]="false">
                                </ngx-charts-pie-chart>
                            </div>
                            <div class="col-8">
                                <h4 style="color:#4869a5;">Lucro Bruto {{ (indicadoresRentabilidade.valor_total_liquido
                                    - indicadoresRentabilidade.custo_medio) /
                                    indicadoresRentabilidade.valor_total_liquido | percent:'1.2-2':'pt' }}</h4>
                                <h4 style="color:#f39e39;">
                                    CMV&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {{
                                    indicadoresRentabilidade.custo_medio / indicadoresRentabilidade.valor_total_liquido
                                    | percent:'1.2-2':'pt' }}</h4>
                            </div>
                        </div>
                        <div class="row"
                            *ngIf="graficoIndicadoresRentabilidade.length > 0 && !carregandoIndicadoresRentabilidade">
                            <div class="col-12 flex flex-wrap">
                                <div class="item-meio flex-item-1">
                                    <div class="card inside-card rounded-0">
                                        <div class="card-body p-2 bg-white">
                                            <h6 class="text-nowrap mb-0">{{ indicadoresRentabilidade.valor_total_liquido
                                                | currency:'BRL':'symbol':'1.2-2':'pt' }}</h6>
                                            <div class="card-title mb-0 text-nowrap">Venda Líquida</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="item-meio text-center">
                                    <h2 class="material-icons text-maxdata mt-3 font20">remove</h2>
                                </div>
                                <div class="item-meio flex-item-1">
                                    <div class="card inside-card rounded-0">
                                        <div class="card-body p-2 bg-white">
                                            <h6 class="text-nowrap mb-0">{{ indicadoresRentabilidade.custo_medio |
                                                currency:'BRL':'symbol':'1.2-2':'pt' }}</h6>
                                            <div class="card-title mb-0 text-nowrap">CMV</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="item-meio text-center">
                                    <h2 class="material-icons mt-3 font20">drag_handle</h2>
                                </div>
                                <div class="item-meio flex-item-1">
                                    <div class="card inside-card rounded-0">
                                        <div class="card-body p-2 bg-white">
                                            <h6 class="text-nowrap mb-0">{{
                                                (indicadoresRentabilidade.valor_total_liquido -
                                                indicadoresRentabilidade.custo_medio) |
                                                currency:'BRL':'symbol':'1.2-2':'pt' }}</h6>
                                            <div class="card-title mb-0 text-nowrap">Lucro Bruto</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- INDICADORES DE RENTABILIDADE - FIM -->
            <!-- TIPOS DE VENDA - INICIO -->
            <div class="col">
                <div class="card inside-card rounded-0"
                    [ngStyle]="{'min-height': graficoTiposVenda.length > 0 ? '261px': 'inherit'}">
                    <div class="card-body p-2 bg-white">
                        <div class="card-title">Tipo de Venda</div>
                        <div class="ph-item p-2 m-0" *ngIf="carregandoTiposVenda">
                            <div class="ph-col-6">
                                <div class="ph-avatar mt-3 ml-2" style="max-width: 125px;"></div>
                            </div>
                            <div class="ph-col-6 p-0 m-0"></div>
                            <div class="ph-col-12 p-0 m-0">
                                <div class="ph-row m-0">
                                    <div class="ph-col-12 big"></div>
                                    <div class="ph-col-12 big p-0 m-0"></div>
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="graficoTiposVenda.length > 0 && !carregandoTiposVenda">
                            <div class="col-4">
                                <ngx-charts-pie-chart [view]="[150,150]" [results]="graficoTiposVenda"
                                    [scheme]="colorSchemeIndicadoresRentabilidade" [animations]="true" [legend]="false"
                                    [labels]="false">
                                </ngx-charts-pie-chart>
                            </div>
                            <div class="col-8">
                                <h5 style="color:#4869a5;" class="text-nowrap">À Vista&nbsp; {{
                                    tiposVenda.valor_total_vendas_a_vista / (tiposVenda.valor_total_vendas_a_vista +
                                    tiposVenda.valor_total_vendas_a_prazo) | percent:'1.2-2':'pt' }}</h5>
                                <h5 style="color:#f39e39;" class="text-nowrap">À Prazo {{
                                    tiposVenda.valor_total_vendas_a_prazo / (tiposVenda.valor_total_vendas_a_vista +
                                    tiposVenda.valor_total_vendas_a_prazo) | percent:'1.2-2':'pt' }}</h5>
                            </div>
                        </div>
                        <div class="row" *ngIf="graficoTiposVenda.length > 0 && !carregandoTiposVenda">
                            <div class="col">
                                <div class="row">
                                    <div class="col">
                                        <div class="card inside-card rounded-0">
                                            <div class="card-body p-2 bg-white">
                                                <h6 class="text-nowrap mb-0">{{ tiposVenda.valor_total_vendas_a_vista |
                                                    currency:'BRL':'symbol':'1.2-2':'pt' }}</h6>
                                                <div class="card-title mb-0 text-nowrap" style="color:#4869a5;">À Vista
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="card inside-card rounded-0">
                                            <div class="card-body p-2 bg-white">
                                                <h6 class="text-nowrap mb-0">{{ tiposVenda.valor_total_vendas_a_prazo |
                                                    currency:'BRL':'symbol':'1.2-2':'pt' }}</h6>
                                                <div class="card-title mb-0 text-nowrap" style="color:#f39e39;">À Prazo
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- TIPOS DE VENDA - FIM -->
        </div>
        <!-- INDICADORES DE RENTABILIDADE E TIPOS DE VENDA - FIM -->
        <br />
        <!-- TOP GRUPOS E TOP FABRICANTES - INICIO -->
        <div class="row">
            <!-- TOP GRUPOS - INICIO -->
            <div class="col">
                <div class="card inside-card rounded-0">
                    <div class="card-body p-2 bg-white">
                        <div class="card-title">Top Grupos</div>
                        <div class="ph-item p-2 m-0" *ngIf="carregandoTopGrupos">
                            <div class="ph-col-6 pl-2 p-0 m-0">
                                <div class="ph-avatar mt-2 ml-1" style="max-width: 220px;"></div>
                            </div>
                            <div class="ph-col-6 pl-4 p-0 m-0">
                                <div class="ph-row m-0">
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12 p-0 m-0"></div>
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="graficoTopGrupos.length > 0 && !carregandoTopGrupos">
                            <div class="col recuar-chart">
                                <ngx-charts-pie-chart (window:resize)="resizeGraficoTopGrupos()"
                                    [view]="viewGraficoTopGrupos" [results]="graficoTopGrupos"
                                    [scheme]="colorSchemeTopGrupos" [legendTitle]="'Legenda'" [showDataLabel]="true"
                                    [animations]="true" [legend]="true" [labels]="false">
                                </ngx-charts-pie-chart>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- TOP GRUPOS - FIM -->
            <!-- TOP FABRICANTES - INICIO -->
            <div class="col">
                <div class="card inside-card rounded-0">
                    <div class="card-body p-2 bg-white">
                        <div class="card-title">Top Fabricantes</div>
                        <div class="ph-item p-2 m-0" *ngIf="carregandoTopFabricantes">
                            <div class="ph-col-6 pl-2 p-0 m-0">
                                <div class="ph-avatar mt-2 ml-1" style="max-width: 220px;"></div>
                            </div>
                            <div class="ph-col-6 pl-4 p-0 m-0">
                                <div class="ph-row m-0">
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12 p-0 m-0"></div>
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="graficoTopFabricantes.length > 0 && !carregandoTopFabricantes">
                            <div class="col recuar-chart">
                                <ngx-charts-pie-chart (window:resize)="resizeGraficoTopFabricantes()"
                                    [view]="viewGraficoTopFabricantes" [results]="graficoTopFabricantes"
                                    [scheme]="colorSchemeTopFabricantes" [legendTitle]="'Legenda'"
                                    [showDataLabel]="true" [animations]="true" [outerRadius]="40" [legend]="true"
                                    [labels]="false">
                                </ngx-charts-pie-chart>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- TOP FABRICANTES - FIM -->
        </div>
        <!-- TOP GRUPOS E TOP FABRICANTES - FIM -->
        <br />
        <!-- DESEMPENHO DOS CAIXAS E CONDIÇÕES DE PAGAMENTO - INICIO -->
        <div class="row">
            <!-- DESEMPENHO DOS CAIXAS - INICIO -->
            <div class="col">
                <div class="card inside-card rounded-0">
                    <div class="card-body p-2 bg-white">
                        <div class="card-title">Desempenho dos Caixas</div>
                        <div class="ph-item p-2 m-0" *ngIf="carregandoDesempenhoCaixas">
                            <div class="ph-col-12 p-0 m-0">
                                <div class="ph-row m-0">
                                    <div class="ph-col-12 big"></div>
                                    <div class="ph-col-10 big"></div>
                                    <div class="ph-col-2 big empty"></div>
                                    <div class="ph-col-8 big"></div>
                                    <div class="ph-col-4 big empty"></div>
                                    <div class="ph-col-6 big"></div>
                                    <div class="ph-col-6 big empty"></div>
                                    <div class="ph-col-4 big"></div>
                                    <div class="ph-col-8 big empty"></div>
                                    <div class="ph-col-4 big"></div>
                                    <div class="ph-col-8 big empty"></div>
                                    <div class="ph-col-2 big p-0 m-0"></div>
                                    <div class="ph-col-10 big empty p-0 m-0"></div>
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="graficoDesempenhoCaixas.length > 0 && !carregandoDesempenhoCaixas">
                            <div class="col">
                                <ngx-charts-bar-horizontal (window:resize)="resizeGraficoDesempenhoCaixas()"
                                    [view]="viewGraficoDesempenhoCaixas" [results]="graficoDesempenhoCaixas"
                                    [scheme]="colorSchemeDesempenhoCaixas" [showDataLabel]="true" [gradient]="false"
                                    [xAxis]="true" [yAxis]="true" [legend]="false" [showXAxisLabel]="false"
                                    [showYAxisLabel]="false" [xAxisTickFormatting]="formatacaoValor">
                                </ngx-charts-bar-horizontal>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- DESEMPENHO DOS CAIXAS - FIM -->
            <!-- CONDIÇÕES DE PAGAMENTO - INICIO -->
            <div class="col">
                <div class="card inside-card rounded-0">
                    <div class="card-body p-2 bg-white">
                        <div class="card-title">Tabela de Preço</div>
                        <div class="ph-item p-2 m-0" *ngIf="carregandoCondicaoPagamento">
                            <div class="ph-col-12 p-0 m-0">
                                <div class="ph-row m-0">
                                    <div class="ph-col-12 big"></div>
                                    <div class="ph-col-10 big"></div>
                                    <div class="ph-col-2 big empty"></div>
                                    <div class="ph-col-8 big"></div>
                                    <div class="ph-col-4 big empty"></div>
                                    <div class="ph-col-8 big"></div>
                                    <div class="ph-col-4 big empty"></div>
                                    <div class="ph-col-6 big"></div>
                                    <div class="ph-col-6 big empty"></div>
                                    <div class="ph-col-4 big"></div>
                                    <div class="ph-col-8 big empty"></div>
                                    <div class="ph-col-4 big"></div>
                                    <div class="ph-col-8 big empty"></div>
                                    <div class="ph-col-2 big p-0 m-0"></div>
                                    <div class="ph-col-10 big empty p-0 m-0"></div>
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="graficoCondicaoPagamento.length > 0 && !carregandoCondicaoPagamento">
                            <div class="col">
                                <ngx-charts-bar-horizontal (window:resize)="resizeGraficoCondicaoPagamento()"
                                    [view]="viewGraficoCondicaoPagamento" [results]="graficoCondicaoPagamento"
                                    [scheme]="colorSchemeCondicaoPagamento" [showDataLabel]="true" [gradient]="false"
                                    [xAxis]="true" [yAxis]="true" [legend]="false" [showXAxisLabel]="false"
                                    [showYAxisLabel]="false" [xAxisTickFormatting]="formatacaoValor">
                                </ngx-charts-bar-horizontal>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- CONDIÇÕES DE PAGAMENTO - FIM -->
        </div>
        <!-- DESEMPENHO DOS CAIXAS E CONDIÇÕES DE PAGAMENTO - FIM -->
        <br />
        <!-- TOP VENDEDORES E VENDA DOS ULTÍMOS 12 MESES - INICIO -->
        <div class="row">
            <!-- TOP VENDEDORES - INICIO -->
            <div class="col">
                <div class="card inside-card rounded-0">
                    <div class="card-body p-2 bg-white">
                        <div class="card-title">Top Vendedores</div>
                        <div class="ph-item p-2 m-0" *ngIf="carregandoTopVendedores">
                            <div class="ph-col-12 p-0 m-0">
                                <div class="ph-row m-0">
                                    <div class="ph-col-12 big"></div>
                                    <div class="ph-col-12 big"></div>
                                    <div class="ph-col-10 big"></div>
                                    <div class="ph-col-2 big empty"></div>
                                    <div class="ph-col-10 big"></div>
                                    <div class="ph-col-2 big empty"></div>
                                    <div class="ph-col-8 big"></div>
                                    <div class="ph-col-4 big empty"></div>
                                    <div class="ph-col-8 big"></div>
                                    <div class="ph-col-4 big empty"></div>
                                    <div class="ph-col-6 big"></div>
                                    <div class="ph-col-6 big empty"></div>
                                    <div class="ph-col-4 big"></div>
                                    <div class="ph-col-8 big empty"></div>
                                    <div class="ph-col-4 big"></div>
                                    <div class="ph-col-8 big empty"></div>
                                    <div class="ph-col-2 big"></div>
                                    <div class="ph-col-10 big empty"></div>
                                    <div class="ph-col-2 big p-0 m-0"></div>
                                    <div class="ph-col-10 big empty p-0 m-0"></div>
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="graficoTopVendedores.length > 0 && !carregandoTopVendedores">
                            <div class="col">
                                <ngx-charts-bar-horizontal (window:resize)="resizeGraficoTopVendedores()"
                                    [view]="viewGraficoTopVendedores" [results]="graficoTopVendedores"
                                    [scheme]="colorSchemeTopVendedores" [showDataLabel]="true" [gradient]="false"
                                    [xAxis]="true" [yAxis]="true" [legend]="false" [showXAxisLabel]="false"
                                    [showYAxisLabel]="false" [xAxisTickFormatting]="formatacaoValor">
                                </ngx-charts-bar-horizontal>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- TOP VENDEDORES - FIM -->
            <!-- VENDA DOS ULTÍMOS 12 MESES - INICIO -->
            <div class="col">
                <div class="card inside-card rounded-0">
                    <div class="card-body p-2 bg-white">
                        <div class="card-title">Vendas dos Últimos 12 Meses</div>
                        <div class="ph-item p-2 m-0" *ngIf="carregandoVendasUltimos12Meses">
                            <div class="ph-col-12 p-0 m-0">
                                <div class="ph-row m-0">
                                    <div class="ph-col-12 big"></div>
                                    <div class="ph-col-12 big"></div>
                                    <div class="ph-col-12 big"></div>
                                    <div class="ph-col-12 big"></div>
                                    <div class="ph-col-12 big"></div>
                                    <div class="ph-col-12 big"></div>
                                    <div class="ph-col-12 big"></div>
                                    <div class="ph-col-12 big"></div>
                                    <div class="ph-col-12 big"></div>
                                    <div class="ph-col-12 big"></div>
                                    <div class="ph-col-12 big p-0 m-0"></div>
                                </div>
                            </div>
                        </div>
                        <div class="row"
                            *ngIf="graficoVendasUltimos12Meses.length > 0 && !carregandoVendasUltimos12Meses">
                            <div class="col">
                                <ngx-charts-line-chart (window:load)="resizeGraficoVendasUltimos12Meses()"
                                    (window:resize)="resizeGraficoVendasUltimos12Meses()"
                                    [view]="viewGraficoVendasUltimos12Meses" [results]="graficoVendasUltimos12Meses"
                                    [scheme]="colorSchemeVendasUltimos12Meses" [showDataLabel]="true" [legend]="false"
                                    [showXAxisLabel]="true" [showYAxisLabel]="true" [xAxis]="true" [yAxis]="true"
                                    [xAxisLabel]="'Período'" [yAxisLabel]="'Valor'" [timeline]="false"
                                    [yAxisTickFormatting]="formatacaoValor">
                                </ngx-charts-line-chart>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- VENDA DOS ULTÍMOS 12 MESES - FIM -->
        </div>
        <!-- TOP VENDEDORES E VENDA DOS ULTÍMOS 12 MESES - FIM -->
    </div>
</div>
<!-- INDICADORES DE RENTABILIDADE E TIPOS DE VENDA E TOP GRUPOS E TOP FABRICANTES E DESEMPENHO DOS CAIXAS E CONDIÇÕES DE PAGAMENTO E TOP VENDEDORES E VENDA DOS ULTÍMOS 12 MESES - MEDIUM - FIM -->

<!-- INDICADORES DE RENTABILIDADE E TIPOS DE VENDA E TOP GRUPOS E TOP FABRICANTES E DESEMPENHO DOS CAIXAS E CONDIÇÕES DE PAGAMENTO E TOP VENDEDORES E VENDA DOS ULTÍMOS 12 MESES - SMALL - INICIO -->
<div class="row" *ngIf="(isSmall)">
    <div class="col">
        <!-- INDICADORES DE RENTABILIDADE - INICIO -->
        <div class="row">
            <div class="col">
                <div class="card inside-card rounded-0">
                    <div class="card-body p-2 bg-white">
                        <div class="card-title">Indicadores de Rentabilidade</div>
                        <div class="ph-item p-2 m-0" *ngIf="carregandoIndicadoresRentabilidade">
                            <div class="ph-col-4">
                                <div class="ph-avatar" style="max-width: 220px;"></div>
                            </div>
                            <div class="ph-col-8 p-0 m-0">
                                <div class="ph-row m-0">
                                    <div class="ph-col-12 big mt-3"></div>
                                    <div class="ph-col-12 big"></div>
                                </div>
                            </div>
                            <div class="ph-col-12 p-0 m-0">
                                <div class="ph-row m-0">
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12 p-0 m-0"></div>
                                </div>
                            </div>
                        </div>
                        <div class="row"
                            *ngIf="graficoIndicadoresRentabilidade.length > 0 && !carregandoIndicadoresRentabilidade">
                            <div class="col-5">
                                <ngx-charts-pie-chart [view]="[250,250]" [results]="graficoIndicadoresRentabilidade"
                                    [scheme]="colorSchemeIndicadoresRentabilidade" [animations]="true" [doughnut]="true"
                                    [arcWidth]="0.25" [legend]="false" [labels]="false">
                                </ngx-charts-pie-chart>
                            </div>
                            <div class="col-7">
                                <h3 style="color:#4869a5;">Lucro Bruto {{ (indicadoresRentabilidade.valor_total_liquido
                                    - indicadoresRentabilidade.custo_medio) /
                                    indicadoresRentabilidade.valor_total_liquido | percent:'1.2-2':'pt' }}</h3>
                                <h3 style="color:#f39e39;">
                                    CMV&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {{
                                    indicadoresRentabilidade.custo_medio / indicadoresRentabilidade.valor_total_liquido
                                    | percent:'1.2-2':'pt' }}</h3>
                            </div>
                        </div>
                        <div class="row"
                            *ngIf="graficoIndicadoresRentabilidade.length > 0 && !carregandoIndicadoresRentabilidade">
                            <div class="col-12 flex flex-wrap">
                                <div class="item-meio flex-item-1">
                                    <div class="card inside-card rounded-0">
                                        <div class="card-body p-2 bg-white">
                                            <h6 class="text-nowrap mb-0">{{ indicadoresRentabilidade.valor_total_liquido
                                                | currency:'BRL':'symbol':'1.2-2':'pt' }}</h6>
                                            <div class="card-title mb-0 text-nowrap">Venda Líquida</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="item-meio text-center">
                                    <h2 class="material-icons text-maxdata mt-3 font30">remove</h2>
                                </div>
                                <div class="item-meio flex-item-1">
                                    <div class="card inside-card rounded-0">
                                        <div class="card-body p-2 bg-white">
                                            <h6 class="text-nowrap mb-0">{{ indicadoresRentabilidade.custo_medio |
                                                currency:'BRL':'symbol':'1.2-2':'pt' }}</h6>
                                            <div class="card-title mb-0 text-nowrap">CMV</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="item-meio text-center">
                                    <h2 class="material-icons mt-3 font30">drag_handle</h2>
                                </div>
                                <div class="item-meio flex-item-1">
                                    <div class="card inside-card rounded-0">
                                        <div class="card-body p-2 bg-white">
                                            <h6 class="text-nowrap mb-0">{{
                                                (indicadoresRentabilidade.valor_total_liquido -
                                                indicadoresRentabilidade.custo_medio) |
                                                currency:'BRL':'symbol':'1.2-2':'pt' }}</h6>
                                            <div class="card-title mb-0 text-nowrap">Lucro Bruto</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- INDICADORES DE RENTABILIDADE - FIM -->
        <br />
        <!-- TIPOS DE VENDA - INICIO -->
        <div class="row">
            <div class="col">
                <div class="card inside-card rounded-0">
                    <div class="card-body p-2 bg-white">
                        <div class="card-title">Tipo de Venda</div>
                        <div class="ph-item p-2 m-0" *ngIf="carregandoTiposVenda">
                            <div class="ph-col-4">
                                <div class="ph-avatar mt-3" style="max-width: 220px;"></div>
                            </div>
                            <div class="ph-col-8 p-0 m-0">
                                <div class="ph-row m-0">
                                    <div class="ph-col-12 big"></div>
                                    <div class="ph-col-12 big p-0 m-0"></div>
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="graficoTiposVenda.length > 0 && !carregandoTiposVenda">
                            <div class="col-5">
                                <ngx-charts-pie-chart [view]="[250,250]" [results]="graficoTiposVenda"
                                    [scheme]="colorSchemeIndicadoresRentabilidade" [animations]="true" [legend]="false"
                                    [labels]="false">
                                </ngx-charts-pie-chart>
                            </div>
                            <div class="col-7">
                                <h3 style="color:#4869a5;" class="text-nowrap">À Vista&nbsp; {{
                                    tiposVenda.valor_total_vendas_a_vista / (tiposVenda.valor_total_vendas_a_vista +
                                    tiposVenda.valor_total_vendas_a_prazo) | percent:'1.2-2':'pt' }}</h3>
                                <h3 style="color:#f39e39;" class="text-nowrap">À Prazo {{
                                    tiposVenda.valor_total_vendas_a_prazo / (tiposVenda.valor_total_vendas_a_vista +
                                    tiposVenda.valor_total_vendas_a_prazo) | percent:'1.2-2':'pt' }}</h3>
                            </div>
                        </div>
                        <div class="row" *ngIf="graficoTiposVenda.length > 0 && !carregandoTiposVenda">
                            <div class="col">
                                <div class="row">
                                    <div class="col">
                                        <div class="card inside-card rounded-0">
                                            <div class="card-body p-2 bg-white">
                                                <h6 class="text-nowrap mb-0">{{ tiposVenda.valor_total_vendas_a_vista |
                                                    currency:'BRL':'symbol':'1.2-2':'pt' }}</h6>
                                                <div class="card-title mb-0 text-nowrap" style="color:#4869a5;">À Vista
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="card inside-card rounded-0">
                                            <div class="card-body p-2 bg-white">
                                                <h6 class="text-nowrap mb-0">{{ tiposVenda.valor_total_vendas_a_prazo |
                                                    currency:'BRL':'symbol':'1.2-2':'pt' }}</h6>
                                                <div class="card-title mb-0 text-nowrap" style="color:#f39e39;">À Prazo
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- TIPOS DE VENDA - FIM -->
        <br />
        <!-- TOP GRUPOS - INICIO -->
        <div class="row">
            <div class="col">
                <div class="card inside-card rounded-0">
                    <div class="card-body p-2 bg-white">
                        <div class="card-title">Top Grupos</div>
                        <div class="ph-item p-2 m-0" *ngIf="carregandoTopGrupos">
                            <div class="ph-col-4 pl-2 p-0 m-0">
                                <div class="ph-avatar mt-2 ml-1" style="max-width: 220px;"></div>
                            </div>
                            <div class="ph-col-8 pl-4 p-0 m-0">
                                <div class="ph-row m-0">
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12 p-0 m-0"></div>
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="graficoTopGrupos.length > 0 && !carregandoTopGrupos">
                            <div class="col recuar-chart">
                                <ngx-charts-pie-chart (window:resize)="resizeGraficoTopGrupos()"
                                    [view]="viewGraficoTopGrupos" [results]="graficoTopGrupos"
                                    [scheme]="colorSchemeTopGrupos" [legendTitle]="'Legenda'" [showDataLabel]="true"
                                    [animations]="true" [legend]="true" [labels]="false">
                                </ngx-charts-pie-chart>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- TOP GRUPOS - FIM -->
        <br />
        <!-- TOP FABRICANTES - INICIO -->
        <div class="row">
            <div class="col">
                <div class="card inside-card rounded-0">
                    <div class="card-body p-2 bg-white">
                        <div class="card-title">Top Fabricantes</div>
                        <div class="ph-item p-2 m-0" *ngIf="carregandoTopFabricantes">
                            <div class="ph-col-4 pl-2 p-0 m-0">
                                <div class="ph-avatar mt-2 ml-1" style="max-width: 220px;"></div>
                            </div>
                            <div class="ph-col-8 pl-4 p-0 m-0">
                                <div class="ph-row m-0">
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12 p-0 m-0"></div>
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="graficoTopFabricantes.length > 0 && !carregandoTopFabricantes">
                            <div class="col recuar-chart">
                                <ngx-charts-pie-chart (window:resize)="resizeGraficoTopFabricantes()"
                                    [view]="viewGraficoTopFabricantes" [results]="graficoTopFabricantes"
                                    [scheme]="colorSchemeTopFabricantes" [legendTitle]="'Legenda'"
                                    [showDataLabel]="true" [animations]="true" [outerRadius]="40" [legend]="true"
                                    [labels]="false">
                                </ngx-charts-pie-chart>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- TOP FABRICANTES - FIM -->
        <br />
        <!-- DESEMPENHO DOS CAIXAS - INICIO -->
        <div class="row">
            <div class="col">
                <div class="card inside-card rounded-0">
                    <div class="card-body p-2 bg-white">
                        <div class="card-title">Desempenho dos Caixas</div>
                        <div class="ph-item p-2 m-0" *ngIf="carregandoDesempenhoCaixas">
                            <div class="ph-col-12 p-0 m-0">
                                <div class="ph-row m-0">
                                    <div class="ph-col-12 big"></div>
                                    <div class="ph-col-10 big"></div>
                                    <div class="ph-col-2 big empty"></div>
                                    <div class="ph-col-8 big"></div>
                                    <div class="ph-col-4 big empty"></div>
                                    <div class="ph-col-6 big"></div>
                                    <div class="ph-col-6 big empty"></div>
                                    <div class="ph-col-4 big"></div>
                                    <div class="ph-col-8 big empty"></div>
                                    <div class="ph-col-4 big"></div>
                                    <div class="ph-col-8 big empty"></div>
                                    <div class="ph-col-2 big p-0 m-0"></div>
                                    <div class="ph-col-10 big empty p-0 m-0"></div>
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="graficoDesempenhoCaixas.length > 0 && !carregandoDesempenhoCaixas">
                            <div class="col">
                                <ngx-charts-bar-horizontal (window:resize)="resizeGraficoDesempenhoCaixas()"
                                    [view]="viewGraficoDesempenhoCaixas" [results]="graficoDesempenhoCaixas"
                                    [scheme]="colorSchemeDesempenhoCaixas" [showDataLabel]="true" [gradient]="false"
                                    [xAxis]="true" [yAxis]="true" [legend]="false" [showXAxisLabel]="false"
                                    [showYAxisLabel]="false" [xAxisTickFormatting]="formatacaoValor">
                                </ngx-charts-bar-horizontal>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- DESEMPENHO DOS CAIXAS - FIM -->
        <br />
        <!-- CONDIÇÕES DE PAGAMENTO - INICIO -->
        <div class="row">
            <div class="col">
                <div class="card inside-card rounded-0">
                    <div class="card-body p-2 bg-white">
                        <div class="card-title">Tabela de Preço</div>
                        <div class="ph-item p-2 m-0" *ngIf="carregandoCondicaoPagamento">
                            <div class="ph-col-12 p-0 m-0">
                                <div class="ph-row m-0">
                                    <div class="ph-col-12 big"></div>
                                    <div class="ph-col-10 big"></div>
                                    <div class="ph-col-2 big empty"></div>
                                    <div class="ph-col-8 big"></div>
                                    <div class="ph-col-4 big empty"></div>
                                    <div class="ph-col-8 big"></div>
                                    <div class="ph-col-4 big empty"></div>
                                    <div class="ph-col-6 big"></div>
                                    <div class="ph-col-6 big empty"></div>
                                    <div class="ph-col-4 big"></div>
                                    <div class="ph-col-8 big empty"></div>
                                    <div class="ph-col-4 big"></div>
                                    <div class="ph-col-8 big empty"></div>
                                    <div class="ph-col-2 big p-0 m-0"></div>
                                    <div class="ph-col-10 big empty p-0 m-0"></div>
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="graficoCondicaoPagamento.length > 0 && !carregandoCondicaoPagamento">
                            <div class="col">
                                <ngx-charts-bar-horizontal (window:resize)="resizeGraficoCondicaoPagamento()"
                                    [view]="viewGraficoCondicaoPagamento" [results]="graficoCondicaoPagamento"
                                    [scheme]="colorSchemeCondicaoPagamento" [showDataLabel]="true" [gradient]="false"
                                    [xAxis]="true" [yAxis]="true" [legend]="false" [showXAxisLabel]="false"
                                    [showYAxisLabel]="false" [xAxisTickFormatting]="formatacaoValor">
                                </ngx-charts-bar-horizontal>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- CONDIÇÕES DE PAGAMENTO - FIM -->
        <br />
        <!-- TOP VENDEDORES - INICIO -->
        <div class="row">
            <div class="col">
                <div class="card inside-card rounded-0">
                    <div class="card-body p-2 bg-white">
                        <div class="card-title">Top Vendedores</div>
                        <div class="ph-item p-2 m-0" *ngIf="carregandoTopVendedores">
                            <div class="ph-col-12 p-0 m-0">
                                <div class="ph-row m-0">
                                    <div class="ph-col-12 big"></div>
                                    <div class="ph-col-12 big"></div>
                                    <div class="ph-col-10 big"></div>
                                    <div class="ph-col-2 big empty"></div>
                                    <div class="ph-col-10 big"></div>
                                    <div class="ph-col-2 big empty"></div>
                                    <div class="ph-col-8 big"></div>
                                    <div class="ph-col-4 big empty"></div>
                                    <div class="ph-col-8 big"></div>
                                    <div class="ph-col-4 big empty"></div>
                                    <div class="ph-col-6 big"></div>
                                    <div class="ph-col-6 big empty"></div>
                                    <div class="ph-col-4 big"></div>
                                    <div class="ph-col-8 big empty"></div>
                                    <div class="ph-col-4 big"></div>
                                    <div class="ph-col-8 big empty"></div>
                                    <div class="ph-col-2 big"></div>
                                    <div class="ph-col-10 big empty"></div>
                                    <div class="ph-col-2 big p-0 m-0"></div>
                                    <div class="ph-col-10 big empty p-0 m-0"></div>
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="graficoTopVendedores.length > 0 && !carregandoTopVendedores">
                            <div class="col">
                                <ngx-charts-bar-horizontal (window:resize)="resizeGraficoTopVendedores()"
                                    [view]="viewGraficoTopVendedores" [results]="graficoTopVendedores"
                                    [scheme]="colorSchemeTopVendedores" [showDataLabel]="true" [gradient]="false"
                                    [xAxis]="true" [yAxis]="true" [legend]="false" [showXAxisLabel]="false"
                                    [showYAxisLabel]="false" [xAxisTickFormatting]="formatacaoValor">
                                </ngx-charts-bar-horizontal>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- TOP VENDEDORES - FIM -->
        <br />
        <!-- VENDA DOS ULTÍMOS 12 MESES - INICIO -->
        <div class="row">
            <div class="col">
                <div class="card inside-card rounded-0">
                    <div class="card-body p-2 bg-white">
                        <div class="card-title">Vendas dos Últimos 12 Meses</div>
                        <div class="ph-item p-2 m-0" *ngIf="carregandoVendasUltimos12Meses">
                            <div class="ph-col-12 p-0 m-0">
                                <div class="ph-row m-0">
                                    <div class="ph-col-12 big"></div>
                                    <div class="ph-col-12 big"></div>
                                    <div class="ph-col-12 big"></div>
                                    <div class="ph-col-12 big"></div>
                                    <div class="ph-col-12 big"></div>
                                    <div class="ph-col-12 big"></div>
                                    <div class="ph-col-12 big"></div>
                                    <div class="ph-col-12 big"></div>
                                    <div class="ph-col-12 big"></div>
                                    <div class="ph-col-12 big"></div>
                                    <div class="ph-col-12 big p-0 m-0"></div>
                                </div>
                            </div>
                        </div>
                        <div class="row"
                            *ngIf="graficoVendasUltimos12Meses.length > 0 && !carregandoVendasUltimos12Meses">
                            <div class="col">
                                <ngx-charts-line-chart (window:resize)="resizeGraficoVendasUltimos12Meses()"
                                    [view]="viewGraficoVendasUltimos12Meses" [results]="graficoVendasUltimos12Meses"
                                    [scheme]="colorSchemeVendasUltimos12Meses" [showDataLabel]="true" [legend]="false"
                                    [showXAxisLabel]="true" [showYAxisLabel]="true" [xAxis]="true" [yAxis]="true"
                                    [xAxisLabel]="'Período'" [yAxisLabel]="'Valor'" [timeline]="false"
                                    [yAxisTickFormatting]="formatacaoValor">
                                </ngx-charts-line-chart>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- VENDA DOS ULTÍMOS 12 MESES - FIM -->
    </div>
</div>
<!-- INDICADORES DE RENTABILIDADE E TIPOS DE VENDA E TOP GRUPOS E TOP FABRICANTES E DESEMPENHO DOS CAIXAS E CONDIÇÕES DE PAGAMENTO E TOP VENDEDORES E VENDA DOS ULTÍMOS 12 MESES - SMALL - FIM -->

<!-- INDICADORES DE RENTABILIDADE E TIPOS DE VENDA E TOP GRUPOS E TOP FABRICANTES E DESEMPENHO DOS CAIXAS E CONDIÇÕES DE PAGAMENTO E TOP VENDEDORES E VENDA DOS ULTÍMOS 12 MESES - XSMALL - INICIO -->
<div class="row" *ngIf="(isXSmall)">
    <div class="col">
        <!-- INDICADORES DE RENTABILIDADE - INICIO -->
        <div class="row">
            <div class="col">
                <div class="card inside-card rounded-0">
                    <div class="card-body p-2 bg-white">
                        <div class="card-title">Indicadores de Rentabilidade</div>
                        <div class="ph-item p-2 m-0" *ngIf="carregandoIndicadoresRentabilidade">
                            <div class="ph-col-6">
                                <div class="ph-avatar" style="max-width: 220px;"></div>
                            </div>
                            <div class="ph-col-6 p-0 m-0">
                                <div class="ph-row m-0">
                                    <div class="ph-col-12 big mt-3"></div>
                                    <div class="ph-col-12 big"></div>
                                </div>
                            </div>
                            <div class="ph-col-12 p-0 m-0">
                                <div class="ph-row m-0">
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12 p-0 m-0"></div>
                                </div>
                            </div>
                        </div>
                        <div class="row"
                            *ngIf="graficoIndicadoresRentabilidade.length > 0 && !carregandoIndicadoresRentabilidade">
                            <div class="col-xs-5">
                                <ngx-charts-pie-chart [view]="[150,150]" [results]="graficoIndicadoresRentabilidade"
                                    [scheme]="colorSchemeIndicadoresRentabilidade" [animations]="true" [doughnut]="true"
                                    [arcWidth]="0.25" [legend]="false" [labels]="false">
                                </ngx-charts-pie-chart>
                            </div>
                            <div class="col-xs-7">
                                <h5 style="color:#4869a5;">Lucro Bruto {{ (indicadoresRentabilidade.valor_total_liquido
                                    - indicadoresRentabilidade.custo_medio) /
                                    indicadoresRentabilidade.valor_total_liquido | percent:'1.2-2':'pt' }}</h5>
                                <h5 style="color:#f39e39;">
                                    CMV&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {{
                                    indicadoresRentabilidade.custo_medio / indicadoresRentabilidade.valor_total_liquido
                                    | percent:'1.2-2':'pt' }}</h5>
                            </div>
                        </div>
                        <div class="row"
                            *ngIf="graficoIndicadoresRentabilidade.length > 0 && !carregandoIndicadoresRentabilidade">
                            <div class="col-12 flex flex-wrap">
                                <div class="item-meio flex-item-1">
                                    <div class="card mt-2">
                                        <div class="card-body p-2 bg-white">
                                            <h6 class="text-nowrap mb-0">{{ indicadoresRentabilidade.valor_total_liquido
                                                | currency:'BRL':'symbol':'1.2-2':'pt' }}</h6>
                                            <div class="card-title mb-0 text-nowrap">Venda Líquida</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="item-meio flex-item-1 text-center">
                                    <h2 class="material-icons text-maxdata mt-4 font20">remove</h2>
                                </div>
                                <div class="item-meio flex-item-1">
                                    <div class="card mt-2">
                                        <div class="card-body p-2 bg-white">
                                            <h6 class="text-nowrap mb-0">{{ indicadoresRentabilidade.custo_medio |
                                                currency:'BRL':'symbol':'1.2-2':'pt' }}</h6>
                                            <div class="card-title mb-0 text-nowrap">CMV</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="item-meio flex-item-1 text-center">
                                    <h2 class="material-icons mt-4 font20">drag_handle</h2>
                                </div>
                                <div class="item-meio flex-item-1">
                                    <div class="card mt-2">
                                        <div class="card-body p-2 bg-white">
                                            <h6 class="text-nowrap mb-0">{{
                                                (indicadoresRentabilidade.valor_total_liquido -
                                                indicadoresRentabilidade.custo_medio) |
                                                currency:'BRL':'symbol':'1.2-2':'pt' }}</h6>
                                            <div class="card-title mb-0 text-nowrap">Lucro Bruto</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- INDICADORES DE RENTABILIDADE - FIM -->
        <br />
        <!-- TIPOS DE VENDA - INICIO -->
        <div class="row">
            <div class="col">
                <div class="card inside-card rounded-0">
                    <div class="card-body p-2 bg-white">
                        <div class="card-title">Tipo de Venda</div>
                        <div class="ph-item p-2 m-0" *ngIf="carregandoTiposVenda">
                            <div class="ph-col-12">
                                <div class="ph-avatar mt-3 ml-5" style="max-width: 140px;"></div>
                            </div>
                            <div class="ph-col-12 p-0 m-0">
                                <div class="ph-row m-0">
                                    <div class="ph-col-12 big"></div>
                                    <div class="ph-col-12 big p-0 m-0"></div>
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="graficoTiposVenda.length > 0 && !carregandoTiposVenda">
                            <div class="col-5">
                                <ngx-charts-pie-chart [view]="[175,150]" [results]="graficoTiposVenda"
                                    [scheme]="colorSchemeIndicadoresRentabilidade" [animations]="true" [legend]="false"
                                    [labels]="false">
                                </ngx-charts-pie-chart>
                            </div>
                            <div class="col-7">
                                <h5 style="color:#4869a5;" class="text-nowrap">À Vista&nbsp; {{
                                    tiposVenda.valor_total_vendas_a_vista / (tiposVenda.valor_total_vendas_a_vista +
                                    tiposVenda.valor_total_vendas_a_prazo) | percent:'1.2-2':'pt' }}</h5>
                                <h5 style="color:#f39e39;" class="text-nowrap">À Prazo {{
                                    tiposVenda.valor_total_vendas_a_prazo / (tiposVenda.valor_total_vendas_a_vista +
                                    tiposVenda.valor_total_vendas_a_prazo) | percent:'1.2-2':'pt' }}</h5>
                            </div>
                        </div>
                        <div class="row" *ngIf="graficoTiposVenda.length > 0 && !carregandoTiposVenda">
                            <div class="col">
                                <div class="row">
                                    <div class="col">
                                        <div class="card inside-card rounded-0">
                                            <div class="card-body p-2 bg-white">
                                                <h6 class="text-nowrap mb-0">{{ tiposVenda.valor_total_vendas_a_vista |
                                                    currency:'BRL':'symbol':'1.2-2':'pt' }}</h6>
                                                <div class="card-title mb-0 text-nowrap" style="color:#4869a5;">À Vista
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="card inside-card rounded-0">
                                            <div class="card-body p-2 bg-white">
                                                <h6 class="text-nowrap mb-0">{{ tiposVenda.valor_total_vendas_a_prazo |
                                                    currency:'BRL':'symbol':'1.2-2':'pt' }}</h6>
                                                <div class="card-title mb-0 text-nowrap" style="color:#f39e39;">À Prazo
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- TIPOS DE VENDA - FIM -->
        <br />
        <!-- TOP GRUPOS - INICIO -->
        <div class="row">
            <div class="col-xs-12">
                <div class="card inside-card rounded-0">
                    <div class="card-body p-2 bg-white">
                        <div class="card-title">Top Grupos</div>
                        <div class="ph-item p-2 m-0" *ngIf="carregandoTopGrupos">
                            <div class="ph-col-8 pl-2 p-0 m-0">
                                <div class="ph-avatar mt-2 ml-1" style="max-width: 197px;"></div>
                            </div>
                            <div class="ph-col-4 p-0 m-0">
                                <div class="ph-row m-0">
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12 p-0 m-0"></div>
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="graficoTopGrupos.length > 0 && !carregandoTopGrupos">
                            <div class="col recuar-chart">
                                <ngx-charts-pie-chart (window:resize)="resizeGraficoTopGrupos()"
                                    [view]="viewGraficoTopGrupos" [results]="graficoTopGrupos"
                                    [scheme]="colorSchemeTopGrupos" [showDataLabel]="true" [legendTitle]="'Legenda'"
                                    [animations]="true" [legend]="true" [labels]="false">
                                </ngx-charts-pie-chart>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- TOP GRUPOS - FIM -->
        <br />
        <!-- TOP FABRICANTES - INICIO -->
        <div class="row">
            <div class="col">
                <div class="card inside-card rounded-0">
                    <div class="card-body p-2 bg-white">
                        <div class="card-title">Top Fabricantes</div>
                        <div class="ph-item p-2 m-0" *ngIf="carregandoTopFabricantes">
                            <div class="ph-col-8 pl-2 p-0 m-0">
                                <div class="ph-avatar mt-2" style="max-width: 197px;"></div>
                            </div>
                            <div class="ph-col-4 p-0 m-0">
                                <div class="ph-row m-0">
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12"></div>
                                    <div class="ph-col-12 p-0 m-0"></div>
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="graficoTopFabricantes.length > 0 && !carregandoTopFabricantes">
                            <div class="col recuar-chart">
                                <ngx-charts-pie-chart (window:resize)="resizeGraficoTopFabricantes()"
                                    [view]="viewGraficoTopFabricantes" [results]="graficoTopFabricantes"
                                    [scheme]="colorSchemeTopFabricantes" [legendTitle]="'Legenda'"
                                    [showDataLabel]="true" [animations]="true" [outerRadius]="40" [legend]="true"
                                    [labels]="false">
                                </ngx-charts-pie-chart>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- TOP FABRICANTES - FIM -->
        <br />
        <!-- DESEMPENHO DOS CAIXAS - INICIO -->
        <div class="row">
            <div class="col">
                <div class="card inside-card rounded-0">
                    <div class="card-body p-2 bg-white">
                        <div class="card-title">Desempenho dos Caixas</div>
                        <div class="ph-item p-2 m-0" *ngIf="carregandoDesempenhoCaixas">
                            <div class="ph-col-12 p-0 m-0">
                                <div class="ph-row m-0">
                                    <div class="ph-col-12 big"></div>
                                    <div class="ph-col-10 big"></div>
                                    <div class="ph-col-2 big empty"></div>
                                    <div class="ph-col-8 big"></div>
                                    <div class="ph-col-4 big empty"></div>
                                    <div class="ph-col-6 big"></div>
                                    <div class="ph-col-6 big empty"></div>
                                    <div class="ph-col-4 big"></div>
                                    <div class="ph-col-8 big empty"></div>
                                    <div class="ph-col-4 big"></div>
                                    <div class="ph-col-8 big empty"></div>
                                    <div class="ph-col-2 big p-0 m-0"></div>
                                    <div class="ph-col-10 big empty p-0 m-0"></div>
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="graficoDesempenhoCaixas.length > 0 && !carregandoDesempenhoCaixas">
                            <div class="col">
                                <ngx-charts-bar-horizontal (window:resize)="resizeGraficoDesempenhoCaixas()"
                                    [view]="viewGraficoDesempenhoCaixas" [results]="graficoDesempenhoCaixas"
                                    [scheme]="colorSchemeDesempenhoCaixas" [showDataLabel]="true" [gradient]="false"
                                    [xAxis]="true" [yAxis]="true" [legend]="false" [showXAxisLabel]="false"
                                    [showYAxisLabel]="false" [xAxisTickFormatting]="formatacaoValor">
                                </ngx-charts-bar-horizontal>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- DESEMPENHO DOS CAIXAS - FIM -->
        <br />
        <!-- CONDIÇÕES DE PAGAMENTO - INICIO -->
        <div class="row">
            <div class="col">
                <div class="card inside-card rounded-0">
                    <div class="card-body p-2 bg-white">
                        <div class="card-title">Tabela de Preço</div>
                        <div class="ph-item p-2 m-0" *ngIf="carregandoCondicaoPagamento">
                            <div class="ph-col-12 p-0 m-0">
                                <div class="ph-row m-0">
                                    <div class="ph-col-12 big"></div>
                                    <div class="ph-col-10 big"></div>
                                    <div class="ph-col-2 big empty"></div>
                                    <div class="ph-col-8 big"></div>
                                    <div class="ph-col-4 big empty"></div>
                                    <div class="ph-col-6 big"></div>
                                    <div class="ph-col-6 big empty"></div>
                                    <div class="ph-col-4 big"></div>
                                    <div class="ph-col-8 big empty"></div>
                                    <div class="ph-col-4 big"></div>
                                    <div class="ph-col-8 big empty"></div>
                                    <div class="ph-col-2 big p-0 m-0"></div>
                                    <div class="ph-col-10 big empty p-0 m-0"></div>
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="graficoCondicaoPagamento.length > 0 && !carregandoCondicaoPagamento">
                            <div class="col">
                                <ngx-charts-bar-horizontal (window:resize)="resizeGraficoCondicaoPagamento()"
                                    [view]="viewGraficoCondicaoPagamento" [results]="graficoCondicaoPagamento"
                                    [scheme]="colorSchemeCondicaoPagamento" [showDataLabel]="true" [gradient]="false"
                                    [xAxis]="true" [yAxis]="true" [legend]="false" [showXAxisLabel]="false"
                                    [showYAxisLabel]="false" [xAxisTickFormatting]="formatacaoValor">
                                </ngx-charts-bar-horizontal>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- CONDIÇÕES DE PAGAMENTO - FIM -->
        <br />
        <!-- TOP VENDEDORES - INICIO -->
        <div class="row">
            <div class="col">
                <div class="card inside-card rounded-0">
                    <div class="card-body p-2 bg-white">
                        <div class="card-title">Top Vendedores</div>
                        <div class="ph-item p-2 m-0" *ngIf="carregandoTopVendedores">
                            <div class="ph-col-12 p-0 m-0">
                                <div class="ph-row m-0">
                                    <div class="ph-col-12 big"></div>
                                    <div class="ph-col-12 big"></div>
                                    <div class="ph-col-10 big"></div>
                                    <div class="ph-col-2 big empty"></div>
                                    <div class="ph-col-8 big"></div>
                                    <div class="ph-col-4 big empty"></div>
                                    <div class="ph-col-6 big"></div>
                                    <div class="ph-col-6 big empty"></div>
                                    <div class="ph-col-4 big"></div>
                                    <div class="ph-col-8 big empty"></div>
                                    <div class="ph-col-2 big p-0 m-0"></div>
                                    <div class="ph-col-10 big empty p-0 m-0"></div>
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="graficoTopVendedores.length > 0 && !carregandoTopVendedores">
                            <div class="col">
                                <ngx-charts-bar-horizontal (window:resize)="resizeGraficoTopVendedores()"
                                    [view]="viewGraficoTopVendedores" [results]="graficoTopVendedores"
                                    [scheme]="colorSchemeTopVendedores" [showDataLabel]="true" [gradient]="false"
                                    [xAxis]="true" [yAxis]="true" [legend]="false" [showXAxisLabel]="false"
                                    [showYAxisLabel]="false" [xAxisTickFormatting]="formatacaoValor">
                                </ngx-charts-bar-horizontal>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- TOP VENDEDORES - FIM -->
        <br />
        <!-- VENDA DOS ULTÍMOS 12 MESES - INICIO -->
        <div class="row">
            <div class="col">
                <div class="card inside-card rounded-0">
                    <div class="card-body p-2 bg-white">
                        <div class="card-title">Vendas dos Últimos 12 Meses</div>
                        <div class="ph-item p-2 m-0" *ngIf="carregandoVendasUltimos12Meses">
                            <div class="ph-col-12 p-0 m-0">
                                <div class="ph-row m-0">
                                    <div class="ph-col-12 big"></div>
                                    <div class="ph-col-12 big"></div>
                                    <div class="ph-col-12 big"></div>
                                    <div class="ph-col-12 big"></div>
                                    <div class="ph-col-12 big"></div>
                                    <div class="ph-col-12 big"></div>
                                    <div class="ph-col-12 big"></div>
                                    <div class="ph-col-12 big"></div>
                                    <div class="ph-col-12 big"></div>
                                    <div class="ph-col-12 big p-0 m-0"></div>
                                </div>
                            </div>
                        </div>
                        <div class="row"
                            *ngIf="graficoVendasUltimos12Meses.length > 0 && !carregandoVendasUltimos12Meses">
                            <div class="col">
                                <ngx-charts-line-chart (window:resize)="resizeGraficoVendasUltimos12Meses()"
                                    [view]="viewGraficoVendasUltimos12Meses" [results]="graficoVendasUltimos12Meses"
                                    [scheme]="colorSchemeVendasUltimos12Meses" [showDataLabel]="true" [legend]="false"
                                    [showXAxisLabel]="true" [showYAxisLabel]="true" [xAxis]="true" [yAxis]="true"
                                    [xAxisLabel]="'Período'" [yAxisLabel]="'Valor'" [timeline]="false"
                                    [yAxisTickFormatting]="formatacaoValor">
                                </ngx-charts-line-chart>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- VENDA DOS ULTÍMOS 12 MESES - FIM -->
    </div>
</div>
<!-- INDICADORES DE RENTABILIDADE E TIPOS DE VENDA E TOP GRUPOS E TOP FABRICANTES E DESEMPENHO DOS CAIXAS E CONDIÇÕES DE PAGAMENTO E TOP VENDEDORES E VENDA DOS ULTÍMOS 12 MESES - XSMALL - FIM -->