import { NgModule } from "@angular/core";
import { Routes, RouterModule } from '@angular/router';

import { InicioComponent } from './inicio/inicio.component';
import { EmpresaComponent } from './cadastro/empresa/empresa.component';
import { EmpresaCadastroComponent } from './cadastro/empresa/empresa-cadastro/empresa-cadastro.component';
import { UsuarioComponent } from './cadastro/usuario/usuario.component';
import { UsuarioCadastroComponent } from './cadastro/usuario/usuario-cadastro/usuario-cadastro.component';
import { AdminAuthGuard } from './admin.auth.guard';
import { SistemaMenuComponent } from "./cadastro/sistema-menu/sistema-menu.component";

const adminRouterConfig: Routes = [
    
    { path: '', component: InicioComponent, canActivate:[AdminAuthGuard]},
    { path: 'cadastro/empresa', component: EmpresaComponent, canActivate:[AdminAuthGuard]},
    { path: 'cadastro/empresa/novo', component: EmpresaCadastroComponent, canActivate:[AdminAuthGuard]},
    { path: 'cadastro/empresa/editar/:id', component: EmpresaCadastroComponent, canActivate:[AdminAuthGuard]},
    { path: 'cadastro/usuario', component: UsuarioComponent, canActivate:[AdminAuthGuard]},
    { path: 'cadastro/usuario/novo', component: UsuarioCadastroComponent, canActivate:[AdminAuthGuard]},
    { path: 'cadastro/usuario/editar/:id', component: UsuarioCadastroComponent, canActivate:[AdminAuthGuard]},
    { path: 'cadastro/sistema-menu', component: SistemaMenuComponent, canActivate:[AdminAuthGuard]},
    { path: 'cadastro/sistema-menu/novo', component: SistemaMenuComponent, canActivate:[AdminAuthGuard]},
    { path: 'cadastro/sistema-menu/editar/:id', component: SistemaMenuComponent, canActivate:[AdminAuthGuard]},
];

@NgModule({
    imports:[
        RouterModule.forChild(adminRouterConfig)
    ],
    exports:[RouterModule]
})
export class AdminRoutingModule{}