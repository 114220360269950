export class EtiquetaRemoverItem {

    constructor(ordem: number, campo: string, descricao: string) {
        this.ordem = ordem;
        this.campo = campo;
        this.descricao = descricao;
    }

    ordem: number;
    campo: string = '';
    descricao: string = '';

    static listaItens = [
        new EtiquetaRemoverItem(1, "nao_mostrar_valor", "Valor"),
        new EtiquetaRemoverItem(2, "nao_mostrar_codigo_produto", "Código"),
        new EtiquetaRemoverItem(3, "nao_mostrar_cod_fabricante", "Cód. Fabricante"),
        new EtiquetaRemoverItem(4, "nao_mostrar_codigo_ean", "Cód. Barras"),
        new EtiquetaRemoverItem(5, "nao_mostrar_un", "Unidade"),
        new EtiquetaRemoverItem(6, "nao_mostrar_grade", "Grade"),
        new EtiquetaRemoverItem(7, "nao_mostrar_lote", "Lote"),
        new EtiquetaRemoverItem(8, "nao_mostrar_fabricante", "Fabricante"),
        new EtiquetaRemoverItem(9, "nao_mostrar_fantasia", "Fantasia"),
        new EtiquetaRemoverItem(10, "nao_mostrar_descricao", "Descrição"),
    ];
}