import { EcommerceCategoriaViewModel } from "@manager/view-models/cadastro/ecommerce/ecommerce-categoria-view-model";
import { PaginacaoViewModel } from "@manager/view-models/PaginacaoViewModel";

export class EcommerceCategoriaPesquisaViewModel extends EcommerceCategoriaViewModel implements PaginacaoViewModel {
    pagina: number = 1;
    quantidadeRegistrosPagina: number = 20;
    totalRegistros: number = 0;
    totalPaginas: number = 0;
    desativados: boolean = false;
    start?: number = 0;
    order?: string;
}
