import { PaginacaoViewModel } from '../../../PaginacaoViewModel';
import { ProdutoGrupoViewModel } from '../ProdutoGrupoViewModel';

export class ProdutoGrupoPesquisaViewModel extends ProdutoGrupoViewModel implements PaginacaoViewModel {
    pagina: number = 1;
    quantidadeRegistrosPagina: number = 20;
    totalRegistros: number = 0;
    totalPaginas: number = 0;
    desativados: boolean = false;
    start?: number = 0;
    order?: string;
}