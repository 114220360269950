import { PaginacaoViewModel } from '../../../PaginacaoViewModel';
import { ProdutoGradeTamanhoViewModel } from '../produto-grade-tamanho-view-model';

export class ProdutoGradeTamanhoPesquisaViewModel extends ProdutoGradeTamanhoViewModel implements PaginacaoViewModel {
    pagina: number = 1;
    quantidadeRegistrosPagina: number = 20;
    totalRegistros: number = 0;
    totalPaginas: number = 0;
    desativados: boolean = false;
    start?: number = 0;
    order?: string;
}