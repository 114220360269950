import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertMessage } from '@modules/alert.configuration.service';
import { ProdutoGradeDescricaoService } from '@modules/manager/cadastro/estoque/produto-grade-descricao/produto-grade-descricao.service';
import { ProdutoGradeTamanhoService } from '@modules/manager/cadastro/estoque/produto-grade-tamanho/produto-grade-tamanho.service';
import { ProdutoGradeService } from '@modules/manager/cadastro/estoque/produto-grade/produto-grade.service';
import { ProdutoGradeDescricaoPesquisaViewModel } from '@modules/manager/view-models/cadastro/estoque/pesquisa/produto-grade-descricao-pesquisa-view-model';
import { ProdutoGradePesquisaViewModel } from '@modules/manager/view-models/cadastro/estoque/pesquisa/produto-grade-pesquisa-view-model';
import { ProdutoGradeTamanhoPesquisaViewModel } from '@modules/manager/view-models/cadastro/estoque/pesquisa/produto-grade-tamanho-pesquisa-view-model';
import { ListaPaginadaViewModel } from '@modules/manager/view-models/ListaPaginadaViewModel';
import { Uteis } from '@modules/utilizarios/uteis';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-produto-grade-modal',
  templateUrl: './produto-grade-modal.component.html',
  styles: []
})
export class ProdutoGradeModalComponent implements OnInit {

  @Input() modalProdutoGrade: boolean = true;
  @Input() descricao: string;
  @Input() multiSelect: boolean = false;
  @Input() produto_id: number;
  @Output() dados_grade = new EventEmitter();
  @Output() grade_array = new EventEmitter();

  @Output() close_modal = new EventEmitter();

  produto_grade_id: number;

  apenasNumeros = Uteis.apenasNumeros;
  descricao_id: number;
  tamanho_id: number;
  qtde: number;
  vlr_venda: number;

  multi_grade: any[] = [];

  pesquisaProdutoGrade: ProdutoGradePesquisaViewModel = new ProdutoGradePesquisaViewModel();
  pesquisaProdutoGradeDescricao: ProdutoGradeDescricaoPesquisaViewModel = new ProdutoGradeDescricaoPesquisaViewModel();
  pesquisaProdutoGradeTamanho: ProdutoGradeTamanhoPesquisaViewModel = new ProdutoGradeTamanhoPesquisaViewModel();
  listaProdutoGrade: ListaPaginadaViewModel<ProdutoGradePesquisaViewModel> = new ListaPaginadaViewModel<ProdutoGradePesquisaViewModel>();
  listaProdutoGradeHeader: any[];
  listaProdutoGradeDescricao: ListaPaginadaViewModel<ProdutoGradeDescricaoPesquisaViewModel> = new ListaPaginadaViewModel<ProdutoGradeDescricaoPesquisaViewModel>();
  listaProdutoGradeTamanho: ListaPaginadaViewModel<ProdutoGradeTamanhoPesquisaViewModel> = new ListaPaginadaViewModel<ProdutoGradeTamanhoPesquisaViewModel>();
  listaProdutoGradeTamanhoCompleta: any[];
  listaProdutoGradeTamanhoPorDescricao: any[];


  constructor(
    private produtoGrade$: ProdutoGradeService,
    private produtoGradeDescricao$: ProdutoGradeDescricaoService,
    private produtoGradeTamanho$: ProdutoGradeTamanhoService,
    private spinner: NgxSpinnerService,
    private alert: AlertMessage,

  ) { }

  ngOnInit() {

    this.obterListaProdutoGrade();

    this.obterListaProdutoGradeDescricao();

    this.obterListaProdutoGradeTamanho();
  }

  modalClose(item: boolean) {

    this.close_modal.emit(item);

    this.produto_grade_id = undefined;
    this.descricao_id = undefined;
    this.tamanho_id = undefined;
    this.qtde = undefined;
    this.multi_grade = [];
  }

  obterListaProdutoGrade() {
    this.pesquisaProdutoGrade.produto_id = this.produto_id;

    this.produtoGrade$.obterTodos(this.pesquisaProdutoGrade).subscribe(
      retorno => {

        this.listaProdutoGrade.itens = retorno.total > 0 ? retorno.result : [];

        this.multi_grade = [];

        (document.querySelector('[name="modal_produto_grade_codigo_barras"]') as HTMLElement).focus();
      },
      error => this.alert.Danger('Erro', Uteis.ObterErroApi(error))
    );

  }

  adicionarQtdItem(produtoGrade: ProdutoGradePesquisaViewModel, qtd_com: number = null) {
    let idx = this.multi_grade.findIndex(e => e?.id == produtoGrade.id);

    if (idx > -1) qtd_com == null ? this.multi_grade[idx].qtd_com++ : this.multi_grade[idx].qtd_com = qtd_com;

    else this.multi_grade.push({ id: produtoGrade.id, produto: produtoGrade, qtd_com: qtd_com == null ? 1 : qtd_com });
  };

  subtrairQtdItem(produtoGrade: ProdutoGradePesquisaViewModel) {
    let idx = this.multi_grade.findIndex(e => e?.id == produtoGrade.id);

    if (idx == -1) return;

    if (this.multi_grade[idx].qtd_com > 0) this.multi_grade[idx].qtd_com--;
  };

  getOneMultGrade(id: number) {
    return this.multi_grade.find(e => e?.id == id);
  };

  multiplosSelecionados(){
    if(!this.exibir_input_qtd) return;

    this.grade_array.emit(this.multi_grade);

    this.modalProdutoGrade = false;

    this.modalClose(true);
  };

  obterListaProdutoGradeDescricao() {
    this.produtoGradeDescricao$.obterTodos().subscribe(
      (retorno) =>
      (this.listaProdutoGradeDescricao.itens =
        retorno.total > 0 ? retorno.result : []),

      (error) => this.alert.Danger("Erro", Uteis.ObterErroApi(error))
    );
  }

  obterListaProdutoGradeTamanho() {
    this.produtoGradeTamanho$.obterTodos().subscribe(
      (retorno) =>
      (this.listaProdutoGradeTamanho.itens =
        retorno.total > 0 ? retorno.result : []),

      (error) => this.alert.Danger("Erro", Uteis.ObterErroApi(error))
    );
  }

  produtoGradeSelecionada(produtoGrade: ProdutoGradePesquisaViewModel) {

    this.dados_grade.emit(Object.assign({}, produtoGrade));

    this.modalProdutoGrade = false;

    this.modalClose(true);

    /*
    this.pesquisaProdutoGrade.produto_grade_descricao_id = this.descricao_id;
    this.pesquisaProdutoGrade.produto_grade_tamanho_id = this.tamanho_id;
    this.pesquisaProdutoGrade.produto_id = this.produto_id;

    this.produtoGrade$.obterTodos(this.pesquisaProdutoGrade).subscribe(
      retorno => {

        if(!retorno.result[0].id){
          this.alert.Info('Produto', 'Produto sem Estoque ou Grade cadastrada!');
          return;
        }
        else{

          this.produto_grade_id = retorno.total > 0 ? retorno.result[0].id : 0;

          if(!this.produto_grade_id || !this.qtde) return;

      

        }

      },
      error => {
        this.alert.Danger('Erro', Uteis.ObterErroApi(error));
      }

    )*/

  }

  atualizarVlrVenda() {
    this.pesquisaProdutoGrade.produto_grade_descricao_id = this.descricao_id;
    this.pesquisaProdutoGrade.produto_grade_tamanho_id = this.tamanho_id;
    this.pesquisaProdutoGrade.produto_id = this.produto_id;

    this.produtoGrade$.obterTodos(this.pesquisaProdutoGrade).subscribe(
      retorno => {

        if (retorno.result.length > 1) return this.vlr_venda = 0;

        return this.vlr_venda = retorno.result.length > 0 ? retorno.result[0].vlr_venda || 0 : 0;


      }
    )

  }


  get exibir_input_qtd(){ return !!this.multi_grade.find(e => e.qtd_com > 0)};
}