export class ProdutoViewModel {
    id?:                           number;
    descricao?:                    string;
    produto_grupo_id?:             number;
    produto_grupo_descricao?:      string;
    produto_sub_grupo_id?:         number;
    produto_sub_grupo_descricao?:  string;
    produto_fabricante_id?:        number;
    produto_fabricante_descricao?: string;
    produto_cest_id?:              number;
    produto_cest_codigo?:          string;
    produto_ncm_id?:               number;
    produto_ncm_codigo?:           string;
    produto_un_comercial_id?:      number;
    produto_un_comercial_un?:      string;
    produto_un_trib_id?:           number;
    produto_un_trib_un?:           string;
    produto_un_multiplo_id?:       number;
    produto_un_multiplo_un?:       string;
    ecommerce_produto_nome?:       string;
    ecommerce_produto_resumo?:     string;
    ecommerce_produto_descricao?:   string;
    peso?:                         number;
    largura?:                      number;
    altura?:                       number;
    profundidade?:                 number;
    volume?:                       string;
    usa_fracionado?:               number;
    aplicacao?:                    string;
    lista_inventario?:             boolean;
    tipo?:                         'P'| 'S';
    garantia?:                     string;
    data?:                         string;
    usa_balanca?:                  boolean;
    msg?:                          string;
    usa_m2?:                       boolean;
    img_foto?:                     string;
    tipo_controlado?:              number;
    tipo_venda?:                   number;
    usa_grade?:                    boolean;
    usa_lote?:                     boolean;
    usa_kit?:                      boolean;
    usa_conferidor?:               boolean;
    qtd_embalagem?:                number;
    inf_ad_prod?:                  string;
    tipo_sped?:                    string | string[];
    qtd_com_entrada?:              number;
    registro_ms?:                  string;
    apresentacao?:                 string;
    veiculo_novo?:                 boolean;
    cubagem?:                      number;
    usa_m3?:                       boolean;
    produto_classe_id?:            number;
    produto_classe_descricao?:     number;
    altera_valor?:                 boolean;
    aliq_comissao?:                number;
    oferta?:                       boolean;
    vlr_promocao?:                 number;
    promocao_tem_vencimento?:      boolean;
    promocao_data_vencimento?:     string;
    usa_desconto?:                 boolean;
    usa_desconto_max_lanc_auto?:   boolean;
    aliq_desconto_max?:            number;
    qtd_atacarejo?:                number;
    usa_desconto_atacado?:         boolean;
    usa_multiplo_embalagem?:       boolean;

    }
