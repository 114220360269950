import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { URLBASE } from 'src/modules/configuration.service';

import { AutenticacaoService } from 'src/modules/autenticacao/services/autenticacao.service';

import { RetornoApiViewModel } from 'src/modules/manager/view-models/RetornoApiViewModel';
import { CadastroViewModel } from 'src/modules/admin/view-models/cadastro/CadastroViewModel';
import { PesquisaGeralViewModel } from '@modules/admin/view-models/pesquisa/PesquisaGeralViewModel';

import { PlanoContaPesquisaViewModel } from 'src/modules/manager/view-models/cadastro/financeiro/pesquisa/plano-conta-pesquisa-view-model';
import { PlanoContaViewModel } from 'src/modules/manager/view-models/cadastro/financeiro/plano-conta-view-model';

@Injectable({
  providedIn: 'root'
})
export class PlanoContaService {
  private idEmpresaMaxdata = this.autenticacaoService.acessoUsuarioValue?.idEmpresaMaxdata;
  private urlGet = `${URLBASE}/${this.idEmpresaMaxdata}/plano_conta_service/get`;
  private urlAddUpdate = `${URLBASE}/${this.idEmpresaMaxdata}/plano_conta_service/addupdate`;

  constructor(private http: HttpClient, private autenticacaoService: AutenticacaoService) { }

  obterTodos(pesquisa?: PlanoContaPesquisaViewModel): Observable<RetornoApiViewModel<PlanoContaPesquisaViewModel[]>> {
    
    let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel()

    pesquisaGeral.select = 'plano_conta.id, plano_conta.descricao, plano_conta.tipo, plano_conta.ativo';

    pesquisaGeral.calcRowCount = 1;

    pesquisaGeral.limit = pesquisa?.quantidadeRegistrosPagina || 0;

    pesquisaGeral.where = `and plano_conta.ativo = ${pesquisa?.desativados ? 0 : 1}`;

    pesquisaGeral.start = pesquisa?.start || 0;

    pesquisaGeral.order = pesquisa?.order ? pesquisa?.order : 'plano_conta.id'

    if (pesquisa?.id) pesquisaGeral.where += ` and plano_conta.id = ${pesquisa?.id}`;
    
    if (pesquisa?.descricao) pesquisaGeral.where += ` and lower(descricao) like lower('%${pesquisa?.descricao}%')`;

      return this.http.post<RetornoApiViewModel<PlanoContaPesquisaViewModel[]>>(this.urlGet, pesquisaGeral)
  }

  obterPorId(id: number): Observable<RetornoApiViewModel<PlanoContaViewModel[]>> {

    let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel()

    pesquisaGeral.select = 'plano_conta.id, plano_conta.descricao, plano_conta.tipo, plano_conta.ativo';
    
    pesquisaGeral.where = ` and plano_conta.id = ${id}`;

    return this.http.post<RetornoApiViewModel<PlanoContaViewModel[]>>(`${this.urlGet}`, pesquisaGeral);
  }

  adicionar(planoConta: CadastroViewModel<PlanoContaViewModel>): Observable<RetornoApiViewModel<PlanoContaViewModel>> {
      return this.http.post<RetornoApiViewModel<PlanoContaViewModel>>(this.urlAddUpdate, planoConta);
  }

  atualizar(id: number, planoConta: CadastroViewModel<PlanoContaViewModel>): Observable<RetornoApiViewModel<PlanoContaViewModel>> {
      return this.http.put<RetornoApiViewModel<PlanoContaViewModel>>(`${this.urlAddUpdate}/${id}`, planoConta);
  }
}