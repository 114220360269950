import { Component, OnInit, ViewChildren, ElementRef, TemplateRef } from '@angular/core';
import { TituloPagina } from 'src/modules/utilizarios/tituloPaginas/tituloPaginas';
import { HistoricoNavegacao } from 'src/modules/utilizarios/tituloPaginas/historicoNavegacao';
import { FormControlName, FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { utilsBr } from 'js-brasil';
import { ValidationMessages, GenericValidator, DisplayMessage } from 'src/modules/validacao/generic-form-validation';
import { UsuarioService } from '../usuario.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UsuarioViewModel } from 'src/modules/admin/view-models/cadastro/UsuarioViewModel';
import { Observable, fromEvent, merge } from 'rxjs';
import { CustomValidators } from 'ng2-validation';
import { MensagensValidacao } from 'src/modules/validacao/mensagensValidacao';
import { isNumber } from 'util';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { UsuarioEmpresaViewModel } from 'src/modules/admin/view-models/cadastro/UsuarioEmpresaViewModel';
import { ConfigModal } from 'src/modules/utilizarios/ConfigModal';
import { EmpresaService } from '../../empresa/empresa.service';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { EmpresaViewModel } from 'src/modules/admin/view-models/cadastro/EmpresaViewModel';
import { UsuarioEmpresaService } from '../../usuario-empresa/usuario-empresa.service';
import { AlertMessage } from 'src/modules/alert.configuration.service';
import { Uteis } from 'src/modules/utilizarios/uteis';
import { CadastroViewModel } from 'src/modules/admin/view-models/cadastro/CadastroViewModel';
import { retryWhen } from 'rxjs/operators';
import { PesquisaGeralViewModel } from '@modules/admin/view-models/pesquisa/PesquisaGeralViewModel';
import { UsuarioEmpresaPesquisaViewModel } from '@modules/admin/view-models/pesquisa/UsuarioEmpresaPesquisaViewModel';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertMensagemPadrao } from '@modules/utilizarios/alertMensagemPadrao';
import { EmpresaPesquisaViewModel } from '../../../view-models/pesquisa/EmpresaPesquisaViewModel';

@Component({
  selector: 'app-usuario-cadastro',
  templateUrl: './usuario-cadastro.component.html',
  styleUrls: []
})
export class UsuarioCadastroComponent implements OnInit {

  tituloPagina: TituloPagina = new TituloPagina('Usuários - Novo',
    [
      new HistoricoNavegacao('Início', '/admin'),
      new HistoricoNavegacao('Cadastro'),
      new HistoricoNavegacao('Usuários', '/admin/cadastro/usuario'),
      new HistoricoNavegacao('Novo'),
    ]);

  @ViewChildren(FormControlName, { read: ElementRef }) formInputElements!: ElementRef[];

  cadastroForm!: FormGroup;
  objUsuario: CadastroViewModel<UsuarioViewModel> = new CadastroViewModel<UsuarioViewModel>();
  objUsuarioEmpresa: CadastroViewModel<UsuarioEmpresaViewModel> = new CadastroViewModel<UsuarioEmpresaViewModel>();
  alterar: boolean = false;
  MASKS = utilsBr.MASKS;
  usuarioEmpresaRemover: UsuarioEmpresaViewModel = new UsuarioEmpresaViewModel();
  isCollapsed: boolean = false;
  usuarioEmpresaPesquisaViewModel: UsuarioEmpresaPesquisaViewModel = new UsuarioEmpresaPesquisaViewModel();
  listaUsuariosEmpresas: UsuarioEmpresaViewModel[] = [];

  // Autocomplete
  noResult = false;
  dataSourceAutocomplete: Observable<any>
  usuarioEmpresa: UsuarioEmpresaViewModel = new UsuarioEmpresaViewModel();

  validationMessages!: ValidationMessages;
  genericValidator!: GenericValidator;
  displayMessage: DisplayMessage = {};

  modalExcluirUsuarioEmpresaRef: BsModalRef = new BsModalRef;

  carregando: boolean = false;
  carregandoAutocomplete: boolean = false;
  btnAdicionarEmp: boolean = false;
  btnRemoverEmp: boolean = false;

  pesquisaEmpresa: EmpresaPesquisaViewModel = new EmpresaPesquisaViewModel();
  pesquisaUsuarioEmpresa: UsuarioEmpresaPesquisaViewModel = new UsuarioEmpresaPesquisaViewModel();

  constructor(private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private usuario$: UsuarioService,
    private empresa$: EmpresaService,
    private modal$: BsModalService,
    private usuarioEmpresa$: UsuarioEmpresaService,
    private alert: AlertMessage,
    private spinner: NgxSpinnerService) {

    this.messagesValidations();

    this.dataSourceAutocomplete = Observable.create((observer: any) => {

      this.carregandoAutocomplete = true;

      this.pesquisaEmpresa = Object.assign({ descricao: this.usuarioEmpresa.descricao })

      this.empresa$.obterTodos(this.pesquisaEmpresa).subscribe(retorno => {

        retorno.result = retorno.total > 0 ? retorno.result : [];

        observer.next(retorno.result);

        this.carregandoAutocomplete = false;
      })
    })
  }

  openModalExcluirUsuarioEmpresaRef(template: TemplateRef<any>, oUsuarioEmpresa: UsuarioEmpresaViewModel) {

    this.usuarioEmpresaRemover = oUsuarioEmpresa
    this.modalExcluirUsuarioEmpresaRef = this.modal$.show(template, ConfigModal.modalBloqueado);
  }

  closeModalExcluirUsuarioEmpresaRef() {
    this.modalExcluirUsuarioEmpresaRef.hide()
  }

  ngOnInit() {

    this.criarFormulario();

    this.route.params.subscribe(params => {

      let id = parseInt(params['id']);

      this.alterar = isNumber(id) && id > 0;

      if (this.alterar) {

        this.spinner.show();

        this.usuario$.obterPorId(id).subscribe(
          retorno => {
    
            if(retorno.total <= 0) this.alert.Danger('Erro', 'Usuário não encontrado');

            this.objUsuario.obj = retorno.total > 0 ? retorno.result[0] : new UsuarioViewModel();
            
            this.obterUsuarioEmpresas(this.objUsuario.obj?.id || 0);

            this.criarFormulario();

            this.spinner.hide();
          },
          error => {

            this.spinner.hide();

            this.alert.Danger('Erro', Uteis.ObterErroApi(error));

          }
        )
      }
    });

  }

  ngAfterViewInit(): void {
    let controlBlurs: Observable<any>[] = this.formInputElements
      .map((formControl: ElementRef) => fromEvent(formControl.nativeElement, 'blur'));

    merge(...controlBlurs).subscribe(() => {
      this.displayMessage = this.genericValidator.processarMensagens(this.cadastroForm);
    });
  }

  messagesValidations() {
    this.validationMessages = {
      id: {
        required: MensagensValidacao.Obrigatorio('Código'),
        min: MensagensValidacao.ValorMinimo('Código', 0)
      },
      
      email: {
        required: MensagensValidacao.Obrigatorio('Email'),
        email: MensagensValidacao.FormatoInvalido('Email')
      },
      senha_hash: {
        required: MensagensValidacao.Obrigatorio('Senha'),
        rangeLength: 'A senha deve possuir entre 1 e 50 caracteres'
      }
    };
    this.genericValidator = new GenericValidator(this.validationMessages);
  }

  criarFormulario() {

    let senha_hash = new FormControl(!this.alterar ? '' : this.objUsuario.obj?.senha_hash, [Validators.required, CustomValidators.rangeLength([1, 50])]);

    this.cadastroForm = this.formBuilder.group({
      id: [{ value: !this.alterar ? 0 : this.objUsuario.obj?.id, disabled: true }],
      email: [!this.alterar ? '' : this.objUsuario.obj?.email, [Validators.required, Validators.email]],
      senha_hash: senha_hash,
      dt_cadastro: [!this.alterar ? Uteis.DateCadastroToString(new Date()) : this.objUsuario.obj?.dt_cadastro],
      ativo: [!this.alterar ? true : this.objUsuario.obj?.ativo],
      chk_usu_adm: [!this.alterar ? false : this.objUsuario.obj?.chk_usu_adm],
      refresh_token: [!this.alterar ? '' : this.objUsuario.obj?.refresh_token],
      chk_usa_sistema: [!this.alterar ? '' : this.objUsuario.obj?.chk_usa_sistema]
    });
  }

  limparForm() {
    this.cadastroForm.reset({
      id: 0,
      email: '',
      senha_hash: '',
      ativo: true,
      chk_usu_adm: false,
      refresh_token: ''
    });
    (document.querySelector('[name="email"]') as HTMLElement).focus();
  }

  onSubmit() {

    this.cadastroForm.markAllAsTouched();

    this.displayMessage = this.genericValidator.processarMensagens(this.cadastroForm);

    if (!this.cadastroForm.valid) {

      this.mensagemCamposInvalidos(this.displayMessage);

      return
    }

    if (!this.cadastroForm.dirty || !this.cadastroForm.valid) {

      this.alert.Info(AlertMensagemPadrao.formularioNaoAlterado.titulo, AlertMensagemPadrao.formularioNaoAlterado.mensagem);

      return
    };

    this.atualizarDadosObjeto();

    this.carregando = true;

    if (this.alterar) this.atualizar();
    else this.adicionar();
  }

  atualizarDadosObjeto() {
    this.objUsuario.obj = Object.assign({}, this.objUsuario.obj, this.cadastroForm.value);
  }

  atualizar() {

    if (this.objUsuario.obj?.id && this.objUsuario.obj?.id > 0)

      this.usuario$.atualizar(this.objUsuario.obj?.id, this.objUsuario).subscribe(
        retorno => {

          this.mensagensCadastro();
          
          this.router.navigate(['/admin/cadastro/usuario']);

          this.carregando = false;

        }, 
        error => {

          this.carregando = false;

          this.alert.Danger('Erro', Uteis.ObterErroApi(error));

        }
      )
  }

  adicionar() {

    this.usuario$.adicionar(this.objUsuario).subscribe(
      retorno => {

        this.mensagensCadastro();

        this.limparForm();

        this.carregando = false;

      },
      error => {

        this.carregando = false;

        this.alert.Danger('Erro', Uteis.ObterErroApi(error));

      }
    )
  }

  obterUsuarioEmpresas(usuario_id: number) {

    this.pesquisaUsuarioEmpresa = Object.assign({ id_usuario: usuario_id, quantidadeRegistrosPagina: 0 })

    this.usuarioEmpresa$.obterTodos(this.pesquisaUsuarioEmpresa).subscribe(
      retorno => {

        this.listaUsuariosEmpresas = retorno.total > 0 ? retorno.result : [];

        if (this.listaUsuariosEmpresas) {

          this.listaUsuariosEmpresas.sort((a, b) => {

            if (a.id_empresa && b.id_empresa) return a.id_empresa - b.id_empresa
            else return 0;
          })

        }
      },
      error => this.alert.Danger('Erro', Uteis.ObterErroApi(error)));
  }

  pesquisarEmpresaPorID() {

    if (this.usuarioEmpresa.id_empresa && this.usuarioEmpresa.id_empresa > 0)

    this.pesquisaEmpresa = Object.assign({ id: this.usuarioEmpresa.id_empresa })

    this.empresa$.obterTodos(this.pesquisaEmpresa).subscribe(
      retorno => {

        this.usuarioEmpresa.id_usuario = this.objUsuario.obj?.id;

        this.usuarioEmpresa.id_empresa = retorno.total > 0 ? retorno.result[0].id : 0;

        this.usuarioEmpresa.id_maxdata = retorno.total > 0 ? retorno.result[0].id_maxdata : 0;

        this.usuarioEmpresa.descricao = retorno.total > 0 ? retorno.result[0].descricao : '';

        (document.getElementById('nomeEmpresa') as HTMLElement).focus();

      }, 
      error => this.alert.Danger('Erro', Uteis.ObterErroApi(error))
    );
  }

  pesquisarEmpresaPorCodigoMaxdata() {

    if (this.usuarioEmpresa.id_maxdata && this.usuarioEmpresa.id_maxdata > 0)

    this.pesquisaEmpresa = Object.assign({ id_maxdata: this.usuarioEmpresa.id_maxdata })

    this.empresa$.obterTodos(this.pesquisaEmpresa).subscribe(
      retorno => {

        this.usuarioEmpresa.id_usuario = this.objUsuario.obj?.id;

        this.usuarioEmpresa.id_empresa = retorno.total > 0 ? retorno.result[0].id : 0;

        this.usuarioEmpresa.id_maxdata = retorno.total > 0 ? retorno.result[0].id_maxdata : 0;

        this.usuarioEmpresa.descricao = retorno.total > 0 ? retorno.result[0].descricao : '';

        (document.getElementById('nomeEmpresa') as HTMLElement).focus();

      }, 
      error => this.alert.Danger('Erro', Uteis.ObterErroApi(error))
    );
  }

  onSelect(event: TypeaheadMatch): void {

    // Populando objeto de UsuarioeEmpresa para adicionar
    this.usuarioEmpresa.id_usuario = this.objUsuario.obj?.id;

    this.usuarioEmpresa.id_empresa = event.item.id;

    this.usuarioEmpresa.descricao = event.item.descricao;

  };

  typeaheadNoResults(event: boolean): void {
    if (this.usuarioEmpresa.descricao) this.noResult = event;
  };

  adicionarUsuarioEmpresa() {

    this.btnAdicionarEmp = true;

    let copyUsuarioEmpresa = { ...this.usuarioEmpresa };

    this.objUsuarioEmpresa.obj = copyUsuarioEmpresa;

    delete this.objUsuarioEmpresa.obj.descricao;

    this.usuarioEmpresa$.adicionar(this.objUsuarioEmpresa).subscribe(
      retorno => {

        if (retorno) {

          if (this.objUsuario.obj?.id && this.objUsuario.obj?.id > 0) this.obterUsuarioEmpresas(this.objUsuario.obj?.id);

          this.alert.Success('Sucesso', `Acesso do usuário à Empresa <b>${this.usuarioEmpresa.descricao}</b> foi Adicionada com Sucesso.`);

          this.usuarioEmpresa = new UsuarioEmpresaViewModel();

          this.objUsuarioEmpresa.obj = new UsuarioEmpresaViewModel();

          (document.getElementById('codigoEmpresa') as HTMLElement).focus();

          this.btnAdicionarEmp = false;
        }
      }, 
      error => {

        this.btnAdicionarEmp = false;

        this.usuarioEmpresa = new UsuarioEmpresaViewModel();

        (document.getElementById('codigoEmpresa') as HTMLElement).focus();

        this.alert.Danger('Erro', Uteis.ObterErroApi(error));

      }
    )
  };

  removerUsuarioEmpresa() {

    this.btnRemoverEmp = true;

    this.objUsuarioEmpresa.obj = this.usuarioEmpresaRemover;

    if (this.objUsuarioEmpresa.obj?.id && this.objUsuarioEmpresa.obj?.id > 0)
      this.usuarioEmpresa$.removerUsuarioEmpresa(this.objUsuarioEmpresa.obj?.id, this.objUsuarioEmpresa).subscribe(
        retorno => {

          var posicao = this.listaUsuariosEmpresas.findIndex(item => item.id === this.usuarioEmpresaRemover.id);

          this.listaUsuariosEmpresas.splice(posicao, 1);

          this.closeModalExcluirUsuarioEmpresaRef();

          this.alert.Info('Informação', `Acesso do Usuário à Empresa <b>${this.usuarioEmpresaRemover.descricao}</b> foi removido com Sucesso.`);

          this.objUsuarioEmpresa.obj = new UsuarioEmpresaViewModel();

          this.usuarioEmpresaRemover = new UsuarioEmpresaViewModel();

          this.btnRemoverEmp = false;

        },
        error => {

          this.btnRemoverEmp = false;

          this.alert.Danger('Erro', Uteis.ObterErroApi(error))

        }
      )
  };

  mensagemCamposInvalidos(mensagens: any) {

    let camposInvalidos = Uteis.ConverterObjetoToArray(mensagens);

    this.alert.Warning(AlertMensagemPadrao.formularioInvalido.titulo, AlertMensagemPadrao.formularioInvalido.mensagem + '<br><br>' + camposInvalidos.toString().replace(/,/g, ''));
  }

  private mensagensCadastro(): void {

    this.alert.Success('Sucesso', `Usuário ${this.alterar ? 'atualizado' : 'cadastrado'} com Sucesso.`);

    if (this.alterar) return;

    this.alert.Info('Informativo', 'Formulário de cadastro foi limpo para realizar um novo cadastro.', { timeOut: 10000 });
    
  }

}
