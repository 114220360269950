import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CadastroViewModel } from '@modules/admin/view-models/cadastro/CadastroViewModel';
import { PesquisaGeralViewModel } from '@modules/admin/view-models/pesquisa/PesquisaGeralViewModel';
import { AutenticacaoService } from '@modules/autenticacao/services/autenticacao.service';
import { URLBASE, URLIMPRESSAO } from '@modules/configuration.service';
import { ConfiguracaoService } from '@modules/manager/sistema/configuracao/configuracao.service';
import { ConselhoViewModel } from '@modules/manager/view-models/cadastro/clinica/conselho-view-model';
import { ConselhoPesquisaViewModel } from '@modules/manager/view-models/cadastro/clinica/pesquisa/conselho-pesquisa-view-model';
import { RetornoApiViewModel } from '@modules/manager/view-models/RetornoApiViewModel';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class ClinicaConselhoService {

  idEmpresaMaxdata = this.autenticacao$.acessoUsuarioValue?.idEmpresaMaxdata;

  urlGet = `${URLBASE}/${this.idEmpresaMaxdata}/clinica_conselho_service/get`;
  urlAddUpdate = `${URLBASE}/${this.idEmpresaMaxdata}/clinica_conselho_service/addupdate`;
  urlImpressaoRel = `${URLIMPRESSAO}/api/relatorio`;
 
  constructor(private http: HttpClient,
      private autenticacao$: AutenticacaoService,
      private configuracao$: ConfiguracaoService) { }
      obterTodos(pesquisa?: ConselhoPesquisaViewModel, imprimir?: boolean): Observable<RetornoApiViewModel<ConselhoPesquisaViewModel[]>> {
      
        let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel();

        pesquisaGeral.select = `clinica_conselho.id, 
                                clinica_conselho.descricao, 
                                clinica_conselho.ativo, 
                                clinica_conselho.sigla
                                `
                                
        pesquisaGeral.calcRowCount = 1;    

        pesquisaGeral.where = `and clinica_conselho.ativo = ${pesquisa?.desativados ? 0 : 1}`;

        pesquisaGeral.start = imprimir ? 0 : pesquisa?.start || 0;

        pesquisaGeral.limit = imprimir ? 0 : pesquisa?.quantidadeRegistrosPagina || 100;

        pesquisaGeral.order = `${pesquisa?.order ? pesquisa?.order : 'clinica_conselho.id'}`;
    
        if (pesquisa?.id) pesquisaGeral.where += ` and clinica_conselho.id = ${pesquisa?.id}`;
    
        if (pesquisa?.descricao) pesquisaGeral.where += ` and lower(clinica_conselho.descricao) like lower('%${pesquisa?.descricao}%')`; 
        
        if (pesquisa?.sigla) pesquisaGeral.where += ` and clinica_conselho.sigla like lower ('%${pesquisa?.sigla}%')`;  
      
        return this.http.post<RetornoApiViewModel<ConselhoPesquisaViewModel[]>>(this.urlGet, pesquisaGeral)
    }

    adicionar(conselhoViewModel: CadastroViewModel<ConselhoViewModel>): Observable<RetornoApiViewModel<ConselhoViewModel>> {
      return this.http.post<RetornoApiViewModel<ConselhoViewModel>>(this.urlAddUpdate, conselhoViewModel);
  }

  atualizar(id: number, conselhoViewModel: CadastroViewModel<ConselhoViewModel>): Observable<RetornoApiViewModel<ConselhoViewModel>> {
      return this.http.put<RetornoApiViewModel<ConselhoViewModel>>(`${this.urlAddUpdate}/${id}`, conselhoViewModel);
  }

  obterPorId(id: number): Observable<RetornoApiViewModel<ConselhoViewModel[]>> {

    let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel()

    pesquisaGeral.select = 'clinica_conselho.id, clinica_conselho.descricao, clinica_conselho.sigla, clinica_conselho.ativo';

    pesquisaGeral.where = `and clinica_conselho.id = ${id}`;

    return this.http.post<RetornoApiViewModel<ConselhoViewModel[]>>(`${this.urlGet}`, pesquisaGeral);
}

}
