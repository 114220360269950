export class ProdutoCst{

    constructor(codigo: string, descricao: string){
        this.codigo = codigo;
        this.descricao = descricao;
    }
    codigo: string = '';
    descricao: string = '';
    
    static cst1 = [
        new ProdutoCst('0','0 - Nacional, exceto as indicadas nos códigos 3 a 5'),
        new ProdutoCst('1','1 - Estrangeira - Importação direta, exceto a indicada no código 6'),
        new ProdutoCst('2','2 - Estrangeira - Adquirida no mercado interno, exceto a indicada no código 7'),
        new ProdutoCst('3','3 - Nacional, mercadoria ou bem com Conteúdo de Importação superior a 40%'),
        new ProdutoCst('4','4 - Nacional, cuja produção tenha sido feita em conformidade com os processos produtivos básicos de que tratam o Decreto-Lei nº 288/1967 , e as Leis nºs 8.248/1991, 8.387/1991, 10.176/2001 e 11.484/2007'),
        new ProdutoCst('5','5 - Nacional, mercadoria ou bem com Conteúdo de Importação inferior ou igual a 40%'),
        new ProdutoCst('6','6 - Estrangeira - Importação direta, sem similar nacional, constante em lista de Resolução Camex e gás natural'),
        new ProdutoCst('7','7 - Estrangeira - Adquirida no mercado interno, sem similar nacional, constante em lista de Resolução Camex e gás natural'),
        new ProdutoCst('8','8 - Nacional - mercadoria ou bem com Conteúdo de Importação superior a 70%'),
    ]

    static cst2 = [
        new ProdutoCst('00','00 - Tributada integralmente'),
        new ProdutoCst('10','10 - Tributada e com cobrança do ICMS por substituição tributária'),
        new ProdutoCst('20','20 - Com redução de base de cálculo'),
        new ProdutoCst('30','30 - Isenta ou não tributada e com cobrança do ICMS por substituição tributária'),
        new ProdutoCst('40','40 - Isenta'),
        new ProdutoCst('41','41 - Não tributada'),
        new ProdutoCst('50','50 - Suspensão'),
        new ProdutoCst('51','51 - Diferimento'),
        new ProdutoCst('60','60 - ICMS cobrado anteriormente por substituição tributária'),
        new ProdutoCst('70','70 - Com redução de base de cálculo e cobrança do ICMS por substituição tributária'),
        new ProdutoCst('90','90 - Outras'),
    ]

    static csosn = [
        new ProdutoCst('101','101 - Tributada pelo Simples Nacional com permissão de crédito'),
        new ProdutoCst('102','102 - Tributada pelo Simples Nacional sem permissão de crédito'),
        new ProdutoCst('103','103 - Isenção do ICMS no Simples Nacional para faixa de receita bruta'),
        new ProdutoCst('201','201 - Tributada pelo Simples Nacional com permissão de crédito e com cobrança do ICMS por substituição tributária'),
        new ProdutoCst('202','202 - Tributada pelo Simples Nacional sem permissão de crédito e com cobrança do ICMS por substituição tributária'),
        new ProdutoCst('203','203 - Isenção do ICMS no Simples Nacional para faixa de receita bruta e com cobrança do ICMS por substituição tributária'),
        new ProdutoCst('300','300 - Imune'),
        new ProdutoCst('400','400 - Não tributada pelo Simples Nacional'),
        new ProdutoCst('500','500 - ICMS cobrado anteriormente por substituição tributária (substituído) ou por antecipação'),
        new ProdutoCst('900','900 - Outros'),
    ]

}