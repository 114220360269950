import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PesquisaGeralViewModel } from '@modules/admin/view-models/pesquisa/PesquisaGeralViewModel';
import { AutenticacaoService } from '@modules/autenticacao/services/autenticacao.service';
import { URLBASE } from '@modules/configuration.service';
import { RetornoApiViewModel } from '@modules/manager/view-models/RetornoApiViewModel';
import { Observable } from 'rxjs';
import { FretePesquisaViewModel } from './../../../view-models/cadastro/servico/pesquisa/frete-pesquisa-view-model';
import { CadastroViewModel } from './../../../../admin/view-models/cadastro/CadastroViewModel';
import { FreteViewModel } from '@modules/manager/view-models/cadastro/servico/frete-view-model';

@Injectable({
  providedIn: 'root'
})
export class FreteService {

  pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel();

  idEmpresaMaxdata = this.autenticacaoService$.acessoUsuarioValue?.idEmpresaMaxdata;

  urlAPI = `${URLBASE}/${this.idEmpresaMaxdata}/frete_service`;

  constructor(    
    private http$: HttpClient, 
    private autenticacaoService$: AutenticacaoService
    ) { }

  obterTodos(pesquisa: FretePesquisaViewModel): Observable<RetornoApiViewModel<FretePesquisaViewModel[]>>{
  
    this.pesquisaGeral.select = `frete.*, endereco_municipio_inicial.descricao as endereco_municipio_inicial_descricao, 
    endereco_municipio_final.descricao as endereco_municipio_final_descricao, endereco_uf_inicial.sigla as endereco_uf_inicial_sigla, 
    endereco_uf_final.sigla as endereco_uf_final_sigla`;

    this.pesquisaGeral.calcRowCount = 1;

    this.pesquisaGeral.limit = pesquisa.quantidadeRegistrosPagina || 100;

    this.pesquisaGeral.where = `and frete.ativo = ${pesquisa?.desativados ? 0 : 1} `;

    this.pesquisaGeral.start = pesquisa.start || 0;

    if (pesquisa?.id) this.pesquisaGeral.where +=`and frete.id = ${pesquisa.id} `;

    if (pesquisa?.descricao) this.pesquisaGeral.where += ` and lower(frete.descricao) like lower('%${pesquisa?.descricao}%') `;

    return this.http$.post<RetornoApiViewModel<FretePesquisaViewModel[]>>(`${this.urlAPI}/get`, this.pesquisaGeral);
  }

  obterPorId(id : number): Observable<RetornoApiViewModel<FretePesquisaViewModel[]>>{
  
    this.pesquisaGeral.select = `frete.*, endereco_municipio_inicial.descricao as endereco_municipio_inicial_descricao, 
    endereco_municipio_final.descricao as endereco_municipio_final_descricao, endereco_uf_inicial.sigla as endereco_uf_inicial_sigla, 
    endereco_uf_final.sigla as endereco_uf_final_sigla`;

    this.pesquisaGeral.where = ` and frete.id = ${id}`;
    this.pesquisaGeral.start = 0;

    return this.http$.post<RetornoApiViewModel<FretePesquisaViewModel[]>>(`${this.urlAPI}/get`, this.pesquisaGeral);
  }

  adicionar(freteViewModel: CadastroViewModel<FreteViewModel>): Observable<RetornoApiViewModel<FreteViewModel[]>>{
    return this.http$.post<RetornoApiViewModel<FreteViewModel[]>>(`${this.urlAPI}/addupdate`, freteViewModel);
  }

  atualizar(id: number, freteViewModel: CadastroViewModel<FreteViewModel>): Observable<RetornoApiViewModel<FreteViewModel[]>>{
    return this.http$.put<RetornoApiViewModel<FreteViewModel[]>>(`${this.urlAPI}/addupdate/${id}`, freteViewModel);
  }


}
