export class ProdutoEmpresaViewModel {
    id?: number;   
    emp_id?: number = 0;   
    produto_id?: number;   
    ativo?: boolean = true;  
    qtd_estoque_atual?: number = 0;   
    qtd_estoque_min?: number = 0;   
    vlr_custo?: number = 0;   
    vlr_custo_dolar?: number = 0;   
    vlr_venda?: number;   
    vlr_atacado?: number = 0;   
    codigo?: string = '';   
    localizador?: string = '';   
    prateleira?: string = '';   
    tipo_base_calc?: number = 0;   
    cod_cst1?: number = 0;   
    // codCst2                         :string;   
    // codCst2ProdutorRural            :string;   
    // codCSOSN                        :string;   
    // codCSOSNProdutorRural           :string;   
    // codCst2                         :string;   
    // codCst2ProdutorRural            :string;   
    // codCSOSN                        :string;   
    // codCSOSNProdutorRural           :string;   
    aliq_fp_cred_icms?: number = 0;   
    aliq_fp_cred_pis?: number = 0;   
    aliq_fp_cred_cofins?: number = 0;   
    aliq_fp_ipi?: number = 0;   
    aliq_fp_icms_sub?: number = 0;   
    aliq_fp_frete?: number = 0;   
    aliq_fp_outros_custo?: number = 0;   
    aliq_fp_custo_op?: number = 0;   
    aliq_fp_trib_fed?: number = 0;   
    aliq_fp_outros?: number = 0;   
    aliq_fp_encarg_finan?: number = 0;   
    aliq_fp_icms?: number = 0;   
    aliq_fp_contrib_social?: number = 0;   
    aliq_fp_comissao?: number = 0;   
    aliq_fp_venda_m_lucro?: number = 0;   
    aliq_fp_atacado_m_lucro?: number = 0;   
    aliq_fp_cred_ipi?: number = 0;   
    aliq_fp_icms_deson?: number = 0;   
    aliq_pis?: number = 0;   
    aliq_cofins?: number = 0;   
    aliq_imposto_renda?: number = 0;   
    // aliq_ipi                         :number;   
    // aliq_ipi                         :number;   
    aliq_ii?: number = 0;   
    aliq_fcp?: number = 0;   
    // aliqIcmsExcCons                 :number;   
    // aliqIcmsExcConsqRed             :number;   
    // aliqIcmsExcConsForaUF           :number;   
    // aliqIcmsExcConsRedForaUF        :number;   
    // cfopExcDentroUf                 :number;   
    // cfopExcForaUf                   :number;   
    // aliqIcmsExcCons                 :number;   
    // aliqIcmsExcConsqRed             :number;   
    // aliqIcmsExcConsForaUF           :number;   
    // aliqIcmsExcConsRedForaUF        :number;   
    // cfopExcDentroUf                 :number;   
    // cfopExcForaUf                   :number;   
    imposto_lancar_red_icms_como_desconto?: number = 0;   
    oferta?: boolean = false;  
    vlr_promocao?: number = 0;   
    promocao_tem_vencimento?: boolean = false;  
    promocao_data_vencimento?: string = '';   
    aliq_desconto_max?: number = 0;   
    aliq_comissao?: number = 0;   
    usa_desconto?: boolean = false;  
    altera_valor?: boolean = false;
    altera_descricao?: number = 0;
    vlr_preco_pauta?: number = 0;   
    usa_preco_pauta?: boolean = false;  
    usa_comissao?: boolean = false;  
    aliq_issqn?: number = 0;   
    pontuacao?: number = 0;  
    nao_cont_estoque?: number = 0;   
    qtd_multiplo?: number = 0;   
    vlr_preco_max_cons?: number = 0;   
    aliq_desconto_max_atacado?: number = 0;   
    usa_desconto_atacado?: boolean = false;  
    usa_desconto_max_lanc_auto?: boolean = false;  
    qtd_atacarejo?: number = 0;   
    vlr_desconto_atacarejo?: number = 0;   
    usa_comissao_atacado?: boolean = false;  
    aliq_comissao_atacado?: number = 0;   
    vlr_comissao_entrega?: number = 0;   
    // aliq_diferimento                 :number;   
    // aliq_diferimento                 :number;   
    vlr_preco_sugerido?: number = 0; 
    tributacao_id?: number; 
    chk_ecommerce?: boolean = false;
    ecommerce_categoria_id?: number;
    ecommerce_departamento_id?: number;
    ecommerce_sub_categoria_id?: number;
    id_sku?: string;
    lst_produto_tipo_venda?: number;

    produto_producao_qtd_insumo_fixa?: boolean = false;
    produto_nao_gerar_registro_0210_bloco_k?: boolean = false;
    produto_materia_prima_multiplo_qtd?: number;
    produto_materia_prima_usa_multiplo?: boolean = false;
}


