import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URLBASE } from 'src/modules/configuration.service';
import { AutenticacaoService } from 'src/modules/autenticacao/services/autenticacao.service';
import { PesquisaGeralViewModel } from '@modules/admin/view-models/pesquisa/PesquisaGeralViewModel';
import { Observable } from 'rxjs';
import { RetornoApiViewModel } from '../../view-models/RetornoApiViewModel';
import { CondicaoPagamentoViewModel } from '@modules/manager/view-models/dashboard/vendas/condicao-pagamento-view-model';
import { DesempenhoCaixaViewModel } from '@modules/manager/view-models/dashboard/vendas/desempenho-caixa-view-model';
import { IndicadoresRentabilidadeVendaViewModel } from '@modules/manager/view-models/dashboard/vendas/indicadores-rentabilidade-venda-view-model';
import { TopFabricanteViewModel } from '@modules/manager/view-models/dashboard/vendas/top-fabricante-view-model';
import { TopGrupoViewModel } from '@modules/manager/view-models/dashboard/vendas/top-grupo-view-model';
import { TopVendedorViewModel } from '@modules/manager/view-models/dashboard/vendas/top-vendedor-view-model';
import { TiposVendaViewModel } from '@modules/manager/view-models/dashboard/vendas/tipos-venda-view-model';
import { VendasUltimos12MesesViewModel } from '@modules/manager/view-models/dashboard/vendas/vendas-ultimos12-meses-view-model';

@Injectable({
  providedIn: 'root'
})
export class DashboardVendaService {

  idEmpresaMaxdata = this.autenticacaoService.acessoUsuarioValue?.idEmpresaMaxdata;

  private urlApi = `${URLBASE}/${this.idEmpresaMaxdata}`;
  private urlApiVendaMax = `${this.urlApi}/venda_service`;
  private urlVendaGet = `${this.urlApiVendaMax}/get`;
  private urlVendaGetGroup = `${this.urlApiVendaMax}/getGroup`;
  private urlPgtoGetGroup = `${this.urlApi}/pgto_service/getGroup`;

  constructor(private http: HttpClient, private autenticacaoService: AutenticacaoService) { }

  private novaPesquisaGeral() {
    var pesquisaGeral = new PesquisaGeralViewModel();
    pesquisaGeral.calcRowCount = 1;
    pesquisaGeral.limit = 0;
    pesquisaGeral.start = 0;
    return pesquisaGeral;
  }


  private colunaValorTotalVendaMaisImpostos: string = ` CASE
  WHEN TRIM(COALESCE(venda_item.status, '')) <> '' THEN 0
  ELSE
      (
          COALESCE(venda_item.vlr_icms_subst, 0)
          + COALESCE(venda_item.vlr_frete, 0)
          + COALESCE(venda_item.vlr_seguro, 0)
          + COALESCE(venda_item.vlr_outras_desp, 0)
          + COALESCE(venda_item.vlr_ipi, 0)
          + (
              CASE WHEN venda.tipo = 'DV' THEN 0
                   ELSE COALESCE(venda_item.qtd_com, 0) * COALESCE(venda_item.vlr_un_com, 0)
              END
          )
      )
      - (
          CASE WHEN venda.tipo = 'DV' THEN COALESCE(venda_item.qtd_com, 0) * COALESCE(venda_item.vlr_un_com, 0)
               ELSE 0
          END
      )
END`;
  private colunaSumValorTotalVendaMaisImpostos: string = `SUM(${this.colunaValorTotalVendaMaisImpostos})`;
  private filtrosIniciais: string = `AND operacao.gera_finan = 1
                                     AND operacao.tipo_opr IN (1, 3)
                                     AND venda.status = 'F'
                                     AND venda.tipo IN ('VE','OS','LO','DV')
                                     AND venda.emp_id = ${this.autenticacaoService.acessoUsuarioValue?.idLoja === 0 ? 1 : this.autenticacaoService.acessoUsuarioValue?.idLoja}
                                    `;

  condicaoPagamento(dataInicial: string, dataFinal: string): Observable<RetornoApiViewModel<CondicaoPagamentoViewModel[]>> {
    var pesquisaGeral = this.novaPesquisaGeral();
    pesquisaGeral.select = `TRIM(COALESCE(produto_tabela_preco.descricao, '(SEM TABELA DE PREÇO)')) AS condicao_pagamento_nome
                            ,${this.colunaSumValorTotalVendaMaisImpostos} AS valor_total_liquido_mais_impostos
                            `;
    pesquisaGeral.where = `${this.filtrosIniciais}
                            AND (venda.dt_fechamento BETWEEN ('${dataInicial}T00:00:00') AND ('${dataFinal}T23:59:59'))
                            `;
    pesquisaGeral.group = `TRIM(COALESCE(produto_tabela_preco.descricao, '(SEM TABELA DE PREÇO)'))`;
    pesquisaGeral.order = `valor_total_liquido_mais_impostos DESC`;
    return this.http.post<RetornoApiViewModel<CondicaoPagamentoViewModel[]>>(this.urlVendaGetGroup, pesquisaGeral);
  }

  desempenhoCaixa(dataInicial: string, dataFinal: string): Observable<RetornoApiViewModel<DesempenhoCaixaViewModel[]>> {
    var pesquisaGeral = this.novaPesquisaGeral();
    pesquisaGeral.select = `COALESCE(usu_lancou.nome, '') as caixa_nome,
    SUM(
        CASE
            WHEN pgto.tipo_operacao = 'DV' THEN -COALESCE(pgto.vlr, 0)
            ELSE COALESCE(pgto.vlr, 0)
        END
    ) AS valor_total_liquido_mais_impostos
    
                           `;
    pesquisaGeral.where = `AND pgto.tipo_conta = 'R' 
                            AND pgto.status <> 'C'
                            AND pgto.venda_id in (
                                               SELECT 
                                                 venda.id
                                               FROM venda
                                                 INNER JOIN 
                                                   venda_item
                                                   ON venda_item.venda_id = venda.id
                                                 INNER JOIN 
                                                   operacao 
                                                   ON operacao.id = venda.operacao_id
                                               WHERE 1=1
                                               ${this.filtrosIniciais}
                                               AND (venda.dt_fechamento BETWEEN ('${dataInicial}T00:00:00') AND ('${dataFinal}T23:59:59'))
                             )`;
    pesquisaGeral.group = `COALESCE(usu_lancou.nome, '')`;
    pesquisaGeral.order = `valor_total_liquido_mais_impostos DESC`;
    return this.http.post<RetornoApiViewModel<DesempenhoCaixaViewModel[]>>(this.urlPgtoGetGroup, pesquisaGeral);
  }

  indicadoresRentabilidade(dataInicial: string, dataFinal: string): Observable<RetornoApiViewModel<IndicadoresRentabilidadeVendaViewModel[]>> {
    var pesquisaGeral = this.novaPesquisaGeral();
    pesquisaGeral.select = `SUM(CASE WHEN TRIM(COALESCE(venda_item.status, '')) <> '' THEN 0         ELSE (COALESCE(venda_item.qtd_com, 0) * COALESCE(venda_item.vlr_un_com, 0))  * (CASE WHEN venda.tipo = 'DV'  THEN -1   ELSE 1 - (COALESCE(venda_item.aliq_desconto, 100) / 100) END)  END) AS valor_total_liquido
                           ,${this.colunaSumValorTotalVendaMaisImpostos} AS valor_total_liquido_mais_impostos
                           ,SUM(CASE WHEN TRIM(COALESCE(venda_item.status, '')) <> '' THEN 0      ELSE (CASE WHEN venda.tipo = 'DV' THEN (COALESCE(venda_item.qtd_com, 0) * COALESCE(venda_item.vlr_un_com, 0)) * (COALESCE(venda_item.aliq_desconto, 100) / 100) ELSE 0  END)  END) AS total_desconto                  
                           ,COUNT(DISTINCT CASE WHEN venda.tipo = 'DV' THEN NULL ELSE venda_item.venda_id END) AS quantidade_vendas
                           ,CASE WHEN ${this.colunaSumValorTotalVendaMaisImpostos} > 0 THEN (${this.colunaSumValorTotalVendaMaisImpostos}) / COUNT(DISTINCT venda_item.venda_id) ELSE 0 END AS valor_medio_venda
                           ,SUM((CASE WHEN venda.tipo = 'DV' THEN 0 ELSE COALESCE(produto_empresa.vlr_custo, 0) * COALESCE(venda_item.qtd_com, 0)  END)) AS custo_medio
                 `;
    pesquisaGeral.where = `${this.filtrosIniciais}
                           AND (venda.dt_fechamento BETWEEN ('${dataInicial}T00:00:00') AND ('${dataFinal}T23:59:59'))
                        
                           `;
    pesquisaGeral.limit = 1;
    return this.http.post<RetornoApiViewModel<IndicadoresRentabilidadeVendaViewModel[]>>(this.urlVendaGetGroup, pesquisaGeral);
  }

  tiposVenda(dataInicial: string, dataFinal: string): Observable<RetornoApiViewModel<TiposVendaViewModel[]>> {
    var pesquisaGeral = this.novaPesquisaGeral();
    pesquisaGeral.select = `SUM(   CASE        WHEN pgto.tipo_operacao = 'DV'        THEN 0 - COALESCE(pgto.vlr, 0)  ELSE COALESCE(pgto.vlr, 0)    END) AS valor_total_vendas_a_vista,
    SUM(
      CASE
          WHEN COALESCE(pgto_tipo.chk_prazo, 0) = 1 AND pgto.tipo_operacao = 'DV'
          THEN 0 - COALESCE(pgto.vlr, 0)
          WHEN COALESCE(pgto_tipo.chk_prazo, 0) = 1
          THEN COALESCE(pgto.vlr, 0)
          ELSE 0
      END
  ) AS valor_total_vendas_a_prazo
  
  
                           `;
    pesquisaGeral.where = `AND pgto.tipo_conta = 'R' 
                           AND pgto.status <> 'C'
                           AND pgto.venda_id in (
                                              SELECT 
                                                venda.id
                                              FROM venda
                                                INNER JOIN 
                                                  venda_item
                                                  ON venda_item.venda_id = venda.id
                                                INNER JOIN 
                                                  operacao 
                                                  ON operacao.id = venda.operacao_id
                                              WHERE 1=1
                                              ${this.filtrosIniciais}
                                              AND (venda.dt_fechamento BETWEEN ('${dataInicial}T00:00:00') AND ('${dataFinal}T23:59:59'))
                            )`;
    pesquisaGeral.limit = 1;
    return this.http.post<RetornoApiViewModel<TiposVendaViewModel[]>>(this.urlPgtoGetGroup, pesquisaGeral);
  }

  topFabricantes(dataInicial: string, dataFinal: string): Observable<RetornoApiViewModel<TopFabricanteViewModel[]>> {
    var pesquisaGeral = this.novaPesquisaGeral();
    pesquisaGeral.select = `TRIM(COALESCE(produto_fabricante.descricao, '(SEM FABRICANTE)')) AS fabricante_nome
                           ,${this.colunaSumValorTotalVendaMaisImpostos} AS valor_total_liquido_mais_impostos
                           `;
    pesquisaGeral.where = `${this.filtrosIniciais}
                           AND (venda.dt_fechamento BETWEEN ('${dataInicial}T00:00:00') AND ('${dataFinal}T23:59:59'))
                           `;
    pesquisaGeral.group = `TRIM(COALESCE(produto_fabricante.descricao, '(SEM FABRICANTE)'))`;
    pesquisaGeral.order = `valor_total_liquido_mais_impostos DESC`;
    return this.http.post<RetornoApiViewModel<TopFabricanteViewModel[]>>(this.urlVendaGetGroup, pesquisaGeral);
  }

  topGrupos(dataInicial: string, dataFinal: string): Observable<RetornoApiViewModel<TopGrupoViewModel[]>> {
    var pesquisaGeral = this.novaPesquisaGeral();
    pesquisaGeral.select = `TRIM(COALESCE(produto_grupo.descricao, '(SEM GRUPO)')) AS grupo_nome
                           ,${this.colunaSumValorTotalVendaMaisImpostos} AS valor_total_liquido_mais_impostos
                           `;
    pesquisaGeral.where = `${this.filtrosIniciais}
                          AND (venda.dt_fechamento BETWEEN ('${dataInicial}T00:00:00') AND ('${dataFinal}T23:59:59'))
                          `;
    pesquisaGeral.group = `TRIM(COALESCE(produto_grupo.descricao, '(SEM GRUPO)'))`;
    pesquisaGeral.order = `valor_total_liquido_mais_impostos DESC`;
    return this.http.post<RetornoApiViewModel<TopGrupoViewModel[]>>(this.urlVendaGetGroup, pesquisaGeral);
  }

  topVendedores(dataInicial: string, dataFinal: string): Observable<RetornoApiViewModel<TopVendedorViewModel[]>> {
    var pesquisaGeral = this.novaPesquisaGeral();
    pesquisaGeral.select = `TRIM(COALESCE(usuario.nome, '')) AS vendedor_nome
                           ,${this.colunaSumValorTotalVendaMaisImpostos} AS valor_total_liquido_mais_impostos
                           `;
    pesquisaGeral.where = `${this.filtrosIniciais}
                          AND (venda.dt_fechamento BETWEEN ('${dataInicial}T00:00:00') AND ('${dataFinal}T23:59:59'))
                          `;
    pesquisaGeral.group = `TRIM(COALESCE(usuario.nome, ''))`;
    pesquisaGeral.order = `valor_total_liquido_mais_impostos DESC`;
    return this.http.post<RetornoApiViewModel<TopVendedorViewModel[]>>(this.urlVendaGetGroup, pesquisaGeral);
  }

  vendasUltimos12Meses(): Observable<RetornoApiViewModel<VendasUltimos12MesesViewModel[]>> {
    var pesquisaGeral = this.novaPesquisaGeral();
    pesquisaGeral.select = `DATE_PART('MONTH', venda.dt_fechamento) AS mes
                           ,DATE_PART('YEAR', venda.dt_fechamento) AS ano 
                           ,${this.colunaSumValorTotalVendaMaisImpostos} AS valor_total_liquido_mais_impostos
                           `;
    pesquisaGeral.where = `${this.filtrosIniciais}
                          AND (venda.dt_fechamento BETWEEN (CURRENT_TIMESTAMP - INTERVAL '11 MONTH' ) AND CURRENT_TIMESTAMP)
                          `;
    pesquisaGeral.group = `DATE_PART('MONTH', venda.dt_fechamento), DATE_PART('YEAR', venda.dt_fechamento)`;
    pesquisaGeral.order = `DATE_PART('MONTH', venda.dt_fechamento), DATE_PART('YEAR', venda.dt_fechamento)`;
    return this.http.post<RetornoApiViewModel<VendasUltimos12MesesViewModel[]>>(this.urlVendaGetGroup, pesquisaGeral);
  }

}
