import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertMessage } from '@modules/alert.configuration.service';
import { UsuarioManagerService } from '@modules/manager/cadastro/usuario-manager/usuario-manager.service';
import { UsuarioManagerPesquisaViewModel } from '@modules/manager/view-models/cadastro/usuario-manager/pesquisa/usuario-manager-pesquisa-view-model';
import { Uteis } from '@modules/utilizarios/uteis';

@Component({
  selector: 'app-multiselect-tecnico',
  templateUrl: './multiselect-tecnico.component.html',
  styles: []
})
export class MultiselectTecnicoComponent implements OnInit {
  @Output() tecnicosSelecionados: EventEmitter<any> = new EventEmitter<any>();
  @Input() mostrarDestativados: boolean = true;

  pesquisa: UsuarioManagerPesquisaViewModel = new UsuarioManagerPesquisaViewModel;
  listaTecnicos: UsuarioManagerPesquisaViewModel[] = [];
  tecnicos: UsuarioManagerPesquisaViewModel[] = [];
  tecnicosDesativados: boolean = false;

  constructor(
    private alert: AlertMessage,
    private usuarioManager$: UsuarioManagerService,
  ) { }

  ngOnInit() {

    if(this.tecnicosDesativados) this.pesquisa.desativados = null;
    else this.pesquisa.desativados = false;

    this.usuarioManager$.obterTodos(this.pesquisa).subscribe(
      retorno => {
        this.listaTecnicos = retorno.total > 0 ? retorno.result : [];
        this.listaTecnicos.forEach(item => { if(!item.ativo){ item.nome += " - Desativado" } });
      },
      error => {  
        this.alert.Danger('Erro ao obter Técnicos', Uteis.ObterErroApi(error));  
      }
    );

  }

  enviar(){ 
    this.tecnicosSelecionados.emit(this.tecnicos);
  }

}
