import { PaginacaoViewModel } from '../../../PaginacaoViewModel';
import { ProdutoGradeViewModel } from '../produto-grade-view-model';

export class ProdutoGradePesquisaViewModel extends ProdutoGradeViewModel implements PaginacaoViewModel {
    pagina: number = 1;
    quantidadeRegistrosPagina: number = 20;
    totalRegistros: number = 0;
    totalPaginas: number = 0;
    desativados: boolean = false;
    start?: number = 0;
    order?: string;
    produto_grade_descricao?: string;
    produto_grade_tamanho?: string;
}