export class AgendamentoViewModel {
    id?: number;
    dt_inicial?: string;
    dt_final?: string;
    atendente_id?: number;
    cliente_id?: number;
    status?: string;
    obs?: string;
    enviar_email?: boolean;
    dt_email?: string;
    emp_id?: number;

}