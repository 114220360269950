<p-dialog [(visible)]="abrirFecharProdutoNcmCestCadastroModal" [modal]="true" [closable]="true" [style]="{width: '100vw'}"
    [maximizable]="true" [baseZIndex]="10000" [draggable]="false" [resizable]="false" (onHide)="fecharModalCadastroProdutoNcmCest()">
    <ngx-spinner name="modalAtualiza" bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-clip-rotate"
        [fullScreen]="false"></ngx-spinner>
    <p-header>Adicionar CEST para o NCM</p-header>
        <div class="d-flex justify-content-end">
            <div class="btns-group mb-1">
                <!-- <button class="btn btn-sm btn-outline-secondary" id="processar" type="button"
                    (click)="openModalEmpresaFiltroRef(modalEmpresaFiltroRef)" *ngIf="alterar"><i
                        class="material-icons">toc</i>Empresas</button> -->
                <button class="btn btn-sm btn-outline-secondary" (click)="fecharModalCadastroProdutoNcmCest()"><i
                            class="material-icons" type="button">close</i>Cancelar</button>
                <button class="btn btn-sm btn-outline-info" (click)="pesquisar()" type="button"><i
                                class="material-icons">search</i>Pesquisar</button>
                <!-- <button class="btn btn-sm btn-outline-success" id="salvar" type="submit" [disabled]="carregando" (click)="adicionar()">
                    <div [ngSwitch]="carregando">
                        <div *ngSwitchCase="true">
                            <span class="spinner-border spinner-border-sm" role="status"
                                aria-hidden="true"></span>&nbsp;
                            <span>Carregando ...</span>
                        </div>
                        <div *ngSwitchDefault>
                            <i class="material-icons">done_all</i><span>Adicionar</span>
                        </div>
                    </div>
                </button> -->
            </div>
        </div>
        <div class="card rounded-0">
            <div class="card-body pt-1 pb-1">
                <div class="row">
                    <div class="col-sm-4 col-md-3 col-lg-2 col-xl-1">
                        <div class="form-group">
                            <label for="id" class="col-form-label-sm">Código</label>
                            <input type="number" id="id" name="id" class="form-control form-control-sm noscroll"
                                (keydown.enter)="pesquisar()"
                                placeholder="Código Interno" [(ngModel)]="pesquisa.id">
                        </div>
                    </div>
                    <div class="col-sm-8 col-md-3 col-lg-3 col-xl-2">
                        <div class="form-group">
                            <label for="codigoCest" class="col-form-label-sm">Código CEST</label>
                            <input type="text" id="codigoCest" name="codigoCest" class="form-control form-control-sm noscroll"
                                (keydown.enter)="pesquisar()"
                                placeholder="Código CEST" [(ngModel)]="pesquisa.codigo">
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4">
                        <div class="form-group">
                            <label for="descricao" class="col-form-label-sm">Descricão</label>
                            <input type="text" id="descricao" name="descricao" class="form-control form-control-sm"
                                (keydown.enter)="pesquisar()"
                                placeholder="Pesquise pela Descricão" [(ngModel)]="pesquisa.descricao">
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-body pt-1 pb-1 border-top rounded-0 bg-white">
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <div class="table-responsive-sm">
                            <table class="table table-sm table-borderless table-striped table-hover">
                                <thead class="thead">
                                    <th (click)="ordenarLista('ID')">Código</th>
                                    <th width="214" (click)="ordenarLista('codigo')">Código CEST</th>
                                    <th (click)="ordenarLista('descricao')">Descrição</th>
                                    <th class="text-right"></th>
                                </thead>
        
                                <tbody>
                                    <tr *ngFor="let item of listaPaginada.itens; let i = index;" 
                                    class="cursor" 
                                    (dblclick)="adicionar(item.id)"
                                    (click)="clickRowIndex(i)"
                                    [ngClass]="{'selected-line': (selectedRowIndex || 0) === i }">
                                        <td>{{item.id}}</td>
                                        <td>{{item.codigo}}</td>
                                        <td>{{item.descricao}}</td>
                                        <td class="text-right">
                                            <button class="btn btn-sm btn-success" (click)="adicionar(item.id)" type="button"><i
                                                class="material-icons">add</i></button>
                                        </td>
                                    </tr>
                                </tbody>
        
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</p-dialog>
