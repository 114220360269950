import { Injectable, ɵɵresolveBody } from '@angular/core';
import { HttpClient} from '@angular/common/http';

import { URLBASE } from 'src/modules/configuration.service';
import { Observable } from 'rxjs';
import { ListaPaginadaViewModel } from 'src/modules/manager/view-models/ListaPaginadaViewModel';
import { RetornoApiViewModel } from 'src/modules/manager/view-models/RetornoApiViewModel';
import { Uteis } from 'src/modules/utilizarios/uteis';

import { PesquisaGeralViewModel } from '@modules/admin/view-models/pesquisa/PesquisaGeralViewModel';
import { AutenticacaoService } from 'src/modules/autenticacao/services/autenticacao.service';
import { EnderecoUfPesquisaViewModel } from 'src/modules/manager/view-models/cadastro/cliente-fornecedor/pesquisa/endereco-uf-pesquisa-view-model';
import { EnderecoUfViewModel } from 'src/modules/manager/view-models/cadastro/cliente-fornecedor/endereco-uf-view-model';
import { CadastroViewModel } from 'src/modules/admin/view-models/cadastro/CadastroViewModel';

@Injectable({
  providedIn: 'root'
})
export class EnderecoUfService {

  idEmpresaMaxdata = this.autenticacaoService.acessoUsuarioValue?.idEmpresaMaxdata;
    
    urlGet =  `${URLBASE}/${this.idEmpresaMaxdata}/endereco_uf_service/get`;
    urlAddUpdate  = `${URLBASE}/${this.idEmpresaMaxdata}/endereco_uf_service/addupdate`;

    constructor(private http: HttpClient, 
        private autenticacaoService: AutenticacaoService){ }

    obterTodos(pesquisa?: EnderecoUfPesquisaViewModel) : Observable<RetornoApiViewModel<EnderecoUfPesquisaViewModel[]>> {
      
      let pesquisaGeral:  PesquisaGeralViewModel = new PesquisaGeralViewModel();

      pesquisaGeral.select = `endereco_uf.id,
                            endereco_uf.codigo,
                            endereco_uf.sigla,
                            endereco_uf.descricao,
                            endereco_uf.ativo`;

      pesquisaGeral.calcRowCount = 1;

      pesquisaGeral.start = pesquisa?.start || 0;

      pesquisaGeral.order = pesquisa?.order ? pesquisa?.order : 'sigla';

      pesquisaGeral.limit = pesquisa?.quantidadeRegistrosPagina || 0;

      pesquisaGeral.where = `and endereco_uf.ativo = ${pesquisa?.desativados ? 0 : 1}`;
  
      if (pesquisa?.id) pesquisaGeral.where += ` and endereco_uf.id = ${pesquisa?.id}`;

      if (pesquisa?.codigo) pesquisaGeral.where += ` and endereco_uf.codigo = '${pesquisa?.codigo}'`;
  
      if (pesquisa?.sigla) pesquisaGeral.where += ` and endereco_uf.sigla = '${pesquisa?.sigla}'`;

      if (pesquisa?.descricao) pesquisaGeral.where += ` and lower(endereco_uf.descricao) like lower('%${pesquisa?.descricao}%')`;

      return this.http.post<RetornoApiViewModel<EnderecoUfPesquisaViewModel[]>>(this.urlGet, pesquisaGeral)
    }

    obterPorId(pesquisaGeralViewModel: PesquisaGeralViewModel = new PesquisaGeralViewModel()): Observable<RetornoApiViewModel<EnderecoUfViewModel[]>>{
        return this.http.post<RetornoApiViewModel<EnderecoUfViewModel[]>>(`${this.urlGet}`,pesquisaGeralViewModel);
    }

    // adicionar(enderecoUfViewModel: CadastroViewModel<EnderecoUfViewModel>) : Observable<RetornoApiViewModel<EnderecoUfViewModel>>{
    //     return this.http.post<RetornoApiViewModel<EnderecoUfViewModel>>(this.urlAddUpdate, enderecoUfViewModel);
    // }

    // atualizar(id: number, enderecoUfViewModel: CadastroViewModel<EnderecoUfViewModel>) : Observable<RetornoApiViewModel<EnderecoUfViewModel>>{
    //     return this.http.put<RetornoApiViewModel<EnderecoUfViewModel>>(`${this.urlAddUpdate}/${id}`, enderecoUfViewModel);
    // }

    obterUfSiglaPorCodigo(codigo_uf: number) : string {

      switch(codigo_uf) {
        case 12:
          return "AC"
       
       case 27:
          return "AL"
       
       case 13:
          return "AM"
       
       case 16:
          return "AP"
       
       case 29:
          return "BA"
       
       case 23:
          return "CE"
       
       case 53:
          return "DF"
       
       case 32:
          return "ES"
       
       case 9999999:
          return "EX"
       
       case 52:
          return "GO"
       
       case 21:
          return "MA"
       
       case 31:
          return "MG"
       
       case 50:
          return "MS"
       
       case 51:
          return "MT"
       
       case 15:
          return "PA"
       
       case 25:
          return "PB"
       
       case 26:
          return "PE"
       
       case 22:
          return "PI"
       
       case 41:
          return "PR"
       
       case 33:
          return "RJ"
       
       case 24:
          return "RN"
       
       case 11:
          return "RO"
       case 14:
          return "RR"
       
       case 43:
          return "RS"
       
       case 42:
          return "SC"
       
       case 28:
          return "SE"
       
       case 35:
          return "SP"
       
       case 17:
          return "TO"
       default:
          return ""
      }
    }
}
