import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CadastroViewModel } from '@modules/admin/view-models/cadastro/CadastroViewModel';
import { AlertMessage } from '@modules/alert.configuration.service';
import { ClienteDioptriaArmacaoService } from '@modules/manager/cadastro/cliente-fornecedor/cliente-dioptria-armacao/cliente-dioptria-armacao.service';
import { ClienteDioptriaArmacaoViewModel } from '@modules/manager/view-models/cadastro/cliente-fornecedor/cliente-dioptria-armacao-view-model';
import { AlertMensagemPadrao } from '@modules/utilizarios/alertMensagemPadrao';
import { Uteis } from '@modules/utilizarios/uteis';
import { DisplayMessage, GenericValidator, ValidationMessages } from '@modules/validacao/generic-form-validation';
import { MensagensValidacao } from '@modules/validacao/mensagensValidacao';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-dioptria-tipo-armacao-modal',
  templateUrl: './dioptria-tipo-armacao-modal.component.html',
  styles: [
  ]
})
export class DioptriaTipoArmacaoModalComponent implements OnInit {
  @Input() abrirFecharDioptriaArmacaoModal: boolean = false;
  @Output() closeModal = new EventEmitter();
  cadastroForm!: FormGroup;
  genericValidator!: GenericValidator;
  validationMessages!: ValidationMessages;
  carregando: boolean = false;

  alterar: boolean = false;
  displayMessage: DisplayMessage = {};
  objArmacao: CadastroViewModel<ClienteDioptriaArmacaoViewModel> = new CadastroViewModel<ClienteDioptriaArmacaoViewModel>();
  constructor(
    private fb: FormBuilder,
    private armacao$: ClienteDioptriaArmacaoService,
    private alert: AlertMessage,
    private spinner: NgxSpinnerService

  ) { }

  ngOnInit(): void {
    this.criarFormulario();
  }
  onSubmit() {

    this.cadastroForm.markAllAsTouched();

    // this.displayMessage = this.genericValidator.processarMensagens(this.cadastroForm);

    if (!this.cadastroForm.valid) {

      this.mensagemCamposInvalidos(this.displayMessage);

      return
    }
    if (!this.cadastroForm.dirty || !this.cadastroForm.valid) {

      this.alert.Info(AlertMensagemPadrao.formularioNaoAlterado.titulo, AlertMensagemPadrao.formularioNaoAlterado.mensagem);

      return
    };

    this.carregando = true;

    if (this.alterar) this.atualizar();
    else this.adicionar();
  }

  atualizarDadosObjeto() {
    this.objArmacao.obj = Object.assign({}, this.objArmacao.obj, this.cadastroForm.value);
  }
  atualizar() {

    this.atualizarDadosObjeto();

    // if (this.objArmacao.obj.id && this.objArmacao.obj.id > 0)

    //   this.armacao$.atualizar(this.objArmacao.obj.id, this.objArmacao).subscribe(
    //     retorno => {

    //       this.mensagensCadastro();          


    //       this.carregando = false;

    //     }, error => {
    //       this.carregando = false;

    //       this.alert.Danger('Erro', Uteis.ObterErroApi(error))
    //     });
  }

  limparForm() {
    this.cadastroForm.reset({
      id: 0,
      descricao: '',
      registro: '',
      ativo: true
    });

    (document.querySelector('[name="descricao"]') as HTMLElement).focus();
  }
  adicionar() {

    this.atualizarDadosObjeto();

    this.armacao$.adicionar(this.objArmacao).subscribe(
      retorno => {
        this.limparForm();
        this.mensagensCadastro();
        this.closeModalDioptriaArmacao();




        this.carregando = false;

      },
      error => {
        this.carregando = false;

        this.alert.Danger('Erro', Uteis.ObterErroApi(error))
      });
  }

  private criarFormulario() {
    this.cadastroForm = this.fb.group({
      id: [0, []],
      descricao: ['', [Validators.required, Validators.maxLength(50)]],
      ativo: [true],

    });

  }

  private mensagensCadastro(): void {

    this.alert.Success('Sucesso', `Armação ${this.alterar ? 'atualizado' : 'cadastrado'} com Sucesso.`);

    if (this.alterar) return;


  }
  mensagemCamposInvalidos(mensagens: any) {
    let camposInvalidos = Uteis.ConverterObjetoToArray(mensagens);
    this.alert.Warning(AlertMensagemPadrao.formularioInvalido.titulo, AlertMensagemPadrao.formularioInvalido.mensagem + '<br><br>' + camposInvalidos.toString().replace(/,/g, ''));
  }
  messagesValidations() {
    this.validationMessages = {
      descricao: {
        required: MensagensValidacao.Obrigatorio('Nome'),
        minlength: MensagensValidacao.MinimoCaracteres('Nome', 2),
        maxlength: MensagensValidacao.MaximoCaracteres('Nome', 50)
      }
    };
    this.genericValidator = new GenericValidator(this.validationMessages);
  }
  closeModalDioptriaArmacao() {
    this.closeModal.emit(false);
  }
}
