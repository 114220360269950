import { PaginacaoViewModel } from "@modules/manager/view-models/PaginacaoViewModel";
import { FreteViewModel } from "../frete-view-model";

export class FretePesquisaViewModel extends FreteViewModel implements PaginacaoViewModel {
    pagina: number = 1;
    quantidadeRegistrosPagina: number = 20;
    totalRegistros: number = 0;
    totalPaginas: number = 0;
    desativados: boolean = false;
    start?: number;
    order?: string;
}