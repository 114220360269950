<p-dialog [(visible)]="abrirFecharModalMedico" [modal]="true" [closable]="false" [style]="{width: '100vw'}"
    [maximizable]="true" [baseZIndex]="10000" [draggable]="false" [resizable]="false"
    (onHide)="closeModalCadastroMedico()">

    <ngx-spinner name="modalAtualiza" bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-clip-rotate"
        [fullScreen]="false">
    </ngx-spinner>
    <div class="row  d-flex justify-content-between">
        <div class="col-mb">
            <h1 class="h4 border-left pl-2">
                Formulário Médico
            </h1>
        </div>

        <div class="btns-group">

            <button class="btn btn-sm btn-outline-secondary" (click)="closeModalCadastroMedico()"><i
                    class="material-icons">close</i>Cancelar</button>
            <button class="btn btn-sm btn-outline-success" id="salvar" type="button" (click)="onSubmit()"
                [disabled]="carregando">
                <div [ngSwitch]="carregando">
                    <div *ngSwitchCase="true">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;
                        <span>Carregando ...</span>
                    </div>
                    <div *ngSwitchDefault>
                        <i class="material-icons">done_all</i><span>Salvar</span>
                    </div>
                </div>
            </button>
        </div>


    </div>
    <div class="card-header">Cadastrar Médico</div>

    <form [formGroup]="cadastroForm">

        <div class="card shadow-sm">
            <div class="card-body pt-1 pb-1">
                <div class="row">

                    <div class="col-sm-6 col-md-5 col-lg-3 col-xl-2">
                        <label class="col-form-label-sm custom-select-label">Conselho<span class="text-muted">
                                (Obrigatório)</span></label>
                        <select id="clinica_conselho_id" name="clinica_conselho_id" class="form-control form-control-sm"
                            formControlName="clinica_conselho_id">
                            <option *ngFor="let item of listaConselho" [ngValue]="item.id">
                                {{ item.descricao }}</option>
                        </select>
                    </div>




                    <div class="col-sm-9 col-md-10 col-lg-6 col-xl-4">
                        <div class="form-group">
                            <label for="nome" class="col-form-label-sm">Nome do Médico<span class="text-muted">
                                    (Obrigatório)</span></label>
                            <input type="text" id="nome" name="nome" class="form-control form-control-sm"
                                placeholder="Nome do Médico" autocomplete="off" formControlName="nome"
                                [ngClass]="{'is-invalid': displayMessage.descricao}" />
                            <span class="invalid-feedback" *ngIf="displayMessage.nome">
                                <p [innerHTML]="displayMessage.descricao"></p>
                            </span>
                        </div>
                    </div>
                    <div class="col-sm-5 col-md-6 col-lg-3 col-xl-2">
                        <div class="form-group">
                            <label for="registro" class="col-form-label-sm">Registro<span class="text-muted">
                                    (Obrigatório)</span></label>
                            <input type="text" id="registro" name="registro" class="form-control form-control-sm"
                                placeholder="registro" autocomplete="off" formControlName="registro"
                                [ngClass]="{'is-invalid': displayMessage.registro}" />
                            <span class="invalid-feedback" *ngIf="displayMessage.registro">
                                <p [innerHTML]="displayMessage.registro"></p>
                            </span>
                        </div>
                    </div>

                    <div class="col-sm-6 col-md-5 col-lg-3 col-xl-2">
                        <div class="form-group">
                            <label for="uf" class="col-form-label-sm">Estado UF<span class="text-muted">
                                    (Obrigatório)</span></label>
                            <select id="uf" name="uf" class="form-control form-control-sm" formControlName="uf">
                                <option value="{{item.descricao}}" *ngFor="let item of listaEnderecoUf">
                                    {{item.descricao}}</option>
                            </select>
                        </div>
                    </div>



                </div>
            </div>
        </div>
    </form>

</p-dialog>