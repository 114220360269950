import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CadastroViewModel } from '@modules/admin/view-models/cadastro/CadastroViewModel';
import { PesquisaGeralViewModel } from '@modules/admin/view-models/pesquisa/PesquisaGeralViewModel';
import { AutenticacaoService } from '@modules/autenticacao/services/autenticacao.service';
import { URLBASE } from '@modules/configuration.service';
import { AgendamentoItemViewModel } from '@modules/manager/view-models/movimentacao/agendamento-item-view-model';
import { AgendamentoItemPesquisaViewModel } from '@modules/manager/view-models/movimentacao/pesquisa/agendamento-item-pesquisa-view-model';
import { RetornoApiViewModel } from '@modules/manager/view-models/RetornoApiViewModel';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AgendamentoItemService {

  idEmpresaMaxdata = this.autenticacao$.acessoUsuarioValue?.idEmpresaMaxdata;
  urlAPI =  `${URLBASE}/${this.idEmpresaMaxdata}/agendamento_item_service`;

  constructor(private http: HttpClient, private autenticacao$: AutenticacaoService) { }

  obterTodos(pesquisa: AgendamentoItemPesquisaViewModel) : Observable<RetornoApiViewModel<AgendamentoItemPesquisaViewModel[]>> {

    let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel()

    pesquisaGeral.select = `agendamento_item.*, 
                            produto.descricao as produto_descricao`;

    pesquisaGeral.where = ` and agendamento_item.agendamento_id = ${pesquisa?.agendamento_id}`;

    pesquisaGeral.limit = pesquisa?.quantidadeRegistrosPagina || 100;

    pesquisaGeral.start = pesquisa?.start || 0;

    pesquisaGeral.order = pesquisa?.order ? pesquisa?.order : 'agendamento_item.id';

    if(pesquisa?.id) pesquisaGeral.where += ` and agendamento_item.id = ${pesquisa?.id}`

    if(pesquisa?.produto_id) pesquisaGeral.where += ` and agendamento_item.produto_id = ${pesquisa?.produto_id}`;

    return this.http.post<RetornoApiViewModel<AgendamentoItemPesquisaViewModel[]>>(`${this.urlAPI}/get`,pesquisaGeral)
  }
  
  obterPorId(id: number, agendamento_id: number): Observable<RetornoApiViewModel<AgendamentoItemPesquisaViewModel[]>>{

    let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel()

    pesquisaGeral.select = `agendamento_item.*`;
    
    pesquisaGeral.where = ` and agendamento_item.id = ${id} and agendamento_item.agendamento_id = ${agendamento_id}`;
  
    return this.http.post<RetornoApiViewModel<AgendamentoItemPesquisaViewModel[]>>(`${this.urlAPI}/get`,pesquisaGeral);
  }
  
  adicionar(adicionarViewModel: CadastroViewModel<AgendamentoItemViewModel>) : Observable<RetornoApiViewModel<AgendamentoItemViewModel>>{
  
      return this.http.post<RetornoApiViewModel<AgendamentoItemViewModel>>(`${this.urlAPI}/addupdate`, adicionarViewModel);
  }
  
  atualizar(id: number, atualizarViewModel: CadastroViewModel<AgendamentoItemViewModel>) : Observable<RetornoApiViewModel<AgendamentoItemViewModel>>{
  
      return this.http.put<RetornoApiViewModel<AgendamentoItemViewModel>>(`${this.urlAPI}/addupdate/${id}`, atualizarViewModel);
  }

  remover(id: number, removerViewModel: CadastroViewModel<AgendamentoItemViewModel>) {
    return this.http.request('delete',`${this.urlAPI}/delete/${id}`, {body: removerViewModel});
  }

}
