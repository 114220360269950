import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertMessage } from '@modules/alert.configuration.service';
import { AutenticacaoService } from '@modules/autenticacao/services/autenticacao.service';
import { EquipamentoService } from '@modules/manager/cadastro/servico/equipamento/equipamento.service';
import { ConfiguracaoService } from '@modules/manager/sistema/configuracao/configuracao.service';
import { EquipamentoPesquisaViewModel } from '@modules/manager/view-models/cadastro/servico/pesquisa/equipamento-pesquisa-view-model';
import { Uteis } from '@modules/utilizarios/uteis';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-equipamento-pesquisa-modal',
  templateUrl: './equipamento-pesquisa-modal.component.html',
  styles: [
  ]
})
export class EquipamentoPesquisaModalComponent implements OnInit {

  @Input() abrirFecharPesquisaEquipamentoModal: boolean = false;
  @Input() stringPesquisa: string = '';
  @Output() closeModal = new EventEmitter();
  @Output() equipamento: EventEmitter<any> = new EventEmitter();

  pesquisa: EquipamentoPesquisaViewModel = new EquipamentoPesquisaViewModel();
  listaEquipamentos: EquipamentoPesquisaViewModel[] = [];

  constructor(private equipamento$: EquipamentoService,
    private alert: AlertMessage,
    private spinner: NgxSpinnerService,
    private configuracao$: ConfiguracaoService,
    private autenticacao$: AutenticacaoService) { }

  ngOnInit() {

    setTimeout(() => {

      (document.querySelector(`[name="modal_descricao"]`) as HTMLElement).focus();

      this.pesquisa.descricao = this.stringPesquisa;

    }, 100);
  }
  pesquisar() {

    this.spinner.show();

    this.equipamento$.obterTodos(this.pesquisa).subscribe(
      retorno => {

        this.listaEquipamentos = retorno.total > 0 ? retorno.result : [];

        this.spinner.hide();
      },
      error => {

        this.alert.Danger('Erro', Uteis.ObterErroApi(error));

        this.spinner.hide();

      }
    );
  }

  equipamentoSelecionado(item) {
    this.equipamento.emit(item);
  }

  closeModalEquipamento() {
    this.closeModal.emit(false);
  }
}
