import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MultiSelectModule } from 'primeng/multiselect';
import { MultiselectAtendenteComponent } from './multiselect-atendente/multiselect-atendente.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SelectUfCidadeComponent } from './select-uf-cidade/select-uf-cidade.component';
import { InputCodigoClienteComponent } from './input-codigo-cliente/input-codigo-cliente.component';
import { InputCodigoProdutoComponent } from './input-codigo-produto/input-codigo-produto.component';
import { MultiselectPlanoContaComponent } from './multiselect-plano-conta/multiselect-plano-conta.component';
import { MultiselectEmpresaComponent } from './multiselect-empresa/multiselect-empresa.component';
import { SelectOperacaoComponent } from './select-operacao/select-operacao.component';
import { MultiselectFabricanteComponent } from './multiselect-fabricante/multiselect-fabricante.component';
import { MultiselectGrupoComponent } from './multiselect-grupo/multiselect-grupo.component';
import { MultiselectSubGrupoComponent } from './multiselect-sub-grupo/multiselect-sub-grupo.component';
import { DialogModule } from "primeng/dialog";
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { MultiselectTecnicoComponent } from './multiselect-tecnico/multiselect-tecnico.component';
import { CheckboxModule } from 'primeng/checkbox';
import { PaginacaoComponent } from './paginacao/paginacao.component';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ImpressaoVendaModalComponent } from './impressao-venda-modal/impressao-venda-modal.component';
import { LoandingComponent } from './loanding/loanding.component';
import { MaterialModule } from '@modules/utilizarios/material.module';
import {ProgressSpinnerModule} from 'primeng/progressspinner';

@NgModule({
  imports: [
    CommonModule,
    DialogModule,
    FormsModule,
    ReactiveFormsModule,
    MultiSelectModule,
    TooltipModule.forRoot(),
    CheckboxModule,
    PaginationModule.forRoot(),
    MaterialModule,
    ProgressSpinnerModule

  ],
  declarations: [
    MultiselectAtendenteComponent,
    SelectUfCidadeComponent,
    SelectOperacaoComponent,
    InputCodigoClienteComponent,
    InputCodigoProdutoComponent,
    MultiselectPlanoContaComponent,
    MultiselectEmpresaComponent,
    MultiselectFabricanteComponent,
    MultiselectGrupoComponent,
    MultiselectSubGrupoComponent,
    MultiselectTecnicoComponent,
    PaginacaoComponent,
    ImpressaoVendaModalComponent,
    LoandingComponent
  ],
  exports: [
    MultiselectAtendenteComponent,
    SelectUfCidadeComponent,
    SelectOperacaoComponent,
    InputCodigoClienteComponent,
    InputCodigoProdutoComponent,
    MultiselectPlanoContaComponent,
    MultiselectEmpresaComponent,
    MultiselectFabricanteComponent,
    MultiselectGrupoComponent,
    MultiselectSubGrupoComponent,
    MultiselectTecnicoComponent,
    PaginacaoComponent,
    ImpressaoVendaModalComponent,
    LoandingComponent,

  ]
})
export class TemplatesModule { }
