<form (ngSubmit)="onSubmit()" [formGroup]="cadastroForm">
    <div class="row">
        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <app-titulo-paginas [tituloPagina]="tituloPagina"></app-titulo-paginas>
        </div>
        <div
            class="col-sm-12 col-md-6 col-lg-6 col-xl-6 text-sm-right text-md-right text-lg-right text-xl-right btns-group">
            <a class="btn btn-sm btn-outline-secondary" href="javascript:window.history.back();"><i
                    class="material-icons">arrow_back</i>Voltar</a>
            <button class="btn btn-sm btn-outline-success" [disabled]="carregando">
                <div [ngSwitch]="carregando">
                    <div *ngSwitchCase="true">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;
                        <span>Carregando ...</span>
                    </div>
                    <div *ngSwitchDefault>
                        <i class="material-icons">done_all</i><span>Salvar</span>
                    </div>
                </div>
            </button>
        </div>
    </div>
    <div>
        <p-tabView>
            <p-tabPanel header="Dados do Usuário" class="first">
                <div class="card rounded-0 border border-top-0">
                    <div class="card-body pt-1 pb-1">
                        <div class="row">
                            <div class="col-sm-4 col-md-4 col-lg-2 col-xl-1">
                                <div class="form-group">
                                    <label for="id" class="col-form-label-sm">Código</label>
                                    <input type="number" id="id" name="id" class="form-control form-control-sm noscroll"
                                        placeholder="Código" [formControlName]="'id'">
                                </div>
                            </div>
                            <div class="col-sm-8 col-md-8 col-lg-4 col-xl-3" *ngIf="alterar">
                                <div class="form-group">
                                    <label for="dt_cadastro" class="col-form-label-sm">Data Cadastro</label>
                                    <label class="card card-block input_fake">{{ objUsuario.obj?.dt_cadastro | date:
                                        'dd/MM/yyyy' }}</label>
                                </div>
                            </div>
                            <div class="col-sm-8 col-md-6 col-lg-4 col-xl-3">
                                <div class="form-group">
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" id="admin" name="admin"
                                            class="custom-control-input form-control-sm"
                                            formControlName="chk_usu_adm" />
                                        <label class="custom-control-label col-form-label-sm status-filtro"
                                            for="admin">Usuário Adminstrador</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-8 col-md-6 col-lg-4 col-xl-3">
                                <div class="form-group">
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" id="chk_usa_sistema" name="chk_usa_sistema"
                                            class="custom-control-input form-control-sm"
                                            formControlName="chk_usa_sistema" />
                                        <label class="custom-control-label col-form-label-sm status-filtro"
                                            for="chk_usa_sistema">Usuário do Sistema</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-4 col-md-6 col-lg-2 col-xl-1">
                                <div class="form-group">
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" id="ativo" name="ativo"
                                            class="custom-control-input form-control-sm" formControlName="ativo" />
                                        <label class="custom-control-label col-form-label-sm status-filtro"
                                            for="ativo">Ativo</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12 col-md-8 col-lg-6 col-xl-4">
                                <div class="form-group">
                                    <label for="email" class="col-form-label-sm">E-mail</label>
                                    <input *ngIf="!alterar" type="text" id="email" name="email"
                                        class="form-control form-control-sm" placeholder="E-mail do Usuário"
                                        autocomplete="off" formControlName="email"
                                        [ngClass]="{'is-invalid': displayMessage.email}" />
                                    <span class="invalid-feedback" *ngIf="displayMessage.email">
                                        <p [innerHTML]="displayMessage.email"></p>
                                    </span>
                                    <label *ngIf="alterar" class="card card-block input_fake">{{ objUsuario.obj?.email
                                        }}</label>
                                </div>
                            </div>
                            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2" *ngIf="!alterar">
                                <div class="form-group">
                                    <label for="senha" class="col-form-label-sm">Senha</label>
                                    <input type="password" id="senha" name="senha" class="form-control form-control-sm"
                                        placeholder="Senha" autocomplete="off" formControlName="senha_hash"
                                        [ngClass]="{'is-invalid': displayMessage.senha_hash}" />
                                    <span class="invalid-feedback" *ngIf="displayMessage.senha_hash">
                                        <p [innerHTML]="displayMessage.senha_hash"></p>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12" *ngIf="alterar">
                                <div class="form-group">
                                    <button type="button" class="btn btn-sm btn-outline-secondary"
                                        (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed"
                                        aria-controls="collapseBasic">Alterar Senha</button>
                                </div>
                                <div id="collapseBasic" [collapse]="!isCollapsed" [isAnimated]="true">
                                    <div class="row">
                                        <div class="col-sm-4 col-md-3 col-lg-2 col-xl-2" *ngIf="alterar">
                                            <div class="form-group">
                                                <label for="senha" class="col-form-label-sm">Senha</label>
                                                <input type="password" id="senha" name="senha"
                                                    class="form-control form-control-sm" placeholder="Senha"
                                                    autocomplete="off" formControlName="senha_hash"
                                                    [ngClass]="{'is-invalid': displayMessage.senha_hash}" />
                                                <span class="invalid-feedback" *ngIf="displayMessage.senha_hash">
                                                    <p [innerHTML]="displayMessage.senha_hash"></p>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </p-tabPanel>
            <p-tabPanel header="Empresas">
                <div class="card rounded-0 border border-top-0 pb-3">
                    <div class="card-body">
                        <form>
                            <div class="form-row align-items-center">
                                <div class="col-sm-4 col-md-3 col-lg-2 col-xl-1">
                                    <div class="form-group">
                                        <label for="codigoEmpresa" class="col-form-label-sm">Cód. interno</label>
                                        <input type="number" id="codigoEmpresa" name="codigoEmpresa"
                                            class="form-control form-control-sm noscroll" step="1" autocomplete="off"
                                            placeholder="Código" (keydown.enter)="pesquisarEmpresaPorID()"
                                            [(ngModel)]="usuarioEmpresa.id_empresa">
                                    </div>
                                </div>
                                <div class="col-sm-4 col-md-3 col-lg-2 col-xl-1">
                                    <div class="form-group">
                                        <label for="id_maxdata" class="col-form-label-sm">Cód. Maxdata</label>
                                        <input type="number" id="id_maxdata" name="id_maxdata"
                                            class="form-control form-control-sm noscroll" step="1" autocomplete="off"
                                            placeholder="Código" (keydown.enter)="pesquisarEmpresaPorCodigoMaxdata()" name
                                            [(ngModel)]="usuarioEmpresa.id_maxdata" [ngModelOptions]="{standalone: true}">
                                    </div>
                                </div>
                                <div class="col-sm-8 col-md-6 col-lg-4 col-xl-4">
                                    <div class="form-group">
                                        <label for="nomeEmpresa" class="col-form-label-sm">Nome &nbsp;
                                            <span *ngIf="carregandoAutocomplete"
                                                class="spinner-border spinner-border-sm text-primary spinner-autocomplete"
                                                role="status" aria-hidden="true"></span>
                                        </label>
                                        <input id="nomeEmpresa" name="nomeEmpresa" class="form-control form-control-sm"
                                            placeholder="Pesquise uma empresa pelo nome" autocomplete="off"
                                            [(ngModel)]="usuarioEmpresa.descricao" [typeahead]="dataSourceAutocomplete"
                                            typeaheadOptionField="descricao" (typeaheadOnSelect)="onSelect($event)"
                                            [typeaheadAsync]="true" [isAnimated]="false" [typeaheadMinLength]="1"
                                            [typeaheadScrollable]="true" [typeaheadOptionsInScrollableView]="5"
                                            [adaptivePosition]="true" (typeaheadNoResults)="typeaheadNoResults($event)"
                                            [ngClass]="{'is-invalid': noResult}">
                                    </div>
                                </div>
                                <div class="col-sm-4 col-md-1 col-lg-1 col-xl-2">
                                    <button class="btn btn-outline-dark btn-sm mt-2" type="button"
                                        (click)="adicionarUsuarioEmpresa()" [disabled]="btnAdicionarEmp">
                                        <div [ngSwitch]="btnAdicionarEmp">
                                            <div *ngSwitchCase="true">
                                                <span class="spinner-border spinner-border-sm" role="status"
                                                    aria-hidden="true"></span>&nbsp;
                                                <span>Carregando ...</span>
                                            </div>
                                            <div *ngSwitchDefault>
                                                Adicionar
                                            </div>
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </form>
                        <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <div class="table-responsive-sm">
                                    <table class="table table-sm table-borderless table-striped table-hover">
                                        <thead class="thead">
                                            <th>Código</th>
                                            <th width="15%">Código Maxdata</th>
                                            <th>Nome</th>
                                            <th></th>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of listaUsuariosEmpresas" id="{{ item.id }}">
                                                <td>{{ item.id_empresa }}</td>
                                                <td>{{ item.id_maxdata }}</td>
                                                <td>{{ item.descricao }}</td>
                                                <td class="text-right">
                                                    <a class="btn btn-sm btn-outline-danger"
                                                        (click)="openModalExcluirUsuarioEmpresaRef(modalExcluirUsuarioEmpresaRef, item)"><i
                                                            class="material-icons">clear</i>Excluir</a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </p-tabPanel>
        </p-tabView>
    </div>
</form>
<ng-template #modalExcluirUsuarioEmpresaRef>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Excluir acesso a empresa</h4>
    </div>
    <div class="modal-body">
        <p>Ao confirmar, o usuário <b>{{ objUsuario.obj?.email }}</b> terá o acesso à empresa
            <b>{{ usuarioEmpresaRemover.descricao }}</b> removido
        </p>
    </div>
    <div class="modal-footer">
        <button class="btn btn-sm btn-outline-secondary"
            (click)="closeModalExcluirUsuarioEmpresaRef()">Cancelar</button>
        <button class="btn btn-sm btn-outline-success" (click)="removerUsuarioEmpresa()" [disabled]="btnRemoverEmp">
            <div [ngSwitch]="btnRemoverEmp">
                <div *ngSwitchCase="true">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;
                    <span>Carregando ...</span>
                </div>
                <div *ngSwitchDefault>
                    Confirmar
                </div>
            </div>
        </button>
    </div>
</ng-template>
