import { formatDate } from '@angular/common';
import { PaginacaoViewModel } from '../../../PaginacaoViewModel';
import { ProdutoLoteViewModel } from '../produto-lote-view-model';

export class ProdutoLotePesquisaViewModel extends ProdutoLoteViewModel implements PaginacaoViewModel{
    pagina:number = 1;
    quantidadeRegistrosPagina: number = 100;
    totalRegistros: number = 0;
    totalPaginas:number = 0;
    desativados: boolean = false;
    start?: number = 0;
    order?: string;
    dt_fabricacao_inicial?: string;
    dt_fabricacao_final?: string;
    dt_vencimento_inicial?: string;
    dt_vencimento_final?: string;

    adicionarDataFabricacao() {

        let data = new Date();

		data = new Date(data.getFullYear(), data.getMonth(), 1);

		this.dt_fabricacao_inicial = formatDate(data, 'yyyy-MM-dd', 'pt');

		this.dt_fabricacao_final = formatDate(new Date(data.getFullYear(), data.getMonth() + 1, 0), 'yyyy-MM-dd', 'pt');
    }

    limparDataFabricacao() {

        this.dt_fabricacao_inicial = '';

        this.dt_fabricacao_final = '';
    }

    adicionarDataVencimento() {

        let data = new Date();

		data = new Date(data.getFullYear(), data.getMonth(), 1);

		this.dt_vencimento_inicial = formatDate(data, 'yyyy-MM-dd', 'pt');

		this.dt_vencimento_final = formatDate(new Date(data.getFullYear(), data.getMonth() + 1, 0), 'yyyy-MM-dd', 'pt');
    }

    limparDataVencimento() {

        this.dt_vencimento_inicial = '';

        this.dt_vencimento_final = '';
    }


}