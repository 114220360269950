import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { AlertMessage } from '@modules/alert.configuration.service';
import { ProdutoEmbalagemVendaService } from '@modules/manager/cadastro/estoque/produto-embalagem-venda/produto-embalagem-venda.service';
import { ProdutoUnidadeService } from '@modules/manager/cadastro/estoque/produto-unidade/produto-unidade.service';
import { ProdutoService } from '@modules/manager/cadastro/estoque/produto/produto.service';
import { ProdutoEmbalagemVendaPesquisaViewModel } from '@modules/manager/view-models/cadastro/estoque/pesquisa/produto-embalagem-venda-pesquisa-view-model';
import { ProdutoUnidadePesquisaViewModel } from '@modules/manager/view-models/cadastro/estoque/pesquisa/produto-unidade-pesquisa-view-model';
import { Uteis } from '@modules/utilizarios/uteis';
import { NgxSpinnerService } from 'ngx-spinner';
import { throwError } from 'rxjs';
import { concatMap, catchError } from 'rxjs/operators';

@Component({
  selector: 'app-produto-embalagem-venda-pesquisa-modal',
  templateUrl: './produto-embalagem-venda-pesquisa-modal.component.html',
  styles: [
  ]
})
export class ProdutoEmbalagemVendaPesquisaModalComponent implements OnInit {

  @Input() produtoId: number;
  @Input() modalPesquisa: boolean = false;
  @Input() pesquisarUnCadastro: boolean = true;
  @Output() fecharModal: EventEmitter<boolean> = new EventEmitter();
  @Output() produtoEmbalagemVendaSelecionado: EventEmitter<any> = new EventEmitter();
  pesquisa: ProdutoEmbalagemVendaPesquisaViewModel = new ProdutoEmbalagemVendaPesquisaViewModel();
  objProdutoEmbalagemVenda: ProdutoEmbalagemVendaPesquisaViewModel = new ProdutoEmbalagemVendaPesquisaViewModel();
  produtoEmbalagemVenda: ProdutoEmbalagemVendaPesquisaViewModel[] = [];
  indexSelecionado: number = -1;
  listaProdutoUn: ProdutoUnidadePesquisaViewModel[] = [];
  produto?: any;
  
  abrirFecharProdutoEmbalagemVendaCadastroModal?: boolean = false;
  constructor(
    private produto_embalagem_venda$: ProdutoEmbalagemVendaService,
    private produto_un$: ProdutoUnidadeService,
    private alert: AlertMessage,
    private spinner: NgxSpinnerService,
    private produto$: ProdutoService
  ) { }

  ngOnInit(): void {

    this.obterProdutoUn();

    this.obterProdutoEmbalagemVenda();
  }

  obterProdutoEmbalagemVenda() {

    this.produtoEmbalagemVenda = []

    this.pesquisa.produto_id = this.produtoId;

    this.pesquisa.quantidadeRegistrosPagina = 0;

    if(!this.pesquisa?.produto_id || this.pesquisa?.produto_id <= 0) return; 

    this.indexSelecionado = -1;

    this.produto_embalagem_venda$.obterTodos(this.pesquisa)
    .pipe(
      concatMap(
        retorno => {

          this.produtoEmbalagemVenda = retorno.total > 0 ? retorno.result : [];

          return this.produto$.obterProdutoEmbalagem(this.produtoId)
        }
      ),
      catchError(error => throwError(error))
    )
    .subscribe(
      retorno => {

        this.spinner.hide();

        this.produto = retorno.total > 0 ? retorno.result[0] : null; 

        if(this.produto && this.pesquisarUnCadastro) this.produtoEmbalagemVenda.push(this.produto);        

        this.indexSelecionado = 0; 
      },
      error => {

        this.indexSelecionado = -1;

        this.alert.Danger('Error', Uteis.ObterErroApi(error))

        this.spinner.hide();
      }
    );
  }

  obterProdutoUn(){
    let produtoUn: ProdutoUnidadePesquisaViewModel = new ProdutoUnidadePesquisaViewModel();

    produtoUn.start = 0; 

    produtoUn.quantidadeRegistrosPagina = 0;

    this.produto_un$.obterTodosProd(produtoUn).subscribe(

      retorno => this.listaProdutoUn = retorno.total > 0 ? retorno.result : [],

      err => this.alert.Danger("Erro", Uteis.ObterErroApi(err))
    );
  }

  selecionarIndex(index: number) {
    this.indexSelecionado = index;
  }

  @HostListener('window:keydown', ['$event']) keyEvent(event: KeyboardEvent) {

    if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {

        if (this.indexSelecionado > 0 && event.key === 'ArrowUp') this.indexSelecionado -= 1;

        if (this.indexSelecionado < this.produtoEmbalagemVenda.length - 1 && event.key === 'ArrowDown') this.indexSelecionado += 1;
        
        document.getElementById('table').getElementsByTagName('tbody')[0].getElementsByTagName('tr')[this.indexSelecionado].scrollIntoView({block: "center"})
       
    } else if (event.key === 'Enter') {

        if (this.indexSelecionado > -1) this.indexSelecionado = -1;

    } 

  }

  abrirModalProdutoEmbalagemVenda(item?: any) {

    this.abrirFecharProdutoEmbalagemVendaCadastroModal = !this.modalPesquisa ? true : false;

    this.objProdutoEmbalagemVenda = item;

    this.produtoEmbalagemVendaSelecionado.emit(item);

  }

  fecharModalProdutoEmbalagemVenda() {

    this.fecharModal.emit(true);
  }

  fecharModalProdutoEmbalagemVendaCadastro() {

    this.abrirFecharProdutoEmbalagemVendaCadastroModal = false;

    this.obterProdutoEmbalagemVenda();
  }
  
}
