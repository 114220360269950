import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertMessage } from '@modules/alert.configuration.service';
import { AutenticacaoService } from '@modules/autenticacao/services/autenticacao.service';
import { ClienteService } from '@modules/manager/cadastro/cliente-fornecedor/cliente/cliente.service';
import { ProdutoEanService } from '@modules/manager/cadastro/estoque/produto-ean/produto-ean.service';
import { ProdutoEmbalagemVendaService } from '@modules/manager/cadastro/estoque/produto-embalagem-venda/produto-embalagem-venda.service';
import { ProdutoGradeService } from '@modules/manager/cadastro/estoque/produto-grade/produto-grade.service';
import { ProdutoService } from '@modules/manager/cadastro/estoque/produto/produto.service';
import { ConfiguracaoVendaService } from '@modules/manager/sistema/configuracao-venda/configuracao-venda-service';
import { ProdutoEanPesquisaViewModel } from '@modules/manager/view-models/cadastro/estoque/pesquisa/produto-ean-pesquisa-view-model';
import { ProdutoEmbalagemVendaPesquisaViewModel } from '@modules/manager/view-models/cadastro/estoque/pesquisa/produto-embalagem-venda-pesquisa-view-model';
import { ProdutoGradePesquisaViewModel } from '@modules/manager/view-models/cadastro/estoque/pesquisa/produto-grade-pesquisa-view-model';
import { ProdutoPesquisaViewModel } from '@modules/manager/view-models/cadastro/estoque/pesquisa/produto-pesquisa-view-model';
import { ConfiguracaoVendaOrcamentoViewModel } from '@modules/manager/view-models/sistema/configuracao-venda-orcamento-view-model';
import { Uteis } from '@modules/utilizarios/uteis';
import { throwError, } from 'rxjs';
import { catchError, concatMap } from 'rxjs/operators';

@Component({
  selector: 'app-input-codigo-produto',
  templateUrl: './input-codigo-produto.component.html',
  styles: []
})
export class InputCodigoProdutoComponent implements OnInit {

  @Input() codigoProduto?: string;
  @Input() tipo_sped: string | Array<string>;
  @Input() limparCodigoProduto: boolean = false;
  @Output() produto: EventEmitter<any> = new EventEmitter();
  @Output() qtd: EventEmitter<any> = new EventEmitter();
  pesquisaPorCodigo: 0 | 1 | 2 = 0;
  // 0 Código Interno
  // 1 Código Fabricante
  // 2 Código Barras
  pesquisaProduto: ProdutoPesquisaViewModel = new ProdutoPesquisaViewModel();
  pesquisaProdutoEan: ProdutoEanPesquisaViewModel = new ProdutoEanPesquisaViewModel();
  idEmpresaMaxdata = this.autenticacao$.acessoUsuarioValue?.idLoja;
  configuracaoVendaOS: ConfiguracaoVendaOrcamentoViewModel = new ConfiguracaoVendaOrcamentoViewModel();
  objProduto: ProdutoPesquisaViewModel = new ProdutoPesquisaViewModel();
  produto_grade?: ProdutoGradePesquisaViewModel = new ProdutoGradePesquisaViewModel();
  objProdutoEan?: ProdutoEanPesquisaViewModel = new ProdutoEanPesquisaViewModel();
  objProdutoGrade?: ProdutoGradePesquisaViewModel = new ProdutoGradePesquisaViewModel();
  objProdutoEmbalagemVenda?: ProdutoEmbalagemVendaPesquisaViewModel = new ProdutoEmbalagemVendaPesquisaViewModel();
  constructor(
    private produto$: ProdutoService,
    private alert: AlertMessage,
    private cliente$: ClienteService,
    private produtoEan$: ProdutoEanService,
    private produtoGrade$: ProdutoGradeService,
    private produtoEmbalagemVenda$: ProdutoEmbalagemVendaService,
    private autenticacao$: AutenticacaoService,
    private configVenda$: ConfiguracaoVendaService
  ) { }

  ngOnInit() {
    this.obterConfiguracao();
  }

  onKeydownProduto(event: KeyboardEvent) {

    if (event.key === "*") {
      this.qtd.emit(parseInt(this.codigoProduto || "0"));
      this.codigoProduto = null;
    }

    if ( (event.shiftKey && event.key === "+") || event.key === "+" || event.type === "click" ) {
      event.preventDefault();
      this.pesquisaPorCodigo++;
      if (this.pesquisaPorCodigo == 3) this.pesquisaPorCodigo = 0;
    }

    if (event.key == "Enter") {
      this.controlePesquisa();
    }

  }

  controlePesquisa() {

    this.pesquisaProduto = new ProdutoPesquisaViewModel();
    this.objProdutoEmbalagemVenda = new ProdutoEmbalagemVendaPesquisaViewModel();
    this.objProdutoGrade = new ProdutoGradePesquisaViewModel();

    if (this.codigoProduto == null || this.codigoProduto == "" || this.codigoProduto == undefined) return;

    if (this.pesquisaPorCodigo == 0) {
      this.pesquisaProduto.id = typeof this.codigoProduto == 'string' ? parseInt(this.codigoProduto) : this.codigoProduto;
      this.pesquisarProdutos();
    }
    if (this.pesquisaPorCodigo == 1) {
      this.pesquisaProduto.produto_empresa_codigo = this.codigoProduto;
      this.pesquisarProdutos();
    }
    if (this.pesquisaPorCodigo == 2) {
      this.obterCodigoBarras();
    }
  }

  pesquisarProdutos() {

    this.tipo_sped !== '' || Array.isArray(this.tipo_sped) && this.tipo_sped.length > 0 ? this.pesquisaProduto.tipo_sped = this.tipo_sped : this.pesquisaProduto.tipo_sped = null;

    this.produto$.obterTodos(this.pesquisaProduto).subscribe(
      (retorno) => {

        if (retorno.total > 0) {

          this.objProduto = new ProdutoPesquisaViewModel();

          this.objProduto = Object.assign({}, retorno.result[0]);

          let vlr_venda = this.objProduto.vlr_venda;

          if (this.objProdutoGrade)
          {
            vlr_venda = this.objProdutoGrade?.vlr_venda || 0 > 0 ? this.objProdutoGrade.vlr_venda : this.objProduto.vlr_venda; 

            this.objProduto.produto_grade_id =  this.objProdutoGrade?.id || 0; 
          }
          else if (this.objProdutoEmbalagemVenda) 
          {
            vlr_venda = this.objProdutoEmbalagemVenda?.vlr_venda || 0 > 0 ? this.objProdutoEmbalagemVenda.vlr_venda : this.objProduto.vlr_venda; 

            this.objProduto.produto_embalagem_venda_id = this.objProdutoEmbalagemVenda?.id || 0; 
          }
         
          this.objProduto.vlr_venda = vlr_venda; 

          this.objProduto['campo_selecionado'] = this.pesquisaPorCodigo;

          this.produto.emit(this.objProduto);
         

        } else this.alert.Info("Produto", "Produto não encontrado");
      },
      (err) => this.alert.Danger("Erro", Uteis.ObterErroApi(err))
    );
  }

  obterCodigoBarras() {

    if (!this.codigoProduto) return;
    this.produtoEan$.obterPorCodigoBarras(this.codigoProduto)
      .pipe(
        concatMap(
          retorno => {

            this.objProdutoEan = retorno.total > 0 ? retorno.result[0] : null;


            return this.produtoGrade$.obterPorCodigoBarras(this.codigoProduto)
          }
        ),
        concatMap(
          retorno => {


            this.objProdutoGrade = retorno.total > 0 ? retorno.result[0] : null;

            return this.produtoEmbalagemVenda$.obterPorCodigoBarras(this.codigoProduto)
          }
        ),
        catchError(err => throwError(err)),
      ).subscribe(
        retorno => {

          this.objProdutoEmbalagemVenda = retorno.total > 0 ? retorno.result[0] : null;

          if (this.objProdutoEan) {

            this.pesquisaProduto.id = this.objProdutoEan.produto_id;

            this.pesquisarProdutos();

          } else if (this.objProdutoGrade) {

            this.pesquisaProduto.id = this.objProdutoGrade.produto_id;

            this.pesquisarProdutos();

          } else if (this.objProdutoEmbalagemVenda) {

            this.pesquisaProduto.id = this.objProdutoEmbalagemVenda.produto_id;

            this.pesquisarProdutos();

          } else {

            return this.alert.Info("Produto", "Produto não encontrado");
          }

        });
  }

  obterConfiguracao() {
    this.configVenda$.obterConfiguracao(this.idEmpresaMaxdata).subscribe(retorno => {
      this.configuracaoVendaOS = retorno.result[0];
      if (this.configuracaoVendaOS.lst_indice_pesquisa_produto == 0) {
        this.pesquisaPorCodigo = 0
      }
      if (this.configuracaoVendaOS.lst_indice_pesquisa_produto == 1) {
        this.pesquisaPorCodigo = 1
      }
      if (this.configuracaoVendaOS.lst_indice_pesquisa_produto == 2) {
        this.pesquisaPorCodigo = 2
      }

    }, error => {
      this.alert.Danger('Erro', Uteis.ObterErroApi(error))
    });

  }


}
