import { Injectable } from '@angular/core';
import { PesquisaGeralViewModel } from '@modules/admin/view-models/pesquisa/PesquisaGeralViewModel';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { URLBASE } from 'src/modules/configuration.service';
import { AutenticacaoService } from 'src/modules/autenticacao/services/autenticacao.service';
import { CadastroViewModel } from 'src/modules/admin/view-models/cadastro/CadastroViewModel';
import { ProdutoFabricanteViewModel } from 'src/modules/manager/view-models/cadastro/estoque/produto-fabricante-view-model';
import { RetornoApiViewModel } from 'src/modules/manager/view-models/RetornoApiViewModel';
import { ProdutoFabricantePesquisaViewModel } from 'src/modules/manager/view-models/cadastro/estoque/pesquisa/produto-fabricante-pesquisa-view-model';

@Injectable({
  providedIn: 'root'
})
export class ProdutoFabricanteService {

  idEmpresaMaxdata = this.autenticacaoService.acessoUsuarioValue?.idEmpresaMaxdata;
    
  urlGet =  `${URLBASE}/${this.idEmpresaMaxdata}/produto_fabricante_service/get`;

  urlAddUpdate  = `${URLBASE}/${this.idEmpresaMaxdata}/produto_fabricante_service/addupdate`;

  constructor(private http: HttpClient, private autenticacaoService: AutenticacaoService) { }

  obterTodos(pesquisa?: ProdutoFabricantePesquisaViewModel) : Observable<RetornoApiViewModel<ProdutoFabricantePesquisaViewModel[]>> {

    let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel();
    
    pesquisaGeral.select = 'produto_fabricante.id, produto_fabricante.descricao, produto_fabricante.ativo';

    pesquisaGeral.calcRowCount = 1;

    pesquisaGeral.start = pesquisa?.start || 0;

    pesquisaGeral.order = pesquisa?.order ? pesquisa?.order : 'produto_fabricante.id'

    pesquisaGeral.limit = pesquisa?.quantidadeRegistrosPagina || 0;

    pesquisaGeral.where = `and ativo = ${pesquisa?.desativados ? 0 : 1}`;

    if (pesquisa?.id) pesquisaGeral.where += ` and produto_fabricante.id = ${pesquisa?.id}`;

    if (pesquisa?.descricao) pesquisaGeral.where += ` and lower(descricao) like lower('%${pesquisa?.descricao}%')`;

    return this.http.post<RetornoApiViewModel<ProdutoFabricantePesquisaViewModel[]>>(this.urlGet,pesquisaGeral)
  }
  
  obterPorId(id: number): Observable<RetornoApiViewModel<ProdutoFabricanteViewModel>>{

    let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel();
    
    pesquisaGeral.select = 'produto_fabricante.id, produto_fabricante.descricao, produto_fabricante.ativo';

    pesquisaGeral.where = ` and produto_fabricante.id = ${id}`
  
    return this.http.post<RetornoApiViewModel<ProdutoFabricanteViewModel>>(`${this.urlGet}`,pesquisaGeral);
  }
  
  ObterTodosAutoComplete(pesquisaGeralViewModel: PesquisaGeralViewModel = new PesquisaGeralViewModel()) : Observable<RetornoApiViewModel<ProdutoFabricanteViewModel>> {   
  
      return this.http.post<RetornoApiViewModel<ProdutoFabricanteViewModel>>(this.urlGet, pesquisaGeralViewModel);
  }
  
  adicionar(produtoFabricanteViewModel: CadastroViewModel<ProdutoFabricanteViewModel>) : Observable<RetornoApiViewModel<ProdutoFabricanteViewModel>>{
  
      return this.http.post<RetornoApiViewModel<ProdutoFabricanteViewModel>>(this.urlAddUpdate, produtoFabricanteViewModel);
  }
  
  atualizar(id: number, produtoFabricanteViewModel: CadastroViewModel<ProdutoFabricanteViewModel>) : Observable<RetornoApiViewModel<ProdutoFabricanteViewModel>>{
  
      return this.http.put<RetornoApiViewModel<ProdutoFabricanteViewModel>>(`${this.urlAddUpdate}/${id}`, produtoFabricanteViewModel);
  }
}
