export class PesquisaGeralViewModel {
        select: string = `*`;
        where: string = ``;
        group?: string;
        order?: string;
        start: number = 0;
        limit: number ;
        calcRowCount: number = 1;
        acesso_externo?: number = 1;
        consultarTodasEmpresas?: number = 0;
}