import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loanding',
  templateUrl: './loanding.component.html',
  styleUrls: ['./loanding.component.scss'

  ]
})
export class LoandingComponent implements OnInit {

  @Input() textoP:string;

  constructor() { }

  ngOnInit(): void {
    
  }

}
