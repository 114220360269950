<div class="form-group">
  <label for="pgto_tipo_id" class="col-form-label-sm">Fabricante</label>
  <p-multiSelect name="fabricante"
                defaultLabel="-Selecione-" 
                inputId="fabricante_id" 
                optionLabel="descricao" 
                [showHeader]="true"
                [emptyFilterMessage]="'Não encontrado'"
                [selectedItemsLabel]="'Atendentes Selecionados {0}'"
                [options]="listaFabricante"
                [(ngModel)]="fabricantes"
                (onPanelHide)="enviar()" 
                >
  </p-multiSelect>
</div>