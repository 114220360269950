import { Component, HostListener, Input, OnInit, Output } from '@angular/core';
import { ClientePesquisaViewModel } from '@modules/manager/view-models/cadastro/cliente-fornecedor/pesquisa/cliente-pesquisa-view-model';
import { EventEmitter } from '@angular/core';
import { ClienteService } from '@modules/manager/cadastro/cliente-fornecedor/cliente/cliente.service';
import { AlertMessage } from '@modules/alert.configuration.service';
import { ClienteGrupoService } from '@modules/manager/cadastro/cliente-fornecedor/cliente-grupo/cliente-grupo.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfiguracaoService } from '@modules/manager/sistema/configuracao/configuracao.service';
import { AutenticacaoService } from '@modules/autenticacao/services/autenticacao.service';
import { PesquisaGeralViewModel } from '@modules/admin/view-models/pesquisa/PesquisaGeralViewModel';
import { Uteis } from '@modules/utilizarios/uteis';
import { ClienteGrupoPesquisaViewModel } from '@modules/manager/view-models/cadastro/cliente-fornecedor/pesquisa/cliente-grupo-pesquisa-view-model';

@Component({
  selector: 'app-cliente-pesquisa-modal',
  templateUrl: './cliente-pesquisa-modal.component.html',
  styles: [
  ]
})
export class ClientePesquisaModalComponent implements OnInit {
  /**
   * @description
   * Modal de pesquisa de cliente
   * @param abrirFecharModalCliente Parâmetro que abre o modal. Se for igual a true o modal é inciado.
   * @param stringPesquisa String que é utilizada na pesquisa por nome.
   * @param tipoCadastro Tipo de cadastro para a pesquisa de cliente, deve ser passado em forma de lista.
   *                      0 = Somente Cliente;
                          1 = Somente Fornecedor;
                          2 = Cliente / Fornecedor;
                          3 = Transportadora;
                          4 = Funcionário;
                          5 = Usuário do Sistema;
                          6 = Grupo de Usuário do Sistema.
   * @param closeModal Emite um evento para que o modal seja fechado.
   * @param cliente Emite um evento e retorno o cliente selecioando para o componente pai.
   */

  @Input() abrirFecharModalCliente: boolean = false;
  @Input() stringPesquisa: string = '';
  @Input() tipoCadastro: [] = [];
  @Input() tipoPessoa: [] = [];
  @Output() closeModal = new EventEmitter();
  @Output() cliente: EventEmitter<any> = new EventEmitter();
  pesquisa: ClientePesquisaViewModel = new ClientePesquisaViewModel();
  listaClientes: ClientePesquisaViewModel[] = [];
  selectedRowIndex: number = -1;

  constructor(private cliente$: ClienteService,
    private alert: AlertMessage,
    private spinner: NgxSpinnerService,
    private configuracao$: ConfiguracaoService,
    private autenticacao$: AutenticacaoService) { }

  ngOnInit() {

    setTimeout(() => {
      (document.querySelector(`[name="nome"]`) as HTMLElement).focus();
      this.pesquisa.nome = this.stringPesquisa;
      this.pesquisa.tipo_cadastro = this.tipoCadastro.length > 0 ? this.tipoCadastro : undefined;
      this.pesquisa.tipo_pessoa = this.tipoPessoa.length > 0 ? this.tipoPessoa : undefined;
    }, 100);
  }

  @HostListener('window:keydown', ['$event']) keyEvent(event: KeyboardEvent) {

    if (event.key === 'ArrowUp') {
      if (this.selectedRowIndex > 0) this.selectedRowIndex -= 1;
      document.getElementById('table')
        .getElementsByTagName('tbody')[0]
        .getElementsByTagName('tr')[this.selectedRowIndex]
        .scrollIntoView({ block: "center" })
    }
    if (event.key === 'ArrowDown') {
      if (this.selectedRowIndex < this.listaClientes.length - 1) this.selectedRowIndex += 1;
      document.getElementById('table')
        .getElementsByTagName('tbody')[0]
        .getElementsByTagName('tr')[this.selectedRowIndex]
        .scrollIntoView({ block: "center" })
    }
    if (event.key === 'Enter') {

      if (this.selectedRowIndex > -1 && this.listaClientes.length > 0) {

        this.cliente.emit(this.listaClientes[this.selectedRowIndex]);   

        this.selectedRowIndex = -1;
      }
    }
    if (event.key === 'Escape') {
      this.closeModalCliente();
    }

  }//
  clickRowIndex(index: number) {
    this.selectedRowIndex = index;
  }//

  pesquisar() {

    (document.querySelector('[name="id"]') as HTMLInputElement).blur();
    (document.querySelector('[name="nome"]') as HTMLInputElement).blur();
    (document.querySelector('[name="fantasia"]') as HTMLInputElement).blur();
    (document.querySelector('[name="cpf_cnpj"]') as HTMLInputElement).blur();
    (document.querySelector('[name="fone"]') as HTMLInputElement).blur();
    this.pesquisa.quantidadeRegistrosPagina = 0;
    this.spinner.show();
    this.pesquisa.fone = this.pesquisa.fone?.replace(/\D/g, '');
    this.cliente$.obterTodos(this.pesquisa).subscribe(
      retorno => {
        this.listaClientes = retorno.total > 0 ? retorno.result : [];
        this.selectedRowIndex = 0;
        this.spinner.hide();
      },
      error => {
        this.alert.Danger('Erro', Uteis.ObterErroApi(error));
        this.spinner.hide();
        this.selectedRowIndex = -1;
      }
    );
  }

  clienteSlecionado(item) {
    this.cliente.emit(item);
    this.selectedRowIndex = -1;
  }

  closeModalCliente() {
    this.closeModal.emit(false);
    this.selectedRowIndex = -1;
  }
}
