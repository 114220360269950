export class EcommerceDepartamentoViewModel {
    id?: number;
    nome?: string;
    descricao?: string;
    palavra_chave?: string;
    chk_visivel?: boolean;
    img?: string;
    img_icon?: string;
    ativo?: boolean;
}
