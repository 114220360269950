import { PaginacaoViewModel } from '@modules/manager/view-models/PaginacaoViewModel';
import { UsuarioManagerViewModel } from '../usuario-manager-view-model';

export class UsuarioManagerPesquisaViewModel extends UsuarioManagerViewModel implements PaginacaoViewModel {
    pagina: number = 1;
    quantidadeRegistrosPagina: number = 20;
    totalRegistros: number = 0;
    totalPaginas: number = 0;
    desativados: boolean = false;
    start?: number;
    order?: string;
    att_tecnico?: number;
    ass_tecnico?: number;
}