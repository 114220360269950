import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertMessage } from '@modules/alert.configuration.service';
import { EtiquetaItemViewModel } from '@modules/manager/view-models/cadastro/estoque/etiqueta-item-view-model';
import { EtiquetaModeloViewModel } from '@modules/manager/view-models/cadastro/estoque/etiqueta-modelo-view-model';
import { ListaPaginadaViewModel } from '@modules/manager/view-models/ListaPaginadaViewModel';
import { TituloPagina } from '@modules/utilizarios/tituloPaginas/tituloPaginas';
import { Uteis } from '@modules/utilizarios/uteis';
import { NgxSpinnerService } from 'ngx-spinner';
import { EtiquetaService } from './etiqueta.service';
import { EtiquetaRemoverItem } from '@modules/utilizarios/etiqueta_remover_item';

@Component({
  selector: 'app-etiqueta',
  templateUrl: './etiqueta.component.html',
  styleUrls: ['./etiqueta.component.css']
})
export class EtiquetaComponent implements OnInit {

  formulario!: FormGroup;
  resetFormularioItem: any;
  tituloPagina: TituloPagina = new TituloPagina('310 - Emissão de Etiquetas', []);
  produto_tipo_sped: Array<string> = [`'00'`,`'04'`];
  processandoImpressao: boolean = false;
  checkedboxAll: boolean = false; 
  pesquisaProdutoModal: boolean = false;
  listaItens: ListaPaginadaViewModel<EtiquetaItemViewModel> = new ListaPaginadaViewModel<EtiquetaItemViewModel>();

  listaItensImpressao: Array<any>;

  etiqueta: EtiquetaModeloViewModel[] = [];
  itensRemocao: EtiquetaRemoverItem[] = [];
  itensRemocaoSelecionados: EtiquetaRemoverItem[] = [];

  modalProdutoGrade: boolean = false;

  constructor(
    private fb: FormBuilder,
    private etiquetaService$: EtiquetaService,
    private alert: AlertMessage,
    private spinner: NgxSpinnerService,
  ) {}

  ngOnInit() {

    this.criarFormulario();

    this.etiqueta = EtiquetaModeloViewModel.etiquetaModeloBobina;
    this.itensRemocao = EtiquetaRemoverItem.listaItens;
    this.modelo.setValue(this.etiqueta[0].modelo);

  }

  criarFormulario(){

    this.formulario = this.fb.group({
      parametros: this.fb.group({
        tipo_etiqueta: [0],
        modelo: [''],
        observacao: '',
      }),
      item: this.fb.group({
        produto_id: [null, [Validators.required]],
        descricao: [null, [Validators.required]],
        produto_empresa_codigo: [],
        produto_fabricante_descricao: [],
        un: [],
        vlr_venda: [],
        oferta: [],
        vlr_custo: [],
        vlr_promocao: [],
        codigo_ean: [],
        grade: [],
        lote: [],
        qtd_etiquetas: [1, [Validators.min(1), Validators.required]],
        chk_select: [false],
      }),
    });

    this.resetFormularioItem = this.formulario.controls.item.value;
  }

  imprimir(){

    if(this.listaItens.itens.length <= 0) {
      this.alert.Info("Emissão de Etiquetas","Nenhum item para impressão.");
      return;
    }

    let parametros = this.formulario.controls.parametros.value;

    // Cria os parametros e adiciona 0 ou 1 dinamicamente;
    this.itensRemocao.forEach(element => {
      parametros[element.campo] = this.itensRemocaoSelecionados.some(itemSelecionado => itemSelecionado.campo === element.campo) ? 1 : 0;
    });

    this.spinner.show();
    this.processandoImpressao = true;

    this.montarObjetoItemParaImpressao();

    this.etiquetaService$.gerarEtiqueta(this.listaItensImpressao, parametros).subscribe(
      retorno => {
        this.spinner.hide();
        this.processandoImpressao = false;
        fetch(retorno).then(res => res.blob()).then(res => window.open(URL.createObjectURL(res), '_blank'));
      },
      error =>{
        this.processandoImpressao = false;
        this.spinner.hide();
        this.alert.Danger(`Erro ao Imprimir Etiqueta`, Uteis.ObterErroApi(error));
      }
    );
    
  }

  montarObjetoItemParaImpressao(){

    this.listaItensImpressao = [];

    this.listaItens.itens.forEach(item => {

      for(let i = 0; i < item.qtd_etiquetas; i++){
        this.listaItensImpressao.push(item);
      }

    });

  }

  produtoSelecionado(produto){

    if(produto.usa_grade){

      this.formulario.controls.item.patchValue(
        { 
          produto_id: produto.id,
          descricao: produto.descricao,
          produto_empresa_codigo: produto.produto_empresa_codigo,
          produto_fabricante_descricao: produto.produto_fabricante_descricao,
          un: produto.un,
          vlr_venda: produto.vlr_venda,
          oferta: produto.oferta,
          vlr_custo: produto.vlr_custo,
          vlr_promocao: produto.vlr_promocao,
          codigo_ean: produto.codigo_barras,
          grade: '',
          lote: '',
        }
      );

      this.pesquisaProdutoModal = false;

      this.modalProdutoGrade = true;
    }
    else{

      this.formulario.controls.item.patchValue(
        { 
          produto_id: produto.id,
          descricao: produto.descricao,
          produto_empresa_codigo: produto.produto_empresa_codigo,
          produto_fabricante_descricao: produto.produto_fabricante_descricao,
          un: produto.un,
          vlr_venda: produto.vlr_venda,
          oferta: produto.oferta,
          vlr_custo: produto.vlr_custo,
          vlr_promocao: produto.vlr_promocao,
          codigo_ean: produto.codigo_barras,
          grade: '',
          lote: '',
        }
      );
  
      this.pesquisaProdutoModal = false;
  
      (document.querySelector(`[name=qtd_etiquetas]`) as HTMLElement).focus();
      (document.querySelector(`[name=qtd_etiquetas]`) as HTMLInputElement).select();
    }

  }

  produtoGradeSelecionado(produto){

    this.formulario.controls.item.patchValue(
      { 
        vlr_venda: this.verificarValorVendaGrade(produto?.vlr_venda),
        codigo_ean: this.verificarCodBarrasGrade(produto?.codigo_barras),
        grade: produto.produto_grade_descricao +" - "+ produto?.produto_grade_tamanho
      }
    );

    this.pesquisaProdutoModal = false;

    (document.querySelector(`[name=qtd_etiquetas]`) as HTMLElement).focus();
    (document.querySelector(`[name=qtd_etiquetas]`) as HTMLInputElement).select();
  }

  verificarValorVendaGrade(vlr_venda?: string): string { 

    if (!vlr_venda){
      return this.vlr_venda.value;
    }

    return vlr_venda;

  }

  verificarCodBarrasGrade(codigo_barras?: string): string { 

    if (!codigo_barras){
      return this.codigo_ean.value;
    }

    return codigo_barras;

  }

  adicionarItem(){

    if(!this.formulario.valid) {

      if(!this.produto_id.valid || !this.descricao.valid){ 
        return;
      }

      this.alert.Warning("Item","Quantidade Inválida.");
      return;
    }

    if(this.codigo_ean.value && !Uteis.ValidarEAN13(this.codigo_ean.value)){
      this.codigo_ean.setValue(null);
      this.alert.Info("Código de barras","Fora do padrão EAN 13");
    }

    this.qtd_etiquetas.setValue(Math.trunc(this.qtd_etiquetas.value));

    this.listaItens.itens.push(this.formulario.controls.item.value)

    this.formulario.controls.item.reset(this.resetFormularioItem);

    (document.querySelector(`[name=input_codigo]`) as HTMLElement).focus();
    (document.querySelector(`[name=input_codigo]`) as HTMLInputElement).select();

  }

  removerItem(index){

    this.listaItens.itens.splice(index, 1);

  }

  alterarTipoEtiqueta(){

    if(this.tipo_etiqueta.value){
      this.etiqueta = EtiquetaModeloViewModel.etiquetaModeloa4;
      this.modelo.setValue(this.etiqueta[0].modelo);
    }else{
      this.etiqueta = EtiquetaModeloViewModel.etiquetaModeloBobina;
      this.modelo.setValue(this.etiqueta[0].modelo);
    }

  }


  get tipo_etiqueta(){ return this.formulario.get('parametros')?.get('tipo_etiqueta') }
  get modelo(){ return this.formulario.get('parametros')?.get('modelo') }

  get produto_id(){ return this.formulario.get('item')?.get('produto_id') }
  get vlr_venda(){ return this.formulario.get('item')?.get('vlr_venda') as FormControl }
  get codigo_ean(){ return this.formulario.get('item')?.get('codigo_ean') as FormControl }
  get descricao(){ return this.formulario.get('item')?.get('descricao') }
  get qtd_etiquetas(){ return this.formulario.get('item')?.get('qtd_etiquetas') }

}
