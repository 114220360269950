<p-dialog [(visible)]="abrirFecharModalCTe" [modal]="true" [style]="{width: '90vw'}" [maximizable]="true"
    [baseZIndex]="10000" [draggable]="false" [resizable]="false" [closable]="false">
    <p-header>
        Pesquisar CT-e
    </p-header>
    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-right btns-group mb-3">
        <button class="btn btn-sm btn-outline-search" type="button" (click)="pesquisar()"><i
                class="material-icons">search</i>Pesquisar</button>
    </div>
    <div class="card rounded-0 mb-1 border-left-0 border-right-0">
        <div class="card-body pt-1 pb-1 bg-white">
            <div class="row">
                <div class="col-sm-6 col-md-3 col-lg-2 col-xl-1">
                    <div class="form-group">
                        <label for="numero" class="col-form-label-sm">Num. CT-e</label>
                        <input type="number" id="numero" name="numero"
                            class="form-control form-control-sm noscroll" placeholder="CT-e" [(ngModel)]="pesquisa.numero">
                    </div>
                </div>
                <div class="col-sm-12 col-md-9 col-lg-6 col-xl-3">
                    <div class="form-group">
                        <label for="remetente_nome" class="col-form-label-sm">Remetente Código | CPF | CNPJ | Nome</label>
                        <input id="remetente_nome" name="remetente_nome" class="form-control form-control-sm"
                            placeholder="Pesquise o Remetente Código | CPF | CNPJ | Nome " autocomplete="off"
                            [(ngModel)]="pesquisa.remetente_nome">
                    </div>
                </div>
                <div class="col-sm-12 col-md-9 col-lg-6 col-xl-3">
                    <div class="form-group">
                        <label for="destinatario_nome" class="col-form-label-sm">Destinatário Código | CPF | CNPJ | Nome</label>
                        <input id="destinatario_nome" name="destinatario_nome" class="form-control form-control-sm"
                            placeholder="Pesquise o Destinatário pelo Nome" autocomplete="off"
                            [(ngModel)]="pesquisa.destinatario_nome">
                    </div>
                </div>
                <div class="col-sm-12 col-md-8 col-lg-6 col-xl-3">
                    <div class="form-group">
                        <label for="dt_contabil" class="col-form-label-sm">Período</label>
                        <div class="input-group">
                            <input type="date" id="dt_contabil" name="dt_contabil" class="form-control form-control-sm"
                                [(ngModel)]="pesquisa.dt_emissao_inicial">
                            <span class="ml-1 mr-1 mt-1 text">a</span>
                            <input type="date" id="data_final" name="data_final" class="form-control form-control-sm"
                                [(ngModel)]="pesquisa.dt_emissao_final">
                            <div class="input-group-append">
                                <button class="btn btn-sm btn-maxdata" type="button" id="remover_data_inicial"
                                    [disabled]="!pesquisa.dt_emissao_inicial && !pesquisa.dt_emissao_final"
                                    ><i class="material-icons mr-0">remove</i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <div class="my-custom-scrollbar table-wrapper-scroll-y">
                        <table class="table table-sm table-borderless table-striped table-hover">
                            <thead class="thead">
                                <th>Nº CT-e</th>
                                <th>Remetente</th>
                                <th>Destinatário</th>
                                <th class="text-nowrap">Período</th>
                                <th class="text-nowrap">Valor CT-e</th>
                                <th class="w-20 text-center"></th>
                            </thead>
                            <tbody>
                                <tr class="cursor" *ngFor="let item of listaCTe" >
                                    <td class="text-nowrap pr-5"><b>{{item.numero}}</b></td>
                                    <td class="text-nowrap pr-2 w-descricao text-truncate">{{item.remetente_id}} - {{item.remetente_nome}}</td>
                                    <td class="text-nowrap pr-2 w-descricao text-truncate">{{item.destinatario_id}} - {{item.destinatario_nome}}</td>
                                    <td class="text-nowrap pr-5">{{item.dt_emissao |date: 'dd/MM/yyyy'}}</td>
                                    <td class="text-nowrap pr-5">{{item.vlr_total_servico | currency: 'BRL' : ''}}</td>
                                    <td class="text-center" >                                      
                                        <p-checkbox binary="true" (onChange)="nfSelecionados($event, item)"></p-checkbox>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <button class="btn btn-sm btn-outline-danger"  type="button" (click)="closeModalCTe()"
            >Cancelar</button>
        <button class="btn btn-sm btn-outline-primary" type="button" (click)="selecionar()">
            <span>Selecionar</span>
        </button>
    </ng-template>
</p-dialog>