import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { TituloPaginasComponent } from './titulo-paginas.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgxSpinnerModule } from 'ngx-spinner';

@NgModule({
    imports:[
        CommonModule,
        RouterModule,
        NgxSpinnerModule,
    ],
    declarations:[
        TituloPaginasComponent  
    ],
    exports:[
        TituloPaginasComponent
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA // Tells Angular we will have custom tags in our templates
    ]
})
export class TituloPaginaModule{

}