import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AlertMessage } from '@modules/alert.configuration.service';
import { EnderecoMunicipioService } from '@modules/manager/cadastro/cliente-fornecedor/endereco-municipio/endereco-municipio.service';
import { EnderecoUfService } from '@modules/manager/cadastro/cliente-fornecedor/endereco-uf/endereco-uf.service';
import { EnderecoMunicipioPesquisaViewModel } from '@modules/manager/view-models/cadastro/cliente-fornecedor/pesquisa/endereco-municipio-pesquisa-view-model';
import { EnderecoUfPesquisaViewModel } from '@modules/manager/view-models/cadastro/cliente-fornecedor/pesquisa/endereco-uf-pesquisa-view-model';
import { Uteis } from '@modules/utilizarios/uteis';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-select-uf-cidade',
  templateUrl: './select-uf-cidade.component.html',
  styles: []
})
export class SelectUfCidadeComponent implements OnInit {

  @Output() uf_selecionado: EventEmitter<any> = new EventEmitter<any>();
  @Output() municipio_selecionado: EventEmitter<any> = new EventEmitter<any>();

  listaEstados: EnderecoUfPesquisaViewModel[] = [];
  listaCidades: EnderecoMunicipioPesquisaViewModel[] = [];
  pesquisaEnderecoMunicipio: EnderecoMunicipioPesquisaViewModel = new EnderecoMunicipioPesquisaViewModel();
  index_uf: number = -1;
  index_municipio: number = -1;

  constructor(
    private enderecoUf$: EnderecoUfService, 
    private enderecoMunicipio$: EnderecoMunicipioService,
    private alert: AlertMessage, 
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit() {

    this.enderecoUf$.obterTodos().subscribe(
      retorno => { 
        this.listaEstados = retorno.total > 0 ? retorno.result : []
      }, 
      error => {
        this.alert.Danger('Erro ao obter UF', Uteis.ObterErroApi(error));
      }
    );

  }

  pesquisarCidadesPorCodigoEstado(){

    if(this.index_uf == -1) {
      this.listaCidades = [];
      return;
    };

    this.uf_selecionado.emit(this.index_uf);

    this.spinner.show();
    //this.codigo_ibge = 0;
    this.listaCidades = [];
    this.pesquisaEnderecoMunicipio = Object.assign({ codigo_uf: this.index_uf['codigo'], quantidadeRegistrosPagina: 0 });
    this.enderecoMunicipio$.obterTodos(this.pesquisaEnderecoMunicipio).subscribe(
      retorno => {        
        this.listaCidades = retorno.total <= 0 ? [] : retorno.result;
        this.spinner.hide();
      }, 
      error => {
        this.spinner.hide();
        this.alert.Danger('Erro ao obter Cidades', Uteis.ObterErroApi(error));
      }
    );
  }

  municipioSelecionado(){
    this.municipio_selecionado.emit(this.index_municipio);
  }
}
