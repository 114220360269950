import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AutenticacaoService } from '@modules/autenticacao/services/autenticacao.service';
import { URLBASE, URLIMPRESSAO } from '@modules/configuration.service';
import { ConfiguracaoService } from '@modules/manager/sistema/configuracao/configuracao.service';
import { EtiquetaItemViewModel } from '@modules/manager/view-models/cadastro/estoque/etiqueta-item-view-model';
import { ApiRelatorioViewModel, RelatorioViewModel } from '@modules/manager/view-models/RelatorioViewModel';
import { RetornoApiViewModel } from '@modules/manager/view-models/RetornoApiViewModel';
import { Observable, throwError } from 'rxjs';
import { catchError, concatMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EtiquetaService {

  constructor(
    private http: HttpClient, 
    private autenticacao$: AutenticacaoService, 
    private configuracao$: ConfiguracaoService,
  ) { }

  idEmpresaMaxdata = this.autenticacao$.acessoUsuarioValue?.idEmpresaMaxdata;
  urlImpressaoRel = `${URLIMPRESSAO}/api/relatorio`;

  gerarEtiqueta(etiqueta: Array<any>, parametros): Observable<any>{

    let objRelatorio: RelatorioViewModel<any, EtiquetaItemViewModel[]> = new RelatorioViewModel<any, EtiquetaItemViewModel[]>();

    let logomarca: string = null;

    objRelatorio.obj = Object.assign( etiqueta, {} );    
  
    return this.configuracao$.obterImagem(this.autenticacao$.acessoUsuarioValue?.idLoja || 0)
    .pipe(
      concatMap(
        retorno => {
          logomarca = retorno.result[0].img_logomarca;
          return this.configuracao$.obterPorId(this.autenticacao$.acessoUsuarioValue?.idLoja || 0)
        }
      ),
      concatMap(
        retorno => {
          objRelatorio.params = Object.assign({
            emp_razao:                         retorno.total > 0 ? retorno.result[0].emp_razao                         : '',
            emp_fantasia:                      retorno.total > 0 ? retorno.result[0].emp_fantasia                      : '',
            emp_cpf_cnpj:                      retorno.total > 0 ? retorno.result[0].emp_cnpj                          : '',
            emp_fone:                          retorno.total > 0 ? retorno.result[0].emp_fone                          : '',
            emp_endereco:                      retorno.total > 0 ? retorno.result[0].emp_endereco                      : '',
            emp_endereco_municipio_descricao:  retorno.total > 0 ? retorno.result[0].emp_endereco_municipio_descricao  : '',
            emp_logo:                          '',
          }, parametros );

          delete objRelatorio.params['tipo_etiqueta'];
          delete objRelatorio.params['modelo'];

          const apiRelatorio = new ApiRelatorioViewModel<any, EtiquetaItemViewModel[]>();
          apiRelatorio.relatorio = objRelatorio;
          apiRelatorio.subrelatorio = [];
          
          return this.imprimir(`${parametros.modelo}`, apiRelatorio);

        }
      ),
      catchError(error => throwError(error))
    )

  }
  
  imprimir(rel: any, dados: any) {
    return this.http.post<any>(`${this.urlImpressaoRel}/${rel}/`, dados);
  }

}
