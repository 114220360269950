import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ClientePesquisaModalComponent } from "./cliente-pesquisa-modal/cliente-pesquisa-modal.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CustomFormsModule } from "ng2-validation";
import { DialogModule } from "primeng/dialog";
import { CheckboxModule } from "primeng/checkbox";
import { NgxSpinnerModule } from "ngx-spinner";
import { NgBrazil } from "ng-brazil";
import { ProdutoPesquisaModalComponent } from "./produto-pesquisa-modal/produto-pesquisa-modal.component";
import { ProdutoGrupoService } from "@modules/manager/cadastro/estoque/produto-grupo/produto-grupo.service";
import { ProdutoSubGrupoService } from "@modules/manager/cadastro/estoque/produto-sub-grupo/produto-sub-grupo.service";
import { ServicoPesquisaModalComponent } from "./servico-pesquisa-modal/servico-pesquisa-modal.component";
import { ProdutoGrupoPesquisaModalComponent } from "./produto-grupo-pesquisa-modal/produto-grupo-pesquisa-modal.component";
import { ProdutoSubGrupoPesquisaModalComponent } from "./produto-sub-grupo-pesquisa-modal/produto-sub-grupo-pesquisa-modal.component";
import { ProdutoFabricantePesquisaModalComponent } from "./produto-fabricante-pesquisa-modal/produto-fabricante-pesquisa-modal.component";
import { FretePesquisaModalComponent } from "./frete-pesquisa-modal/frete-pesquisa-modal.component";
import { MunicipioPesquisaModalComponent } from "./municipio-pesquisa-modal/municipio-pesquisa-modal.component";
import { VeiculoPesquisaModalComponent } from "./veiculo-pesquisa-modal/veiculo-pesquisa-modal.component";
import { NfModalComponent } from "./nf-modal/nf-modal.component";
import { EquipamentoPesquisaModalComponent } from "./equipamento-pesquisa-modal/equipamento-pesquisa-modal.component";
import { ProdutoEanService } from "@modules/manager/cadastro/estoque/produto-ean/produto-ean.service";
import { ProdutoFornecedorModalComponent } from "./produto-fornecedor-modal/produto-fornecedor-modal.component";
import { VendaPesquisaModalComponent } from "./venda-pesquisa-modal/venda-pesquisa-modal.component";
import { ProdutoGradeModalComponent } from "./produto-grade-modal/produto-grade-modal.component";
import { ProdutoLotePesquisaModalComponent } from './produto-lote-pesquisa-modal/produto-lote-pesquisa-modal.component';
import { MedicoPesquisaModalComponent } from "./medico-pesquisa-modal/medico-pesquisa-modal.component";
import { ProdutoEmbalagemVendaPesquisaModalComponent } from './produto-embalagem-venda/produto-embalagem-venda-pesquisa-modal/produto-embalagem-venda-pesquisa-modal.component';
import { TemplatesModule } from "../templates/templates.module";
import { CtePesquisaModalComponent } from "./cte-pesquisa-modal/cte-pesquisa-modal.component";
import { CadastroModalModule } from "../cadastro-modal/cadastro-modal.module";
import { ProdutoEmbalagemVendaCadastroModalComponent } from "./produto-embalagem-venda/produto-embalagem-venda-cadastro-modal/produto-embalagem-venda-cadastro-modal.component";
import { ProdutoConsultaEstoqueFiliaisComponent } from './produto-consulta-estoque-filiais/produto-consulta-estoque-filiais.component';

@NgModule({
  declarations: [
    ClientePesquisaModalComponent,
    ProdutoPesquisaModalComponent,
    ServicoPesquisaModalComponent,
    ProdutoGrupoPesquisaModalComponent,
    ProdutoSubGrupoPesquisaModalComponent,
    ProdutoFabricantePesquisaModalComponent,
    FretePesquisaModalComponent,
    MunicipioPesquisaModalComponent,
    NfModalComponent,
    VeiculoPesquisaModalComponent,
    EquipamentoPesquisaModalComponent,
    ProdutoFornecedorModalComponent,
    VendaPesquisaModalComponent,
    ProdutoGradeModalComponent,
    ProdutoLotePesquisaModalComponent,
    MedicoPesquisaModalComponent,
    ProdutoEmbalagemVendaPesquisaModalComponent,
    CtePesquisaModalComponent,
    ProdutoEmbalagemVendaCadastroModalComponent,
    ProdutoConsultaEstoqueFiliaisComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CustomFormsModule,
    DialogModule,
    CheckboxModule,
    NgxSpinnerModule,
    NgBrazil,
    TemplatesModule
  ],
  exports: [
    ClientePesquisaModalComponent,
    ProdutoPesquisaModalComponent,
    ServicoPesquisaModalComponent,
    ProdutoGrupoPesquisaModalComponent,
    ProdutoSubGrupoPesquisaModalComponent,
    ProdutoFabricantePesquisaModalComponent,
    FretePesquisaModalComponent,
    MunicipioPesquisaModalComponent,
    NfModalComponent,
    VeiculoPesquisaModalComponent,
    EquipamentoPesquisaModalComponent,
    ProdutoFornecedorModalComponent,
    VendaPesquisaModalComponent,
    ProdutoGradeModalComponent,
    ProdutoLotePesquisaModalComponent,
    MedicoPesquisaModalComponent,
    ProdutoEmbalagemVendaPesquisaModalComponent,
    CtePesquisaModalComponent,
    ProdutoEmbalagemVendaCadastroModalComponent
  ],
  providers: [ProdutoGrupoService, ProdutoSubGrupoService, ProdutoEanService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PesquisaModalModule {}
