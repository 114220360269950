<p-dialog [(visible)]="abrirFecharDioptriaArmacaoModal" [modal]="true" [closable]="true" [style]="{width: '90vw'}"
    [maximizable]="true" [baseZIndex]="10000" [draggable]="false" [resizable]="false" (onHide)="closeModalDioptriaArmacao()">
    <ngx-spinner name="modalAtualiza" bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-clip-rotate"
        [fullScreen]="false"></ngx-spinner>

        <div class="row  d-flex justify-content-between">
            <div class="col-mb">
                <h1 class="h4 border-left pl-2">
                    Formulário de Armação
                </h1>
            </div>
          
                <div class="btns-group">
    
                    <button class="btn btn-sm btn-outline-secondary" (click)="abrirFecharDioptriaArmacaoModal = false"><i
                            class="material-icons">close</i>Cancelar</button>
                    <button class="btn btn-sm btn-outline-success" id="salvar" type="button" (click)="onSubmit()"
                        [disabled]="carregando">
                        <div [ngSwitch]="carregando">
                            <div *ngSwitchCase="true">
                                <span class="spinner-border spinner-border-sm" role="status"
                                    aria-hidden="true"></span>&nbsp;
                                <span>Carregando ...</span>
                            </div>
                            <div *ngSwitchDefault>
                                <i class="material-icons">done_all</i><span>Salvar</span>
                            </div>
                        </div>
                    </button>
                </div>
           
                
        </div>
        

        <form (ngSubmit)="onSubmit()" [formGroup]="cadastroForm">

            <div class="card" >
    
                <div class="card-header">
                    Cadastro de Receita
                </div>
                <div class="card-body">
                    <div class="form-row" >
                        <div class="form-group col-md-4">
                            <label for="descricao" class="col-form-label-sm">Descrição</label>
                            <input type="text" class="form-control" id="descricao" formControlName="descricao">
                            
                        </div>
                                                                   
                        
                    </div>
    
                </div>

            </div>
            
           <!-- <div class="row float-right">
               <button class="btn btn-sm btn-outline-success" id="salvar" type="submit" >                       
                                  
                   <i class="material-icons">done_all</i><span>Salvar</span>
                          
                      
                   </button>
    
                   <button class="btn btn-sm btn-outline-maxdata" id="processar" type="button"
                   (click)="abrirFecharDioptriaArmacaoModal = false">Cancelar</button>

           </div> -->
                   
           
        </form>

</p-dialog>