import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { URLBASEADMIN } from '../../configuration.admin.service';
import { Observable } from 'rxjs';
import { ListaPaginadaViewModel } from 'src/modules/manager/view-models/ListaPaginadaViewModel';
import { RetornoApiViewModel } from 'src/modules/manager/view-models/RetornoApiViewModel';
import { EmpresaViewModel } from '../../view-models/cadastro/EmpresaViewModel';
import { EmpresaPesquisaViewModel } from '../../view-models/pesquisa/EmpresaPesquisaViewModel';
import { Uteis } from 'src/modules/utilizarios/uteis';
import { PesquisaGeralViewModel } from '../../view-models/pesquisa/PesquisaGeralViewModel';
import { CadastroViewModel } from '../../view-models/cadastro/CadastroViewModel';
import { ApiRelatorioViewModel, RelatorioViewModel } from '@modules/manager/view-models/RelatorioViewModel';
import { concatMap } from 'rxjs/operators';
import { ConfiguracaoService } from '@modules/manager/sistema/configuracao/configuracao.service';
import { AutenticacaoService } from '@modules/autenticacao/services/autenticacao.service';
import { URLIMPRESSAO } from '@modules/configuration.service';




@Injectable()
export class EmpresaService {
    urlGet = `${URLBASEADMIN}/empresa_adm_service/get`;
    urlAddUpdate = `${URLBASEADMIN}/empresa_adm_service/addupdate`;
    urlImpressaoRel = `${URLIMPRESSAO}/api/relatorio`;
    constructor(private http: HttpClient
        , private configuracao$: ConfiguracaoService
        , private autenticacao$: AutenticacaoService) { }

    obterTodos(pesquisa: EmpresaPesquisaViewModel): Observable<RetornoApiViewModel<EmpresaPesquisaViewModel[]>> {

        let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel();

        pesquisaGeral.select = 'id, descricao, id_maxdata, ip_database, ativo, qtd_max_usuario, dt_validade_chave, emp_cnpj';

        pesquisaGeral.calcRowCount = 1;

        pesquisaGeral.where = ` and ativo = ${pesquisa?.desativados ? 0 : 1}`;

        pesquisaGeral.limit = pesquisa?.quantidadeRegistrosPagina || 100;

        pesquisaGeral.order = pesquisa?.order ? pesquisa?.order : 'id'

        pesquisaGeral.start = pesquisa?.start || 0;

        if (pesquisa?.id) pesquisaGeral.where += ' and id = ' + pesquisa?.id;

        if (pesquisa?.id_maxdata) pesquisaGeral.where += ' and id_maxdata = ' + pesquisa?.id_maxdata;

        if (pesquisa?.descricao) pesquisaGeral.where += ` and lower(descricao) like lower('%` + pesquisa?.descricao + `%')`;

        return this.http.post<RetornoApiViewModel<EmpresaPesquisaViewModel[]>>(this.urlGet, pesquisaGeral);
    }

    obterPorId(id: number): Observable<RetornoApiViewModel<EmpresaViewModel[]>> {

        let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel();

        pesquisaGeral.select = `id, descricao, id_maxdata, ip_database, ativo, qtd_max_usuario, emp_cnpj`;


        pesquisaGeral.where = ` and id = ${id}`;

        return this.http.post<RetornoApiViewModel<EmpresaViewModel[]>>(this.urlGet, pesquisaGeral);
    }

    adicionar(objUsuario: CadastroViewModel<EmpresaViewModel>): Observable<RetornoApiViewModel<EmpresaViewModel>> {
        return this.http.post<RetornoApiViewModel<EmpresaViewModel>>(`${this.urlAddUpdate}`, objUsuario);
    }

    atualizar(id: number, objCadastro: CadastroViewModel<EmpresaViewModel>): Observable<RetornoApiViewModel<EmpresaViewModel>> {
        return this.http.put<RetornoApiViewModel<EmpresaViewModel>>(`${this.urlAddUpdate}/${id}`, objCadastro);
    }


    imprimirListagemEmpresas(pesquisa: EmpresaPesquisaViewModel): Observable<any> {


        let objRelatorio: RelatorioViewModel<any, any> = new RelatorioViewModel<any, any>();

        let logomarca: string = null;

        return this.obterTodos(pesquisa).pipe(
            concatMap(retorno => {

                objRelatorio.obj = retorno.total > 0 ? retorno.result : [];

                return this.configuracao$.obterImagem(this.autenticacao$.acessoUsuarioValue?.idLoja || 0)
            }),
            concatMap(retorno => {

                logomarca = retorno.result[0].img_logomarca

                return this.configuracao$.obterPorId(this.autenticacao$.acessoUsuarioValue?.idLoja || 0)
            }),
            concatMap(retorno => {
                objRelatorio.params = Object.assign({
                    emp_razao: retorno.total > 0 ? retorno.result[0].emp_razao : '',
                    emp_fantasia: retorno.total > 0 ? retorno.result[0].emp_fantasia : '',
                    emp_cpf_cnpj: retorno.total > 0 ? retorno.result[0].emp_cnpj : '',
                    emp_fone: retorno.total > 0 ? retorno.result[0].emp_fone : '',
                    emp_endereco: retorno.total > 0 ? retorno.result[0].emp_endereco : '',
                    emp_endereco_municipio_descricao: retorno.total > 0 ? retorno.result[0].emp_endereco_municipio_descricao : '',
                    emp_logo: logomarca
                });

                const apiRelatorio = new ApiRelatorioViewModel<any, any>();

                apiRelatorio.relatorio = Object.assign(objRelatorio);

                apiRelatorio.subrelatorio = [];

                return this.http.post<any>(`${this.urlImpressaoRel}/listagem_empresas/`, apiRelatorio);
            })
        )



    }

}