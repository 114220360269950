import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CadastroViewModel } from '@modules/admin/view-models/cadastro/CadastroViewModel';
import { PesquisaGeralViewModel } from '@modules/admin/view-models/pesquisa/PesquisaGeralViewModel';
import { AutenticacaoService } from '@modules/autenticacao/services/autenticacao.service';
import { URLBASE } from '@modules/configuration.service';
import { SubPlanoContaPesquisaViewModel } from '@modules/manager/view-models/cadastro/financeiro/pesquisa/sub-plano-conta-pesquisa-view-model';
import { SubPlanoContaViewModel } from '@modules/manager/view-models/cadastro/financeiro/sub-plano-conta-view-model';
import { RetornoApiViewModel } from '@modules/manager/view-models/RetornoApiViewModel';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SubPlanoContaService {

  private idEmpresaMaxdata = this.autenticacaoService.acessoUsuarioValue?.idEmpresaMaxdata;
  private urlGet =  `${URLBASE}/${this.idEmpresaMaxdata}/sub_plano_conta_service/get`;
  private urlAddUpdate  = `${URLBASE}/${this.idEmpresaMaxdata}/sub_plano_conta_service/addupdate`;

  constructor(private http: HttpClient, private autenticacaoService: AutenticacaoService){ }

  obterTodos(pesquisa: SubPlanoContaPesquisaViewModel) : Observable<RetornoApiViewModel<SubPlanoContaPesquisaViewModel[]>> {

    let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel()

    pesquisaGeral.select = `sub_plano_conta.id, 
                          sub_plano_conta.descricao,  
                          sub_plano_conta.ativo,
                          sub_plano_conta.tipo,
                          sub_plano_conta.plano_conta_id, 
                          plano_conta.descricao AS plano_conta_descricao`;

    pesquisaGeral.calcRowCount = 1;

    pesquisaGeral.limit = pesquisa?.quantidadeRegistrosPagina || 0;

    pesquisaGeral.where = `and sub_plano_conta.ativo = ${pesquisa?.desativados ? 0 : 1}`;

    pesquisaGeral.start = pesquisa?.start || 0;
    
    pesquisaGeral.order = pesquisa?.order ? pesquisa?.order : 'sub_plano_conta.id'

    if (pesquisa?.id) pesquisaGeral.where += ` and sub_plano_conta.id = ${pesquisa?.id}`;

    if(pesquisa?.plano_conta_id) pesquisaGeral.where += `and sub_plano_conta.plano_conta_id in (${pesquisa?.plano_conta_id})`;

    if (pesquisa?.descricao) pesquisaGeral.where += ` and lower(sub_plano_conta.descricao) like lower('%${pesquisa?.descricao}%')`;

    return this.http.post<RetornoApiViewModel<SubPlanoContaPesquisaViewModel[]>>(this.urlGet, pesquisaGeral)
  }

  obterPorId(id: number): Observable<RetornoApiViewModel<SubPlanoContaViewModel[]>>{

    let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel()

    pesquisaGeral.select = `sub_plano_conta.id, 
                          sub_plano_conta.descricao,  
                          sub_plano_conta.ativo,
                          sub_plano_conta.tipo,
                          sub_plano_conta.plano_conta_id, 
                          plano_conta.descricao AS plano_conta_descricao`;

    pesquisaGeral.where = ` and sub_plano_conta.id = ${id}`

    return this.http.post<RetornoApiViewModel<SubPlanoContaViewModel[]>>(`${this.urlGet}`, pesquisaGeral);
  }

  adicionar(subPlanoConta: CadastroViewModel<SubPlanoContaViewModel>) : Observable<RetornoApiViewModel<SubPlanoContaViewModel>>{
      return this.http.post<RetornoApiViewModel<SubPlanoContaViewModel>>(this.urlAddUpdate, subPlanoConta);
  }

  atualizar(id: number, subPlanoConta: CadastroViewModel<SubPlanoContaViewModel>) : Observable<RetornoApiViewModel<SubPlanoContaViewModel>>{
      return this.http.put<RetornoApiViewModel<SubPlanoContaViewModel>>(`${this.urlAddUpdate}/${id}`, subPlanoConta);
  }
}
