export class ClienteDioptriaViewModel {
    id?: number;
    cliente_id?: number;
    clinica_medico_id?: number;
    dioptria_tipo_armacao_id?: number;
    dt_ocorrencia?: string;
    dt_venc_receita?: string;
    longe_olho_dir_esferico?: string;
    longe_olho_dir_cilindrico?: string;
    longe_olho_dir_eixo?: string;
    longe_olho_dir_dnp?: string;
    longe_olho_dir_altura?: string;
    longe_olho_esq_esferico?: string;
    longe_olho_esq_cilindrico?: string;
    longe_olho_esq_eixo?: string;
    longe_olho_esq_dnp?: string;
    longe_olho_esq_altura?: string;
    longe_olho_dir_centro_optico?: string;
    longe_olho_esq_centro_optico?: string;
    perto_olho_dir_esferico?: string;
    perto_olho_dir_cilindrico?: string;
    perto_olho_dir_eixo?: string;
    perto_olho_dir_dnp?: string;
    perto_olho_dir_altura?: string;
    perto_olho_dir_adicao?: string;
    perto_olho_esq_esferico?: string;
    perto_olho_esq_cilindrico?: string;
    perto_olho_esq_eixo?: string;
    perto_olho_esq_dnp?: string;
    perto_olho_esq_altura?: string;
    perto_olho_esq_adicao?: string;
    perto_olho_dir_centro_optico?: string;
    perto_olho_esq_centro_optico?: string;
    armacao_ponte?: string;
    armacao_aro?: string;
    armacao_diag_maior?: string;
    armacao_altura?: string;
    obs?: string;
}