<div class="row">
  <div class="col-12 col-sm-6 col-md-4 col-lg-3">
    <strong>Imagem do Produto:</strong>
    <hr>
    <div class="my-custom-scrollbar" style="background-color: #f1f1f1; padding: 20px; max-height: 200px; display: flex; flex-direction: column; justify-content: center; align-items: center;">
      <div style="max-width: 100%; max-height: 100%;">
        <img [src]="url ? url : 'https://via.placeholder.com/300x150?text=Imagem'" style="width: 100%; height: auto; max-height: 100%;" class="img-thumbnail">
      </div>
    </div>
  </div>
  

  <div class="col-12 col-sm-6 col-md-4 col-lg-3">
    <strong>Aplicação:</strong>
    <hr>
    <div class="my-custom-scrollbar" style="background-color: #f1f1f1; padding: 20px; max-height: 200px;">
      <p>{{ aplicacaoProduto }}</p>
    </div>
  </div>

  <div class="col-12 col-sm-12 col-md-4 col-lg-6">
    <div class="d-flex justify-content-between">
      <strong class="text-left">Estoque de Empresas:</strong>
      <strong class="text-right">{{total_estoque}}</strong>
    </div>
    <hr>
    <div class="my-custom-scrollbar table-responsive" style="background-color: #f1f1f1; max-height: 200px; height: 200px; overflow-x: hidden;">
      <div class="row">
        <div class="col-12">
          <table class="table table-hover table-borderless table-striped table-sm" width="100%" id="table">
            <thead class="thead">
              <tr>
                <th>Empresa</th>
                <th class="text-right">Estoque</th>
                <th class="text-right">Valor Un.</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let estoque of produtoEstoqueEmpresas">
                <td class="text-nowrap w-descricao pr-2 w-descricao text-truncate">{{ estoque.emp_apelido || estoque.emp_razao }}</td>
                <td class="text-nowrap text-right pr-2">{{ estoque.qtd_estoque_atual }}</td>
                <td class="text-nowrap text-right pr-2">{{ estoque.vlr_venda | currency: 'BRL':'R$':'1.2-2'}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
