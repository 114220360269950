import { Injectable } from '@angular/core';
import { URLBASE, URLIMPRESSAO } from 'src/modules/configuration.service';
import { HttpClient } from '@angular/common/http';
import { AutenticacaoService } from 'src/modules/autenticacao/services/autenticacao.service';
import { PesquisaGeralViewModel } from '@modules/admin/view-models/pesquisa/PesquisaGeralViewModel';
import { Observable } from 'rxjs';
import { CadastroViewModel } from 'src/modules/admin/view-models/cadastro/CadastroViewModel';
import { ProdutoViewModel } from 'src/modules/manager/view-models/cadastro/estoque/produto-view-model';
import { ProdutoEmpresaViewModel } from 'src/modules/manager/view-models/cadastro/estoque/produto-empresa-view-model';
import { CadastroProdutoViewModel } from 'src/modules/manager/view-models/cadastro/estoque/cadastro-produto-view-model';
import { RetornoApiViewModel } from 'src/modules/manager/view-models/RetornoApiViewModel';
import { ProdutoPesquisaViewModel } from 'src/modules/manager/view-models/cadastro/estoque/pesquisa/produto-pesquisa-view-model';
import { concatMap } from 'rxjs/operators';
import { ApiRelatorioViewModel, RelatorioViewModel } from '@modules/manager/view-models/RelatorioViewModel';
import { ConfiguracaoService } from 'src/modules/manager/sistema/configuracao/configuracao.service';
import { RelatorioProdutoObjViewModel, RelatorioProdutoParamsViewModel } from '@modules/manager/view-models/cadastro/estoque/pesquisa/relatorio-produto-view-model';
import { ProdutoEmbalagemVendaPesquisaModalComponent } from '@modules/components/pesquisa-modal/produto-embalagem-venda/produto-embalagem-venda-pesquisa-modal/produto-embalagem-venda-pesquisa-modal.component';
import { ProdutoEmbalagemVendaViewModel } from '@modules/manager/view-models/cadastro/estoque/produto-embalagem-venda-view-model';

@Injectable({
  providedIn: 'root'
})
export class ProdutoService {

  idEmpresaMaxdata = this.autenticacao$.acessoUsuarioValue?.idEmpresaMaxdata;
  urlAPI = `${URLBASE}/${this.idEmpresaMaxdata}/produto_service`;
  urlGet = `${URLBASE}/${this.idEmpresaMaxdata}/produto_service/get`;

  urlAddUpdate = `${URLBASE}/${this.idEmpresaMaxdata}/produto_service/addupdate`;
  updateBlob = `${URLBASE}/${this.idEmpresaMaxdata}/produto_service/UpdateBlob`;
  getBlob = `${URLBASE}/${this.idEmpresaMaxdata}/produto_service/getBlob`;
  urlImpressaoRel = `${URLIMPRESSAO}/api/relatorio`;

  constructor(private http: HttpClient, private autenticacao$: AutenticacaoService, private configuracao$: ConfiguracaoService) { }

  obterTodos(pesquisa: ProdutoPesquisaViewModel, relatorio?: boolean): Observable<RetornoApiViewModel<ProdutoPesquisaViewModel[]>> {

    let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel();

    pesquisaGeral.select = `produto.id, 
                          produto.descricao, 
                          produto.peso, 
                          produto.qtd_com_entrada, 
                          produto.usa_grade, 
                          produto.usa_lote,
                          produto.usa_multiplo_embalagem, 
                          produto.produto_grupo_id, 
                          produto_sub_grupo_id, 
                          produto.produto_un_comercial_id, 
                          produto_empresa.produto_materia_prima_multiplo_qtd, 
                          produto_empresa.produto_materia_prima_usa_multiplo, 
                          produto_empresa.codigo as produto_empresa_codigo, 
                          produto_empresa.qtd_estoque_atual, 
                          produto_empresa.vlr_custo, 
                          produto_empresa.vlr_venda, 
                          produto_empresa.vlr_atacado, 
                          produto_empresa.altera_valor,
                          produto_empresa.oferta, 
                          produto_empresa.qtd_atacarejo, 
                          produto_empresa.usa_desconto_atacado, 
                          produto_empresa.vlr_promocao, 
                          produto_empresa.promocao_tem_vencimento, 
                          produto_empresa.promocao_data_vencimento, 
                          produto_empresa.usa_desconto, 
                          produto_empresa.usa_desconto_max_lanc_auto, 
                          produto_empresa.aliq_desconto_max, 
                          produto_empresa.usa_comissao, 
                          produto_empresa.aliq_comissao,
                          produto_empresa.altera_descricao,
                          produto.usa_fracionado,
                          UnComercial.un,                         
                          UnComercial.un as produto_un_comercial_un, 
                          produto_fabricante.descricao as produto_fabricante_descricao,
                          produto_empresa.qtd_estoque_atual,
                          produto.produto_ncm_id as produto_ncm_id , 
                          produto_ncm.codigo as produto_ncm_codigo,
                          produto_cest.codigo as produto_cest_codigo,
                          produto.aplicacao,
                          produto.apresentacao,
                          produto.msg,
                          (select produto_ean.codigo_ean from  produto_ean where produto_id = produto.id limit 1 )as codigo_barras`;

    pesquisaGeral.calcRowCount = 1;
    pesquisaGeral.limit = pesquisa?.quantidadeRegistrosPagina >= 0 ? pesquisa?.quantidadeRegistrosPagina : 100;

    pesquisaGeral.where = ` and produto_empresa.ativo = ${pesquisa?.desativados ? 0 : 1} and produto.tipo = '${pesquisa?.tipo ? pesquisa?.tipo : 'P'}' `;

    pesquisaGeral.order = `${pesquisa?.order ? pesquisa?.order : 'produto.id'}`

    pesquisaGeral.start = relatorio ? 0 : pesquisa?.start || 0;

    if (pesquisa?.id) {
      if (pesquisa.pesquisa_global_com_like) {
        pesquisaGeral.where += ` AND CAST(produto_id AS TEXT) LIKE '${pesquisa.id}%'`;
      } else {
        pesquisaGeral.where += ` AND produto.id = ${pesquisa.id}`;
      }
    }


    if (pesquisa?.descricao) pesquisaGeral.where += ` and lower(produto.descricao) like lower('%` + pesquisa?.descricao + `%')`;

    if (pesquisa?.produto_fabricante_descricao) pesquisaGeral.where += ` and lower(produto_fabricante.descricao) like lower('%${pesquisa?.produto_fabricante_descricao}%')`;

    if (pesquisa?.produto_grupo_id) pesquisaGeral.where += ` and produto.produto_grupo_id = ${pesquisa?.produto_grupo_id}`;

    if (pesquisa?.produto_sub_grupo_id) pesquisaGeral.where += ` and produto.produto_sub_grupo_id = ${pesquisa?.produto_sub_grupo_id}`;

    if (pesquisa?.produto_empresa_codigo) pesquisaGeral.where += ` and lower(produto_empresa.codigo) = lower('` + pesquisa?.produto_empresa_codigo + `')`;

    if (pesquisa?.tipo_sped) {

      if (Array.isArray(pesquisa?.tipo_sped)) {

        if (pesquisa?.tipo_sped.length > 0) pesquisaGeral.where += ` and produto.tipo_sped in (` + pesquisa?.tipo_sped + `)`;

      } else {
        pesquisaGeral.where += ` and lower(produto.tipo_sped) = lower('${pesquisa.tipo_sped}')`
      }


    }

    return this.http.post<RetornoApiViewModel<ProdutoPesquisaViewModel[]>>(this.urlGet, pesquisaGeral)
  }

  obterPorId(id: number, tipo?: string): Observable<RetornoApiViewModel<ProdutoViewModel[]>> {

    let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel();

    pesquisaGeral.select = `produto.*, 
                            produto_grupo.descricao as produto_grupo_descricao, 
                            produto_sub_grupo.descricao as produto_sub_grupo_descricao,
                            produto_fabricante.descricao as produto_fabricante_descricao`;

    pesquisaGeral.where = ` and produto.id = ${id} and produto.tipo = '${tipo ? tipo : 'P'}'`;

    return this.http.post<RetornoApiViewModel<ProdutoViewModel[]>>(`${this.urlGet}`, pesquisaGeral);
  }

  ObterTodosAutoComplete(pesquisaGeralViewModel: PesquisaGeralViewModel = new PesquisaGeralViewModel()): Observable<RetornoApiViewModel<ProdutoPesquisaViewModel>> {

    return this.http.post<RetornoApiViewModel<ProdutoPesquisaViewModel>>(this.urlGet, pesquisaGeralViewModel);
  }

  adicionar(produtoViewModel: CadastroProdutoViewModel<ProdutoViewModel, ProdutoEmpresaViewModel>): Observable<any> {

    return this.http.post<any[]>(this.urlAddUpdate, produtoViewModel);
  }

  atualizar(id: number, produtoViewModel: CadastroProdutoViewModel<ProdutoViewModel, ProdutoEmpresaViewModel>): Observable<any> {

    return this.http.put<any[]>(`${this.urlAddUpdate}/${id}`, produtoViewModel);
  }

  imprimir(pesquisa: ProdutoPesquisaViewModel): Observable<any> {

    let objRelatorio: RelatorioViewModel<RelatorioProdutoParamsViewModel, RelatorioProdutoObjViewModel[]> = new RelatorioViewModel<RelatorioProdutoParamsViewModel, RelatorioProdutoObjViewModel[]>();

    let logomarca: string = null;

    return this.obterTodos(pesquisa, true).pipe(
      concatMap(retorno => {

        objRelatorio.obj = retorno.total > 0 ? retorno.result : [];

        return this.configuracao$.obterImagem(this.autenticacao$.acessoUsuarioValue?.idLoja || 0)
      }),
      concatMap(retorno => {

        logomarca = retorno.result[0].img_logomarca

        return this.configuracao$.obterPorId(this.autenticacao$.acessoUsuarioValue?.idLoja || 0)
      }),
      concatMap(retorno => {

        objRelatorio.params = Object.assign({
          emp_cpf_cnpj: retorno.total > 0 ? retorno.result[0].emp_cnpj : '',
          emp_razao: retorno.total > 0 ? retorno.result[0].emp_razao : '',
          emp_fantasia: retorno.total > 0 ? retorno.result[0].emp_fantasia : '',
          emp_fone: retorno.total > 0 ? retorno.result[0].emp_fone : '',
          emp_endereco: retorno.total > 0 ? retorno.result[0].emp_endereco : '',
          emp_endereco_municipio_descricao: retorno.total > 0 ? retorno.result[0].emp_endereco_municipio_descricao : '',
          emp_logo: logomarca
        });

        const apiRelatorio = new ApiRelatorioViewModel<any, any>();

        apiRelatorio.relatorio = objRelatorio;

        apiRelatorio.subrelatorio = [];

        return this.http.post<any>(`${this.urlImpressaoRel}/produto/`, apiRelatorio);
      })
    )

  }

  atualizarImagem(id, dados): Observable<any> {
    return this.http.put<any>(`${this.updateBlob}/${id}`, dados);
  }

  getImage(id, dados): Observable<any> {
    return this.http.post<any>(`${this.getBlob}/${id}`, dados);
  }

  obterFornecedorPorProduto(produto_id: number): Observable<any> {
    return this.http.post<any>(`${this.urlAPI}/Get_Fornecedores/${produto_id}`, { id_produto: produto_id });
  }

  obterProdutoEmbalagem(produto_id: number) {

    let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel();

    pesquisaGeral.select = `0 AS produto_embalagem_venda_id
                          , produto.id as produto_id
                          , produto.descricao AS produto_descricao
                          , produto_empresa.vlr_venda
                          , produto.produto_un_comercial_id as produto_un_id
                          , UnComercial.un as produto_un_un
                          , 1 AS fator_conversao
                          , NULL as codigo_ean`;

    pesquisaGeral.where = ` and produto.id = ${produto_id} and lower(produto.tipo) = lower('P')`;

    return this.http.post<RetornoApiViewModel<ProdutoViewModel[]>>(`${this.urlGet}`, pesquisaGeral);

  }

}
