import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CadastroViewModel } from '@modules/admin/view-models/cadastro/CadastroViewModel';
import { PesquisaGeralViewModel } from '@modules/admin/view-models/pesquisa/PesquisaGeralViewModel';
import { AutenticacaoService } from '@modules/autenticacao/services/autenticacao.service';
import { URLBASE } from '@modules/configuration.service';
import { ProdutoEmbalagemVendaPesquisaViewModel } from '@modules/manager/view-models/cadastro/estoque/pesquisa/produto-embalagem-venda-pesquisa-view-model';
import { ProdutoEmbalagemVendaViewModel } from '@modules/manager/view-models/cadastro/estoque/produto-embalagem-venda-view-model';
import { RetornoApiViewModel } from '@modules/manager/view-models/RetornoApiViewModel';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProdutoEmbalagemVendaService {

  idEmpresaMaxdata = this.autenticacao$.acessoUsuarioValue?.idEmpresaMaxdata;

  private urlMaxApi = `${URLBASE}/${this.idEmpresaMaxdata}/produto_embalagem_venda_service`;
  private urlGet =  `${this.urlMaxApi}/get`;
  private urlAddUpdate  = `${this.urlMaxApi}/addupdate`;
  private urlDelete = `${this.urlMaxApi}/delete`;

  constructor(private http: HttpClient, private autenticacao$: AutenticacaoService){ }

  obterTodos(pesquisa: ProdutoEmbalagemVendaPesquisaViewModel) : Observable<RetornoApiViewModel<ProdutoEmbalagemVendaPesquisaViewModel[]>> {

    let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel();

    pesquisaGeral.select = `produto_embalagem_venda.id
                            , produto_embalagem_venda.produto_id
                            , produto.descricao as produto_descricao
                            , produto_embalagem_venda.produto_un_id
                            , produto_un.un as produto_un_un
                            , produto_un.descricao as produto_un_descricao
                            , produto_embalagem_venda.fator_conversao
                            , produto_embalagem_venda.vlr_venda
                            , produto_embalagem_venda.codigo_ean
                            , produto_embalagem_venda.emp_id
                            `;                           

    pesquisaGeral.start = pesquisa?.start || 0;

    pesquisaGeral.limit = pesquisa?.quantidadeRegistrosPagina || 100;

    pesquisaGeral.order = `${pesquisa?.order ? pesquisa?.order : 'produto_embalagem_venda.id'}`

    pesquisaGeral.where = ` AND produto_embalagem_venda.produto_id = ${pesquisa?.produto_id || 0}`;

    if(pesquisa?.id) pesquisaGeral.where += ` AND produto_embalagem_venda.id = ${pesquisa?.id || 0}`;
    if(pesquisa?.produto_un_id) pesquisaGeral.where += ` AND produto_embalagem_venda.produto_un_id = ${pesquisa?.produto_un_id || 0}`;
    if(pesquisa?.codigo_ean) pesquisaGeral.where += ` AND lower(produto_embalagem_venda.codigo_ean) = lower('${pesquisa?.codigo_ean}')`;

    return this.http.post<RetornoApiViewModel<ProdutoEmbalagemVendaPesquisaViewModel[]>>(this.urlGet, pesquisaGeral)
  }

  obterPorId(id: number): Observable<RetornoApiViewModel<ProdutoEmbalagemVendaPesquisaViewModel[]>> {

    let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel();

    pesquisaGeral.select = `produto_embalagem_venda.id
                            , produto_embalagem_venda.produto_id
                            , produto.descricao as produto_descricao
                            , produto_embalagem_venda.produto_un_id
                            , produto_un.un as produto_un_un 
                            , produto_un.descricao as produto_un_descricao
                            , produto_embalagem_venda.fator_conversao
                            , produto_embalagem_venda.vlr_venda
                            , produto_embalagem_venda.codigo_ean
                            , produto_embalagem_venda.emp_id
                            `;

    pesquisaGeral.where = ` and produto_embalagem_venda.id = ${id}`;

    return this.http.post<RetornoApiViewModel<ProdutoEmbalagemVendaPesquisaViewModel[]>>(`${this.urlGet}`, pesquisaGeral);
  }

  atualizar(id: number, produtoEquivalenteViewModel: CadastroViewModel<ProdutoEmbalagemVendaViewModel>) : Observable<RetornoApiViewModel<ProdutoEmbalagemVendaViewModel>>{
    return this.http.put<RetornoApiViewModel<ProdutoEmbalagemVendaViewModel>>(`${this.urlAddUpdate}/${id}`, produtoEquivalenteViewModel);
  }

  adicionar(produtoEquivalenteViewModel: CadastroViewModel<ProdutoEmbalagemVendaViewModel>) : Observable<RetornoApiViewModel<ProdutoEmbalagemVendaViewModel>>{
    return this.http.post<RetornoApiViewModel<ProdutoEmbalagemVendaViewModel>>(this.urlAddUpdate, produtoEquivalenteViewModel);
  }

  remover(id: number, produtoEquivalenteViewModel: CadastroViewModel<ProdutoEmbalagemVendaViewModel>) : Observable<RetornoApiViewModel<any>>{
    return this.http.request<RetornoApiViewModel<any>>('delete',`${this.urlDelete}/${id}`, {body: produtoEquivalenteViewModel})
  }

  obterPorCodigoBarras(codigo_barras: string) : Observable<RetornoApiViewModel<ProdutoEmbalagemVendaPesquisaViewModel[]>> {

    let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel();

    pesquisaGeral.select = `produto_embalagem_venda.id
                            , produto_embalagem_venda.produto_id
                            , produto.descricao as produto_descricao
                            , produto_embalagem_venda.produto_un_id
                            , produto_un.un as produto_un_un
                            , produto_un.descricao as produto_un_descricao
                            , produto_embalagem_venda.fator_conversao
                            , produto_embalagem_venda.vlr_venda
                            , produto_embalagem_venda.codigo_ean
                            , produto_embalagem_venda.emp_id
                            `;

    pesquisaGeral.calcRowCount = 1;

    pesquisaGeral.where = ` and lower(produto_embalagem_venda.codigo_ean) = lower('${codigo_barras}')`

    pesquisaGeral.limit = 1;

    return this.http.post<RetornoApiViewModel<ProdutoEmbalagemVendaPesquisaViewModel[]>>(`${this.urlMaxApi}/get`,pesquisaGeral)
  }

}