<div class="form-group">
  <label for="plano_conta_id" class="col-form-label-sm">Plano de Contas</label>
  <p-multiSelect name="plano_conta_id"
                inputId="plano_conta_id" 
                defaultLabel="-Selecione-" 
                optionLabel="descricao" 
                [showHeader]="true"
                [selectedItemsLabel]="'Plano de Contas Selecionados {0}'"
                [options]="listaPlanoContas"
                [(ngModel)]="plano_contas"
                (onPanelHide)="enviar()" 
                >
  </p-multiSelect>
</div>