import { Component, OnInit, Input } from '@angular/core';
import { TituloPagina } from 'src/modules/utilizarios/tituloPaginas/tituloPaginas';

@Component({
  selector: 'app-titulo-paginas',
  templateUrl: './titulo-paginas.component.html',
})
export class TituloPaginasComponent implements OnInit {

  @Input()
  tituloPagina!: TituloPagina;

  constructor() { }

  ngOnInit() {
  }

}
