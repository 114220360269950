import { number } from "ng-brazil/number/validator";

export class OperacaoViewModel {
    id?: number;
    descricao?: string;
    ativo?: boolean;
    natureza_operacao?: string;
    gera_finan?: boolean;
    gera_icms?: boolean;
    gera_credito?: boolean;
    gera_ipi?: boolean;
    tipo_estoque?: number;
    tipo_finan?: number;
    tipo_opr?: number | number[];
    usa_ref?: boolean;
    usa_preco_custo?: boolean;
    usa_vlr_neg?: boolean;
    destaca_msf?: boolean;
    altera_vlr?: boolean;
    destaca_no_caixa?: boolean;
    nao_enviar_sped?: boolean;
    nao_permite_venda_forn?: boolean;
    altera_vlr_tt_nf?: boolean;
    nao_enviar_inventario?: boolean;
    pedir_senha_gerente?: boolean;
    ciap?: boolean;
    gera_fcp_st?: boolean;
}
