import { PaginacaoViewModel } from '../../../PaginacaoViewModel';
import { ClienteEnderecoViewModel } from './../cliente-endereco-view-model';

export class ClienteEnderecoPesquisaViewModel extends ClienteEnderecoViewModel implements PaginacaoViewModel {
    pagina: number = 1;
    quantidadeRegistrosPagina: number = 20;
    totalRegistros: number = 0;
    totalPaginas: number = 0;
    desativados: boolean = false;
    endereco_descricao?: string;
    endereco_ativo?: boolean;
    endereco_endereco?: string;
    endereco_numero?: string;
    endereco_bairro?: string;
    endereco_cep?: string;
    endereco_municipio_codigo_ibge?: number;
    endereco_municipio_descricao?: string;
    endereco_municipio_ativo?: boolean;
    endereco_municipio_codigo_pais?: number;
    endereco_municipio_descricao_pais?: string;
    endereco_uf_codigo?: number;
    endereco_uf_sigla?: string;
    endereco_uf_descricao?: string;
    endereco_uf_ativo?: boolean;
    start?: number = 0;
    order?: string;
}