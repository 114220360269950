<div class="form-group">

    <div class="d-flex justify-content-between">
        <label class="from-chek-label" for="tecnico_id" class="col-form-label-sm">Técnicos</label>
        <p-checkbox *ngIf="mostrarDestativados" [binary]="true" label="Desativados" class=" mr-2" [(ngModel)]="tecnicosDesativados"
                    tooltip="Inclui no filtro todos os Técnicos desativados" placement="top" (onChange)=ngOnInit()>
        </p-checkbox>
    </div>
      
    <p-multiSelect name="tecnico_id" defaultLabel="-Selecione-" inputId="tecnico_id" optionLabel="nome" [showHeader]="true" 
                  [selectedItemsLabel]="'Técnicos Selecionados {0}'" [options]="listaTecnicos" [(ngModel)]="tecnicos" (onPanelHide)="enviar()" >
    </p-multiSelect>

</div>