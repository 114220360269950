<p-dialog [(visible)]="abrirFecharProdutoGradeDescricaoCadastroModal" [modal]="true" [closable]="true" [style]="{width: '100vw'}"
    [maximizable]="true" [baseZIndex]="10000" [draggable]="false" [resizable]="false" (onHide)="closeModalCadastroProdutoGradeDescricao()">
    <ngx-spinner name="modalAtualiza" bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-clip-rotate"
        [fullScreen]="false"></ngx-spinner>
    <p-header>Cadastrar Produto Grade Descrição</p-header>


    <form>
        <div class="d-flex justify-content-end">
            <div class="btns-group mb-1">
                <!-- <button class="btn btn-sm btn-outline-secondary" id="processar" type="button"
                    (click)="openModalEmpresaFiltroRef(modalEmpresaFiltroRef)" *ngIf="alterar"><i
                        class="material-icons">toc</i>Empresas</button> -->
                        <button class="btn btn-sm btn-outline-secondary" (click)="closeModalCadastroProdutoGradeDescricao()"><i
                            class="material-icons">close</i>Cancelar</button>
                <button class="btn btn-sm btn-outline-success" id="salvar" type="button" (click)="onSubmit()" [disabled]="carregando">
                    <div [ngSwitch]="carregando">
                        <div *ngSwitchCase="true">
                            <span class="spinner-border spinner-border-sm" role="status"
                                aria-hidden="true"></span>&nbsp;
                            <span>Carregando ...</span>
                        </div>
                        <div *ngSwitchDefault>
                            <i class="material-icons">done_all</i><span>Salvar</span>
                        </div>
                    </div>
                </button>
            </div>
        </div>
        <!-- <p-tabView>
            <p-tabPanel header="Produto Grade Descrição" class="first"  > -->
                <div class="card rounded-0" [formGroup]="cadastroForm">
                    <div class="card-body pt-1 pb-1">
                        <div class="row">
                            <div class="col-sm-4 col-md-4 col-lg-2 col-xl-1" >
                                <div class="form-group">
                                    <label for="id" class="col-form-label-sm">Código</label>
                                    <input type="number" id="id" name="id" class="form-control form-control-sm noscroll"
                                        placeholder="Código" formControlName="id">
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-8 col-lg-8 col-xl-6">
                                <div class="form-group">
                                    <label for="descricao" class="col-form-label-sm">Descrição</label>
                                    <input type="text" id="descricao" name="descricao"
                                        class="form-control form-control-sm" placeholder="Descrição"
                                        autocomplete="off" formControlName="descricao"
                                        [ngClass]="{'is-invalid': displayMessage?.descricao}" 
                                        [value]="cadastroForm?.get('descricao').value | lowercase"/>
                                    <span class="text-danger" *ngIf="displayMessage?.descricao">
                                        <p [innerHTML]="displayMessage?.descricao"></p>
                                    </span>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            <!-- </p-tabPanel> -->
        <!-- </p-tabView> -->
    </form>
</p-dialog>
