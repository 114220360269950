import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';

import { URLBASE } from 'src/modules/configuration.service';
import { Observable } from 'rxjs';
import { RetornoApiViewModel } from 'src/modules/manager/view-models/RetornoApiViewModel';

import { PesquisaGeralViewModel } from '@modules/admin/view-models/pesquisa/PesquisaGeralViewModel';
import { AutenticacaoService } from 'src/modules/autenticacao/services/autenticacao.service';
import { CadastroViewModel } from 'src/modules/admin/view-models/cadastro/CadastroViewModel';
import { ProdutoEanViewModel } from 'src/modules/manager/view-models/cadastro/estoque/produto-ean-view-model';
import { ProdutoEanPesquisaViewModel } from '@modules/manager/view-models/cadastro/estoque/pesquisa/produto-ean-pesquisa-view-model';

@Injectable()
export class ProdutoEanService {    
    idEmpresaMaxdata = this.autenticacaoService.acessoUsuarioValue?.idEmpresaMaxdata;
    
    private urlMaxApi = `${URLBASE}/${this.idEmpresaMaxdata}/produto_ean_service`;
    private urlGet =  `${this.urlMaxApi}/get`;
    private urlAddUpdate  = `${this.urlMaxApi}/addupdate`;
    private urlDelete = `${this.urlMaxApi}/delete`;

    constructor(private http: HttpClient, private autenticacaoService: AutenticacaoService){ }

    obterTodos(pesquisa: ProdutoEanPesquisaViewModel) : Observable<RetornoApiViewModel<ProdutoEanPesquisaViewModel[]>> {
        
        let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel();

        pesquisaGeral.select = `produto_ean.id,
                            produto_ean.produto_id,
                            produto_ean.codigo_ean,
                            produto_ean.chk_cx_fechada,
                            produto_ean.qtd_cx_fechada,
                            produto_ean.vlr_un_cx_fechada`

       if(pesquisa?.codigo_ean) pesquisaGeral.where = ` and lower(produto_ean.codigo_ean) like lower('${pesquisa?.codigo_ean || '0'}%')`

        if(pesquisa?.produto_id) pesquisaGeral.where += ` and produto_ean.produto_id = ${pesquisa?.produto_id || 0}`;

        return this.http.post<RetornoApiViewModel<ProdutoEanPesquisaViewModel[]>>(this.urlGet, pesquisaGeral)
    }

    adicionar(produtoEanViewModel: CadastroViewModel<ProdutoEanViewModel>) : Observable<RetornoApiViewModel<ProdutoEanViewModel>>{
        return this.http.post<RetornoApiViewModel<ProdutoEanViewModel>>(this.urlAddUpdate, produtoEanViewModel);
    }

    atualizar(id: number, produtoEanViewModel: CadastroViewModel<ProdutoEanViewModel>) : Observable<any>{
        return this.http.put<RetornoApiViewModel<ProdutoEanViewModel>>(`${this.urlAddUpdate}/${id}`, produtoEanViewModel);
    }

    remover(id: number, produtoEanViewModel: CadastroViewModel<ProdutoEanViewModel>) : Observable<RetornoApiViewModel<any>>{
        return this.http.request<RetornoApiViewModel<any>>('delete',`${this.urlDelete}/${id}`, {body: produtoEanViewModel})
    }

    obterPorCodigoBarras(codigo_barras: string) : Observable<RetornoApiViewModel<ProdutoEanPesquisaViewModel[]>> {

        let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel();

        pesquisaGeral.select = `produto_ean.id,
                            produto_ean.produto_id,
                            produto_ean.codigo_ean,
                            produto_ean.chk_cx_fechada,
                            produto_ean.qtd_cx_fechada,
                            produto_ean.vlr_un_cx_fechada`

        pesquisaGeral.where = ` and lower(produto_ean.codigo_ean) like lower('${codigo_barras || '0'}%')`
    
        pesquisaGeral.calcRowCount = 1;
    
        pesquisaGeral.where = ` and lower(produto_ean.codigo_ean) = lower('${codigo_barras}')`
    
        pesquisaGeral.limit = 1;
    
        return this.http.post<RetornoApiViewModel<ProdutoEanPesquisaViewModel[]>>(`${this.urlMaxApi}/get`,pesquisaGeral)
      }
}