import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertMessage } from '@modules/alert.configuration.service';
import { ClienteService } from '@modules/manager/cadastro/cliente-fornecedor/cliente/cliente.service';
import { ClientePesquisaViewModel } from '@modules/manager/view-models/cadastro/cliente-fornecedor/pesquisa/cliente-pesquisa-view-model';
import { Uteis } from '@modules/utilizarios/uteis';

@Component({
  selector: 'app-input-codigo-cliente',
  templateUrl: './input-codigo-cliente.component.html',
  styles: []
})
export class InputCodigoClienteComponent {


  @Input() cliente_id: number;
  @Input() tipoCadastro: [] = [];
  @Output() cliente: EventEmitter<any> = new EventEmitter();

  pesquisa: ClientePesquisaViewModel = new ClientePesquisaViewModel();

  abrirFecharPesquisaProdutoModal: boolean = false;
  abrirFecharModalCliente: boolean = false;

  constructor(
    private cliente$: ClienteService, 
    private alert: AlertMessage
  ) { }

  obterCliente() {

    this.pesquisa.id = this.cliente_id;

    this.pesquisa.tipo_cadastro = this.tipoCadastro.length > 0 ? this.tipoCadastro : undefined;

    this.cliente$.obterTodos(this.pesquisa).subscribe(
      (retorno) => {
        if (retorno.total > 0) {
          this.cliente.emit(retorno.result[0]);
        } else {
          this.alert.Info("Pesquisa", "Cliente / Fornecedor não encontrado");
        }
      },
      (error) => {
        this.alert.Danger("Erro", Uteis.ObterErroApi(error));
      }
    );
  }


}
