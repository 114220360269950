import { Injectable } from '@angular/core';
import { URLBASE } from 'src/modules/configuration.service';
import { HttpClient } from '@angular/common/http';
import { AutenticacaoService } from 'src/modules/autenticacao/services/autenticacao.service';
import { PesquisaGeralViewModel } from '@modules/admin/view-models/pesquisa/PesquisaGeralViewModel';
import { Observable } from 'rxjs';
import { CadastroViewModel } from 'src/modules/admin/view-models/cadastro/CadastroViewModel';
import { ProdutoEmpresaViewModel } from 'src/modules/manager/view-models/cadastro/estoque/produto-empresa-view-model';
import { RetornoApiViewModel } from 'src/modules/manager/view-models/RetornoApiViewModel';
import { ProdutoEmpresaPesquisaViewModel } from '@modules/manager/view-models/cadastro/estoque/pesquisa/produto-empresa-pesquisa-view-model';
import { produtoEstoque } from '@modules/components/pesquisa-modal/produto-consulta-estoque-filiais/produto-consulta-estoque-filiais.component';

@Injectable({
  providedIn: 'root'
})
export class ProdutoEmpresaService {
  idEmpresaMaxdata = this.autenticacao$.acessoUsuarioValue?.idEmpresaMaxdata;
    
  urlGet =  `${URLBASE}/${this.idEmpresaMaxdata}/produto_empresa_service/get`;

  urlAddUpdate  = `${URLBASE}/${this.idEmpresaMaxdata}/produto_empresa_service/addupdate`;

  constructor(private http: HttpClient, private autenticacao$: AutenticacaoService) { }

  obterTodos(pesquisa: ProdutoEmpresaPesquisaViewModel) : Observable<RetornoApiViewModel<ProdutoEmpresaPesquisaViewModel[]>> {

    let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel();

    pesquisaGeral.select = `produto_empresa.*`;

    pesquisaGeral.where = ` and produto_empresa.ativo = ${pesquisa?.desativados ? 0 : 1}`

    pesquisaGeral.start = pesquisa?.start || 0;

    pesquisaGeral.order = pesquisa?.order ? pesquisa?.order : 'produto_empresa.id';

    if(pesquisa?.id) pesquisaGeral.where = ` and produto_empresa.id = ${pesquisa?.id}`

    if(pesquisa?.produto_id) pesquisaGeral.where = ` and produto_empresa.produto_id = ${pesquisa?.produto_id}`

    return this.http.post<RetornoApiViewModel<ProdutoEmpresaPesquisaViewModel[]>>(this.urlGet,pesquisaGeral)
  }
  
  obterPorId(id: number, produto_id: number): Observable<RetornoApiViewModel<ProdutoEmpresaViewModel[]>>{

    let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel();

    pesquisaGeral.select = `produto_empresa.*`;

    pesquisaGeral.where = ` and produto_empresa.id = ${id}`;
  
    return this.http.post<RetornoApiViewModel<ProdutoEmpresaViewModel[]>>(`${this.urlGet}`,pesquisaGeral);
  }
  
  ObterTodosAutoComplete(pesquisaGeralViewModel: PesquisaGeralViewModel = new PesquisaGeralViewModel()) : Observable<RetornoApiViewModel<ProdutoEmpresaViewModel>> {   
  
      return this.http.post<RetornoApiViewModel<ProdutoEmpresaViewModel>>(this.urlGet, pesquisaGeralViewModel);
  }
  
  adicionar(adicionarViewModel: CadastroViewModel<ProdutoEmpresaViewModel>) : Observable<RetornoApiViewModel<ProdutoEmpresaViewModel>>{
  
      return this.http.post<RetornoApiViewModel<ProdutoEmpresaViewModel>>(this.urlAddUpdate, adicionarViewModel);
  }
  
  atualizar(id: number, atualizarViewModel: CadastroViewModel<ProdutoEmpresaViewModel>) : Observable<RetornoApiViewModel<ProdutoEmpresaViewModel>>{
      return this.http.put<RetornoApiViewModel<ProdutoEmpresaViewModel>>(`${this.urlAddUpdate}/${id}`, atualizarViewModel);
  }

  estoqueEmpresas(pesquisa: ProdutoEmpresaPesquisaViewModel) : Observable<RetornoApiViewModel<produtoEstoque[]>>{

    let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel();

    pesquisaGeral.select = `produto_empresa.emp_id, config.emp_razao, config.emp_apelido, produto_empresa.produto_id, produto_empresa.qtd_estoque_atual, produto_empresa.vlr_venda`; 

    pesquisaGeral.start = pesquisa?.start || 0;

    pesquisaGeral.order = pesquisa?.order ? pesquisa?.order : 'produto_empresa.qtd_estoque_atual desc';

    pesquisaGeral.consultarTodasEmpresas = pesquisa?.consultarTodasEmpresas ? pesquisa?.consultarTodasEmpresas : 0;    

   
    if(pesquisa?.produto_id) pesquisaGeral.where = ` and produto_empresa.produto_id = ${pesquisa?.produto_id} and  config.ativo = ${pesquisa?.desativados ? 0 : 1}`

    return this.http.post<RetornoApiViewModel<produtoEstoque[]>>(this.urlGet,pesquisaGeral)

  }
}
