export class NfViewModel {
    id?: number;
    emp_id?: number;
    numero?: number;
    serie?: string;
    sub_serie?: string;
    tipo?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | string | undefined ;
    operacao_id?: number;
    cliente_id?: number;
    cliente_nome?: string;
    status?: string;
    dt_emissao?: string;
    dt_saida?: string;
    tipo_nf?: 'S' | 'E' | undefined;
    tipo_frete?: number;
    msg_fisco?: string;
    transportadora_id?: number;
    transportadora_nome?: string;
    transportadora_placa?: string;
    transportadora_uf?: string;
    transportadora_qtde?: string;
    transportadora_especie?: string;
    transportadora_marca?: string;
    transportadora_peso_b?: string;
    transportadora_peso_l?: string;
    transportadora_reg_antt?: string;
    transportadora_numero_volume?: string;
    chave_nf?: string;
    mensagem?: string;
    nf_lote?: number;
    usa_nfe?: boolean;
    atendente?: number;
    tab_preco_id?: number;
    enviou_email?: boolean;
    //xmlDestinatario?: string;
    xml_destinatario_base_64?: string;
    xml_cancelamento_base_64?: string;
    cancelamento_motivo?: string;
    cancelamento_obs?: string;
    cancelamento_protocolo?: string;
    dt_cancelamento?: Date;
    cancelamento_usuario_id?: number;
    xml_inutilizacao_base_64?: string;
    inutilizacao_motivo?: string;
    inutilizacao_obs?: string;
    inutilizacao_protocolo?: string;
    dt_inutilizacao?: Date;
    inutilizacao_usuario_id?: number;
    usuario_lancou_id?: number;
    dt_lancamento?: string;
    dt_contabil?: string;
    tipo_ind_pagamento?: number;
    tipo_nf_sped: '00' | '01' | '02' | '03' | '04' | '05' | '06' | '07' | '08' | '09' | '10' | '55'| '65' | undefined;
    imposto_calc_auto?: boolean;
    imposto_add_ipi_na_base_subst?: number; 
    imposto_add_ipi_na_base_icms?: number; 
    imposto_add_ipi_reduzido_na_base_icms?: number; 
    imposto_add_frete_na_base_icms?: number; 
    imposto_rem_desconto_na_base_subst?: number; 
    imposto_add_seguro_na_base_icms?: number; 
    imposto_add_ii_na_base_ipi?: number; 
    imposto_add_outras_despesas_na_base_icms?: number; 
    imposto_usar_base_icms_na_base_subst?: number; 
    imposto_add_frete_na_base_pis?: number; 
    imposto_add_frete_na_base_ii?: number; 
    importacao_num_di?: number;
    dt_importacao_reg_di?: Date;
    importacao_local_desembaraco?: string;
    importacao_local_desembaraco_uf?: string;
    dt_importacao_desembaraco?: string;
    somente_servico?: boolean;
    simples_nacional?: number;
    obs_lanc_fiscal?: string;
    nfe_autorizado?: boolean;
    nfe_de_ajuste?: boolean;
    consumidor_final?: boolean;
    ambiente_homologacao?: boolean;
    nfe_pendente?: boolean;
    dt_nfe_recebimento?: Date;
    nf_recibo?: string;
    nf_protocolo?: string;
    produto_tabela_preco_id?: number;
    chave_nf_rej?: string;
    chave_nf_ref?: string;
    vlr_xml_entrada_frete?: number;
    vlr_xml_entrada_seg?: number;
    vlr_xml_entrada_outro?: number;
    vlr_xml_entrada_icms?: number;
    vlr_xml_entrada_bc?: number;
    vlr_xml_entrada_st?: number;
    vlr_xml_entrada_bcst?: number;
    vlr_xml_entrada_icms_deson?: number;
    vlr_xml_entrada_fcpst?: number;
    vlr_xml_entrada_ipi?: number;
    vlr_xml_entrada_nf?: number;
    xml_cce_base_64?: string;
    cce_motivo?: string;
    cce_obs?: string;
    cce_protocolo?: string;
    dt_cce?: string;
    cce_usuario_id?: number;
    cce_sequencial_evento?: string;
    nf_tipo_ajuste?: number;
    nf_tipo_complementar?: number;
    lst_nf_complementar_nat_opr?: number; 
    retirada_entrega_tipo?: 'E'| 'R'| undefined;               
    retirada_entrega_cliente_id?: number;       
    retirada_entrega_cliente_endereco_id?: number;
    retirada_entrega_cpf_cnpj?: string;           
    retirada_entrega_ie?: string;                 
    retirada_entrega_nome?: string;               
    retirada_entrega_endereco?: string;          
    retirada_entrega_numero?: string;            
    retirada_entrega_bairro?: string;           
    retirada_entrega_complemento?: string;  
    retirada_entrega_municipio_descricao?: string;     
    retirada_entrega_municipio_codigo_ibge?: number;
    retirada_entrega_uf_sigla?: string;          
    retirada_entrega_cep?: number;
    retirada_entrega_codigo_pais?: number;     
    retirada_entrega_fone?: string;               
    retirada_entrega_email?: string;
    cliente_endereco_id?: number;
    cliente_cpf_cnpj?:string;
    vlr_frete?: number;
    vlr_seguro?: number;
    vlr_outras_desp?: number;
    cte_tipo: number;
	cte_chave: string;
	cte_chave_referencia: string;
	cte_municipio_inicial_codigo_ibge: number;
	cte_municipio_final_codigo_ibge: number;
    energia_classe_consumo: string;
	energia_codigo_grupo_tensao: string;
	energia_codigo_tipo_ligacao: number;
	sped_codigo_situacao_doc: string;
	sped_data_mov: Date;
	nf_telecom_tipo_assinante: number;
}

// TipoNfSped
// 00 - Merc. para Revenda 
// 01 - Matéria-Prima
// 02 - Embalagem           
// 03 - Produto em Processo
// 04 - Produto Acabado
// 05 - Subproduto          
// 06 - Produto Intermediário
// 07 - Produto Intermediário
// 08 - Produto Intermediário
// 09 - Produto Intermediário
// 10 - Produto Intermediário
// 99 - Produto Intermediário


// tipo:
// 1 = Entrada NF
// 2 = Saida NF Pré-Impressa
// 3 = Saída NF-Eletrônica
// 4 = Entrada não fiscal
// 5 = Entrada neutra
// 6 = Entrada Nota de Consumo (Energia Elétrica, etc.)
// 7 = Entrada Nota de Consumo (Energia Elétrica, etc.) não fiscal
// 8 = Entrada NF Serviço
// 9 = Saida NF Servico
// 10 = Saída NFC-Eletrônica
// 11 = Saída CF-Eletrônica (SAT CF-e)
