import { Injectable } from '@angular/core';
import { URLBASE } from 'src/modules/configuration.service';
import { HttpClient } from '@angular/common/http';
import { AutenticacaoService } from 'src/modules/autenticacao/services/autenticacao.service';
import { PesquisaGeralViewModel } from '@modules/admin/view-models/pesquisa/PesquisaGeralViewModel';
import { Observable } from 'rxjs';
import { RetornoApiViewModel } from '../../view-models/RetornoApiViewModel';
import { NfItemPesquisaViewModel } from '../../view-models/fiscal/pesquisa/nf-item-pesquisa-view-model';
import { NfItemViewModel } from '../../view-models/fiscal/nf-item-view-model';
import { CadastroViewModel } from 'src/modules/admin/view-models/cadastro/CadastroViewModel';

@Injectable({
  providedIn: 'root'
})
export class NfItemService {
  
  idEmpresaMaxdata     = this.autenticacaoService.acessoUsuarioValue?.idEmpresaMaxdata;
  urlGet        =  `${URLBASE}/${this.idEmpresaMaxdata}/nf_item_service/get`;
  urlAddUpdate  = `${URLBASE}/${this.idEmpresaMaxdata}/nf_item_service/addupdate`;
  urlDelete     = `${URLBASE}/${this.idEmpresaMaxdata}/nf_item_service/delete`;

  constructor(private http: HttpClient, 
      private autenticacaoService: AutenticacaoService){ }

  obterTodos(pesquisa: NfItemPesquisaViewModel) : Observable<RetornoApiViewModel<NfItemPesquisaViewModel[]>> {

    let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel();

    pesquisaGeral.select = `nf_item.id,
                        nf_item.nf_id,
                        nf_item.produto_id,                        
                        produto.descricao as produto_descricao,
                        nf_item.cst,
                        nf_item.csosn,
                        UPPER(nf_item.un) as un,
                        nf_item.qtd,
                        nf_item.vlr_un,
                        UPPER(nf_item.un_com) as un_com,
                        nf_item.qtd_com,
                        nf_item.vlr_un_com,
                        nf_item.aliq_icms,
                        nf_item.aliq_icms_st,
                        nf_item.aliq_reducao,
                        nf_item.aliq_desconto,
                        nf_item.vlr_bc_icms,
                        nf_item.vlr_icms,
                        nf_item.cfop,
                        nf_item.lote,
                        nf_item.vlr_bc_icms_subst,
                        nf_item.vlr_icms_subst,
                        nf_item.aliq_iva,
                        nf_item.vlr_pmc,
                        nf_item.aliq_ipi,
                        nf_item.alterou_icms_manual,
                        nf_item.qtd_frac,
                        nf_item.aliq_sn,
                        nf_item.vlr_bc_icms_sn,
                        nf_item.vlr_icms_sn,
                        nf_item.vlr_frete,
                        nf_item.vlr_outras_desp,
                        nf_item.codigo_ncm,
                        nf_item.vlr_seguro,
                        nf_item.aliq_reducao_st,
                        nf_item.inf_ad_prod,
                        nf_item.cst_pis,
                        nf_item.aliq_pis,
                        nf_item.vlr_bc_pis,
                        nf_item.vlr_pis,
                        nf_item.cst_cofins,
                        nf_item.aliq_cofins,
                        nf_item.vlr_bc_cofins,
                        nf_item.vlr_cofins,
                        nf_item.cst_ipi,
                        nf_item.vlr_bc_ipi,
                        nf_item.vlr_ipi,
                        nf_item.aliq_issqn,
                        nf_item.aliq_ii,
                        nf_item.vlr_bc_ii,
                        nf_item.vlr_ii,
                        nf_item.vlr_bc_issqn,
                        nf_item.vlr_issqn,
                        nf_item.aliq_carga_media_icms_st,
                        nf_item.aliq_diferimento,
                        nf_item.vlr_icms_op,
                        nf_item.vlr_icms_dif,
                        nf_item.vlr_icms_deson,
                        nf_item.tipo_icms_deson_motivo,
                        nf_item.aliq_fcp_uf_dest,
                        nf_item.aliq_icms_uf_dest,
                        nf_item.aliq_icms_inter,
                        nf_item.aliq_icms_inter_part,
                        nf_item.vlr_fcp_uf_dest,
                        nf_item.vlr_icms_uf_dest,
                        nf_item.vlr_icms_uf_remet,
                        nf_item.aliq_ret_pis,
                        nf_item.vlr_ret_pis,
                        nf_item.aliq_ret_cofins,
                        nf_item.vlr_ret_cofins,
                        nf_item.aliq_ret_csll,
                        nf_item.vlr_ret_csll,
                        nf_item.aliq_bc_irrf,
                        nf_item.vlr_bc_irrf,
                        nf_item.aliq_irrf,
                        nf_item.vlr_irrf,
                        nf_item.aliq_bc_ret_prev,
                        nf_item.vlr_bc_ret_prev,
                        nf_item.aliq_ret_prev,
                        nf_item.vlr_ret_prev,
                        nf_item.aliq_fcp_st,
                        nf_item.vlr_fcp_st,
                        nf_item.vlr_bc_ret_csll,
                        nf_item.aliq_desconto_red_icms,
                        nf_item.vlr_bc_fcp_st,
                        nf_item.vlr_tt_desconto,
                        nf_item.tributacao_id,
                        nf_item.aliq_partilha_icms,
                        nf_item.vlr_bc_icms_uf_dest,
                        nf_item.chk_atualizou_estoque_movimento,
                        (COALESCE(nf_item.qtd_com, 0) *  COALESCE(nf_item.vlr_un_com,0)) as total_s_desc,
                        (COALESCE(nf_item.qtd_com, 0) * COALESCE(nf_item.vlr_un_com,0)) * (COALESCE(nf_item.aliq_desconto,0) / 100) as vlr_desc,
                        COALESCE(nf_item.qtd_com, 0) * COALESCE(nf_item.vlr_un_com,0) - (COALESCE(nf_item.qtd_com, 0) * COALESCE(nf_item.vlr_un_com,0)) * (nf_item.aliq_desconto / 100) as total_c_desc`;

    pesquisaGeral.where = ` and nf_item.nf_id = ${pesquisa?.nf_id || 0 }`;

    pesquisaGeral.order = pesquisa?.order ? pesquisa?.order : 'nf_item.id asc'; 

    pesquisaGeral.limit = pesquisa?.quantidadeRegistrosPagina || 0;

    pesquisaGeral.start = pesquisa?.start || 0; 
    
    return this.http.post<RetornoApiViewModel<NfItemPesquisaViewModel[]>>(this.urlGet, pesquisaGeral)
  }

  obterPorId(id: number, nf_id: number): Observable<RetornoApiViewModel<NfItemViewModel[]>>{

    let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel()

    pesquisaGeral.select = `nf_item.id,
                        nf_item.nf_id,
                        nf_item.produto_id,                        
                        produto.descricao as produto_descricao,
                        nf_item.cst,
                        nf_item.csosn,
                        nf_item.un,
                        nf_item.qtd,
                        nf_item.vlr_un,
                        nf_item.un_com,
                        nf_item.qtd_com,
                        nf_item.vlr_un_com,
                        nf_item.aliq_icms,
                        nf_item.aliq_icms_st,
                        nf_item.aliq_reducao,
                        nf_item.aliq_desconto,
                        nf_item.vlr_bc_icms,
                        nf_item.vlr_icms,
                        nf_item.cfop,
                        nf_item.lote,
                        nf_item.vlr_bc_icms_subst,
                        nf_item.vlr_icms_subst,
                        nf_item.aliq_iva,
                        nf_item.vlr_pmc,
                        nf_item.aliq_ipi,
                        nf_item.alterou_icms_manual,
                        nf_item.qtd_frac,
                        nf_item.aliq_sn,
                        nf_item.vlr_bc_icms_sn,
                        nf_item.vlr_icms_sn,
                        nf_item.vlr_frete,
                        nf_item.vlr_outras_desp,
                        nf_item.codigo_ncm,
                        nf_item.vlr_seguro,
                        nf_item.aliq_reducao_st,
                        nf_item.inf_ad_prod,
                        nf_item.cst_pis,
                        nf_item.aliq_pis,
                        nf_item.vlr_bc_pis,
                        nf_item.vlr_pis,
                        nf_item.cst_cofins,
                        nf_item.aliq_cofins,
                        nf_item.vlr_bc_cofins,
                        nf_item.vlr_cofins,
                        nf_item.cst_ipi,
                        nf_item.vlr_bc_ipi,
                        nf_item.vlr_ipi,
                        nf_item.aliq_issqn,
                        nf_item.aliq_ii,
                        nf_item.vlr_bc_ii,
                        nf_item.vlr_ii,
                        nf_item.vlr_bc_issqn,
                        nf_item.vlr_issqn,
                        nf_item.aliq_carga_media_icms_st,
                        nf_item.aliq_diferimento,
                        nf_item.vlr_icms_op,
                        nf_item.vlr_icms_dif,
                        nf_item.vlr_icms_deson,
                        nf_item.tipo_icms_deson_motivo,
                        nf_item.aliq_fcp_uf_dest,
                        nf_item.aliq_icms_uf_dest,
                        nf_item.aliq_icms_inter,
                        nf_item.aliq_icms_inter_part,
                        nf_item.vlr_fcp_uf_dest,
                        nf_item.vlr_icms_uf_dest,
                        nf_item.vlr_icms_uf_remet,
                        nf_item.aliq_ret_pis,
                        nf_item.vlr_ret_pis,
                        nf_item.aliq_ret_cofins,
                        nf_item.vlr_ret_cofins,
                        nf_item.aliq_ret_csll,
                        nf_item.vlr_ret_csll,
                        nf_item.aliq_bc_irrf,
                        nf_item.vlr_bc_irrf,
                        nf_item.aliq_irrf,
                        nf_item.vlr_irrf,
                        nf_item.aliq_bc_ret_prev,
                        nf_item.vlr_bc_ret_prev,
                        nf_item.aliq_ret_prev,
                        nf_item.vlr_ret_prev,
                        nf_item.aliq_fcp_st,
                        nf_item.vlr_fcp_st,
                        nf_item.vlr_bc_ret_csll,
                        nf_item.aliq_desconto_red_icms,
                        nf_item.vlr_bc_fcp_st,
                        nf_item.vlr_tt_desconto,
                        nf_item.tributacao_id,
                        nf_item.aliq_partilha_icms,
                        nf_item.vlr_bc_icms_uf_dest,
                        nf_item.chk_atualizou_estoque_movimento, 
                        (COALESCE(nf_item.qtd_com, 0) *  COALESCE(nf_item.vlr_un_com,0)) as total_s_desc,
                        (COALESCE(nf_item.qtd_com, 0) * COALESCE(nf_item.vlr_un_com,0)) * (COALESCE(nf_item.aliq_desconto,0) / 100) as vlr_desc,
                        COALESCE(nf_item.qtd_com, 0) * COALESCE(nf_item.vlr_un_com,0) - (COALESCE(nf_item.qtd_com, 0) * COALESCE(nf_item.vlr_un_com,0)) * (nf_item.aliq_desconto / 100) as total_c_desc`;


    pesquisaGeral.where = ` and nf.id = ${id} and nf.nf_id = ${nf_id}`;

    return this.http.post<RetornoApiViewModel<NfItemViewModel[]>>(`${this.urlGet}`,pesquisaGeral);
  }

  adicionar(nfViewModel: CadastroViewModel<NfItemViewModel>) : Observable<RetornoApiViewModel<NfItemViewModel[]>>{
      return this.http.post<RetornoApiViewModel<NfItemViewModel[]>>(this.urlAddUpdate, nfViewModel);
  }

  atualizar(id: number, nfViewModel: CadastroViewModel<NfItemViewModel>) : Observable<RetornoApiViewModel<NfItemViewModel>>{
      return this.http.put<RetornoApiViewModel<NfItemViewModel>>(`${this.urlAddUpdate}/${id}`, nfViewModel);
  }

  Remover(id: number, nfViewModel: CadastroViewModel<NfItemViewModel>) {
    return this.http.request('delete',`${this.urlDelete}/${id}`, {body: nfViewModel})
  }
}
