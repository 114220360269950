import { Component, OnInit, ViewChildren, ElementRef, AfterViewInit, TemplateRef, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControlName } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, fromEvent, merge } from 'rxjs';
import { isNumber } from 'util';
import { ProdutoGrupoViewModel } from 'src/modules/manager/view-models/cadastro/estoque/ProdutoGrupoViewModel';
import { ProdutoSubGrupoViewModel } from 'src/modules/manager/view-models/cadastro/estoque/ProdutoSubGrupoViewModel';
import { TituloPagina } from 'src/modules/utilizarios/tituloPaginas/tituloPaginas';
import { HistoricoNavegacao } from 'src/modules/utilizarios/tituloPaginas/historicoNavegacao';
import { AlertMessage } from 'src/modules/alert.configuration.service';
import { Uteis } from 'src/modules/utilizarios/uteis';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { PesquisaGeralViewModel } from '@modules/admin/view-models/pesquisa/PesquisaGeralViewModel';
import { CadastroViewModel } from 'src/modules/admin/view-models/cadastro/CadastroViewModel';
import { MensagensValidacao } from 'src/modules/validacao/mensagensValidacao';
import { AlertMensagemPadrao } from 'src/modules/utilizarios/alertMensagemPadrao';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfigModal } from '@modules/utilizarios/ConfigModal';
import { ProdutoGrupoPesquisaViewModel } from '@modules/manager/view-models/cadastro/estoque/pesquisa/ProdutoGrupoPesquisaViewModel';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DisplayMessage, GenericValidator, ValidationMessages } from '@modules/validacao/generic-form-validation';
import { ProdutoSubGrupoService } from '@modules/manager/cadastro/estoque/produto-sub-grupo/produto-sub-grupo.service';
import { ProdutoGrupoService } from '@modules/manager/cadastro/estoque/produto-grupo/produto-grupo.service';

@Component({
  selector: 'app-sub-grupo-cadastro-modal',
  templateUrl: './sub-grupo-cadastro-modal.component.html',
  styles: [
  ]
})
export class SubGrupoCadastroModalComponent implements OnInit {
  @Output() closeModal = new EventEmitter();
  @Input() abrirFecharCadastroGrupoModal: boolean = false;
  @Output() idCadastrado: EventEmitter<number> = new EventEmitter<number>();
  @ViewChildren(FormControlName, { read: ElementRef }) formInputElements!: ElementRef[];

  tituloPagina: TituloPagina = new TituloPagina('Sub-Grupo de Produto - Pesquisa',
    [
      new HistoricoNavegacao('Início', '/'),
      new HistoricoNavegacao('Cadastro'),
      new HistoricoNavegacao('Estoque'),
      new HistoricoNavegacao('Sub-Grupos de Produto', '/cadastro/estoque/sub-grupo-de-produto'),
      new HistoricoNavegacao('Cadastro'),
    ]);

  cadastroForm!: FormGroup;
  objProdutoSubGrupo: CadastroViewModel<ProdutoSubGrupoViewModel> = new CadastroViewModel<ProdutoSubGrupoViewModel>();
  alterar: boolean = false;
  grupos: ProdutoGrupoViewModel = new ProdutoGrupoViewModel();
  formResult: string = '';
  clickAddVariosRegistros: boolean = false;
  pesquisaProdutoGrupo: ProdutoGrupoPesquisaViewModel = new ProdutoGrupoPesquisaViewModel();
  // Autocomplete
  noResult = false;
  dataSourceAutocomplete: Observable<any>
  produto_grupo: ProdutoGrupoViewModel = new ProdutoGrupoViewModel();

  validationMessages!: ValidationMessages;
  genericValidator!: GenericValidator;
  displayMessage: DisplayMessage = {};
  carregando: boolean = false;
  modalEmpresaFiltroRef!: BsModalRef;
  tabela: string = 'produto_sub_grupo'

  constructor(private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private produtoSubGrupo$: ProdutoSubGrupoService,
    private produtoGrupo$: ProdutoGrupoService,
    private alert: AlertMessage,
    private spinner: NgxSpinnerService,
    private modal$: BsModalService,) {

    this.messagesValidations();

    this.dataSourceAutocomplete = Observable.create((observer: any) => {

      this.pesquisaProdutoGrupo = Object.assign({ descricao: this.produto_grupo.descricao })

      this.produtoGrupo$.obterTodos(this.pesquisaProdutoGrupo).subscribe(retorno => observer.next(retorno.total > 0 ? retorno.result : []));
    });
  }

  ngOnInit() {

    this.criarFormulario();

  }

  ngAfterViewInit(): void {
    let controlBlurs: Observable<any>[] = this.formInputElements
      .map((formControl: ElementRef) => fromEvent(formControl.nativeElement, 'blur'));

    merge(...controlBlurs).subscribe(() => {
      this.displayMessage = this.genericValidator.processarMensagens(this.cadastroForm);
    })
  }

  private messagesValidations() {
    this.validationMessages = {
      descricao: {
        required: MensagensValidacao.Obrigatorio('Descrição'),
        minlength: MensagensValidacao.MinimoCaracteres('Descrição', 2),
        maxlength: MensagensValidacao.MaximoCaracteres('Descrição', 50)
      },
      produto_grupo_descricao: {
        required: MensagensValidacao.Obrigatorio('Grupo')
      },
      aliq_comissao: {
        min: MensagensValidacao.ValorMinimo('Descrição', 0)
      },
    };
    this.genericValidator = new GenericValidator(this.validationMessages);
  }

  criarFormulario() {
    this.cadastroForm = this.fb.group({
      id: [{ value: !this.alterar ? 0 : this.objProdutoSubGrupo.obj?.id, disabled: true }],
      descricao: [!this.alterar ? '' : this.objProdutoSubGrupo.obj?.descricao, [Validators.required, Validators.minLength(2), Validators.maxLength(50)]],
      produto_grupo_id: [!this.alterar ? '' : this.objProdutoSubGrupo.obj?.produto_grupo_id, [Validators.required]],
      produto_grupo_descricao: [!this.alterar ? '' : this.objProdutoSubGrupo.obj?.produto_grupo_descricao, [Validators.required]],
      aliq_comissao: [!this.alterar ? 0 : this.objProdutoSubGrupo.obj?.aliq_comissao, [Validators.min(0)]],
      dt_cadastro: [!this.alterar ? Uteis.DateCadastroToString(new Date()) : this.objProdutoSubGrupo.obj?.dt_cadastro],
      ativo: [!this.alterar ? true : this.objProdutoSubGrupo.obj?.ativo]
    });
  }

  limparForm() {
    this.cadastroForm.reset({
      id: 0,
      descricao: '',
      aliq_comissao: 0,
      ativo: true
    });
  }

  onSubmit() {

    this.cadastroForm.markAllAsTouched();

    this.displayMessage = this.genericValidator.processarMensagens(this.cadastroForm);

    if (!this.cadastroForm.valid) {

      this.mensagemCamposInvalidos(this.displayMessage);

      return
    }

    if (!this.cadastroForm.dirty || !this.cadastroForm.valid) {

      this.alert.Info(AlertMensagemPadrao.formularioNaoAlterado.titulo, AlertMensagemPadrao.formularioNaoAlterado.mensagem);

      return
    };

    this.carregando = true;

    this.adicionar();
  }

  atualizarDadosObjeto() {

    this.objProdutoSubGrupo.obj = Object.assign({}, this.objProdutoSubGrupo.obj, this.cadastroForm.value);

    if (this.produto_grupo.id && this.objProdutoSubGrupo.obj) {

      this.objProdutoSubGrupo.obj = Object.assign({

        produto_grupo_id: this.produto_grupo.id,

        produto_grupo_descricao: this.produto_grupo.descricao

      }, this.objProdutoSubGrupo.obj)

    }

    this.produto_grupo = new ProdutoGrupoViewModel;
  }



  adicionar() {

    this.atualizarDadosObjeto();

    delete this.objProdutoSubGrupo.obj?.produto_grupo_descricao;

    this.produtoSubGrupo$.adicionar(this.objProdutoSubGrupo).subscribe(
      retorno => {

        this.mensagensCadastro();

        this.limparForm();
        this.carregando = false;
        this.idCadastrado.emit(retorno.result[0].id);
        this.closeCadastroSubGrupo();
      },
      error => {
        this.carregando = false;

        this.alert.Danger('Erro', Uteis.ObterErroApi(error));
      });
  }

  mensagemCamposInvalidos(mensagens: any) {

    let camposInvalidos = Uteis.ConverterObjetoToArray(mensagens);

    this.alert.Warning(AlertMensagemPadrao.formularioInvalido.titulo, AlertMensagemPadrao.formularioInvalido.mensagem + '<br><br>' + camposInvalidos.toString().replace(/,/g, ''));
  }

  private mensagensCadastro(): void {
    this.alert.Success('Sucesso', `Sub-Grupo de Produto ${this.alterar ? 'atualizado' : 'cadastrado'} com Sucesso.`);

  }

  onSelect(event: TypeaheadMatch): void {
    this.produto_grupo = event.item;
  };

  typeaheadNoResults(event: boolean): void {
    if (this.produto_grupo.descricao) this.noResult = event;
  };
  openCadastroSubGrupo() {
    this.abrirFecharCadastroGrupoModal = true;

  }
  closeCadastroSubGrupo() {
    this.closeModal.emit(false);
  }
}

