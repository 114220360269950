import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { URLBASEADMIN } from '../../configuration.admin.service';
import { Observable } from 'rxjs';
import { ListaPaginadaViewModel } from 'src/modules/manager/view-models/ListaPaginadaViewModel';
import { RetornoApiViewModel } from 'src/modules/manager/view-models/RetornoApiViewModel';
import { Uteis } from 'src/modules/utilizarios/uteis';
import { UsuarioPesquisaViewModel } from '../../view-models/pesquisa/UsuarioPesquisaViewModel';
import { UsuarioViewModel } from '../../view-models/cadastro/UsuarioViewModel';

import { PesquisaGeralViewModel } from '@modules/admin/view-models/pesquisa/PesquisaGeralViewModel';
import { CadastroViewModel } from '../../view-models/cadastro/CadastroViewModel';

const urlGet = `${URLBASEADMIN}/usuario_adm_service/get`;
const urlAddUpdate  = `${URLBASEADMIN}/usuario_adm_service/addupdate`;


@Injectable()
export class UsuarioService{
    constructor(private http: HttpClient){}

    obterTodos(pesquisa: UsuarioPesquisaViewModel): Observable<RetornoApiViewModel<UsuarioPesquisaViewModel[]>> {
        
        let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel()
        
        pesquisaGeral.select = 'id, email, chk_usu_adm, dt_cadastro, ativo,chk_usa_sistema';

        pesquisaGeral.calcRowCount = 1;

        pesquisaGeral.where = `and ativo = ${pesquisa?.desativados ? 0 : 1}`;

        pesquisaGeral.limit = pesquisa?.quantidadeRegistrosPagina || 100;

        pesquisaGeral.start = pesquisa?.start || 0; 

        pesquisaGeral.order = pesquisa?.order ? pesquisa?.order : 'id'

        if (pesquisa?.id) pesquisaGeral.where += ' and id = ' + pesquisa?.id;

        if (pesquisa?.email) pesquisaGeral.where += ` and lower(email) like lower('%` + pesquisa?.email + `%')`;

        return this.http.post<RetornoApiViewModel<UsuarioPesquisaViewModel[]>>(`${urlGet}`, pesquisaGeral);
    }

    obterPorId(id: number): Observable<RetornoApiViewModel<UsuarioViewModel[]>>{
        
        let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel()
        
        pesquisaGeral.select = 'id, email, chk_usu_adm, dt_cadastro, ativo, senha_hash, refresh_token,chk_usa_sistema,chk_primeiro_acesso';

        pesquisaGeral.where = ` and id = ${id}`

        return this.http.post<RetornoApiViewModel<UsuarioViewModel[]>>(urlGet,pesquisaGeral);
    }

    adicionar(objCadastro: CadastroViewModel<UsuarioViewModel>) : Observable<RetornoApiViewModel<UsuarioViewModel>>{
        return this.http.post<RetornoApiViewModel<UsuarioViewModel>>(`${urlAddUpdate}`, objCadastro);
    }

    atualizar(id: number, objCadastro: CadastroViewModel<UsuarioViewModel>) : Observable<RetornoApiViewModel<UsuarioViewModel>>{
        return this.http.put<RetornoApiViewModel<UsuarioViewModel>>(`${urlAddUpdate}/${id}`, objCadastro);
    }
}