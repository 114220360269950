import { PaginacaoViewModel } from '../../../PaginacaoViewModel';
import { ProdutoUnidadeViewModel } from '../produto-unidade-view-model';

export class ProdutoUnidadePesquisaViewModel extends ProdutoUnidadeViewModel implements PaginacaoViewModel {
    pagina: number = 1;
    quantidadeRegistrosPagina: number = 20;
    totalRegistros: number = 0;
    totalPaginas: number = 0;
    desativados: boolean = false;
    start?: number = 0;
    order?: string;
}