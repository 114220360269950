import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanLoad, CanActivateChild } from '@angular/router';
import { AutenticacaoService } from '../autenticacao/services/autenticacao.service';

@Injectable({ providedIn: 'root' })
export class AdminAuthGuard implements CanActivate, CanLoad {
    
    constructor(private router: Router, private autenticacaoService: AutenticacaoService){ }
  
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean{

        const acessoUsuario = this.autenticacaoService.acessoUsuarioValue;

        if(acessoUsuario && acessoUsuario.usuAdm === 1) return true;

        this.router.navigate(['/login'], { queryParams: { returnUrl:state.url }});

        return false;
    }

    canLoad(): boolean{

        const acessoUsuario = this.autenticacaoService.acessoUsuarioValue;

        if(acessoUsuario && acessoUsuario.usuAdm === 1) return true;

        this.router.navigate(['/']);
        
        return false;
    }
}