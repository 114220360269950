import { Component, OnInit} from '@angular/core';
import { PesquisaGeralViewModel } from '@modules/admin/view-models/pesquisa/PesquisaGeralViewModel';
import { ClienteService } from '../cadastro/cliente-fornecedor/cliente/cliente.service';
import { NfeService } from '../fiscal/nfe/nfe.service';
import { ClientePesquisaViewModel } from '../view-models/cadastro/cliente-fornecedor/pesquisa/cliente-pesquisa-view-model';
import { concatMap } from 'rxjs/operators';
import { NfPesquisaViewModel } from '../view-models/fiscal/pesquisa/nf-pesquisa-view-model';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  totalCliente: any;
  totalFonecedor: any;
  totalNfe: any;
  pesquisaCliente: ClientePesquisaViewModel = new ClientePesquisaViewModel();
  pesquisaNf: NfPesquisaViewModel = new NfPesquisaViewModel();

  constructor(private cliente$: ClienteService, private nfe$: NfeService
    ) { }

  ngOnInit() {
    this.pesquisar();
  }

  pesquisar(){
    this.pesquisaCliente = Object.assign({ tipo_cadastro: [0,2] })

    this.cliente$.obterTodos(this.pesquisaCliente)
    .pipe(
      concatMap(retorno => {

        this.totalCliente = retorno.total; 

        this.pesquisaCliente = Object.assign({ tipo_cadastro: 1 })

        return this.cliente$.obterTodos(this.pesquisaCliente)
      }), 
      concatMap(retorno => {
        
        this.totalFonecedor = retorno.total; 

        this.pesquisaNf = Object.assign({ nfe_autorizado: 1, quantidadeRegistrosPagina: 0 })

        return this.nfe$.obterTodos(this.pesquisaNf)
      }), 
    )
    .subscribe(retorno => this.totalNfe = retorno.total)
  }

}