export class UsuarioManagerViewModel {
    id?: number;
    id_usuario_adm?: number;
    nome?: string;
    apelido?: string;
    email?: string;
    cpf?: string;
    rg?: string;
    dt_nascimento?: string;
    fone?: string;
    endereco?: string;
    endereco_numero?: string;
    endereco_bairro?: string;
    endereco_cep?: string;
    endereco_cidade_codigo_ibge?: number;
    chk_menu_completo?: boolean;
    ativo?: boolean;
    chk_usa_sistema?: boolean;
    desconto_maximo?: number;
    aliq_comissao_produto?: number;
    aliq_comissao_servico?:number;
    emp_id?: number;
    chk_bloquear_consulta_venda_outro_vendedor?: boolean;
    chk_bloquear_consulta_nf_outro_atendente?: boolean;
}