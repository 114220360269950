import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CadastroViewModel } from '@modules/admin/view-models/cadastro/CadastroViewModel';
import { AlertMessage } from '@modules/alert.configuration.service';
import { ProdutoEmbalagemVendaService } from '@modules/manager/cadastro/estoque/produto-embalagem-venda/produto-embalagem-venda.service';
import { ProdutoUnidadeService } from '@modules/manager/cadastro/estoque/produto-unidade/produto-unidade.service';
import { ProdutoUnidadePesquisaViewModel } from '@modules/manager/view-models/cadastro/estoque/pesquisa/produto-unidade-pesquisa-view-model';
import { ProdutoEmbalagemVendaViewModel } from '@modules/manager/view-models/cadastro/estoque/produto-embalagem-venda-view-model';
import { AlertMensagemPadrao } from '@modules/utilizarios/alertMensagemPadrao';
import { Uteis } from '@modules/utilizarios/uteis';
import { DisplayMessage, GenericValidator, ValidationMessages } from '@modules/validacao/generic-form-validation';
import { MensagensValidacao } from '@modules/validacao/mensagensValidacao';
import { NgxSpinnerService } from 'ngx-spinner';
import { isNumber } from 'util';

@Component({
  selector: 'app-produto-embalagem-venda-cadastro-modal',
  templateUrl: './produto-embalagem-venda-cadastro-modal.component.html',
  styles: [
  ]
})
export class ProdutoEmbalagemVendaCadastroModalComponent implements OnInit {

  @Input() produtoId: number;
  @Input() produtoEmbalagemVenda!: ProdutoEmbalagemVendaViewModel;
  @Input() produtoEmbalagemVendaId: number;
  @Input() alterar: boolean = false;
  @Input() abrirFecharProdutoEmbalagemVendaCadastroModal: boolean = false;
  @Output() fecharModal: EventEmitter<boolean> = new EventEmitter();

  cadastroForm!: FormGroup;
  validationMessages?: ValidationMessages;
  genericValidator?: GenericValidator;
  displayMessage: DisplayMessage = {};
  carregando: boolean = false;
  objProdutoEmbalagemVenda: CadastroViewModel<ProdutoEmbalagemVendaViewModel> = new CadastroViewModel<ProdutoEmbalagemVendaViewModel>();
  listaProdutoUn: ProdutoUnidadePesquisaViewModel[] = [];

  constructor(private fb: FormBuilder,
    private alert: AlertMessage,    
    private spinner: NgxSpinnerService,
    private produtoEmbalagemVenda$: ProdutoEmbalagemVendaService,
    private produto_un$: ProdutoUnidadeService) {
      this.messagesValidations()
     }

  ngOnInit(): void {

    this.obterProdutoUn();

    this.criarFormulario();

      this.alterar = isNumber(this.produtoEmbalagemVendaId) && this.produtoEmbalagemVendaId > 0;

      if (this.alterar) {

        this.spinner.show();

        this.produtoEmbalagemVenda$.obterPorId(this.produtoEmbalagemVendaId|| 0).subscribe(
          retorno => {

            this.spinner.hide();

            if (retorno.total <= 0) this.alert.Danger('Erro', 'Registro não encontrado');

            this.objProdutoEmbalagemVenda.obj = retorno.total > 0 ? retorno.result[0] : new ProdutoEmbalagemVendaViewModel();

            this.criarFormulario();
          },
          error => {
            this.spinner.hide();

            this.alert.Danger('Erro', Uteis.ObterErroApi(error))
          });
      }
  }

  onSubmit() {

    this.cadastroForm.markAllAsTouched();

    if (!this.cadastroForm.valid) {

      if (typeof this.genericValidator !== 'undefined') {

        this.displayMessage = this.genericValidator.processarMensagens(this.cadastroForm);

      }

      let mensagem = Uteis.ConverterObjetoToArray(this.displayMessage)

      return this.mensagemCamposInvalidos(mensagem);
    }

    if (!this.cadastroForm.dirty) {

      this.alert.Info(AlertMensagemPadrao.formularioNaoAlterado.titulo, AlertMensagemPadrao.formularioNaoAlterado.mensagem);

      return
    }

    this.carregando = true;

    this.alterar ? this.atualizar() : this.adicionar();
  }

  criarFormulario() {
    this.cadastroForm = this.fb.group({
      id:              [{ value: !this.alterar ? 0 : this.objProdutoEmbalagemVenda.obj?.id, disabled: true }],
      produto_id:      [!this.alterar ? this.produtoId : this.objProdutoEmbalagemVenda.obj?.produto_id, [Validators.required, Validators.min(1)]],
      fator_conversao: [!this.alterar ? null : this.objProdutoEmbalagemVenda.obj?.fator_conversao, [Validators.required, Validators.min(0)]],
      produto_un_id:   [!this.alterar ? null : this.objProdutoEmbalagemVenda.obj?.produto_un_id],
      vlr_venda:       [!this.alterar ? null : this.objProdutoEmbalagemVenda.obj?.vlr_venda, []],
      codigo_ean:      [!this.alterar ? null : this.objProdutoEmbalagemVenda.obj?.codigo_ean, []],
      emp_id:          [!this.alterar ? 1 : this.objProdutoEmbalagemVenda.obj?.emp_id],
    })
  }

  atualizarDadosObjeto() {

    this.objProdutoEmbalagemVenda.obj = Object.assign({}, this.objProdutoEmbalagemVenda.obj, this.cadastroForm.value);
  }

  atualizar() {

    this.atualizarDadosObjeto();
    
    if (this.objProdutoEmbalagemVenda.obj?.id)
      this.produtoEmbalagemVenda$.atualizar(this.objProdutoEmbalagemVenda.obj?.id, this.objProdutoEmbalagemVenda).subscribe(
          () => {

            this.mensagensCadastro();

            this.closeModalCadastroProdutoEmbalagemVenda();

            this.carregando = false;

          },
          error => {

            this.carregando = false;

            this.alert.Danger('Erro', Uteis.ObterErroApi(error));

          }
        )
  }

  adicionar() {

    this.atualizarDadosObjeto();

    this.produtoEmbalagemVenda$.adicionar(this.objProdutoEmbalagemVenda).subscribe(
      retorno => {

        this.alert.Success('Sucesso','Registro cadastrado com sucesso');

        this.closeModalCadastroProdutoEmbalagemVenda();

        this.limparForm();

        this.carregando = false;
      },
      error => {

        this.carregando = false;

        this.alert.Danger('Erro', Uteis.ObterErroApi(error))

      }
    )
  }

  limparForm() {
    this.cadastroForm.reset();
  }

  obterProdutoUn(){
    let produtoUn: ProdutoUnidadePesquisaViewModel = new ProdutoUnidadePesquisaViewModel();

    produtoUn.start = 0; 

    produtoUn.quantidadeRegistrosPagina = 0;

    this.produto_un$.obterTodosProd(produtoUn).subscribe(

      retorno => this.listaProdutoUn = retorno.total > 0 ? retorno.result : [],

      err => this.alert.Danger("Erro", Uteis.ObterErroApi(err))
    );
  }

  messagesValidations() {
    this.validationMessages = {
      descricao: {
        required: MensagensValidacao.Obrigatorio('EmbalagemVenda'),
        minlength: MensagensValidacao.MinimoCaracteres('EmbalagemVenda', 2),
      },
    }
  }

  closeModalCadastroProdutoEmbalagemVenda() {
    this.fecharModal.emit(false);
  }

  private mensagensCadastro(): void {
    this.alert.Success('Sucesso', `Registro ${this.alterar ? 'atualizado' : 'cadastrado'} com Sucesso.`);
    if (this.alterar) return;
    this.alert.Info('Informativo', 'Formulário de cadastro foi limpo para realizar um novo cadastro.', { timeOut: 10000 });
  }

  mensagemCamposInvalidos(mensagens: any) {
    this.alert.Warning(AlertMensagemPadrao.formularioInvalido.titulo, AlertMensagemPadrao.formularioInvalido.mensagem + '<br><br>' + mensagens.toString().replace(/,/g, ''));
  }

}
