import { PaginacaoViewModel } from '../../../PaginacaoViewModel';
import { OperacaoViewModel } from '../operacao-view-model';

export class OperacaoPesquisaViewModel extends OperacaoViewModel implements PaginacaoViewModel {
    pagina: number = 1;
    quantidadeRegistrosPagina: number = 20;
    totalRegistros: number = 0;
    totalPaginas: number = 0;
    desativados: boolean = false;
    start?: number = 0;
    order?: string;
}
