import { Injectable } from '@angular/core';
import { URLBASE } from 'src/modules/configuration.service';
import { HttpClient } from '@angular/common/http';
import { AutenticacaoService } from 'src/modules/autenticacao/services/autenticacao.service';
import { PesquisaGeralViewModel } from '@modules/admin/view-models/pesquisa/PesquisaGeralViewModel';
import { Observable } from 'rxjs';
import { RetornoApiViewModel } from 'src/modules/manager/view-models/RetornoApiViewModel';
import { TributacaoPesquisaViewModel } from 'src/modules/manager/view-models/cadastro/contabil/pesquisa/tributacao-pesquisa-view-model';
import { CadastroViewModel } from 'src/modules/admin/view-models/cadastro/CadastroViewModel';
import { TributacaoViewModel } from 'src/modules/manager/view-models/cadastro/contabil/tributacao-view-model';

@Injectable({
  providedIn: 'root'
})
export class TributacaoService {

  idEmpresaMaxdata = this.autenticacaoService.acessoUsuarioValue?.idEmpresaMaxdata;
  urlGet =  `${URLBASE}/${this.idEmpresaMaxdata}/tributacao_service/get`;
  urlAddUpdate  = `${URLBASE}/${this.idEmpresaMaxdata}/tributacao_service/addupdate`;

  constructor(private http: HttpClient, private autenticacaoService: AutenticacaoService) { }

  obterTodos(pesquisa: TributacaoPesquisaViewModel) : Observable<RetornoApiViewModel<TributacaoPesquisaViewModel[]>> {

    let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel()

    pesquisaGeral.select = 'tributacao.id, tributacao.descricao, tributacao.ativo';

    pesquisaGeral.calcRowCount = 1;

    pesquisaGeral.start = pesquisa?.start || 0;

    pesquisaGeral.order = pesquisa?.order ? pesquisa?.order : 'descricao'; 

    pesquisaGeral.limit = pesquisa?.quantidadeRegistrosPagina;

    pesquisaGeral.where = `and tributacao.ativo = ${pesquisa?.desativados ? 0 : 1}`;

    if (pesquisa?.id) pesquisaGeral.where += ` and tributacao.id = ${pesquisa?.id} `;

    if (pesquisa?.descricao) pesquisaGeral.where += ` and lower(tributacao.descricao) like lower('%${pesquisa?.descricao}%')`;

    return this.http.post<RetornoApiViewModel<TributacaoPesquisaViewModel[]>>(this.urlGet,pesquisaGeral)
  }
  
  obterPorId(id: number): Observable<RetornoApiViewModel<TributacaoPesquisaViewModel[]>>{

    let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel()

    pesquisaGeral.select = 'tributacao.id, tributacao.descricao, tributacao.ativo';

    pesquisaGeral.where = ` and tributacao.id = ${id}`

    return this.http.post<RetornoApiViewModel<TributacaoPesquisaViewModel[]>>(`${this.urlGet}`,pesquisaGeral);
  }
  
  adicionar(tributacaoViewModel: CadastroViewModel<TributacaoViewModel>) : Observable<RetornoApiViewModel<TributacaoViewModel[]>>{
      return this.http.post<RetornoApiViewModel<TributacaoViewModel[]>>(this.urlAddUpdate, tributacaoViewModel);
  }
  
  atualizar(id: number, tributacaoViewModel: CadastroViewModel<TributacaoViewModel>) : Observable<RetornoApiViewModel<TributacaoViewModel>>{  
      return this.http.put<RetornoApiViewModel<TributacaoViewModel>>(`${this.urlAddUpdate}/${id}`, tributacaoViewModel);
  }
}
