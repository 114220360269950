import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertMessage } from '@modules/alert.configuration.service';
import { OperacaoService } from '@modules/manager/cadastro/contabil/operacao/operacao.service';
import { UsuarioManagerService } from '@modules/manager/cadastro/usuario-manager/usuario-manager.service';
import { VendaService } from '@modules/manager/movimentacao/venda/venda.service';
import { OperacaoPesquisaViewModel } from '@modules/manager/view-models/cadastro/contabil/pesquisa/operacao-pesquisa-view-model';
import { UsuarioManagerPesquisaViewModel } from '@modules/manager/view-models/cadastro/usuario-manager/pesquisa/usuario-manager-pesquisa-view-model';
import { ListaPaginadaViewModel } from '@modules/manager/view-models/ListaPaginadaViewModel';
import { VendaPesquisaViewModel } from '@modules/manager/view-models/movimentacao/pesquisa/venda-pesquisa-view-model';
import { Uteis } from '@modules/utilizarios/uteis';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-venda-pesquisa-modal',
  templateUrl: './venda-pesquisa-modal.component.html',
  styles: [
  ]
})
export class VendaPesquisaModalComponent implements OnInit {
  @Input() abrirFecharVendaPesquisaModal: boolean = false;
  @Input() stringPesquisa: string = '';
  @Output() closeModal = new EventEmitter();
  @Output() venda: EventEmitter<any> = new EventEmitter();
  pesquisaVenda: VendaPesquisaViewModel = new VendaPesquisaViewModel();
  pesquisaOperacao: OperacaoPesquisaViewModel = new OperacaoPesquisaViewModel();
  listaVenda: ListaPaginadaViewModel<VendaPesquisaViewModel> = new ListaPaginadaViewModel<VendaPesquisaViewModel>();
  listaOperacao: ListaPaginadaViewModel<OperacaoPesquisaViewModel> = new ListaPaginadaViewModel<OperacaoPesquisaViewModel>();
  listaVendedores: UsuarioManagerPesquisaViewModel[] = [];
  listaVendaSelecionada: Array<any> = [];
  selecionarTodasVenda: boolean = false;
  constructor(private route: ActivatedRoute,
    private router: Router,
    private venda$: VendaService,
    private operacao$: OperacaoService,
    private spinner: NgxSpinnerService,
    private alert: AlertMessage,
    private usuarioManager$: UsuarioManagerService,
  ) { }

  ngOnInit(): void {
    this.obterOperacao();
    this.obterListaVendedor();
  }

  pesquisarVenda() {

    this.listaVendaSelecionada = [];

    this.selecionarTodasVenda = false;

    this.pesquisaVenda.status = 'F';

    this.venda$.obterTodosGroup(this.pesquisaVenda).subscribe(
      retorno => {

        this.listaVenda.itens = retorno.total > 0 ? retorno.result : [];

        this.listaVenda.paginacao.totalRegistros = retorno.total;

        this.spinner.hide();
      },
      error => {

        this.spinner.hide();

        this.alert.Danger('Erro', Uteis.ObterErroApi(error));

      }
    )
  }

  selecionarVenda(todos?: boolean) {

    this.listaVendaSelecionada = [];

    this.selecionarTodasVenda = todos ? this.selecionarTodasVenda : todos;

    if (todos) this.listaVenda.itens.map(x => { if (todos) x.checked = this.selecionarTodasVenda; })

    this.listaVenda.itens.map(x => { if (x.checked) this.listaVendaSelecionada.push(x.id) })

  }

  vendaSelecionada() {

    if (this.listaVendaSelecionada.length <= 0) return this.alert.Info('Informação', 'Nenhuma venda selecionada');

    this.venda.emit(this.listaVendaSelecionada)
  }

  fecharVendaPesquisaModal() {
    this.closeModal.emit(false)
  }

  limparData() {

    this.pesquisaVenda.data_inicial = '';

    this.pesquisaVenda.data_final = '';

  }

  obterOperacao() {

    this.pesquisaOperacao = Object.assign({ quantidadeRegistrosPagina: 0 })

    this.operacao$.obterTodos(this.pesquisaOperacao).subscribe(
      retorno => {

        this.listaOperacao.itens = retorno.total > 0 ? retorno.result : [];

        this.listaOperacao.paginacao.totalRegistros = retorno.total;

        this.listaOperacao.paginacao.quantidadeRegistrosPagina = 0;

      },
      err => this.alert.Danger('Erro', Uteis.ObterErroApi(err))
    )
  }

  obterListaVendedor() {

    this.usuarioManager$.obterTodos().subscribe(

      retorno => this.listaVendedores = retorno.total > 0 ? retorno.result : [],

      err => this.alert.Danger('Erro', Uteis.ObterErroApi(err))

    );
  }

}
