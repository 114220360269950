import { PaginacaoViewModel } from '../../../PaginacaoViewModel';
import { ProdutoCestViewModel } from '../produto-cest-view-models';

export class ProdutoCestPesquisaViewModel extends ProdutoCestViewModel implements PaginacaoViewModel {
    pagina: number = 1;
    quantidadeRegistrosPagina: number = 20;
    totalRegistros: number = 0;
    totalPaginas: number = 0;
    desativados: boolean = false;
    start?: number = 0;
    order?: string;
}