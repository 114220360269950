<p-dialog [(visible)]="abrirFecharModalMedico" [modal]="true" [closable]="false" [style]="{width: '100vw'}"
  [maximizable]="true" [baseZIndex]="10000" [draggable]="false" [resizable]="false">
  <ngx-spinner name="modalAtualiza" bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-clip-rotate"
    [fullScreen]="false"></ngx-spinner>
  <p-header>Pesquisa de Cliente</p-header>
  <div class="row float-right">
    <button class="btn btn-sm btn-outline-info" type="button" (click)="pesquisar()"><i
        class="material-icons">search</i>Pesquisar</button>
  </div>
  <div class="row pl-0">
    <div class="col-sm-4 col-md-3 col-lg-2 col-xl-1">
      <div class="form-group">
        <label for="id" class="col-form-label-sm">Código</label>
        <input type="number" id="id" name="id" class="form-control form-control-sm noscroll" placeholder="Código"
          [(ngModel)]="pesquisa.id" (keydown.enter)="pesquisar()">
      </div>
    </div>
    <div class="col-sm-6 col-md-5 col-lg-3 col-xl-2">
      <label class="col-form-label-sm custom-select-label">Conselho</label>
      <select id="clinica_conselho_id" name="clinica_conselho_id" class="form-control form-control-sm"
        [(ngModel)]="pesquisa.clinica_conselho_id">
        <option value="null" SELECTED>-Selecione-</option>
        <option *ngFor="let item of listaConselho" [ngValue]="item.id">
          {{ item.descricao }}</option>
      </select>
    </div>
    <div class="col-sm-8 col-md-8 col-lg-4 col-xl-4">
      <div class="form-group">
          <label for="nome" class="col-form-label-sm">Nome</label>
          <input type="text" id="nome" name="nome" class="form-control form-control-sm"
              placeholder="Pesquise pela descrição" [(ngModel)]="pesquisa.nome">
      </div>
    </div>
    <div class="col-sm-6 col-md-5 col-lg-3 col-xl-2">
      <div class="form-group">
        <label for="sigla" class="col-form-label-sm">Registro</label>
        <input type="text" id="sigla" name="sigla"
        class="form-control form-control-sm" placeholder="Pesquise pelo Registro"
        [(ngModel)]="pesquisa.registro">
      </div>
    </div>
    <div class="col-sm-6 col-md-5 col-lg-3 col-xl-2">
      <div class="form-group">
          <label for="uf" class="col-form-label-sm">Estado UF</label>
          <select id="uf" name="uf" class="form-control form-control-sm" [(ngModel)]="pesquisa.uf">
              <option value=""  >-Selecione-</option>
              <option value="{{item.codigo}}" *ngFor="let item of listaEnderecoUf">
                  {{item.descricao}}</option>
          </select>
      </div>
  </div>
  </div>
  <div class="card-body pt-1 pb-1 border-top rounded-0 bg-white">
    <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div class="table-responsive">
                <table class="table table-sm table-borderless table-striped table-hover">
                    <thead class="thead">
                        <th (click)="ordenarLista('ID')">Código</th>
                        <th (click)="ordenarLista('descricao')">Conselho</th>
                        <th (click)="ordenarLista('nome')">Nome</th>
                        <th (click)="ordenarLista('registro')">Registro</th>
                        <th (click)="ordenarLista('uf')">UF</th>
                        <th class="text-right"></th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of listaPaginada.itens 
                            ">
                            <td>{{item.id}}</td>
                            <td>{{item.descricao}}</td>
                            <td>{{item.nome}}</td>
                            <td>{{item.registro}}</td>
                            <td>{{item.uf}}</td>
                            
                            <td class="text-right">
                                <!-- <a class="badge badge-info"><i class="material-icons">edit</i></a> -->
                            </td>
                        </tr>
                    </tbody>

                </table>
            </div>
        </div>
    </div>
</div>
  <ng-template pTemplate="footer">
    <button class="btn btn-sm btn-outline-secondary" (click)="closeModalCliente()">Cancelar</button>
  </ng-template>
</p-dialog>