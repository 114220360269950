<p-dialog [(visible)]="abrirFecharModalCliente" [modal]="true" [closable]="false" [style]="{width: '100vw'}"
    [maximizable]="true" [baseZIndex]="10000" [draggable]="false" [resizable]="false">
    <ngx-spinner name="modalAtualiza" bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-clip-rotate"
        [fullScreen]="false"></ngx-spinner>
    <p-header>Pesquisa de Cliente</p-header>
    <div class="row float-right">
        <button class="btn btn-sm btn-outline-info" type="button" (click)="pesquisar()"><i
                class="material-icons">search</i>Pesquisar</button>
    </div>
    <div class="row pl-0">
        <div class="col-sm-4 col-md-3 col-lg-2 col-xl-1">
            <div class="form-group">
                <label for="id" class="col-form-label-sm">Código</label>
                <input type="number" id="id" name="id" class="form-control form-control-sm noscroll"
                    placeholder="Código" [(ngModel)]="pesquisa.id" (keydown.enter)="pesquisar()">
            </div>
        </div>
        <div class="col-sm-8 col-md-6 col-lg-5 col-xl-4">
            <div class="form-group">
                <label for="nome" class="col-form-label-sm">Nome</label>
                <input type="text" id="nome" name="nome" class="form-control form-control-sm"
                    placeholder="Pesquise pelo Nome" [(ngModel)]="pesquisa.nome" (keydown.enter)="pesquisar()">
            </div>
        </div>
        <div class="col-sm-8 col-md-5 col-lg-4 col-xl-3">
            <div class="form-group">
                <label for="fantasia" class="col-form-label-sm">Fantasia</label>
                <input type="text" id="fantasia" name="fantasia" class="form-control form-control-sm"
                    placeholder="Pesquise pela Fantasia" [(ngModel)]="pesquisa.fantasia" (keydown.enter)="pesquisar()">
            </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-3 col-xl-2">
            <div class="form-group">
                <label for="cpf_cnpj" class="col-form-label-sm">CPF/CNPJ</label>
                <div class="input-group">
                    <input type="text" id="cpf_cnpj" name="cpf_cnpj" class="form-control form-control-sm"
                        placeholder="Pesquise pelo CPF/CNPJ" [(ngModel)]="pesquisa.cpf_cnpj"
                        (keydown.enter)="pesquisar()">
                </div>
            </div>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-3 col-xl-2">
            <div class="form-group">
                <label for="fone" class="col-form-label-sm">Fone</label>
                <div class="input-group">
                    <input type="text" id="fone" name="fone" class="form-control form-control-sm"
                        placeholder="Pesquise pelo Fone" [(ngModel)]="pesquisa.fone" (keydown.enter)="pesquisar()">
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div class="my-custom-scrollbar table-wrapper-scroll-y">
                <table class="table table-hover table-borderless table-striped table-sm " width="100%" id="table">
                    <thead class="thead">
                        <tr>
                            <th class="text-nowrap">Código</th>
                            <th class="text-nowrap">Nome / Razão</th>
                            <th class="text-nowrap">Fantasia</th>
                            <th class="text-nowrap">CPF / CNPJ</th>
                            <th class="text-nowrap">Telefone</th>
                            <th class="text-nowrap">Endereço</th>
                            <th class="text-nowrap">Cidade</th>
                            <th class="text-nowrap">UF</th>
                            <th class="text-nowrap text-center">Tipo</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="cursor" *ngFor="let item of listaClientes; let i = index;" 
                            (dblclick)="clienteSlecionado(item)"
                            (click)="clickRowIndex(i)"
                            [ngClass]="{'selected-line': (selectedRowIndex || 0) === i }">
                            <td class="text-nowrap pr-5">{{item.id}}</td>
                            <td class="text-nowrap pr-2 w-descricao text-truncate">{{item.nome}}</td>
                            <td class="text-nowrap pr-2">{{item.fantasia}}</td>
                            <td class="text-nowrap pr-2" *ngIf="item.tipo_pessoa === 0">{{item.cpf_cnpj | cpf}}</td>
                            <td class="text-nowrap pr-2" *ngIf="item.tipo_pessoa === 1">{{item.cpf_cnpj | cnpj}}</td>
                            <td class="text-nowrap pr-2">{{item.fone | telefone}}</td>
                            <td class="text-nowrap pr-2">{{item.endereco}}</td>
                            <td class="text-nowrap pr-2">{{item.cidade_descricao}}</td>
                            <td class="text-nowrap pr-2">{{item.uf_sigla}}</td>
                            <td class="text-center text-nowrap">
                                <div class="badge badge-primary text-nowrap" tooltip="Pessoa Física" placement="left" *ngIf="item.tipo_pessoa === 0">PF</div>
                                <div class="badge badge-primary text-nowrap" tooltip="Pessoa Jurídica" placement="left" *ngIf="item.tipo_pessoa === 1">PJ</div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <button class="btn btn-sm btn-outline-secondary" (click)="closeModalCliente()">Cancelar</button>
    </ng-template>
</p-dialog>