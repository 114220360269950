import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { AlertMessage } from '@modules/alert.configuration.service';
import { VendaService } from '@modules/manager/movimentacao/venda/venda.service';
import { VendaPesquisaViewModel } from '@modules/manager/view-models/movimentacao/pesquisa/venda-pesquisa-view-model';
import { Uteis } from '@modules/utilizarios/uteis';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-impressao-venda-modal',
  templateUrl: './impressao-venda-modal.component.html',
  styles: [
  ]
})
export class ImpressaoVendaModalComponent implements OnInit {
  @HostListener('window:keydown.Escape') escEvent() {
    this.closeModalImpressao();

  }
  @Input() venda_id: number = 0;
  @Input() abrirFecharModal: boolean = false;
  @Output() closeModal = new EventEmitter();

  loanding: boolean = false;
  constructor(
    private venda$: VendaService
    , private alert: AlertMessage
  ) { }

  ngOnInit(): void {

  }




  imprimirVenda() {

    let objVenda: VendaPesquisaViewModel = new VendaPesquisaViewModel();



    if (!this.venda_id) return

    this.loanding = true;

    this.venda$.imprimirDav(this.venda_id || 0, objVenda?.tipo || 'VE', 'dav_os').subscribe(
      retorno => {

        this.loanding = false;

        var link = retorno;

        this.closeModalImpressao();

        fetch(link).then(res => res.blob()).then(res => window.open(URL.createObjectURL(res), '_blank'));
      },
      err => {
        this.loanding = false;
        this.alert.Danger('Erro na Impressão', Uteis.ObterErroApi(err));
      }
    )
  }

  imprimirVendaBobinaTermica() {

    let objVenda: VendaPesquisaViewModel = new VendaPesquisaViewModel();



    if (!this.venda_id) return

    this.loanding = true;

    this.venda$.imprimirDav(this.venda_id || 0, objVenda?.tipo || 'VE', 'dav_venda_os_bobina_termica').subscribe(
      retorno => {
        var link = retorno;
        this.loanding = true;
        this.closeModalImpressao();
        fetch(link).then(res => res.blob()).then(res => window.open(URL.createObjectURL(res), '_blank'));
      },
      err => {
        this.loanding = false;
        this.alert.Danger('Impressão', Uteis.ObterErroApi(err))
      }
    );
  }

  closeModalImpressao() {
    this.abrirFecharModal = false;
    this.closeModal.emit(false);
  }
}
