import { PaginacaoViewModel } from '../../PaginacaoViewModel';
import { VendaViewModel } from '../venda-view-model';
import { VendaItemViewModel } from '../venda-item-view-model';

export class VendaPesquisaViewModel extends VendaViewModel implements PaginacaoViewModel, VendaItemViewModel{
    pagina:number = 1;
    quantidadeRegistrosPagina: number = 20;
    totalRegistros: number = 0;
    totalPaginas:number = 0;
    desativados: boolean = false;
    start?: number;
    order?: string;
    orcamento_id?: number;
    cliente_fantasia?: string;
    cliente_cpf_cnpj?: string;
    cliente_email?: string;
    cliente_fone?: string;
    cliente_celular?: string;
    cliente_grupo_id?: number;    
    data_inicial?: string;
    data_final?: string;
    filtro_data?: string;
    aliq_desc?: number;
    total_s_desc?: number;
    total_c_desc?: number;
    total_venda?:number;
    vendedor_nome?:string;
    tipo_comissao?: number;  
    total_servico_s_desc?: number;
    total_produto_s_desc?: number;
    venda_id?: number;
    produto_id?: number;
    qtd?: number;
    qtd_com?: number;
    vlr_un?: number;
    vlr_un_com?: number;
    vlr_un_cadastro?: number;
    vlr_un_cadastro_atacado?: number;
    vlr_custo_final?: number;
    aliq_desconto?: number;
    cst?: string;
    csosn?: string;
    venda_item_status?: string;
    dt_lancamento?: string;
    dt_abertura_inicial?: string;
    dt_abertura_final?: string;
    aliq_comissao?: number;
    lote?: string;
    un?: string;
    inf_ad_prod?: string;
    cfop?: number;
    vlr_bc_icms?: number;
    vlr_icms?: number;
    aliq_icms?: number;
    vlr_bc_ipi?: number;
    vlr_ipi?: number;
    aliq_ipi?: number;
    vlr_frete?: number;
    vlr_seguro?: number;
    vlr_outras_desp?: number;
    tributacao_id?: number;
    aliq_reducao?: number;
    aliq_reducao_st?: number;
    aliq_diferimento?: number;
    aliq_iva?: number;
    aliq_icms_st?: number;
    vlr_bc_icms_subst?: number;
    vlr_icms_subst?: number;
    aliq_partilha_icms?: number;
    aliq_icms_inter?: number;
    aliq_icms_inter_part?: number;
    aliq_icms_uf_dest?: number;
    vlr_bc_icms_uf_dest?: number;
    vlr_icms_uf_dest?: number;
    vlr_icms_uf_remet?: number;
    aliq_fcp_uf_dest?: number;
    vlr_fcp_uf_dest?: number;
    aliq_fcp_st?: number;
    vlr_bc_fcp_st?: number;
    vlr_fcp_st?: number;
    aliq_sn?: number;
    vlr_bc_icms_sn?: number;
    vlr_icms_sn?: number;
    cst_ipi?: string;
    aliq_carga_media_icms_st?: number;
    vlr_icms_op?: number;
    vlr_icms_dif?: number;
    cst_pis?: string;
    aliq_pis?: number;
    vlr_bc_pis?: number;
    vlr_pis?: number;
    cst_cofins?: string;
    aliq_cofins?: number;
    vlr_bc_cofins?: number;
    vlr_cofins?: number;
    vlr_base_calc?: number;
    vlr_base_subst?: number;
    vlr_base_ipi?: number;
    vlr_bc_subst?: number;
    vlr_subst?: number;
    aliq_st?: number;
    aliq_issqn?: number;
    vlr_bc_issqn?: number;
    vlr_issqn?: number;
    aliq_ii?: number;
    vlr_bc_ii?: number;
    vlr_ii?: number;
    codigo_ncm?: number;
    vlr_pmc?: number;
    aliq_ret_pis?: number;
    vlr_ret_pis?: number;
    aliq_ret_cofins?: number;
    vlr_ret_cofins?: number;
    aliq_ret_csll?: number;
    vlr_ret_csll?: number;
    aliq_bc_irrf?: number;
    vlr_bc_irrf?: number;
    aliq_irrf?: number;
    vlr_irrf?: number;
    aliq_bc_ret_prev?: number;
    vlr_bc_ret_prev?: number;
    aliq_ret_prev?: number;
    vlr_ret_prev?: number;
    vlr_bc_ret_csll?: number;
    aliq_desconto_red_icms?: number;
    vlr_icms_deson?: number;
    tipo_icms_deson_motivo?: number;
    qtd_origem?: number;
    chk_atualizou_estoque_movimento?: boolean;
    checked?: boolean;
    tipo_opr?: number;
    nfe?: number; 
    nfce?: number;
    placa?: string;
}
