import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertMessage } from '@modules/alert.configuration.service';
import { ProdutoSubGrupoService } from '@modules/manager/cadastro/estoque/produto-sub-grupo/produto-sub-grupo.service';
import { ProdutoGrupoPesquisaViewModel } from '@modules/manager/view-models/cadastro/estoque/pesquisa/ProdutoGrupoPesquisaViewModel';
import { ProdutoSubGrupoPesquisaViewModel } from '@modules/manager/view-models/cadastro/estoque/pesquisa/ProdutoSubGrupoPesquisaViewModel';
import { ProdutoSubGrupoViewModel } from '@modules/manager/view-models/cadastro/estoque/ProdutoSubGrupoViewModel';
import { Uteis } from '@modules/utilizarios/uteis';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-multiselect-sub-grupo',
  templateUrl: './multiselect-sub-grupo.component.html',
  styles: []
})
export class MultiselectSubGrupoComponent implements OnInit {

  @Input() grupoId: Array<string>;
  @Output() subGruposSelecionados: EventEmitter<any> = new EventEmitter<any>();
  listaSubGrupo: ProdutoSubGrupoViewModel[] = [];
  subgrupos: ProdutoSubGrupoViewModel[] = [];
  listaGrupos: ProdutoGrupoPesquisaViewModel[] = [];

  constructor(
    private spinner: NgxSpinnerService,
    private alert: AlertMessage, 
    private produtoSubGrupo$: ProdutoSubGrupoService,
    
  ) { }

  ngOnInit() {

    this.produtoSubGrupo$.obterTodos().subscribe(
      retorno => {
        this.listaSubGrupo = retorno.total > 0 ? retorno.result : [];        
      },
      error =>
        this.alert.Danger("Grupo de produto", Uteis.ObterErroApi(error))
    );

  }

  enviar() {
    this.subGruposSelecionados.emit(this.subgrupos);
  }

}
