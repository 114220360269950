import { PaginacaoViewModel } from '../../../PaginacaoViewModel';
import { ProdutoViewModel } from '../produto-view-model';
import { ProdutoEmpresaViewModel } from '@manager/view-models/cadastro/estoque/produto-empresa-view-model';

export class ProdutoPesquisaViewModel extends ProdutoViewModel implements PaginacaoViewModel, ProdutoEmpresaViewModel {
    pagina: number = 1;
    usa_comissao?: any;
    quantidadeRegistrosPagina: number = 20;
    totalRegistros: number = 0;
    totalPaginas: number = 0;
    desativados: boolean = false;
    start?: number = 0;
    order?: string;
    produto_empresa_codigo?: string;
    produto_ean_codigo_ean?: string;
    codigo_barras?: string;
    vlr_custo?: number;
    vlr_venda?: number;
    vlr_atacado?: number;
    qtd_estoque_atual?: number;
    produto_grade_id?: number;
    produto_embalagem_venda_id?: number;
    tipo_sped?: any = null;   
    pesquisa_global_com_like?:boolean = false;   
}
