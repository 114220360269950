import { PaginacaoViewModel } from '../../PaginacaoViewModel';
import { NfViewModel } from './../nf-view-model';

export class NfPesquisaViewModel extends NfViewModel implements PaginacaoViewModel{
    cliente_nome?: string;
    cliente_fantasia?: string;
    dt_emissao_inicial?: string;
    dt_emissao_final?: string;
    dt_lancamento_inicial?: string;
    dt_lancamento_final?: string;
    dt_contabil_inicial?: string;
    dt_contabil_final?: string;
    vlr_bc_icms?: number;
    vlr_icms?: number;
    vlr_icms_deson?: number;
    vlr_bc_icms_subst?: number;
    vlr_icms_subst?: number;
    vlr_bc_fcp_st?: number;
    vlr_fcp_st?: number;
    vlr_ipi?: number;
    vlr_ii?: number;
    vlr_frete?: number;
    vlr_seguro?: number;
    vlr_outras_desp?: number;
    total_s_desc?: number;
    vlr_total_prod?: number;
    vlr_desc?: number;
    total_c_desc?: number;
    vlr_bc_issqn?: number;
    vlr_issqn?: number;

    pagina:number = 1;
    quantidadeRegistrosPagina: number = 20;
    totalRegistros: number = 0;
    totalPaginas:number = 0;
    desativados: boolean = false;
    start?: number;
    order?: string;

}
