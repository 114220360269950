import { CurrencyPipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChildren } from '@angular/core';
import { FormBuilder, FormControlName, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PesquisaGeralViewModel } from '@modules/admin/view-models/pesquisa/PesquisaGeralViewModel';
import { AlertMessage } from '@modules/alert.configuration.service';
import { AutenticacaoService } from '@modules/autenticacao/services/autenticacao.service';
import { TributacaoService } from '@modules/manager/cadastro/contabil/tributacao/tributacao.service';
import { ProdutoCestService } from '@modules/manager/cadastro/estoque/produto-cest/produto-cest.service';
import { ProdutoFabricanteService } from '@modules/manager/cadastro/estoque/produto-fabricante/produto-fabricante.service';
import { ProdutoGrupoService } from '@modules/manager/cadastro/estoque/produto-grupo/produto-grupo.service';
import { ProdutoNcmCestService } from '@modules/manager/cadastro/estoque/produto-ncm-cest/produto-ncm-cest.service';
import { ProdutoNcmService } from '@modules/manager/cadastro/estoque/produto-ncm/produto-ncm.service';
import { ProdutoSubGrupoService } from '@modules/manager/cadastro/estoque/produto-sub-grupo/produto-sub-grupo.service';
import { ProdutoUnidadeService } from '@modules/manager/cadastro/estoque/produto-unidade/produto-unidade.service';
import { ProdutoService } from '@modules/manager/cadastro/estoque/produto/produto.service';
import { TributacaoViewModel } from '@modules/manager/view-models/cadastro/contabil/tributacao-view-model';
import { CadastroProdutoViewModel } from '@modules/manager/view-models/cadastro/estoque/cadastro-produto-view-model';
import { ProdutoFabricantePesquisaViewModel } from '@modules/manager/view-models/cadastro/estoque/pesquisa/produto-fabricante-pesquisa-view-model';
import { ProdutoPesquisaViewModel } from '@modules/manager/view-models/cadastro/estoque/pesquisa/produto-pesquisa-view-model';
import { ProdutoGrupoPesquisaViewModel } from '@modules/manager/view-models/cadastro/estoque/pesquisa/ProdutoGrupoPesquisaViewModel';
import { ProdutoSubGrupoPesquisaViewModel } from '@modules/manager/view-models/cadastro/estoque/pesquisa/ProdutoSubGrupoPesquisaViewModel';
import { ProdutoCestViewModel } from '@modules/manager/view-models/cadastro/estoque/produto-cest-view-models';
import { ProdutoEmpresaViewModel } from '@modules/manager/view-models/cadastro/estoque/produto-empresa-view-model';
import { ProdutoFormacaoPrecoViewModel } from '@modules/manager/view-models/cadastro/estoque/produto-formacao-preco-view-models';
import { ProdutoNcmViewModel } from '@modules/manager/view-models/cadastro/estoque/produto-ncm-view-model';
import { ProdutoViewModel } from '@modules/manager/view-models/cadastro/estoque/produto-view-model';
import { NfImportacaoXmlItemViewModel } from '@modules/manager/view-models/fiscal/nf-importacao-xml-item-view-model';
import { ListaPaginadaViewModel } from '@modules/manager/view-models/ListaPaginadaViewModel';
import { AlertMensagemPadrao } from '@modules/utilizarios/alertMensagemPadrao';
import { ConfigModal } from '@modules/utilizarios/ConfigModal';
import { ProdutoCst } from '@modules/utilizarios/produtoCst';
import { ProdutoSped } from '@modules/utilizarios/produtoSped';
import { QuantidadeItensPaginacao } from '@modules/utilizarios/quantidadeItensPaginacao';
import { Uteis } from '@modules/utilizarios/uteis';
import { DisplayMessage, GenericValidator, ValidationMessages } from '@modules/validacao/generic-form-validation';
import { MensagensValidacao } from '@modules/validacao/mensagensValidacao';
import { utilsBr } from 'js-brasil';

import { NgxSpinnerService } from 'ngx-spinner';
import { fromEvent, Observable, throwError, merge } from 'rxjs';
import { catchError, concatMap } from 'rxjs/operators';
import { ProdutoNcmPesquisaViewModel } from 'src/modules/manager/view-models/cadastro/estoque/pesquisa/produto-ncm-pesquisa-view-model';
import { ProdutoCestPesquisaViewModel } from '@modules/manager/view-models/cadastro/estoque/pesquisa/produto-cest-pesquisa-view-models';
import { ProdutoUnidadePesquisaViewModel } from '@modules/manager/view-models/cadastro/estoque/pesquisa/produto-unidade-pesquisa-view-model';
import { TributacaoPesquisaViewModel } from '@modules/manager/view-models/cadastro/contabil/pesquisa/tributacao-pesquisa-view-model';
import { ProdutoEmpresaPesquisaViewModel } from '@modules/manager/view-models/cadastro/estoque/pesquisa/produto-empresa-pesquisa-view-model';
import { ProdutoNcmCestPesquisaViewModel } from '@modules/manager/view-models/cadastro/estoque/pesquisa/produto-ncm-cest-pesquisa-view-model';
import { ProdutoEmpresaService } from '@modules/manager/cadastro/estoque/produto-empresa/produto-empresa.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { ProdutoEanViewModel } from '@modules/manager/view-models/cadastro/estoque/produto-ean-view-model';
import { CadastroViewModel } from '@modules/admin/view-models/cadastro/CadastroViewModel';
import { ProdutoEanService } from '@modules/manager/cadastro/estoque/produto-ean/produto-ean.service';
import { ProdutoEanPesquisaViewModel } from '@modules/manager/view-models/cadastro/estoque/pesquisa/produto-ean-pesquisa-view-model';
import { ProdutoFabricanteViewModel } from '@modules/manager/view-models/cadastro/estoque/produto-fabricante-view-model';
import { ProdutoGrupoViewModel } from '@modules/manager/view-models/cadastro/estoque/ProdutoGrupoViewModel';
import { ProdutoSubGrupoViewModel } from '@modules/manager/view-models/cadastro/estoque/ProdutoSubGrupoViewModel';

@Component({
  selector: 'app-produto-cadastro-modal',
  templateUrl: './produto-cadastro-modal.component.html',
  styleUrls: ['./produto-cadastro-modal-component.css']
})
export class ProdutoCadastroModalComponent implements OnInit {

  @Input() produto!: NfImportacaoXmlItemViewModel;
  @Input() alterar: boolean = false;
  @Input() abrirFecharProdutoCadastroModal: boolean = false;
  @Output() closeModal = new EventEmitter();
  @Output() produtoId: EventEmitter<number> = new EventEmitter();

  @ViewChildren(FormControlName, { read: ElementRef }) formInputElements!: ElementRef[];

  public MASKS = utilsBr.MASKS;

  cadastroForm!: FormGroup;

  pesquisa: ProdutoPesquisaViewModel = new ProdutoPesquisaViewModel();
  pesquisaEmpresa: ProdutoEmpresaPesquisaViewModel = new ProdutoEmpresaPesquisaViewModel();
  pesquisaProdutoNcm: ProdutoNcmPesquisaViewModel = new ProdutoNcmPesquisaViewModel();
  pesquisaProdutoNcmCest: ProdutoNcmCestPesquisaViewModel = new ProdutoNcmCestPesquisaViewModel();
  pesquisaProdutoUn: ProdutoUnidadePesquisaViewModel = new ProdutoUnidadePesquisaViewModel();
  pesquisaProdutoFabricante: ProdutoFabricantePesquisaViewModel = new ProdutoFabricantePesquisaViewModel();
  pesquisaProdutoGrupo: ProdutoGrupoPesquisaViewModel = new ProdutoGrupoPesquisaViewModel();
  pesquisaProdutoSubGrupo: ProdutoSubGrupoPesquisaViewModel = new ProdutoSubGrupoPesquisaViewModel();
  pesquisaProdutoCest: ProdutoCestPesquisaViewModel = new ProdutoCestPesquisaViewModel();
  pesquisaTributacao: TributacaoPesquisaViewModel = new TributacaoPesquisaViewModel();
  objProduto: CadastroProdutoViewModel<ProdutoViewModel, ProdutoEmpresaViewModel> = new CadastroProdutoViewModel<ProdutoViewModel, ProdutoEmpresaViewModel>();

  objFormacaoPreco: ProdutoFormacaoPrecoViewModel = new ProdutoFormacaoPrecoViewModel();
  // listaPaginada: ListaPaginadaViewModel<ProdutoViewModel> = new ListaPaginadaViewModel<ProdutoViewModel>();
  listaFabricante: ListaPaginadaViewModel<ProdutoFabricantePesquisaViewModel> = new ListaPaginadaViewModel<ProdutoFabricantePesquisaViewModel>();
  listaGrupo: ListaPaginadaViewModel<ProdutoGrupoPesquisaViewModel> = new ListaPaginadaViewModel<ProdutoGrupoPesquisaViewModel>();
  listaSubGrupo: ListaPaginadaViewModel<ProdutoSubGrupoPesquisaViewModel> = new ListaPaginadaViewModel<ProdutoSubGrupoPesquisaViewModel>();
  listaTributacao: ListaPaginadaViewModel<TributacaoViewModel> = new ListaPaginadaViewModel<TributacaoViewModel>();
  listaItensPaginacao: QuantidadeItensPaginacao[] = QuantidadeItensPaginacao.listaQuantidades;
  produtoCst1: ProdutoCst[] = ProdutoCst.cst1;
  listaProdutoSped: ProdutoSped[] = ProdutoSped.tipo_sped;
  produto_grupo: ProdutoGrupoViewModel = new ProdutoGrupoViewModel();
  formResult: string = '';
  clickAddVariosRegistros: boolean = false;
  noResult = false;
  produtoNcmAutoComplete: Observable<any> = new Observable;
  produtoCestAutoComplete: Observable<any> = new Observable;
  produtoNcmCest: Observable<any> = new Observable;

  validationMessages?: ValidationMessages;
  genericValidator?: GenericValidator;

  displayMessage: DisplayMessage = {};
  displayMessageProduto: DisplayMessage = {};
  displayMessageProdEmpresa: DisplayMessage = {};
  dataSourceAutocomplete: Observable<any>
  modalPesquisarFabricante: boolean = false;
  modalCadastrarFabricante: boolean = false;
  modalPesquisarGrupo: boolean = false;
  modalCadastrarGrupo: boolean = false;
  modalCadastrarSubGrupo: boolean = false;
  modalPesquisarSubGrupo: boolean = false;
  objProdutoSubGrupo: CadastroViewModel<ProdutoSubGrupoViewModel> = new CadastroViewModel<ProdutoSubGrupoViewModel>();
  objProdutoFabricante: CadastroViewModel<ProdutoFabricanteViewModel> = new CadastroViewModel<ProdutoFabricanteViewModel>();
  objProdutoGrupo: CadastroViewModel<ProdutoGrupoViewModel> = new CadastroViewModel<ProdutoGrupoViewModel>();

  listaProdutoUn: {} = [];
  listaProdutoCest: Array<any> = [];
  $: any;

  produto_ncm: ProdutoNcmViewModel = new ProdutoNcmViewModel();
  produto_cest: ProdutoCestViewModel = new ProdutoCestViewModel();

  listaCodigoBarra: ListaPaginadaViewModel<ProdutoEanViewModel> = new ListaPaginadaViewModel<ProdutoEanViewModel>();
  objProdutoEan: CadastroViewModel<ProdutoEanViewModel> = new CadastroViewModel<ProdutoEanViewModel>();
  pesquisaProdutoEan: ProdutoEanPesquisaViewModel = new ProdutoEanPesquisaViewModel();

  noResultProdutoNcm: boolean = false;

  isCollapsed: boolean = true;
  ultimoDigito: boolean = false;
  url: string = '';
  dinheiroFormatado: any = '';
  private base64textString: string = "";
  carregando: boolean = false;
  carregandoAutocomplete: boolean = false;

  modalEmpresaFiltroRef!: BsModalRef;
  tabela: string = 'produto'
  displayBasic: boolean;

  abrirFecharProdutoNcmCestCadastroModal?: boolean;

  constructor(private fb: FormBuilder,
    private produto$: ProdutoService,
    private modal$: BsModalService,
    private alert: AlertMessage,
    private produtoFabricante$: ProdutoFabricanteService,
    private produtoGrupo$: ProdutoGrupoService,
    private produtoSubGrupo$: ProdutoSubGrupoService,
    private produtoUn: ProdutoUnidadeService,
    private produtoEmpresa$: ProdutoEmpresaService,
    private produtoNcm$: ProdutoNcmService,
    private produtoCest$: ProdutoCestService,
    private produtoNcmCest$: ProdutoNcmCestService,
    private autenticacao$: AutenticacaoService,
    private spinner: NgxSpinnerService,
    private tributacao$: TributacaoService,
    private currencyPipe: CurrencyPipe,
    private produtoEan$: ProdutoEanService,
  ) {
    this.messagesValidations();
  }

  ngOnInit() {

    this.criarFormulario();

    this.pesquisarAutoComplete();

    this.pesquisarTributacao();

    this.pesquisarGrupo();

    this.spinner.show();

    if (this.alterar) {

      this.spinner.show();


      this.produto$.obterPorId(this.produto.produto_id || 0, 'P').pipe(
        concatMap(retorno => {

          if (retorno.total <= 0) return throwError({ error: { errorCode: 404, errorText: 'Produto não localizado' } });

          this.objProduto.produto = retorno.total > 0 ? retorno.result[0] : new ProdutoViewModel();

          this.pesquisaEmpresa = Object.assign({ produto_id: this.objProduto.produto?.id || 0 })

          return this.produtoEmpresa$.obterTodos(this.pesquisaEmpresa);


        }),
        concatMap(retorno => {

          if (retorno.total <= 0) return throwError({ error: { errorCode: 404, errorText: 'Produto não localizado' } });

          this.objProduto.produto_empresa = retorno.total > 0 ? retorno.result[0] : new ProdutoEmpresaViewModel();

          this.pesquisaProdutoNcm = Object.assign({ id: this.objProduto.produto?.produto_ncm_id || 0 })

          return this.produtoNcm$.obterTodos(this.pesquisaProdutoNcm);

        }),
        concatMap(retorno => {

          this.produto_ncm = retorno.total > 0 ? retorno.result[0] : new ProdutoNcmViewModel();

          return this.produtoNcmCest

        }),
        catchError(err => throwError(err)),

      ).subscribe(retorno => {

        this.listaProdutoCest = retorno;

        this.listaProdutoCest.map(x => {

          if (x.padrao == 1) this.produto_cest_id?.setValue(x.id);
        })

        this.criarFormulario();

        this.formacaoPreco();

        this.spinner.hide();

        this.obterImagem();

        this.obterListaCodigoBarra();
      },
        error => {

          this.spinner.hide();

          this.alert.Danger('Erro', Uteis.ObterErroApi(error));

        }
      )
    }

    this.pesquisaProdutoNcm = Object.assign({ codigo: this.produto.ncm })

    this.produtoNcm$.obterTodos(this.pesquisaProdutoNcm).pipe(
      concatMap(retorno => {

        this.cadastroForm.patchValue({

          produto: { produto_ncm_id: retorno.total > 0 ? retorno.result[0].id : 0 },

          produto_ncm: { codigo: retorno.total > 0 ? retorno.result[0].codigo : '' }
        });

        this.produto_ncm.id = retorno.total > 0 ? retorno.result[0].id : 0;

        this.produtoNcmCest.subscribe(retorno => {

          this.listaProdutoCest = retorno

          this.listaProdutoCest.map(x => {

            if (x.padrao == 1) this.produto_cest_id?.setValue(x.id);
          })

        });

        this.pesquisaProdutoUn = Object.assign({ un: this.produto.u_com });

        return this.produtoUn.obterTodos(this.pesquisaProdutoUn)

      })
    ).subscribe(retorno => {

      this.spinner.hide();

      this.cadastroForm.get('produto')?.patchValue({

        produto_un_comercial_id: retorno.total > 0 ? retorno.result[0].id : 0,

        produto_un_trib_id: retorno.total > 0 ? retorno.result[0].id : 0
      });

    })


    /*** Inicio Unidades do produto ***/

    this.pesquisaProdutoUn = Object.assign({ quantidadeRegistrosPagina: 0 })

    this.produtoUn.obterTodos(this.pesquisaProdutoUn).subscribe(

      retorno => this.listaProdutoUn = retorno.total > 0 ? retorno.result : [],

      err => this.alert.Danger('Erro', Uteis.ObterErroApi(err))

    );
    /*** Fim Unidades do produto ***/
  }

  ngAfterViewInit(): void {

    let controlBlurs: Observable<any>[] = this.formInputElements
      .map((formControl: ElementRef) => fromEvent(formControl.nativeElement, 'blur'));

    merge(...controlBlurs).subscribe(() => {
      if (typeof this.genericValidator !== 'undefined') {
        this.displayMessageProduto = this.genericValidator.processarMensagens(this.cadastroForm.get('produto'));
        this.displayMessageProdEmpresa = this.genericValidator.processarMensagens(this.cadastroForm.get('produto_empresa'));
      }
    });
  }

  messagesValidations() {
    this.validationMessages = {
      descricao: {
        required: MensagensValidacao.Obrigatorio('Descrição'),
        minlength: MensagensValidacao.MinimoCaracteres('Descricão', 2),
        maxlength: MensagensValidacao.MaximoCaracteres('Descrição', 50)
      },
      peso: {
        min: MensagensValidacao.ValorMinimo('Peso', 0),
      },
      tipo_venda: {
        min: MensagensValidacao.ValorMinimo('Tipo Venda', 0),
      },
      qtd_embalagem: {
        min: MensagensValidacao.ValorMinimo('Qtd Embalagem', 0),
      },
      tipo_sped: {
        required: MensagensValidacao.Obrigatorio('Tipo Sped'),
      },
      qtd_com_entrada: {
        min: MensagensValidacao.ValorMinimo('Qtd Comercial Entrada', 0),
      },
      cubagem: {
        min: MensagensValidacao.ValorMinimo('Cubagem', 0),
      },
      qtd_estoque_min: {
        min: MensagensValidacao.ValorMinimo('Qtd Estoque Minimo', 0),
      },
      vlr_custo: {
        min: MensagensValidacao.ValorMinimo('Vlr Custo', 0),
      },
      vlr_custo_dolar: {
        min: MensagensValidacao.ValorMinimo('Vlr Custo Dolar', 0),
      },
      vlr_venda: {
        min: MensagensValidacao.ValorMinimo('Valor de Venda', 0),
      },
      vlr_atacado: {
        min: MensagensValidacao.ValorMinimo('Vlr Atacado', 0),
      },
      cod_cst1: {
        required: MensagensValidacao.Obrigatorio('CST 1')
      },
      aliq_fp_cred_icms: {
        min: MensagensValidacao.ValorMinimo('Alíq. Cred. ICMS', 0),
      },
      aliq_fp_cred_pis: {
        min: MensagensValidacao.ValorMinimo('Alíq. Cred. PIS', 0),
      },
      aliq_fp_cred_cofins: {
        min: MensagensValidacao.ValorMinimo('Alíq. Cred. COFINS', 0),
      },
      aliq_fp_ipi: {
        min: MensagensValidacao.ValorMinimo('Alíq. IPI', 0),
      },
      aliq_fp_icms_sub: {
        min: MensagensValidacao.ValorMinimo('Alíq. ICMS Sub.', 0),
      },
      aliq_fp_frete: {
        min: MensagensValidacao.ValorMinimo('Alíq Frete', 0),
      },
      aliq_fp_outros_custo: {
        min: MensagensValidacao.ValorMinimo('Alíq. Outros Custo', 0),
      },
      aliq_fp_custo_op: {
        min: MensagensValidacao.ValorMinimo('Alíq. Custo Op.', 0),
      },
      aliq_fp_trib_fed: {
        min: MensagensValidacao.ValorMinimo('Alíq Trib. Fed.', 0),
      },
      aliq_fp_outros: {
        min: MensagensValidacao.ValorMinimo('Alíq Outros', 0),
      },
      aliq_fp_encarg_finan: {
        min: MensagensValidacao.ValorMinimo('Alíq. Encargo Financeiro', 0),
      },
      aliq_fp_icms: {
        min: MensagensValidacao.ValorMinimo('Alíq ICMS', 0),
      },
      aliq_fp_contrib_social: {
        min: MensagensValidacao.ValorMinimo('Alíq Contribuição Social', 0),
      },
      aliq_fp_comissao: {
        min: MensagensValidacao.ValorMinimo('Aléq Comissão', 0),
      },
      aliq_fp_venda_m_lucro: {
        min: MensagensValidacao.ValorMinimo('Alíq Venda Mag. lucro', 0),
      },
      aliq_fp_atacado_m_lucro: {
        min: MensagensValidacao.ValorMinimo('Alíq Atacado Mag. lucro', 0),
      },
      aliq_fp_cred_ipi: {
        min: MensagensValidacao.ValorMinimo('Alíq Cred. IPI', 0),
      },
      aliq_fp_icms_deson: {
        min: MensagensValidacao.ValorMinimo('Alíq ICMS Deson.', 0),
      },
      aliq_pis: {
        min: MensagensValidacao.ValorMinimo('Alíq. PIS', 0),
      },
      aliq_cofins: {
        min: MensagensValidacao.ValorMinimo('Alíq COFINS', 0),
      },
      aliq_imposto_renda: {
        min: MensagensValidacao.ValorMinimo('Alíq Imposto Renda', 0),
      },
      pontuacao: {
        min: MensagensValidacao.ValorMinimo('Pontução', 0),
      },
      produto_cest_id: {
        required: MensagensValidacao.Obrigatorio('CEST'),
        min: MensagensValidacao.Obrigatorio('CEST'),
      },
      produto_ncm_id: {
        required: MensagensValidacao.Obrigatorio('NCM'),
        min: MensagensValidacao.Obrigatorio('NCM'),
      },
      produto_un_comercial_id: {
        required: MensagensValidacao.Obrigatorio('UN Comercial'),
        min: MensagensValidacao.Obrigatorio('UN Comercial'),
      },
      produto_un_trib_id: {
        required: MensagensValidacao.Obrigatorio('UN Tributária'),
        min: MensagensValidacao.Obrigatorio('UN Tributária'),
      },
      tributacao_id: {
        required: MensagensValidacao.Obrigatorio('Tributação')
      }
    };
    this.genericValidator = new GenericValidator(this.validationMessages);
  }

  criarFormulario() {
    this.cadastroForm = this.fb.group({
      produto: this.fb.group({
        id: [{ value: !this.alterar ? null : this.objProduto.produto?.id, disabled: true }],
        descricao: [!this.alterar ? this.produto?.x_prod?.substring(0, 50) : this.objProduto.produto?.descricao, Validators.compose([Validators.required, Validators.minLength(2), Validators.maxLength(50)])],
        produto_grupo_id: [!this.alterar ? 0 : this.objProduto.produto?.produto_grupo_id,],
        produto_grupo_descricao: [!this.alterar ? '' : this.objProduto.produto?.produto_grupo_descricao,],
        produto_sub_grupo_id: [!this.alterar ? 0 : this.objProduto.produto?.produto_sub_grupo_id,],
        produto_sub_grupo_descricao: [!this.alterar ? '' : this.objProduto.produto?.produto_sub_grupo_descricao,],
        produto_fabricante_id: [!this.alterar ? 0 : this.objProduto.produto?.produto_fabricante_id,],
        produto_fabricante_descricao: [!this.alterar ? '' : this.objProduto.produto?.produto_fabricante_descricao],
        produto_cest_id: [!this.alterar ? null : this.objProduto.produto?.produto_cest_id, Validators.compose([Validators.required, Validators.min(1)])],
        produto_ncm_id: [!this.alterar ? null : this.objProduto.produto?.produto_ncm_id, Validators.compose([Validators.required, Validators.min(1)])],
        produto_un_comercial_id: [!this.alterar ? null : this.objProduto.produto?.produto_un_comercial_id, Validators.compose([Validators.required, Validators.min(1)])],
        produto_un_trib_id: [!this.alterar ? null : this.objProduto.produto?.produto_un_trib_id, Validators.compose([Validators.required, Validators.min(1)])],
        produto_un_multiplo_id: [!this.alterar ? 0 : this.objProduto.produto?.produto_un_multiplo_id,],
        peso: [!this.alterar ? 0 : this.objProduto.produto?.peso, Validators.compose([Validators.min(0)])],
        volume: [!this.alterar ? '' : this.objProduto.produto?.volume],
        usa_fracionado: [!this.alterar ? false : this.objProduto.produto?.usa_fracionado],
        aplicacao: [!this.alterar ? '' : this.objProduto.produto?.aplicacao],
        lista_inventario: [!this.alterar ? true : this.objProduto.produto?.lista_inventario],
        tipo: [!this.alterar ? 'P' : this.objProduto.produto?.tipo],
        garantia: [!this.alterar ? '' : this.objProduto.produto?.garantia],
        data: [!this.alterar ? '' : this.objProduto.produto?.data],
        usa_balanca: [!this.alterar ? false : this.objProduto.produto?.usa_balanca],
        msg: [!this.alterar ? '' : this.objProduto.produto?.msg],
        usa_m2: [!this.alterar ? false : this.objProduto.produto?.usa_m2],
        img_foto: [!this.alterar ? '' : this.objProduto.produto?.img_foto],
        tipo_controlado: [!this.alterar ? 0 : this.objProduto.produto?.tipo_controlado,],
        tipo_venda: [!this.alterar ? 0 : this.objProduto.produto?.tipo_venda, Validators.compose([Validators.min(0)])],
        usa_grade: [!this.alterar ? false : this.objProduto.produto?.usa_grade],
        usa_conferidor: [!this.alterar ? false : this.objProduto.produto?.usa_conferidor],
        qtd_embalagem: [!this.alterar ? 0 : this.objProduto.produto?.qtd_embalagem, Validators.compose([Validators.min(0)])],
        inf_ad_prod: [!this.alterar ? '' : this.objProduto.produto?.inf_ad_prod],
        tipo_sped: [!this.alterar ? '00' : this.objProduto.produto?.tipo_sped, Validators.compose([Validators.required])],
        qtd_com_entrada: [!this.alterar ? 0 : this.objProduto.produto?.qtd_com_entrada, Validators.compose([Validators.min(0)])],
        registro_ms: [!this.alterar ? '' : this.objProduto.produto?.registro_ms],
        apresentacao: [!this.alterar ? '' : this.objProduto.produto?.apresentacao],
        veiculo_novo: [!this.alterar ? 0 : this.objProduto.produto?.veiculo_novo,],
        cubagem: [!this.alterar ? 0 : this.objProduto.produto?.cubagem, Validators.compose([Validators.min(0)])],
        usa_m3: [!this.alterar ? false : this.objProduto.produto?.usa_m3],
      }),
      produto_empresa: this.fb.group({
        id: [!this.alterar ? 0 : this.objProduto.produto_empresa?.id],
        emp_id: [!this.alterar ? this.autenticacao$.acessoUsuarioValue?.idEmpresaMaxdata : this.objProduto.produto_empresa?.emp_id, Validators.compose([Validators.required, Validators.min(1)])],
        produto_id: [!this.alterar ? 0 : this.objProduto.produto_empresa?.produto_id,],
        ativo: [!this.alterar ? 1 : this.objProduto.produto_empresa?.ativo],
        qtd_estoque_atual: [!this.alterar ? 0 : this.objProduto.produto_empresa?.qtd_estoque_atual],
        qtd_estoque_min: [!this.alterar ? 0 : this.objProduto.produto_empresa?.qtd_estoque_min, Validators.compose([Validators.min(0)])],
        vlr_custo: [!this.alterar ? this.produto.v_un_com || 0 : this.objProduto.produto_empresa?.vlr_custo || 0, Validators.compose([Validators.min(0)])],
        vlr_custo_dolar: [!this.alterar ? 0 : this.objProduto.produto_empresa?.vlr_custo_dolar || 0, Validators.compose([Validators.min(0)])],
        vlr_venda: [!this.alterar ? 0 : this.objProduto.produto_empresa?.vlr_venda || 0, { validators: Validators.compose([Validators.min(0)]) }],
        vlr_atacado: [!this.alterar ? 0 : this.objProduto.produto_empresa?.vlr_atacado || 0, Validators.compose([Validators.min(0)])],
        codigo: [!this.alterar ? this.produto.c_prod : this.objProduto.produto_empresa?.codigo],
        localizador: [!this.alterar ? '' : this.objProduto.produto_empresa?.localizador],
        prateleira: [!this.alterar ? '' : this.objProduto.produto_empresa?.prateleira],
        tipo_base_calc: [!this.alterar ? 0 : this.objProduto.produto_empresa?.tipo_base_calc],
        cod_cst1: [!this.alterar ? 0 : this.objProduto.produto_empresa?.cod_cst1, Validators.compose([Validators.required])],
        aliq_fp_cred_icms: [!this.alterar ? 0 : this.objProduto.produto_empresa?.aliq_fp_cred_icms || 0, Validators.compose([Validators.min(0)])], // Formação de preço  
        aliq_fp_cred_pis: [!this.alterar ? 0 : this.objProduto.produto_empresa?.aliq_fp_cred_pis || 0, Validators.compose([Validators.min(0)])],  // Formação de preço
        aliq_fp_cred_cofins: [!this.alterar ? 0 : this.objProduto.produto_empresa?.aliq_fp_cred_cofins || 0, Validators.compose([Validators.min(0)])], // Formação de preço
        aliq_fp_ipi: [!this.alterar ? 0 : this.objProduto.produto_empresa?.aliq_fp_ipi || 0, Validators.compose([Validators.min(0)])], // Formação de preço
        aliq_fp_icms_sub: [!this.alterar ? 0 : this.objProduto.produto_empresa?.aliq_fp_icms_sub || 0, Validators.compose([Validators.min(0)])],// Formação de preço
        aliq_fp_frete: [!this.alterar ? 0 : this.objProduto.produto_empresa?.aliq_fp_frete || 0, Validators.compose([Validators.min(0)])],// Formação de preço
        aliq_fp_outros_custo: [!this.alterar ? 0 : this.objProduto.produto_empresa?.aliq_fp_outros_custo || 0, Validators.compose([Validators.min(0)])],// Formação de preço
        aliq_fp_custo_op: [!this.alterar ? 0 : this.objProduto.produto_empresa?.aliq_fp_custo_op || 0, Validators.compose([Validators.min(0)])], // Formação de preço
        aliq_fp_trib_fed: [!this.alterar ? 0 : this.objProduto.produto_empresa?.aliq_fp_trib_fed || 0, Validators.compose([Validators.min(0)])],// Formação de preço
        aliq_fp_outros: [!this.alterar ? 0 : this.objProduto.produto_empresa?.aliq_fp_outros || 0, Validators.compose([Validators.min(0)])], // Formação de preço
        aliq_fp_encarg_finan: [!this.alterar ? 0 : this.objProduto.produto_empresa?.aliq_fp_encarg_finan || 0, Validators.compose([Validators.min(0)])], // Formação de preço
        aliq_fp_icms: [!this.alterar ? 0 : this.objProduto.produto_empresa?.aliq_fp_icms || 0, Validators.compose([Validators.min(0)])], // Formação de preço  
        aliq_fp_contrib_social: [!this.alterar ? 0 : this.objProduto.produto_empresa?.aliq_fp_contrib_social || 0, Validators.compose([Validators.min(0)])], // Formação de preço 
        aliq_fp_comissao: [!this.alterar ? 0 : this.objProduto.produto_empresa?.aliq_fp_comissao || 0, Validators.compose([Validators.min(0)])], // Formação de preço 
        aliq_fp_venda_m_lucro: [!this.alterar ? 0 : this.objProduto.produto_empresa?.aliq_fp_venda_m_lucro || 0, Validators.compose([Validators.min(0)])], // Formação de preço
        aliq_fp_atacado_m_lucro: [!this.alterar ? 0 : this.objProduto.produto_empresa?.aliq_fp_atacado_m_lucro || 0, Validators.compose([Validators.min(0)])], // Formação de preço 
        aliq_fp_cred_ipi: [!this.alterar ? 0 : this.objProduto.produto_empresa?.aliq_fp_cred_ipi || 0, Validators.compose([Validators.min(0)])], // Formação de preço
        aliq_fp_icms_deson: [!this.alterar ? 0 : this.objProduto.produto_empresa?.aliq_fp_icms_deson || 0, Validators.compose([Validators.min(0)])], // Formação de preço
        aliq_pis: [!this.alterar ? 0 : this.objProduto.produto_empresa?.aliq_pis || 0, Validators.compose([Validators.min(0)])],
        aliq_cofins: [!this.alterar ? 0 : this.objProduto.produto_empresa?.aliq_cofins || 0, Validators.compose([Validators.min(0)])],
        aliq_imposto_renda: [!this.alterar ? 0 : this.objProduto.produto_empresa?.aliq_imposto_renda || 0, Validators.compose([Validators.min(0)])],
        aliq_ii: [!this.alterar ? 0 : this.objProduto.produto_empresa?.aliq_ii || 0],
        aliq_fcp: [!this.alterar ? 0 : this.objProduto.produto_empresa?.aliq_fcp || 0],
        imposto_lancar_red_icms_como_desconto: [!this.alterar ? 0 : this.objProduto.produto_empresa?.imposto_lancar_red_icms_como_desconto],
        oferta: [!this.alterar ? 0 : this.objProduto.produto_empresa?.oferta],
        vlr_promocao: [!this.alterar ? 0 : this.objProduto.produto_empresa?.vlr_promocao || 0, Validators.compose([Validators.min(0)])],
        promocao_tem_vencimento: [!this.alterar ? 0 : this.objProduto.produto_empresa?.promocao_tem_vencimento],
        promocao_data_vencimento: [!this.alterar ? '' : this.objProduto.produto_empresa?.promocao_data_vencimento],
        aliq_desconto_max: [!this.alterar ? 0 : this.objProduto.produto_empresa?.aliq_desconto_max || 0, Validators.compose([Validators.min(0)])],
        aliq_comissao: [!this.alterar ? 0 : this.objProduto.produto_empresa?.aliq_comissao || 0, Validators.compose([Validators.min(0)])],
        usa_desconto: [!this.alterar ? 0 : this.objProduto.produto_empresa?.usa_desconto],
        altera_valor: [!this.alterar ? 0 : this.objProduto.produto_empresa?.altera_valor],
        vlr_preco_pauta: [!this.alterar ? 0 : this.objProduto.produto_empresa?.vlr_preco_pauta || 0, Validators.compose([Validators.min(0)])],
        usa_preco_pauta: [!this.alterar ? 0 : this.objProduto.produto_empresa?.usa_preco_pauta],
        usa_comissao: [!this.alterar ? 0 : this.objProduto.produto_empresa?.usa_comissao],
        aliq_issqn: [!this.alterar ? 0 : this.objProduto.produto_empresa?.aliq_issqn || 0,],
        pontuacao: [!this.alterar ? 0 : this.objProduto.produto_empresa?.pontuacao, Validators.compose([Validators.min(0)])],
        nao_cont_estoque: [!this.alterar ? 0 : this.objProduto.produto_empresa?.nao_cont_estoque],
        qtd_multiplo: [!this.alterar ? 0 : this.objProduto.produto_empresa?.qtd_multiplo],
        vlr_preco_max_cons: [!this.alterar ? 0 : this.objProduto.produto_empresa?.vlr_preco_max_cons || 0,],
        aliq_desconto_max_atacado: [!this.alterar ? 0 : this.objProduto.produto_empresa?.aliq_desconto_max_atacado || 0,],
        usa_desconto_atacado: [!this.alterar ? 0 : this.objProduto.produto_empresa?.usa_desconto_atacado],
        usa_desconto_max_lanc_auto: [!this.alterar ? 0 : this.objProduto.produto_empresa?.usa_desconto_max_lanc_auto],
        qtd_atacarejo: [!this.alterar ? 0 : this.objProduto.produto_empresa?.qtd_atacarejo, Validators.compose([Validators.min(0)])],
        vlr_desconto_atacarejo: [!this.alterar ? 0 : this.objProduto.produto_empresa?.vlr_desconto_atacarejo || 0, Validators.compose([Validators.min(0)])],
        usa_comissao_atacado: [!this.alterar ? 0 : this.objProduto.produto_empresa?.usa_comissao_atacado, Validators.compose([Validators.min(0)])],
        aliq_comissao_atacado: [!this.alterar ? 0 : this.objProduto.produto_empresa?.aliq_comissao_atacado || 0, Validators.compose([Validators.min(0)])],
        vlr_comissao_entrega: [!this.alterar ? 0 : this.objProduto.produto_empresa?.vlr_comissao_entrega || 0, Validators.compose([Validators.min(0)])],
        vlr_preco_sugerido: [{ value: !this.alterar ? 0 : this.objProduto.produto_empresa?.vlr_preco_sugerido || 0, disabled: true }],
        tributacao_id: [!this.alterar ? null : this.objProduto.produto_empresa?.tributacao_id, Validators.compose([Validators.required])]
      }),
      produto_ncm: this.fb.group({
        codigo: [!this.alterar ? null : this.produto_ncm.codigo,],
      }),
      formacaoPreco: this.fb.group({
        aliq_fp_cred_icms: [{ value: 0, disabled: true }],
        aliq_fp_cred_pis: [{ value: 0, disabled: true }],
        aliq_fp_cred_cofins: [{ value: 0, disabled: true }],
        aliq_fp_cred_ipi: [{ value: 0, disabled: true }],
        aliq_fp_icms_deson: [{ value: 0, disabled: true }],

        aliq_fp_ipi: [{ value: 0, disabled: true }],
        aliq_fp_icms_sub: [{ value: 0, disabled: true }],
        aliq_fp_frete: [{ value: 0, disabled: true }],
        aliq_fp_outros_custo: [{ value: 0, disabled: true }],

        aliq_fp_custo_op: [{ value: 0, disabled: true }],
        aliq_fp_trib_fed: [{ value: 0, disabled: true }],
        aliq_pis: [{ value: 0, disabled: true }],
        aliq_cofins: [{ value: 0, disabled: true }],
        aliq_imposto_renda: [{ value: 0, disabled: true }],

        aliq_fp_outros: [{ value: 0, disabled: true }],
        aliq_fp_encarg_finan: [{ value: 0, disabled: true }],
        aliq_fp_icms: [{ value: 0, disabled: true }],
        aliq_fp_contrib_social: [{ value: 0, disabled: true }],
        aliq_fp_comissao: [{ value: 0, disabled: true }],

        aliq_fp_venda_m_lucro: [{ value: 0, disabled: true }],
        aliq_fp_atacado_m_lucro: [{ value: 0, disabled: true }],
        custoFinal: [{ value: 0, disabled: true }],
        fatorDivisorVenda: [{ value: 0, disabled: true }],
        fatorDivisorAtacado: [{ value: 0, disabled: true }],
      }),
      produto_ean: this.fb.group({
        id: [0],
        produto_id: [0],
        codigo_ean: [null],
        chk_cx_fechada: [false],
        qtd_cx_fechada: [0],
        vlr_un_cx_fechada: [0],
      }),
      produto_fabricante: this.fb.group({

        descricao: [0, [Validators.required, Validators.minLength(2), Validators.maxLength(50)]],
        ativo: [true]
      }),
      produto_grupo: this.fb.group({

        descricao: [0, [Validators.required, Validators.minLength(2), Validators.maxLength(50)]],
        aliq_comissao: [0, [Validators.min(0)]],
        dt_cadastro: [Uteis.DateCadastroToString(new Date())],
        ativo: [true]
      }),
      produto_sub_grupo: this.fb.group({

        descricao: [null],
        produto_grupo_id: [0],
        produto_grupo_descricao: [null],
        aliq_comissao: [0],
        dt_cadastro: [Uteis.DateCadastroToString(new Date())],
        ativo: [true]
      }),
    });
  }

  limparForm() {
    this.cadastroForm.reset();
  }
  limparFormFabricante() {
    this.cadastroForm.get('produto_fabricante').reset();
  }

  onSubmit() {

    this.cadastroForm.markAllAsTouched();

    if (!this.cadastroForm.get('produto').valid) {

      if (typeof this.genericValidator !== 'undefined') {

        this.displayMessageProduto = this.genericValidator.processarMensagens(this.cadastroForm.get('produto'));

        this.displayMessageProdEmpresa = this.genericValidator.processarMensagens(this.cadastroForm.get('produto_empresa'));

      }

      let mensagemProduto = Uteis.ConverterObjetoToArray(this.displayMessageProduto)

      let mensagemProdutoEmpresa = Uteis.ConverterObjetoToArray(this.displayMessageProdEmpresa)

      let mensagem = mensagemProduto.concat(mensagemProdutoEmpresa);

      return this.mensagemCamposInvalidos(mensagem);
    }

    if (!this.cadastroForm.dirty) {

      this.alert.Info(AlertMensagemPadrao.formularioNaoAlterado.titulo, AlertMensagemPadrao.formularioNaoAlterado.mensagem);

      return
    }

    this.carregando = true;

    if (this.alterar) this.atualizar();
    else this.adicionar();
  }

  atualizarDadosObjeto() {
    this.objProduto.produto = Object.assign({}, this.objProduto.produto, this.cadastroForm.value.produto);
    this.objProduto.produto_empresa = Object.assign({}, this.objProduto.produto_empresa, this.cadastroForm.value.produto_empresa);

    if (this.objProduto.produto_empresa) {
      this.objProduto.produto_empresa.cod_cst1 = parseInt(this.cadastroForm.get('produto_empresa')?.get('cod_cst1')?.value || '0');

      this.objProduto.produto_empresa.vlr_custo = this.cadastroForm.get('produto_empresa')?.get('vlr_custo')?.value || 0
      this.objProduto.produto_empresa.vlr_venda = this.cadastroForm.get('produto_empresa')?.get('vlr_venda')?.value || 0
      this.objProduto.produto_empresa.vlr_atacado = this.cadastroForm.get('produto_empresa')?.get('vlr_atacado')?.value || 0

      this.objProduto.produto_empresa.aliq_fp_cred_icms = this.cadastroForm.get('produto_empresa')?.get('aliq_fp_cred_icms')?.value || 0
      this.objProduto.produto_empresa.aliq_fp_cred_pis = this.cadastroForm.get('produto_empresa')?.get('aliq_fp_cred_pis')?.value || 0
      this.objProduto.produto_empresa.aliq_fp_cred_cofins = this.cadastroForm.get('produto_empresa')?.get('aliq_fp_cred_cofins')?.value || 0
      this.objProduto.produto_empresa.aliq_fp_cred_ipi = this.cadastroForm.get('produto_empresa')?.get('aliq_fp_cred_ipi')?.value || 0
      this.objProduto.produto_empresa.aliq_fp_icms_deson = this.cadastroForm.get('produto_empresa')?.get('aliq_fp_icms_deson')?.value || 0
      this.objProduto.produto_empresa.aliq_fp_ipi = this.cadastroForm.get('produto_empresa')?.get('aliq_fp_ipi')?.value || 0
      this.objProduto.produto_empresa.aliq_fp_icms_sub = this.cadastroForm.get('produto_empresa')?.get('aliq_fp_icms_sub')?.value || 0
      this.objProduto.produto_empresa.aliq_fp_frete = this.cadastroForm.get('produto_empresa')?.get('aliq_fp_frete')?.value || 0
      this.objProduto.produto_empresa.aliq_fp_outros_custo = this.cadastroForm.get('produto_empresa')?.get('aliq_fp_outros_custo')?.value || 0

      this.objProduto.produto_empresa.aliq_fp_custo_op = this.cadastroForm.get('produto_empresa')?.get('aliq_fp_custo_op')?.value || 0
      this.objProduto.produto_empresa.aliq_fp_trib_fed = this.cadastroForm.get('produto_empresa')?.get('aliq_fp_trib_fed')?.value || 0
      this.objProduto.produto_empresa.aliq_pis = this.cadastroForm.get('produto_empresa')?.get('aliq_pis')?.value || 0
      this.objProduto.produto_empresa.aliq_cofins = this.cadastroForm.get('produto_empresa')?.get('aliq_cofins')?.value || 0
      this.objProduto.produto_empresa.aliq_imposto_renda = this.cadastroForm.get('produto_empresa')?.get('aliq_imposto_renda')?.value || 0
      this.objProduto.produto_empresa.aliq_fp_outros = this.cadastroForm.get('produto_empresa')?.get('aliq_fp_outros')?.value || 0
      this.objProduto.produto_empresa.aliq_fp_encarg_finan = this.cadastroForm.get('produto_empresa')?.get('aliq_fp_encarg_finan')?.value || 0
      this.objProduto.produto_empresa.aliq_fp_icms = this.cadastroForm.get('produto_empresa')?.get('aliq_fp_icms')?.value || 0
      this.objProduto.produto_empresa.aliq_fp_contrib_social = this.cadastroForm.get('produto_empresa')?.get('aliq_fp_contrib_social')?.value || 0
      this.objProduto.produto_empresa.aliq_fp_comissao = this.cadastroForm.get('produto_empresa')?.get('aliq_fp_comissao')?.value || 0
      this.objProduto.produto_empresa.aliq_fp_venda_m_lucro = this.cadastroForm.get('produto_empresa')?.get('aliq_fp_venda_m_lucro')?.value || 0
      this.objProduto.produto_empresa.aliq_fp_atacado_m_lucro = this.cadastroForm.get('produto_empresa')?.get('aliq_fp_atacado_m_lucro')?.value || 0

      this.objProduto.produto_empresa.vlr_preco_sugerido = this.cadastroForm.get('produto_empresa')?.get('vlr_preco_sugerido')?.value || 0

      this.objProduto.produto_empresa.aliq_fcp = this.cadastroForm.get('produto_empresa')?.get('aliq_fcp')?.value || 0
      this.objProduto.produto_empresa.aliq_ii = this.cadastroForm.get('produto_empresa')?.get('aliq_ii')?.value || 0
      this.objProduto.produto_empresa.vlr_promocao = this.cadastroForm.get('produto_empresa')?.get('vlr_promocao')?.value || 0
      this.objProduto.produto_empresa.aliq_desconto_max = this.cadastroForm.get('produto_empresa')?.get('aliq_desconto_max')?.value || 0
      this.objProduto.produto_empresa.aliq_desconto_max_atacado = this.cadastroForm.get('produto_empresa')?.get('aliq_desconto_max_atacado')?.value || 0
      this.objProduto.produto_empresa.aliq_comissao = this.cadastroForm.get('produto_empresa')?.get('aliq_comissao')?.value || 0
      this.objProduto.produto_empresa.aliq_comissao_atacado = this.cadastroForm.get('produto_empresa')?.get('aliq_comissao_atacado')?.value || 0
      this.objProduto.produto_empresa.vlr_comissao_entrega = this.cadastroForm.get('produto_empresa')?.get('vlr_comissao_entrega')?.value || 0
      this.objProduto.produto_empresa.vlr_preco_pauta = this.cadastroForm.get('produto_empresa')?.get('vlr_preco_pauta')?.value || 0
      this.objProduto.produto_empresa.aliq_issqn = this.cadastroForm.get('produto_empresa')?.get('aliq_issqn')?.value || 0
      this.objProduto.produto_empresa.vlr_desconto_atacarejo = this.cadastroForm.get('produto_empresa')?.get('vlr_desconto_atacarejo')?.value || 0
      this.objProduto.produto_empresa.vlr_preco_max_cons = this.cadastroForm.get('produto_empresa')?.get('vlr_preco_max_cons')?.value || 0
      this.objProduto.produto_empresa.vlr_custo_dolar = this.cadastroForm.get('produto_empresa')?.get('vlr_custo_dolar')?.value || 0;
    }
    delete this.objProduto.produto?.produto_fabricante_descricao
    delete this.objProduto.produto?.produto_grupo_descricao
    delete this.objProduto.produto?.produto_sub_grupo_descricao
  }

  atualizar() {

    this.atualizarDadosObjeto();

    if (this.objProduto.produto?.id)
      this.produto$.atualizar(this.objProduto.produto?.id, this.objProduto).subscribe(
        () => {

          this.mensagensCadastro();

          this.closeModalCadastroProduto();

          this.carregando = false;

        },
        error => {

          this.carregando = false;

          this.alert.Danger('Erro', Uteis.ObterErroApi(error));

        }
      )
  }

  adicionar() {

    this.atualizarDadosObjeto();


    this.produto$.adicionar(this.objProduto).subscribe(
      retorno => {

        this.retornarProdutoId(retorno.result[0]?.id || 0);

        this.closeModalCadastroProduto();

        this.limparForm();

        this.carregando = false;
      },
      error => {

        this.carregando = false;

        this.alert.Danger('Erro', Uteis.ObterErroApi(error))

      }
    )
  }

  calculoValorFp(aliquota: number, valor: number) {
    let aliq = aliquota / 100;
    return aliq * valor;
  }

  formacaoPreco() {
    this.objFormacaoPreco.aliq_fp_cred_icms = this.calculoValorFp(this.produtoEmpAliqFpCredIcms || 0, this.produtoEmpVlrCusto || 0);
    this.objFormacaoPreco.aliq_fp_cred_pis = this.calculoValorFp(this.produtoEmpAliqFpCredPis || 0, this.produtoEmpVlrCusto || 0);
    this.objFormacaoPreco.aliq_fp_cred_cofins = this.calculoValorFp(this.produtoEmpAliqFpCredCofins || 0, this.produtoEmpVlrCusto || 0);
    this.objFormacaoPreco.aliq_fp_cred_ipi = this.calculoValorFp(this.produtoEmpAliqFpCredIpi || 0, this.produtoEmpVlrCusto || 0);
    this.objFormacaoPreco.aliq_fp_icms_deson = this.calculoValorFp(this.produtoEmpAliqFpIcmsDeson || 0, this.produtoEmpVlrCusto || 0);
    this.objFormacaoPreco.aliq_fp_ipi = this.calculoValorFp(this.produtoEmpAliqFpIpi || 0, this.produtoEmpVlrCusto || 0);
    this.objFormacaoPreco.aliq_fp_icms_sub = this.calculoValorFp(this.produtoEmpAliqFpIcmsSub || 0, this.produtoEmpVlrCusto || 0);
    this.objFormacaoPreco.aliq_fp_frete = this.calculoValorFp(this.produtoEmpAliqFpFrete || 0, this.produtoEmpVlrCusto || 0);
    this.objFormacaoPreco.aliq_fp_outros_custo = this.calculoValorFp(this.produtoEmpAliqFpOutrosCusto || 0, this.produtoEmpVlrCusto || 0);

    this.objFormacaoPreco.custoFinal = (
      this.produtoEmpVlrCusto +
      this.objFormacaoPreco.aliq_fp_ipi +
      this.objFormacaoPreco.aliq_fp_icms_sub +
      this.objFormacaoPreco.aliq_fp_frete +
      this.objFormacaoPreco.aliq_fp_outros_custo
    ) -
      (
        this.objFormacaoPreco.aliq_fp_cred_icms +
        this.objFormacaoPreco.aliq_fp_cred_pis +
        this.objFormacaoPreco.aliq_fp_cred_cofins +
        this.objFormacaoPreco.aliq_fp_cred_ipi +
        this.objFormacaoPreco.aliq_fp_icms_deson
      ) || 0;

    this.objFormacaoPreco.fatorDivisorVenda = (
      100 -
      (
        this.produtoEmpAliqFpCustoOp +
        this.produtoEmpAliqFpTribFed +
        this.produtoEmpAliqPis +
        this.produtoEmpAliqCofins +
        this.produtoEmpAliqImpostoRenda +
        this.produtoEmpAliqFpOutros +
        this.produtoEmpAliqFpEncargFinan +
        this.produtoEmpAliqFpIcms +
        this.produtoEmpAliqFpContribSocial +
        this.produtoEmpAliqFpComissao +
        this.produtoEmpAliqFpVendaMlucro
      ) / 100);
    this.objFormacaoPreco.vlr_preco_sugerido = this.objFormacaoPreco.custoFinal || 0 / this.objFormacaoPreco.fatorDivisorVenda || 0;

    this.objFormacaoPreco.aliq_fp_custo_op = this.calculoValorFp(this.produtoEmpAliqFpCustoOp || 0, this.objFormacaoPreco.vlr_preco_sugerido || 0);
    this.objFormacaoPreco.aliq_fp_trib_fed = this.calculoValorFp(this.produtoEmpAliqFpTribFed || 0, this.objFormacaoPreco.vlr_preco_sugerido || 0);
    this.objFormacaoPreco.aliq_pis = this.calculoValorFp(this.produtoEmpAliqPis || 0, this.objFormacaoPreco.vlr_preco_sugerido || 0);
    this.objFormacaoPreco.aliq_cofins = this.calculoValorFp(this.produtoEmpAliqCofins || 0, this.objFormacaoPreco.vlr_preco_sugerido || 0);
    this.objFormacaoPreco.aliq_imposto_renda = this.calculoValorFp(this.produtoEmpAliqImpostoRenda || 0, this.objFormacaoPreco.vlr_preco_sugerido || 0);
    this.objFormacaoPreco.aliq_fp_outros = this.calculoValorFp(this.produtoEmpAliqFpOutros || 0, this.objFormacaoPreco.vlr_preco_sugerido || 0);
    this.objFormacaoPreco.aliq_fp_encarg_finan = this.calculoValorFp(this.produtoEmpAliqFpEncargFinan || 0, this.objFormacaoPreco.vlr_preco_sugerido || 0);
    this.objFormacaoPreco.aliq_fp_icms = this.calculoValorFp(this.produtoEmpAliqFpIcms || 0, this.objFormacaoPreco.vlr_preco_sugerido || 0);
    this.objFormacaoPreco.aliq_fp_contrib_social = this.calculoValorFp(this.produtoEmpAliqFpContribSocial || 0, this.objFormacaoPreco.vlr_preco_sugerido || 0);
    this.objFormacaoPreco.aliq_fp_comissao = this.calculoValorFp(this.produtoEmpAliqFpComissao || 0, this.objFormacaoPreco.vlr_preco_sugerido || 0);
    this.objFormacaoPreco.aliq_fp_venda_m_lucro = this.calculoValorFp(this.produtoEmpAliqFpVendaMlucro || 0, this.objFormacaoPreco.vlr_preco_sugerido || 0);
    this.atualizarFormacaoPreco();
  }

  atualizarFormacaoPreco() {
    this.cadastroForm.patchValue({
      formacaoPreco: {
        aliq_fp_cred_icms: this.objFormacaoPreco.aliq_fp_cred_icms,
        aliq_fp_cred_pis: this.objFormacaoPreco.aliq_fp_cred_pis,
        aliq_fp_cred_cofins: this.objFormacaoPreco.aliq_fp_cred_cofins,
        aliq_fp_cred_ipi: this.objFormacaoPreco.aliq_fp_cred_ipi,
        aliq_fp_icms_deson: this.objFormacaoPreco.aliq_fp_icms_deson,
        aliq_fp_ipi: this.objFormacaoPreco.aliq_fp_ipi,
        aliq_fp_icms_sub: this.objFormacaoPreco.aliq_fp_icms_sub,
        aliq_fp_frete: this.objFormacaoPreco.aliq_fp_frete,
        aliq_fp_outros_custo: this.objFormacaoPreco.aliq_fp_outros_custo,

        custoFinal: this.objFormacaoPreco.custoFinal,
        fatorDivisorVenda: this.objFormacaoPreco.fatorDivisorVenda,

        aliq_fp_custo_op: this.objFormacaoPreco.aliq_fp_custo_op,
        aliq_fp_trib_fed: this.objFormacaoPreco.aliq_fp_trib_fed,
        aliq_pis: this.objFormacaoPreco.aliq_pis,
        aliq_cofins: this.objFormacaoPreco.aliq_cofins,
        aliq_imposto_renda: this.objFormacaoPreco.aliq_imposto_renda,
        aliq_fp_outros: this.objFormacaoPreco.aliq_fp_outros,
        aliq_fp_encarg_finan: this.objFormacaoPreco.aliq_fp_encarg_finan,
        aliq_fp_icms: this.objFormacaoPreco.aliq_fp_icms,
        aliq_fp_contrib_social: this.objFormacaoPreco.aliq_fp_contrib_social,
        aliq_fp_comissao: this.objFormacaoPreco.aliq_fp_comissao,
        aliq_fp_venda_m_lucro: this.objFormacaoPreco.aliq_fp_venda_m_lucro,
      }
    })
    // Formação de preço - VlrVendaSugerido Venda
    this.cadastroForm.patchValue({ produto_empresa: { vlr_preco_sugerido: this.objFormacaoPreco.vlr_preco_sugerido } })
  }

  openModalPesquisarFabricante() {

    this.listaFabricante.itens = [];

    this.listaFabricante.paginacao.totalRegistros = 0;

    this.pesquisa.id = 0;

    this.pesquisa.descricao = '';

    this.modalPesquisarFabricante = true;

  }

  openModalPesquisarGrupo() {

    this.listaGrupo.itens = [];

    this.listaGrupo.paginacao.totalRegistros = 0;

    this.pesquisa.id = 0;

    this.pesquisa.descricao = '';

    this.modalPesquisarGrupo = true;

  }

  openModalPesquisarSubGrupo() {

    this.listaSubGrupo.itens = [];

    this.listaSubGrupo.paginacao.totalRegistros = 0;

    this.pesquisa.id = 0;

    this.pesquisa.descricao = '';

    this.cadastroForm.controls.produto.get('produto_grupo_id')?.value !== 0 ? this.modalPesquisarSubGrupo = true :
      [
        this.alert.Warning('Atenção', 'Selecione um Grupo'),
        (document.querySelector('[name="produto_grupo_descricao"]') as HTMLElement).focus(),
        (document.querySelector('[name="produto_grupo_descricao"]') as HTMLInputElement).click()
      ];
  }

  pesquisarFabricante() {
    if (this.pesquisa === undefined) this.pesquisa = new ProdutoFabricantePesquisaViewModel();

    this.spinner.show();

    this.produtoFabricante$.obterTodos(this.pesquisa).subscribe(
      retorno => {

        this.listaFabricante.itens = retorno.total > 0 ? retorno.result : [];

        this.listaFabricante.paginacao.totalRegistros = retorno.total;

        this.listaFabricante.paginacao.quantidadeRegistrosPagina = this.pesquisa.quantidadeRegistrosPagina;

        this.spinner.hide();

        Uteis.atualizarFiltro('produto_fabricante', this.pesquisa)
      },
      error => { this.spinner.hide(); this.alert.Danger('Erro', Uteis.ObterErroApi(error)); });
  }

  pesquisarGrupo() {

    this.pesquisaProdutoGrupo = Object.assign(
      {
        id: this.pesquisa.id,
        descricao: this.pesquisa.descricao,
        quantidadeRegistrosPagina: this.pesquisa.quantidadeRegistrosPagina
      }
    )

    this.produtoGrupo$.obterTodos(this.pesquisaProdutoGrupo).subscribe(
      retorno => {

        this.listaGrupo.itens = retorno.total > 0 ? retorno.result : [];

        this.listaGrupo.paginacao.totalRegistros = retorno.total;

        this.listaGrupo.paginacao.quantidadeRegistrosPagina = this.pesquisa.quantidadeRegistrosPagina

      },
      error => this.alert.Danger('Erro', Uteis.ObterErroApi(error))
    )
  }

  pesquisarSubGrupo() {

    this.pesquisaProdutoSubGrupo = Object.assign(
      {
        id: this.pesquisa.id,
        descricao: this.pesquisa.descricao,
        produto_grupo_id: this.produto_grupo_id.value,
        quantidadeRegistrosPagina: this.pesquisa.quantidadeRegistrosPagina
      }
    )

    this.produtoSubGrupo$.obterTodos(this.pesquisaProdutoSubGrupo).subscribe(
      retorno => {

        this.listaSubGrupo.itens = retorno.total > 0 ? retorno.result : [];

        this.listaSubGrupo.paginacao.totalRegistros = retorno.total;

        this.listaSubGrupo.paginacao.quantidadeRegistrosPagina = this.pesquisa.quantidadeRegistrosPagina

      },
      error => this.alert.Danger('Erro', Uteis.ObterErroApi(error))
    )
  }

  mudarPaginaFabricante(event: any) {

    this.pesquisa.start = (event.page - 1) * this.pesquisa.quantidadeRegistrosPagina;
    this.pesquisa.pagina= event.page;
    this.pesquisarFabricante();
  }

  mudarPaginaGrupo(event: any) {

    this.pesquisaProdutoGrupo.start = (event.page - 1) * this.pesquisaProdutoGrupo.quantidadeRegistrosPagina;

    this.pesquisarGrupo();

  }

  mudarPaginaSubGrupo(event: any) {

    this.pesquisaProdutoSubGrupo.start = (event.page - 1) * this.pesquisaProdutoSubGrupo.quantidadeRegistrosPagina;

    this.pesquisarSubGrupo();
  }
  selecionar(obj: string, id: number, nome: string) {

    this.cadastroForm.markAsDirty();

    switch (obj) {

      case 'produto_fabricante':

        this.cadastroForm.controls.produto.patchValue({ produto_fabricante_id: id, produto_fabricante_descricao: nome })

        this.modalPesquisarFabricante = false;

        break;

      case 'produto_grupo':

        this.cadastroForm.controls.produto.patchValue({ produto_grupo_id: id, produto_grupo_descricao: nome, produto_sub_grupo: 0, produto_sub_grupo_descricao: '' })

        this.modalPesquisarGrupo = false;

        break;

      case 'produto_sub_grupo':

        this.cadastroForm.controls.produto.patchValue({ produto_sub_grupo_id: id, produto_sub_grupo_descricao: nome })

        this.modalPesquisarSubGrupo = false;

        break;
    }
  }

  pesquisarAutoComplete() {
    this.produtoNcmAutoComplete = Observable.create((observer: any) => {

      this.carregandoAutocomplete = true;

      this.pesquisaProdutoNcm = Object.assign({ codigo: this.prodNcmProdutoNcmCodigo });

      this.produtoNcm$.obterTodos(this.pesquisaProdutoNcm).subscribe(
        retorno => {

          retorno.result = retorno.total > 0 ? retorno.result : [];

          observer.next(retorno.result);

          this.carregandoAutocomplete = false;
        }
      );
    });

    this.produtoCestAutoComplete = Observable.create((observer: any) => {

      this.produtoCest$.obterTodos(this.pesquisaProdutoCest).subscribe(
        retorno => {

          retorno.result = retorno.total > 0 ? retorno.result : [];

          observer.next(retorno.result);
        }
      )
    });

    this.produtoNcmCest = Observable.create((observer: any) => {

      this.pesquisaProdutoNcmCest = Object.assign({ quantidadeRegistrosPagina: 0, produto_ncm_id: this.produto_ncm.id })

      this.produtoNcmCest$.obterTodos(this.pesquisaProdutoNcmCest).subscribe(
        retorno => {

          retorno.result = retorno.total > 0 ? retorno.result : [];

          observer.next(retorno.result);

        }
      )
    });
  }

  obterListaProdutoNcmCest() {

    this.produtoNcmCest.subscribe(retorno => {

      this.listaProdutoCest = retorno

      this.listaProdutoCest.map(x => {

        if (x.padrao == 1) this.produto_cest_id?.setValue(x.id);
      })
    })

  }

  onSelect(event: TypeaheadMatch, campo: string): void {

    switch (campo) {

      case 'produto_ncm':

        this.produto_ncm = event.item

        this.produto_ncm_id.setValue(event.item.id);

        this.obterListaProdutoNcmCest();

        break;
    }
  }

  typeaheadNoResults(event: boolean): void {
    if (this.produto_grupo.descricao) this.noResult = event;
  };
  onSelectSubgrupo(event: TypeaheadMatch): void {
    this.produto_grupo = event.item;
  };
  pesquisarTributacao() {

    this.pesquisaTributacao = Object.assign({ quantidadeRegistrosPagina: 0 })

    return this.tributacao$.obterTodos(this.pesquisaTributacao).subscribe(
      retorno => {

        this.listaTributacao.itens = retorno.total > 0 ? retorno.result : [];

        this.listaTributacao.paginacao.totalRegistros = retorno.total;

        this.listaTributacao.paginacao.quantidadeRegistrosPagina = 999;

      },
      err => this.alert.Danger('Tributação', Uteis.ObterErroApi(err)));
  }

  handleFileSelect(evt) {

    var files = evt.target.files;

    var file = files[0];

    if (files && file) {

      var reader = new FileReader();

      reader.onload = this._handleReaderLoaded.bind(this);

      reader.onload = (event: any) => {

        var foto: String = event.target.result as string;

        this.url = String(foto);

        this.alert.Info('Info', 'Para manter essa imagem, clique em Salvar.');

      };

      reader.readAsDataURL(file);
      // reader.readAsBinaryString(file);
    }
  }

  obterImagem() {

    var data = {

      id: this.objProduto?.produto?.id,

      field_name: "img_foto"

    }

    this.produto$.getImage(this.objProduto?.produto?.id, data).subscribe(
      retorno => {

        var binaryString = retorno.result[0].img_foto;

        if (retorno.result[0].img_foto == '') {
          this.url = 'https://via.placeholder.com/300x150?text=Imagem'

        } else {

          this.base64textString = 'data:image/png;base64,' + (binaryString);

          this.url = this.base64textString;

        }
      },
      error => this.url = 'https://via.placeholder.com/300x150?text=Imagem'
    )
  }

  _handleReaderLoaded(readerEvt) {

    var binaryString = readerEvt.target.result;

    this.base64textString = 'data:image/png;base64,' + btoa(binaryString);

    this.url = this.base64textString;

  }

  salvarImg() {

    var data = {

      id: this.objProduto?.produto?.id,

      field_name: "img_foto",

      value_base64: this.url.split(',')[1]
    }

    this.produto$.atualizarImagem(this.objProduto.produto?.id, data).subscribe(

      retorno => this.alert.Success('Success', 'Imagem salva com sucesso!'),

      error => this.alert.Danger('Error', Uteis.ObterErroApi(error)),
    )
  }

  limparImg() {

    this.base64textString = '';

    this.cadastroForm.get('produto_id')?.get('img_foto')?.setValue('');

    this.url = '';

    this.alert.Info('Info', 'Caso queira exluir permanentemente a imagem, clique em salvar.');

  }

  adicionarCodigoBarra() {

    this.cadastroForm.get("produto_ean")?.patchValue({ produto_id: this.objProduto?.produto?.id || 0 });

    this.objProdutoEan.obj = Object.assign({}, this.cadastroForm.get("produto_ean")?.value);

    if (!this.objProdutoEan.obj.codigo_ean || this.objProdutoEan.obj.codigo_ean == null) {
      this.alert.Warning("Atenção", "Preencha o código de barras!");

      return;
    }

    if (
      this.objProdutoEan.obj?.produto_id &&
      this.objProdutoEan.obj?.produto_id > 0 &&
      this.objProdutoEan.obj?.id &&
      this.objProdutoEan.obj?.id > 0
    ) {
      this.produtoEan$
        .atualizar(this.objProdutoEan.obj.id, this.objProdutoEan)
        .subscribe(
          (retorno) => {
            this.obterListaCodigoBarra();

            //this.limparFormProdutoEan();

            this.alert.Success("Successo", "Registro atualizado com sucesso.");
          },
          (err) => this.alert.Danger("Código Barras", Uteis.ObterErroApi(err))
        );
    } else if (
      this.objProdutoEan?.obj?.produto_id &&
      this.objProdutoEan?.obj?.produto_id > 0
    ) {
      this.produtoEan$.adicionar(this.objProdutoEan).subscribe(
        (retorno) => {
          this.obterListaCodigoBarra();

          //this.limparFormProdutoEan();

          this.alert.Success("Successo", "Registro adicionado com sucesso.");
        },
        (err) => this.alert.Danger("Código Barras", Uteis.ObterErroApi(err))
      );
    }
  }

  atualizarCodigoBarra(item: ProdutoEanViewModel) {
    this.cadastroForm.get("produto_ean")?.patchValue({
      id: item.id,
      produto_id: item.produto_id,
      codigo_ean: item.codigo_ean,
      chk_cx_fechada: item.chk_cx_fechada,
      qtd_cx_fechada: item.qtd_cx_fechada,
      vlr_un_cx_fechada: item.vlr_un_cx_fechada,
    });
  }

  removerCodigoBarra(item: ProdutoEanViewModel) {
    this.objProdutoEan.obj = Object.assign(item);

    this.produtoEan$
      .remover(this.objProdutoEan?.obj?.id || 0, this.objProdutoEan)
      .subscribe(
        (retorno) => {
          this.obterListaCodigoBarra();

          //this.limparFormProdutoEan();

          this.alert.Success("Successo", "Registro removido com sucesso.");
        },
        (err) => this.alert.Danger("Código Barras", Uteis.ObterErroApi(err))
      );
  }

  obterListaCodigoBarra() {
    this.pesquisaProdutoEan = Object.assign({
      produto_id: this.objProduto.produto?.id || 0,
      quantidadeRegistrosPagina: 0,
    });

    return this.produtoEan$.obterTodos(this.pesquisaProdutoEan).subscribe(
      (retorno) => {
        this.listaCodigoBarra.itens = retorno.total > 0 ? retorno.result : [];

        this.listaCodigoBarra.paginacao.totalRegistros = retorno.total;

        this.listaCodigoBarra.paginacao.quantidadeRegistrosPagina = 999;
      },
      (err) => this.alert.Danger("Código Barras", Uteis.ObterErroApi(err))
    );
  }

  removerItemSelecioando(formName?: string) {

    this.cadastroForm.markAsDirty();

    switch (formName) {

      case 'produto_fabricante_id':

        this.cadastroForm.controls.produto.patchValue({ produto_fabricante_id: 0, produto_fabricante_descricao: '' });

        break;

      case 'produto_grupo_id':

        this.cadastroForm.controls.produto.patchValue({ produto_grupo_id: 0, produto_grupo_descricao: '', produto_sub_grupo_id: 0, produto_sub_grupo_descricao: '' });

        break;

      case 'produto_sub_grupo_id':

        this.cadastroForm.controls.produto.patchValue({ produto_sub_grupo_id: 0, produto_sub_grupo_descricao: '' });

        break;

      case 'produto_ncm_id':

        this.cadastroForm.controls.produto.patchValue({ produto_ncm_id: null, produto_cest_id: null });

        this.cadastroForm.controls.produto_ncm.patchValue({ codigo: '' });

        this.listaProdutoCest = [];

        break;
    }
  }

  /* Produto Empresa */
  get produtoEmpVlrCusto() { return this.cadastroForm.get('produto_empresa')?.get('vlr_custo')?.value }
  get produtoEmpAliqFpCredIcms() { return this.cadastroForm.get('produto_empresa.aliq_fp_cred_icms')?.value }
  get produtoEmpAliqFpCredPis() { return this.cadastroForm.get('produto_empresa.aliq_fp_cred_pis')?.value }
  get produtoEmpAliqFpCredCofins() { return this.cadastroForm.get('produto_empresa.aliq_fp_cred_cofins')?.value }
  get produtoEmpAliqFpCredIpi() { return this.cadastroForm.get('produto_empresa.aliq_fp_cred_ipi')?.value }
  get produtoEmpAliqFpIcmsDeson() { return this.cadastroForm.get('produto_empresa.aliq_fp_icms_deson')?.value }
  get produtoEmpAliqFpIpi() { return this.cadastroForm.get('produto_empresa.aliq_fp_ipi')?.value }
  get produtoEmpAliqFpIcmsSub() { return this.cadastroForm.get('produto_empresa.aliq_fp_icms_sub')?.value }
  get produtoEmpAliqFpFrete() { return this.cadastroForm.get('produto_empresa.aliq_fp_frete')?.value }
  get produtoEmpAliqFpOutrosCusto() { return this.cadastroForm.get('produto_empresa.aliq_fp_outros_custo')?.value }

  get produtoEmpAliqFpCustoOp() { return this.cadastroForm.get('produto_empresa.aliq_fp_custo_op')?.value }
  get produtoEmpAliqFpTribFed() { return this.cadastroForm.get('produto_empresa.aliq_fp_trib_fed')?.value }
  get produtoEmpAliqPis() { return this.cadastroForm.get('produto_empresa.aliq_pis')?.value }
  get produtoEmpAliqCofins() { return this.cadastroForm.get('produto_empresa.aliq_cofins')?.value }
  get produtoEmpAliqImpostoRenda() { return this.cadastroForm.get('produto_empresa.aliq_imposto_renda')?.value }
  get produtoEmpAliqFpOutros() { return this.cadastroForm.get('produto_empresa.aliq_fp_outros')?.value }
  get produtoEmpAliqFpEncargFinan() { return this.cadastroForm.get('produto_empresa.aliq_fp_encarg_finan')?.value }
  get produtoEmpAliqFpIcms() { return this.cadastroForm.get('produto_empresa.aliq_fp_icms')?.value }
  get produtoEmpAliqFpContribSocial() { return this.cadastroForm.get('produto_empresa.aliq_fp_contrib_social')?.value }
  get produtoEmpAliqFpComissao() { return this.cadastroForm.get('produto_empresa.aliq_fp_comissao')?.value }
  get produtoEmpAliqFpVendaMlucro() { return this.cadastroForm.get('produto_empresa.aliq_fp_venda_m_lucro')?.value }
  get produtoEmpVlrPrecoSugerido() { return this.cadastroForm.get('produto_empresa.vlr_preco_sugerido')?.value }

  /** Formação de Preço  */
  get fpAliqFpIPI() { return this.cadastroForm.get('formacaoPreco.aliq_fp_ipi')?.value }
  get fpAliqFpIcmsSub() { return this.cadastroForm.get('formacaoPreco.aliq_fp_icms_sub')?.value }
  get fpAliqFpFrete() { return this.cadastroForm.get('formacaoPreco.aliq_fp_frete')?.value }
  get fpAliqFpOutrosCusto() { return this.cadastroForm.get('formacaoPreco.aliq_fp_outros_custo')?.value }
  get fpAliqFpCredIcms() { return this.cadastroForm.get('formacaoPreco.aliq_fp_cred_icms')?.value }
  get fpAliqFpCredPis() { return this.cadastroForm.get('formacaoPreco.aliq_fp_cred_pis')?.value }
  get fpAliqFpCredCofins() { return this.cadastroForm.get('formacaoPreco.aliq_fp_cred_cofins')?.value }
  get fpAliqFpCredIpi() { return this.cadastroForm.get('formacaoPreco.aliq_fp_cred_ipi')?.value }
  get fpAliqFpIcmsDeson() { return this.cadastroForm.get('formacaoPreco.aliq_fp_icms_deson')?.value }
  get fpCustoFinal() { return this.cadastroForm.get('formacaoPreco.custoFinal')?.value }
  get fpFatorDivisorVenda() { return this.cadastroForm.get('formacaoPreco.fatorDivisorVenda')?.value }

  /** Produto */
  get produtoTipoSped() { return this.cadastroForm.controls.produto.get('tipo_sped')?.value }

  /** Produto NCM  */
  get prodNcmProdutoNcmCodigo() { return this.cadastroForm.get('produto_ncm.codigo')?.value }

  mensagemCamposInvalidos(mensagens: any) {
    this.alert.Warning(AlertMensagemPadrao.formularioInvalido.titulo, AlertMensagemPadrao.formularioInvalido.mensagem + '<br><br>' + mensagens.toString().replace(/,/g, ''));
  }
  private mensagensCadastro(): void {
    this.alert.Success('Sucesso', `Produto ${this.alterar ? 'atualizado' : 'cadastrado'} com Sucesso.`);
    if (this.alterar) return;
    this.alert.Info('Informativo', 'Formulário de cadastro foi limpo para realizar um novo cadastro.', { timeOut: 10000 });
  }

  openModalEmpresaFiltroRef(template: TemplateRef<any>) {
    this.modalEmpresaFiltroRef = this.modal$.show(template, ConfigModal.modalPadrao);
    this.modalEmpresaFiltroRef.setClass('modal-large');

  }
  closeModalEmpresaFiltroRef() {
    this.modalEmpresaFiltroRef.hide();
  }

  retornarProdutoId(produto_id) {
    this.produtoId.emit(produto_id);
  }

  closeModalCadastroProduto() {
    this.closeModal.emit(false);
  }

  abrirModalCadastroProdutoNcmCest() {
    this.abrirFecharProdutoNcmCestCadastroModal = true;
  }


  fecharModalCadastroProdutoNcmCest() {

    this.abrirFecharProdutoNcmCestCadastroModal = false;

    this.obterListaProdutoNcmCest();
  }

  abrirModalCadastroFabricante() {
    this.modalCadastrarFabricante = true;
  }
  fecharModalCadastroFabricante() {

    this.modalCadastrarFabricante = false;


  }
  abrirModalCadastroGrupo() {
    this.modalCadastrarGrupo = true;
  }
  abrirModalCadastroSubGrupo() {
    this.modalCadastrarSubGrupo = true;
  }
  fecharModalCadastroGrupo() {

    this.modalCadastrarGrupo = false;


  }
  fecharModalCadastroSubGrupo() {

    this.modalCadastrarSubGrupo = false;


  }

  atualizarDadosFabricante() {
    this.objProdutoFabricante.obj = Object.assign({}, this.objProdutoFabricante.obj, this.cadastroForm.get('produto_fabricante').value);
  }
  atualizarDadosGrupo() {
    this.objProdutoGrupo.obj = Object.assign({}, this.objProdutoGrupo.obj, this.cadastroForm.get('produto_grupo').value);
  }

  atualizarDadosSubGrupo() {

    this.objProdutoSubGrupo.obj = Object.assign({}, this.objProdutoSubGrupo.obj, this.cadastroForm.get('produto_sub_grupo').value);

    if (this.produto_grupo.id && this.objProdutoSubGrupo.obj) {

      this.objProdutoSubGrupo.obj = Object.assign({

        produto_grupo_id: this.produto_grupo.id,

        produto_grupo_descricao: this.produto_grupo.descricao

      }, this.objProdutoSubGrupo.obj)

    }

    this.produto_grupo = new ProdutoGrupoViewModel;
  }
  adicionarFabricante() {



    this.carregando = true;

    this.atualizarDadosFabricante();

    this.produtoFabricante$.adicionar(this.objProdutoFabricante).subscribe(
      retorno => {
        this.alert.Success('Sucesso', `Fabricante cadastrado com Sucesso.`);

        this.limparFormFabricante();

        this.fecharModalCadastroFabricante();
        this.pesquisarFabricante();
        this.carregando = false;
      },
      error => {
        this.carregando = false;

        this.alert.Danger('Erro', Uteis.ObterErroApi(error))
      });
  }
  AdicionarGrupo() {



    this.carregando = true;

    this.atualizarDadosGrupo();

    this.produtoGrupo$.adicionar(this.objProdutoGrupo).subscribe(
      retorno => {

        this.alert.Success('Sucesso', `Grupo cadastrado com Sucesso.`);
        this.cadastroForm.get('produto_grupo').reset();
        this.fecharModalCadastroGrupo();
        this.pesquisarGrupo();
        this.carregando = false;
      },
      error => {
        this.carregando = false;

        this.alert.Danger('Erro', Uteis.ObterErroApi(error))
      });
  }
  AdicionarSubGrupo() {

    this.atualizarDadosSubGrupo();

    delete this.objProdutoSubGrupo.obj?.produto_grupo_descricao;


    this.produtoSubGrupo$.adicionar(this.objProdutoSubGrupo).subscribe(
      retorno => {

        this.alert.Success('Sucesso', `Sub-Grupo cadastrado com Sucesso.`);
        this.pesquisarSubGrupo()
        this.fecharModalCadastroSubGrupo();
        this.cadastroForm.get('produto_sub_grupo').reset();
        this.carregando = false;
      },
      error => {
        this.carregando = false;

        this.alert.Danger('Erro', Uteis.ObterErroApi(error));
      });
  }


  /** 
* @Author: werleyss 
* @Date: 2021-08-25 09:29:36 
* @Desc: Inicio Produto  
*/

  get produto_id() { return this.cadastroForm.controls.produto.get('id') }
  get descricao() { return this.cadastroForm.controls.produto.get('descricao') }
  get produto_grupo_id() { return this.cadastroForm.controls.produto.get('produto_grupo_id') }
  get produto_grupo_descricao() { return this.cadastroForm.controls.produto.get('produto_grupo_descricao') }
  get produto_sub_grupo_id() { return this.cadastroForm.controls.produto.get('produto_sub_grupo_id') }
  get produto_sub_grupo_descricao() { return this.cadastroForm.controls.produto.get('produto_sub_grupo_descricao') }
  get produto_fabricante_id() { return this.cadastroForm.controls.produto.get('produto_fabricante_id') }
  get produto_fabricante_descricao() { return this.cadastroForm.controls.produto.get('produto_fabricante_descricao') }
  get produto_cest_id() { return this.cadastroForm.controls.produto.get('produto_cest_id') }
  get produto_ncm_id() { return this.cadastroForm.controls.produto.get('produto_ncm_id') }
  get produto_un_comercial_id() { return this.cadastroForm.controls.produto.get('produto_un_comercial_id') }
  get produto_un_trib_id() { return this.cadastroForm.controls.produto.get('produto_un_trib_id') }
  get produto_un_multiplo_id() { return this.cadastroForm.controls.produto.get('produto_un_multiplo_id') }
  get produto_classe_id() { return this.cadastroForm.controls.produto.get('produto_classe_id') }
  get peso() { return this.cadastroForm.controls.produto.get('peso') }
  get volume() { return this.cadastroForm.controls.produto.get('volume') }
  get usa_fracionado() { return this.cadastroForm.controls.produto.get('usa_fracionado') }
  get aplicacao() { return this.cadastroForm.controls.produto.get('aplicacao') }
  get lista_inventario() { return this.cadastroForm.controls.produto.get('lista_inventario') }
  get tipo() { return this.cadastroForm.controls.produto.get('tipo') }
  get garantia() { return this.cadastroForm.controls.produto.get('garantia') }
  get data() { return this.cadastroForm.controls.produto.get('data') }
  get usa_balanca() { return this.cadastroForm.controls.produto.get('usa_balanca') }
  get msg() { return this.cadastroForm.controls.produto.get('msg') }
  get usa_m2() { return this.cadastroForm.controls.produto.get('usa_m2') }
  get img_foto() { return this.cadastroForm.controls.produto.get('img_foto') }
  get tipo_controlado() { return this.cadastroForm.controls.produto.get('tipo_controlado') }
  get tipo_venda() { return this.cadastroForm.controls.produto.get('tipo_venda') }
  get usa_grade() { return this.cadastroForm.controls.produto.get('usa_grade') }
  get usa_conferidor() { return this.cadastroForm.controls.produto.get('usa_conferidor') }
  get qtd_embalagem() { return this.cadastroForm.controls.produto.get('qtd_embalagem') }
  get inf_ad_prod() { return this.cadastroForm.controls.produto.get('inf_ad_prod') }
  get tipo_sped() { return this.cadastroForm.controls.produto.get('tipo_sped') }
  get qtd_com_entrada() { return this.cadastroForm.controls.produto.get('qtd_com_entrada') }
  get registro_ms() { return this.cadastroForm.controls.produto.get('registro_ms') }
  get apresentacao() { return this.cadastroForm.controls.produto.get('apresentacao') }
  get veiculo_novo() { return this.cadastroForm.controls.produto.get('veiculo_novo') }
  get cubagem() { return this.cadastroForm.controls.produto.get('cubagem') }
  get usa_m3() { return this.cadastroForm.controls.produto.get('usa_m3') }
  get largura() { return this.cadastroForm.controls.produto.get('largura') }
  get altura() { return this.cadastroForm.controls.produto.get('altura') }
  get profundidade() { return this.cadastroForm.controls.produto.get('profundidade') }
  get ecommerce_produto_nome() { return this.cadastroForm.controls.produto.get('ecommerce_produto_nome') }
  get ecommerce_produto_resumo() { return this.cadastroForm.controls.produto.get('ecommerce_produto_resumo') }
  get ecommerce_produto_descricao() { return this.cadastroForm.controls.produto.get('ecommerce_produto_descricao') }

  /* Produto Fim */

  /** 
   * @Author: werleyss 
   * @Date: 2021-08-25 09:29:36 
   * @Desc: Inicio produto_empresa  
   */
  get produto_empresa_id() { return this.cadastroForm.controls.produto_empresa.get('produto_empresa_id') }
  get emp_id() { return this.cadastroForm.controls.produto_empresa.get('emp_id') }
  get produto_empresa_produto_id() { return this.cadastroForm.controls.produto_empresa.get('produto_empresa_produto_id') }
  get ativo() { return this.cadastroForm.controls.produto_empresa.get('ativo') }
  get qtd_estoque_atual() { return this.cadastroForm.controls.produto_empresa.get('qtd_estoque_atual') }
  get qtd_estoque_min() { return this.cadastroForm.controls.produto_empresa.get('qtd_estoque_min') }
  get vlr_custo() { return this.cadastroForm.controls.produto_empresa.get('vlr_custo') }
  get vlr_custo_dolar() { return this.cadastroForm.controls.produto_empresa.get('vlr_custo_dolar') }
  get vlr_venda() { return this.cadastroForm.controls.produto_empresa.get('vlr_venda') }
  get vlr_atacado() { return this.cadastroForm.controls.produto_empresa.get('vlr_atacado') }
  get codigo() { return this.cadastroForm.controls.produto_empresa.get('codigo') }
  get localizador() { return this.cadastroForm.controls.produto_empresa.get('localizador') }
  get prateleira() { return this.cadastroForm.controls.produto_empresa.get('prateleira') }
  get tipo_base_calc() { return this.cadastroForm.controls.produto_empresa.get('tipo_base_calc') }
  get cod_cst1() { return this.cadastroForm.controls.produto_empresa.get('cod_cst1') }
  get aliq_fp_cred_icms() { return this.cadastroForm.controls.produto_empresa.get('aliq_fp_cred_icms') }
  get aliq_fp_cred_pis() { return this.cadastroForm.controls.produto_empresa.get('aliq_fp_cred_pis') }
  get aliq_fp_cred_cofins() { return this.cadastroForm.controls.produto_empresa.get('aliq_fp_cred_cofins') }
  get aliq_fp_ipi() { return this.cadastroForm.controls.produto_empresa.get('aliq_fp_ipi') }
  get aliq_fp_icms_sub() { return this.cadastroForm.controls.produto_empresa.get('aliq_fp_icms_sub') }
  get aliq_fp_frete() { return this.cadastroForm.controls.produto_empresa.get('aliq_fp_frete') }
  get aliq_fp_outros_custo() { return this.cadastroForm.controls.produto_empresa.get('aliq_fp_outros_custo') }
  get aliq_fp_custo_op() { return this.cadastroForm.controls.produto_empresa.get('aliq_fp_custo_op') }
  get aliq_fp_trib_fed() { return this.cadastroForm.controls.produto_empresa.get('aliq_fp_trib_fed') }
  get aliq_fp_outros() { return this.cadastroForm.controls.produto_empresa.get('aliq_fp_outros') }
  get aliq_fp_encarg_finan() { return this.cadastroForm.controls.produto_empresa.get('aliq_fp_encarg_finan') }
  get aliq_fp_icms() { return this.cadastroForm.controls.produto_empresa.get('aliq_fp_icms') }
  get aliq_fp_contrib_social() { return this.cadastroForm.controls.produto_empresa.get('aliq_fp_contrib_social') }
  get aliq_fp_comissao() { return this.cadastroForm.controls.produto_empresa.get('aliq_fp_comissao') }
  get aliq_fp_venda_m_lucro() { return this.cadastroForm.controls.produto_empresa.get('aliq_fp_venda_m_lucro') }
  get aliq_fp_atacado_m_lucro() { return this.cadastroForm.controls.produto_empresa.get('aliq_fp_atacado_m_lucro') }
  get aliq_fp_cred_ipi() { return this.cadastroForm.controls.produto_empresa.get('aliq_fp_cred_ipi') }
  get aliq_fp_icms_deson() { return this.cadastroForm.controls.produto_empresa.get('aliq_fp_icms_deson') }
  get aliq_pis() { return this.cadastroForm.controls.produto_empresa.get('aliq_pis') }
  get aliq_cofins() { return this.cadastroForm.controls.produto_empresa.get('aliq_cofins') }
  get aliq_imposto_renda() { return this.cadastroForm.controls.produto_empresa.get('aliq_imposto_renda') }
  get aliq_ii() { return this.cadastroForm.controls.produto_empresa.get('aliq_ii') }
  get aliq_fcp() { return this.cadastroForm.controls.produto_empresa.get('aliq_fcp') }
  get imposto_lancar_red_icms_como_desconto() { return this.cadastroForm.controls.produto_empresa.get('imposto_lancar_red_icms_como_desconto') }
  get oferta() { return this.cadastroForm.controls.produto_empresa.get('oferta') }
  get vlr_promocao() { return this.cadastroForm.controls.produto_empresa.get('vlr_promocao') }
  get promocao_tem_vencimento() { return this.cadastroForm.controls.produto_empresa.get('promocao_tem_vencimento') }
  get promocao_data_vencimento() { return this.cadastroForm.controls.produto_empresa.get('promocao_data_vencimento') }
  get aliq_desconto_max() { return this.cadastroForm.controls.produto_empresa.get('aliq_desconto_max') }
  get aliq_comissao() { return this.cadastroForm.controls.produto_empresa.get('aliq_comissao') }
  get usa_desconto() { return this.cadastroForm.controls.produto_empresa.get('usa_desconto') }
  get altera_valor() { return this.cadastroForm.controls.produto_empresa.get('altera_valor') }
  get vlr_preco_pauta() { return this.cadastroForm.controls.produto_empresa.get('vlr_preco_pauta') }
  get usa_preco_pauta() { return this.cadastroForm.controls.produto_empresa.get('usa_preco_pauta') }
  get usa_comissao() { return this.cadastroForm.controls.produto_empresa.get('usa_comissao') }
  get aliq_issqn() { return this.cadastroForm.controls.produto_empresa.get('aliq_issqn') }
  get pontuacao() { return this.cadastroForm.controls.produto_empresa.get('pontuacao') }
  get nao_cont_estoque() { return this.cadastroForm.controls.produto_empresa.get('nao_cont_estoque') }
  get qtd_multiplo() { return this.cadastroForm.controls.produto_empresa.get('qtd_multiplo') }
  get vlr_preco_max_cons() { return this.cadastroForm.controls.produto_empresa.get('vlr_preco_max_cons') }
  get aliq_desconto_max_atacado() { return this.cadastroForm.controls.produto_empresa.get('aliq_desconto_max_atacado') }
  get usa_desconto_atacado() { return this.cadastroForm.controls.produto_empresa.get('usa_desconto_atacado') }
  get usa_desconto_max_lanc_auto() { return this.cadastroForm.controls.produto_empresa.get('usa_desconto_max_lanc_auto') }
  get qtd_atacarejo() { return this.cadastroForm.controls.produto_empresa.get('qtd_atacarejo') }
  get vlr_desconto_atacarejo() { return this.cadastroForm.controls.produto_empresa.get('vlr_desconto_atacarejo') }
  get usa_comissao_atacado() { return this.cadastroForm.controls.produto_empresa.get('usa_comissao_atacado') }
  get aliq_comissao_atacado() { return this.cadastroForm.controls.produto_empresa.get('aliq_comissao_atacado') }
  get vlr_comissao_entrega() { return this.cadastroForm.controls.produto_empresa.get('vlr_comissao_entrega') }
  get vlr_preco_sugerido() { return this.cadastroForm.controls.produto_empresa.get('vlr_preco_sugerido') }
  get tributacao_id() { return this.cadastroForm.controls.produto_empresa.get('tributacao_id') }
  get chk_ecommerce() { return this.cadastroForm.controls.produto_empresa.get('chk_ecommerce') }
  get id_sku() { return this.cadastroForm.controls.produto_empresa.get('id_sku') }
  get ecommerce_departamento_id() { return this.cadastroForm.controls.produto_empresa.get('ecommerce_departamento_id') }
  get ecommerce_categoria_id() { return this.cadastroForm.controls.produto_empresa.get('ecommerce_categoria_id') }
  get ecommerce_sub_categoria_id() { return this.cadastroForm.controls.produto_empresa.get('ecommerce_sub_categoria_id') }
}