import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { URLBASE, URLIMPRESSAO } from '@modules/configuration.service';

import { PesquisaGeralViewModel } from '@modules/admin/view-models/pesquisa/PesquisaGeralViewModel';
import { RetornoApiViewModel } from '@modules/manager/view-models/RetornoApiViewModel';
import { CadastroViewModel } from '@modules/admin/view-models/cadastro/CadastroViewModel';
import { CadastroArrayViewModel } from '@modules/admin/view-models/cadastro/CadastroArrayViewModel';

import { AutenticacaoService } from '@modules/autenticacao/services/autenticacao.service';
import { ContasReceberPagarPesquisaViewModel } from '@modules/manager/view-models/financeiro/contas-receber-pagar/pesquisa/contas-receber-pagar-pesquisa-view-model';
import { ContasReceberPagarViewModel } from '@modules/manager/view-models/financeiro/contas-receber-pagar/contas-receber-pagar-view-model';
import { ApiRelatorioViewModel, RelatorioViewModel } from '@modules/manager/view-models/RelatorioViewModel';
import { Uteis } from '@modules/utilizarios/uteis';
import { TipoVencimentoPgto } from '@modules/utilizarios/tipoVencimentoPgto';
import { concatMap } from 'rxjs/operators';
import { ConfiguracaoService } from '@modules/manager/sistema/configuracao/configuracao.service';
import { ConfiguracaoCaixaViewModel } from '@modules/manager/view-models/sistema/configuracao-caixa-view-model';

@Injectable({
  providedIn: 'root'
})
export class ContasReceberPagarService {

  idEmpresaMaxdata = this.autenticacao$.acessoUsuarioValue?.idEmpresaMaxdata;
  private urlApiMax = `${URLBASE}/${this.idEmpresaMaxdata}/pgto_service`;
  private urlGet = `${this.urlApiMax}/get`;
  private urlGetGroup = `${this.urlApiMax}/getGroup`;
  private urlAddUpdate = `${this.urlApiMax}/addupdate`;
  private urlSuprimento = `${this.urlApiMax}/GerarSuprimento`;
  private urlSangria = `${this.urlApiMax}/GerarSangria`;
  private urlQuitar = `${this.urlApiMax}/Quitar`;
  private urlCancelar = `${this.urlApiMax}/Cancelar`;
  private urlAddUpdateArray = `${this.urlApiMax}/AddUpdateArray`;
  private urlGerarBoleto = `${this.urlApiMax}/GerarBoleto`;
  private urlGerarBoletoArray = `${this.urlApiMax}/GerarBoletoArray`;
  private urlGerarRemessa = `${this.urlApiMax}/GerarRemessa`;
  private urlGerarRemessa2Via = `${this.urlApiMax}/GerarRemessa2Via`;
  private urlProcessarRetorno = `${this.urlApiMax}/ProcessarRetorno`;
  private urlImpressaoRel = `${URLIMPRESSAO}/api/relatorio`;
  private urlTransferirConta = `${this.urlApiMax}/TransferirConta`;
  private urlReativar = `${this.urlApiMax}/Reativar`;

  listaTiposVencimento: TipoVencimentoPgto[] = TipoVencimentoPgto.listaTiposVencimento();

  constructor(private http: HttpClient,
    private autenticacao$: AutenticacaoService,
    private configuracao$: ConfiguracaoService) { }

  obterTodos(pesquisa: ContasReceberPagarPesquisaViewModel): Observable<RetornoApiViewModel<ContasReceberPagarPesquisaViewModel[]>> {

    let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel()

    pesquisaGeral.select = `CASE WHEN pgto.tipo_operacao='DV' THEN pgto.vlr * -1
                                 WHEN pgto.status='C' THEN pgto.vlr * 0
                            ELSE pgto.vlr END AS vlr
                          ,pgto.caixa_id 
                          ,  pgto.* 
                          ,  pgto_tipo.* 
                          , cliente.nome as cliente_nome
                          , pgto_tipo.descricao as pgto_tipo_descricao
                          , TRIM(atendente.nome) as atendente_nome 
                          , COALESCE(produto_tabela_preco.descricao, '') AS produto_tabela_preco_descricao
                          , TRIM(COALESCE(cartao.bandeira, '')) AS cartao_bandeira
                          , CASE WHEN COALESCE(pgto.venda_id, 0) > 0 THEN CONCAT(venda.tipo, '.', CAST(pgto.venda_id AS VARCHAR)) ELSE pgto.numero_doc END AS numero_operacao`;

    pesquisaGeral.calcRowCount = 1;

    pesquisaGeral.start = pesquisa?.start || 0;

    if (pesquisa?.quantidadeRegistrosPagina >= 0) pesquisaGeral.limit = pesquisa?.quantidadeRegistrosPagina;
    else pesquisaGeral.limit = 100;

    if (pesquisa?.status) pesquisaGeral.where = pesquisa?.status && pesquisa?.status.length > 1 ? ` and pgto.status in (${pesquisa?.status})` : ` and pgto.status = '${pesquisa?.status || 'N'}'`;

    if (pesquisa?.tipo_conta) pesquisaGeral.where += ` AND pgto.tipo_conta = '${pesquisa?.tipo_conta}' `;

    pesquisaGeral.order = `${pesquisa?.order ? pesquisa?.order : 'pgto.dt_vencimento'}`;

    if (pesquisa?.id) pesquisaGeral.where += ` AND pgto.id = ${pesquisa?.id || 0} `;

    if (pesquisa?.nf_id) pesquisaGeral.where += ` AND pgto.nf_id = ${pesquisa?.nf_id || 0} `;

    if (pesquisa?.portador_id) pesquisaGeral.where += ` AND pgto.portador_id = ${pesquisa?.portador_id}`;

    if (pesquisa?.cte_id) pesquisaGeral.where += ` AND pgto.cte_id = ${pesquisa?.cte_id}`;

    if (pesquisa?.tipo_vencimento === TipoVencimentoPgto.AVencer.id) pesquisaGeral.where += " AND pgto.dt_vencimento >= (CURRENT_DATE + TIME '23:59:59')";

    if (pesquisa?.tipo_vencimento === TipoVencimentoPgto.Vencidas.id) pesquisaGeral.where += " AND pgto.dt_vencimento < (CURRENT_DATE + TIME '00:00:00')";

    if (pesquisa?.tipo_vencimento === TipoVencimentoPgto.VenceHoje.id) pesquisaGeral.where += " AND (pgto.dt_vencimento BETWEEN (CURRENT_DATE + TIME '00:00:00') AND (CURRENT_DATE + TIME '23:59:59'))";

    if ((pesquisa?.venda_id || 0) > 0) pesquisaGeral.where += ' AND pgto.venda_id = ' + (pesquisa?.venda_id || 0);

    let clienteId = parseInt(Uteis.SomenteNumeros((pesquisa?.cliente_nome || '')));

    clienteId = Number(clienteId) && clienteId > 0 ? clienteId : 0;

    if (clienteId > 0 || pesquisa?.cliente_id) pesquisaGeral.where += ` AND pgto.cliente_id = ${clienteId > 0 ? clienteId : pesquisa?.cliente_id || 0}`;

    if (clienteId <= 0 && (pesquisa?.cliente_nome || '') !== '') pesquisaGeral.where += ` AND LOWER(cliente.nome) LIKE LOWER('%` + (pesquisa?.cliente_nome || '') + `%')`;

    if ((pesquisa?.numero_doc || '') !== '') pesquisaGeral.where += ` AND LOWER(pgto.numero_doc) LIKE LOWER('%` + (pesquisa?.numero_doc || '') + `%')`;

    if ((pesquisa?.vlr_str || '0,00') !== '0,00') {

      const valorStr = pesquisa?.vlr_str || 0;

      const valor = valorStr;

      if (Number(valor) > 0) pesquisaGeral.where += ' AND ROUND(COALESCE(pgto.vlr, 0), 2) = ' + valor;
    }

    if ((pesquisa?.obs || '') !== '') pesquisaGeral.where += ` AND LOWER(pgto.obs) LIKE LOWER('` + (pesquisa?.obs || '') + `%')`;

    if ((pesquisa?.conta_id || 0) > 0) pesquisaGeral.where += ' AND pgto.conta_id = ' + (pesquisa?.conta_id || 0);

    if (pesquisa?.contas_mov_selecionada && pesquisa?.contas_mov_selecionada.length > 0) {

      var conta_id: Array<number> = [];

      pesquisa?.contas_mov_selecionada.forEach(x => conta_id.push(x.id));

      pesquisaGeral.where += ` AND pgto.conta_id in (${conta_id.toString()})`

    }

    if ((pesquisa?.nosso_numero || '') !== '') pesquisaGeral.where += ` AND LOWER(pgto.nosso_numero) LIKE LOWER('%` + (pesquisa?.nosso_numero || '') + `%')`;

    if ((pesquisa?.grupo_cliente_id || 0) > 0) pesquisaGeral.where += ' AND cliente.cliente_grupo_id = ' + (pesquisa?.grupo_cliente_id || 0);

    pesquisa?.tipo_operacao ? Array.isArray(pesquisa?.tipo_operacao) ? pesquisaGeral.where += ` AND pgto.tipo_operacao IN (${pesquisa?.tipo_operacao.toString()})`
      : pesquisaGeral.where += ` AND pgto.tipo_operacao IN ('${pesquisa?.tipo_operacao}')` : pesquisaGeral.where += ` AND pgto.tipo_operacao IN ('VE','OS', 'FI', 'FA', 'RE', 'BP', 'CR', 'CO','DV','SU','SA','FR')`;

    if ((pesquisa?.atendente_id || 0) > 0) pesquisaGeral.where += ' AND pgto.atendente_id = ' + (pesquisa?.atendente_id || 0);

    if ((pesquisa?.usuario_quitou_id || 0) > 0) pesquisaGeral.where += ' AND pgto.usuario_quitou_id = ' + (pesquisa?.usuario_quitou_id || 0);

    if ((pesquisa?.cartao_id || 0) > 0) pesquisaGeral.where += ' AND pgto.cartao_id = ' + (pesquisa?.cartao_id || 0);

    if ((pesquisa?.portador_id || 0) > 0) pesquisaGeral.where += ' AND pgto.portador_id = ' + (pesquisa?.portador_id || 0);

    if ((pesquisa?.plano_conta_id || 0) > 0) pesquisaGeral.where += ' AND pgto.plano_conta_id = ' + (pesquisa?.plano_conta_id || 0);

    if ((pesquisa?.sub_plano_conta_id || 0) > 0) pesquisaGeral.where += ' AND pgto.sub_plano_conta_id = ' + (pesquisa?.sub_plano_conta_id || 0);

    if ((pesquisa?.centro_custo_id || 0) > 0) pesquisaGeral.where += ' AND pgto.centro_custo_id = ' + (pesquisa?.centro_custo_id || 0);

    if ((pesquisa?.dt_emissao_inicio || '') !== '' && (pesquisa?.dt_emissao_fim || '') !== '') pesquisaGeral.where += ` AND (CASE WHEN pgto.dt_emissao >= '1900-01-01'::timestamp THEN pgto.dt_emissao ELSE pgto.dt_lancamento END BETWEEN ('${pesquisa?.dt_emissao_inicio}T00:00:00') AND ('${pesquisa?.dt_emissao_fim}T23:59:59'))`;

    if ((pesquisa?.dt_vencimento_inicio || '') !== '' && (pesquisa?.dt_vencimento_fim || '') !== '') pesquisaGeral.where += ` AND (pgto.dt_vencimento BETWEEN ('${pesquisa?.dt_vencimento_inicio}T00:00:00') AND ('${pesquisa?.dt_vencimento_fim}T23:59:59'))`;

    if ((pesquisa?.dt_pagamento_inicio || '') !== '' && (pesquisa?.dt_pagamento_fim || '') !== '') pesquisaGeral.where += ` AND (pgto.dt_quitou BETWEEN ('${pesquisa?.dt_pagamento_inicio}T00:00:00') AND ('${pesquisa?.dt_pagamento_fim}T23:59:59'))`;

    if ((pesquisa?.dt_cancelamento_inicio || '') !== '' && (pesquisa?.dt_cancelamento_fim || '') !== '') pesquisaGeral.where += ` AND (pgto.dt_cancel BETWEEN ('${pesquisa?.dt_cancelamento_inicio}T00:00:00') AND ('${pesquisa?.dt_cancelamento_fim}T23:59:59'))`;

    if ((pesquisa?.dt_lancamento_inicio || '') !== '' && (pesquisa?.dt_lancamento_fim || '') !== '') pesquisaGeral.where += ` AND (pgto.dt_lancamento BETWEEN ('${pesquisa?.dt_lancamento_inicio}T00:00:00') AND ('${pesquisa?.dt_lancamento_fim}T23:59:59'))`;

    if (pesquisa?.pgto_tipo_id) pesquisaGeral.where += ` AND pgto.pgto_tipo_id IN (${pesquisa?.pgto_tipo_id})`;

    if (pesquisa?.caixa_id) pesquisaGeral.where += ` AND pgto.caixa_id IN (${pesquisa?.caixa_id})`;

    pesquisaGeral.select += `,(SELECT SUM(pgto.vlr) FROM pgto WHERE ${pesquisaGeral.where.substr(4)} and pgto.emp_id = ${this.autenticacao$.acessoUsuarioValue?.idLoja || 1}) AS vlr_total`;

    return this.http.post<RetornoApiViewModel<ContasReceberPagarPesquisaViewModel[]>>(this.urlGet, pesquisaGeral)
  }


  obterTodosContasPagarReceber(pesquisa: ContasReceberPagarPesquisaViewModel): Observable<RetornoApiViewModel<ContasReceberPagarPesquisaViewModel[]>> {

    let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel()

    pesquisaGeral.select = `CASE WHEN pgto.tipo_operacao='DV' THEN pgto.vlr * -1
                                 WHEN pgto.status='C' THEN pgto.vlr * 0
                            ELSE pgto.vlr END AS vlr
                          ,pgto.caixa_id 
                          ,  pgto.* 
                          ,  pgto_tipo.* 
                          , cliente.nome as cliente_nome
                          , pgto_tipo.descricao as pgto_tipo_descricao
                          , TRIM(atendente.nome) as atendente_nome 
                          , COALESCE(produto_tabela_preco.descricao, '') AS produto_tabela_preco_descricao
                          , TRIM(COALESCE(cartao.bandeira, '')) AS cartao_bandeira
                          , CASE WHEN COALESCE(pgto.venda_id, 0) > 0 THEN CONCAT(venda.tipo, '.', CAST(pgto.venda_id AS VARCHAR)) ELSE pgto.numero_doc END AS numero_operacao`;

    pesquisaGeral.calcRowCount = 1;

    pesquisaGeral.start = pesquisa?.start || 0;

    if (pesquisa?.quantidadeRegistrosPagina >= 0) pesquisaGeral.limit = pesquisa?.quantidadeRegistrosPagina;
    else pesquisaGeral.limit = 100;

    pesquisaGeral.where = pesquisa?.status ?(pesquisa?.status === 'D' ? '':` AND pgto.status in ('${pesquisa?.status}')`):` AND pgto.status = 'N'`;

    if (pesquisa?.tipo_conta) pesquisaGeral.where += ` AND pgto.tipo_conta = '${pesquisa?.tipo_conta}' `;

    pesquisaGeral.order = `${pesquisa?.order ? pesquisa?.order : 'pgto.dt_vencimento'}`;

    if (pesquisa?.id) pesquisaGeral.where += ` AND pgto.id = ${pesquisa?.id || 0} `;

    if (pesquisa?.nf_id) pesquisaGeral.where += ` AND pgto.nf_id = ${pesquisa?.nf_id || 0} `;

    //if (pesquisa?.venda_id) pesquisaGeral.where += ` AND pgto.venda_id = ${pesquisa?.venda_id || 0} `;

    if (pesquisa?.portador_id) pesquisaGeral.where += ` AND pgto.portador_id = ${pesquisa?.portador_id}`;

    if (pesquisa?.tipo_vencimento === TipoVencimentoPgto.AVencer.id) pesquisaGeral.where += " AND pgto.dt_vencimento >= (CURRENT_DATE + TIME '23:59:59')";

    if (pesquisa?.tipo_vencimento === TipoVencimentoPgto.Vencidas.id) pesquisaGeral.where += " AND pgto.dt_vencimento < (CURRENT_DATE + TIME '00:00:00')";

    if (pesquisa?.tipo_vencimento === TipoVencimentoPgto.VenceHoje.id) pesquisaGeral.where += " AND (pgto.dt_vencimento BETWEEN (CURRENT_DATE + TIME '00:00:00') AND (CURRENT_DATE + TIME '23:59:59'))";

    if ((pesquisa?.venda_id || 0) > 0) pesquisaGeral.where += ' AND pgto.venda_id = ' + (pesquisa?.venda_id || 0);

    let clienteId = parseInt(Uteis.SomenteNumeros((pesquisa?.cliente_nome || '')));

    clienteId = Number(clienteId) && clienteId > 0 ? clienteId : 0;

    if (clienteId > 0 || pesquisa?.cliente_id) pesquisaGeral.where += ` AND pgto.cliente_id = ${clienteId > 0 ? clienteId : pesquisa?.cliente_id || 0}`;

    if (clienteId <= 0 && (pesquisa?.cliente_nome || '') !== '') pesquisaGeral.where += ` AND LOWER(cliente.nome) LIKE LOWER('%` + (pesquisa?.cliente_nome || '') + `%')`;

    if ((pesquisa?.numero_doc || '') !== '') pesquisaGeral.where += ` AND LOWER(pgto.numero_doc) LIKE LOWER('%` + (pesquisa?.numero_doc || '') + `%')`;

    if ((pesquisa?.vlr_str || '0,00') !== '0,00') {

      const valorStr = pesquisa?.vlr_str || 0;

      const valor = valorStr;

      if (Number(valor) > 0) pesquisaGeral.where += ' AND ROUND(COALESCE(pgto.vlr, 0), 2) = ' + valor;
    }

    if (pesquisa?.obs) pesquisaGeral.where += ` AND LOWER(pgto.obs) LIKE LOWER('%${pesquisa?.obs}%')`;

    if ((pesquisa?.conta_id || 0) > 0) pesquisaGeral.where += ' AND pgto.conta_id = ' + (pesquisa?.conta_id || 0);

    if (pesquisa?.contas_mov_selecionada && pesquisa?.contas_mov_selecionada.length > 0) {

      var conta_id: Array<number> = [];

      pesquisa?.contas_mov_selecionada.forEach(x => conta_id.push(x.id));

      pesquisaGeral.where += ` AND pgto.conta_id in (${conta_id.toString()})`

    }

    if ((pesquisa?.nosso_numero || '') !== '') pesquisaGeral.where += ` AND LOWER(pgto.nosso_numero) LIKE LOWER('%` + (pesquisa?.nosso_numero || '') + `%')`;

    if ((pesquisa?.grupo_cliente_id || 0) > 0) pesquisaGeral.where += ' AND cliente.cliente_grupo_id = ' + (pesquisa?.grupo_cliente_id || 0);

    pesquisa?.tipo_operacao ? Array.isArray(pesquisa?.tipo_operacao) ? pesquisaGeral.where += ` AND pgto.tipo_operacao IN (${pesquisa?.tipo_operacao.toString()})`
      : pesquisaGeral.where += ` AND pgto.tipo_operacao IN ('${pesquisa?.tipo_operacao}')` : pesquisaGeral.where += ` AND pgto.tipo_operacao IN ('VE','OS', 'FI', 'FA', 'RE', 'BP', 'CR', 'CO','DV','SU','SA')`;

    if ((pesquisa?.atendente_id || 0) > 0) pesquisaGeral.where += ' AND pgto.atendente_id = ' + (pesquisa?.atendente_id || 0);

    if ((pesquisa?.usuario_quitou_id || 0) > 0) pesquisaGeral.where += ' AND pgto.usuario_quitou_id = ' + (pesquisa?.usuario_quitou_id || 0);

    if ((pesquisa?.cartao_id || 0) > 0) pesquisaGeral.where += ' AND pgto.cartao_id = ' + (pesquisa?.cartao_id || 0);

    if ((pesquisa?.portador_id || 0) > 0) pesquisaGeral.where += ' AND pgto.portador_id = ' + (pesquisa?.portador_id || 0);

    if ((pesquisa?.plano_conta_id || 0) > 0) pesquisaGeral.where += ' AND pgto.plano_conta_id = ' + (pesquisa?.plano_conta_id || 0);

    if ((pesquisa?.sub_plano_conta_id || 0) > 0) pesquisaGeral.where += ' AND pgto.sub_plano_conta_id = ' + (pesquisa?.sub_plano_conta_id || 0);

    if ((pesquisa?.centro_custo_id || 0) > 0) pesquisaGeral.where += ' AND pgto.centro_custo_id = ' + (pesquisa?.centro_custo_id || 0);

    if ((pesquisa?.dt_emissao_inicio || '') !== '' && (pesquisa?.dt_emissao_fim || '') !== '') pesquisaGeral.where += ` AND (CASE WHEN pgto.dt_emissao >= '1900-01-01'::timestamp THEN pgto.dt_emissao ELSE pgto.dt_lancamento END BETWEEN ('${pesquisa?.dt_emissao_inicio}T00:00:00') AND ('${pesquisa?.dt_emissao_fim}T23:59:59'))`;

    if ((pesquisa?.dt_vencimento_inicio || '') !== '' && (pesquisa?.dt_vencimento_fim || '') !== '') pesquisaGeral.where += ` AND (pgto.dt_vencimento BETWEEN ('${pesquisa?.dt_vencimento_inicio}T00:00:00') AND ('${pesquisa?.dt_vencimento_fim}T23:59:59'))`;

    if ((pesquisa?.dt_pagamento_inicio || '') !== '' && (pesquisa?.dt_pagamento_fim || '') !== '') pesquisaGeral.where += ` AND (pgto.dt_quitou BETWEEN ('${pesquisa?.dt_pagamento_inicio}T00:00:00') AND ('${pesquisa?.dt_pagamento_fim}T23:59:59'))`;

    if ((pesquisa?.dt_cancelamento_inicio || '') !== '' && (pesquisa?.dt_cancelamento_fim || '') !== '') pesquisaGeral.where += ` AND (pgto.dt_cancel BETWEEN ('${pesquisa?.dt_cancelamento_inicio}T00:00:00') AND ('${pesquisa?.dt_cancelamento_fim}T23:59:59'))`;

    if ((pesquisa?.dt_lancamento_inicio || '') !== '' && (pesquisa?.dt_lancamento_fim || '') !== '') pesquisaGeral.where += ` AND (pgto.dt_lancamento BETWEEN ('${pesquisa?.dt_lancamento_inicio}T00:00:00') AND ('${pesquisa?.dt_lancamento_fim}T23:59:59'))`;

    if (pesquisa?.pgto_tipo_id) pesquisaGeral.where += ` AND pgto.pgto_tipo_id IN (${pesquisa?.pgto_tipo_id})`;

    if (pesquisa?.caixa_id) pesquisaGeral.where += ` AND pgto.caixa_id IN (${pesquisa?.caixa_id})`;

    pesquisaGeral.select += `,(SELECT SUM(pgto.vlr) FROM pgto WHERE ${pesquisaGeral.where.substr(4)} and pgto.emp_id = ${this.autenticacao$.acessoUsuarioValue?.idLoja || 1}) AS vlr_total`;

    return this.http.post<RetornoApiViewModel<ContasReceberPagarPesquisaViewModel[]>>(this.urlGet, pesquisaGeral)
  }
  obterTodosImpresao(pesquisa: ContasReceberPagarPesquisaViewModel): Observable<RetornoApiViewModel<ContasReceberPagarPesquisaViewModel[]>> {

    let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel()

    pesquisaGeral.select = `pgto.acompanhamento
    ,pgto.aliq_comissao
    ,pgto.atendente_id
    ,pgto.avalista_id
    , TO_CHAR(pgto.dt_lancamento, 'dd/mm/yyyy HH:mi') as lancamento
    ,pgto.banco_numero_arquivo
    ,pgto.caixa_id
    ,pgto.cartao_id
    ,pgto.centro_custo_id
    ,pgto.chk_estorno
    ,pgto.chk_recebido_no_caixa
    ,pgto.chk_transferiu_conta
    ,pgto.cliente_id
    ,pgto.codigo_agencia
    ,pgto.codigo_aliena_cheque_dev
    ,pgto.codigo_autorizacao
    ,pgto.codigo_banco
    ,pgto.codigo_conta_corrente
    ,pgto.codigo_portador
    ,pgto.conta_descontado_id
    ,pgto.conta_id
    ,pgto.dt_boleto_grafico
    ,pgto.dt_boleto_remessa
    ,pgto.dt_boleto_retorno
    ,pgto.dt_cancel
    ,pgto.dt_custodia
    ,pgto.dt_descontado
    ,TO_CHAR(pgto.dt_emissao, 'DD/MM/YYYY') as dt_emissao
    ,pgto.dt_estorno
    ,pgto.dt_faturou
    ,pgto.dt_lancamento
    ,TO_CHAR(pgto.dt_quitou, 'DD/MM/YYYY') as dt_quitou
    ,TO_CHAR(pgto.dt_vencimento, 'DD/MM/YYYY') as dt_vencimento
    ,pgto.emp_id
    ,pgto.historico
    ,pgto.id
    ,pgto.motivo_cancel
    ,pgto.msg_portador
    ,pgto.nf_id
    ,pgto.nosso_numero
    ,pgto.nsu
    ,pgto.numero_doc
    ,pgto.numero_parcela
    ,pgto.obs
    ,pgto.pgto_descontado_id
    ,pgto.pgto_tipo_id
    ,pgto.plano_conta_id
    ,pgto.portador_acbr_codigo_mora
    ,pgto.portador_acbr_data_baixa
    ,pgto.portador_acbr_data_desconto
    ,pgto.portador_acbr_data_mora_juros
    ,pgto.portador_acbr_data_protesto
    ,pgto.portador_acbr_multa_valor_fixo
    ,pgto.portador_acbr_parcela
    ,pgto.portador_acbr_percentual_multa
    ,pgto.portador_acbr_tipo_desconto
    ,pgto.portador_acbr_tipo_juros
    ,pgto.portador_acbr_total_parcelas
    ,pgto.portador_acbr_valor_abatimento
    ,pgto.portador_acbr_valor_desconto
    ,pgto.portador_acbr_valor_mora_juros
    ,pgto.portador_id
    ,pgto.remessa_numero
    ,pgto.remessa_usuario_id
    ,pgto.status
    ,pgto.sub_plano_conta_id
    ,pgto.tipo_conta
    ,pgto.tipo_operacao
    ,pgto.usuario_cancelou_id
    ,pgto.usuario_estornou_id
    ,pgto.usuario_faturou_id
    ,pgto.usuario_lancou_id
    ,pgto.usuario_quitou_id
    ,pgto.venda_id
    ,pgto.vlr
    ,pgto.vlr_comissao
    ,pgto.vlr_desconto_fin
    ,pgto.vlr_dinheiro_troco
    ,pgto.vlr_juros
    ,pgto.vlr_multa
    ,usu_quitou.nome as usu_quitou_nome
    , cliente.nome as cliente_nome
    , pgto_tipo.descricao as pgto_tipo_descricao
    , TRIM(atendente.nome) as atendente_nome 
    , COALESCE(produto_tabela_preco.descricao, '') AS produto_tabela_preco_descricao
    , TRIM(COALESCE(cartao.bandeira, '')) AS cartao_bandeira
    , CASE WHEN COALESCE(pgto.venda_id, 0) > 0 THEN CONCAT(venda.tipo, '.', CAST(pgto.venda_id AS VARCHAR)) ELSE pgto.numero_doc END AS numero_operacao`;

    pesquisaGeral.calcRowCount = 1;

    pesquisaGeral.start = pesquisa?.start || 0;

    pesquisaGeral.limit = 0;

    pesquisaGeral.where = pesquisa?.status && pesquisa?.status.length > 1 ? ` and pgto.status in (${pesquisa?.status})` : ` and pgto.status = '${pesquisa?.status || 'N'}'`

    if (pesquisa?.tipo_conta) pesquisaGeral.where += ` and pgto.tipo_conta = '${pesquisa?.tipo_conta}' `;

    pesquisaGeral.order = `${pesquisa?.order ? pesquisa?.order : 'pgto.dt_vencimento'}`;

    if (pesquisa?.id) pesquisaGeral.where += ` and pgto.id = ${pesquisa?.id || 0} `;

    if (pesquisa?.nf_id) pesquisaGeral.where += ` and pgto.nf_id = ${pesquisa?.nf_id || 0} `;

    if (pesquisa?.venda_id) pesquisaGeral.where += ` and pgto.venda_id = ${pesquisa?.venda_id || 0} `;

    if (pesquisa?.portador_id) pesquisaGeral.where += ` and pgto.portador_id = ${pesquisa?.portador_id}`;

    if (pesquisa?.tipo_vencimento === TipoVencimentoPgto.AVencer.id) pesquisaGeral.where += " AND pgto.dt_vencimento >= (CURRENT_DATE + TIME '23:59:59')";

    if (pesquisa?.tipo_vencimento === TipoVencimentoPgto.Vencidas.id) pesquisaGeral.where += " AND pgto.dt_vencimento < (CURRENT_DATE + TIME '00:00:00')";

    if (pesquisa?.tipo_vencimento === TipoVencimentoPgto.VenceHoje.id) pesquisaGeral.where += " AND (pgto.dt_vencimento BETWEEN (CURRENT_DATE + TIME '00:00:00') AND (CURRENT_DATE + TIME '23:59:59'))";

    if ((pesquisa?.venda_id || 0) > 0) pesquisaGeral.where += ' AND pgto.venda_id = ' + (pesquisa?.venda_id || 0);

    let clienteId = parseInt(Uteis.SomenteNumeros((pesquisa?.cliente_nome || '')));

    clienteId = Number(clienteId) && clienteId > 0 ? clienteId : 0;

    if (clienteId > 0 || pesquisa?.cliente_id) pesquisaGeral.where += ` AND pgto.cliente_id = ${clienteId > 0 ? clienteId : pesquisa?.cliente_id || 0}`;

    if (clienteId <= 0 && (pesquisa?.cliente_nome || '') !== '') pesquisaGeral.where += ` AND LOWER(cliente.nome) LIKE LOWER('%` + (pesquisa?.cliente_nome || '') + `%')`;

    if ((pesquisa?.numero_doc || '') !== '') pesquisaGeral.where += ` AND LOWER(pgto.numero_doc) LIKE LOWER('%` + (pesquisa?.numero_doc || '') + `%')`;

    if ((pesquisa?.vlr_str || '0,00') !== '0,00') {

      const valorStr = pesquisa?.vlr_str || 0;

      const valor = Number(valorStr);

      if (valor > 0) pesquisaGeral.where += ' AND ROUND(COALESCE(pgto.vlr, 0), 2) = ' + valor;
    }
    if ((pesquisa?.obs || '') !== '') pesquisaGeral.where += ` AND LOWER(pgto.obs) LIKE LOWER('` + (pesquisa?.obs || '') + `%')`;

    if ((pesquisa?.conta_id || 0) > 0) pesquisaGeral.where += ' AND pgto.conta_id = ' + (pesquisa?.conta_id || 0);

    if (pesquisa?.contas_mov_selecionada && pesquisa?.contas_mov_selecionada.length > 0) {

      var conta_id: Array<number> = [];

      pesquisa?.contas_mov_selecionada.forEach(x => conta_id.push(x.id));

      pesquisaGeral.where += ` AND pgto.conta_id in (${conta_id.toString()})`

    }

    if ((pesquisa?.nosso_numero || '') !== '') pesquisaGeral.where += ` AND LOWER(pgto.nosso_numero) LIKE LOWER('%` + (pesquisa?.nosso_numero || '') + `%')`;

    if ((pesquisa?.grupo_cliente_id || 0) > 0) pesquisaGeral.where += ' AND cliente.cliente_grupo_id = ' + (pesquisa?.grupo_cliente_id || 0);



    pesquisa?.tipo_operacao ? Array.isArray(pesquisa?.tipo_operacao) ? pesquisaGeral.where += ` AND pgto.tipo_operacao IN (${pesquisa?.tipo_operacao.toString()})` : pesquisaGeral.where += ` AND pgto.tipo_operacao IN ('${pesquisa?.tipo_operacao}')` : pesquisaGeral.where += ` AND pgto.tipo_operacao IN ('VE','OS', 'FI', 'FA', 'RE', 'BP', 'CR', 'CO','DV')`;

    if ((pesquisa?.atendente_id || 0) > 0) pesquisaGeral.where += ' AND pgto.atendente_id = ' + (pesquisa?.atendente_id || 0);

    if ((pesquisa?.usuario_quitou_id || 0) > 0) pesquisaGeral.where += ' AND pgto.usuario_quitou_id = ' + (pesquisa?.usuario_quitou_id || 0);

    if ((pesquisa?.cartao_id || 0) > 0) pesquisaGeral.where += ' AND pgto.cartao_id = ' + (pesquisa?.cartao_id || 0);

    if ((pesquisa?.portador_id || 0) > 0) pesquisaGeral.where += ' AND pgto.portador_id = ' + (pesquisa?.portador_id || 0);

    if ((pesquisa?.plano_conta_id || 0) > 0) pesquisaGeral.where += ' AND pgto.plano_conta_id = ' + (pesquisa?.plano_conta_id || 0);

    if ((pesquisa?.sub_plano_conta_id || 0) > 0) pesquisaGeral.where += ' AND pgto.sub_plano_conta_id = ' + (pesquisa?.sub_plano_conta_id || 0);

    if ((pesquisa?.centro_custo_id || 0) > 0) pesquisaGeral.where += ' AND pgto.centro_custo_id = ' + (pesquisa?.centro_custo_id || 0);

    if ((pesquisa?.dt_emissao_inicio || '') !== '' && (pesquisa?.dt_emissao_fim || '') !== '') pesquisaGeral.where += ` AND (CASE WHEN pgto.dt_emissao >= '1900-01-01'::timestamp THEN pgto.dt_emissao ELSE pgto.dt_lancamento END BETWEEN ('${pesquisa?.dt_emissao_inicio}T00:00:00') AND ('${pesquisa?.dt_emissao_fim}T23:59:59'))`;

    if ((pesquisa?.dt_vencimento_inicio || '') !== '' && (pesquisa?.dt_vencimento_fim || '') !== '') pesquisaGeral.where += ` AND (pgto.dt_vencimento BETWEEN ('${pesquisa?.dt_vencimento_inicio}T00:00:00') AND ('${pesquisa?.dt_vencimento_fim}T23:59:59'))`;

    if ((pesquisa?.dt_pagamento_inicio || '') !== '' && (pesquisa?.dt_pagamento_fim || '') !== '') pesquisaGeral.where += ` AND (pgto.dt_quitou BETWEEN ('${pesquisa?.dt_pagamento_inicio}T00:00:00') AND ('${pesquisa?.dt_pagamento_fim}T23:59:59'))`;

    if ((pesquisa?.dt_cancelamento_inicio || '') !== '' && (pesquisa?.dt_cancelamento_fim || '') !== '') pesquisaGeral.where += ` AND (pgto.dt_cancel BETWEEN ('${pesquisa?.dt_cancelamento_inicio}T00:00:00') AND ('${pesquisa?.dt_cancelamento_fim}T23:59:59'))`;

    if ((pesquisa?.dt_lancamento_inicio || '') !== '' && (pesquisa?.dt_lancamento_fim || '') !== '') pesquisaGeral.where += ` AND (pgto.dt_lancamento BETWEEN ('${pesquisa?.dt_lancamento_inicio}T00:00:00') AND ('${pesquisa?.dt_lancamento_fim}T23:59:59'))`;

    if (pesquisa?.pgto_tipo_id) pesquisaGeral.where += ` AND pgto.pgto_tipo_id IN (${pesquisa?.pgto_tipo_id})`;

    if (pesquisa?.caixa_id) pesquisaGeral.where += ` AND pgto.caixa_id = ${pesquisa?.caixa_id}`;

    pesquisaGeral.select += `,(SELECT SUM(pgto.vlr) FROM pgto WHERE ${pesquisaGeral.where.substr(4)} and pgto.emp_id = ${this.autenticacao$.acessoUsuarioValue?.idLoja || 1}) AS vlr_total`;

    return this.http.post<RetornoApiViewModel<ContasReceberPagarPesquisaViewModel[]>>(this.urlGet, pesquisaGeral)
  }

  obterTodosRel(pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel()): Observable<RetornoApiViewModel<ContasReceberPagarPesquisaViewModel[]>> {

    return this.http.post<RetornoApiViewModel<ContasReceberPagarPesquisaViewModel[]>>(this.urlGet, pesquisaGeral)
  }

  obterTodosBoleto(pesquisa: ContasReceberPagarPesquisaViewModel): Observable<RetornoApiViewModel<ContasReceberPagarPesquisaViewModel[]>> {

    let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel()

    pesquisaGeral.select = `pgto.id, 
                            pgto.cliente_id, 
                            cliente.nome as cliente_nome, 
                            cliente.cpf_cnpj as cliente_cpf_cnpj, 
                            pgto.vlr, 
                            pgto.portador_id,
                            pgto.nosso_numero,
                            pgto.numero_doc,
                            pgto.dt_vencimento,
                            pgto.dt_emissao,
                            pgto.remessa_numero`;

 

    pesquisaGeral.calcRowCount = 1;

    pesquisaGeral.start = pesquisa?.start || 0;

    if (pesquisa?.quantidadeRegistrosPagina >= 0) pesquisaGeral.limit = pesquisa?.quantidadeRegistrosPagina;
    else pesquisaGeral.limit = 100;

    pesquisaGeral.where = ` and pgto.pgto_tipo_id = 8 and pgto.status = 'N' and pgto.nosso_numero = '' `;

    pesquisaGeral.order = 'pgto.id';

    if (typeof pesquisa?.boleto_status !== 'undefined' && pesquisa?.boleto_status > 0) {
      if (pesquisa?.boleto_status == 1) {
        pesquisaGeral.where = ` and pgto.pgto_tipo_id = 8 
                                    and pgto.status = 'N' 
                                    and pgto.nosso_numero IS NOT NULL 
                                    and pgto.nosso_numero <> '' 
                                    and pgto.dt_boleto_remessa <= '1900-01-01'::timestamp`;
      } else if (pesquisa?.boleto_status == 2) {
        pesquisaGeral.where = ` and pgto.pgto_tipo_id = 8 
                                    and pgto.nosso_numero IS NOT NULL 
                                    and pgto.nosso_numero  <> '' 
                                    and pgto.dt_boleto_remessa >= '1900-01-01'::timestamp
                                    and pgto.remessa_numero > 0`;
      } else {
        pesquisaGeral.where = ` and pgto.pgto_tipo_id = 8 and pgto.status = 'F' and pgto.nosso_numero <> ''`;
      }
    }

    if (pesquisa?.id) pesquisaGeral.where += ' and pgto.id = ' + pesquisa?.id;

    if (pesquisa?.cliente_nome) pesquisaGeral.where += ` and lower(cliente.nome) like lower('%${pesquisa?.cliente_nome}%')`;

    if (pesquisa?.portador_id) pesquisaGeral.where += ` and pgto.portador_id = ${pesquisa?.portador_id}`;

    return this.http.post<RetornoApiViewModel<ContasReceberPagarPesquisaViewModel[]>>(this.urlGet, pesquisaGeral)
  }

  obterPorId(id: number, tipo_conta: string): Observable<RetornoApiViewModel<ContasReceberPagarViewModel[]>> {

    let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel()

    pesquisaGeral.select = `pgto.id,
                          COALESCE(pgto.emp_id,0) as emp_id, 
                          COALESCE(pgto.cliente_id,0) as cliente_id,
                          cliente.nome as cliente_nome,
                          COALESCE(pgto.atendente_id,0) as atendente_id,
                          atendente.nome as atendente_nome,
                          COALESCE(pgto.usuario_lancou_id,0) as usuario_lancou_id,
                          usu_lancou.nome as usuario_lancou_nome,
                          COALESCE(pgto.venda_id,0) as venda_id,
                          COALESCE(pgto.nf_id,0) as nf_id,
                          COALESCE(pgto.conta_id,0) as conta_id,
                          COALESCE(pgto.pgto_tipo_id,0) as pgto_tipo_id,
                          COALESCE(pgto.cartao_id,0) as cartao_id,
                          COALESCE(pgto.caixa_id,0) as caixa_id,
                          pgto.vlr,
                          pgto.vlr_juros,
                          pgto.vlr_multa,
                          pgto.vlr_desconto_fin,
                          pgto.codigo_banco,
                          pgto.codigo_agencia,
                          pgto.codigo_conta_corrente,
                          pgto.numero_doc,
                          pgto.nosso_numero,
                          pgto.codigo_portador,
                          pgto.banco_numero_arquivo,
                          pgto.msg_portador,
                          pgto.dt_emissao,
                          pgto.dt_vencimento,
                          pgto.dt_lancamento,
                          pgto.obs,
                          pgto.historico,
                          pgto.acompanhamento,
                          pgto.numero_parcela,
                          pgto.tipo_conta,
                          pgto.dt_cancel,
                          pgto.motivo_cancel,
                          pgto.status,
                          pgto.dt_quitou,
                          COALESCE(pgto.usuario_quitou_id,0) as usuario_quitou_id,
                          usu_quitou.nome as usuario_quitou_nome,
                          pgto.tipo_operacao,
                          pgto.dt_custodia,
                          pgto.vlr_comissao,
                          pgto.aliq_comissao,
                          pgto.dt_boleto_remessa,
                          pgto.dt_boleto_retorno,
                          pgto.codigo_aliena_cheque_dev,
                          pgto.chk_recebido_no_caixa,
                          COALESCE(pgto.usuario_faturou_id,0) as usuario_faturou_id,
                          usu_estornou.nome as usuario_faturou_nome,
                          pgto.dt_faturou,
                          pgto.vlr_dinheiro_troco,
                          pgto.chk_estorno,
                          pgto.dt_estorno,
                          pgto.dt_descontado,
                          COALESCE(pgto.conta_descontado_id,0) as conta_descontado_id,
                          COALESCE(pgto.pgto_descontado_id,0) as pgto_descontado_id,
                          COALESCE(pgto.avalista_id,0) as avalista_id,
                          COALESCE(pgto.portador_id,0) as portador_id,
                          pgto.portador_acbr_codigo_mora,
                          pgto.portador_acbr_data_baixa,
                          pgto.portador_acbr_data_desconto,
                          pgto.portador_acbr_data_mora_juros,
                          pgto.portador_acbr_data_protesto,
                          pgto.portador_acbr_multa_valor_fixo,
                          pgto.portador_acbr_parcela,
                          pgto.portador_acbr_percentual_multa,
                          pgto.portador_acbr_tipo_desconto,
                          pgto.portador_acbr_tipo_juros,
                          pgto.portador_acbr_total_parcelas,
                          pgto.portador_acbr_valor_abatimento,
                          pgto.portador_acbr_valor_desconto,
                          pgto.portador_acbr_valor_mora_juros,
                          pgto.dt_boleto_grafico,
                          pgto.remessa_numero,
                          COALESCE(pgto.remessa_usuario_id,0) as remessa_usuario_id,
                          COALESCE(pgto.centro_custo_id,0) as centro_custo_id,
                          COALESCE(pgto.plano_conta_id,0) as plano_conta_id,
                          COALESCE(pgto.sub_plano_conta_id,0) as sub_plano_conta_id,
                          COALESCE(pgto.usuario_cancelou_id,0) as usuario_cancelou_id,
                          usu_cancelou.nome as usuario_cancelou_nome,
                          COALESCE(pgto.usuario_estornou_id,0) as usuario_estornou_id,
                          usu_estornou.nome as usuario_estornou_nome,
                          pgto.chk_transferiu_conta`;

    pesquisaGeral.where = ` and pgto.id = ${id} and pgto.tipo_conta = '${tipo_conta ? tipo_conta : 'R'}'`

    return this.http.post<RetornoApiViewModel<ContasReceberPagarViewModel[]>>(`${this.urlGet}`, pesquisaGeral);
  }

  obterTodosGroup(pesquisa: ContasReceberPagarPesquisaViewModel): Observable<RetornoApiViewModel<ContasReceberPagarPesquisaViewModel[]>> {

    let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel();



    return this.http.post<RetornoApiViewModel<ContasReceberPagarPesquisaViewModel[]>>(this.urlGetGroup, pesquisaGeral)
  }

  obterTodosGroupRel(pesquisaGeral: PesquisaGeralViewModel): Observable<RetornoApiViewModel<ContasReceberPagarPesquisaViewModel[]>> {

    return this.http.post<RetornoApiViewModel<ContasReceberPagarPesquisaViewModel[]>>(this.urlGetGroup, pesquisaGeral)
  }

  obterTodosGroupCartao(pesquisa: ContasReceberPagarPesquisaViewModel) {
    var pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel();

    pesquisaGeral.select = `cartao.tipo as cartao_tipo,
                            TRIM(COALESCE(cartao.bandeira, '')) AS cartao_bandeira
                           ,ROUND(COALESCE(cartao.aliq_taxa, 0), 2) AS cartao_aliq_taxa
                           ,ROUND(SUM(COALESCE(pgto.vlr, 0)) , 2) AS pgto_vlr
                           ,ROUND(SUM(COALESCE(pgto.vlr, 0)) * (COALESCE(cartao.aliq_taxa, 100) / 100), 2) AS pgto_vlr_taxa
                           ,ROUND(SUM(COALESCE(pgto.vlr, 0)) - (SUM(COALESCE(pgto.vlr, 0)) * (COALESCE(cartao.aliq_taxa, 100) / 100)), 2) AS pgto_vlr_liquido
                           `;
    pesquisaGeral.where = "AND (venda.status IN ('F', 'A')) AND pgto.status <> 'C' AND pgto.tipo_operacao NOT IN ('SU', 'SA') AND COALESCE(pgto.cartao_id, 0) > 0 ";
    pesquisaGeral.where += `AND pgto.caixa_id IN (${pesquisa?.caixa_id})
                           `;
    pesquisaGeral.group = `cartao.tipo, cartao.bandeira ,cartao.aliq_taxa `;
    pesquisaGeral.order = 'TRIM(cartao.bandeira) ASC';

    return this.http.post<RetornoApiViewModel<ContasReceberPagarPesquisaViewModel[]>>(this.urlGetGroup, pesquisaGeral)

  }

  obterTodosGroupSangriaSupremento(caixa_id): Observable<RetornoApiViewModel<ContasReceberPagarPesquisaViewModel[]>> {

    var pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel()

    pesquisaGeral.select = `pgto.dt_lancamento,
                            pgto.tipo_operacao,
                            ROUND(pgto.vlr, 2) AS vlr,
                            pgto_tipo.descricao AS pgto_tipo_descricao,
                            pgto.historico
                           `;

    pesquisaGeral.where = "AND pgto.tipo_operacao IN ('SU', 'SA') AND ((pgto.tipo_operacao = 'SU' AND pgto.vlr > 0) OR (pgto.tipo_operacao = 'SA' AND pgto.vlr < 0))";

    pesquisaGeral.where += `AND pgto.caixa_id IN (${caixa_id})`;

    pesquisaGeral.order = 'pgto.dt_lancamento ASC';

    pesquisaGeral.limit = 0;

    return this.http.post<RetornoApiViewModel<ContasReceberPagarPesquisaViewModel[]>>(this.urlGet, pesquisaGeral)
  }

  obterTodosGroupPgtoTipo(caixa_id: number): Observable<any> {

    var pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel()

    pesquisaGeral.select = `pgto.pgto_tipo_id, 
                            pgto_tipo.descricao as pgto_tipo_descricao, 
                            pgto_tipo.chk_prazo as pgto_tipo_chk_prazo, 
                                                        
                            SUM(
                              CASE WHEN 
                                pgto.tipo_operacao = 'DV' 
                              THEN 
                                COALESCE(pgto.vlr*-1,0)
                              WHEN 
                                pgto.status = 'C' 
                              THEN 
                                COALESCE(pgto.vlr*0,0)
                              ELSE 
                                COALESCE(pgto.vlr,0)
                              END
                              ) AS pgto_vlr 
                           `;

    //SUM(COALESCE(pgto.vlr * -1,0)) end as devolucao

    pesquisaGeral.where += `AND pgto.status in ('N','S','F','G','P','D','C') 
                            AND pgto.tipo_conta = 'R'  
                            AND pgto.tipo_operacao IN ('CO','VE', 'OS','FA','BP','FI','CR','PG','RE','DV') 
                            AND pgto.caixa_id IN (${caixa_id})
                            `;

    pesquisaGeral.group = `pgto.pgto_tipo_id, pgto_tipo.descricao, pgto_tipo.chk_prazo`

    pesquisaGeral.order = 'pgto_tipo.descricao ASC';

    pesquisaGeral.limit = 0;

    return this.http.post<RetornoApiViewModel<ContasReceberPagarPesquisaViewModel[]>>(this.urlGetGroup, pesquisaGeral)
  }


  adicionar(contasReceberPagarViewModel: CadastroViewModel<ContasReceberPagarViewModel>): Observable<any> {
    return this.http.post<any[]>(this.urlAddUpdate, contasReceberPagarViewModel);
  }

  adicionarLista(contasReceberPagarViewModel: CadastroArrayViewModel<ContasReceberPagarViewModel>): Observable<any> {
    return this.http.post<any>(this.urlAddUpdateArray, contasReceberPagarViewModel);
  }

  recebimento(id: number, contasReceberPagarViewModel: CadastroArrayViewModel<ContasReceberPagarViewModel>): Observable<any> {
    return this.http.post<any>(`${this.urlApiMax}/Recebimento/${id}`, { id_pgto: id, obj_array: contasReceberPagarViewModel.obj_array });
  }

  atualizar(id: number, contasReceberPagarViewModel: CadastroViewModel<ContasReceberPagarViewModel>): Observable<any> {
    return this.http.put<any[]>(`${this.urlAddUpdate}/${id}`, contasReceberPagarViewModel);
  }

  gerarBoleto(id_pgto: number): Observable<any> {
    return this.http.post<any>(`${this.urlGerarBoleto}/${id_pgto}`, { id_pgto });
  }


  gerarBoletoArray(ids: any, id_portador: number): Observable<any> {
    return this.http.post<any>(`${this.urlGerarBoletoArray}`, {pgto_id_array: ids, id_portador});
  }
  gerarRemessa(id_pgto_array: string): Observable<any> {
    return this.http.post<any>(`${this.urlGerarRemessa}`, { id_pgto_array });
  }
  gerarRemessa2Via(id_remessa: number, id_portador: number): Observable<any> {
    return this.http.post<any>(`${this.urlGerarRemessa2Via}`, { id_remessa, id_portador });
  }

 

  imprimir(rel: any, dados: any): Observable<any> {

    return this.http.post<any>(`${this.urlImpressaoRel}/${rel}/`, dados);
  }

  imprimirContasReceberPagar(pesquisa: ContasReceberPagarPesquisaViewModel): Observable<any> {

    let objRelatorio: RelatorioViewModel<any, any> = new RelatorioViewModel<any, any>();

    let logomarca: string = null;

    return this.obterTodosImpresao(pesquisa).pipe(
      concatMap(retorno => {

        objRelatorio.obj = retorno.total > 0 ? retorno.result : [];

        return this.configuracao$.obterImagem(this.autenticacao$.acessoUsuarioValue?.idLoja || 0)
      }),
      concatMap(retorno => {

        logomarca = retorno.result[0].img_logomarca

        return this.configuracao$.obterPorId(this.autenticacao$.acessoUsuarioValue?.idLoja || 0)
      }),
      concatMap(retorno => {

        objRelatorio.params = Object.assign({
          emp_cpf_cnpj: retorno.total > 0 ? retorno.result[0].emp_cnpj : '',
          emp_razao: retorno.total > 0 ? retorno.result[0].emp_razao : '',
          emp_fantasia: retorno.total > 0 ? retorno.result[0].emp_fantasia : '',
          emp_fone: retorno.total > 0 ? retorno.result[0].emp_fone : '',
          emp_endereco: retorno.total > 0 ? retorno.result[0].emp_endereco : '',
          emp_endereco_municipio_descricao: retorno.total > 0 ? retorno.result[0].emp_endereco_municipio_descricao : '',
          emp_endereco_uf_sigla: retorno.total > 0 ? retorno.result[0].emp_endereco_uf_sigla : '',
          emp_logo: logomarca,
          status: pesquisa.status,
          NomeRelatorio: `contas-a-${pesquisa.tipo_conta === 'P' ? 'pagar' : 'receber'}`
        });

        const apiRelatorio = new ApiRelatorioViewModel<any, ContasReceberPagarPesquisaViewModel[]>();

        apiRelatorio.relatorio = objRelatorio;

        apiRelatorio.subrelatorio = [];

        return this.imprimir('contas_receber_pagar', apiRelatorio);

      })
    )

  }

  imprimirMovimentacaoConta(pesquisa, filtros) {
    let objRelatorio: RelatorioViewModel<any, any> = new RelatorioViewModel<any, any>();

    let logomarca: string = null;

    

    return this.obterTodosImpresao(pesquisa).pipe(
      concatMap(retorno => {

        objRelatorio.obj = retorno.total > 0 ? retorno.result : [];

        return this.configuracao$.obterImagem(this.autenticacao$.acessoUsuarioValue?.idLoja || 0)
      }),
      concatMap(retorno => {

        logomarca = retorno.result[0].img_logomarca
        
        return this.configuracao$.obterPorId(this.autenticacao$.acessoUsuarioValue?.idLoja || 0)
      }),
      concatMap(retorno => {

        objRelatorio.params = Object.assign({
          emp_cpf_cnpj: retorno.total > 0 ? retorno.result[0].emp_cnpj : '',
          emp_razao: retorno.total > 0 ? retorno.result[0].emp_razao : '',
          emp_fantasia: retorno.total > 0 ? retorno.result[0].emp_fantasia : '',
          emp_fone: retorno.total > 0 ? retorno.result[0].emp_fone : '',
          emp_endereco: retorno.total > 0 ? retorno.result[0].emp_endereco : '',
          emp_endereco_municipio_descricao: retorno.total > 0 ? retorno.result[0].emp_endereco_municipio_descricao : '',
          emp_logo: logomarca,
          filtros_descricao: filtros
        });

        const apiRelatorio = new ApiRelatorioViewModel<any, ContasReceberPagarPesquisaViewModel[]>();

        apiRelatorio.relatorio = objRelatorio;

        apiRelatorio.subrelatorio = [];

        return this.imprimir('movimentacao_conta_listagem', apiRelatorio);

      })
    )
  };

  suprimento(dados: any): Observable<any> {

    return this.http.post<any>(this.urlSuprimento, dados);
  }

  sangria(dados: any): Observable<any> {

    return this.http.post<any>(this.urlSangria, dados);
  }

  quitar(id: number, dados: CadastroViewModel<any>): Observable<any> {
    return this.http.post<any>(`${this.urlQuitar}/${id}`, dados);
  }

  quitarArray(dados: any): Observable<any> {
    return this.http.post<any>(`${this.urlApiMax}/QuitarArray`, dados);
  }

  cancelar(id: number, dados: CadastroViewModel<any>): Observable<any> {
    return this.http.post<any>(`${this.urlCancelar}/${id}`, dados);
  }

  transferirConta(id_pgto: number, id_conta_destino: number): Observable<any> {
    return this.http.post<any>(`${this.urlTransferirConta}/${id_pgto}`, { id_pgto, id_conta_destino });
  }

  faturamento(obj: any): Observable<any> {
    return this.http.post<any>(`${this.urlApiMax}/Faturamento`, obj);
  }

  reativar(id: number): Observable<any> {
    return this.http.post<any>(`${this.urlReativar}/${id}`, { id_pgto: id });
  }

  obterTotaisPorId(pgto_id_array: Array<number>): Observable<any> {

    let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel();

    pesquisaGeral.select = `
                            SUM(pgto.vlr) as total_s_desc 
                          , SUM(pgto.vlr * COALESCE(cartao.aliq_taxa / 100, 0)) as vlr_taxa 
                          , SUM(pgto.vlr - COALESCE(pgto.vlr * (cartao.aliq_taxa / 100),0))  as total_c_desc`;

    pesquisaGeral.where = ` and pgto.id in (${pgto_id_array.toString()})`;

    return this.http.post<RetornoApiViewModel<ContasReceberPagarPesquisaViewModel[]>>(this.urlGetGroup, pesquisaGeral)
  }

  obterPorIdComMultaJuros(pgto_id?: Array<number>, tipo_conta?: 'R' | 'P'): Observable<RetornoApiViewModel<ContasReceberPagarPesquisaViewModel[]>> {

    let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel()

    pesquisaGeral.select = `pgto.id,
                            pgto.dt_vencimento::TIMESTAMP::date,
                            (CURRENT_DATE - pgto.dt_vencimento::TIMESTAMP::date) AS qtd_dias_vencida,
                            CASE
                              WHEN 
                      		   		  (CURRENT_DATE - pgto.dt_vencimento::TIMESTAMP::date) >
                      				    (SELECT config_pgto.qtd_dias_carencia_juros FROM config_pgto) and pgto.tipo_conta = 'R'
                      		    THEN 
                      		   		  (pgto.vlr *(SELECT (config_pgto.aliq_juros_dia_a_receber / 100) FROM config_pgto))
                      		    WHEN 
                      		   		  (CURRENT_DATE - pgto.dt_vencimento::TIMESTAMP::date) >
                      				    (SELECT config_pgto.qtd_dias_carencia_juros FROM config_pgto) and pgto.tipo_conta = 'P'
                      		    THEN 
                      		   		  (pgto.vlr * (SELECT (config_pgto.aliq_juros_dia_a_pagar / 100) FROM config_pgto))
                              ELSE 0
                            END AS vlr_juros_ao_dia,
                            pgto.vlr_juros,
                            TRUNC(CASE
                              WHEN 
                                  (CURRENT_DATE - pgto.dt_vencimento::TIMESTAMP::date) >
                                  (SELECT config_pgto.qtd_dias_carencia_juros FROM config_pgto) and pgto.tipo_conta = 'R'
                              THEN  
                                  (pgto.vlr * (SELECT (config_pgto.aliq_multa_a_receber / 100) FROM config_pgto))
                              WHEN 
                                  (CURRENT_DATE - pgto.dt_vencimento::TIMESTAMP::date) >
                                  (SELECT config_pgto.qtd_dias_carencia_juros FROM config_pgto) and pgto.tipo_conta = 'P'
                              THEN  
                                  (pgto.vlr * (SELECT (config_pgto.aliq_multa_a_pagar / 100) FROM config_pgto))
                              ELSE 0
                            END, 2) AS vlr_multa,
                            pgto.vlr,
                        	  TRUNC(CASE
                              WHEN 
                        		   		(CURRENT_DATE - pgto.dt_vencimento::TIMESTAMP::date) >
                                  (SELECT config_pgto.qtd_dias_carencia_juros FROM config_pgto) and pgto.tipo_conta = 'R'
                        		  THEN  
                        		   		pgto.vlr + 
                        			    (pgto.vlr * (SELECT (config_pgto.aliq_multa_a_receber / 100) FROM config_pgto)) + 
                        				  (
                        					  (CURRENT_DATE - pgto.dt_vencimento::TIMESTAMP::date) * 
                        					  (pgto.vlr * (SELECT (config_pgto.aliq_juros_dia_a_receber / 100) FROM config_pgto))
                        				  )
                        		  WHEN 
                        		   		(CURRENT_DATE - pgto.dt_vencimento::TIMESTAMP::date) >
                                  (SELECT config_pgto.qtd_dias_carencia_juros FROM config_pgto) and pgto.tipo_conta = 'P'
                        		  THEN  
                        		   		pgto.vlr + 
                        				  (pgto.vlr * (SELECT (config_pgto.aliq_multa_a_pagar / 100) FROM config_pgto)) + 
                        				  (
                                    (CURRENT_DATE - pgto.dt_vencimento::TIMESTAMP::date) * 
                        				    (pgto.vlr *(SELECT (config_pgto.aliq_juros_dia_a_pagar / 100) FROM config_pgto))
                                  )
                              ELSE pgto.vlr
                            END, 2) AS total_c_juros_multa`
    pesquisaGeral.where = ` AND pgto.status = 'N'
                            AND pgto.tipo_conta = '${tipo_conta || 'R'}'
                            AND pgto.id in (${pgto_id})`

    return this.http.post<RetornoApiViewModel<ContasReceberPagarPesquisaViewModel[]>>(`${this.urlApiMax}/get`, pesquisaGeral);
  }

  obterPgtoPorVendaId(pesquisa: ContasReceberPagarPesquisaViewModel): Observable<RetornoApiViewModel<ContasReceberPagarPesquisaViewModel[]>> {

    let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel()

    pesquisaGeral.select = `pgto.vlr as pgto_info_vlr
                            ,pgto.dt_vencimento
                            ,pgto.numero_doc
                            , pgto_tipo.descricao as pgto_info_descricao
                            , CASE WHEN pgto_tipo.chk_prazo = 1 THEN 'À Prazo' ELSE 'À Vista' END AS forma_pgt_venda
                            `;

    pesquisaGeral.calcRowCount = 1;

    pesquisaGeral.start = pesquisa?.start || 0;

    pesquisaGeral.limit = pesquisa?.quantidadeRegistrosPagina || 100;

    pesquisaGeral.where = pesquisa?.venda_id ? ` and pgto.venda_id = ${pesquisa?.venda_id}` : '';


    pesquisaGeral.order = `${pesquisa?.order ? pesquisa?.order : 'pgto.id'}`;
    return this.http.post<RetornoApiViewModel<ContasReceberPagarPesquisaViewModel[]>>(this.urlGet, pesquisaGeral)

  }

  obterTodosGroupPgtoTipoResumoCaixa(caixa_id): Observable<any> {

    var pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel()

    pesquisaGeral.select = `pgto.pgto_tipo_id,
                            pgto_tipo.descricao as pgto_tipo_descricao,
                            pgto_tipo.chk_prazo as pgto_tipo_chk_prazo,
                            SUM(
                              CASE 
                                WHEN pgto.tipo_operacao = 'DV' THEN COALESCE(pgto.vlr * -1, 0)
                                WHEN pgto.tipo_operacao = 'SA' AND pgto.vlr > 0 THEN 0
                                WHEN pgto.tipo_operacao = 'SU' AND pgto.vlr < 0 THEN 0
                                WHEN pgto.status = 'C' THEN COALESCE(pgto.vlr * 0, 0)
                                ELSE COALESCE(pgto.vlr, 0)
                              END
                              ) AS pgto_vlr 
                           `;

    pesquisaGeral.where += `AND pgto.status IN ('N','S','F','G','P','D','C')
                            AND pgto.tipo_operacao IN ('CO','OS','VE','FA','BP','FI','CR','PG','RE','DV','SU','SA') 
                            AND pgto.caixa_id IN (${caixa_id})
                            `;

    pesquisaGeral.group = `pgto.pgto_tipo_id, pgto_tipo.descricao, pgto_tipo.chk_prazo`

    pesquisaGeral.order = 'pgto_tipo.descricao ASC';

    pesquisaGeral.limit = 0;

    return this.http.post<RetornoApiViewModel<ContasReceberPagarPesquisaViewModel[]>>(this.urlGetGroup, pesquisaGeral)
  }

  obterTodosGroupResumoPorBandeira(caixa_id: number) : Observable<any> {

    var pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel()

    pesquisaGeral.select = `CASE 
                              WHEN cartao.tipo = 1 THEN 'Cartão de Crédito'
                              WHEN cartao.tipo = 0 THEN 'Cartão de Débito'
                            END AS cartao_tipo,
                            CASE 
                              WHEN cartao.codigo_bandeira_nf = '01' THEN 'Visa'
                              WHEN cartao.codigo_bandeira_nf = '02' THEN 'Mastercard'
                              WHEN cartao.codigo_bandeira_nf = '03' THEN 'American Express'
                              WHEN cartao.codigo_bandeira_nf = '04' THEN 'Sorocred'
                              WHEN cartao.codigo_bandeira_nf = '05' THEN 'Diners Club'
                              WHEN cartao.codigo_bandeira_nf = '06' THEN 'Elo'
                              WHEN cartao.codigo_bandeira_nf = '07' THEN 'HiperCard'
                              WHEN cartao.codigo_bandeira_nf = '08' THEN 'Aura'
                              WHEN cartao.codigo_bandeira_nf = '09' THEN 'Cabal'
                              ELSE 'Outros'
                            END AS cartao_bandeira,
                            cartao.bandeira as cartao_descricao,
                            SUM(COALESCE(pgto.vlr, 0)) as pgto_vlr,
                            COALESCE(cartao.aliq_taxa) as cartao_aliq_taxa,
                            SUM(ROUND(COALESCE(pgto.vlr, 0) * (COALESCE(cartao.aliq_taxa) / 100),2)) as pgto_vlr_taxa,
                            SUM(ROUND(COALESCE(pgto.vlr, 0) - (COALESCE(pgto.vlr, 0) * (COALESCE(cartao.aliq_taxa) / 100)),2)) as pgto_vlr_liq`;

    pesquisaGeral.where = ` AND pgto_tipo.codigo_pgto_nf IN ('03','04')
                            AND pgto.cartao_id > 0
                            AND pgto.caixa_id IN (${caixa_id})
                          `;

    pesquisaGeral.group =`cartao.tipo, cartao.codigo_bandeira_nf, cartao.bandeira, cartao.aliq_taxa`;

    pesquisaGeral.order = `cartao.bandeira ASC`;

    pesquisaGeral.limit = 0;

    return this.http.post<RetornoApiViewModel<ContasReceberPagarPesquisaViewModel[]>>(this.urlGetGroup, pesquisaGeral)
  }

  obterRecebimentos(caixa_id, configuracaoCaixa: ConfiguracaoCaixaViewModel): Observable<any> {

    var pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel()

    pesquisaGeral.select = `pgto.id,
                            pgto.pgto_ref_pgto_id,
                            TO_CHAR(pgto.dt_lancamento, 'DD/MM/YYYY HH:MM:ss') as dt_lancamento,
                            pgto.venda_id,
                            pgto.cliente_id,
                            cliente.nome as cliente_nome,
                            pgto.tipo_operacao,
                            pgto.atendente_id,
                            atendente.nome as atendente_nome,
                            pgto_ref_pgto_tipo.descricao as documento,
                            pgto_ref.numero_doc,
                            pgto_ref.vlr as pgto_ref_vlr,
                            TO_CHAR(pgto_ref.dt_vencimento, 'DD/MM/YYYY') as dt_vencimento,
                            pgto.vlr,
                            pgto.status,
                            pgto.tipo_conta,
                            pgto_ref.venda_id as venda_referente,
                            pgto.chk_recebido_no_caixa,
                            pgto.pgto_tipo_id as pgto_tipo_id,
                            pgto_tipo.chk_prazo as pgto_tipo_chk_prazo,
                            pgto_tipo.chk_quitado as pgto_tipo_chk_quitado,
                            pgto_tipo.descricao as pgto_tipo_descricao`;

    pesquisaGeral.where += ` AND pgto.caixa_id IN (${caixa_id})`;

    pesquisaGeral.where += ` AND pgto.tipo_conta = 'R'`;

    pesquisaGeral.where += ` AND pgto.tipo_operacao = 'RE'`;

    pesquisaGeral.where += configuracaoCaixa?.chk_mostrar_recebimento_caixa ? `AND pgto.chk_recebido_no_caixa IN (0, 1)` : `AND pgto.chk_recebido_no_caixa IN (1)`;

    pesquisaGeral.group = ``;

    pesquisaGeral.order = 'pgto.id, pgto.dt_lancamento';

    pesquisaGeral.limit = 0;

    return this.http.post<RetornoApiViewModel<any[]>>(`${this.urlApiMax}/get`, pesquisaGeral)
  }

  obterHistoricoFaturamento(ids: number[]): Observable<RetornoApiViewModel<ContasReceberPagarPesquisaViewModel[]>> {
    let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel()
    pesquisaGeral.select = `pgto.id,  pgto.dt_vencimento, pgto.numero_doc, pgto.vlr, pgto.obs, usu_faturou.nome, pgto.dt_faturou`;
    pesquisaGeral.calcRowCount = 1;
    pesquisaGeral.start = 0;
    pesquisaGeral.limit = 100;
    pesquisaGeral.where = `and pgto.id in (${ids.join()})`;
    pesquisaGeral.order = `pgto.id`;
    return this.http.post<RetornoApiViewModel<ContasReceberPagarPesquisaViewModel[]>>(this.urlGet, pesquisaGeral);
}


}