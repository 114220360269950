import { PaginacaoViewModel } from '../../../PaginacaoViewModel';
import { ProdutoNcmViewModel } from '../produto-ncm-view-model';

export class ProdutoNcmPesquisaViewModel extends ProdutoNcmViewModel implements PaginacaoViewModel {
    pagina: number = 1;
    quantidadeRegistrosPagina: number = 20;
    totalRegistros: number = 0;
    totalPaginas: number = 0;
    desativados: boolean = false;
    start?: number = 0;
    order?: string;
}