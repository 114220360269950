import { Injectable } from '@angular/core';
import { URLBASE } from 'src/modules/configuration.service';
import { HttpClient } from '@angular/common/http';
import { AutenticacaoService } from 'src/modules/autenticacao/services/autenticacao.service';
import { PesquisaGeralViewModel } from '@modules/admin/view-models/pesquisa/PesquisaGeralViewModel';
import { RetornoApiViewModel } from '../../view-models/RetornoApiViewModel';
import { Observable } from 'rxjs';
import { CadastroViewModel } from 'src/modules/admin/view-models/cadastro/CadastroViewModel';
import { ConfiguracaoNfePesquisaViewModel } from '../../view-models/sistema/pesquisa/configuracao-nfe-pesquisa-view-model';
import { ConfiguracaoNfeViewModel } from '../../view-models/sistema/configuracao-nfe-view-model';
import { ArquivoBase64ViewModel } from '@modules/manager/view-models/ArquivoBase64ViewModel';

@Injectable({
    providedIn: 'root'
})
export class ConfiguracaoNfeService {

    idEmpresaMaxdata = this.autenticacao$.acessoUsuarioValue?.idEmpresaMaxdata;

    Url = `${URLBASE}/${this.idEmpresaMaxdata}/config_nfe_service`;
    urlGet = `${URLBASE}/${this.idEmpresaMaxdata}/config_nfe_service/get`;
    urlAddUpdate = `${URLBASE}/${this.idEmpresaMaxdata}/config_nfe_service/addupdate`;
    urlAddUpdateBlob      = `${URLBASE}/${this.idEmpresaMaxdata}/config_nfe_service/UpdateBlob`;

    constructor(private http: HttpClient,
        private autenticacao$: AutenticacaoService) { }

    obterTodos(pesquisa?: ConfiguracaoNfePesquisaViewModel): Observable<RetornoApiViewModel<ConfiguracaoNfePesquisaViewModel[]>> {

        let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel();

        pesquisaGeral.select = `config_nfe.*`;

        pesquisaGeral.calcRowCount = 1;

        pesquisaGeral.limit = pesquisa?.quantidadeRegistrosPagina || 100;

        pesquisaGeral.order = pesquisa?.order ? pesquisa?.order : 'config_nfe.id';

        if (pesquisa?.id) pesquisaGeral.where = ` and config_nfe.id = ${pesquisa?.id}` ;
       
        return this.http.post<RetornoApiViewModel<ConfiguracaoNfePesquisaViewModel[]>>(this.urlGet, pesquisaGeral)
    }

    obterPorId(id: number): Observable<RetornoApiViewModel<ConfiguracaoNfeViewModel[]>> {

        let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel();

        pesquisaGeral.select = `config_nfe.id,
                            config_nfe.emp_id,
                            config_nfe.usa_nfe,
                            config_nfe.tipo_nfe_ambiente,
                            config_nfe.tipo_nfe_frete_padrao,
                            config_nfe.usa_desconto_item,
                            config_nfe.nfe_certificado_senha,
                            config_nfe.tipo_danfe_retrato_paisagem,
                            config_nfe.qtd_copias,
                            config_nfe.email_assunto,
                            config_nfe.email_remetente,
                            config_nfe.email_remetente_venda,
                            config_nfe.email_remetente_financeiro,
                            config_nfe.email_mensagem,
                            config_nfe.email_usuario,
                            config_nfe.email_senha,
                            config_nfe.email_servidor_smtp,
                            config_nfe.email_porta,
                            config_nfe.nfce_email_auto,
                            config_nfe.email_autentica,
                            config_nfe.email_usa_ssl,
                            config_nfe.email_tipo_ssl,
                            config_nfe.email_tipo_tsl,
                            config_nfe.img_logo_nfe,
                            config_nfe.nao_concluir_sem_fatura,
                            config_nfe.msg_simples_nacional,
                            config_nfe.msg_fisco,
                            config_nfe.transportadora_padrao_id,
                            config_nfe.tipo_nfe_fuso_horario,
                            config_nfe.usa_nfce,
                            config_nfe.nfce_certificado_senha,
                            config_nfe.tipo_nfce_ambiente,
                            config_nfe.nfce_token,
                            config_nfe.nfce_id_token,
                            config_nfe.tipo_danfce_modelo,
                            config_nfe.tipo_nfce_fuso_horario,
                            config_nfe.nfce_id_token_homologacao,
                            config_nfe.nfce_token_homologacao,
                            config_nfe.nfce_imprime_item_uma_linha,
                            config_nfe.img_logo_nfce,
                            config_nfe.nfe_certificado_dados_pfx,
                            config_nfe.nfce_certificado_dados_pfx,
                            config_nfe.codigo_ultimo_nsu`;

        pesquisaGeral.where = ` and config_nfe.id = ${id}`

        return this.http.post<RetornoApiViewModel<ConfiguracaoNfeViewModel[]>>(`${this.urlGet}`, pesquisaGeral);
    }

    adicionar(configuracaoNfeViewModel: CadastroViewModel<ConfiguracaoNfeViewModel>): Observable<RetornoApiViewModel<ConfiguracaoNfeViewModel>> {
        return this.http.post<RetornoApiViewModel<ConfiguracaoNfeViewModel>>(this.urlAddUpdate, configuracaoNfeViewModel);
    }

    atualizar(id: number, configuracaoNfeViewModel: CadastroViewModel<ConfiguracaoNfeViewModel>): Observable<RetornoApiViewModel<ConfiguracaoNfeViewModel>> {
        return this.http.put<RetornoApiViewModel<ConfiguracaoNfeViewModel>>(`${this.urlAddUpdate}/${id}`, configuracaoNfeViewModel);
    }

    AtualizarArquivoBase64(id: number, nfViewModel: ArquivoBase64ViewModel): Observable<ArquivoBase64ViewModel>{
        return this.http.put<ArquivoBase64ViewModel>(`${this.urlAddUpdateBlob}/${id}`,nfViewModel)
    }

    removerCertificadoNFe(id: number): Observable<any>{
        return this.http.put(`${this.Url}/RemoverCertificadoNFe/${id}`, { id })
    }

    removerCertificadoNFCe(id: number): Observable<any>{
        return this.http.put(`${this.Url}/RemoverCertificadoNFCe/${id}`, { id })
    }

    obterImagem(id): Observable<any> {

        let data = Object.assign({ id: id || 0, field_name: "img_logo_nfe" })
    
        return this.http.post<any>(`${this.Url}/getBlob/${id}`, data);
    }

    obterPorEmpresaLogada(): Observable<RetornoApiViewModel<ConfiguracaoNfeViewModel[]>> {

        let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel();

        pesquisaGeral.select = `config_nfe.id,
                            config_nfe.emp_id,
                            config_nfe.usa_nfe,
                            config_nfe.tipo_nfe_ambiente,
                            config_nfe.tipo_nfe_frete_padrao,
                            config_nfe.usa_desconto_item,
                            config_nfe.nfe_certificado_senha,
                            config_nfe.tipo_danfe_retrato_paisagem,
                            config_nfe.qtd_copias,
                            config_nfe.email_assunto,
                            config_nfe.email_remetente,
                            config_nfe.email_remetente_venda,
                            config_nfe.email_remetente_financeiro,
                            config_nfe.email_mensagem,
                            config_nfe.email_usuario,
                            config_nfe.email_senha,
                            config_nfe.email_servidor_smtp,
                            config_nfe.email_porta,
                            config_nfe.nfce_email_auto,
                            config_nfe.email_autentica,
                            config_nfe.email_usa_ssl,
                            config_nfe.email_tipo_ssl,
                            config_nfe.email_tipo_tsl,
                            config_nfe.img_logo_nfe,
                            config_nfe.nao_concluir_sem_fatura,
                            config_nfe.msg_simples_nacional,
                            config_nfe.msg_fisco,
                            config_nfe.transportadora_padrao_id,
                            config_nfe.tipo_nfe_fuso_horario,
                            config_nfe.usa_nfce,
                            config_nfe.nfce_certificado_senha,
                            config_nfe.tipo_nfce_ambiente,
                            config_nfe.nfce_token,
                            config_nfe.nfce_id_token,
                            config_nfe.tipo_danfce_modelo,
                            config_nfe.tipo_nfce_fuso_horario,
                            config_nfe.nfce_id_token_homologacao,
                            config_nfe.nfce_token_homologacao,
                            config_nfe.nfce_imprime_item_uma_linha,
                            config_nfe.img_logo_nfce,
                            config_nfe.nfe_certificado_dados_pfx,
                            config_nfe.nfce_certificado_dados_pfx,
                            config_nfe.codigo_ultimo_nsu`;

        pesquisaGeral.where = ` and config_nfe.emp_id = ${this.autenticacao$.acessoUsuarioValue?.idLoja}`

        console.log(this.autenticacao$.acessoUsuarioValue?.idLoja)

        return this.http.post<RetornoApiViewModel<ConfiguracaoNfeViewModel[]>>(`${this.urlGet}`, pesquisaGeral);
    }
}
