import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertMessage } from '@modules/alert.configuration.service';
import { FreteService } from '@modules/manager/cadastro/servico/frete/frete.service';
import { FretePesquisaViewModel } from '@modules/manager/view-models/cadastro/servico/pesquisa/frete-pesquisa-view-model';
import { Uteis } from '@modules/utilizarios/uteis';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-frete-pesquisa-modal',
  templateUrl: './frete-pesquisa-modal.component.html',
  styles: [
  ]
})
export class FretePesquisaModalComponent implements OnInit {

  @Input() abrirFecharModalFrete: boolean = false;
  @Input() stringPesquisa: string = '';
  @Output() closeModal = new EventEmitter();
  @Output() frete: EventEmitter<any> = new EventEmitter();

  pesquisa: FretePesquisaViewModel = new FretePesquisaViewModel();

  listaFretes: FretePesquisaViewModel[] = [];

  constructor(
    private alert$: AlertMessage,
    private spinner$: NgxSpinnerService,
    private freteService$: FreteService,
  ) { }

  ngOnInit(): void {
    setTimeout(() => {

      this.pesquisa.descricao = this.stringPesquisa;

    }, 100);
  }

  pesquisar() {
  
    this.spinner$.show();

    this.freteService$.obterTodos(this.pesquisa).subscribe(
      retorno => {

        this.listaFretes = retorno.total > 0 ? retorno.result : [];

        this.spinner$.hide();
      },
      error => {
        this.spinner$.hide();

        this.alert$.Danger('Erro', Uteis.ObterErroApi(error));
      }
    );
  }

  produtoSlecionado(item) {
    this.frete.emit(item);
  }

  closeModalFrete() {
    this.closeModal.emit(false);
  }

}
