import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CadastroViewModel } from '@modules/admin/view-models/cadastro/CadastroViewModel';
import { PesquisaGeralViewModel } from '@modules/admin/view-models/pesquisa/PesquisaGeralViewModel';
import { URLBASE, URLIMPRESSAO } from '@modules/configuration.service';
import { ConfiguracaoService } from '@modules/manager/sistema/configuracao/configuracao.service';
import { ClienteDioptriaViewModel } from '@modules/manager/view-models/cadastro/cliente-fornecedor/cliente-dioptria-view-model ';
import { ClienteDioptriaPesquisaViewModel } from '@modules/manager/view-models/cadastro/cliente-fornecedor/pesquisa/cliente-dioptria-pesquisa-view-model';
import { ApiRelatorioViewModel, RelatorioViewModel } from '@modules/manager/view-models/RelatorioViewModel';
import { RetornoApiViewModel } from '@modules/manager/view-models/RetornoApiViewModel';
import { Observable } from 'rxjs';
import { AutenticacaoService } from 'src/modules/autenticacao/services/autenticacao.service';
import { concatMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ClienteDioptriaService {
  

  idEmpresaMaxdata = this.autenticacaoService.acessoUsuarioValue?.idEmpresaMaxdata;

  urlGet = `${URLBASE}/${this.idEmpresaMaxdata}/dioptria_service/get`;
  urlAddUpdate = `${URLBASE}/${this.idEmpresaMaxdata}/dioptria_service/addupdate`;
  urlImpressaoRel = `${URLIMPRESSAO}/api/relatorio`;
  constructor(
    private http: HttpClient,
    private autenticacaoService: AutenticacaoService,
    
    private autenticacao$: AutenticacaoService,
    private configuracao$: ConfiguracaoService
    ) { }

  obterTodos(client_id?: number, pesquisa?: ClienteDioptriaPesquisaViewModel): Observable<RetornoApiViewModel<ClienteDioptriaPesquisaViewModel[]>> {

    let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel();

    pesquisaGeral.select = `dioptria.*
                            , dioptria_tipo_armacao.descricao
                            , clinica_medico.nome AS medico
                            , cliente.nome
                            , CASE WHEN dt_venc_receita >  (SELECT current_date) THEN 'EM DIA' ELSE 'VENCIDA' END AS status
                            , to_char( dioptria.dt_venc_receita, 'dd/MM/yyyy') as dt_vencimento
                  `;

    pesquisaGeral.where = ` and dioptria.cliente_id = ${client_id || 0}`;

    pesquisaGeral.calcRowCount = 1;

    pesquisaGeral.start = pesquisa?.start || 0;

    pesquisaGeral.limit = 0;

    pesquisaGeral.order = `${pesquisa?.order ? pesquisa?.order : 'id'}`;

    // if(pesquisa.id) pesquisaGeral.where += ` and dioptria.id = ${pesquisa?.id || 0}`;

    return this.http.post<RetornoApiViewModel<ClienteDioptriaPesquisaViewModel[]>>(this.urlGet, pesquisaGeral)
  }


  adicionar(dioptriaViewModel: CadastroViewModel<ClienteDioptriaViewModel>): Observable<RetornoApiViewModel<ClienteDioptriaViewModel>> {
  
    return this.http.post<RetornoApiViewModel<ClienteDioptriaViewModel>>(this.urlAddUpdate, dioptriaViewModel);
}
atualizar(id: number, dioptriaViewModel: CadastroViewModel<ClienteDioptriaViewModel>): Observable<RetornoApiViewModel<ClienteDioptriaViewModel>> {
  return this.http.put<RetornoApiViewModel<ClienteDioptriaViewModel>>(`${this.urlAddUpdate}/${id}`, dioptriaViewModel);
}

obterPorId(id: number): Observable<RetornoApiViewModel<ClienteDioptriaViewModel[]>> {
  let pesquisaGeral: PesquisaGeralViewModel = new PesquisaGeralViewModel()

  pesquisaGeral.select = `dioptria.*
                         , cliente.nome as cliente_nome
                         , cliente.cpf_cnpj as cliente_cpf_cnpj
                         , cliente.celular as celular
                         , dioptria_tipo_armacao.descricao as armacao_descricao
                         , clinica_medico.nome as medico_nome
                         , (select endereco from cliente_endereco inner join endereco on cliente_endereco.endereco_id = endereco.id where cliente_endereco.cliente_id = dioptria.cliente_id and cliente_endereco.principal = 1 ) as endereco
                         , (select endereco_bairro from cliente_endereco inner join endereco on cliente_endereco.endereco_id = endereco.id where cliente_endereco.cliente_id = dioptria.cliente_id and cliente_endereco.principal = 1 ) as endereco_bairro
                         , (select endereco_municipio.descricao from cliente_endereco inner join endereco on cliente_endereco.endereco_id = endereco.id inner join endereco_municipio on endereco.endereco_cidade_codigo_ibge = endereco_municipio.codigo_ibge where cliente_endereco.cliente_id = dioptria.cliente_id and cliente_endereco.principal = 1 ) as endereco_municipio_descricao`;
  pesquisaGeral.where = `and dioptria.id = ${id}`;

  return this.http.post<RetornoApiViewModel<ClienteDioptriaViewModel[]>>(`${this.urlGet}`, pesquisaGeral);
}

imprimirReceita(receita) {

  
  let objReceita: RelatorioViewModel<any, any> = new RelatorioViewModel<any, any>();

  let logomarca: string = null;

  return this.configuracao$.obterImagem(this.autenticacao$.acessoUsuarioValue?.idLoja || 0).pipe(
    concatMap(retorno => {

      logomarca = retorno.result[0].img_logomarca

      return this.configuracao$.obterPorId(this.autenticacao$.acessoUsuarioValue?.idLoja || 0)
    }),
    concatMap(retorno => {
      objReceita.params = Object.assign({
        emp_cpf_cnpj: retorno.total > 0 ? retorno.result[0].emp_cnpj : '',
        emp_razao: retorno.total > 0 ? retorno.result[0].emp_razao.toLocaleUpperCase() : '',
        emp_fantasia: retorno.total > 0 ? retorno.result[0].emp_fantasia.toLocaleUpperCase() : '',
        emp_fone: retorno.total > 0 ? retorno.result[0].emp_fone : '',
        emp_endereco: retorno.total > 0 ? retorno.result[0].emp_endereco.toLocaleUpperCase() : '',
        emp_endereco_municipio_descricao: retorno.total > 0 ? retorno.result[0].emp_endereco_municipio_descricao.toLocaleUpperCase() : '',
        emp_cep:retorno.total > 0 ? retorno.result[0].emp_endereco_cep:'',
        emp_ie:retorno.total > 0 ? retorno.result[0].emp_ie:'',
        emp_bairro:retorno.total > 0 ? retorno.result[0].emp_endereco_bairro.toLocaleUpperCase():'',
        emp_endereco_uf_sigla:retorno.total > 0 ? retorno.result[0].emp_endereco_uf_sigla.toLocaleUpperCase():'',
        emp_logo: logomarca,
        pgt_tipo: receita?.pgt_tipo
      });
      objReceita.obj = Object.assign({}, receita);

      const apiRelatorio = new ApiRelatorioViewModel<any, any>();

      apiRelatorio.relatorio = objReceita;
     
      
      return this.http.post<any>(`${this.urlImpressaoRel}/cliente_dioptria/`, apiRelatorio);
    })
  )



}
}
